/**
 * Unlink from portfolio reducer handler
 */
import {
  UNLINK_FROM_PORTFOLIO_REQUEST,
  UNLINK_FROM_PORTFOLIO_SUCCESS,
  UNLINK_FROM_PORTFOLIO_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case UNLINK_FROM_PORTFOLIO_REQUEST:
      return Object.assign({}, state, {
        unlinkFromPortfolioLoading: {
          ...state.unlinkFromPortfolioLoading,
          [payload.dealId]: true
        },
        unlinkFromPortfolioError: {
          ...state.unlinkFromPortfolioError,
          [payload.dealId]: null
        },
      });

    case UNLINK_FROM_PORTFOLIO_SUCCESS: {
      const { deal } = payload.data;
      return Object.assign({}, state, {
        deals: {
          ...state.deals,
          [payload.dealId]: (state.deals[payload.dealId] ? {
            ...state.deals[payload.dealId],
            ...deal,
            portfolio: undefined,
            // This 👇 is because internal properties may get updated in between
            // two requests and unless we go to the last level and do a deep clone,
            // we will end up with state not updating even when it should
            // @todo: add validation on structure of deal, and its
            // internal resources, like startup, assignees, categories etc
            // Ref nucleus#886
            assignees: (
              // array
              deal.assignees ?
              deal.assignees :
              state.deals[payload.dealId].assignees
            ),
            attachments: (
              // array
              deal.attachments ?
              deal.attachments :
              state.deals[payload.dealId].attachments
            ),
            categories: (
              deal.categories ?
              deal.categories :
              state.deals[payload.dealId].categories
            ),
            creator: (
              // @todo: add validation on structure of creator
              deal.creator ?
              deal.creator :
              state.deals[payload.dealId].creator
            ),
            externalReferrer: (
              // @todo: add validation on structure of external referrer
              deal.externalReferrer ?
              deal.externalReferrer :
              state.deals[payload.dealId].externalReferrer
            ),
            internalReferrer: (
              // @todo: add validation on structure of internal referrer
              deal.internalReferrer ?
              deal.internalReferrer :
              state.deals[payload.dealId].internalReferrer
            ),
            participants: (
              // array
              deal.participants ?
              deal.participants :
              state.deals[payload.dealId].participants
            ),
            stage: (
              // @todo: add validation on structure of internal referrer
              deal.stage ?
              deal.stage :
              state.deals[payload.dealId].stage
            ),
            startup: (
              // @todo: add validation on structure of startup, and its
              // internal resources, like founders, addresses etc
              // Ref nucleus#886
              deal.startup ?
              ({
                ...state.deals[payload.dealId].startup,
                ...deal.startup
              }) :
              state.deals[payload.dealId].startup
            ),
            _roles: (
              // array
              deal._roles ?
              deal._roles : state.deals[payload.dealId]._roles
            ),
          } : deal)
        },
        unlinkFromPortfolioLoading: {
          ...state.unlinkFromPortfolioLoading,
          [payload.dealId]: false,
        },
        unlinkFromPortfolioError: {
          ...state.unlinkFromPortfolioError,
          [payload.dealId]: null,
        }
      });
    }

    case UNLINK_FROM_PORTFOLIO_FAILURE:
      return Object.assign({}, state, {
        unlinkFromPortfolioLoading: {
          ...state.unlinkFromPortfolioLoading,
          [payload.dealId]: false
        },
        unlinkFromPortfolioError: {
          ...state.unlinkFromPortfolioError,
          [payload.dealId]: payload.message
        },
      });

    default:
      return state;
  }
};

