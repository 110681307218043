/** @format */
import reduce from 'lodash.reduce';
import * as types from './actions';

import { assign } from '../../helpers/object';
import property from 'lodash.property';

const initial = {
  limitedPartners: {},
  limitedPartnersListLoading: false,
  limitedPartnersListError: null,
  limitedPartnerLoading: false,
  limitedPartnerError: null,
  tags: [
    //List of all tags defined in the conext of fund limited partners
  ],
  tagsLoading: null,
  tagsError: null,
  noOfLps: null,
  addLimitedPartnersLoading: false,
};

export default function(state = initial, { type, payload }) {
  switch (type) {
    case types.ADD_LP_TO_FUND_REQUEST: {
      return assign(state, {
        addLimitedPartnersLoading: true,
      });
    }
    case types.ADD_LP_TO_FUND_FAILURE: {
      return assign(state, {
        addLimitedPartnersLoading: false,
      });
    }
    case types.ADD_LP_TO_FUND_SUCCESS: {
      const newLimitedPartners = reduce(
        payload.data,
        (acc, result) => {
          if (result.success) {
            // only successful results end with limited partner getting added
            const newLP = result.data;
            acc[newLP.id] = newLP;
          }
          return acc;
        },
        {}
      );
      return assign(state, {
        addLimitedPartnersLoading: false,
        // merge existing and new
        limitedPartners: assign(state.limitedPartners, newLimitedPartners),
      });
    }

    case types.LOAD_FUND_LPS_REQUEST: {
      return assign(state, {
        limitedPartnersListLoading: true,
        limitedPartnersListError: null,
      });
    }
    case types.LOAD_FUND_LPS_FAILURE: {
      return assign(state, {
        limitedPartnersListLoading: false,
        limitedPartnersListError: payload.message,
      });
    }
    case types.LOAD_FUND_LPS_SUCCESS: {
      const limtedPartners = reduce(
        payload.data,
        function(limitedPartners, limitedPartnerObj) {
          limitedPartners[limitedPartnerObj.id] = limitedPartnerObj;
          return limitedPartners;
        },
        {}
      );

      return assign(state, {
        limitedPartnersListLoading: false,
        limitedPartnersListError: null,
        noOfLps: payload.noOfLps,
        limitedPartners: assign(state.limitedPartners, limtedPartners),
      });
    }

    case types.LOAD_FUND_LPS_LIST_TAGS_REQUEST: {
      const loading = {
        tagsLoading: true,
        tagsError: null,
      };
      return assign(state, loading);
    }
    case types.LOAD_FUND_LPS_LIST_TAGS_SUCCESS: {
      const tags = {
        tags: payload.tags,
        tagsLoading: false,
        tagsError: null,
      };

      return assign(state, tags);
    }

    case types.LOAD_FUND_LPS_LIST_TAGS_FAILURE: {
      const error = {
        tagsLoading: false,
        tagsError: payload.message,
      };

      return assign(state, error);
    }

    case types.ADD_TAG_TO_LP_SUCCESS: {
      const tags = reduce(
        Object.keys(state.limitedPartners),
        function(limitedPartners, limitedPartnerId) {
          limitedPartners[limitedPartnerId] = {
            ...state.limitedPartners[limitedPartnerId],
          };

          if (limitedPartnerId === payload.limitedPartnerId) {
            limitedPartners[limitedPartnerId].investorEntity =
              limitedPartners[limitedPartnerId].investorEntity || {};
            limitedPartners[limitedPartnerId].investorEntity.fundInvestor =
              limitedPartners[limitedPartnerId].investorEntity.fundInvestor ||
              {};
            // prettier-ignore
            limitedPartners[limitedPartnerId].investorEntity.fundInvestor.tags = [
              ...(limitedPartners[limitedPartnerId].investorEntity.fundInvestor.tags || []),
              { name: payload.tag },
            ];
          }
          return limitedPartners;
        },
        {}
      );

      return assign(state, {
        limitedPartners: assign(state.limitedPartners, tags),
      });
    }

    case types.REMOVE_TAG_FROM_LP_SUCCESS: {
      const tagsAfterRemoval = reduce(
        Object.keys(state.limitedPartners),
        function(limitedPartners, limitedPartnerId) {
          limitedPartners[limitedPartnerId] = {
            ...state.limitedPartners[limitedPartnerId],
          };
          if (limitedPartnerId !== payload.limitedPartnerId) {
            return limitedPartners;
          }
          limitedPartners[limitedPartnerId].investorEntity =
            limitedPartners[limitedPartnerId].investorEntity || {};
          limitedPartners[limitedPartnerId].investorEntity.fundInvestor =
            limitedPartners[limitedPartnerId].investorEntity.fundInvestor || {};
          limitedPartners[limitedPartnerId].investorEntity.fundInvestor.tags = (
            limitedPartners[limitedPartnerId].investorEntity.fundInvestor
              .tags || []
          ).filter(tag => tag.name !== payload.tag.name);
          return limitedPartners;
        },
        {}
      );
      return assign(state, {
        limitedPartners: assign(state.limitedPartners, tagsAfterRemoval),
      });
    }

    /** LOAD_LP */
    case types.LOAD_LP_REQUEST: {
      return assign(state, {
        limitedPartnerLoading: true,
        limitedPartnerError: null,
      });
    }
    case types.LOAD_LP_SUCCESS: {
      return assign(state, {
        limitedPartnerLoading: false,
        limitedPartnerError: null,
        limitedPartners: assign(state.limitedPartners, {
          [payload.limitedPartnerId]: payload.data,
        }),
      });
    }
    case types.LOAD_LP_FAILURE: {
      return assign(state, {
        limitedPartnerLoading: false,
        limitedPartnerError: payload.message,
      });
    }

    /** UPDATE_LP */
    case types.UPDATE_LP_REQUEST: {
      return assign(state, {
        limitedPartnerLoading: true,
        limitedPartnerError: null,
      });
    }
    case types.UPDATE_LP_SUCCESS: {
      return assign(state, {
        limitedPartnerLoading: false,
        limitedPartnerError: null,
        limitedPartners: assign(state.limitedPartners, {
          [payload.limitedPartnerId]: {
            ...property(payload.limitedPartnerId)(state.limitedPartners),
            ...payload.data,
          },
        }),
      });
    }
    case types.UPDATE_LP_FAILURE:
      return assign(state, {
        limitedPartnerLoading: false,
        limitedPartnerError: payload.message,
      });

    /** DELETE_LP */
    case types.DELETE_LP_REQUEST: {
      return assign(state, {
        limitedPartnerLoading: true,
        limitedPartnerError: null,
      });
    }
    case types.DELETE_LP_SUCCESS: {
      return assign(state, {
        limitedPartnerLoading: false,
        limitedPartnerError: null,
        limitedPartners: assign(state.limitedPartners, {
          [payload.limitedPartnerId]: null,
        }),
      });
    }
    case types.DELETE_LP_FAILURE:
      return assign(state, {
        limitedPartnerLoading: false,
        limitedPartnerError: payload.message,
      });

    // Resets the redux state
    case types.CLEAR: {
      return assign(state, initial);
    }

    default:
      return state;
  }
}
