/**
 * Used to only increment/decrement the deal attachemtn count
 * @format
 */
import {
  INCREMENT_DEAL_ATTACHMENT_COUNT,
  DECREMENT_DEAL_ATTACHMENT_COUNT,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case INCREMENT_DEAL_ATTACHMENT_COUNT:
      return Object.assign({}, state, {
        dealsResourceCount: {
          ...state.dealsResourceCount,
          [payload.dealId]: {
            ...state.dealsResourceCount[payload.dealId],
            attachmentsCount:
              state.dealsResourceCount[payload.dealId] &&
              state.dealsResourceCount[payload.dealId].attachmentsCount
                ? state.dealsResourceCount[payload.dealId].attachmentsCount +
                  payload.increment
                : payload.increment,
          },
        },
      });

    case DECREMENT_DEAL_ATTACHMENT_COUNT:
      return Object.assign({}, state, {
        dealsResourceCount: {
          ...state.dealsResourceCount,
          [payload.dealId]: {
            ...state.dealsResourceCount[payload.dealId],
            attachmentsCount:
              state.dealsResourceCount[payload.dealId] &&
              state.dealsResourceCount[payload.dealId].attachmentsCount
                ? state.dealsResourceCount[payload.dealId].attachmentsCount -
                    payload.decrement >=
                  0
                  ? state.dealsResourceCount[payload.dealId].attachmentsCount -
                    payload.decrement
                  : 0
                : 0,
          },
        },
      });

    default:
      return state;
  }
};
