/**
 * Returns the workspace view type
 * See@ https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
 * @format
 */
import supportsHTML5Storage from './supportsHTML5Storage';

const getWorkspaceViewType = () => {
  if (!supportsHTML5Storage()) {
    return null;
  }
  let viewType = window.localStorage.getItem('viewType');
  if (viewType && typeof viewType === 'string') {
    try {
      viewType = JSON.parse(viewType);
      // If expires_on is in the past, expire
      if (new Date(viewType.expires_on) < new Date()) {
        window.localStorage.removeItem('viewType');
        return null;
      }
      // Return value if it has not expired
      return viewType.value;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export default getWorkspaceViewType;
