/**
 * DELETE_SIGN_OFF_GROUP reducer
 * @format
 */
import {
  DELETE_SIGN_OFF_GROUP_REQUEST,
  DELETE_SIGN_OFF_GROUP_SUCCESS,
  DELETE_SIGN_OFF_GROUP_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case DELETE_SIGN_OFF_GROUP_REQUEST:
      return Object.assign({}, state, {
        updateSignOffGroupLoading: {
          ...state.updateSignOffGroupLoading,
          [payload.signOffGroupId]: true,
        },
        updateSignOffGroupError: {
          ...state.updateSignOffGroupError,
          [payload.signOffGroupId]: null,
        },
      });

    case DELETE_SIGN_OFF_GROUP_SUCCESS:
      return Object.assign({}, state, {
        updateSignOffGroupLoading: {
          ...state.updateSignOffGroupLoading,
          [payload.signOffGroupId]: false,
        },
        updateSignOffGroupError: {
          ...state.updateSignOffGroupError,
          [payload.signOffGroupId]: null,
        },
        signOffGroups: {
          ...state.signOffGroups,
          [payload.signOffGroupId]: null,
        },
        // Remove the sign off group from the association hashmap
        signOffGroupsDealsHashMap: {
          ...state.signOffGroupsDealsHashMap,
          [payload.dealId]: state.signOffGroupsDealsHashMap[payload.dealId]
            ? state.signOffGroupsDealsHashMap[payload.dealId].filter(
                sign_off_group_id =>
                  sign_off_group_id !== payload.signOffGroupId
              )
            : [],
        },
      });
    case DELETE_SIGN_OFF_GROUP_FAILURE:
      return Object.assign({}, state, {
        updateSignOffGroupLoading: {
          ...state.updateSignOffGroupLoading,
          [payload.signOffGroupId]: false,
        },
        updateSignOffGroupError: {
          ...state.updateSignOffGroupError,
          [payload.signOffGroupId]: payload.message,
        },
      });

    default:
      return state;
  }
};
