import * as types from './actions';

const initialState = {
  user: null,
  // holds completed stages for the user
  stage: [],

  loadUserLoading: false,
  loadUserError: null,
  loginLoading: false,
  loginError: null,

  signupLoading: false,
  signupError: null,

  logoutLoading: false,
  logoutError: null,

  inviterData: null,
  inviterDataLoading: false,
  inviterDataError: null,

  resetPasswordInitLoading: false,
  resetPasswordInitError: null,

  resetPasswordDataLoading: false,
  resetPasswordDataError: null,
  resetPasswordData: null,

  resetPasswordFinishLoading: false,
  resetPasswordFinishError: null,

  googleSettingsForLoginLoading: false,
  googleSettingsForLoginError: null,

  microsoftSettingsForLoginLoading: false,
  microsoftSettingsForLoginError: null,

  oauth2AuthorizationInitLoading: false,
  oauth2AuthorizationInitError: null,

  oauth2AuthorizationFinishLoading: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {

    /**
    * Load user reducer
    */
    case types.LOAD_USER_REQUEST:
      return Object.assign({}, state, {
        loginLoading: true,
        loadUserLoading: true,
        loadUserError: null,
      });
    case types.LOAD_USER_SUCCESS:
      // user is being set by LOGIN_USER_SUCCESS
      return Object.assign({}, state, {
        loginLoading: false,
        loadUserLoading: false,
        loginError: null,
        loadUserError: null,
      });
    case types.LOAD_USER_ERROR:
      return Object.assign({}, state, {
        user: null,
        loginLoading: false,
        loadUserLoading: false,
        loginError: payload,
        loadUserError: payload
      });

    /**
    * Sign up user reducer
    */
    case types.SIGNUP_USER_REQUEST:
      return Object.assign({}, state, {
        signupLoading: true,
        signupError: null,
      });
    case types.SIGNUP_USER_SUCCESS:
      return Object.assign({}, state, {
        signupLoading: false,
        signupError: null,
      });
    case types.SIGNUP_USER_FAILURE:
      return Object.assign({}, state, {
        signupLoading: false,
        signupError: payload,
      });

    /**
    * Login user reducer
    */
    case types.LOGIN_USER_REQUEST:
      return Object.assign({}, state, {
        loginLoading: true,
        loginError: null
      });
    case types.LOGIN_USER_SUCCESS:
      return Object.assign({}, state, {
        user: payload.user,
        stage: payload.stage,
        loginLoading: false,
        loginError: null,
      });
    case types.LOGIN_USER_FAILURE:
      return Object.assign({}, state, {
        loginLoading: false,
        loginError: payload
      });

    /**
    * Logout reducer
    */
    case types.LOGOUT_USER_REQUEST:
      return Object.assign({}, state, {
        logoutLoading: true,
        logoutError: null
      });
    case types.LOGOUT_USER_SUCCESS:
      return Object.assign({}, state, {
        user: null,
        logoutLoading: false,
        logoutError: null
      });
    case types.LOGOUT_USER_FAILURE:
      return Object.assign({}, state, {
        logoutError: payload,
        logoutLoading:false
      });


    /**
    * Reset password initiate reducer
    */
    case types.RESET_PASSWORD_INIT_REQUEST:
      return Object.assign({}, state, {
        resetPasswordInitLoading: true,
        resetPasswordInitError: null,
      });
    case types.RESET_PASSWORD_INIT_SUCCESS:
      return Object.assign({}, state, {
        resetPasswordInitLoading: false,
        resetPasswordInitError: null,
      });
    case types.RESET_PASSWORD_INIT_FAILURE:
      return Object.assign({}, state, {
        resetPasswordInitLoading: false,
        resetPasswordInitError: payload
      });

    /**
    * Reset password data loading reducer
    */
    case types.RESET_PASSWORD_DATA_REQUEST:
      return Object.assign({}, state, {
        resetPasswordDataLoading: true,
        resetPasswordDataError: null,
      });
    case types.RESET_PASSWORD_DATA_SUCCESS:
      return Object.assign({}, state, {
        resetPasswordDataLoading: false,
        resetPasswordData: payload,
        resetPasswordDataError: null,
      });
    case types.RESET_PASSWORD_DATA_FAILURE:
      return Object.assign({}, state, {
        resetPasswordDataLoading: false,
        resetPasswordDataError: payload
      });

    /**
    * Reset password finish reducer
    */
    case types.RESET_PASSWORD_FINISH_REQUEST:
      return Object.assign({}, state, {
        resetPasswordFinishLoading: true,
        resetPasswordFinishError: null,
      });
    case types.RESET_PASSWORD_FINISH_SUCCESS:
      return Object.assign({}, state, {
        resetPasswordFinishLoading: false,
        resetPasswordFinishError: null,
      });
    case types.RESET_PASSWORD_FINISH_FAILURE:
      return Object.assign({}, state, {
        resetPasswordFinishLoading: false,
        resetPasswordFinishError: payload
      });

    /**
    * Load inviter's data reducer
    */
    case types.LOAD_INVITERDATA_REQUEST:
      return Object.assign({}, state, {
        inviterDataLoading: true,
        inviterDataError: null
      });
    case types.LOAD_INVITERDATA_SUCCESS:
      return Object.assign({}, state, {
        inviterDataLoading: false,
        inviterData: payload,
        inviterDataError: null
      });
    case types.LOAD_INVITERDATA_FAILURE:
      return Object.assign({}, state, {
        inviterDataLoading: false,
        inviterDataError: payload
      });

    /**
    * Google settings for login - reducers
    */
    case types.GOOGLE_SETTINGS_FOR_LOGIN_REQUEST:
      return Object.assign({}, state, {
        googleSettingsForLoginLoading: true,
        googleSettingsForLoginError: null,
      });
    case types.GOOGLE_SETTINGS_FOR_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        googleSettingsForLoginLoading: false,
        googleSettingsForLoginError: null,
      });
    case types.GOOGLE_SETTINGS_FOR_LOGIN_FAILURE:
      return Object.assign({}, state, {
        googleSettingsForLoginLoading: false,
        googleSettingsForLoginError: payload,
      });

      /**
       * Microsoft Settings for login - reducers
       */
      case types.MICROSOFT_SETTINGS_FOR_LOGIN_REQUEST:
        return Object.assign({}, state, {
          microsoftSettingsForLoginLoading: true,
          microsoftSettingsForLoginError: null,
        });
      case types.MICROSOFT_SETTINGS_FOR_LOGIN_SUCCESS:
        return Object.assign({}, state, {
          microsoftSettingsForLoginLoading: false,
          microsoftSettingsForLoginError: null,
        });
      case types.MICROSOFT_SETTINGS_FOR_LOGIN_FAILURE:
        return Object.assign({}, state, {
          microsoftSettingsForLoginLoading: false,
          microsoftSettingsForLoginError: payload,
        });

    /**
    * OAuth2 initial authorization request
    */
    case types.OAUTH2_AUTHORIZATION_INIT_REQUEST:
      return Object.assign({}, state, {
        oauth2AuthorizationInitLoading: true,
        oauth2AuthorizationInitError: null,
      });
    case types.OAUTH2_AUTHORIZATION_INIT_SUCCESS:
      return Object.assign({}, state, {
        oauth2AuthorizationInitLoading: false,
        oauth2AuthorizationInitError: null,
      });
    case types.OAUTH2_AUTHORIZATION_INIT_FAILURE:
      return Object.assign({}, state, {
        oauth2AuthorizationInitLoading: false,
        oauth2AuthorizationInitError: payload,
      });

    /**
    * OAuth2 authorization decision request (approve/decline)
    */
    case types.OAUTH2_AUTHORIZATION_FINISH_REQUEST:
      return Object.assign({}, state, {
        oauth2AuthorizationFinishLoading: true,
      });

    /**
    * Default
    */
    default:
      return state;
  }
};
