/**
 * Deal actions
 *
 * @author Ritesh Shrivastav
 */
/**
 * Add deal
 */
export const ADD_DEAL_REQUEST = 'deal/ADD_DEAL_REQUEST';
export const ADD_DEAL_SUCCESS = 'deal/ADD_DEAL_SUCCESS';
export const ADD_DEAL_FAILURE = 'deal/ADD_DEAL_FAILURE';

 /**
 * ADD_ASSIGNEE actions
 */
export const ADD_ASSIGNEE_REQUEST = 'deal/ADD_ASSIGNEE_REQUEST';
export const ADD_ASSIGNEE_SUCCESS = 'deal/ADD_ASSIGNEE_SUCCESS';
export const ADD_ASSIGNEE_FAILURE = 'deal/ADD_ASSIGNEE_FAILURE';
/**
 * ADD_DEAL_EVENT_NOTE actions
 */
export const ADD_DEAL_EVENT_NOTE_REQUEST = 'deal/ADD_DEAL_EVENT_NOTE_REQUEST';
export const ADD_DEAL_EVENT_NOTE_SUCCESS = 'deal/ADD_DEAL_EVENT_NOTE_SUCCESS';
export const ADD_DEAL_EVENT_NOTE_FAILURE = 'deal/ADD_DEAL_EVENT_NOTE_FAILURE';
/**
 * ADD_DEAL_NOTE actions
 */
export const ADD_DEAL_NOTE_REQUEST = 'deal/ADD_DEAL_NOTE_REQUEST';
export const ADD_DEAL_NOTE_SUCCESS = 'deal/ADD_DEAL_NOTE_SUCCESS';
export const ADD_DEAL_NOTE_FAILURE = 'deal/ADD_DEAL_NOTE_FAILURE';
/**
 * ADD_PARTICIPANT actions
 */
export const ADD_PARTICIPANT_REQUEST = 'deal/ADD_PARTICIPANT_REQUEST';
export const ADD_PARTICIPANT_SUCCESS = 'deal/ADD_PARTICIPANT_SUCCESS';
export const ADD_PARTICIPANT_FAILURE = 'deal/ADD_PARTICIPANT_FAILURE';
/**
 * ADD_STARTUP_FOUNDER actions
 */
export const ADD_STARTUP_FOUNDER_REQUEST = 'deal/ADD_STARTUP_FOUNDER_REQUEST';
export const ADD_STARTUP_FOUNDER_SUCCESS = 'deal/ADD_STARTUP_FOUNDER_SUCCESS';
export const ADD_STARTUP_FOUNDER_FAILURE = 'deal/ADD_STARTUP_FOUNDER_FAILURE';
/**
 * ADD_STARTUP_FOUNDERS actions
 */
export const ADD_STARTUP_FOUNDERS_REQUEST = 'deal/ADD_STARTUP_FOUNDERS_REQUEST';
export const ADD_STARTUP_FOUNDERS_SUCCESS = 'deal/ADD_STARTUP_FOUNDERS_SUCCESS';
export const ADD_STARTUP_FOUNDERS_FAILURE = 'deal/ADD_STARTUP_FOUNDERS_FAILURE';
/**
 * ASSOCIATE_CATEGORY actions
 */
export const ASSOCIATE_CATEGORY_REQUEST = 'deal/ASSOCIATE_CATEGORY_REQUEST';
export const ASSOCIATE_CATEGORY_SUCCESS = 'deal/ASSOCIATE_CATEGORY_SUCCESS';
export const ASSOCIATE_CATEGORY_FAILURE = 'deal/ASSOCIATE_CATEGORY_FAILURE';
/**
 *  Add internal referrer
 */
export const ADD_INTERNAL_REFERRER_REQUEST = 'deal/ADD_INTERNAL_REFERRER_REQUEST';
export const ADD_INTERNAL_REFERRER_SUCCESS = 'deal/ADD_INTERNAL_REFERRER_SUCCESS';
export const ADD_INTERNAL_REFERRER_FAILURE = 'deal/ADD_INTERNAL_REFERRER_FAILURE';

/**
 *  Add internal referrers
 */
export const ADD_INTERNAL_REFERRERS_REQUEST = 'deal/ADD_INTERNAL_REFERRERS_REQUEST';
export const ADD_INTERNAL_REFERRERS_SUCCESS = 'deal/ADD_INTERNAL_REFERRERS_SUCCESS';
export const ADD_INTERNAL_REFERRERS_FAILURE = 'deal/ADD_INTERNAL_REFERRERS_FAILURE';



/**
 * CLEAR_DEALS actions
 */
export const CLEAR_DEALS = 'deal/CLEAR_DEALS';
/**
 * CLOSE_MANAGE_COLUMN actions
 */
export const CLOSE_MANAGE_COLUMN = 'deal/CLOSE_MANAGE_COLUMN';
/**
 * CONVERT_FOUNDER_TO_EXTERNAL_REFERRER actions
 */
export const CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_REQUEST = 'deal/CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_REQUEST';
export const CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_SUCCESS = 'deal/CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_SUCCESS';
export const CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_FAILURE = 'deal/CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_FAILURE';


/**
 * DEASSOCIATE_CATEGORY actions
 */
export const DEASSOCIATE_CATEGORY_REQUEST = 'deal/DEASSOCIATE_CATEGORY_REQUEST';
export const DEASSOCIATE_CATEGORY_SUCCESS = 'deal/DEASSOCIATE_CATEGORY_SUCCESS';
export const DEASSOCIATE_CATEGORY_FAILURE = 'deal/DEASSOCIATE_CATEGORY_FAILURE';


/**
 * LOAD_ASSIGNEE_LIST actions
 */
export const LOAD_ASSIGNEE_LIST_REQUEST = 'deal/LOAD_ASSIGNEE_LIST_REQUEST';
export const LOAD_ASSIGNEE_LIST_SUCCESS = 'deal/LOAD_ASSIGNEE_LIST_SUCCESS';
export const LOAD_ASSIGNEE_LIST_FAILURE = 'deal/LOAD_ASSIGNEE_LIST_FAILURE';
/**
 * LOAD_BATCH_DEAL actions
 */
export const LOAD_BATCH_DEAL_REQUEST = 'deal/LOAD_BATCH_DEAL_REQUEST';
export const LOAD_BATCH_DEAL_SUCCESS = 'deal/LOAD_BATCH_DEAL_SUCCESS';
export const LOAD_BATCH_DEAL_FAILURE = 'deal/LOAD_BATCH_DEAL_FAILURE';
/**
 * LOAD_CUSTOM_FIELDS actions
 */
export const LOAD_CUSTOM_FIELDS_REQUEST = 'deal/LOAD_CUSTOM_FIELDS_REQUEST';
export const LOAD_CUSTOM_FIELDS_SUCCESS = 'deal/LOAD_CUSTOM_FIELDS_SUCCESS';
export const LOAD_CUSTOM_FIELDS_FAILURE = 'deal/LOAD_CUSTOM_FIELDS_FAILURE';
/**
 * LOAD_DEAL actions
 */
export const LOAD_DEAL_REQUEST = 'deal/LOAD_DEAL_REQUEST';
export const LOAD_DEAL_SUCCESS = 'deal/LOAD_DEAL_SUCCESS';
export const LOAD_DEAL_FAILURE = 'deal/LOAD_DEAL_FAILURE';
/**
 * LOAD_DEAL_LIST actions
 */
export const LOAD_DEAL_LIST_REQUEST = 'deal/LOAD_DEAL_LIST_REQUEST';
export const LOAD_DEAL_LIST_SUCCESS = 'deal/LOAD_DEAL_LIST_SUCCESS';
export const LOAD_DEAL_LIST_FAILURE = 'deal/LOAD_DEAL_LIST_FAILURE';

/**
 * DEAL_ID_LIST actions
 */
export const SET_DEAL_ID_LIST = 'deal/SET_DEAL_ID_LIST';
export const CLEAR_DEAL_ID_LIST = 'deal/CLEAR_DEAL_ID_LIST';
/**
 * LOAD_DEAL_SUGGESTIONS actions
 */
export const LOAD_DEAL_SUGGESTIONS_REQUEST = 'deal/LOAD_DEAL_SUGGESTIONS_REQUEST';
export const LOAD_DEAL_SUGGESTIONS_SUCCESS = 'deal/LOAD_DEAL_SUGGESTIONS_SUCCESS';
export const LOAD_DEAL_SUGGESTIONS_FAILURE = 'deal/LOAD_DEAL_SUGGESTIONS_FAILURE';
/**
 * LOAD_DEAL_TABLE_COLUMNS actions
 */
export const LOAD_DEAL_TABLE_COLUMNS_REQUEST = 'deal/LOAD_DEAL_TABLE_COLUMNS_REQUEST';
export const LOAD_DEAL_TABLE_COLUMNS_SUCCESS = 'deal/LOAD_DEAL_TABLE_COLUMNS_SUCCESS';
export const LOAD_DEAL_TABLE_COLUMNS_FAILURE = 'deal/LOAD_DEAL_TABLE_COLUMNS_FAILURE';
/**
 * LOAD_DEAL_PARTICIPANT_LIST actions
 */
export const LOAD_DEAL_PARTICIPANT_LIST_REQUEST = 'deal/LOAD_DEAL_PARTICIPANT_LIST_REQUEST';
export const LOAD_DEAL_PARTICIPANT_LIST_SUCCESS = 'deal/LOAD_DEAL_PARTICIPANT_LIST_SUCCESS';
export const LOAD_DEAL_PARTICIPANT_LIST_FAILURE = 'deal/LOAD_DEAL_PARTICIPANT_LIST_FAILURE';
/**
 * LOAD_DEAL_EVENT_LIST actions
 */
export const LOAD_DEAL_EVENT_LIST_REQUEST = 'deal/LOAD_DEAL_EVENT_LIST_REQUEST';
export const LOAD_DEAL_EVENT_LIST_SUCCESS = 'deal/LOAD_DEAL_EVENT_LIST_SUCCESS';
export const LOAD_DEAL_EVENT_LIST_FAILURE = 'deal/LOAD_DEAL_EVENT_LIST_FAILURE';
/**
 * LOAD_DEAL_EVENT actions
 */
export const LOAD_DEAL_EVENT_REQUEST = 'deal/LOAD_DEAL_EVENT_REQUEST';
export const LOAD_DEAL_EVENT_SUCCESS = 'deal/LOAD_DEAL_EVENT_SUCCESS';
export const LOAD_DEAL_EVENT_FAILURE = 'deal/LOAD_DEAL_EVENT_FAILURE';
/**
 * LOAD_DEAL_NOTE actions
 */
export const LOAD_DEAL_NOTE_REQUEST = 'deal/LOAD_DEAL_NOTE_REQUEST';
export const LOAD_DEAL_NOTE_SUCCESS = 'deal/LOAD_DEAL_NOTE_SUCCESS';
export const LOAD_DEAL_NOTE_FAILURE = 'deal/LOAD_DEAL_NOTE_FAILURE';

/**
 * DELETE_DEAL_NOTE actions
 */
export const DELETE_DEAL_NOTE_SUCCESS = 'deal/DELETE_DEAL_NOTE_SUCCESS';

 /**
 * LOAD_DEAL_NOTE actions
 */
export const LOAD_PINNED_DEAL_NOTE_REQUEST = 'deal/LOAD_PINNED_DEAL_NOTE_REQUEST';
export const LOAD_PINNED_DEAL_NOTE_SUCCESS = 'deal/LOAD_PINNED_DEAL_NOTE_SUCCESS';
export const LOAD_PINNED_DEAL_NOTE_FAILURE = 'deal/LOAD_PINNED_DEAL_NOTE_FAILURE';

/**
 * LOAD_DEAL_NOTE_LIST actions
 */
export const LOAD_DEAL_NOTE_LIST_REQUEST = 'deal/LOAD_DEAL_NOTE_LIST_REQUEST';
export const LOAD_DEAL_NOTE_LIST_SUCCESS = 'deal/LOAD_DEAL_NOTE_LIST_SUCCESS';
export const LOAD_DEAL_NOTE_LIST_FAILURE = 'deal/LOAD_DEAL_NOTE_LIST_FAILURE';
/**
 * LOAD_DEAL_EVENT_NOTE_LIST actions
 */
export const LOAD_DEAL_EVENT_NOTE_LIST_REQUEST = 'deal/LOAD_DEAL_EVENT_NOTE_LIST_REQUEST';
export const LOAD_DEAL_EVENT_NOTE_LIST_SUCCESS = 'deal/LOAD_DEAL_EVENT_NOTE_LIST_SUCCESS';
export const LOAD_DEAL_EVENT_NOTE_LIST_FAILURE = 'deal/LOAD_DEAL_EVENT_NOTE_LIST_FAILURE';
/**
 * LOAD_DEAL_CATEGORY actions
 */
export const LOAD_DEAL_CATEGORY_REQUEST = 'deal/LOAD_DEAL_CATEGORY_REQUEST';
export const LOAD_DEAL_CATEGORY_SUCCESS = 'deal/LOAD_DEAL_CATEGORY_SUCCESS';
export const LOAD_DEAL_CATEGORY_FAILURE = 'deal/LOAD_DEAL_CATEGORY_FAILURE';
/**
 * LOAD_DEAL_RESOURCE_COUNT actions
 */
export const LOAD_DEAL_RESOURCE_COUNT_REQUEST = 'deal/LOAD_DEAL_RESOURCE_COUNT_REQUEST';
export const LOAD_DEAL_RESOURCE_COUNT_SUCCESS = 'deal/LOAD_DEAL_RESOURCE_COUNT_SUCCESS';
export const LOAD_DEAL_RESOURCE_COUNT_FAILURE = 'deal/LOAD_DEAL_RESOURCE_COUNT_FAILURE';

/**
 * LOAD_DEALS_RESOURCE_COUNT actions
 */
export const LOAD_DEALS_RESOURCE_COUNT_REQUEST = 'deal/LOAD_DEALS_RESOURCE_COUNT_REQUEST';
export const LOAD_DEALS_RESOURCE_COUNT_SUCCESS = 'deal/LOAD_DEALS_RESOURCE_COUNT_SUCCESS';
export const LOAD_DEALS_RESOURCE_COUNT_FAILURE = 'deal/LOAD_DEALS_RESOURCE_COUNT_FAILURE';

//LOAD_DEAL-FILTERS actions
export const SET_DEAL_FILTERS = 'deal/SET_DEAL_FILTERS';

/**
 * OPEN_MANAGE_COLUMN actions
 */
export const OPEN_MANAGE_COLUMN = 'deal/OPEN_MANAGE_COLUMN';


/**
 * REMOVE_ASSIGNEE actions
 */
export const REMOVE_ASSIGNEE_REQUEST = 'deal/REMOVE_ASSIGNEE_REQUEST';
export const REMOVE_ASSIGNEE_SUCCESS = 'deal/REMOVE_ASSIGNEE_SUCCESS';
export const REMOVE_ASSIGNEE_FAILURE = 'deal/REMOVE_ASSIGNEE_FAILURE';
/**
 * REMOVE_EXTERNAL_REFERRER actions
 */
export const REMOVE_EXTERNAL_REFERRER_REQUEST = 'deal/REMOVE_EXTERNAL_REFERRER_REQUEST';
export const REMOVE_EXTERNAL_REFERRER_SUCCESS = 'deal/REMOVE_EXTERNAL_REFERRER_SUCCESS';
export const REMOVE_EXTERNAL_REFERRER_FAILURE = 'deal/REMOVE_EXTERNAL_REFERRER_FAILURE';
/**
 * REMOVE_INTERNAL_REFERRER actions
 */
export const REMOVE_INTERNAL_REFERRER_REQUEST = 'deal/REMOVE_INTERNAL_REFERRER_REQUEST';
export const REMOVE_INTERNAL_REFERRER_SUCCESS = 'deal/REMOVE_INTERNAL_REFERRER_SUCCESS';
export const REMOVE_INTERNAL_REFERRER_FAILURE = 'deal/REMOVE_INTERNAL_REFERRER_FAILURE';
/**
 * REMOVE_PARTICIPANT actions
 */
export const REMOVE_PARTICIPANT_REQUEST = 'deal/REMOVE_PARTICIPANT_REQUEST';
export const REMOVE_PARTICIPANT_SUCCESS = 'deal/REMOVE_PARTICIPANT_SUCCESS';
export const REMOVE_PARTICIPANT_FAILURE = 'deal/REMOVE_PARTICIPANT_FAILURE';
/**
 * REMOVE_STARTUP_FOUNDER actions
 */
export const REMOVE_STARTUP_FOUNDER_REQUEST = 'deal/REMOVE_STARTUP_FOUNDER_REQUEST';
export const REMOVE_STARTUP_FOUNDER_SUCCESS = 'deal/REMOVE_STARTUP_FOUNDER_SUCCESS';
export const REMOVE_STARTUP_FOUNDER_FAILURE = 'deal/REMOVE_STARTUP_FOUNDER_FAILURE';

export const NOTE_ATTACHMENT_DELETE_REQUEST = 'deal/NOTE_ATTACHMENT_DELETE_REQUEST';
export const NOTE_ATTACHMENT_DELETE_SUCCESS = 'deal/NOTE_ATTACHMENT_DELETE_SUCCESS';
export const NOTE_ATTACHMENT_DELETE_FAILURE = 'deal/NOTE_ATTACHMENT_DELETE_FAILURE';

/**
 * TOGGLE_DEAL actions
 */
export const TOGGLE_DEAL_SUMMARY = 'deal/TOGGLE_DEAL_SUMMARY';


/**
 * UPDATE actions
 */
export const UPDATE_REQUEST = 'deal/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'deal/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'deal/UPDATE_FAILURE';

export const UPDATE_PINNED_STATUS_REQUEST = 'deal/UPDATE_PINNED_STATUS_REQUEST';
export const UPDATE_PINNED_STATUS_SUCCESS = 'deal/UPDATE_PINNED_STATUS_SUCCESS';
export const UPDATE_PINNED_STATUS_FAILURE = 'deal/UPDATE_PINNED_STATUS_FAILURE';


/**
 * UPDATE_DEAL_TABLE_COLUMN actions
 */
export const UPDATE_DEAL_TABLE_COLUMN_REQUEST = 'deal/UPDATE_DEAL_TABLE_COLUMN_REQUEST';
export const UPDATE_DEAL_TABLE_COLUMN_SUCCESS = 'deal/UPDATE_DEAL_TABLE_COLUMN_SUCCESS';
export const UPDATE_DEAL_TABLE_COLUMN_FAILURE = 'deal/UPDATE_DEAL_TABLE_COLUMN_FAILURE';
/**
 * Custom actions
 */
export const INCREMENT_DEAL_NOTE_COUNT = 'deal/INCREMENT_DEAL_NOTE_COUNT';

/**
* Delete deal
*/
export const DELETE_DEAL_REQUEST = 'deal/DELETE_DEAL_REQUEST';
export const DELETE_DEAL_SUCCESS = 'deal/DELETE_DEAL_SUCCESS';
export const DELETE_DEAL_FAILURE = 'deal/DELETE_DEAL_FAILURE';


/**
 * UPDATE_DEAL_NOTES
 */
export const UPDATE_DEAL_NOTES = 'deal/UPDATE_DEAL_NOTES';
/**
 * Update deal conversation count
 */
export const INCREMENT_DEAL_CONVERSATION_COUNT = 'deal/INCREMENT_DEAL_CONVERSATION_COUNT';
export const DECREMENT_DEAL_CONVERSATION_COUNT = 'deal/DECREMENT_DEAL_CONVERSATION_COUNT';
export const INCREMENT_DEAL_ATTACHMENT_COUNT = 'deal/INCREMENT_DEAL_ATTACHMENT_COUNT';
export const DECREMENT_DEAL_ATTACHMENT_COUNT = 'deal/DECREMENT_DEAL_ATTACHMENT_COUNT';

/**
 * Load deal table row settings
 */
export const LOAD_DEAL_TABLE_ROW_SETTINGS_REQUEST = 'deal/LOAD_DEAL_TABLE_ROW_SETTINGS_REQUEST';
export const LOAD_DEAL_TABLE_ROW_SETTINGS_SUCCESS = 'deal/LOAD_DEAL_TABLE_ROW_SETTINGS_SUCCESS';
export const LOAD_DEAL_TABLE_ROW_SETTINGS_FAILURE = 'deal/LOAD_DEAL_TABLE_ROW_SETTINGS_FAILURE';

/**
 * UPDATE_TBABLE_ROW_SETTING actions
 */
export const UPDATE_TBABLE_ROW_SETTING_REQUEST = 'deal/UPDATE_TBABLE_ROW_SETTING_REQUEST';
export const UPDATE_TBABLE_ROW_SETTING_SUCCESS = 'deal/UPDATE_TBABLE_ROW_SETTING_SUCCESS';
export const UPDATE_TBABLE_ROW_SETTING_FAILURE = 'deal/UPDATE_TBABLE_ROW_SETTING_FAILURE';


/**
 * CONVERT_TO_PORTFOLIO actions
 */
export const CONVERT_TO_PORTFOLIO_REQUEST = 'deal/CONVERT_TO_PORTFOLIO_REQUEST';
export const CONVERT_TO_PORTFOLIO_SUCCESS = 'deal/CONVERT_TO_PORTFOLIO_SUCCESS';
export const CONVERT_TO_PORTFOLIO_FAILURE = 'deal/CONVERT_TO_PORTFOLIO_FAILURE';

/**
 * UNLINK_FROM_PORTFOLIO actions
 */
export const UNLINK_FROM_PORTFOLIO_REQUEST = 'deal/UNLINK_FROM_PORTFOLIO_REQUEST';
export const UNLINK_FROM_PORTFOLIO_SUCCESS = 'deal/UNLINK_FROM_PORTFOLIO_SUCCESS';
export const UNLINK_FROM_PORTFOLIO_FAILURE = 'deal/UNLINK_FROM_PORTFOLIO_FAILURE';

/**
 * DEAL_CYCLE_REPORT actions
 */
 export const DEAL_CYCLE_REPORT_REQUEST = 'deal/DEAL_CYCLE_REPORT_REQUEST';
 export const DEAL_CYCLE_REPORT_SUCCESS = 'deal/DEAL_CYCLE_REPORT_SUCCESS';
 export const DEAL_CYCLE_REPORT_FAILURE = 'deal/DEAL_CYCLE_REPORT_FAILURE';

/**
 * GET_DEAL_ACTIVITY_LOG actions
 */
 export const GET_DEAL_ACTIVITY_LOG_REQUEST = 'deal/GET_DEAL_ACTIVITY_LOG_REQUEST';
 export const GET_DEAL_ACTIVITY_LOG_SUCCESS = 'deal/GET_DEAL_ACTIVITY_LOG_SUCCESS';
 export const GET_DEAL_ACTIVITY_LOG_FAILURE = 'deal/GET_DEAL_ACTIVITY_LOG_FAILURE';
