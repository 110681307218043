/** @format */
import merge from 'lodash.merge';
import * as types from './actions';
import { uniq } from 'lodash';
import filter from 'lodash.filter';

const initialState = {
  // key is fund id and value is an array of ids of fund notes
  fundNoteList: {},
  // key is fund id and value is the id of the pinned fund note
  pinnedNote: {},
  // key is the note id of a fund note and value is the data
  fundNotes: {},
  // key is fund id and value is boolean
  addFundNoteLoading: {},
  addFundNoteError: {},
  // key is note id and value is boolean
  getFundNoteLoading: {},
  getFundNoteError: {},
  // key is fund id and value is boolean
  getPinnedFundNoteLoading: {},
  getPinnedFundNoteError: {},
  // key is fund id and value is boolean
  getFundNoteListLoading: {},
  getFundNoteListOptions: {},
  getFundNoteListMore: {},
  getFundNoteListError: {},
  // key is note's id
  updateFundNoteLoading: {},
  updateFundNoteError: {},
  // key is note's id
  deleteFundNoteLoading: {},
  deleteFundNoteError: {},

  // states to hold reply data. Key is thread id
  noteReplyList: {},
  noteReplyListLoading: {},
  noteReplyListError: {},
  noteReplyListOptions: {},
  noteReplyListMore: {},

  noteReplyCount: {},
  noteReplyCountLoading: {},
  noteReplyCountError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * Add fund note list reducers
     */
    case types.ADD_FUND_NOTE_REQUEST:
      return Object.assign({}, state, {
        addFundNoteLoading: {
          ...state.addFundNoteLoading,
          [payload.fundId]: true,
        },
        addFundNoteError: {
          ...state.addFundNoteError,
          [payload.fundId]: null,
        },
      });
    case types.ADD_FUND_NOTE_SUCCESS: {
      const shouldAddToFundNoteList = !payload.data.note.parent_id;
      let newFundNoteList = {
        ...state.fundNoteList,
      };
      if (shouldAddToFundNoteList) {
        newFundNoteList = {
          ...newFundNoteList,
          [payload.fundId]:
            state.fundNoteList[payload.fundId] &&
            state.fundNoteList[payload.fundId].length > 0
              ? [
                  // append to the beginning
                  payload.data.id,
                  ...state.fundNoteList[payload.fundId],
                ]
              : [payload.data.id],
        };
      }

      return Object.assign({}, state, {
        fundNoteList: newFundNoteList,
        // add data to the hashmap
        fundNotes: {
          ...state.fundNotes,
          [payload.data.id]: payload.data,
        },
        addFundNoteLoading: {
          ...state.addFundNoteLoading,
          [payload.fundId]: false,
        },
        addFundNoteError: {
          ...state.addFundNoteError,
          [payload.fundId]: null,
        },
      });
    }
    case types.ADD_FUND_NOTE_FAILURE:
      return Object.assign({}, state, {
        addFundNoteLoading: {
          ...state.addFundNoteLoading,
          [payload.fundId]: false,
        },
        addFundNoteError: {
          ...state.addFundNoteError,
          [payload.fundId]: payload.message,
        },
      });

    /**
     * Get fund note reducers
     */
    case types.GET_FUND_NOTE_REQUEST:
      return Object.assign({}, state, {
        getFundNoteLoading: {
          ...state.getFundNoteLoading,
          [payload.noteId]: true,
        },
        getFundNoteError: {
          ...state.getFundNoteError,
          [payload.noteId]: null,
        },
      });
    case types.GET_FUND_NOTE_SUCCESS:
      return Object.assign({}, state, {
        // add data to the hashmap
        fundNotes: {
          ...state.fundNotes,
          [payload.data.id]: payload.data,
        },
        getFundNoteLoading: {
          ...state.getFundNoteLoading,
          [payload.noteId]: false,
        },
        getFundNoteError: {
          ...state.getFundNoteError,
          [payload.noteId]: null,
        },
      });
    case types.GET_FUND_NOTE_FAILURE:
      return Object.assign({}, state, {
        getFundNoteLoading: {
          ...state.getFundNoteLoading,
          [payload.noteId]: false,
        },
        getFundNoteError: {
          ...state.getFundNoteError,
          [payload.noteId]: payload.message,
        },
      });

    /**
     * Get pinned fund note reducers
     */
    case types.GET_PINNED_FUND_NOTE_REQUEST:
      return Object.assign({}, state, {
        getPinnedFundNoteLoading: {
          ...state.getPinnedFundNoteLoading,
          [payload.fundId]: true,
        },
        getPinnedFundNoteError: {
          ...state.getPinnedFundNoteError,
          [payload.fundId]: null,
        },
      });
    case types.GET_PINNED_FUND_NOTE_SUCCESS: {
      const pinnedNoteId = payload.data?.id;
      // if pinned note is present, add its payload to the fundNotes hashmap
      const updatedFundNotes = pinnedNoteId
        ? merge(state.fundNotes, { [payload.data.id]: payload.data })
        : state.fundNotes;
      return Object.assign({}, state, {
        // add data to the hashmap
        fundNotes: updatedFundNotes,
        pinnedNote: {
          ...state.pinnedNote,
          // update reference to the pinned note for this fund
          // if non-existent, this will be undefined
          [payload.fundId]: pinnedNoteId,
        },
        getPinnedFundNoteLoading: {
          ...state.getPinnedFundNoteLoading,
          [payload.fundId]: false,
        },
        getPinnedFundNoteError: {
          ...state.getPinnedFundNoteError,
          [payload.fundId]: null,
        },
      });
    }
    case types.GET_PINNED_FUND_NOTE_FAILURE:
      return Object.assign({}, state, {
        getPinnedFundNoteLoading: {
          ...state.getPinnedFundNoteLoading,
          [payload.fundId]: false,
        },
        getPinnedFundNoteError: {
          ...state.getPinnedFundNoteError,
          [payload.fundId]: payload.message,
        },
      });

    /**
     * Load fund notes reducers
     */
    case types.LIST_FUND_NOTES_REQUEST:
      return Object.assign({}, state, {
        getFundNoteListLoading: {
          ...state.getFundNoteListLoading,
          [payload.fundId]: true,
        },
        getFundNoteListError: {
          ...state.getFundNoteListError,
          [payload.fundId]: null,
        },
      });
    case types.LIST_FUND_NOTES_SUCCESS: {
      const currentFundNoteList =
        state.fundNoteList[payload.fundId] &&
        state.fundNoteList[payload.fundId].length > 0
          ? state.fundNoteList[payload.fundId]
          : [];
      const newFundNoteList = payload.data.map(note => note.note_id);
      return Object.assign({}, state, {
        fundNoteList: {
          ...state.fundNoteList,
          [payload.fundId]:
            // if we are loading first page of results, reset the list, otherwise
            // append to it
            payload.options.page === 1
              ? newFundNoteList
              : uniq([...currentFundNoteList, ...newFundNoteList]),
        },
        // add data to the hashmap
        fundNotes: {
          ...state.fundNotes,
          // create hashmap for returned notes
          ...payload.data.reduce(
            (acc, note) => ({ ...acc, [note.note_id]: note }),
            {}
          ),
        },
        getFundNoteListLoading: {
          ...state.getFundNoteListLoading,
          [payload.fundId]: false,
        },
        getFundNoteListOptions: {
          ...state.getFundNoteListOptions,
          [payload.fundId]: payload.options,
        },
        getFundNoteListMore: {
          ...state.getFundNoteListMore,
          [payload.fundId]: payload.data.length === payload.options.limit,
        },
        getFundNoteListError: {
          ...state.getFundNoteListError,
          [payload.fundId]: null,
        },
      });
    }
    case types.LIST_FUND_NOTES_FAILURE:
      return Object.assign({}, state, {
        getFundNoteListLoading: {
          ...state.getFundNoteListLoading,
          [payload.fundId]: false,
        },
        getFundNoteListError: {
          ...state.getFundNoteListError,
          [payload.fundId]: payload.message,
        },
      });

    /**
     * Update fund note reducers
     */
    case types.UPDATE_FUND_NOTE_REQUEST:
      return Object.assign({}, state, {
        updateFundNoteLoading: {
          ...state.updateFundNoteLoading,
          [payload.noteId]: true,
        },
        updateFundNoteError: {
          ...state.updateFundNoteError,
          [payload.noteId]: null,
        },
      });
    case types.UPDATE_FUND_NOTE_SUCCESS: {
      const newState = Object.assign({}, state, {
        // add data to the hashmap
        fundNotes: {
          ...state.fundNotes,
          [payload.noteId]: merge(
            state.fundNotes[payload.noteId] || {},
            payload.data
          ),
        },
        updateFundNoteLoading: {
          ...state.updateFundNoteLoading,
          [payload.noteId]: false,
        },
        updateFundNoteError: {
          ...state.updateFundNoteError,
          [payload.noteId]: null,
        },
      });
      return newState;
    }
    case types.UPDATE_FUND_NOTE_FAILURE:
      return Object.assign({}, state, {
        updateFundNoteLoading: {
          ...state.updateFundNoteLoading,
          [payload.noteId]: false,
        },
        updateFundNoteError: {
          ...state.updateFundNoteError,
          [payload.noteId]: payload.message,
        },
      });

    /**
     * Delete fund note reducers
     */
    case types.DELETE_FUND_NOTE_REQUEST:
      return Object.assign({}, state, {
        deleteFundNoteLoading: {
          ...state.deleteFundNoteLoading,
          [payload.noteId]: true,
        },
        deleteFundNoteError: {
          ...state.deleteFundNoteError,
          [payload.noteId]: null,
        },
      });
    case types.DELETE_FUND_NOTE_SUCCESS: {
      const copyOfState = Object.assign({}, state, {
        fundNoteList: {
          ...state.fundNoteList,
        },
        // add data to the hashmap
        fundNotes: {
          ...state.fundNotes,
        },
        deleteFundNoteLoading: {
          ...state.deleteFundNoteLoading,
          [payload.noteId]: false,
        },
        deleteFundNoteError: {
          ...state.deleteFundNoteError,
          [payload.noteId]: null,
        },
      });
      copyOfState.fundNoteList[payload.fundId] = filter(
        copyOfState.fundNoteList[payload.fundId],
        noteId => noteId !== payload.noteId
      );
      delete copyOfState.fundNotes[payload.noteId];
      return copyOfState;
    }
    case types.DELETE_FUND_NOTE_FAILURE:
      return Object.assign({}, state, {
        deleteFundNoteLoading: {
          ...state.deleteFundNoteLoading,
          [payload.noteId]: false,
        },
        deleteFundNoteError: {
          ...state.deleteFundNoteError,
          [payload.noteId]: payload.message,
        },
      });

    case types.LOAD_NOTE_REPLY_LIST_REQUEST:
      return Object.assign({}, state, {
        noteReplyListLoading: {
          ...state.noteReplyListLoading,
          [payload.threadId]: true,
        },
        noteReplyListError: {
          ...state.noteReplyListError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_LIST_SUCCESS:
      return Object.assign({}, state, {
        noteReplyList: {
          ...state.noteReplyList,
          [payload.threadId]:
            payload.options.page === 1
              ? payload.data
              : [...state.noteReplyList[payload.threadId], ...payload.data],
        },
        noteReplyListLoading: {
          ...state.noteReplyListLoading,
          [payload.threadId]: false,
        },
        noteReplyListError: {
          ...state.noteReplyListError,
          [payload.threadId]: null,
        },
        noteReplyListOptions: {
          ...state.noteReplyListOptions,
          [payload.threadId]: payload.options,
        },
        noteReplyListMore: {
          ...state.noteReplyListMore,
          [payload.threadId]:
            payload.metadata.length >= payload.options.limit ? true : false,
        },
        noteReplyCount: {
          ...state.noteReplyCount,
          [payload.threadId]: payload.metadata.length,
        },
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: false,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_LIST_FAILURE:
      return Object.assign({}, state, {
        noteReplyListLoading: {
          ...state.noteReplyListLoading,
          [payload.threadId]: false,
        },
        noteReplyListError: {
          ...state.noteReplyListError,
          [payload.threadId]: payload.message,
        },
      });

    case types.LOAD_NOTE_REPLY_COUNT_REQUEST:
      return Object.assign({}, state, {
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: true,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_COUNT_SUCCESS:
      return Object.assign({}, state, {
        noteReplyCount: {
          ...state.noteReplyCount,
          [payload.threadId]: payload.data.length,
        },
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: false,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_COUNT_FAILURE:
      return Object.assign({}, state, {
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: false,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: payload.message,
        },
      });

    default:
      return state;
  }
};
