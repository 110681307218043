/**
 * Fetches portco companies with the v2 api
 *
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_PORTCO_COMPANIES_SUCCESS,
  LOAD_PORTCO_COMPANIES_REQUEST,
  LOAD_PORTCO_COMPANIES_FAILURE,
} from '../actions';

export default options => {
  return async dispatch => {
    dispatch({
      type: LOAD_PORTCO_COMPANIES_REQUEST,
      payload: {
        options: options,
      },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/v2/companies`,
        options,
        dispatch
      );

      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading portfolio companies, please try again.';
      dispatch({
        type: LOAD_PORTCO_COMPANIES_FAILURE,
        payload: message,
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: LOAD_PORTCO_COMPANIES_SUCCESS,
        payload: {
          data: data.data,
          options: options,
        },
      });
      return data;
    } else {
      dispatch({
        type: LOAD_PORTCO_COMPANIES_FAILURE,
        payload: data.message,
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
