/**
 * Associates category to a deal
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  ASSOCIATE_CATEGORY_REQUEST,
  ASSOCIATE_CATEGORY_SUCCESS,
  ASSOCIATE_CATEGORY_FAILURE } from '../actions';

export default (id, category) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ASSOCIATE_CATEGORY_REQUEST
      });
      request.post(`/api/v1/pipelines/${id}/categories/add`, {
        category
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: ASSOCIATE_CATEGORY_SUCCESS });
          resolve();
        } else {
          dispatch({
            type: ASSOCIATE_CATEGORY_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while associating category, please try again.';
        dispatch({
          type: ASSOCIATE_CATEGORY_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
