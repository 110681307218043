/**
 * @format
 */
import {
  LOAD_PIPELINE_DASHBOARDS_FAILURE,
  LOAD_PIPELINE_DASHBOARDS_REQUEST,
  LOAD_PIPELINE_DASHBOARDS_SUCCESS,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case LOAD_PIPELINE_DASHBOARDS_REQUEST:
      return Object.assign({}, state, {
        pipelineDashboardListLoading: true,
        pipelineDashboardListError: null,
        pipelineDashboardListOptions: {
          ...state.pipelineDashboardListOptions,
          ...payload.options,
        },
      });

    case LOAD_PIPELINE_DASHBOARDS_SUCCESS:
      return Object.assign({}, state, {
        pipelineDashboardListLoading: false,
        pipelineDashboardListError: null,
        pipelineDashboards: {
          ...state.pipelineDashboards,
          ...payload.data.reduce((result, item) => {
            result[item.id] = state.pipelineDashboards[item.id]
              ? {
                  ...state.pipelineDashboards[item.id],
                  ...item,
                }
              : item;
            return result;
          }, {}),
        },
        pipelineDashboardListOptions: {
          ...state.pipelineDashboardListOptions,
          ...payload.options,
        },
      });

    case LOAD_PIPELINE_DASHBOARDS_FAILURE:
      return Object.assign({}, state, {
        pipelineDashboardListLoading: false,
        pipelineDashboardListError: payload,
      });

    default:
      return state;
  }
};
