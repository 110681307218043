/**
 * @format
 */

import * as types from '../actions';

import { assign } from '../../../helpers/object';
import reduce from 'lodash.reduce';

export default (state, type, payload) => {
  switch (type) {
    case types.ADD_COMMITMENT_REQUEST: {
      return assign(state, {
        addCommitmentLoading: true,
      });
    }
    case types.ADD_COMMITMENT_FAILURE: {
      return assign(state, {
        addCommitmentLoading: false,
      });
    }
    case types.ADD_COMMITMENT_SUCCESS: {
      const newCommitments = reduce(
        payload.data,
        (acc, result) => {
          if (result.success) {
            // only successful results end with commitment getting added
            const newCommitment = result.data;
            acc[newCommitment.id] = newCommitment;
          }
          return acc;
        },
        {}
      );
      return assign(state, {
        addCommitmentLoading: false,
        // merge existing and new
        distribution: assign(state.commitment, newCommitments),
      });
    }

    default:
      return state;
  }
};
