/**
 * Holds all the deal columns, do not modify keys.
 *
 * To add a new column:
 * - add here
 * - implement appropriate AbcCell container, AbcCell, AbcCell/Viewer, AbcCell/Viewers
 * - add spec in getColumns
 * - add in defaultTableColumnConfig
 * - add transformation in getRowStructure
 * - add support in backend to save new column
 */
const DealSheetColumn = {
  SERIAL_NO:{
    key: 'serial_no',
    sticky: true,
    name: ''
  },
  DEAL: {
    // unique key for the column
    key: 'deal',
    // the columns with `sticky` property set to true are considered fixed,
    // which is mostly first column of the table - deal name
    sticky: true,
    // Name of the column to be displayed
    name: 'Deal'
  },
  STAGE: {
    key: 'stage',
    name: 'Stage'
  },
  PREVIOUS_STAGE: {
    key: 'previous_stage',
    name: 'Previous Stage'
  },
  STATUS: {
    key: 'is_open',
    name: 'Status',
  },
  WORKSPACE: {
    key: 'workspace',
    name: 'Workspace'
  },
  ASSIGNEES: {
    key: 'assignees',
    name: 'Assignees'
  },
  ADDED_BY:{
    key: 'added_by',
    name: 'Added by'
  },
  FIRST_EMAIL_INTERACTION_DATE: {
    key: 'first_email_interaction_date',
    name: 'First Email Interaction Date'
  },
  CREATED_AT: {
    key: 'created_at',
    name: 'Added on'
  },
  LAST_ACTIVE_AT: {
    key: 'last_active_at',
    name: 'Last Active At'
  },
  EXTERNAL_REFERRER: {
    key: 'externalReferrer',
    name: 'External Referrer'
  },
  INTERNAL_REFERRERS: {
    key: 'internalReferrers',
    name: 'Internal Referrers'
  },
  STARTUP_FOUNDERS: {
    key: 'startup_founders',
    name: 'Founders'
  },
  STARTUP_DESCRIPTION: {
    key: 'startup_description',
    name: 'Description'
  },
  STARTUP_REGISTERED_NAME: {
    key: 'startup_registered_name',
    name: 'Registered Name'
  },
  STARTUP_URL: {
    key: 'startup_url',
    name: 'Website'
  },
  STARTUP_EMAIL: {
    key: 'startup_email',
    name: 'Contact Email'
  },
  STARTUP_PHONE_NUMBER: {
    key: 'startup_phone_number',
    name: 'Contact Phone Number'
  },
  STARTUP_OPERATIONAL_STATUS: {
    key: 'startup_operational_status',
    name: 'Operational Status'
  },
  STARTUP_LOCAL_OP_ADDRESS: {
    key: 'startup_local_ops_address',
    name: 'Local Operational Address'
  },
  STARTUP_GLOBAL_OP_ADDRESS: {
    key: 'startup_global_ops_address',
    name: 'Global Operational Address'
  },
  STARTUP_FOUNDING_YEAR: {
    key: 'startup_founding_year',
    name: 'Founding Year'
  },
  STARTUP_EMPLOYEE_COUNT: {
    key: 'startup_employee_count',
    name: 'Employee Count'
  },
  ATTACHMENTS: {
    key: 'attachments',
    name: 'Attachments'
  },
  SOCIAL_LINKS: {
    key: 'social_links',
    name: 'Social links'
  },
  REFERRAL_MEDIUM: {
    key: 'referral_medium',
    name: 'Referral Medium'
  },
  INVESTMENT_REQUESTED: {
    key: 'investment_requested',
    name: 'Investment Requested'
  },
  DEAL_LINK: {
    key: 'deal_link',
    name: 'Deal Link'
  },
};

export default DealSheetColumn;
