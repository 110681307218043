/**
 * Welcome page component
 *
 * @author  Akhila
*/
import Button from '@material-ui/core/Button';
import Loader from '../Loader';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {    
    flexGrow: 1,
    margin: theme.spacing(3),
  },
  header: {
    color: '#379AFF',
    fontFamily: 'Roboto',
    fontSize: '40px',
    fontWeight: 500,
    lineHeight: '40px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px',
    }
  },
  content: {
    margin: '40px auto',
    color: '#4C4F59',
    fontFamily: 'Roboto',
    fontSize: '23px',
    fontWeight: 300,
    lineHeight: '40px'
  }
});

class WelcomePage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    header: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    buttonName: PropTypes.string,
    handleSubmit: PropTypes.func,
    loader: PropTypes.bool.isRequired,
  };

  handleSubmit = event => {
    this.props.handleSubmit();
  };

  render() {
    const { classes, header, children, buttonName, handleSubmit } = this.props;
    const { loader } = this.props;
    return (
      <div className={classes.root}>
        <Loader active={loader} />
        <div className={classes.header}>
          {header}
        </div>
        <div className={classes.content}>
          {children}
        </div>
        { buttonName && handleSubmit && (
          <Button 
            size="large" 
            variant="contained" 
            color="primary"
            onClick={this.handleSubmit}
            disabled={loader}
          >
            {buttonName}
          </Button>
        )}
      </div> 
    );
  }
}

export default withStyles(styles)(WelcomePage);

