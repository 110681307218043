/**
 * Add assignee on a sign off
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  ADD_SIGN_OFF_ASSIGNEE_REQUEST,
  ADD_SIGN_OFF_ASSIGNEE_SUCCESS,
  ADD_SIGN_OFF_ASSIGNEE_FAILURE,
} from '../actions';

export default (form, sign_off_id, sign_off_group_id, deal_id) => {
  return async dispatch => {
    dispatch({
      type: ADD_SIGN_OFF_ASSIGNEE_REQUEST,
      payload: {
        signOffId: sign_off_id,
      },
    });

    let data;
    try {
      const response = await request.post(
        `/api/pipeline/sign-offs/v1/deal/${deal_id}/sign-off-group/${sign_off_group_id}/sign-off/${sign_off_id}/assignees`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while adding an assignee, please try again.';
      dispatch({
        type: ADD_SIGN_OFF_ASSIGNEE_FAILURE,
        payload: {
          signOffId: sign_off_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: ADD_SIGN_OFF_ASSIGNEE_SUCCESS,
        payload: {
          signOffId: sign_off_id,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: ADD_SIGN_OFF_ASSIGNEE_FAILURE,
        payload: {
          signOffId: sign_off_id,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
