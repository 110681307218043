/**
 * dealflowAnalyticsflow analytics actions
 */

/**
 * GET dealflowAnalyticsflow analytics dashboards
 * to fetch the pipeline dashboards 
 */
export const LOAD_PIPELINE_DASHBOARDS_REQUEST = 'dealflowAnalytics/GET_DASHBOARDS_REQUEST';
export const LOAD_PIPELINE_DASHBOARDS_SUCCESS = 'dealflowAnalytics/GET_DASHBOARDS_SUCCESS';
export const LOAD_PIPELINE_DASHBOARDS_FAILURE = 'dealflowAnalytics/GET_DASHBOARDS_FAILURE';

/**
 * Get pipeline dashboard w/ components
 */
export const GET_PIPELINE_DASHBOARD_REQUEST = 'dealflowAnalytics/GET_PIPELINE_DASHBOARD_REQUEST';
export const GET_PIPELINE_DASHBOARD_SUCCESS = 'dealflowAnalytics/GET_PIPELINE_DASHBOARD_SUCCESS';
export const GET_PIPELINE_DASHBOARD_FAILURE = 'dealflowAnalytics/GET_PIPELINE_DASHBOARD_FAILURE';

/**
 * Add chart to pipeline dashboard reducers
 */
export const ADD_CHART_TO_PIPELINE_DASHBOARD_REQUEST = 'dealflowAnalytics/ADD_CHART_TO_PIPELINE_DASHBOARD_REQUEST';
export const ADD_CHART_TO_PIPELINE_DASHBOARD_SUCCESS = 'dealflowAnalytics/ADD_CHART_TO_PIPELINE_DASHBOARD_SUCCESS';
export const ADD_CHART_TO_PIPELINE_DASHBOARD_FAILURE = 'dealflowAnalytics/ADD_CHART_TO_PIPELINE_DASHBOARD_FAILURE';