import { PORTCO_CLOSE_MANAGE_COLUMN } from "../actions";

export default (state, type) => {
  switch(type) {
    case PORTCO_CLOSE_MANAGE_COLUMN:
      return Object.assign({}, state, {
        manageColumnOpen: false
      });

    default:
      return state;
  }
};