/**
 * @format
 */
import request from '../../../helpers/request';
import setTableRowsettingsCache from '../../../helpers/setTableRowsettingsCache';
import { enqueueNotification } from '../../app';
import {
  LOAD_DEAL_TABLE_ROW_SETTINGS_REQUEST,
  LOAD_DEAL_TABLE_ROW_SETTINGS_SUCCESS,
  LOAD_DEAL_TABLE_ROW_SETTINGS_FAILURE,
} from '../actions';

export default (dealsRowSettings, workspace_id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LOAD_DEAL_TABLE_ROW_SETTINGS_REQUEST,
      });
      request
        .put(
          `/api/v5/organizations/deal_table_row_settings`,
          {
            dealsRowSettings,
            workspace_id,
          },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: LOAD_DEAL_TABLE_ROW_SETTINGS_SUCCESS,
              payload: dealsRowSettings,
            });
            setTableRowsettingsCache(dealsRowSettings, workspace_id);
            resolve(data);
          } else {
            dispatch({
              type: LOAD_DEAL_TABLE_ROW_SETTINGS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while setting row height, please try again.';
          dispatch({
            type: LOAD_DEAL_TABLE_ROW_SETTINGS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
