/** @format */

export const ADD_PORTFOLIO_UPDATE_REQUEST =
  'portfolio-update/ADD_PORTFOLIO_UPDATE_REQUEST';
export const ADD_PORTFOLIO_UPDATE_SUCCESS =
  'portfolio-update/ADD_PORTFOLIO_UPDATE_SUCCESS';
export const ADD_PORTFOLIO_UPDATE_FAILURE =
  'portfolio-update/ADD_PORTFOLIO_UPDATE_FAILURE';

export const GET_PORTFOLIO_UPDATE_LIST_REQUEST =
  'portfolio-update/GET_PORTFOLIO_UPDATE_LIST_REQUEST';
export const GET_PORTFOLIO_UPDATE_LIST_SUCCESS =
  'portfolio-update/GET_PORTFOLIO_UPDATE_LIST_SUCCESS';
export const GET_PORTFOLIO_UPDATE_LIST_FAILURE =
  'portfolio-update/GET_PORTFOLIO_UPDATE_LIST_FAILURE';

export const GET_PORTFOLIO_UPDATE_REQUEST =
  'portfolio-update/GET_PORTFOLIO_UPDATE_REQUEST';
export const GET_PORTFOLIO_UPDATE_SUCCESS =
  'portfolio-update/GET_PORTFOLIO_UPDATE_SUCCESS';
export const GET_PORTFOLIO_UPDATE_FAILURE =
  'portfolio-update/GET_PORTFOLIO_UPDATE_FAILURE';

export const UPDATE_PINNED_STATUS_REQUEST =
  'portfolio/UPDATE_PINNED_STATUS_REQUEST';
export const UPDATE_PINNED_STATUS_SUCCESS =
  'portfolio/UPDATE_PINNED_STATUS_SUCCESS';
export const UPDATE_PINNED_STATUS_FAILURE =
  'portfolio/UPDATE_PINNED_STATUS_FAILURE';

export const GET_PORTCO_PINNNED_UPDATE_REQUEST =
  'portfolio/GET_PORTCO_PINNNED_UPDATE_REQUEST';
export const GET_PORTCO_PINNED_UPDATE_SUCCESS =
  'portfolio/GET_PORTCO_PINNED_UPDATE_SUCCESS';
export const GET_PORTCO_PINNED_UPDATE_FAILURE =
  'portfolio/GET_PORTCO_PINNED_UPDATE_FAILURE';

export const UPDATE_PORTFOLIO_UPDATE_REQUEST =
  'portfolio-update/UPDATE_PORTFOLIO_UPDATE_REQUEST';
export const UPDATE_PORTFOLIO_UPDATE_SUCCESS =
  'portfolio-update/UPDATE_PORTFOLIO_UPDATE_SUCCESS';
export const UPDATE_PORTFOLIO_UPDATE_FAILURE =
  'portfolio-update/UPDATE_PORTFOLIO_UPDATE_FAILURE';

export const DELETE_PORTFOLIO_UPDATE_REQUEST =
  'portfolio-update/DELETE_PORTFOLIO_UPDATE_REQUEST';
export const DELETE_PORTFOLIO_UPDATE_SUCCESS =
  'portfolio-update/DELETE_PORTFOLIO_UPDATE_SUCCESS';
export const DELETE_PORTFOLIO_UPDATE_FAILURE =
  'portfolio-update/DELETE_PORTFOLIO_UPDATE_FAILURE';

export const TRANSCRIBE_UPDATE_REQUEST =
  'portfolio-update/TRANSCRIBE_UPDATE_REQUEST';
export const TRANSCRIBE_UPDATE_SUCCESS =
  'portfolio-update/TRANSCRIBE_UPDATE_SUCCESS';
export const TRANSCRIBE_UPDATE_FAILURE =
  'portfolio-update/TRANSCRIBE_UPDATE_FAILURE';
