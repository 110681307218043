const getCategoryMap = (nestedCategories, path) => {
  if (!nestedCategories || nestedCategories.length < 1) return {};
  //
  // keeps the resultant category map, the structure is as-
  // 
  // ```
  //  {
  //    <categoryNameWithPath>: categoryName
  //  }
  // ```
  let categoryMap = {};
  nestedCategories.forEach(({ name, children }) => {
    const key = path ? `${path} / ${name}` : name;
    categoryMap[key] = name;
    const childrenMap = getCategoryMap(children, key);
    categoryMap = { ...categoryMap, ...childrenMap };
  });
  return categoryMap;
};

export default getCategoryMap;
