import * as types from './actions';

const initialState = {
  /**
   * Holds Conversation List, key here is - deal id
   */
  dealConversations: {},
  dealConversationsLoading: {},
  dealConversationsError: {},
  dealConversationsOptions: {},
  dealConversationsMore: {},



  /**
   * Holds individual conversation of each dealConversations(key is deal id)
   * and key is - conversation id.
   *
   * TODO:
   * https://gitlab.com/taghash/valence-v2/merge_requests/109#note_118195673
   * And corresponding issue -
   * https://gitlab.com/taghash/nucleus/issues/660
   */
  conversation: {},
  conversationLoading: {},
  conversationError: {},

  /**
   * Key is - conversation id.
   */
  addReplyLoading: {},
  addReplyError: {},

  /**
   * Key is - deal id
   */
  createConversationLoading: {},
  createConversationError: {},

  /**
   * Holds participants of each conversation, key is - conversation id.
   */
  conversationParticipantList: {},
  conversationParticipantListLoading: {},
  conversationParticipantListError: {},

  /**
   * Key is - conversation id.
   */
  rollbackConversationLoading: {},
  rollbackConversationError: {},

  /**
   * Key for starring email conversation
   * 
   */
  dealConversationStarredLoading:{},
  dealConversationStarredError:{},
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
     * Conversation list reducers
     */
    case types.LOAD_CONVERSATION_LIST_REQUEST:
      return Object.assign({}, state, {
        dealConversationsLoading: {
          ...state.dealConversationsLoading,
          [payload.dealId]: true
        },
        dealConversationsError: {
          ...state.dealConversationsError,
          [payload.dealId]: null
        }
      });
    case types.LOAD_CONVERSATION_LIST_SUCCESS:
      return Object.assign({}, state, {
        dealConversations: {
          ...state.dealConversations,
          [payload.dealId]: (
            (payload.options.page === 1) ?
              payload.data : [...state.dealConversations[payload.dealId], ...payload.data]
          )},
        dealConversationsLoading: {
          ...state.dealConversationsLoading,
          [payload.dealId]: false
        },
        dealConversationsError: {
          ...state.dealConversationsError,
          [payload.dealId]: null
        },
        dealConversationsOptions: {
          ...state.dealConversationsOptions,
          [payload.dealId]: payload.options
        },
        dealConversationsMore: {
          ...state.dealConversationsMore,
          [payload.dealId]: (payload.data.length >= payload.options.limit ? true : false)
        },
        conversationParticipantList: {
          ...state.conversationParticipantList,
          // only if withParticipants option is true, participants would have
          // been returned in the api
          ...(payload.data && payload.options.withParticipants
            ? payload.data.reduce((acc, conversation) => {
              if(conversation.participantsAndLevels && conversation.participantsAndLevels.length >0){
                acc[conversation.id] = conversation.participantsAndLevels;

              } else {
                acc[conversation.id] = conversation.participants;
              }
              return acc;
            }, {})
            : {})
        }
      });
    case types.LOAD_CONVERSATION_LIST_FAILURE:
      return Object.assign({}, state, {
        dealConversationsLoading: {
          ...state.dealConversationsLoading,
          [payload.dealId]: false
        },
        dealConversationsError: {
          ...state.dealConversationsError,
          [payload.dealId]: payload.message
        },
      });

    /**
     * Conversation reducer
     */
    case types.LOAD_CONVERSATION_REQUEST:
      return Object.assign({}, state, {
        conversationLoading: {
          ...state.conversationLoading,
          [payload.conversationId]: true
        },
        conversationError: {
          ...state.conversationError,
          [payload.conversationId]: null
        }
      });
    case types.LOAD_CONVERSATION_SUCCESS:
      return Object.assign({}, state, {
        conversation: {
          ...state.conversation,
          [payload.conversationId]: payload.data
        },
        conversationLoading: {
          ...state.conversationLoading,
          [payload.conversationId]: false
        },
        conversationError: {
          ...state.conversationError,
          [payload.conversationId]: null
        }
      });
    case types.LOAD_CONVERSATION_FAILURE:
      return Object.assign({}, state, {
        conversationLoading: {
          ...state.conversationLoading,
          [payload.conversationId]: false
        },
        conversationError: {
          ...state.conversationError,
          [payload.conversationId]: payload.message
        },
      });

    /**
     * Conversation participant list reducer
     */
    case types.LOAD_PARTICIPANT_LIST_REQUEST:
      return Object.assign({}, state, {
        conversationParticipantListLoading: {
          ...state.conversationParticipantListLoading,
          [payload.conversationId]: true,
        },
        conversationParticipantListError: {
          ...state.conversationParticipantListError,
          [payload.conversationId]:null
        }
      });
    case types.LOAD_PARTICIPANT_LIST_SUCCESS:
      return Object.assign({}, state, {
        conversationParticipantList: {
          ...state.conversationParticipantList,
          [payload.conversationId]: payload.data
        },
        conversationParticipantListLoading: {
          ...state.conversationParticipantListLoading,
          [payload.conversationId]: false
        },
        conversationParticipantListError: {
          ...state.conversationParticipantListError,
          [payload.conversationId]: null
        }
      });
    case types.LOAD_PARTICIPANT_LIST_FAILURE:
      return Object.assign({}, state, {
        conversationParticipantListLoading: {
          ...state.conversationParticipantListLoading,
          [payload.conversationId]: false
        },
        conversationParticipantListError: {
          ...state.conversationParticipantListError,
          [payload.conversationId]: payload.message
        }
      });

    /**
     * Deal conversation starring reducer
     */
    case types.TOGGLE_CONVERSATION_AS_STARRED_REQUEST:
      return Object.assign({},state,{
        dealConversationStarredLoading:{
          ...state.dealConversationStarredLoading,
         [payload.conversationId]:true
        },
        dealConversationStarredError:{
          ...state.dealConversationStarredError,
          [payload.conversationId]: null
        }
      });
    case types.TOGGLE_CONVERSATION_AS_STARRED_FAILURE:
      return Object.assign({}, state,{
        dealConversationStarredLoading:{
          ...state.dealConversationStarredLoading,
         [payload.conversationId]:false
        },
        dealConversationStarredError:{
          ...state.dealConversationStarredError,
          [payload.conversationId]: payload.message
        }
      })
    case types.TOGGLE_CONVERSATION_AS_STARRED_SUCCESS:
      return Object.assign({}, state,{
        dealConversationStarredLoading:{
          ...state.dealConversationStarredLoading,
         [payload.conversationId]:false
        },
        dealConversationStarredError:{
          ...state.dealConversationStarredError,
          [payload.conversationId]: null
        },
        dealConversations: {
          ...state.dealConversations,
          //All convvos in this deal
          [payload.dealId]:[
            ...(state.dealConversations[payload.dealId] || []).map(convo  => {
              if(convo.id === payload.conversationId){
                convo.is_starred = payload.is_starred;
              }
              return convo
            }),
           
          ]
        },
        conversation: {
          ...state.conversation,
          [payload.conversationId]: {
            ...state.conversation[payload.conversationId],
            is_starred: payload.is_starred
          }
        },
      })
    

    /**
     * Create conversation reducer
     */
    case types.CREATE_CONVERSATION_REQUEST:
      return Object.assign({}, state, {
        createConversationLoading: {
          ...state.createConversationLoading,
          [payload.dealId]: true
        },
        createConversationError: {
          ...state.createConversationError,
          [payload.dealId]: null
        }
      });
    case types.CREATE_CONVERSATION_SUCCESS:
      return Object.assign({}, state, {
        createConversationLoading: {
          ...state.createConversationLoading,
          [payload.dealId]: false
        },
        dealConversations: {
          ...state.dealConversations,
          [payload.dealId]: [payload.data,
            ...(state.dealConversations[payload.dealId] ? state.dealConversations[payload.dealId] : [])
            ]
          },
        createConversationError: {
          ...state.createConversationError,
          [payload.dealId]: null
        }
      });
    case types.CREATE_CONVERSATION_FAILURE:
      return Object.assign({}, state, {
        createConversationLoading: {
          ...state.createConversationLoading,
          [payload.dealId]: false
        },
        createConversationError: {
          ...state.createConversationError,
          [payload.dealId]: payload.message
        }
      });

    /**
     * Rollback conversation reducer
     */
    case types.ROLLBACK_CONVERSATION_REQUEST:
      return Object.assign({}, state, {
        rollbackConversationLoading: {
          ...state.rollbackConversationLoading,
          [payload.conversationId]: true
        },
        rollbackConversationError: {
          ...state.rollbackConversationError,
          [payload.conversationId]: null
        }
      });
    case types.ROLLBACK_CONVERSATION_SUCCESS:
      return Object.assign({}, state, {
        rollbackConversationLoading: {
          ...state.rollbackConversationLoading,
          [payload.conversationId]: false
        },
        rollbackConversationError: {
          ...state.rollbackConversationError,
          [payload.conversationId]: null
        },
      });
    case types.ROLLBACK_CONVERSATION_FAILURE:
      return Object.assign({}, state, {
        rollbackConversationLoading: {
          ...state.rollbackConversationLoading,
          [payload.conversationId]: false
        },
        rollbackConversationError: {
          ...state.rollbackConversationError,
          [payload.conversationId]: payload.message
        }
      });

    default:
      return state;
  }
};
