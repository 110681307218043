/**
 * Settings reducers
 *
 * @author Ritesh Shrivastav
 */
import * as types from './actions';
import {
  NOTIFICATIONS_EMAIL_UNREAD_SUMMARY,
  NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU,
  NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_SETTING_KEY,
  DEAL_HIDE_ACTIVITY_LOG,
  FLAG_FULL_SYNC_GMAIL
} from '../../constants/UserSetting';
import { CUBEJS_SCHEMA_VERSION } from '../../constants/OrganizationSetting';

const initialState = {
  googleSetting: null,
  googleSettingLoading: false,
  googleSettingError: null,

  microsoftSetting: null,
  microsoftSettingLoading: false,
  microsoftSettingError: null,

  updateProfileLoading: false,
  updateProfileError: null,

  saveStageLoading: false,
  saveStageError: null,

  googleConnectLoading: false,
  googleConnectError: null,

  microsoftConnectLoading: false,
  microsoftConnectError: null,

  dealViewType: null,
  dealViewTypeLoading: false,

  portfolioViewType: null,
  portfolioViewTypeLoading: false,

  /**
   * Holds user's settings as key: value pairs
   * NOTE: In some cases, complex values are stored as JSON strings in backend
   * and might need to be parsed to use it meaningfully
   */
  settings: {},
  /**
   * Holds the loading status of each user settting key
   */
  settingsLoading: {},
  /**
   * Holds the error status of each user settting key
   */
  settingsError: {},

  /**
   * Holds organization's settings as key: value pairs
   * NOTE: In some cases, complex values are stored as values
   */
  organizationSettings: {},
  /**
   * Holds the loading status of each organization settting key
   */
  organizationSettingsLoading: {},
  /**
   * Holds the error status of each organization settting key
   */
  organizationSettingsError: {},

  /**
   * Loading status of request to update notification email summary setting
   * @type {Boolean}
   */
  updateUnreadNotificationsSummaryLoading: false,
  /**
   * Error message if any, in the request to update notification email summary
   * setting
   * @type {String}
   */
  updateUnreadNotificationsSummaryError: null,

  /**
   * Loading status of request to update notification email on deal assignment
   * setting
   * @type {Boolean}
   */
  updateNotificationEmailAssignedPrefLoading: false,
  /**
   * Error message if any, in the request to update notification email on deal
   * assignment setting
   * @type {String}
   */
  updateNotificationEmailAssignedPrefError: null,

  /**
   * Loading status of request to update signals summary notification email
   * setting
   * @type {Boolean}
   */
  updateNotificationEmailSignalsSummaryLoading: false,


  /**
   * Error message if any, in the request to update signals summary notification
   * email setting
   * @type {String}
   */
  updateNotificationEmailSignalsSummaryError: null,

   /**
   * Loading status of request to update reminder gcal toggle button
   * setting
   * @type {Boolean}
   */
  updateReminderGcalToggleLoading: false,

  /**
   * Error message if any, in the request to update reminder gcal toggle button
   * email setting
   * @type {String}
   */
  updateReminderGcalToggleError: null,

  /**
   * Loading status of request to update flag full sync gmail
   * setting
   * @type {Boolean}
   */
   updateFlagFullSyncGmailLoading: false,

   /**
    * Error message if any, in the request to update flag full sync gmail
    * email setting
    * @type {String}
    */
   updateFlagFullSyncGmailError: null,

  /**
   * Loading status for updating cubejs schema version
   * @type {Boolean}
   */
  updateCubejsSchemaVersionLoading: false,
  /**
   * Error message in the request to update CubehsSchemaVersion
   * @type {String}
   */
  updateCubejsSchemaVersionError: null,

   /**
   * Loading status of request to update deal activity log show / hide toggle
   * setting
   * @type {Boolean}
   */
  updateDealActivityLogSettingLoading: false,

  /**
   * Error message if any, in the request to update deal activity log show / hide toggle
   * email setting
   * @type {String}
   */
  updateDealActivityLogSettingError: null,


};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
     * Update dealViewType
     */
    case types.UPDATE_DEAL_VIEW_TYPE_REQUEST:
      return Object.assign({}, state, {
        dealViewType: payload
      });
    case types.UPDATE_DEAL_VIEW_TYPE_SUCCESS:
    case types.UPDATE_DEAL_VIEW_TYPE_FAILURE:
      return state;

    /**
     * Update portfolioViewType
     */
    case types.UPDATE_PORTFOLIO_VIEW_TYPE_REQUEST:
      return Object.assign({}, state,{
        portfolioViewType: payload
      });
    case types.UPDATE_PORTFOLIO_VIEW_TYPE_SUCCESS:
    case types.UPDATE_PORTFOLIO_VIEW_TYPE_FAILURE:
      return state;

    /**
     * Load dealViewType
     */
    case types.LOAD_DEAL_VIEW_TYPE_REQUEST:
      return Object.assign({}, state, {
        dealViewTypeLoading: true
      });
    case types.LOAD_DEAL_VIEW_TYPE_SUCCESS:
      return Object.assign({}, state, {
        dealViewType: payload,
        dealViewTypeLoading: false
      });
    case types.LOAD_DEAL_VIEW_TYPE_FAILURE:
      return Object.assign({}, state, {
        dealViewTypeLoading: false
      });

    /**
     * Load portfolioViewType
    */
    case types.LOAD_PORTFOLIO_VIEW_TYPE_REQUEST:
      return Object.assign({},state,{
        portfolioViewTypeLoading: true
      });
    case types.LOAD_PORTFOLIO_VIEW_TYPE_SUCCESS:
      return Object.assign({}, state, {
        portfolioViewType: payload,
        portfolioViewTypeLoading: false
      });
    case types.LOAD_PORTFOLIO_VIEW_TYPE_FAILURE:
      return Object.assign({}, state, {
        portfolioViewTypeLoading: false
      });

    /**
     * Get google setting reducers
     */
    case types.FETCH_GOOGLE_SETTING_REQUEST:
      return Object.assign({}, state, {
        googleSettingLoading: true,
        googleSettingError: null
      });
    case types.FETCH_GOOGLE_SETTING_SUCCESS:
      return Object.assign({}, state, {
        googleSetting: payload,
        googleSettingLoading: false,
        googleSettingError: null,
      });
    case types.FETCH_GOOGLE_SETTING_FAILURE:
      return Object.assign({}, state, {
        googleSettingLoading: false,
        googleSettingError: payload,
      });

    /**
     *  Get Microsoft Setting Reducers
     */
      case types.FETCH_MICROSOFT_SETTING_REQUEST:
        return Object.assign({},state, {
          microsoftSettingLoading:true,
          microsoftSettingError: null
        });
      case types.FETCH_MICROSOFT_SETTING_SUCCESS:
        return Object.assign({},state,{
          microsoftSetting: payload,
          microsoftSettingLoading: false,
          microsoftSettingError: null
        })
      case types.FETCH_MICROSOFT_SETTING_FAILURE:
        return Object.assign({},state,{
          microsoftSettingError: payload,
          microsoftSettingLoading: false
        })
    /**
     * Update profile reducers
     */
    case types.UPDATE_PROFILE_REQUEST:
      return Object.assign({}, state, {
        updateProfileLoading: true,
        updateProfileError: null
      });
    case types.UPDATE_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        updateProfileLoading: false,
        updateProfileError: null,
      });
    case types.UPDATE_PROFILE_FAILURE:
      return Object.assign({}, state, {
        updateProfileLoading: false,
        updateProfileError: payload,
      });

    /**
    * Save stages
    */
    case types.SAVE_STAGE_REQUEST:
      return Object.assign({}, state, {
        saveStageLoading: true,
        saveStageError: null
      });
    case types.SAVE_STAGE_SUCCESS:
      return Object.assign({}, state, {
        saveStageLoading: false,
        saveStageError: null,
      });
    case types.SAVE_STAGE_FAILURE:
      return Object.assign({}, state, {
        saveStageLoading: false,
        saveStageError: payload
      });

    /**
     * Google connect reducers
     */
    case types.GOOGLE_CONNECT_REQUEST:
      return Object.assign({}, state, {
        googleConnectLoading: true,
        googleConnectError: null
      });
    case types.GOOGLE_CONNECT_SUCCESS:
      return Object.assign({}, state, {
        googleConnectLoading: false,
        googleConnectError: null,
      });
    case types.GOOGLE_CONNECT_FAILURE:
      return Object.assign({}, state, {
        googleConnectLoading: false,
        googleConnectError: payload,
      });

    /**
     *  Microsoft Connect Reducers
     */
    case types.MICROSOFT_CONNECT_REQUEST:
      return Object.assign({},state,{
        microsoftConnectLoading: true,
        microsoftConnectError: null
      });
    case types.MICROSOFT_CONNECT_SUCCESS:
      return Object.assign({},state,{
        microsoftConnectLoading: false,
        microsoftConnectError: null
      });
    case types.MICROSOFT_CONNECT_FAILURE:
      return Object.assign({},state,{
        microsoftConnectLoading: false,
        microsoftConnectError: payload
      })
    // We do this in the background, and don't care about success/failure states,
    // so we do nothing here
    case types.UPDATE_FCM_REGISTRATION_TOKENS_REQUEST:
    case types.UPDATE_FCM_REGISTRATION_TOKENS_SUCCESS:
    case types.UPDATE_FCM_REGISTRATION_TOKENS_FAILURE:
      return state;

    /**
     * Load setting by key reducers
     */
    case types.LOAD_SETTING_VALUE_BY_KEY_REQUEST:
      return Object.assign({}, state, {
        settingsLoading: {
          ...state.settingsLoading,
          [payload.key]: true
        },
        settingsError: {
          ...state.settingsError,
          [payload.key]: null
        },
      });
    case types.LOAD_SETTING_VALUE_BY_KEY_SUCCESS: {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/switch#Block-scope_variables_within_switch_statements
      let copyOfState = {...state};
      // deleting loading and error since we don't need it anymore and we no
      // longer want to keep it around in memory
      delete copyOfState.settingsLoading[payload.key];
      delete copyOfState.settingsError[payload.key];
      return Object.assign({}, state, {
        ...copyOfState, // if you don't do this, the deletions above are useless
        settings: {
          ...state.settings,
          [payload.key]: payload.value
        },
      });
    }
    case types.LOAD_SETTING_VALUE_BY_KEY_FAILURE: {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/switch#Block-scope_variables_within_switch_statements
      let copyOfState = {...state};
      // deleting loading state since we don't need it anymore and we no
      // longer want to keep it around in memory
      delete copyOfState.settingsLoading[payload.key];
      return Object.assign({}, state, {
        ...copyOfState, // if you don't do this, the deletions above are useless
        settingsError: {
          ...state.settingsError,
          [payload.key]: payload.message
        },
      });
    }

    case types.UPDATE_SETTING_VALUE_BY_KEY_REQUEST:{
      return Object.assign({}, state, {
        ...state,
        settingsLoading:{
          ...state.settingsLoading,
          [payload.key]: true,
        },
        settingsError:{
          ...state.settingsError,
          [payload.key]: null
        }
      });
    }

    case types.UPDATE_SETTING_VALUE_BY_KEY_SUCCESS:{
      return Object.assign({}, state,{
        ...state,
        settingsLoading:{
          ...state.settingsLoading,
          [payload.key]: false,
        },
        settingsError:{
          ...state.settingsError,
          [payload.key]: null
        },
        settings:{
          ...state.settings,
          [payload.key]: payload.data
        }
      });
    }

    case types.UPDATE_SETTING_VALUE_BY_KEY_FAILURE:{
      return Object.assign({}, state,{
        ...state,
        settingsLoading:{
          ...state.settingsLoading,
          [payload.key]: false
        },
        settingsError:{
          ...state.settingsError,
          [payload.key]: payload.message,
        }
      });
    }

    /**
     * Load organization setting by key reducers
     */
    case types.LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_REQUEST:
    case types.UPDATE_ORGANIZATION_SETTING_BY_KEY_REQUEST:
      return Object.assign({}, state, {
        organizationSettingsLoading: {
          ...state.organizationSettingsLoading,
          [payload.key]: true
        },
        organizationSettingsError: {
          ...state.organizationSettingsError,
          [payload.key]: null
        },
      });
    case types.LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_SUCCESS:
    case types.UPDATE_ORGANIZATION_SETTING_BY_KEY_SUCCESS: {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/switch#Block-scope_variables_within_switch_statements
      let copyOfState = {...state};
      // deleting loading and error since we don't need it anymore and we no
      // longer want to keep it around in memory
      delete copyOfState.organizationSettingsLoading[payload.key];
      delete copyOfState.organizationSettingsError[payload.key];
      return Object.assign({}, state, {
        ...copyOfState, // if you don't do this, the deletions above are useless
        organizationSettings: {
          ...state.organizationSettings,
          [payload.key]: payload.value
        },
      });
    }
    case types.LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_FAILURE:
    case types.UPDATE_ORGANIZATION_SETTING_BY_KEY_FAILURE: {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/switch#Block-scope_variables_within_switch_statements
      let copyOfState = {...state};
      // deleting loading state since we don't need it anymore and we no
      // longer want to keep it around in memory
      delete copyOfState.organizationSettingsLoading[payload.key];
      return Object.assign({}, state, {
        ...copyOfState, // if you don't do this, the deletions above are useless
        organizationSettingsError: {
          ...state.organizationSettingsError,
          [payload.key]: payload.message
        },
      });
    }

    /**
     * Update unread notifications email summary settings reducers
     */
    case types.UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_REQUEST:
      return Object.assign({}, state, {
        updateUnreadNotificationsSummaryLoading: true,
        updateUnreadNotificationsSummaryError: null
      });
    case types.UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        updateUnreadNotificationsSummaryLoading: false,
        updateUnreadNotificationsSummaryError: null,
        settings: {
          ...state.settings,
          // Update setting's value in local state
          [NOTIFICATIONS_EMAIL_UNREAD_SUMMARY]: payload
        }
      });
    case types.UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_FAILURE:
      return Object.assign({}, state, {
        updateUnreadNotificationsSummaryLoading: false,
        updateUnreadNotificationsSummaryError: payload
      });

    /**
     * Update assigned-to-you notifications email settings reducers
     */
    case types.UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_REQUEST:
      return Object.assign({}, state, {
        updateNotificationEmailAssignedPrefLoading: true,
        updateNotificationEmailAssignedPrefError: null
      });
    case types.UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_SUCCESS:
      return Object.assign({}, state, {
        updateNotificationEmailAssignedPrefLoading: false,
        updateNotificationEmailAssignedPrefError: null,
        settings: {
          ...state.settings,
          // Update setting's value in local state
          [NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU]: payload
        }
      });
    case types.UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_FAILURE:
      return Object.assign({}, state, {
        updateNotificationEmailAssignedPrefLoading: false,
        updateNotificationEmailAssignedPrefError: payload
      });

    /**
     * Update signals summary notifications email settings reducers
     */
    case types.UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_REQUEST:
      return Object.assign({}, state, {
        updateNotificationEmailSignalsSummaryLoading: true,
        updateNotificationEmailSignalsSummaryError: null
      });
    case types.UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        updateNotificationEmailSignalsSummaryLoading: false,
        updateNotificationEmailSignalsSummaryError: null,
        settings: {
          ...state.settings,
          // Update setting's value in local state
          [NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_SETTING_KEY]: payload
        }
      });
    case types.UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_FAILURE:
      return Object.assign({}, state, {
        updateNotificationEmailSignalsSummaryLoading: false,
        updateNotificationEmailSignalsSummaryError: payload
      });

   

      case types.UPDATE_FLAG_FULL_SYNC_GMAIL_REQUEST:
        return Object.assign({},state,{
          updateFlagFullSyncGmailLoading: true,
          updateFlagFullSyncGmailError: null
        }) ;
      case types.UPDATE_FLAG_FULL_SYNC_GMAIL_SUCCESS:
        return Object.assign({},state,{
          updateFlagFullSyncGmailLoading: false,
          updateFlagFullSyncGmailError: null,
          settings: {
            ...state.settings,
            [FLAG_FULL_SYNC_GMAIL]: payload
          }
        });
      case types.UPDATE_FLAG_FULL_SYNC_GMAIL_FAILURE:
        return Object.assign({},state,{
          updateFlagFullSyncGmailLoading: false,
          updateFlagFullSyncGmailError: payload
        })

    case types.UPDATE_CUBEJS_SCHEMA_VERSION_REQUEST:
      return Object.assign({},state,{
        updateCubejsSchemaVersionLoading: true,
        updateCubejsSchemaVersionError: null
      })
    case types.UPDATE_CUBEJS_SCHEMA_VERSION_SUCCESS:
      return Object.assign({},state, {
        updateCubejsSchemaVersionLoading: false,
        updateCubejsSchemaVersionError: null,
        settings: {
          ...state.settings,
          [CUBEJS_SCHEMA_VERSION]: payload
        }
      })
    case types.UPDATE_CUBEJS_SCHEMA_VERSION_FAILURE:
      return Object.assign({}, state, {
        updateCubejsSchemaVersionLoading: false,
        updateCubejsSchemaVersionError: payload
      })

    case types.UPDATE_HIDE_DEAL_ACTIVITY_LOG_REQUEST:
      return Object.assign({},state,{
        updateDealActivityLogSettingLoading: true,
        updateDealActivityLogSettingError: null
      }) ;
    case types.UPDATE_HIDE_DEAL_ACTIVITY_LOG_SUCCESS:
      return Object.assign({},state,{
        updateDealActivityLogSettingLoading: false,
        updateDealActivityLogSettingError: null,
        settings: {
          ...state.settings,
          [DEAL_HIDE_ACTIVITY_LOG]: payload
        }
      });
    case types.UPDATE_HIDE_DEAL_ACTIVITY_LOG_FAILURE:
      return Object.assign({},state,{
        updateDealActivityLogSettingLoading: false,
        updateDealActivityLogSettingError: payload
      })

    default:
      return state;
  }
};
