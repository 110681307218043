/**
 * Fetches deal assignee list
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_ASSIGNEE_LIST_REQUEST,
  LOAD_ASSIGNEE_LIST_SUCCESS,
  LOAD_ASSIGNEE_LIST_FAILURE} from '../actions';

export default (deal_id) => {
  return dispatch => {
    dispatch({
      type: LOAD_ASSIGNEE_LIST_REQUEST,
      payload: {
        dealId: deal_id,
      }
    });
    request.get(`/api/v1/pipelines/${deal_id}/assignees`, {}, dispatch)
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: LOAD_ASSIGNEE_LIST_SUCCESS,
          payload: {
            data: data.data,
            dealId: deal_id,
          }
        });
      } else {
        dispatch({
          type: LOAD_ASSIGNEE_LIST_FAILURE,
          payload: {
            message: data.message,
            dealId: deal_id,
          }
        });
        dispatch(enqueueNotification(data.message));
      }
    })
    .catch((err) => {
      const message = 'Something went wrong while loading assignees, please try again.'
      dispatch({
        type: LOAD_ASSIGNEE_LIST_FAILURE,
        payload: {
          message,
          dealId: deal_id,
        }
      });
      dispatch(enqueueNotification(message));
    });
  };
};
