/**
 * Cnvert a deal to portfolio
 *
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  CONVERT_TO_PORTFOLIO_REQUEST,
  CONVERT_TO_PORTFOLIO_SUCCESS,
  CONVERT_TO_PORTFOLIO_FAILURE,
} from '../actions';

export default dealId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CONVERT_TO_PORTFOLIO_REQUEST,
        payload: { dealId },
      });
      request
        .put(
          `/api/pipeline/v6/convert-to-portfolio`,
          { deal_id: dealId },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: CONVERT_TO_PORTFOLIO_SUCCESS,
              payload: {
                dealId: dealId,
                data: data.data,
              },
            });
            resolve(data.data);
          } else {
            dispatch({
              type: CONVERT_TO_PORTFOLIO_FAILURE,
              payload: {
                dealId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while converting to portfolio, please try again.';
          dispatch({
            type: CONVERT_TO_PORTFOLIO_FAILURE,
            payload: {
              dealId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
