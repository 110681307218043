export const EMAIL_ATTACHMENT_GETSIGNEDURL_REQUEST = 'attachment/EMAIL_ATTACHMENT_GETSIGNEDURL_REQUEST';
export const EMAIL_ATTACHMENT_GETSIGNEDURL_SUCCESS = 'attachment/EMAIL_ATTACHMENT_GETSIGNEDURL_SUCCESS';
export const EMAIL_ATTACHMENT_GETSIGNEDURL_FAILURE = 'attachment/EMAIL_ATTACHMENT_GETSIGNEDURL_FAILURE';

export const DEAL_ATTACHMENT_GETSIGNEDURL_REQUEST = 'attachment/DEAL_ATTACHMENT_GETSIGNEDURL_REQUEST';
export const DEAL_ATTACHMENT_GETSIGNEDURL_SUCCESS = 'attachment/DEAL_ATTACHMENT_GETSIGNEDURL_SUCCESS';
export const DEAL_ATTACHMENT_GETSIGNEDURL_FAILURE = 'attachment/DEAL_ATTACHMENT_GETSIGNEDURL_FAILURE';

export const PORTCO_ATTACHMENT_GETSIGNEDURL_REQUEST = 'attachment/PORTCO_ATTACHMENT_GETSIGNEDURL_REQUEST';
export const PORTCO_ATTACHMENT_GETSIGNEDURL_SUCCESS = 'attachment/PORTCO_ATTACHMENT_GETSIGNEDURL_SUCCESS';
export const PORTCO_ATTACHMENT_GETSIGNEDURL_FAILURE = 'attachment/PORTCO_ATTACHMENT_GETSIGNEDURL_FAILURE';

export const DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST = 'attachment/DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST';
export const DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS = 'attachment/DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS';
export const DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE = 'attachment/DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE';

export const FILE_UPLOAD_S3_REQUEST = 'attachment/FILE_UPLOAD_S3_REQUEST';
export const FILE_UPLOAD_S3_SUCCESS = 'attachment/FILE_UPLOAD_S3_SUCCESS';
export const FILE_UPLOAD_S3_FAILURE = 'attachment/FILE_UPLOAD_S3_FAILURE';

export const FILE_DOWNLOAD_S3_REQUEST = 'attachment/FILE_DOWNLOAD_S3_REQUEST';
export const FILE_DOWNLOAD_S3_SUCCESS = 'attachment/FILE_DOWNLOAD_S3_SUCCESS';
export const FILE_DOWNLOAD_S3_FAILURE = 'attachment/FILE_DOWNLOAD_S3_FAILURE';

export const DEAL_ATTACHMENT_DELETE_REQUEST = 'attachment/DEAL_ATTACHMENT_DELETE_REQUEST';
export const DEAL_ATTACHMENT_DELETE_SUCCESS = 'attachment/DEAL_ATTACHMENT_DELETE_SUCCESS';
export const DEAL_ATTACHMENT_DELETE_FAILURE = 'attachment/DEAL_ATTACHMENT_DELETE_FAILURE';

export const GET_DEAL_ATTACHMENTS_REQUEST = 'attachment/GET_DEAL_ATTACHMENTS_REQUEST';
export const GET_DEAL_ATTACHMENTS_SUCCESS = 'attachment/GET_DEAL_ATTACHMENTS_SUCCESS';
export const GET_DEAL_ATTACHMENTS_FAILURE = 'attachment/GET_DEAL_ATTACHMENTS_FAILURE';

export const DEAL_ATTACHMENT_LIST_TAGS_REQUEST = 'attachment/DEAL_ATTACHMENT_LIST_TAGS_REQUEST';
export const DEAL_ATTACHMENT_LIST_TAGS_SUCCESS = 'attachment/DEAL_ATTACHMENT_LIST_TAGS_SUCCESS';
export const DEAL_ATTACHMENT_LIST_TAGS_FAILURE = 'attachment/DEAL_ATTACHMENT_LIST_TAGS_FAILURE';

export const DEAL_ATTACHMENT_ADD_TAG_REQUEST = 'attachment/DEAL_ATTACHMENT_ADD_TAG_REQUEST';
export const DEAL_ATTACHMENT_ADD_TAG_SUCCESS = 'attachment/DEAL_ATTACHMENT_ADD_TAG_SUCCESS';
export const DEAL_ATTACHMENT_ADD_TAG_FAILURE = 'attachment/DEAL_ATTACHMENT_ADD_TAG_FAILURE';

export const DEAL_ATTACHMENT_REMOVE_TAG_REQUEST = 'attachment/DEAL_ATTACHMENT_REMOVE_TAG_REQUEST';
export const DEAL_ATTACHMENT_REMOVE_TAG_SUCCESS = 'attachment/DEAL_ATTACHMENT_REMOVE_TAG_SUCCESS';
export const DEAL_ATTACHMENT_REMOVE_TAG_FAILURE = 'attachment/DEAL_ATTACHMENT_REMOVE_TAG_FAILURE';

export const NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST = 'attachment/NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST';
export const NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS = 'attachment/NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS';
export const NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE = 'attachment/NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE';

export const EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST = 'attachment/EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST';
export const EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS = 'attachment/EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS';
export const EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE = 'attachment/EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE';

export const NOTE_ATTACHMENT_DELETE_REQUEST = 'attachment/NOTE_ATTACHMENT_DELETE_REQUEST';
export const NOTE_ATTACHMENT_DELETE_SUCCESS = 'attachment/NOTE_ATTACHMENT_DELETE_SUCCESS';
export const NOTE_ATTACHMENT_DELETE_FAILURE = 'attachment/NOTE_ATTACHMENT_DELETE_FAILURE';

export const ATTACHMENT_DELETE_REQUEST = 'attachment/ATTACHMENT_DELETE_REQUEST';
export const ATTACHMENT_DELETE_SUCCESS = 'attachment/ATTACHMENT_DELETE_SUCCESS';
export const ATTACHMENT_DELETE_FAILURE = 'attachment/ATTACHMENT_DELETE_FAILURE';

export const PORTCO_ATTACHMENT_DELETE_REQUEST = 'attachment/PORTCO_ATTACHMENT_DELETE_REQUEST';
export const PORTCO_ATTACHMENT_DELETE_SUCCESS = 'attachment/PORTCO_ATTACHMENT_DELETE_SUCCESS';
export const PORTCO_ATTACHMENT_DELETE_FAILURE = 'attachment/PORTCO_ATTACHMENT_DELETE_FAILURE';

export const HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_REQUEST = 'attachment/HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_REQUEST';
export const HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_SUCCESS = 'attachment/HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_SUCCESS';
export const HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_FAILURE = 'attachment/HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_FAILURE';

export const PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST = 'attachment/PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST';
export const PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS = 'attachment/PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS';
export const PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE = 'attachment/PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE';


export const PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_UPDATE_REQUEST = 'attachment/PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST/UPDATE';
export const PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_UPDATE_SUCCESS = 'attachment/PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS/UPDATE';
export const PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_UPDATE_FAILURE = 'attachment/PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE/UPDATE';

export const PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_EMAIL_REQUEST = 'attachment/PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST/EMAIL';
export const PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_EMAIL_SUCCESS = 'attachment/PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS/EMAIL';
export const PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_EMAIL_FAILURE = 'attachment/PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE/EMAIL';
 

export const GET_PORTFOLIO_ATTACHMENTS_REQUEST = 'attachment/GET_PORTFOLIO_ATTACHMENTS_REQUEST';
export const GET_PORTFOLIO_ATTACHMENTS_SUCCESS = 'attachment/GET_PORTFOLIO_ATTACHMENTS_SUCCESS';
export const GET_PORTFOLIO_ATTACHMENTS_FAILURE = 'attachment/GET_PORTFOLIO_ATTACHMENTS_FAILURE';

export const PORTFOLIO_ATTACHMENT_LIST_TAGS = 'attachment/PORTFOLIO_ATTACHMENT_LIST_TAGS';
export const PORTFOLIO_ATTACHMENT_ADD_TAG = 'attachment/PORTFOLIO_ATTACHMENT_ADD_TAG';
export const PORTFOLIO_ATTACHMENT_REMOVE_TAG = 'attachment/PORTFOLIO_ATTACHMENT_REMOVE_TAG';