

/**
 * Fetches pinned Note
 * 
 * @author Taghash Team
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_PINNED_DEAL_NOTE_REQUEST,
  LOAD_PINNED_DEAL_NOTE_SUCCESS,
  LOAD_PINNED_DEAL_NOTE_FAILURE } from '../actions';

export default (dealId, options={}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LOAD_PINNED_DEAL_NOTE_REQUEST,
        payload: { dealId }
      });
      request.get(`/api/v3/deals/${dealId}/notes/pin`, options, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: LOAD_PINNED_DEAL_NOTE_SUCCESS,
            payload: {
              data: data.data,
              dealId
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: LOAD_PINNED_DEAL_NOTE_FAILURE,
            payload: {
              message: data.message,
              dealId
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading note, please try again.'
        dispatch({
          type: LOAD_PINNED_DEAL_NOTE_FAILURE,
          payload: { message, dealId }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
