/**
 * @format
 */

import request from '../../../helpers/request';
import * as types from '../actions';
import { enqueueNotification } from '../../app';

/**
 * Create deal from org contact
 */
export default (portcoId, emailId) => {
  return async dispatch => {
    dispatch({
      type: types.ADD_TO_PORTCO_REQUEST,
      payload: {
        emailId,
      },
    });
    let data;
    try {
      const response = await request.post(
        '/api/email-processor/v1/add-to-portfolio-company',
        {
          email_id: emailId,
          portfolio_company_id: portcoId,
        },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong with adding to portfolio company. Please try again or contact support';
      dispatch({
        type: types.ADD_TO_PORTCO_ERROR,
        payload: {
          emailId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.ADD_TO_PORTCO_SUCCESS,
        payload: {
          emailId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.ADD_TO_PORTCO_ERROR,
        payload: {
          emailId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
    }
  };
};
