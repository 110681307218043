/**
 * list reminders for dashboard
 * @format
 */

import request from '../../helpers/request';
import isEmptyString from '../../helpers/string/isEmptyString';
import { enqueueNotification } from '../app';
import {
  LIST_REMINDER_DASHBOARD_REQUEST,
  LIST_REMINDER_DASHBOARD_FAILURE,
  LIST_REMINDER_DASHBOARD_SUCCESS,
  RESET_REMINDER_STATE,
  UPDATE_REMINDER_DASHBOARD_REQUEST,
  UPDATE_REMINDER_DASHBOARD_FAILURE,
  UPDATE_REMINDER_DASHBOARD_SUCCESS,
  DELETE_REMINDER_DASHBOARD_REQUEST,
  DELETE_REMINDER_DASHBOARD_FAILURE,
  DELETE_REMINDER_DASHBOARD_SUCCESS,
} from './actions';
import reducers from './reducers';

export default reducers;

/**
 * Fetching reminders for reminders dashboard based on filters selected by user
 * @param {Object} filters
 * @param {Object} options
 * @returns
 */
export const listRemindersFromDashboard = (filters, options = {}) => {
  return async dispatch => {
    dispatch({
      type: LIST_REMINDER_DASHBOARD_REQUEST,
      payload: {
        filters,
      },
    });
    let data;
    try {
      const response = await request.get(`/api/reminders-dashboard/v1`, {
        filters: { ...filters },
        limit: options.limit || 10,
        page: options.page || 1,
      });
      data = response.data;
    } catch (err) {
      const message = `Something went wrong when fetching reminders for dashboard`;
      dispatch({
        type: LIST_REMINDER_DASHBOARD_FAILURE,
        payload: { filters, message },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }

    if (!data.success) {
      dispatch({
        type: LIST_REMINDER_DASHBOARD_FAILURE,
        payload: { filters, message: data.message },
      });

      throw new Error(data.message);
    }
    dispatch({
      type: LIST_REMINDER_DASHBOARD_SUCCESS,
      payload: {
        filters,
        data: data.data,
        metadata: data.metadata,
      },
    });

    return { data: data.data, count: data.metadata.count };
  };
};

//Function that updates a reminder in reminder dashboard
//Is a copy of the main one from modules/reminder
export const updateReminderById = (
  reminderId,
  resource_type,
  resource_id,
  form = { remindAt: '', title: '' }
) => {
  return async dispatch => {
    if (
      isEmptyString(reminderId) ||
      isEmptyString(resource_type) ||
      isEmptyString(resource_id)
    ) {
      const message = `Something went wrong when updating reminder
      Please try again`;

      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: UPDATE_REMINDER_DASHBOARD_REQUEST,
      payload: {
        resource_id,
        resource_type,
        reminderId,
      },
    });

    let data;
    try {
      const response = await request.patch(
        `/api/reminders/v1/${reminderId}`,
        { ...form, for_reminders_dashboard: true },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message = `Something went wrong while updating reminder`;
      dispatch({
        type: UPDATE_REMINDER_DASHBOARD_FAILURE,
        payload: {
          resource_type,
          resource_id,
          reminderId,
          message,
        },
      });

      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: UPDATE_REMINDER_DASHBOARD_SUCCESS,
        payload: {
          resource_type,
          resource_id,
          reminderId,
          data: data.data,
        },
      });

      dispatch(enqueueNotification('Reminder updated successfully'));
      return data.data;
    } else {
      dispatch({
        type: UPDATE_REMINDER_DASHBOARD_FAILURE,
        payload: {
          resource_id,
          resource_type,
          reminderId,
          message: data.message,
        },
      });
      const error = data;
      if (error.code === 105 && /not authorized/.test(error.message)) {
        dispatch(
          enqueueNotification(
            'You do not have permissions to update this reminder'
          )
        );
      }
      if (
        error.code === 102 &&
        /Requested resource not found/.test(error.message)
      ) {
        dispatch(enqueueNotification(error.message));
      }

      throw new Error(error.message);
    }
  };
};

//Deletes a reminder
export const deleteReminderById = (
  reminderId,
  resource_type,
  resource_id,
  form = { is_recurring: false }
) => {
  return async dispatch => {
    if (
      isEmptyString(reminderId) ||
      isEmptyString(resource_type) ||
      isEmptyString(resource_id)
    ) {
      const message = `Something went wrong when deleting reminder
      Please try again`;

      dispatch(enqueueNotification(message));
      throw new Error(message);
    }

    dispatch({
      type: DELETE_REMINDER_DASHBOARD_REQUEST,
      payload: {
        resource_id,
        resource_type,
        reminderId,
      },
    });

    let data;
    try {
      const response = await request.del(
        `/api/reminders/v1/${reminderId}`,
        { ...form },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message = `Something went wrong while deleting reminder`;
      dispatch({
        type: DELETE_REMINDER_DASHBOARD_FAILURE,
        payload: {
          resource_type,
          resource_id,
          reminderId,
          message,
        },
      });

      dispatch(enqueueNotification(message));
      throw err;
    }

    if (data.success) {
      dispatch({
        type: DELETE_REMINDER_DASHBOARD_SUCCESS,
        payload: {
          resource_type,
          resource_id,
          reminderId,
        },
      });

      dispatch(enqueueNotification('Reminder deleted successfully'));
    } else {
      dispatch({
        type: DELETE_REMINDER_DASHBOARD_FAILURE,
        payload: {
          resource_id,
          resource_type,
          reminderId,
          message: data.message,
        },
      });
      const error = data;

      if (
        error.code === 105 &&
        /do not have sufficient permissions to delete/.test(error.message)
      ) {
        dispatch(
          enqueueNotification(
            'You do not have permissions to delete this reminder'
          )
        );
      }

      if (error.code === 100 && /Cannot remove investor/.test(error.message)) {
        dispatch(enqueueNotification(error.message));
      }

      throw new Error(data.message);
    }
  };
};

export const resetRemindersForRemindersDashboard = () => dispatch =>
  dispatch({
    type: RESET_REMINDER_STATE,
  });
