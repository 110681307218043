/**
 * Adds internal referrer to the deal
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  ADD_INTERNAL_REFERRERS_REQUEST,
  ADD_INTERNAL_REFERRERS_SUCCESS,
  ADD_INTERNAL_REFERRERS_FAILURE } from '../actions';

export default (dealId, userIds) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_INTERNAL_REFERRERS_REQUEST,
        payload: { dealId }
      });
      request.post(`/api/v4/deals/${dealId}/internal_referrers/add`, userIds, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: ADD_INTERNAL_REFERRERS_SUCCESS,
            payload: { 
              data: data.data,
              dealId: dealId
            }
          });
          resolve(data.data);
        } else {
          dispatch({
            type: ADD_INTERNAL_REFERRERS_FAILURE,
            payload: {
              dealId,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while adding internal referrers, please try again.';
        dispatch({
          type: ADD_INTERNAL_REFERRERS_FAILURE,
          payload: {
            dealId,
            message,
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
