import React from 'react';
import { Grow, Slide, Fade, Collapse } from '@material-ui/core';

// Global transition settings
export const TRANSITION_DURATION = 250; // Not too fast, not too slow. can change based on feedback
export const TRANSITION_EASING = 'ease-in-out'; //linear looks too plain, so this.

// Scale Transition for Dialogs, Popovers, and Menus
export const ScaleTransition = React.forwardRef((props, ref) => (
  <Grow
    ref={ref}
    {...props}
    timeout={TRANSITION_DURATION}
    style={{ transitionTimingFunction: TRANSITION_EASING }}
  />
));

// Fade Transition for Select Dropdowns & Popper
export const FadeTransition = React.forwardRef((props, ref) => (
  <Fade
    ref={ref}
    {...props}
    timeout={TRANSITION_DURATION}
    style={{ transitionTimingFunction: TRANSITION_EASING }}
  />
));

// Slide Transition for Alerts & Snackbar (Right to Left)
export const SlideTransition = React.forwardRef((props, ref) => (
  <Slide
    ref={ref}
    {...props}
    direction="left"
    timeout={TRANSITION_DURATION}
    style={{ transitionTimingFunction: TRANSITION_EASING }}
  />
));

// Collapse Transition for Accordions
export const CollapseTransition = React.forwardRef((props, ref) => (
  <Collapse
    ref={ref}
    {...props}
    timeout={TRANSITION_DURATION}
    style={{ transitionTimingFunction: TRANSITION_EASING }}
  />
));

export default ScaleTransition;