/**
 * updates orgContact
 *
 * @format
 */
import request from '../../../helpers/request';
import isEmptyString from '../../../helpers/string/isEmptyString';
import { enqueueNotification } from '../../app';
import {
  UPDATE_ORG_CONTACT_REQUEST,
  UPDATE_ORG_CONTACT_SUCCESS,
  UPDATE_ORG_CONTACT_FAILURE,
} from '../actions';

export default (orgContactId, form) => {
  return async dispatch => {
    if (isEmptyString(orgContactId)) {
      throw new Error(`Missing orgContactId`);
    }
    dispatch({
      type: UPDATE_ORG_CONTACT_REQUEST,
      payload: {
        orgContactId,
      },
    });
    let data;
    try {
      const response = await request.put(
        `/api/contact/v1/organizations/${orgContactId}`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while updating contact, please try again.';
      dispatch({
        type: UPDATE_ORG_CONTACT_FAILURE,
        payload: {
          orgContactId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: UPDATE_ORG_CONTACT_SUCCESS,
        payload: {
          orgContactId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: UPDATE_ORG_CONTACT_FAILURE,
        payload: {
          orgContactId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
