import { 
  LOAD_CUSTOM_FIELDS_REQUEST,
  LOAD_CUSTOM_FIELDS_SUCCESS,
  LOAD_CUSTOM_FIELDS_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_CUSTOM_FIELDS_REQUEST:
      return Object.assign({}, state, {
        customFieldsLoading: true,
        customFieldsError: null
      });

    case LOAD_CUSTOM_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: null,
        customFields: payload
      });
      
    case LOAD_CUSTOM_FIELDS_FAILURE:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: payload.message
      });

    default:
      return state;
  }
};
