/**
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  UPDATE_CUSTOM_FIELDS_REQUEST,
  UPDATE_CUSTOM_FIELDS_SUCCESS,
  UPDATE_CUSTOM_FIELDS_FAILURE,
} from '../actions';

export default form => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UPDATE_CUSTOM_FIELDS_REQUEST,
        paylod: form,
      });
      request
        .put(`/api/portfolio-fields/v1/fields`, form, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: UPDATE_CUSTOM_FIELDS_SUCCESS,
              payload: data.data,
            });
            dispatch(
              enqueueNotification(
                'Custom fields updated for the organization successfully!'
              )
            );
            resolve(data);
          } else {
            dispatch({
              type: UPDATE_CUSTOM_FIELDS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating custom fields, please try again.';
          dispatch({
            type: UPDATE_CUSTOM_FIELDS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
