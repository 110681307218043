/** @format */
import {
  CONSOLIDATED_REPORTS_RESOURCE_ALLOWED_TYPES,
  CONSOLIDATED_REPORTS_RESOURCE_TYPES,
} from '../../constants/ConsolidatedReports';
import request, { api } from '../../helpers/request';
import { enqueueNotification } from '../app';
import * as types from './actions';
import reducers from './reducers';

const urls = {
  [CONSOLIDATED_REPORTS_RESOURCE_TYPES.PORTFOLIO_COMPANY]: {
    GET_TEMPLATES:
      '/api/portfolio/v1/companies/:id/consolidated-report/templates',
    GET_REPORTS: '/api/portfolio/v1/companies/:id/consolidated-report/reports',
    UPLOAD_TEMPLATE:
      '/api/portfolio/v1/companies/:id/consolidated-report/templates',
    GENERATE_REPORT:
      '/api/portfolio/v1/companies/:id/consolidated-report/generate',
    GET_SINGED_URLS:
      '/api/portfolio/v1/companies/:id/consolidated-report/signed-url',
    DELETE_REPORTS:
      '/api/portfolio/v1/companies/:id/consolidated-report/reports',
  },
};

export default reducers;

export const loadConsolidatedReportTemplates = (
  resource_id,
  resource_type,
  options
) => async dispatch => {
  if (!CONSOLIDATED_REPORTS_RESOURCE_ALLOWED_TYPES.includes(resource_type))
    throw new Error('Invalid resource type');
  dispatch({
    type: types.LOAD_CONSOLIDATED_REPORT_TEMPLATES_REQUEST,
    payload: {
      resource_type,
      resource_id,
    },
  });
  const url = urls[resource_type].GET_TEMPLATES.replace(':id', resource_id);
  try {
    const { data } = await request.get(url, options);
    if (data.success) {
      dispatch({
        type: types.LOAD_CONSOLIDATED_REPORT_TEMPLATES_SUCCESS,
        payload: {
          resource_id,
          resource_type,
          data: data.data,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.LOAD_CONSOLIDATED_REPORT_TEMPLATES_ERROR,
        payload: { resource_type, message: data.message, resource_id },
      });
      dispatch(enqueueNotification(data.message));
    }
  } catch (error) {
    const message =
      'Something went wrong while loading report templates, please try again.';
    dispatch({
      type: types.LOAD_CONSOLIDATED_REPORT_TEMPLATES_ERROR,
      payload: {
        message,
        resource_id,
        resource_type,
      },
    });
    dispatch(enqueueNotification(message));
  }
};

export const pollConsolidatedReports = (
  resource_id,
  resource_type,
  options
) => async dispatch => {
  if (!CONSOLIDATED_REPORTS_RESOURCE_ALLOWED_TYPES.includes(resource_type))
    throw new Error('Invalid resource type');
  const url = urls[resource_type].GET_REPORTS.replace(':id', resource_id);
  try {
    const { data } = await request.get(url, options);
    if (data.success) {
      dispatch({
        type: types.LOAD_CONSOLIDATED_REPORTS_SUCCESS,
        payload: {
          resource_id,
          resource_type,
          data: data.data,
          isPolled: true,
        },
      });
      return data;
    }
  } catch (error) {
    const message =
      'Something went wrong while loading reports, please try again.';
    dispatch(enqueueNotification(message));
    dispatch({
      type: types.LOAD_CONSOLIDATED_REPORTS_ERROR,
      payload: {
        message,
        resource_id,
        resource_type,
      },
    });
  }
};

export const loadConsolidatedReports = (
  resource_id,
  resource_type,
  options = {},
  { reset } = {}
) => async dispatch => {
  if (!CONSOLIDATED_REPORTS_RESOURCE_ALLOWED_TYPES.includes(resource_type))
    throw new Error('Invalid resource type');
  if (reset) {
    options.page = 1;
  }
  const isFirstPage = options?.page === 1;
  dispatch({
    type: types.LOAD_CONSOLIDATED_REPORTS_REQUEST,
    payload: {
      resource_type,
      resource_id,
      isFirstPage,
    },
  });

  const url = urls[resource_type].GET_REPORTS.replace(':id', resource_id);
  try {
    const { data } = await request.get(url, options);
    if (data.success) {
      dispatch({
        type: types.LOAD_CONSOLIDATED_REPORTS_SUCCESS,
        payload: {
          resource_id,
          resource_type,
          data: data.data,
          isFirstPage,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.LOAD_CONSOLIDATED_REPORTS_ERROR,
        payload: { resource_type, message: data.message, resource_id },
      });
      dispatch(enqueueNotification(data.message));
    }
  } catch (error) {
    const message =
      'Something went wrong while loading reports, please try again.';
    dispatch({
      type: types.LOAD_CONSOLIDATED_REPORTS_ERROR,
      payload: {
        message,
        resource_id,
        resource_type,
      },
    });
    dispatch(enqueueNotification(message));
  }
};

export const uploadTemplate = (
  resource_id,
  resource_type,
  inputData
) => async dispatch => {
  const url = urls[resource_type].UPLOAD_TEMPLATE.replace(':id', resource_id);
  const file = inputData.file;
  try {
    const { data } = await request.post(url, inputData);
    if (data.success) {
      await api.put(data.data.url, file, {});
      return data;
    } else {
      dispatch(
        enqueueNotification(
          data.message ||
            'Something went wrong while uploading template, please try again.'
        )
      );
    }
  } catch (error) {
    const message =
      'Something went wrong while uploading template, please try again.';
    dispatch(enqueueNotification(message));
  }
};

export const generateReport = (
  resource_id,
  resource_type,
  options
) => async dispatch => {
  const url = urls[resource_type].GENERATE_REPORT.replace(':id', resource_id);
  try {
    const { data } = await request.get(url, options);
    if (data.success) {
      if (data.message && typeof data.message === 'string') {
        dispatch(enqueueNotification(data.message));
      }
      return data;
    } else {
      dispatch(
        enqueueNotification(
          data.message ||
            'Something went wrong while generating report, please try again.'
        )
      );
    }
  } catch (error) {
    const message =
      'Something went wrong while generating report, please try again.';
    dispatch(enqueueNotification(message));
  }
};
// We generate a report with the current template and give that to user as current template
export const getCurrentTemplate = (
  resource_id,
  resource_type
) => async dispatch => {
  const url = urls[resource_type].GENERATE_REPORT.replace(':id', resource_id);
  try {
    const { data } = await request.get(url, {
      // this is added here to generate report in foreground when needed
      // initally when making this features reports were going to be
      // generated in foreground only but since it takes some time we
      // decided to use background jobs. Currently this is needed so that
      // when user clicks on 'Download current template' we generate a report
      // in foreground and download it on users machine.
      foreground: true,
    });
    if (data.success) {
      return data.data;
    } else {
      dispatch(
        enqueueNotification(
          data.message ||
            'Something went wrong while downloading template, please try again.'
        )
      );
    }
  } catch (error) {
    const message =
      'Something went wrong while downloading template, please try again.';
    dispatch(enqueueNotification(message));
  }
};

export const getSignedUrls = (
  resource_id,
  resource_type,
  input
) => async dispatch => {
  const url = urls[resource_type].GET_SINGED_URLS.replace(':id', resource_id);
  try {
    const { data } = await request.get(url, input);
    if (data.success) {
      return data.data;
    } else {
      dispatch(
        enqueueNotification(
          data.message || 'Something went wrong, please try again.'
        )
      );
    }
  } catch (error) {
    const message = 'Something went wrong while, please try again.';
    dispatch(enqueueNotification(message));
  }
};

export const deleteReports = (resource_id, resource_type, ids) => async (
  dispatch,
  getState
) => {
  const reports =
    getState().consolidatedReports[resource_type].reports[resource_id] || [];
  const url = urls[resource_type].DELETE_REPORTS.replace(':id', resource_id);
  dispatch({
    type: types.DELETE_REPORTS_REQUEST,
    payload: {
      resource_id,
      resource_type,
      data: reports.filter(report => !ids.includes(report.id)),
    },
  });
  try {
    const { data } = await request.del(url, {
      ids,
    });
    if (data.success) {
      dispatch({
        type: types.DELETE_REPORTS_SUCCESS,
        payload: {
          resource_id,
          resource_type,
        },
      });
      return data;
    } else {
      // rollback the reports
      dispatch({
        type: types.DELETE_REPORTS_ERROR,
        payload: {
          resource_id,
          resource_type,
          data: reports,
        },
      });
      dispatch(
        enqueueNotification(
          data.message ||
            'Something went wrong while deleting reports, please try again.'
        )
      );
    }
  } catch (error) {
    // rollback the reports
    dispatch({
      type: types.DELETE_REPORTS_ERROR,
      payload: {
        resource_id,
        resource_type,
        data: reports,
      },
    });
    const message =
      'Something went wrong while while deleteing reports, please try again.';
    dispatch(enqueueNotification(message));
  }
};
