/**
 * Get pipeline dashboard reducer handler
 * @format
 */

import {
  GET_PIPELINE_DASHBOARD_FAILURE,
  GET_PIPELINE_DASHBOARD_REQUEST,
  GET_PIPELINE_DASHBOARD_SUCCESS,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case GET_PIPELINE_DASHBOARD_REQUEST:
      return Object.assign({}, state, {
        getPipelineDashboardLoading: {
          ...state.getPipelineDashboardLoading,
          [payload.dashboardId]: true,
        },

        getPipelineDashboardError: {
          ...state.getPipelineDashboardError,
          [payload.dashboardId]: null,
        },
      });

    case GET_PIPELINE_DASHBOARD_SUCCESS:
      return Object.assign({}, state, {
        pipelineDashboards: {
          ...state.pipelineDashboards,
          [payload.dashboardId]: state.pipelineDashboards[payload.dashboardId]
            ? {
                ...state.pipelineDashboards[payload.dashboardId],
                ...payload.data,
                components: payload.data.components
                  ? payload.data.components
                  : state.pipelineDashboards[payload.dashboardId].components,
                _roles: payload.data._roles
                  ? payload.data._roles
                  : state.pipelineDashboards[payload.dashboardId]._roles,
              }
            : payload.data,
        },
        getPipelineDashboardLoading: {
          ...state.getPipelineDashboardLoading,
          [payload.dashboardId]: false,
        },

        getPipelineDashboardError: {
          ...state.getPipelineDashboardError,
          [payload.dashboardId]: null,
        },
      });

    case GET_PIPELINE_DASHBOARD_FAILURE:
      return Object.assign({}, state, {
        getPipelineDashboardLoading: {
          ...state.getPipelineDashboardLoading,
          [payload.dashboardId]: false,
        },

        getPipelineDashboardError: {
          ...state.getPipelineDashboardError,
          [payload.dashboardId]: payload.error,
        },
      });
    default:
      return state;
  }
};
