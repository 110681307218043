export const LOAD_NOTE_TEMPLATE_LIST_REQUEST = 'note-template/LOAD_NOTE_TEMPLATE_LIST_REQUEST';
export const LOAD_NOTE_TEMPLATE_LIST_SUCCESS = 'note-template/LOAD_NOTE_TEMPLATE_LIST_SUCCESS';
export const LOAD_NOTE_TEMPLATE_LIST_FAILURE = 'note-template/LOAD_NOTE_TEMPLATE_LIST_FAILURE';

export const ADD_NOTE_TEMPLATE_REQUEST = 'note-template/ADD_NOTE_TEMPLATE_REQUEST';
export const ADD_NOTE_TEMPLATE_SUCCESS = 'note-template/ADD_NOTE_TEMPLATE_SUCCESS';
export const ADD_NOTE_TEMPLATE_FAILURE = 'note-template/ADD_NOTE_TEMPLATE_FAILURE';

export const UPDATE_NOTE_TEMPLATE_REQUEST = 'note-template/UPDATE_NOTE_TEMPLATE_REQUEST';
export const UPDATE_NOTE_TEMPLATE_SUCCESS = 'note-template/UPDATE_NOTE_TEMPLATE_SUCCESS';
export const UPDATE_NOTE_TEMPLATE_FAILURE = 'note-template/UPDATE_NOTE_TEMPLATE_FAILURE';

export const SOFT_DELETE_NOTE_TEMPLATE_REQUEST = 'note-template/SOFT_DELETE_NOTE_TEMPLATE_REQUEST';
export const SOFT_DELETE_NOTE_TEMPLATE_SUCCESS = 'note-template/SOFT_DELETE_NOTE_TEMPLATE_SUCCESS';
export const SOFT_DELETE_NOTE_TEMPLATE_FAILURE = 'note-template/SOFT_DELETE_NOTE_TEMPLATE_FAILURE';
