/**
 * Fetches resource counts associated with the deal
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
   LOAD_DEAL_RESOURCE_COUNT_REQUEST,
   LOAD_DEAL_RESOURCE_COUNT_SUCCESS,
   LOAD_DEAL_RESOURCE_COUNT_FAILURE } from '../actions';

export default (dealId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LOAD_DEAL_RESOURCE_COUNT_REQUEST,
        payload: {
          dealId
        }
      });
      request.get(`/api/v2/deals/${dealId}/counts`, {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: LOAD_DEAL_RESOURCE_COUNT_SUCCESS,
            payload: {
              data: data.data,
              dealId
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: LOAD_DEAL_RESOURCE_COUNT_FAILURE,
            payload: {
              message: data.message,
              dealId
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading deal resource counts, please try again.';
        dispatch({
          type: LOAD_DEAL_RESOURCE_COUNT_FAILURE,
          payload: {
            message,
            dealId
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
