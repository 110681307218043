/**
 * Holds all the deal overview fields, namespaced with versions
 * @format
 */
const DealsOverviewFields = {
  v1: {
    CREATED_AT: {
      key: 'created_at',
      name: 'Added On',
    },
    LAST_ACTIVE_AT: {
      key: 'last_active_at',
      name: 'Last Active At',
    },
    EXTERNAL_REFERRER: {
      key: 'externalReferrer',
      name: 'External Referrer',
    },
    INTERNAL_REFERRERS: {
      key: 'internalReferrers',
      name: 'Internal Referrers',
    },
    INDUSTRY: {
      key: 'industry',
      name: 'Industry',
    },
    REVENUE_MODEL: {
      key: 'revenueModel',
      name: 'Revenue Model',
    },
    TECHNOLOGY: {
      key: 'technology',
      name: 'Technology',
    },
    STARTUP_FOUNDERS: {
      key: 'startup_founders',
      name: 'Founders',
    },
    STARTUP_DESCRIPTION: {
      key: 'startup_description',
      name: 'Description',
    },
    STARTUP_REGISTERED_NAME: {
      key: 'startup_registered_name',
      name: 'Registered Name',
    },
    STARTUP_URL: {
      key: 'startup_url',
      name: 'Website',
    },
    STARTUP_EMAIL: {
      key: 'startup_email',
      name: 'Contact Email',
    },
    STARTUP_PHONE_NUMBER: {
      key: 'startup_phone_number',
      name: 'Contact Phone Number',
    },
    STARTUP_OPERATIONAL_STATUS: {
      key: 'startup_operational_status',
      name: 'Operational Status',
    },
    STARTUP_LOCAL_OP_ADDRESS: {
      key: 'startup_local_ops_address',
      name: 'Local Operational Address',
    },
    STARTUP_GLOBAL_OP_ADDRESS: {
      key: 'startup_global_ops_address',
      name: 'Global Operational Address',
    },
    STARTUP_FOUNDING_YEAR: {
      key: 'startup_founding_year',
      name: 'Founding Year',
    },
    STARTUP_EMPLOYEE_COUNT: {
      key: 'startup_employee_count',
      name: 'Employee Count',
    },
    // @todo add after merging it with phone number column
    // STARTUP_PHONE_NUMBERS: {
    // key: 'startup_phone_numbers'
    // name: 'Other Phone Numbers'
    // },
    SOCIAL_LINKS: {
      key: 'social_links',
      name: 'Social links',
    },
    REFERRAL_MEDIUM: {
      key: 'referral_medium',
      name: 'Referral Medium',
    },
    INVESTMENT_REQUESTED: {
      key: 'investment_requested',
      name: 'Investment Requested',
    },
    STATUS: {
      key: 'is_open',
      name: 'Status',
    },
    ADDED_BY: {
      key: 'added_by',
      name: 'Added by',
    },
  },
};
DealsOverviewFields.v2 = {
  ...DealsOverviewFields.v1,
  FIRST_EMAIL_INTERACTION_DATE: {
    key: 'first_email_interaction_date',
    name: 'First Email Interaction Date',
  },
};
DealsOverviewFields.currentVersion = 'v2';
export default DealsOverviewFields;
