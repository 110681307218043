/**
 * Time component
 *
 * Note: By default in browsers if you do not set timezone, it returns system's
 * timezone by default.
 *
 * @author  Ritesh Shrivastav
 */
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import timezoneConstants from '../../constants/Timezone';
import isEmptyString from '../../helpers/string/isEmptyString';

class Time extends React.PureComponent {

  static propTypes = {
    time : PropTypes.any,
    now: PropTypes.bool.isRequired,
    format : PropTypes.string,
    hintPrefix : PropTypes.string.isRequired,
    fromNow : PropTypes.bool.isRequired,
  };

  static defaultProps = {
    now: false,
    fromNow: false,
    hintPrefix: ''
  };
  static isCorrectDateObject = dateObj => dateObj instanceof moment;
  static initialize = (dateString, format) => isEmptyString(dateString) ? moment() : moment(dateString,format);
  
  static initMoment = (dateString,format) => moment(dateString,format)

  static parse = (dateString, format) => {
    return moment(dateString, format).toDate();
  };

  static getFormattedString = (time, format) => {
    return moment(time).format(format);
  };

  /**
   * Calculates the new date with given time difference from now.
   * @param  {Number} d      Number of date difference
   * @param  {String} format Format of the returned date
   * @return {String}        Date in formatted string
   */
  static getTimeWithDayDifference = (d, format) => {
    if (isNaN(d)) {
      throw new Error('Expecting valid difference.');
    }
    if (d > 0) {
      return moment().add(d, 'days').startOf('day').format(format);
    } else {
      return moment().subtract((-d), 'days').startOf('day').format(format);
    }
  };

  /**
   * Checks if given time string is valid time.
   * @param  {string} t - Time string
   * @param  {string} format - Format in which time string is expected
   * @param  {bool} strict - Strict parsing mode, ref - https://stackoverflow.com/questions/26040397/is-there-a-way-to-validate-time-using-moment-js
   * @return {bool} - Returns true if time string is valid
   */
  static isValidTimeString = (t, format,strict=false) => {
    if (!t || typeof t !== 'string' || t.trim() === '') {
      return false;
    }
    try {
      const time = moment(t, format,strict);
      return time.isValid();
    } catch(err) {
      // If error is thrown then it's an invalid time
    }
    return false;
  };

  /**
   * Checks if given timezone string is valid or not
   * @param {String} tz Timezone string
   */
  static isValidTimezone(tz) {
    if (!tz || typeof tz !== 'string') return false;
    return Object.values(timezoneConstants).indexOf(tz) > -1;
  }

  /**
   * Gets user's local timezone from browser. Returns only if user's timezone is
   * amongst the timezones we recognize
   *
   * @see https://danielcompton.net/2017/07/06/detect-user-timezone-javascript
   *
   * @return {String|undefined} Returns local timezone if the browser supports it
   */
  static getSystemTimezone() {
    if (Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions) {
      const timezone_name = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (Time.isValidTimezone(timezone_name)) {
        return timezone_name;
      }
    }
  }

  /**
   * Function to get today.
   */
  static getTodayRange = () => {
    return {
      start: moment().startOf('day').toDate(),
      end: moment().endOf('day').toDate(),
    }
  };

  /**
   * Function to get yesterday
   */
  static getYesterdayRange = () => {
    return {
      start: moment().subtract(1, 'days').toDate(),
      end: moment().subtract(1, 'days').toDate(),
    }
  };

  /**
   * Function to get tomorrow
   */
  static getTomorrowRange = () =>({
    start: moment().add(1,'days').startOf('day').toDate(),
    end: moment().add(1,'days').endOf('day').toDate()
  })

  /**
   * Function to get range of next 3 days
   */
  static getNext3DaysRange = () =>({
    start: moment().toDate(),
    end: moment().add(3,'days').toDate()
  })

  /**
   * Function to get range of next 7 days
   */
  static getNext7DaysRange = () =>({
    start: moment().toDate(),
    end: moment().add(7,'days').toDate()
  })

  /**
   * Function to get range of next 30 days
   */
  static getNext30DaysRange = () =>({
    start: moment().toDate(),
    end: moment().add(30,'days').toDate()
  })

  /**
   * Function to get range of next 6 months
   */
  static getNext6MonthsRange = () =>({
    start: moment().toDate(),
    end: moment().add(6,'months').toDate()
  })

  /**
   * Function to get range of next 1 year
   */
  static getNext1Year = () =>({
    start: moment().toDate(),
    end: moment().add(1,'year').toDate()
  })

   /**
   * Function to get this week
   */
  static getThisWeekRange = () => {
    return {
      start: moment().startOf('week').toDate(),
      end: moment().endOf('week').toDate(),
    };
  };

   /**
   * Function to get last week
   */
  static getLastWeekRange = () => {
    return {
      start: moment().subtract(1, 'week').startOf('week').toDate(),
      end: moment().subtract(1, 'week').endOf('week').toDate(),
    };
  };

   /**
   * Function to get this month
   */
  static getThisMonthRange = () => {
    return {
      start: moment().startOf('month').toDate(),
      end: moment().endOf('month').toDate(),
    };
  };

  /**
   * Last month
   */
  static getLastMonthRange = () => {
    return {
      start: moment().subtract(1, 'month').startOf('month').toDate(),
      end: moment().subtract(1, 'month').endOf('month').toDate(),
    };
  };

  /**
   * Last 6 months
   */
  static getPastSixMonthRange = () => {
    return {
      start: moment().subtract(6, 'month').toDate(),
      end: moment().toDate(),
    };
  };

  /**
   * This year
   */
  static getThisYearRange = () => {
    return {
      start: moment().startOf('year').toDate(),
      end: moment().endOf('year').toDate(),
    };
  };

  /**
 * Function to get this quarter
 */
static getThisQuarterRange = () => {
    return {
        start: moment().startOf('quarter').toDate(),
        end: moment().endOf('quarter').toDate(),
    };
};

/**
 * Function to get last quarter
 */
static getLastQuarterRange = () => {
    return {
        start: moment().subtract(1, 'quarter').startOf('quarter').toDate(),
        end: moment().subtract(1, 'quarter').endOf('quarter').toDate(),
    };
};

/**
 * Function to get last 3 years
 */
static getLastThreeYearsRange = () => {
    return {
        start: moment().subtract(3, 'years').toDate(),
        end: moment().toDate(),
    };
};

/**
 * Function to get Year to Date (YTD)
 */
static getYearToDateRange = () => {
    return {
        start: moment().startOf('year').toDate(),
        end: moment().toDate(),
    };
};

/**
 * Function to get this financial year
 * Assuming financial year starts from April 1st
 */
static getThisFinancialYearRange = () => {
    let currentYear = moment().year();
    let start = moment().month() >= 3
        ? moment([currentYear, 3, 1]).toDate()  // April 1st of the current year
        : moment([currentYear - 1, 3, 1]).toDate(); // April 1st of the last year
    let end = moment(start).add(1, 'year').subtract(1, 'day').toDate(); // March 31st

    return { start, end };
};

/**
 * Function to get last financial year
 */
static getLastFinancialYearRange = () => {
    let start = moment(Time.getThisFinancialYearRange().start).subtract(1, 'year').toDate();
    let end = moment(start).add(1, 'year').subtract(1, 'day').toDate(); 

    return { start, end };
};

  /**
   * Get hours from datestring / object
   * @param {Object || String} dateObjOrString
   * @returns
   */
  static getHours = (dateObjOrString) => dateObjOrString instanceof moment ? dateObjOrString.hours() : moment(dateObjOrString).hours()

  /**
   * Get minutes from datestring / object
   * @param {Object || String} dateObjOrString
   * @returns
   */
  static getMinutes = (dateObjOrString) => dateObjOrString instanceof moment ? dateObjOrString.minutes() : moment(dateObjOrString).minutes()
  /**
   * Calculates difference between given two dates.
   * @param  {string} t1 - First time
   * @param  {string} t2 - Second time
   * @param  {string} format - Format of time strings
   * @return {number} - Number of days difference
   */
  static diffDayBetweenTimeString(t1, t2) {
    const first = moment(t1);
    const second = moment(t2);
    return first.diff(second, 'days');
  }

  /**
   * Calculates difference between given two dates.
   * @param  {moment.MomentInput} t1 - First time
   * @param  {moment.MomentInput} t2 - Second time
   * @param  {moment.unitOfTime.Diff} unit - Unit of time to calculate difference
   * @return {number} - Difference between two times in given unit
   */
  static diff(t1, t2, unit) {
    return moment(t1).diff(moment(t2), unit);
  }

  static fromNow = (time, short = false) => {
    if (!short) return moment(time).fromNow();
    const res = moment(time).fromNow()
    // in this case we need to manually construct the string
    // to get a short representation like "10m"
    const parts = [
      {
        label: 'Y',
        value: 'year'
      },
      {
        label: 'M',
        value: 'month'
      },
      {
        label: 'd',
        value: 'day'
      },
      {
        label: 'h',
        value: 'hour'
      },
      {
        label: 'min',
        value: 'minute'
      },
      {
        label: 's',
        value: 'second'
      }
    ]

    // find the first part with a non-zero value
    const firstNonZeroIndex = parts.find(p=>res.includes(p.value))
    // if all parts are zero, return "0s"
    if (firstNonZeroIndex === -1) {
      return '0s';
    }
    // construct the string
    let num = res.split(' ')[0]
    if (num.toLowerCase() === 'a') num = '1'
    return `${num}${firstNonZeroIndex.label} ago`;
  }

  static new = (time, timezone) => {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    return momentifiedTime.toDate();
  };

  static now = () => Time.new()

  static fiscal(time) {
    if(moment(time).quarter() === 1) {
      return {
        start: Time.startOf(moment(time).month('April').add(-1, 'year'), 'month'),
        end: Time.endOf(moment(time).month('March'), 'month')
      }
    } else {
      return {
        start: Time.startOf(moment(time).month('April'), 'month'),
        end: Time.endOf(moment(time).month('March').add(1, 'year'), 'month')
      }
    }
  }

  /**
   * Mutates the original time by setting it to the start of a unit of time
   * @see https://momentjs.com/docs/#/manipulating/start-of/
   * @param  {string} unit     unit of time (year, month, quarter, week, isoWeek,
   *                           day, date, hour, minute, second)
   * @param  {string} timezone timezone that should be used with this
   * @return {Time}            Returns new time object set to the required start time
   */
  static startOf(time, unit, timezone) {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    const newDate = momentifiedTime.startOf(unit).toDate();
    return newDate;
  }

  /**
   * Mutates the original time by setting it to the end of a unit of time
   * @see https://momentjs.com/docs/#/manipulating/end-of/
   * @param  {string} unit     unit of time (year, month, quarter, week, isoWeek,
   *                           day, date, hour, minute, second)
   * @param  {string} timezone timezone that should be used with this
   * @return {Time}            Returns new time object set to the required end time
   */
  static endOf(time, unit, timezone) {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    const newDate = momentifiedTime.endOf(unit).toDate();
    return newDate;
  }

  /**
   * Gets or sets the day of the year.
   * Accepts numbers from 1 to 366. If the range is exceeded, it will bubble up to the years.
   * @see https://momentjs.com/docs/#/get-set/day-of-year/
   *
   * @param  {number} number  Day of the year to set
   * @param  {string} timezone timezone that should be used with this
   * @return {(Time|number)}  Time if day of year is being set, or number to
   *                          indicate day of the year
   */
  static dayOfYear(time, number, timezone) {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    if (typeof number === 'number') {
      return momentifiedTime.dayOfYear(number).toDate();
    } else {
      return momentifiedTime.dayOfYear();
    }
  };

  /**
   * Gets or sets millisecond
   * @see https://momentjs.com/docs/#/get-set/millisecond/
   *
   * @param  {number} number  Millisecond to set
   * @param  {string} timezone timezone that should be used with this
   * @return {(Time|number)}  Time if millisecond is being set, or number to
   *                          indicate milliseconds
   */
  static millisecond(time, number, timezone) {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    if (typeof number === 'number') {
      return momentifiedTime.millisecond(number).toDate();
    } else {
      return momentifiedTime.millisecond();
    }
  };

  /**
   * Gets or sets second
   * @see https://momentjs.com/docs/#/get-set/second/
   *
   * @param  {number} number  second to set
   * @param  {string} timezone timezone that should be used with this
   * @return {(Time|number)}  Time if second is being set, or number to
   *                          indicate seconds
   */
  static second(time, number, timezone) {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    if (typeof number === 'number') {
      return momentifiedTime.second(number).toDate();
    } else {
      return momentifiedTime.second();
    }
  };

  /**
   * Gets or sets minute
   * @see https://momentjs.com/docs/#/get-set/minute/
   *
   * @param  {number} number  minute to set
   * @param  {string} timezone timezone that should be used with this
   * @return {(Time|number)}  Time if minute is being set, or number to
   *                          indicate minutes
   */
  static minute(time, number, timezone) {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    if (typeof number === 'number') {
      return momentifiedTime.minute(number).toDate();
    } else {
      return momentifiedTime.minute();
    }
  };

  /**
   * Gets or sets hour
   * @see https://momentjs.com/docs/#/get-set/hour/
   *
   * @param  {number} number  hour to set
   * @param  {string} timezone timezone that should be used with this
   * @return {(Time|number)}  Time if hour is being set, or number to
   *                          indicate hours
   */
  static hour(time, number, timezone) {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    if (typeof number === 'number') {
      return momentifiedTime.hour(number).toDate();
    } else {
      return momentifiedTime.hour();
    }
  };

  /**
   * Gets or sets date of the month
   * @see https://momentjs.com/docs/#/get-set/date/
   *
   * @param  {number} number  date to set (1-31)
   * @param  {string} timezone timezone that should be used with this
   * @return {(Time|number)}  Time if hour is being set, or number to
   *                          indicate hours
   */
  static date(time, number, timezone) {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    if (typeof number === 'number') {
      return momentifiedTime.date(number).toDate();
    } else {
      return momentifiedTime.date();
    }
  };

  /**
   * Gets or sets year
   * @see https://momentjs.com/docs/#/get-set/year/
   *
   * @param  {number} number  year to set
   * @param  {string} timezone timezone that should be used with this
   * @return {(Time|number)}  Time if year is being set, or number to
   *                          indicate milliseconds
   */
  static year(time, number, timezone) {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    if (typeof number === 'number') {
      return momentifiedTime.year(number).toDate();
    } else {
      return momentifiedTime.year();
    }
  };

  /**
   * Is the year this time lies in, a leap year?
   * @see https://momentjs.com/docs/#/query/is-leap-year/
   *
   * @param  {string} timezone timezone that should be used with this
   * @return {Boolean} returns true if that year is a leap year, and false if it is not.
   */
  static isLeapYear(time, timezone) {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    return momentifiedTime.isLeapYear();
  }

  /**
   * Sets the tz and returns momentified object
   * @see https://momentjs.com/docs/#/query/is-leap-year/
   *
   * @param  {string} timezone timezone that should be used with this
   * @return {Time} Momentified time object with timezone info filled
   */
  static tz(time, timezone_name) {
    return moment(time).tz(timezone_name).toDate();
  }

  /**
   * Mutates the original time by adding the amount of time passed via arguments
   * @see https://momentjs.com/docs/#/manipulating/add/
   * @param  {Number} count    Number of units of time to add to the time
   * @param  {string} unit     unit of time (year, month, quarter, week, isoWeek,
   *                           day, date, hour, minute, second)
   * @param  {string} timezone timezone that should be used with this
   * @return {Time}            Returns new time object set to the required start time
   */
  static add(time, count, unit, timezone) {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    return momentifiedTime.add(count, unit).toDate();
  }

  /**
   * Mutates the original time by subtracting the amount of time passed via arguments
   * @see https://momentjs.com/docs/#/manipulating/subtact/
   * @param  {Number} count    Number of units of time to subtract from the time
   * @param  {string} unit     unit of time (year, month, quarter, week, isoWeek,
   *                           day, date, hour, minute, second)
   * @param  {string} timezone timezone that should be used with this
   * @return {Time}            Returns new time object set to the required start time
   */
   static subtract(time, count, unit, timezone) {
    let momentifiedTime = moment(time);
    if (timezone) { momentifiedTime = momentifiedTime.tz(timezone) };
    return momentifiedTime.subtract(count, unit).toDate();
  }

  // returns true if `a` and `b` are equivalent date values according to passed in unit
  static isSame(a, b, unit) {
    return moment(a).isSame(b, unit)
  }

  static isSameOrAfter(a,b) {
    return moment(a).isSameOrAfter(b)
  }

  /**
   * Checks if a is after b
   * @param {Date or String} a 
   * @param {Date or String} b 
   * @param {Number} unit 
   */
  static isAfter(a,b,unit){
    return moment(a).isAfter(b,unit)
  }

  // returns a human-readable representation of a time interval / duration
  // see: https://momentjs.com/docs/#/durations
  static humanizeDuration(seconds, short=false) {
    const duration = moment.duration(seconds, 'seconds');
    if (!short) {
      // in this case we can use the humanize method directly
      // which will return a human-readable string like "10 minutes"
      return duration.humanize();
    }
    // in this case we need to manually construct the string
    // to get a short representation like "10m"
    const parts = [
      {
        label: 'Y',
        value: duration.years()
      },
      {
        label: 'M',
        value: duration.months()
      },
      {
        label: 'd',
        value: duration.days()
      },
      {
        label: 'h',
        value: duration.hours()
      },
      {
        label: 'min',
        value: duration.minutes()
      },
      {
        label: 's',
        value: duration.seconds()
      }
    ]

    // find the first part with a non-zero value
    const firstNonZeroIndex = parts.findIndex(part => !!part.value);
    // if all parts are zero, return "0s"
    if (firstNonZeroIndex === -1) {
      return '0s';
    }
    // construct the string
    const part = parts[firstNonZeroIndex];
    return `${part.value}${part.label}`;
  }

  // returns year from timestamp
  static getYearFromTimestamp(timestamp){
    return moment(timestamp).year();
  }

  // Add one month to the original date
  static getOneMonthLater = time=>
     moment(time).add(1,'months').endOf('month')

  render() {
    const { fromNow, time, format, hintPrefix, now,disableHoverListener=false, ...others } = this.props;
    const timeToUse = !time && now ? new Date() : time;
    const mTime = moment(timeToUse);
    let timeStr;
    if (fromNow) {
      timeStr = mTime.fromNow();
    } else {
      const formatStr = format || (
        mTime.isSame(moment(), 'day') ? 'h:mm a' : ( // Same day
          mTime.isSame(moment(), 'year') ? 'D MMM' : // Same year
            'D MMM, YYYY')); // Different Year
      timeStr = mTime.format(formatStr);
    }
    //
    // Tooltip child can't be set to plain string constant. Wrap under any HTML
    // element: as of 20 Aug 18
    return (
      <Tooltip disableHoverListener={disableHoverListener} title={`${(hintPrefix ? hintPrefix + ' ' : '')}${(new Date(timeToUse)).toString()}`} placement="bottom">
        <span 
         {...others}>
          {timeStr}
        </span>
      </Tooltip>
    );
  }
}

export default Time;
