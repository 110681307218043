import {
  ADD_PARTICIPANT_REQUEST,
  ADD_PARTICIPANT_SUCCESS,
  ADD_PARTICIPANT_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case ADD_PARTICIPANT_REQUEST:
      return Object.assign({}, state, {
        addParticipantLoading: true,
        addParticipantError: null
      });

    case ADD_PARTICIPANT_SUCCESS:
      return Object.assign({}, state, {
        addParticipantLoading: false,
        addParticipantError: null,
      });

    case ADD_PARTICIPANT_FAILURE:
      return Object.assign({}, state, {
        addParticipantLoading: false,
        addParticipantError: payload,
      });

    default:
      return state;
  }
};
