/**@format */
import { CONSOLIDATED_REPORTS_RESOURCE_TYPES } from '../../constants/ConsolidatedReports';
import * as types from './actions';
const initialState = {
  [CONSOLIDATED_REPORTS_RESOURCE_TYPES.PORTFOLIO_COMPANY]: {
    templates: {},
    templatesLoading: {},
    templatesError: {},
    reports: {},
    reportsLoading: {},
    moreReportsLoading: {},
    reportsError: {},

    deletingReports: {},
  },
};

const allowed_resource_types = Object.values(
  CONSOLIDATED_REPORTS_RESOURCE_TYPES
);

const validatedPayload = payload => {
  if (
    !payload?.resource_type ||
    !allowed_resource_types.includes(payload.resource_type)
  ) {
    throw new Error('Resource Type is required');
  }
};

export default (state = initialState, { type, payload }) => {
  if (Object.values(types).includes(type)) {
    validatedPayload(payload);
  }
  const resource_type = payload?.resource_type;
  switch (type) {
    /**
     * Templates list load
     */
    case types.LOAD_CONSOLIDATED_REPORT_TEMPLATES_REQUEST:
      return Object.assign({}, state, {
        [resource_type]: Object.assign({}, state[resource_type], {
          templates: Object.assign({}, state[resource_type].templates, {
            [payload.resource_id]: [],
          }),
          templatesError: Object.assign(
            {},
            state[resource_type].templatesError,
            {
              [payload.resource_id]: null,
            }
          ),
          templatesLoading: Object.assign(
            {},
            state[resource_type].templatesLoading,
            {
              [payload.resource_id]: true,
            }
          ),
        }),
      });
    case types.LOAD_CONSOLIDATED_REPORT_TEMPLATES_SUCCESS:
      return Object.assign({}, state, {
        [resource_type]: Object.assign({}, state[resource_type], {
          templates: Object.assign({}, state[resource_type].templates, {
            [payload.resource_id]: payload.data,
          }),
          templatesError: Object.assign(
            {},
            state[resource_type].templatesError,
            {
              [payload.resource_id]: null,
            }
          ),
          templatesLoading: Object.assign(
            {},
            state[resource_type].templatesLoading,
            {
              [payload.resource_id]: false,
            }
          ),
        }),
      });
    case types.LOAD_CONSOLIDATED_REPORT_TEMPLATES_ERROR:
      return Object.assign({}, state, {
        [resource_type]: Object.assign({}, state[resource_type], {
          templates: Object.assign({}, state[resource_type].templates, {
            [payload.resource_id]: [],
          }),
          templatesError: Object.assign(
            {},
            state[resource_type].templatesError,
            {
              [payload.resource_id]: payload.message,
            }
          ),
          templatesLoading: Object.assign(
            {},
            state[resource_type].templatesLoading,
            {
              [payload.resource_id]: false,
            }
          ),
        }),
      });

    case types.DELETE_REPORTS_REQUEST:
      return Object.assign({}, state, {
        [resource_type]: Object.assign({}, state[resource_type], {
          reports: Object.assign({}, state[resource_type].reports, {
            [payload.resource_id]: payload.data,
          }),
          deletingReports: Object.assign(
            {},
            state[resource_type].deletingReports,
            {
              [payload.resource_id]: true,
            }
          ),
        }),
      });
    case types.DELETE_REPORTS_SUCCESS:
      return Object.assign({}, state, {
        [resource_type]: Object.assign({}, state[resource_type], {
          deletingReports: Object.assign(
            {},
            state[resource_type].deletingReports,
            {
              [payload.resource_id]: false,
            }
          ),
        }),
      });
    case types.DELETE_REPORTS_ERROR:
      return Object.assign({}, state, {
        [resource_type]: Object.assign({}, state[resource_type], {
          reports: Object.assign({}, state[resource_type].reports, {
            [payload.resource_id]: payload.data,
          }),
          deletingReports: Object.assign(
            {},
            state[resource_type].deletingReports,
            {
              [payload.resource_id]: false,
            }
          ),
        }),
      });
    // Reports list load

    case types.LOAD_CONSOLIDATED_REPORTS_REQUEST: {
      const isFirstPage = payload.isFirstPage === true;
      const oldReports =
        state[resource_type].reports[payload.resource_id] || [];
      const reports = isFirstPage ? [] : oldReports;
      const oldLoading =
        state[resource_type].reportsLoading[payload.resource_id];
      const loading = isFirstPage ? true : oldLoading;

      const moreLoading = isFirstPage ? false : true;

      return Object.assign({}, state, {
        [resource_type]: Object.assign({}, state[resource_type], {
          reports: Object.assign({}, state[resource_type].reports, {
            [payload.resource_id]: reports,
          }),
          reportsError: Object.assign({}, state[resource_type].reportsError, {
            [payload.resource_id]: null,
          }),
          reportsLoading: Object.assign(
            {},
            state[resource_type].reportsLoading,
            {
              [payload.resource_id]: loading,
            }
          ),
          moreReportsLoading: Object.assign(
            {},
            state[resource_type].moreReportsLoading,
            {
              [payload.resource_id]: moreLoading,
            }
          ),
        }),
      });
    }
    case types.LOAD_CONSOLIDATED_REPORTS_SUCCESS:
      const isPolled = payload.isPolled === true;
      if (isPolled) {
        const mappedReports = payload.data.reduce((object, report) => {
          object[report.id] = report;
          return object;
        }, {});
        const oldReports =
          state[resource_type].reports[payload.resource_id] || [];

        const reports = oldReports.map(report => {
          if (mappedReports[report.id]) {
            return mappedReports[report.id];
          }
          return report;
        });
        return Object.assign({}, state, {
          [resource_type]: Object.assign({}, state[resource_type], {
            reports: Object.assign({}, state[resource_type].reports, {
              [payload.resource_id]: reports,
            }),
          }),
        });
      }
      const isFirstPage = payload.isFirstPage === true;
      const oldReports =
        state[resource_type].reports[payload.resource_id] || [];
      const newReports = payload.data;
      const reports = isFirstPage ? newReports : oldReports.concat(newReports);

      const oldMoreLoading =
        state[resource_type].moreReportsLoading[payload.resource_id];
      const moreLoading = isFirstPage ? oldMoreLoading : false;
      return Object.assign({}, state, {
        [resource_type]: Object.assign({}, state[resource_type], {
          reports: Object.assign({}, state[resource_type].reports, {
            [payload.resource_id]: reports,
          }),
          reportsError: Object.assign({}, state[resource_type].reportsError, {
            [payload.resource_id]: null,
          }),
          reportsLoading: Object.assign(
            {},
            state[resource_type].reportsLoading,
            {
              [payload.resource_id]: false,
            }
          ),
          moreReportsLoading: Object.assign(
            {},
            state[resource_type].moreReportsLoading,
            {
              [payload.resource_id]: moreLoading,
            }
          ),
        }),
      });

    case types.LOAD_CONSOLIDATED_REPORTS_ERROR:
      return Object.assign({}, state, {
        [resource_type]: Object.assign({}, state[resource_type], {
          reports: Object.assign({}, state[resource_type].reports, {
            [payload.resource_id]: [],
          }),
          reportsError: Object.assign({}, state[resource_type].reportsError, {
            [payload.resource_id]: payload.message,
          }),
          reportsLoading: Object.assign(
            {},
            state[resource_type].reportsLoading,
            {
              [payload.resource_id]: false,
            }
          ),
        }),
      });

    default:
      return state;
  }
};
