import request from "../../../helpers/request";
import { enqueueNotification } from "../../app";

const getDealsCount = (options) => async dispatch => {
  try {
    const { data } = await request.get('/api/pipeline/v6/count', options)
    if(!data.success) {
      const message = data.message || 'Something went wrong while loading deal count'
      dispatch(enqueueNotification(message));
    }
    return data.data
  } catch (error) {
      const message = 'Something went wrong while loading deal count'
      dispatch(enqueueNotification(message));
  }
}

export default getDealsCount