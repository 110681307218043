/**
 * Add Custom Field
 * @format
 */
import {
  ADD_CUSTOM_FIELD_REQUEST,
  ADD_CUSTOM_FIELD_SUCCESS,
  ADD_CUSTOM_FIELDS_FAILURE,
} from '../actions';
import { enqueueNotification } from '../../app';
import request from '../../../helpers/request';

export default form => {
  return async dispatch => {
    dispatch({
      type: ADD_CUSTOM_FIELD_REQUEST,
    });
    let data;
    try {
      const response = await request.post(
        `/api/portfolio-fields/v1/fields`,
        form
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while adding custom fields, please try again.';
      dispatch({
        type: ADD_CUSTOM_FIELDS_FAILURE,
        payload: message,
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: ADD_CUSTOM_FIELD_SUCCESS,
        payload: data.data,
      });
      return data;
    } else {
      dispatch({
        type: ADD_CUSTOM_FIELDS_FAILURE,
        payload: data.message,
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
