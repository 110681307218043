/**
 * System reducers
 *
 * @format
 */
import * as types from './actions';

const initialState = {
  // key: data_transport_id
  dealExportGetSignedURLLoading: {},
  dealExportGetSignedURLError: {},

  dealExportEnqueueJobLoading: false,
  dealExportEnqueueJobError: null,

  portcoExportEnqueueJobLoading: false,
  portcoExportEnqueueJobError: null,

  /**
   * Holds deal import job details, keys are the deal import job id and values
   * are the import job properties and subresources.
   */
  dealExportJobs: {},

  dealExportJobsOptions: {
    withOwner: true,
    withOrganization: true,
    page: 1,
    limit: 10,
  },
  dealExportJobsMore: false,
  dealExportJobsLoading: false,
  dealExportJobsError: null,

  /**
   * Holds portfolio import job details, keys are the portfolio import job id and values
   * are the import job properties and subresources.
   */
  portcoExportJobs: {},

  portcoExportJobsMore: false,
  portcoExportJobsLoading: false,
  portcoExportJobsError: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * List deal export jobs
     */
    case types.LIST_DEAL_EXPORT_REQUEST:
      return Object.assign({}, state, {
        dealExportJobsLoading: true,
        dealExportJobsError: null,
      });
    case types.LIST_DEAL_EXPORT_SUCCESS:
      return Object.assign({}, state, {
        dealExportJobs: {
          ...state.dealExportJobs,
          ...payload.data.reduce((acc, dealExportJob) => {
            acc[dealExportJob.id] = dealExportJob;
            return acc;
          }, {}),
        },
        dealExportJobsLoading: false,
        dealExportJobsError: null,
        dealExportJobsOptions: payload.options,
        dealExportJobsMore:
          payload.data.length >= payload.options.limit ? true : false,
      });
    case types.LIST_DEAL_EXPORT_FAILURE:
      return Object.assign({}, state, {
        dealExportJobsLoading: false,
        dealExportJobsError: payload,
      });
    case types.LIST_DEAL_EXPORT_CLEAR:
      return Object.assign({}, state, {
        dealExportJobs: {},
      });

    /**
     * Get signed url to download exported csv - reducers
     */
    case types.DEAL_EXPORT_GET_SIGNED_URL_REQUEST:
      return Object.assign({}, state, {
        dealExportGetSignedURLLoading: {
          ...state.dealExportGetSignedURLLoading,
          [payload.dataTransportId]: true,
        },
        dealExportGetSignedURLError: {
          ...state.dealExportGetSignedURLError,
          [payload.dataTransportId]: null,
        },
      });
    case types.DEAL_EXPORT_GET_SIGNED_URL_SUCCESS:
      return Object.assign({}, state, {
        dealExportGetSignedURLLoading: {
          ...state.dealExportGetSignedURLLoading,
          [payload.dataTransportId]: false,
        },
        dealExportGetSignedURLError: {
          ...state.dealExportGetSignedURLError,
          [payload.dataTransportId]: null,
        },
      });
    case types.DEAL_EXPORT_GET_SIGNED_URL_FAILURE:
      return Object.assign({}, state, {
        dealExportGetSignedURLLoading: {
          [payload.dataTransportId]: false,
        },
        dealExportGetSignedURLError: {
          [payload.dataTransportId]: payload.message,
        },
      });

    /**
     * Enqueue export job for processing - reducers
     */
    case types.DEAL_EXPORT_ENQUEUE_JOB_REQUEST:
      return Object.assign({}, state, {
        dealExportEnqueueJobLoading: true,
        dealExportEnqueueJobError: null,
      });
    case types.DEAL_EXPORT_ENQUEUE_JOB_SUCCESS:
      return Object.assign({}, state, {
        dealExportEnqueueJobLoading: false,
        dealExportEnqueueJobError: null,
      });
    case types.DEAL_EXPORT_ENQUEUE_JOB_FAILURE:
      return Object.assign({}, state, {
        dealExportEnqueueJobLoading: false,
        dealExportEnqueueJobError: payload,
      });

    /**
     * List portfolio export jobs
     */
    case types.LIST_PORTCO_EXPORT_REQUEST:
      return Object.assign({}, state, {
        portcoExportJobsLoading: true,
        portcoExportJobsError: null,
      });
    case types.LIST_PORTCO_EXPORT_SUCCESS:
      return Object.assign({}, state, {
        portcoExportJobs: {
          ...state.portcoExportJobs,
          ...payload.data.reduce((acc, portcoExportJob) => {
            acc[portcoExportJob.id] = portcoExportJob;
            return acc;
          }, {}),
        },
        portcoExportJobsLoading: false,
        portcoExportJobsError: null,
        portcoExportJobsMore:
          payload.data.length >= payload.options?.limit ? true : false,
      });
    case types.LIST_PORTCO_EXPORT_FAILURE:
      return Object.assign({}, state, {
        dealExportJobsLoading: false,
        dealExportJobsError: payload,
      });
    case types.LIST_PORTCO_EXPORT_CLEAR:
      return Object.assign({}, state, {
        portcoExportJobs: {},
      });

    /**
     * Enqueue export job for processing - reducers
     */
    case types.PORTCO_EXPORT_ENQUEUE_JOB_REQUEST:
      return Object.assign({}, state, {
        portcoExportEnqueueJobLoading: true,
        portcoExportEnqueueJobError: null,
      });
    case types.PORTCO_EXPORT_ENQUEUE_JOB_SUCCESS:
      return Object.assign({}, state, {
        portcoExportEnqueueJobLoading: false,
        portcoExportEnqueueJobError: null,
      });
    case types.PORTCO_EXPORT_ENQUEUE_JOB_FAILURE:
      return Object.assign({}, state, {
        portcoExportEnqueueJobLoading: false,
        portcoExportEnqueueJobError: payload,
      });

    default:
      return state;
  }
};
