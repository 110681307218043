/**
 * Microsoft connect container
 *
 * @format
 */

import Button from '@material-ui/core/Button';
import Footer from '../../components/Footer';
import GuestPage from '../../components/GuestPage';
import PropTypes from 'prop-types';
import React from 'react';
import WelcomePage from '../../components/WelcomePage';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadUser } from '../../modules/auth';
import { saveStage, fetchMicrosoftSetting } from '../../modules/setting';
import { withStyles } from '@material-ui/core/styles';
import MicrosoftLogo from '../../components/MicrosoftConnectForm/MicrosoftLogo';

const INTERVAL = 5000;

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    color: 'black',
    backgroundColor: 'white',
    border: '0.5px solid black',
  },
});

class MicrosoftConnect extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    completedStages: PropTypes.array.isRequired,
    saveStage: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    microsoftSettingLoading: PropTypes.bool.isRequired,
    fetchMicrosoftSetting: PropTypes.func.isRequired,
    saveStageLoading: PropTypes.bool.isRequired,
    microsoftSetting: PropTypes.object,
  };

  state = {
    timer: null,
  };

  componentDidMount() {
    const { completedStages, history } = this.props;
    //We use stage called 'ms-connect' here as that
    //is the stage where the user connects their microsoft/google
    //account it was named as such because when we began only
    //google account was needed but think of this stage as the stage where
    //user connects their microsoft/google accounts
    if (completedStages.indexOf('ms-connect') > -1) {
      history.push('/dashboard');
      return;
    }
    this.getMicrosoftData();
    const timer = setInterval(this.getMicrosoftData, INTERVAL);
    this.setState({
      timer,
    });
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
  }

  getMicrosoftData = () => {
    const { microsoftSettingLoading, fetchMicrosoftSetting } = this.props;
    if (microsoftSettingLoading) return;
    fetchMicrosoftSetting();
  };

  handleSubmit = event => {
    event && event.preventDefault();
    const { saveStage, loadUser, saveStageLoading } = this.props;
    if (saveStageLoading) return;
    saveStage('ms-connect')
      .then(data => loadUser('/welcome/finish'))
      .catch(err => {});
  };

  /**
   * When the user is already connected to his/her microsoft account we show this
   * message
   * @return {Node}
   */
  renderSuccessMicrosoftConnectMessage = () => {
    return (
      <div>
        <p>
          Successfully connected your Microsoft account, please click 'Next' to
          continue.
        </p>
      </div>
    );
  };

  /**
   * When user is signing up for the first time he/she is not connect to google
   * account we show this message
   * @return {Node}
   */
  renderMicrosoftConnectMessage = () => {
    return (
      <div>
        <p>
          You will need to <strong>connect your Microsoft account</strong> so
          that you can maximize your productivity with Taghash.
        </p>
        <p>
          We import only the emails you mark using the category
          <b> Taghash</b> in your Outlook inbox.
        </p>
      </div>
    );
  };

  render() {
    const { classes, saveStageLoading } = this.props;
    const { microsoftSetting, microsoftSettingLoading } = this.props;
    const isActive =
      microsoftSetting && microsoftSetting.microsoft_auth_status === 'active';
    return (
      <GuestPage>
        <WelcomePage
          header={
            isActive
              ? 'Successfully connected your Microsoft account!'
              : 'Connect your Microsoft account'
          }
          loader={
            saveStageLoading || (!microsoftSetting && microsoftSettingLoading)
          }
          buttonName={isActive ? 'Next' : ''}
          handleSubmit={isActive ? this.handleSubmit : null}
        >
          {isActive
            ? this.renderSuccessMicrosoftConnectMessage()
            : this.renderMicrosoftConnectMessage()}
          {microsoftSetting && !isActive && (
            <div>
              <Button
                className={classes.button}
                as="a"
                href={microsoftSetting.microsoft_auth_url}
                size="large"
                variant="contained"
                color="primary"
                disabled={saveStageLoading}
                startIcon={<MicrosoftLogo />}
              >
                Connect Microsoft Account
              </Button>
              <Button
                //className={classes.button}
                onClick={this.handleSubmit}
                size="large"
                variant="text"
                color="default"
                disabled={saveStageLoading}
              >
                I'll do it later
              </Button>
            </div>
          )}
        </WelcomePage>
        <Footer />
      </GuestPage>
    );
  }
}

const mapStateToProps = state => ({
  completedStages: state.auth.stage,
  microsoftSettingLoading: state.setting.microsoftSettingLoading,
  saveStageLoading: state.setting.saveStageLoading,
  microsoftSetting: state.setting.microsoftSetting,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMicrosoftSetting,
      saveStage,
      loadUser,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MicrosoftConnect)
);
