/**
 * Used to only increment the deal conversation count by 1
 * @format
 */
import {
  INCREMENT_DEAL_CONVERSATION_COUNT,
  DECREMENT_DEAL_CONVERSATION_COUNT,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case INCREMENT_DEAL_CONVERSATION_COUNT:
      return Object.assign({}, state, {
        dealsResourceCount: {
          ...state.dealsResourceCount,
          [payload.dealId]: {
            ...state.dealsResourceCount[payload.dealId],
            conversationsCount:
              state.dealsResourceCount[payload.dealId] &&
              state.dealsResourceCount[payload.dealId].conversationsCount
                ? state.dealsResourceCount[payload.dealId].conversationsCount +
                  payload.increment
                : payload.increment,
          },
        },
      });

    case DECREMENT_DEAL_CONVERSATION_COUNT:
      return Object.assign({}, state, {
        dealsResourceCount: {
          ...state.dealsResourceCount,
          [payload.dealId]: {
            ...state.dealsResourceCount[payload.dealId],
            conversationsCount:
              state.dealsResourceCount[payload.dealId] &&
              state.dealsResourceCount[payload.dealId].conversationsCount
                ? state.dealsResourceCount[payload.dealId].conversationsCount -
                    payload.decrement >
                  0
                  ? state.dealsResourceCount[payload.dealId]
                      .conversationsCount - payload.decrement
                  : 0
                : 0,
          },
        },
      });

    default:
      return state;
  }
};
