import * as types from './actions';
import isEmptyString from '../../helpers/string/isEmptyString';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const loadWorkspaceDealStages = (workspace_id, options={}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_WORKSPACE_DEAL_STAGE_LIST_REQUEST,
        payload: {
          workspaceId: workspace_id,
        },
      });
      request.get(`/api/v5/deal-stages`, {
        workspace_id,
        includeUnused: options.includeUnused,
        ignoreCache: options.ignoreCache,
      }, dispatch)
      .then(({ data }) => {
        if (data && data.success) {
          dispatch({
            type: types.LOAD_WORKSPACE_DEAL_STAGE_LIST_SUCCESS,
            payload: {
              workspaceId: workspace_id,
              data: data.data
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.LOAD_WORKSPACE_DEAL_STAGE_LIST_FAILURE,
            payload: {
              workspaceId: workspace_id,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        let message;
        message = `Something went wrong while loading workspace's deal stages. Please try again`;
        dispatch({
          type: types.LOAD_WORKSPACE_DEAL_STAGE_LIST_FAILURE,
          payload: {
            workspaceId: workspace_id,
            message,
          }
        });
        dispatch(enqueueNotification(message));
        return reject(err);
      });
    });
  };
};

export const updateDealStage = (deal_stage_id, position_id, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (isEmptyString(deal_stage_id) || isNaN(position_id) || position_id < 0) {
        // nothing to update
        dispatch(enqueueNotification(`Nothing to update`));
        return reject(new Error(`Nothing to update`));
      }
      const propertiesToUpdate = [];
      if (!isEmptyString(form.name)) {
        propertiesToUpdate.push('name');
      }
      if (!isEmptyString(form.color)) {
        propertiesToUpdate.push('color');
      }
      if (!isNaN(form.position_id) && form.position_id > -1) {
        propertiesToUpdate.push('position_id');
      }
      if (propertiesToUpdate.length === 0) {
        // nothing to update
        dispatch(enqueueNotification(`Nothing to update`));
        return reject(new Error(`Nothing to update`));
      }
      dispatch({
        type: types.UPDATE_DEAL_STAGE_REQUEST,
        payload: {
          position_id,
          propertiesToUpdate
        }
      });
      request.put(`/api/v2/deal-stages/${deal_stage_id}`, form, dispatch)
      .then(({ data }) => {
        if (data && data.success) {
          dispatch({
            type: types.UPDATE_DEAL_STAGE_SUCCESS,
            payload: {
              position_id,
              propertiesToUpdate,
              data: data.data
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.UPDATE_DEAL_STAGE_FAILURE,
            payload: {
              position_id,
              propertiesToUpdate,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = `Something went wrong while updating this deal stage. Please try again`;
        dispatch({
          type: types.UPDATE_DEAL_STAGE_FAILURE,
          payload: {
            position_id,
            propertiesToUpdate,
            message
          }
        });
        dispatch(enqueueNotification(message));
        return reject(err);
      });
    });
  };
};

export const softDeleteDealStage = (deal_stage_id, position_id, {migrate_deals_to_stage_id}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (isEmptyString(deal_stage_id) || isNaN(position_id) || position_id < 0) {
        // nothing to update
        dispatch(enqueueNotification(`Nothing to delete`));
        return reject(new Error(`Nothing to delete`));
      }
      if (isEmptyString(migrate_deals_to_stage_id)) {
        const message = `Missing reference to fallback deal stage for migrating deals`;
        // nothing to update
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.SOFT_DELETE_DEAL_STAGE_REQUEST,
        payload: {
          position_id,
        }
      });
      request.del(`/api/v2/deal-stages/${deal_stage_id}`, {migrate_deals_to_stage_id}, dispatch)
      .then(({ data }) => {
        if (data && data.success) {
          dispatch({
            type: types.SOFT_DELETE_DEAL_STAGE_SUCCESS,
            payload: {
              position_id,
              data: data.data
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.SOFT_DELETE_DEAL_STAGE_FAILURE,
            payload: {
              position_id,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = `Something went wrong while deleting this deal stage. Please try again`;
        dispatch({
          type: types.SOFT_DELETE_DEAL_STAGE_FAILURE,
          payload: {
            position_id,
            message
          }
        });
        dispatch(enqueueNotification(message));
        return reject(err);
      });
    });
  };
};
