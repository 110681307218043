/**
 *
 * @format
 */
import {
  LOAD_PORTFOLIO_TABLE_COLUMNS_REQUEST,
  LOAD_PORTFOLIO_TABLE_COLUMNS_SUCCESS,
  LOAD_PORTFOLIO_TABLE_COLUMNS_FAILURE,
} from '../actions';
import defaultTableViewColumnsConfig from '../defaultTableViewColumnsConfig';

export default (state, type, payload) => {
  switch (type) {
    case LOAD_PORTFOLIO_TABLE_COLUMNS_REQUEST:
      return Object.assign({}, state, {
        columnsLoading: true,
        columnsError: null,
      });
    case LOAD_PORTFOLIO_TABLE_COLUMNS_SUCCESS:
      return payload.length > 0
        ? Object.assign({}, state, {
            columnsLoading: false,
            columnsError: null,
            columns: payload,
          })
        : Object.assign({}, state, {
            columns: defaultTableViewColumnsConfig,
            columnsLoading: false,
            columnsError: null,
          });
    case LOAD_PORTFOLIO_TABLE_COLUMNS_FAILURE:
      return Object.assign({}, state, {
        columnsLoading: false,
        columnsError: payload.message,
      });
    default:
      return state;
  }
};
