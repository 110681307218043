import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  UPDATE_PINNED_STATUS_REQUEST,
  UPDATE_PINNED_STATUS_SUCCESS,
  UPDATE_PINNED_STATUS_FAILURE } from '../actions';

/**
 * Update pinned status
 * @param {String} noteId Note id
 * @param  {String} dealId Deal id
 * @param  {Boolean} is_pinned  Pinned Status
 * @return {Promise}       Resolves on success
 */
export default (noteId, dealId, is_pinned) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UPDATE_PINNED_STATUS_REQUEST,
        payload: { noteId, dealId }
      });
      request.put(`/api/v3/deals/${dealId}/notes/${noteId}/pin`, {is_pinned}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: UPDATE_PINNED_STATUS_SUCCESS,
            payload: {
              noteId,
              dealId,
              data: data.data,
            }
          });
          resolve(data.data);
        } else {
          dispatch({
            type: UPDATE_PINNED_STATUS_FAILURE,
            payload: {
              noteId,
              dealId,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while updating note, please try again.';
        dispatch({
          type: UPDATE_PINNED_STATUS_FAILURE,
          payload: {
            noteId,
            dealId,
            message
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
