/**
 * Loader component
 *
 * @author Ritesh Shrivastav
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  progress: {
    margin: '2em auto',
    display: 'block',
  },
  progressInline: {
    // Why `inline-block` and not `inline`? Because `inline-block` will respect
    // setting width/height. If this is set to `inline`, `size` prop will not be
    // respected
    // @see https://stackoverflow.com/a/14033814
    display: 'inline-block',
    margin: 'unset'
  },
  progressNoMargin: {
    margin: 'unset'
  },
};

class Loader extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    inline: PropTypes.bool,
    noMargin: PropTypes.bool,
    color: PropTypes.oneOf(['normal', 'primary']),
  };

  render() {
    const { active, classes, inline, noMargin, color, ...others } = this.props;
    return (active ? (
      <CircularProgress
        className={classNames(
          classes.progress,
          inline && classes.progressInline,
          noMargin && classes.progressNoMargin,
          color && classes[color]
        )}
        {...others}
      />
    ) : null);
  }
}

export default withStyles(styles)(Loader);
