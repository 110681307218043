/**
 * Sign offs reducers
 *
 */
import * as types from './actions';
import initialState from './initialState';

import addSignOffGroup from './reducerHandlers/addSignOffGroup';
import addSignOffItem from './reducerHandlers/addSignOffItem';
import updateSignOffGroup from './reducerHandlers/updateSignOffGroup';
import updateSignOffItem from './reducerHandlers/updateSignOffItem';
import addSignOffAssignee from './reducerHandlers/addSignOffAssignee';
import removeSignOffAssignee from './reducerHandlers/removeSignOffAssignee';
import approveSignOff from './reducerHandlers/approveSignOff';
import rejectSignOff from './reducerHandlers/rejectSignOff';
import addSignOffAttachment from './reducerHandlers/addSignOffAttachment';
import removeSignOffAttachment from './reducerHandlers/removeSignOffAttachment';
import deleteSignOffItem from './reducerHandlers/deleteSignOffItem';
import deleteSignOffGroup from './reducerHandlers/deleteSignOffGroup';
import listSignOffGroups from './reducerHandlers/listSignOffGroups';
import getSignOffGroup from './reducerHandlers/getSignOffGroup';
import listSignOffItems from './reducerHandlers/listSignOffItems';
import getSignOffItem from './reducerHandlers/getSignOffItem';
import listSignOffActivityLogs from './reducerHandlers/listSignOffActivityLogs';
import clearSignOffState from './reducerHandlers/clearSignOffState';

/**
 * Reducer handlers imports
 */
/**
 * Reducers
 */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * ADD_SIGN_OFF_GROUP reducers
     */
    case types.ADD_SIGN_OFF_GROUP_REQUEST:
    case types.ADD_SIGN_OFF_GROUP_SUCCESS:
    case types.ADD_SIGN_OFF_GROUP_FAILURE:
      return addSignOffGroup(state, type, payload);

    /**
     * ADD_SIGN_OFF_ITEM reducers
     */
    case types.ADD_SIGN_OFF_ITEM_REQUEST:
    case types.ADD_SIGN_OFF_ITEM_SUCCESS:
    case types.ADD_SIGN_OFF_ITEM_FAILURE:
      return addSignOffItem(state, type, payload);

    /**
     * UPDATE_SIGN_OFF_GROUP reducers
     */
    case types.UPDATE_SIGN_OFF_GROUP_REQUEST:
    case types.UPDATE_SIGN_OFF_GROUP_SUCCESS:
    case types.UPDATE_SIGN_OFF_GROUP_FAILURE:
      return updateSignOffGroup(state, type, payload);

    /**
     * UPDATE_SIGN_OFF_ITEM reducers
     */
    case types.UPDATE_SIGN_OFF_ITEM_REQUEST:
    case types.UPDATE_SIGN_OFF_ITEM_SUCCESS:
    case types.UPDATE_SIGN_OFF_ITEM_FAILURE:
      return updateSignOffItem(state, type, payload);

    /**
     * ADD_SIGN_OFF_ASSIGNEE reducers
     */
    case types.ADD_SIGN_OFF_ASSIGNEE_REQUEST:
    case types.ADD_SIGN_OFF_ASSIGNEE_SUCCESS:
    case types.ADD_SIGN_OFF_ASSIGNEE_FAILURE:
      return addSignOffAssignee(state, type, payload);

    /**
     * REMOVE_SIGN_OFF_ASSIGNEE reducers
     */
    case types.REMOVE_SIGN_OFF_ASSIGNEE_REQUEST:
    case types.REMOVE_SIGN_OFF_ASSIGNEE_SUCCESS:
    case types.REMOVE_SIGN_OFF_ASSIGNEE_FAILURE:
      return removeSignOffAssignee(state, type, payload);

    /**
     * APPROVE_SIGN_OFF reducers
     */
    case types.APPROVE_SIGN_OFF_REQUEST:
    case types.APPROVE_SIGN_OFF_SUCCESS:
    case types.APPROVE_SIGN_OFF_FAILURE:
      return approveSignOff(state, type, payload);

    /**
     * REJECT_SIGN_OFF reducers
     */
    case types.REJECT_SIGN_OFF_REQUEST:
    case types.REJECT_SIGN_OFF_SUCCESS:
    case types.REJECT_SIGN_OFF_FAILURE:
      return rejectSignOff(state, type, payload);

    /**
     * ADD_SIGN_OFF_ATTACHMENT reducers
     */
    case types.ADD_SIGN_OFF_ATTACHMENT_REQUEST:
    case types.ADD_SIGN_OFF_ATTACHMENT_SUCCESS:
    case types.ADD_SIGN_OFF_ATTACHMENT_FAILURE:
      return addSignOffAttachment(state, type, payload);

    /**
     * REMOVE_SIGN_OFF_ATTACHMENT reducers
     */
    case types.REMOVE_SIGN_OFF_ATTACHMENT_REQUEST:
    case types.REMOVE_SIGN_OFF_ATTACHMENT_SUCCESS:
    case types.REMOVE_SIGN_OFF_ATTACHMENT_FAILURE:
      return removeSignOffAttachment(state, type, payload);

    /**
     * DELETE_SIGN_OFF_ITEM reducers
     */
    case types.DELETE_SIGN_OFF_ITEM_REQUEST:
    case types.DELETE_SIGN_OFF_ITEM_SUCCESS:
    case types.DELETE_SIGN_OFF_ITEM_FAILURE:
      return deleteSignOffItem(state, type, payload);

    /**
     * DELETE_SIGN_OFF_GROUP reducers
     */
    case types.DELETE_SIGN_OFF_GROUP_REQUEST:
    case types.DELETE_SIGN_OFF_GROUP_SUCCESS:
    case types.DELETE_SIGN_OFF_GROUP_FAILURE:
      return deleteSignOffGroup(state, type, payload);

    /**
     * LIST_SIGN_OFF_GROUPS reducers
     */
    case types.LIST_SIGN_OFF_GROUPS_REQUEST:
    case types.LIST_SIGN_OFF_GROUPS_SUCCESS:
    case types.LIST_SIGN_OFF_GROUPS_FAILURE:
      return listSignOffGroups(state, type, payload);

    /**
     * GET_SIGN_OFF_GROUP reducers
     */
    case types.GET_SIGN_OFF_GROUP_REQUEST:
    case types.GET_SIGN_OFF_GROUP_SUCCESS:
    case types.GET_SIGN_OFF_GROUP_FAILURE:
      return getSignOffGroup(state, type, payload);

    /**
     * LIST_SIGN_OFF_ITEMS reducers
     */
    case types.LIST_SIGN_OFF_ITEMS_REQUEST:
    case types.LIST_SIGN_OFF_ITEMS_SUCCESS:
    case types.LIST_SIGN_OFF_ITEMS_FAILURE:
      return listSignOffItems(state, type, payload);

    /**
     * GET_SIGN_OFF_ITEM reducers
     */
    case types.GET_SIGN_OFF_ITEM_REQUEST:
    case types.GET_SIGN_OFF_ITEM_SUCCESS:
    case types.GET_SIGN_OFF_ITEM_FAILURE:
      return getSignOffItem(state, type, payload);

    /**
     * LIST_SIGN_OFF_ACTIVITY_LOGS reducers
     */
    case types.LIST_SIGN_OFF_ACTIVITY_LOGS_REQUEST:
    case types.LIST_SIGN_OFF_ACTIVITY_LOGS_SUCCESS:
    case types.LIST_SIGN_OFF_ACTIVITY_LOGS_FAILURE:
      return listSignOffActivityLogs(state, type, payload);

    /**
     * CLEAR_SIGN_OFFS_STATE reducers
     */
    case types.CLEAR_SIGN_OFFS_STATE:
      return clearSignOffState(state, type, payload);

    default:
      return state;
  }
};
