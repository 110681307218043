/**
 * Fetches categories associated with the portco
 *
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_CATEGORIES_REQUEST,
  LOAD_CATEGORIES_SUCCESS,
  LOAD_CATEGORIES_FAILURE,
} from '../actions';

export default portcoId => {
  return async dispatch => {
    dispatch({
      type: LOAD_CATEGORIES_REQUEST,
      payload: {
        portcoId,
      },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/categories/v1/companies/${portcoId}`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading categories, please try again.';
      dispatch({
        type: LOAD_CATEGORIES_FAILURE,
        payload: {
          message,
          portcoId,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: LOAD_CATEGORIES_SUCCESS,
        payload: {
          data: data.data,
          portcoId,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: LOAD_CATEGORIES_FAILURE,
        payload: {
          message: data.message,
          portcoId,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
