export const LOAD_CATEGORY_LIST_REQUEST = 'category/LOAD_CATEGORY_LIST_REQUEST';
export const LOAD_CATEGORY_LIST_SUCCESS = 'category/LOAD_CATEGORY_LIST_SUCCESS';
export const LOAD_CATEGORY_LIST_FAILURE = 'category/LOAD_CATEGORY_LIST_FAILURE';

export const ADD_CATEGORY_REQUEST = 'category/ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = 'category/ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'category/ADD_CATEGORY_FAILURE';

export const REMOVE_CATEGORY_REQUEST = 'category/REMOVE_CATEGORY_REQUEST';
export const REMOVE_CATEGORY_SUCCESS = 'category/REMOVE_CATEGORY_SUCCESS';
export const REMOVE_CATEGORY_FAILURE = 'category/REMOVE_CATEGORY_FAILURE';

export const REGENERATE_CATEGORY_REQUEST = 'category/REGENERATE_CATEGORY_REQUEST';
export const REGENERATE_CATEGORY_SUCCESS = 'category/REGENERATE_CATEGORY_SUCCESS';
export const REGENERATE_CATEGORY_FAILURE = 'category/REGENERATE_CATEGORY_FAILURE';

export const UPDATE_ROOT_CATEGORY_SETTING = 'category/UPDATE_ROOT_CATEGORY_SETTING';

