/** @format */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const getSheetList = (portcoId, options = {}) => {
  return async dispatch => {
    if (!portcoId) {
      const message = 'Please select a company to load sheets for';
      dispatch({
        type: types.GET_SHEET_LIST_FAILURE,
        payload: {
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.GET_SHEET_LIST_REQUEST,
      payload: {
        portcoId,
      },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/v1/companies/${portcoId}/sheets`,
        options,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading sheets for this company. Please try again.';
      dispatch({
        type: types.GET_SHEET_LIST_FAILURE,
        payload: {
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      const list = data.data;
      dispatch({
        type: types.GET_SHEET_LIST_SUCCESS,
        payload: {
          portcoId,
          data: list,
        },
      });
      list.forEach(sheet => {
        dispatch({
          type: types.GET_SHEET_SUCCESS,
          payload: {
            sheetId: sheet.id,
            data: sheet,
          },
        });
      });
      return list;
    } else {
      dispatch({
        type: types.GET_SHEET_LIST_FAILURE,
        payload: {
          portcoId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const addSheet = (portcoId, form) => {
  return async dispatch => {
    if (!portcoId) {
      const message = 'Please select a company to add the sheet to';
      dispatch({
        type: types.ADD_SHEET_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.ADD_SHEET_REQUEST,
      payload: {},
    });
    let data;
    try {
      const response = await request.post(
        `/api/portfolio/v1/companies/${portcoId}/sheets`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      let message =
        'Something went wrong while adding sheet. Please try again.';
      if (err?.response?.status === 429) {
        message = `Too fast! Try again in a couple of seconds.`;
      }
      dispatch({
        type: types.ADD_SHEET_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.ADD_SHEET_SUCCESS,
        payload: {
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.ADD_SHEET_FAILURE,
        payload: {
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

/**
 * Delete a sheet
 * @param {String} portcoId Portfolio Company's id
 * @param {String} sheetId Portfolio Sheet's id
 */
export const deleteSheet = (portcoId, sheetId) => {
  return async dispatch => {
    if (!portcoId || !sheetId) {
      const message = 'Missing key input in deleting sheet';
      dispatch({
        type: types.DELETE_SHEET_FAILURE,
        payload: {
          sheetId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.DELETE_SHEET_REQUEST,
      payload: {
        sheetId,
      },
    });
    let data;
    try {
      const response = await request.del(
        `/api/portfolio/v1/companies/${portcoId}/sheets/${sheetId}`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message = `Something went wrong while deleting sheet. Please try again.`;
      dispatch({
        type: types.DELETE_SHEET_FAILURE,
        payload: {
          sheetId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }

    if (data.success) {
      dispatch({
        type: types.DELETE_SHEET_SUCCESS,
        payload: {
          sheetId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.DELETE_SHEET_FAILURE,
        payload: {
          sheetId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const getSheet = (portcoId, sheetId, queryParams = {}) => {
  return async dispatch => {
    if (!portcoId || !sheetId) {
      const message = 'Please select a sheet to load';
      dispatch({
        type: types.GET_SHEET_FAILURE,
        payload: {
          sheetId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.GET_SHEET_REQUEST,
      payload: {
        sheetId,
      },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/v1/companies/${portcoId}/sheets/${sheetId}`,
        queryParams,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading sheet. Please try again.';
      dispatch({
        type: types.GET_SHEET_FAILURE,
        payload: {
          sheetId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.GET_SHEET_SUCCESS,
        payload: {
          sheetId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.GET_SHEET_FAILURE,
        payload: {
          sheetId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const updateSheet = (portcoId, sheetId, form) => {
  return async dispatch => {
    if (!portcoId || !sheetId) {
      const message = 'Please select a sheet to update';
      dispatch({
        type: types.UPDATE_SHEET_FAILURE,
        payload: {
          sheetId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.UPDATE_SHEET_REQUEST,
      payload: {
        sheetId,
      },
    });
    let data;
    try {
      const response = await request.put(
        `/api/portfolio/v1/companies/${portcoId}/sheets/${sheetId}`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while updating sheet. Please try again.';
      dispatch({
        type: types.UPDATE_SHEET_FAILURE,
        payload: {
          sheetId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.UPDATE_SHEET_SUCCESS,
        payload: {
          sheetId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.UPDATE_SHEET_FAILURE,
        payload: {
          sheetId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const addSheetColumns = (
  portcoId,
  sheetId,
  form,
  options = { multiple: false }
) => {
  return async dispatch => {
    if (!portcoId || !sheetId) {
      const message = 'Please select a sheet to add columns to';
      dispatch({
        type: types.ADD_COLUMNS_FAILURE,
        payload: {
          sheetId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.ADD_COLUMNS_REQUEST,
      payload: {
        sheetId,
      },
    });
    let data;
    let uri = `/api/portfolio/v1/companies/${portcoId}/sheets/${sheetId}/columns`;
    if (options.multiple) {
      // to add multiple columns at once
      uri = uri + `?multiple=true`;
    }
    try {
      const response = await request.post(uri, form, dispatch);
      data = response.data;
    } catch (err) {
      const message = `Something went wrong while adding columns to sheet. Please try again.`;
      dispatch({
        type: types.ADD_COLUMNS_FAILURE,
        payload: {
          sheetId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }

    if (data.success) {
      dispatch({
        type: types.ADD_COLUMNS_SUCCESS,
        payload: {
          sheetId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.ADD_COLUMNS_FAILURE,
        payload: {
          sheetId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const getSheetColumns = (portcoId, sheetId) => {
  return async dispatch => {
    if (!portcoId || !sheetId) {
      const message = 'Please select a sheet to load';
      dispatch({
        type: types.GET_SHEET_COLUMNS_FAILURE,
        payload: {
          sheetId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.GET_SHEET_COLUMNS_REQUEST,
      payload: {
        sheetId,
      },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/v1/companies/${portcoId}/sheets/${sheetId}/columns`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message = `Something went wrong while loading sheet's columns. Please try again.`;
      dispatch({
        type: types.GET_SHEET_COLUMNS_FAILURE,
        payload: {
          sheetId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }

    if (data.success) {
      dispatch({
        type: types.GET_SHEET_COLUMNS_SUCCESS,
        payload: {
          sheetId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.GET_SHEET_COLUMNS_FAILURE,
        payload: {
          sheetId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const getSheetRows = (portcoId, sheetId) => {
  return async dispatch => {
    if (!portcoId || !sheetId) {
      const message = 'Please select a sheet to load';
      dispatch({
        type: types.GET_SHEET_ROWS_FAILURE,
        payload: {
          sheetId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.GET_SHEET_ROWS_REQUEST,
      payload: {
        sheetId,
      },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/v1/companies/${portcoId}/sheets/${sheetId}/rows`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message = `Something went wrong while loading sheet's rows. Please try again.`;
      dispatch({
        type: types.GET_SHEET_ROWS_FAILURE,
        payload: {
          sheetId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }

    if (data.success) {
      dispatch({
        type: types.GET_SHEET_ROWS_SUCCESS,
        payload: {
          sheetId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.GET_SHEET_ROWS_FAILURE,
        payload: {
          sheetId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

/**
 * Delete a sheet's row at a given position
 * @param {String} portcoId Portfolio Company's id
 * @param {String} sheetId Portfolio Sheet's id
 * @param {Number} position Position of the row to delete
 */
export const deleteSheetRow = (portcoId, sheetId, position) => {
  return async dispatch => {
    if (!portcoId || !sheetId || typeof position !== 'number') {
      const message = 'Missing key input in deleting row';
      dispatch({
        type: types.DELETE_SHEET_ROW_FAILURE,
        payload: {
          sheetId,
          position,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.DELETE_SHEET_ROW_REQUEST,
      payload: {
        sheetId,
        position,
      },
    });
    let data;
    try {
      const response = await request.del(
        `/api/portfolio/v1/companies/${portcoId}/sheets/${sheetId}/rows/${position}`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message = `Something went wrong while loading sheet's rows. Please try again.`;
      dispatch({
        type: types.DELETE_SHEET_ROW_FAILURE,
        payload: {
          sheetId,
          position,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }

    if (data.success) {
      dispatch({
        type: types.DELETE_SHEET_ROW_SUCCESS,
        payload: {
          sheetId,
          position,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.DELETE_SHEET_ROW_FAILURE,
        payload: {
          sheetId,
          position,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const bulkUpdateSheet = (patch, portcoId, sheetId) => {
  return async dispatch => {
    if (!portcoId || !sheetId) {
      const message = 'Please select a sheet to load';
      dispatch({
        type: types.BULK_UPDATE_SHEET_FAILURE,
        payload: { message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.BULK_UPDATE_SHEET_REQUEST,
    });

    let data;
    try {
      const response = await request.post(
        `/api/portfolio/v1/companies/${portcoId}/sheets/${sheetId}/bulk`,
        patch,
        dispatch
      );
      data = response.data;
    } catch (err) {
      let message =
        'Something went wrong while loading sheet. Please try again.';
      if (err?.response?.status === 429) {
        message = `Too fast! Try again in a couple of seconds.`;
      }
      dispatch({
        type: types.BULK_UPDATE_SHEET_FAILURE,
        payload: { message },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.BULK_UPDATE_SHEET_SUCCESS,
      });
      return data.data;
    } else {
      dispatch({
        type: types.BULK_UPDATE_SHEET_FAILURE,
        payload: { message: data.message },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

/**
 * Load 20210710_mis_report
 * @param {*} portcoId
 * @returns
 */
export const get20210710MetricsReport = (
  generate_report_for,
  portcoId,
  is_20210710_mis_report_enabled = false
) => {
  return async dispatch => {
    if (!is_20210710_mis_report_enabled) {
      const message = 'This feature is not enabled for your account';
      dispatch({
        type: types.REPORT_MIS_20210710_FAILURE,
        payload: {
          portcoId,
          message,
          errorCode: -1,
        },
      });
      dispatch(enqueueNotification(message));
      const err = new Error(message);
      err.code = -1;
      throw err;
    }
    if (!portcoId) {
      const message = 'Please select a company to load';
      dispatch({
        type: types.REPORT_MIS_20210710_FAILURE,
        payload: {
          portcoId,
          message,
          errorCode: -1,
        },
      });
      dispatch(enqueueNotification(message));
      const err = new Error(message);
      err.code = -1;
      throw err;
    }
    dispatch({
      type: types.REPORT_MIS_20210710_REQUEST,
      payload: {
        portcoId,
      },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/v1/companies/${portcoId}/reports/20210710_mis_report`,
        { generate_report_for },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading report. Please try again.';
      dispatch({
        type: types.REPORT_MIS_20210710_FAILURE,
        payload: {
          portcoId,
          message,
          errorCode: -1,
        },
      });
      err.code = -1;
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.REPORT_MIS_20210710_SUCCESS,
        payload: {
          portcoId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.REPORT_MIS_20210710_FAILURE,
        payload: {
          portcoId,
          message: data.message,
          errorCode: data.code,
        },
      });
      // If it's resource not found error, don't dispatch notification
      if (data.code !== 102) {
        dispatch(enqueueNotification(data.message));
      }
      const err = new Error(data.message);
      err.code = data.code;
      throw err;
    }
  };
};

export const clearSheets = () => {
  return dispatch => {
    return new Promise(resolve => {
      dispatch({
        type: types.CLEAR_SHEETS,
      });
      resolve();
    });
  };
};
