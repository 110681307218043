/**
 * Removes a founder from the startup associated with the portco
 *
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  REMOVE_STARTUP_FOUNDER_REQUEST,
  REMOVE_STARTUP_FOUNDER_SUCCESS,
  REMOVE_STARTUP_FOUNDER_FAILURE,
} from '../actions';

export default (portcoId, form) => {
  return async dispatch => {
    dispatch({
      type: REMOVE_STARTUP_FOUNDER_REQUEST,
      payload: { portcoId },
    });
    let data;
    try {
      const response = await request.put(
        `/api/portfolio/v1/companies/${portcoId}/founders/remove`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while removing founder, please try again.';
      dispatch({
        type: REMOVE_STARTUP_FOUNDER_FAILURE,
        payload: { portcoId, message },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: REMOVE_STARTUP_FOUNDER_SUCCESS,
        payload: { portcoId, data: data.data },
      });
      return data.data;
    } else {
      dispatch({
        type: REMOVE_STARTUP_FOUNDER_FAILURE,
        payload: { portcoId, message: data.message },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
