/**
 * @format
 */
import {
  REMOVE_CUSTOM_FIELDS_REQUEST,
  REMOVE_CUSTOM_FIELDS_SUCCESS,
  REMOVE_CUSTOM_FIELDS_FAILURE,
} from '../actions';
import { enqueueNotification } from '../../app';
import request from '../../../helpers/request';

export default customFieldNames => {
  return async dispatch => {
    dispatch({
      type: REMOVE_CUSTOM_FIELDS_REQUEST,
    });
    let data;
    try {
      let deletedNames = customFieldNames
        .map(cfName => `name=${encodeURIComponent(cfName)}`)
        .join('&');
      let path = `/api/portfolio-fields/v1/fields?${deletedNames}`;

      const response = await request.del(path);
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while deleting custom fields, please try again.';
      dispatch({
        type: REMOVE_CUSTOM_FIELDS_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({ type: REMOVE_CUSTOM_FIELDS_SUCCESS });
      dispatch(enqueueNotification('removed custom field successfully!'));
      return data;
    } else {
      dispatch({
        type: REMOVE_CUSTOM_FIELDS_FAILURE,
        payload: { message: data.message },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
