/**
 * List sign off groups in a deal
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LIST_SIGN_OFF_GROUPS_REQUEST,
  LIST_SIGN_OFF_GROUPS_SUCCESS,
  LIST_SIGN_OFF_GROUPS_FAILURE,
} from '../actions';

export default (deal_id, options = {}) => {
  return async dispatch => {
    dispatch({
      type: LIST_SIGN_OFF_GROUPS_REQUEST,
      payload: {
        dealId: deal_id,
      },
    });

    let data;
    try {
      const response = await request.get(
        `/api/pipeline/sign-offs/v1/deal/${deal_id}/sign-off-groups`,
        options,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while fetching sign off groups in a deal, please try again.';
      dispatch({
        type: LIST_SIGN_OFF_GROUPS_FAILURE,
        payload: {
          dealId: deal_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: LIST_SIGN_OFF_GROUPS_SUCCESS,
        payload: {
          dealId: deal_id,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: LIST_SIGN_OFF_GROUPS_FAILURE,
        payload: {
          dealId: deal_id,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
