import {
  CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_REQUEST,
  CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_SUCCESS,
  CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_REQUEST:
      return Object.assign({}, state, {
        convertFounderToExternalReferrerLoading: {
          ...state.convertFounderToExternalReferrerLoading,
          [payload.dealId]: true
        },
        convertFounderToExternalReferrerError: {
          ...state.convertFounderToExternalReferrerError,
          [payload.dealId]: null
        }
      });

    case CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_SUCCESS:
      // create a deep copy of the section of states that are heading for clearance
      const convertFounderToExternalReferrerClear = Object.assign({},
        state, {
          convertFounderToExternalReferrerLoading: {
            ...state.convertFounderToExternalReferrerLoading,
          },
          convertFounderToExternalReferrerError: {
            ...state.convertFounderToExternalReferrerError,
          }
        });
      // clear the target items
      delete convertFounderToExternalReferrerClear.convertFounderToExternalReferrerLoading[payload.dealId];
      delete convertFounderToExternalReferrerClear.convertFounderToExternalReferrerError[payload.dealId];
      return convertFounderToExternalReferrerClear;

    case CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_FAILURE:
      return Object.assign({}, state, {
        convertFounderToExternalReferrerLoading: {
          ...state.convertFounderToExternalReferrerLoading,
          [payload.dealId]: false
        },
        convertFounderToExternalReferrerError: {
          ...state.convertFounderToExternalReferrerError,
          [payload.dealId]: payload.message
        }
      });

    default:
      return state;
  }
};
