/** @format */

import * as types from './actions';
import reducers from './reducers';
import request, { wrap, api, ApplicationError } from '../../helpers/request';
import isEmptyString from '../../helpers/string/isEmptyString';
import { enqueueNotification } from '../app';

// mark reducers as the default export
export default reducers;

/**
 *
 * @param {String} fundId Id of the fund the LPs are being added to
 * @param {Array} arrayOfLPs Accepts Lp details in an array format
 * @returns
 */
export function addLpToFund(fundId, arrayOfLPs) {
  return async dispatch => {
    dispatch({
      type: types.ADD_LP_TO_FUND_REQUEST,
      payload: {
        fundId,
      },
    });

    try {
      const { data } = await request.post(
        `/api/funds/v1/funds/${fundId}/limited_partners`,
        arrayOfLPs,
        dispatch
      );

      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }

      const response = data.data[0];
      const { success } = response;
      if (!success) {
        throw new ApplicationError(response.message, response);
      }

      dispatch({
        type: types.ADD_LP_TO_FUND_SUCCESS,
        payload: { fundId, data: data.data },
      });

      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while adding investors. Please try again.';
      dispatch({
        type: types.ADD_LP_TO_FUND_FAILURE,
        payload: { fundId, message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(err.message);
    }
  };
}

export function loadFundLps(fund_id, queryParams) {
  return async function(dispatch) {
    dispatch({
      type: types.LOAD_FUND_LPS_REQUEST,
      payload: {
        options: queryParams,
      },
    });

    try {
      const { data } = await wrap(
        api.get(
          `/api/funds/v1/funds/${fund_id}/limited_partners`,
          { params: queryParams },
          dispatch
        )
      );

      dispatch({
        type: types.LOAD_FUND_LPS_SUCCESS,
        payload: {
          fund_id,
          data: data.data,
          noOfLps: data.metadata.count,
        },
      });

      return data;
    } catch (err) {
      dispatch({
        type: types.LOAD_FUND_LPS_FAILURE,
        payload: { message: err.message },
      });
      throw err;
    }
  };
}

export function loadAllFundLpTags(fundId) {
  if (isEmptyString(fundId)) {
    throw new Error('Fund id missing');
  }
  return async dispatch => {
    dispatch({
      type: types.LOAD_FUND_LPS_LIST_TAGS_REQUEST,
      payload: { fundId },
    });

    const { data } = await wrap(
      api.get(`/api/funds/v1/funds/${fundId}/limited_partners/tags`)
    );

    if (!data.success) {
      dispatch({
        type: types.LOAD_FUND_LPS_LIST_TAGS_FAILURE,
        payload: { message: data.message },
      });
      throw new Error(data.message);
    }
    dispatch({
      type: types.LOAD_FUND_LPS_LIST_TAGS_SUCCESS,
      payload: {
        tags: data.data,
      },
    });

    return data;
  };
}

export function addTagToLp(fundId, limitedPartnerId, tag) {
  if (isEmptyString(fundId)) {
    throw new Error('Fund id missing');
  } else if (isEmptyString(limitedPartnerId)) {
    throw new Error('Limited partner id missing ');
  }

  return async dispatch => {
    dispatch({
      type: types.ADD_TAG_TO_LP_REQUEST,
    });

    const { data } = await wrap(
      api.post(
        `/api/funds/v1/funds/${fundId}/limited_partners/${limitedPartnerId}/tag`,
        { tag },
        dispatch
      )
    );

    if (!data.success) {
      dispatch({
        type: types.ADD_TAG_TO_LP_FAILURE,
        payload: { message: data.message },
      });
      dispatch(
        enqueueNotification(
          `Something went wrong when adding tag to the limited partner:${data.message}`
        )
      );
      throw new Error(data.message);
    }

    dispatch({
      type: types.ADD_TAG_TO_LP_SUCCESS,
      payload: { fundId, limitedPartnerId, tag },
    });
  };
}

export function removeTagFromLp(fundId, limitedPartnerId, tag) {
  if (isEmptyString(fundId)) {
    throw new Error('Fund id missing');
  } else if (isEmptyString(limitedPartnerId)) {
    throw new Error('Limited partner id missing ');
  }
  return async dispatch => {
    dispatch({
      type: types.REMOVE_TAG_FROM_LP_REQUEST,
    });

    const response = await wrap(
      api.delete(
        `/api/funds/v1/funds/${fundId}/limited_partners/${limitedPartnerId}/untag`,
        { data: { tag } },
        dispatch
      )
    );

    const { data } = response;

    if (!data.success) {
      dispatch({
        type: types.REMOVE_TAG_FROM_LP_FAILURE,
        payload: { message: data.message },
      });
      dispatch(
        enqueueNotification(
          'Something went wrong when removing tag from this limited partner'
        )
      );
      throw new Error(data.message);
    }

    dispatch({
      type: types.REMOVE_TAG_FROM_LP_SUCCESS,
      payload: { fundId, limitedPartnerId, tag },
    });
  };
}

/**
 * Loads limited partner's details, by its id
 * @param {UUID} limitedPartnerId Limited partner's id
 * @param {UUID} fundId Fund's id
 * @param {Object} options Additional options to pass
 * @returns Limited partner object
 */
export function loadLimitedPartner(limitedPartnerId, fundId, options = {}) {
  if (isEmptyString(fundId)) {
    throw new Error('Fund id missing');
  } else if (isEmptyString(limitedPartnerId)) {
    throw new Error('Limited partner id missing ');
  }
  return async dispatch => {
    dispatch({
      type: types.LOAD_LP_REQUEST,
      payload: {
        fundId,
        limitedPartnerId,
      },
    });

    try {
      const { data } = await wrap(
        api.get(
          `/api/funds/v1/funds/${fundId}/limited_partners/${limitedPartnerId}`,
          { params: options },
          dispatch
        )
      );

      dispatch({
        type: types.LOAD_LP_SUCCESS,
        payload: { fundId, limitedPartnerId, data: data.data },
      });

      return data.data;
    } catch (err) {
      dispatch({
        type: types.LOAD_LP_FAILURE,
        payload: { fundId, limitedPartnerId, message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message || 'Something went wrong when loading limited partner'
        )
      );
      throw new Error(err.message);
    }
  };
}

/**
 * Update limited partner
 * @param {Object} form Attributes to update
 * @param {UUID} limitedPartnerId Limited partner's id
 * @param {UUID} fundId Fund's id
 * @returns Updated limited partner
 */
export function updateLimitedPartner(form, limitedPartnerId, fundId) {
  if (isEmptyString(fundId)) {
    throw new Error('Fund id missing');
  } else if (isEmptyString(limitedPartnerId)) {
    throw new Error('Limited partner id missing ');
  }
  return async dispatch => {
    dispatch({
      type: types.UPDATE_LP_REQUEST,
      payload: {
        fundId,
        limitedPartnerId,
      },
    });

    try {
      const { data } = await wrap(
        api.put(
          `/api/funds/v1/funds/${fundId}/limited_partners/${limitedPartnerId}`,
          form,
          dispatch
        )
      );

      dispatch({
        type: types.UPDATE_LP_SUCCESS,
        payload: { fundId, limitedPartnerId, data: data.data },
      });

      return data.data;
    } catch (err) {
      dispatch({
        type: types.UPDATE_LP_FAILURE,
        payload: { fundId, limitedPartnerId, message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message || 'Something went wrong when updating limited partner'
        )
      );
      throw new Error(err.message);
    }
  };
}

/**
 * Delete limited partner
 * @param {UUID} limitedPartnerId Limited partner's id
 * @param {UUID} fundId Fund's id
 */
export function deleteLimitedPartner(limitedPartnerId, fundId) {
  if (isEmptyString(fundId)) {
    throw new Error('Fund id missing');
  } else if (isEmptyString(limitedPartnerId)) {
    throw new Error('Limited partner id missing ');
  }
  return async dispatch => {
    dispatch({
      type: types.DELETE_LP_REQUEST,
      payload: {
        fundId,
        limitedPartnerId,
      },
    });

    try {
      const { data } = await request.del(
        `/api/funds/v1/funds/${fundId}/limited_partners/${limitedPartnerId}`,
        {},
        dispatch
      );

      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }

      dispatch({
        type: types.DELETE_LP_SUCCESS,
        payload: { fundId, limitedPartnerId, data: data.data },
      });

      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while deleting this investor. Please try again.';
      dispatch({
        type: types.DELETE_LP_FAILURE,
        payload: { fundId, limitedPartnerId, message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(err.message);
    }
  };
}

// Clears redux state
export const clearLimitedPartnerState = () => dispatch =>
  dispatch({ type: types.CLEAR });
