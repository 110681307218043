import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';
import { initializeFirebaseMessaging } from '../app/pushNotifications';

export default reducers;

// Initialize firebase
export const initializeFirebase = () => {
  return dispatch => {
    // returns promise
    return initializeFirebaseMessaging(dispatch);
  }
}

export const loadNotificationList = (options) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_NOTIFICATION_LIST_REQUEST,
        payload: {}
      });
      request.get(`/api/v2/notifications`, options, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_NOTIFICATION_LIST_SUCCESS,
            payload: {
              data: data.data,
              options: options,
              metadata: data.metadata // contains unread notification count
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.LOAD_NOTIFICATION_LIST_FAILURE,
            payload: {
              message: data.message,
            }
          });
          dispatch(enqueueNotification(data.message));
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading notifications, please try again.';
        dispatch({
          type: types.LOAD_NOTIFICATION_LIST_FAILURE,
          payload: {
            message,
          }
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      });
    });
  };
};

export const markNotificationAsRead = (notificationId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.MARK_NOTIFICATION_READ_REQUEST,
        payload: {
          notificationId
        }
      });
      request.put(`/api/v2/notifications/${notificationId}/mark-read`, {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.MARK_NOTIFICATION_READ_SUCCESS,
            payload: {
              notificationId,
              data: data.data
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.MARK_NOTIFICATION_READ_FAILURE,
            payload: {
              notificationId,
              message: data.message,
            }
          });
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while marking notification as read, please try again.';
        dispatch({
          type: types.MARK_NOTIFICATION_READ_FAILURE,
          payload: {
            notificationId,
            message,
          }
        });
        return reject(new Error(message));
      });
    });
  };
};

export const markAllNotificationsAsRead = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.MARK_ALL_NOTIFICATIONS_READ_REQUEST,
        payload: {}
      });
      request.put(`/api/v2/notifications/mark-read`, {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.MARK_ALL_NOTIFICATIONS_READ_SUCCESS,
            payload: {
              data: data.data
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.MARK_ALL_NOTIFICATIONS_READ_FAILURE,
            payload: {
              message: data.message,
            }
          });
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while marking notification as read, please try again.';
        dispatch({
          type: types.MARK_ALL_NOTIFICATIONS_READ_FAILURE,
          payload: {
            message,
          }
        });
        return reject(new Error(message));
      });
    });
  };
};

export const setNewIncomingNotification = () => {
  return dispatch => {
    dispatch({
      type: types.SET_NEW_INCOMING_NOTIFICATION,
    });
  };
};
