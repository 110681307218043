/**
* App container
*
* @author Ritesh Shrivastav
* @author Akhila
*/
import asyncComponent from "../components/AsyncComponent";
import Alert from '../components/Alert';
import EnsureSignedIn from '../components/EnsureSignedIn';
import GoogleRedirect from './GoogleRedirect';
import MicrosoftRedirect from './MicrosoftRedirect';
import HeadManager from '../components/HeadManager';
import NotFound from '../components/NotFound';
import Privacy from '../components/Privacy';
import ContactUs from "../components/ContactUs";
import SecurityPolicyPage from '../components/SecurityPolicyPage';
import PropTypes from 'prop-types';
import React from 'react';
import Signup from './Signup';
import SplashScreen from '../components/SplashScreen';
import Terms from '../components/Terms';
import Welcome from './Welcome';
import InternalLink from '../components/InternalLink';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { dequeueNotification } from '../modules/app';
import { loadUser } from '../modules/auth';
import { Route, Switch, withRouter } from "react-router-dom";

const Dashboard = asyncComponent(() => import('./Dashboard'));
const Login = asyncComponent(() => import('./Login'));
const ResetPasswordFinish = asyncComponent(() => import('./Auth/ResetPasswordFinish'));
const ResetPasswordInit = asyncComponent(() => import('./Auth/ResetPasswordInit'));
const Oauth2Authorize = asyncComponent(() => import('./Auth/Oauth2Authorize'));
const RequestInvite = asyncComponent(() => import('./Invitations/RequestInvite'));

class App extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    logoutLoading: PropTypes.bool.isRequired,
    loadUser: PropTypes.func.isRequired,
    loadUserLoading: PropTypes.bool.isRequired,
    loadUserError: PropTypes.string,
    user: PropTypes.object,
  };

  componentDidMount() {
    this.loadUser()
      // Ignore success and error here. `componentDidUpdate` will take care of
      // hiding splash
      .catch(() => {});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadUserLoading !== this.props.loadUserLoading &&
      !this.props.loadUserLoading) {
      this.hideSplash();
    }
  }

  state = {
    showSplash: true
  };

  hideSplash = (cb) => {
    this.setState({ showSplash: false }, cb);
  };

  loadUser = () => {
    const { loadUser, loadUserLoading } = this.props;
    return new Promise((resolve, reject) => {
      // There's already an ongoing request. Resolve empty
      if (loadUserLoading) { return resolve() };
      return loadUser().then(resolve).catch(reject);
    });
  };

  render() {
    const { user, loadUserLoading, loadUserError, history } = this.props;
    return (
      <div className="app">
        {this.state.showSplash && <SplashScreen />} 
        {this.props.logoutLoading && <SplashScreen message="Logging you out" />}
        <Alert
          {...this.props.app}
          dequeueNotification={this.props.dequeueNotification}
        />
        <HeadManager defaultTitle="Taghash" titleTemplate="%s - Taghash" />
        <Switch>
          <Route exact path="/" component={() => {
            if (!!user) {
              history.push('/dashboard');
              return null;
            } else {
              return (<InternalLink path={"/"} />);
            }
          }} />
          <Route exact path="/terms-and-conditions/v1" component={Terms} />
          <Route exact path="/privacy-policy/v1" component={Privacy} />
          <Route exact path="/security-policy/v1" component={SecurityPolicyPage} />
          <Route exact path="/contact-us/v1" component={ContactUs}/>
          <Route exact path="/login" component={Login} />
          <Route exact path="/invite/request" component={RequestInvite} />
          <Route exact path="/invite/:token/:email" component={Signup} />
          <Route exact path="/reset-password" component={ResetPasswordInit} />
          <Route exact
            path="/reset-password-finish/:token/:email"
            component={ResetPasswordFinish}
          />
          <Route path="/settings/google" component={GoogleRedirect} />
          <Route path="/settings/microsoft" component={MicrosoftRedirect} />
          <EnsureSignedIn
            user={user}
            loadUserLoading={loadUserLoading}
            loadUserError={loadUserError}
            loadUser={this.loadUser}>
            <Switch>
              <Route path="/dashboard/" component={Dashboard} />
              <Route path="/welcome/" component={Welcome} />
              <Route path="/oauth/authorize" component={Oauth2Authorize} />
              <Route path="*" component={NotFound} />
            </Switch>
          </EnsureSignedIn>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loadUserLoading: state.auth.loadUserLoading,
  loadUserError: state.auth.loadUserError,
  app: state.app,
  logoutLoading: state.auth.logoutLoading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadUser,
  dequeueNotification,
}, dispatch);

//
// https://github.com/ReactTraining/react-router/issues/4756
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));
