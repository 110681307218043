/**
 * DataTransport action creators
 *
 * @format
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const dealExportGetSignedURL = (
  data_transport_id,
  options = { download: true }
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.DEAL_EXPORT_GET_SIGNED_URL_REQUEST,
        payload: {
          dataTransportId: data_transport_id,
        },
      });
      request
        .get(
          `/api/v5/data-transports/deals/exports/getSignedUrl`,
          { data_transport_id, ...options },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.DEAL_EXPORT_GET_SIGNED_URL_SUCCESS,
              payload: {
                dataTransportId: data_transport_id,
                data,
              },
            });
            resolve(data);
          } else {
            dispatch({
              type: types.DEAL_EXPORT_GET_SIGNED_URL_FAILURE,
              payload: {
                dataTransportId: data_transport_id,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while downloading exported csv, please try again.';
          dispatch({
            type: types.DEAL_EXPORT_GET_SIGNED_URL_FAILURE,
            payload: {
              dataTransportId: data_transport_id,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const enqueueDealExportJob = form => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.DEAL_EXPORT_ENQUEUE_JOB_REQUEST,
      });
      request
        .post(`/api/v5/data-transports/deals/exports/enqueue`, form, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.DEAL_EXPORT_ENQUEUE_JOB_SUCCESS,
            });
            const message =
              data.message ||
              'Export request registered successfully. We will generate your csv in the background ' +
                'and will send you an email once the deals have been exported. ' +
                'Please continue using Taghash in the meantime.';
            // Make the notification sticky, since it is lengthy
            dispatch(enqueueNotification(message, { sticky: true }));
            resolve(data);
          } else {
            dispatch({
              type: types.DEAL_EXPORT_ENQUEUE_JOB_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while enqueuing export job, please try again.';
          dispatch({
            type: types.DEAL_EXPORT_ENQUEUE_JOB_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const listDealExportJobs = options => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LIST_DEAL_EXPORT_REQUEST,
        payload: {
          options,
        },
      });
      request
        .get(`/api/v5/data-transports/deals/exports`, options, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LIST_DEAL_EXPORT_SUCCESS,
              payload: {
                data: data.data,
                options,
              },
            });
            resolve(data);
          } else {
            dispatch({
              type: types.LIST_DEAL_EXPORT_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while listing export jobs, please try again.';
          dispatch({
            type: types.LIST_DEAL_EXPORT_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const clearDealExportJobs = options => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LIST_DEAL_EXPORT_CLEAR,
      });
      resolve();
    });
  };
};

export const listPortcoExportJobs = options => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LIST_PORTCO_EXPORT_REQUEST,
        payload: {
          options,
        },
      });
      request
        .get(`/api/export/v1/exports`, options, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LIST_PORTCO_EXPORT_SUCCESS,
              payload: {
                data: data.data,
                options,
              },
            });
            resolve(data);
          } else {
            const message =
              'Something went wrong while listing export jobs, please try again.';
            dispatch({
              type: types.LIST_PORTCO_EXPORT_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while listing export jobs, please try again.';
          dispatch({
            type: types.LIST_PORTCO_EXPORT_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const enqueuePortcoExportJob = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.PORTCO_EXPORT_ENQUEUE_JOB_REQUEST,
      });
      request
        .post(`/api/export/v1/exports`)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.PORTCO_EXPORT_ENQUEUE_JOB_SUCCESS,
            });
            const message =
              data.message || 'Export request registered successfully.';
            // Make the notification sticky, since it is lengthy
            dispatch(enqueueNotification(message, { sticky: true }));
            resolve(data);
          } else {
            const message =
              'Something went wrong while enqueuing export job, please try again.';
            dispatch({
              type: types.PORTCO_EXPORT_ENQUEUE_JOB_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while enqueuing export job, please try again.';
          dispatch({
            type: types.PORTCO_EXPORT_ENQUEUE_JOB_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const clearPortcoExportJobs = options => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LIST_PORTCO_EXPORT_CLEAR,
      });
      resolve();
    });
  };
};
