export const LOAD_MAGIC_SYNC_SUGGESTIONS_REQUEST = 'magicSync/LOAD_MAGIC_SYNC_SUGGESTIONS_REQUEST';
export const LOAD_MAGIC_SYNC_SUGGESTIONS_SUCCESS = 'magicSync/LOAD_MAGIC_SYNC_SUGGESTIONS_SUCCESS';
export const LOAD_MAGIC_SYNC_SUGGESTIONS_FAILURE = 'magicSync/LOAD_MAGIC_SYNC_SUGGESTIONS_FAILURE';

export const ADD_DEAL_FROM_CONTACT_REQUEST = 'magicSync/ADD_DEAL_FROM_CONTACT_REQUEST';
export const ADD_DEAL_FROM_CONTACT_SUCCESS = 'magicSync/ADD_DEAL_FROM_CONTACT_SUCCESS';
export const ADD_DEAL_FROM_CONTACT_FAILURE = 'magicSync/ADD_DEAL_FROM_CONTACT_FAILURE';

export const DISCARD_FROM_CONTACT_REQUEST = 'magicSync/DISCARD_FROM_CONTACT_REQUEST';
export const DISCARD_FROM_CONTACT_SUCCESS = 'magicSync/DISCARD_FROM_CONTACT_SUCCESS';
export const DISCARD_FROM_CONTACT_FAILURE = 'magicSync/DISCARD_FROM_CONTACT_FAILURE';

export const UPDATE_SUGGESTION_DEAL_NAME = 'magicSync/UPDATE_SUGGESTION_DEAL_NAME';