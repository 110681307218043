/**
 * @format
 */

import * as types from '../actions';

import { assign } from '../../../helpers/object';
import property from 'lodash.property';

export default (state, type, payload) => {
  switch (type) {
    /** GET_NAV_UPDATE */
    case types.GET_NAV_UPDATE_REQUEST: {
      return assign(state, {
        navUpdateLoading: assign(state.navUpdateLoading, {
          [payload.navUpdateId]: true,
        }),
        navUpdateError: assign(state.navUpdateError, {
          [payload.navUpdateId]: null,
        }),
      });
    }
    case types.GET_NAV_UPDATE_FAILURE: {
      return assign(state, {
        navUpdateLoading: assign(state.navUpdateLoading, {
          [payload.navUpdateId]: false,
        }),
        navUpdateError: assign(state.navUpdateError, {
          [payload.navUpdateId]: payload.message,
        }),
      });
    }
    case types.GET_NAV_UPDATE_SUCCESS: {
      return assign(state, {
        navUpdateLoading: assign(state.navUpdateLoading, {
          [payload.navUpdateId]: false,
        }),
        navUpdateError: assign(state.navUpdateError, {
          [payload.navUpdateId]: null,
        }),
        navUpdate: assign(state.navUpdate, {
          [payload.navUpdateId]: assign(
            property(payload.navUpdateId)(state.navUpdate),
            payload.data
          ),
        }),
      });
    }

    default:
      return state;
  }
};
