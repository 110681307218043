export const CREATE_FUND_GENERAL_PARTNER_REQUEST = 'fund-people/CREATE_FUND_GENERAL_PARTNER_REQUEST';
export const CREATE_FUND_GENERAL_PARTNER_SUCCESS = 'fund-people/CREATE_FUND_GENERAL_PARTNER_SUCCESS';
export const CREATE_FUND_GENERAL_PARTNER_FAILURE = 'fund-people/CREATE_FUND_GENERAL_PARTNER_FAILURE';

export const LIST_FUND_GENERAL_PARTNERS_REQUEST = 'fund-people/LIST_FUND_GENERAL_PARTNERS_REQUEST';
export const LIST_FUND_GENERAL_PARTNERS_SUCCESS = 'fund-people/LIST_FUND_GENERAL_PARTNERS_SUCCESS';
export const LIST_FUND_GENERAL_PARTNERS_FAILURE = 'fund-people/LIST_FUND_GENERAL_PARTNERS_FAILURE';

export const EDIT_FUND_GENERAL_PARTNER_REQUEST = 'fund-people/EDIT_FUND_GENERAL_PARTNER_REQUEST';
export const EDIT_FUND_GENERAL_PARTNER_SUCCESS = 'fund-people/EDIT_FUND_GENERAL_PARTNER_SUCCESS';
export const EDIT_FUND_GENERAL_PARTNER_FAILURE = 'fund-people/EDIT_FUND_GENERAL_PARTNER_FAILURE';

export const REMOVE_FUND_GENERAL_PARTNER_REQUEST = 'fund-people/REMOVE_FUND_GENERAL_PARTNER_REQUEST';
export const REMOVE_FUND_GENERAL_PARTNER_SUCCESS = 'fund-people/REMOVE_FUND_GENERAL_PARTNER_SUCCESS';
export const REMOVE_FUND_GENERAL_PARTNER_FAILURE = 'fund-people/REMOVE_FUND_GENERAL_PARTNER_FAILURE';

export const CREATE_FUND_CONTACT_PERSON_REQUEST = 'fund-people/CREATE_FUND_CONTACT_PERSON_REQUEST';
export const CREATE_FUND_CONTACT_PERSON_SUCCESS = 'fund-people/CREATE_FUND_CONTACT_PERSON_SUCCESS';
export const CREATE_FUND_CONTACT_PERSON_FAILURE = 'fund-people/CREATE_FUND_CONTACT_PERSON_FAILURE';

export const LIST_FUND_CONTACT_PERSON_REQUEST = 'fund-people/LIST_FUND_CONTACT_PERSON_REQUEST';
export const LIST_FUND_CONTACT_PERSON_SUCCESS = 'fund-people/LIST_FUND_CONTACT_PERSON_SUCCESS';
export const LIST_FUND_CONTACT_PERSON_FAILURE = 'fund-people/LIST_FUND_CONTACT_PERSON_FAILURE';

export const EDIT_FUND_CONTACT_PERSON_REQUEST = 'fund-people/EDIT_FUND_CONTACT_PERSON_REQUEST';
export const EDIT_FUND_CONTACT_PERSON_SUCCESS = 'fund-people/EDIT_FUND_CONTACT_PERSON_SUCCESS';
export const EDIT_FUND_CONTACT_PERSON_FAILURE = 'fund-people/EDIT_FUND_CONTACT_PERSON_FAILURE';

export const REMOVE_FUND_CONTACT_PERSON_REQUEST = 'fund-people/REMOVE_FUND_CONTACT_PERSON_REQUEST';
export const REMOVE_FUND_CONTACT_PERSON_SUCCESS = 'fund-people/REMOVE_FUND_CONTACT_PERSON_SUCCESS';
export const REMOVE_FUND_CONTACT_PERSON_FAILURE = 'fund-people/REMOVE_FUND_CONTACT_PERSON_FAILURE';