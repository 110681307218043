import { 
  ADD_DEAL_EVENT_NOTE_REQUEST,
  ADD_DEAL_EVENT_NOTE_SUCCESS,
  ADD_DEAL_EVENT_NOTE_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case ADD_DEAL_EVENT_NOTE_REQUEST:
      return Object.assign({}, state, {
        addEventNoteLoading: true,
        addEventNoteError: null
      });

    case ADD_DEAL_EVENT_NOTE_SUCCESS:
      return Object.assign({}, state, {
        addEventNoteLoading: false,
        addEventNoteError: null
      });
      
    case ADD_DEAL_EVENT_NOTE_FAILURE:
      return Object.assign({}, state, {
        addEventNoteLoading: false,
        addEventNoteError: payload,
      });

    default:
      return state;
  }
};
