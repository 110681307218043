import { TOGGLE_DEAL_SUMMARY } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case TOGGLE_DEAL_SUMMARY:
      return Object.assign({}, state, {
        dealSummaryExpanded: {
          ...state.dealSummaryExpanded,
          [payload.dealId]: !!!state.dealSummaryExpanded[payload.dealId]
        }
      });

    default:
      return state;
  }
};
