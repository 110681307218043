/**
 * @format
 * Holds all the person contacts columns, do not modify keys.
 *
 * To add a new column:
 * - add here
 * - implement appropriate AbcCell container, AbcCell, AbcCell/Viewer, AbcCell/Viewers
 * - add spec in getColumns
 * - add in defaultTableColumnConfig
 * - add transformation in getRowStructure
 * - add support in backend to save new column
 */
const PersonContactTableViewColumn = {
  SERIAL_NO: {
    key: 'serial_no',
    sticky: true,
    name: '',
  },
  NAME: {
    key: 'name',
    sticky: true,
    name: 'Name',
  },
  EMAIL: {
    key: 'email_primary',
    name: 'Email',
  },
  PHONE_NUMBER: {
    key: 'phone_primary',
    name: 'Phone Number',
  },
  TAGS: {
    key: 'tags',
    name: 'Tags',
  },
  ORGANIZATION_CONTACTS: {
    key: 'organization_contacts',
    name: 'Organization Contacts',
  },
  // SOCIAL_LINKS: {
  //   key: 'social_links',
  //   name: 'Social links',
  // },
};

export default PersonContactTableViewColumn;
