/** Initial state for personContact resources */
export default {
  /**
   * State that holds all the loaded personContacts
   * This object is namespaced by a person's id, and the value
   * will be the person's data
   */
  people: {},
  // Stores the loading, err state for `getPersonContact` calls. Namespaced by personContactId
  getPersonContactLoading: {},
  getPersonContactError: {},
  // update person contact.  Namespaced by personContactId
  updatePersonContactLoading: {},
  updatePersonContactError: {},
  // recent interactions.  Namespaced by personContactId
  recentInteractions: {},
  recentInteractionsLoading: {},
  recentInteractionsError: {},
};
