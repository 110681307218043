import { KEY_TYPES } from '../../../constants/DealActivityLog';
import {
  GET_DEAL_ACTIVITY_LOG_REQUEST,
  GET_DEAL_ACTIVITY_LOG_SUCCESS,
  GET_DEAL_ACTIVITY_LOG_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case GET_DEAL_ACTIVITY_LOG_REQUEST:
      return Object.assign({}, state, {
        dealActivityLogLoading: {
          ...state.dealActivityLogLoading,
          [payload.dealId]: true
        },
        dealActivityLogError: {
          ...state.dealActivityLogError,
          [payload.dealId]: null
        }
      });

    case GET_DEAL_ACTIVITY_LOG_SUCCESS: {
      const newActivityLogList = payload.options.page === 1
        ? payload.data
        : [
            /**
            * Filtering the state.dealActivityLogs[deal.id] by removing the logs
            * which might present in the payload.data to avoid duplicates
            * while appending payload.
            */
            ...(
              state.dealActivityLogs[payload.dealId]
              ? state.dealActivityLogs[payload.dealId].filter(
                  log => !payload.data.find(({ id }) => log.id === id)
                )
              : []),
            ...payload.data,
          ];
      // If we've got the same number of activity log entries as the limit, then
      // more likely than not, we have more activity logs to load in the next page
      const newDealActivityLogMore = (
        payload.options && payload.options.limit &&
        payload.options.limit === payload.data.length
      );
      /**
       * There are valid circumstances where, even if we have activity logs for
       * a deal, the log does not represent a complete history of all actions
       * that happen on a deal. This can happen for two reasons:
       * 1. For the deals that exist prior to shipping the activity log feature,
       *    we won't have any activity logs so won't have any track of changes
       * 2. For deals that are added via import csv feature, where created date
       *    is set to a historic date during import
       *
       * These two possibilities can be reduced into saying that, if the earliest
       * activity log in the history of the deal is not about the creation of the
       * deal (pre-deployment deals), or if there is a mismatch in the action_time
       * of the deal creation activity log and deal's created_at by more than a
       * minute (imported historic deals), then we can consider that our
       * knowledge of the deal's activity is incomplete
       */
      if (!newDealActivityLogMore && newActivityLogList.length > 0) {
        const lastActivityLog = newActivityLogList[newActivityLogList.length - 1];
        if (
          payload.deal_created_at &&
          (lastActivityLog.key !== KEY_TYPES.ID ||
          !(
            Math.abs(new Date(payload.deal_created_at) - new Date(lastActivityLog.action_time)) <
              60000
          ))
        ) {
          // Pseudo activity log, appended to the very end of the activity log
          newActivityLogList.push({
            id: '',
            key: KEY_TYPES.POSSIBLE_MISSING,
            action_time: payload.deal_created_at,
            extra: null,
            user: {},
            value: null,
          });
        }
      }
      return Object.assign({}, state, {
        dealActivityLogs: {
          ...state.dealActivityLogs,
          [payload.dealId]: newActivityLogList,
        },
        dealActivityLogOptions: {
          ...state.dealActivityLogOptions,
          [payload.dealId]: payload.options
        },
        dealActivityLogMore: {
          ...state.dealActivityLogMore,
          [payload.dealId]: newDealActivityLogMore
        },
        dealActivityLogLoading: {
          ...state.dealActivityLogLoading,
          [payload.dealId]: false
        },
        dealActivityLogError: {
          ...state.dealActivityLogError,
          [payload.dealId]: null
        }
      });
    }
    case GET_DEAL_ACTIVITY_LOG_FAILURE:
      return Object.assign({}, state, {
        dealActivityLogLoading: {
          ...state.dealActivityLogLoading,
          [payload.dealId]: false
        },
        dealActivityLogError: {
          ...state.dealActivityLogError,
          [payload.dealId]: payload.message
        },
      });

    default:
      return state;
  }
};
