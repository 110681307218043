/** @format */

import * as types from './actions';
import reducers from './reducers';
import { wrap, api, ApplicationError } from '../../helpers/request';
import { enqueueNotification } from '../app';

// mark reducers as the default export
export default reducers;

// LoadTransactions loads all the transactions for the given portfolio company
export function loadTransactions(id, params = {}) {
  return async function(dispatch) {
    params.dispatch !== false &&
      dispatch({
        type: types.LOAD_PORTFOLIO_TRANSACTIONS_REQUEST,
        payload: { id, filters: params },
      });
    try {
      const { data } = await wrap(
        api.get(`/api/portfolio-valuation/v1/companies/${id}/transactions`, {
          params,
        })
      );

      if (!data.success) {
        throw new Error(data.message);
      }

      params.dispatch !== false &&
        dispatch({
          type: types.LOAD_PORTFOLIO_TRANSACTIONS_SUCCESS,
          payload: { id, filters: params, data: data.data },
        });

      return data;
    } catch (err) {
      params.dispatch !== false &&
        dispatch({
          type: types.LOAD_PORTFOLIO_TRANSACTIONS_FAILURE,
          payload: { id },
        });
      throw err;
    }
  };
}

// AddTransaction adds the provided transaction to the portfolio company
export function addTransaction(id, txn) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.post(`/api/portfolio-valuation/v1/companies/${id}/transactions`, txn)
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.ADD_PORTFOLIO_TRANSACTION,
      payload: { id, data: data.data },
    });

    return data;
  };
}

// DeleteTransaction deletes the provided transaction from the portfolio company
export function deleteTransaction(id, txn) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.delete(
        `/api/portfolio-valuation/v1/companies/${id}/transactions/${txn}`
      )
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.DELETE_PORTFOLIO_TRANSACTION,
      payload: { id, txn },
    });
  };
}

// DeleteTransaction deletes the provided transaction from the portfolio company
export function convertTransaction(id, txn, values) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.post(
        `/api/portfolio-valuation/v1/companies/${id}/transactions/${txn}/convert`,
        values
      )
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.CONVERT_PORTFOLIO_TRANSACTION,
      payload: { id, data: data.data },
    });
  };
}

export function updateTransaction(id, txn, values) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.put(
        `/api/portfolio-valuation/v1/companies/${id}/transactions/${txn}`,
        values
      )
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.UPDATE_PORTFOLIO_TRANSACTION,
      payload: { id, txn, data: data.data },
    });
  };
}

// LoadValuations loads all the valuation events for the given portfolio company
export function loadValuations(id, params) {
  return async function(dispatch) {
    dispatch({
      type: types.LOAD_PORTFOLIO_VALUATIONS_REQUEST,
      payload: { id, filters: params },
    });

    try {
      const { data } = await wrap(
        api.get(`/api/portfolio-valuation/v1/companies/${id}/valuations`, {
          params,
        })
      );

      if (!data.success) {
        throw new Error(data.message);
      }

      dispatch({
        type: types.LOAD_PORTFOLIO_VALUATIONS_SUCCESS,
        payload: { id, filters: params, data: data.data },
      });

      return data;
    } catch (err) {
      dispatch({
        type: types.LOAD_PORTFOLIO_VALUATIONS_FAILURE,
        payload: { id },
      });
      throw err;
    }
  };
}

// AddValuation adds the provided valuation event to the portfolio company
export function addValuation(id, value) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.post(`/api/portfolio-valuation/v1/companies/${id}/valuations`, value)
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.ADD_PORTFOLIO_VALUATION,
      payload: { id, data: data.data },
    });

    return data;
  };
}

export function updateValuation(id, val, values) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.put(
        `/api/portfolio-valuation/v1/companies/${id}/valuations/${val}`,
        values
      )
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.UPDATE_PORTFOLIO_VALUATION,
      payload: { id, val, data: data.data },
    });
  };
}

// deleteValuation removes a valuation event from the portfolio company
export function deleteValuation(portcoId, valuationId) {
  return async function(dispatch) {
    dispatch({ type: types.DELETE_PORTFOLIO_VALUATION_REQUEST });
    const { data } = await wrap(
      api.delete(
        `/api/portfolio-valuation/v1/companies/${portcoId}/valuations/${valuationId}`
      )
    );

    if (!data.success) {
      dispatch({ type: types.DELETE_PORTFOLIO_VALUATION_FAILURE });
      throw new Error(data.message);
    }

    dispatch({
      type: types.DELETE_PORTFOLIO_VALUATION_SUCCESS,
      payload: {
        portcoId,
        valuationId,
        // for backward compatibility only
        id: portcoId,
      },
    });

    return data;
  };
}

// LoadFundingRounds loads all the funding rounds for the given portfolio company
export function loadFundingRounds(id, params) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.get(`/api/portfolio-valuation/v1/companies/${id}/rounds`, {
        params,
      })
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.LOAD_PORTFOLIO_FUNDING_ROUNDS,
      payload: { id, filters: params, data: data.data },
    });

    return data.data;
  };
}

// AddFundingRound adds the provided funding round to the portfolio company
export function addFundingRound(id, round) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.post(`/api/portfolio-valuation/v1/companies/${id}/rounds`, round)
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.ADD_PORTFOLIO_FUNDING_ROUND,
      payload: { id, data: data.data },
    });

    return data.data;
  };
}

/**
 * Adds funding rounds in bulk (can also be used to add a single funding round with an array of 1 element)
 * @param {Array} arrayOfFundingRounds Accepts funding round details in an array format
 * @returns
 */
export function addFundingRoundInBulk(arrayOfFundingRounds) {
  return async dispatch => {
    dispatch({
      type: types.ADD_FUNDING_ROUND_IN_BULK_REQUEST,
    });

    try {
      const { data } = await wrap(
        api.post(
          `/api/portfolio-valuation/v1/companies/rounds/bulk-upload`,
          arrayOfFundingRounds
        )
      );

      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }

      dispatch({
        type: types.ADD_FUNDING_ROUND_IN_BULK_SUCCESS,
      });

      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while adding funding rounds. Please try again.';
      dispatch({
        type: types.ADD_FUNDING_ROUND_IN_BULK_FAILURE,
      });
      dispatch(enqueueNotification(message));
      throw new Error(err.message);
    }
  };
}

/**
 * Adds valuations in bulk (can also be used to add a single valuation with an array of 1 element)
 * @param {Array} arrayOfValuations Accepts valuations details in an array format
 * @returns
 */
export function addValuationInBulk(arrayOfValuations) {
  return async dispatch => {
    dispatch({
      type: types.ADD_VALUATION_IN_BULK_REQUEST,
    });

    try {
      const { data } = await wrap(
        api.post(
          `/api/portfolio-valuation/v1/companies/valuations/bulk-upload`,
          arrayOfValuations
        )
      );

      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }

      dispatch({
        type: types.ADD_VALUATION_IN_BULK_SUCCESS,
      });

      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while adding funding rounds. Please try again.';
      dispatch({
        type: types.ADD_VALUATION_IN_BULK_FAILURE,
      });
      dispatch(enqueueNotification(message));
      throw new Error(err.message);
    }
  };
}

/**
 * Adds direct investment transaction in bulk (can also be used to add a single transaction with an array of 1 element)
 * @param {Array} arrayOfDirectTransactions Accepts direct transaction details in an array format
 * @returns
 */
export function bulkUploadDirectInvestmentTransaction(
  arrayOfDirectTransactions
) {
  return async dispatch => {
    dispatch({
      type: types.ADD_DIRECT_INVESTMENT_IN_BULK_REQUEST,
    });

    try {
      const { data } = await wrap(
        api.post(
          `/api/portfolio-valuation/v1/companies/transaction/direct-investment/bulk-upload`,
          arrayOfDirectTransactions
        )
      );

      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }

      dispatch({
        type: types.ADD_DIRECT_INVESTMENT_IN_BULK_SUCCESS,
      });

      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while adding transactions. Please try again.';
      dispatch({
        type: types.ADD_DIRECT_INVESTMENT_IN_BULK_FAILURE,
      });
      dispatch(enqueueNotification(message));
      throw new Error(err.message);
    }
  };
}

/**
 * Adds direct investment transaction in bulk (can also be used to add a single transaction with an array of 1 element)
 * @param {Array} arrayOfDirectTransactions Accepts direct transaction details in an array format
 * @returns
 */
export function bulkUploadIndirectInvestmentTransaction(
  arrayOfInDirectTransactions
) {
  return async dispatch => {
    dispatch({
      type: types.ADD_IN_DIRECT_INVESTMENT_IN_BULK_REQUEST,
    });

    try {
      const { data } = await wrap(
        api.post(
          `/api/portfolio-valuation/v1/companies/transaction/in-direct-investment/bulk-upload`,
          arrayOfInDirectTransactions
        )
      );

      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }

      dispatch({
        type: types.ADD_IN_DIRECT_INVESTMENT_IN_BULK_SUCCESS,
      });

      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while adding transactions. Please try again.';
      dispatch({
        type: types.ADD_IN_DIRECT_INVESTMENT_IN_BULK_FAILURE,
      });
      dispatch(enqueueNotification(message));
      throw new Error(err.message);
    }
  };
}

// deleteFundingRound removes the given funding round from the portfolio company
export function deleteFundingRound(portcoId, roundId) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.delete(
        `/api/portfolio-valuation/v1/companies/${portcoId}/rounds/${roundId}`
      )
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.DELETE_PORTFOLIO_FUNDING_ROUND,
      payload: {
        portcoId,
        roundId,
      },
    });
  };
}

export function clearValuationState() {
  return async function(dispatch) {
    dispatch({ type: types.CLEAR });
  };
}
