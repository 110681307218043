import {
  LOAD_DEAL_EVENT_LIST_REQUEST,
  LOAD_DEAL_EVENT_LIST_SUCCESS,
  LOAD_DEAL_EVENT_LIST_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_DEAL_EVENT_LIST_REQUEST:
      return Object.assign({}, state, {
        dealEventsLoading: {
          ...state.dealEventsLoading,
          [payload.dealId]: true
        },
        dealEventsError: {
          ...state.dealEventsError,
          [payload.dealId]: null
        }
      });
    
    case LOAD_DEAL_EVENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        dealEvents: {
          ...state.dealEvents,
          [payload.dealId]: (
            (payload.options.page === 1) ? payload.data : [...state.dealEvents[payload.dealId], ...payload.data]
          )
        },
        dealEventsLoading: {
          ...state.dealEventsLoading,
          [payload.dealId]: false
        },
        dealEventsError: {
          ...state.dealEventsError,
          [payload.dealId]: null
        },
        dealEventsOptions: {
          ...state.dealEventsOptions,
          [payload.dealId]: payload.options
        },
        dealEventsMore: {
          ...state.dealEventsMore,
          [payload.dealId]: (payload.data.length >= payload.options.limit ? true : false)
        }
      });
    
    case LOAD_DEAL_EVENT_LIST_FAILURE:
      return Object.assign({}, state, {
        dealEventsLoading: {
          ...state.dealEventsLoading,
          [payload.dealId]: false
        },
        dealEventsError: {
          ...state.dealEventsError,
          [payload.dealId]: payload.message
        },
      });

    default:
      return state;
  }
};
