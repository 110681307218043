/** @format */
import * as types from './actions';
import { assign } from '../../../helpers/object';
import reduce from 'lodash.reduce';
import property from 'lodash.property';
import uniqBy from 'lodash.uniqby';

const initialState = {
  personContactDocumentLoading: {},
  personContactDocumentError: {},
  personContactDocuments: {},

  createPersonContactDocumentLoading: false,
  personContactDocumentsListLoading: false,
  personContactDocumentsCount: {},
  tagsListLoading: false,

  tags: [],

  getPersonContactDocumentOptions: {
    q: '',
    tags: [],
    sortBy: '',
    sortOrder: '',
    limit: '',
    page: '',
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CREATE_PERSON_CONTACT_DOCUMENT_REQUEST: {
      return assign(state, {
        createPersonContactDocumentLoading: true,
      });
    }
    case types.CREATE_PERSON_CONTACT_DOCUMENT_SUCCESS: {
      return assign(state, {
        createPersonContactDocumentLoading: false,
        personContactDocuments: assign(
          state.personContactDocuments,
          reduce(
            payload.data,
            (acc, { document }) => {
              // no need to `assign` here, since it's a fresh doc
              // added a empty tags array for newly created fund document to add tags without reloading the page
              acc[document.id] = { ...document, tags: [] };
              return acc;
            },
            {}
          )
        ),
        personContactDocumentsCount: {
          ...state.personContactDocumentsCount,
          [payload.personContactId]: {
            ...state.personContactDocumentsCount[payload.personContactId],
            all:
              state.personContactDocumentsCount[payload.personContactId] &&
              state.personContactDocumentsCount[payload.personContactId].all
                ? state.personContactDocumentsCount[payload.personContactId]
                    .all + payload.data.length
                : 1,
          },
        },
      });
    }
    case types.CREATE_PERSON_CONTACT_DOCUMENT_FAILURE: {
      return assign(state, {
        createPersonContactDocumentLoading: false,
      });
    }
    //
    case types.LIST_PERSON_CONTACT_DOCUMENTS_REQUEST: {
      return assign(state, {
        personContactDocumentsListLoading: true,
      });
    }
    case types.LIST_PERSON_CONTACT_DOCUMENTS_SUCCESS: {
      return assign(state, {
        personContactDocumentsListLoading: false,
        personContactDocuments: assign(
          state.personContactDocuments,
          reduce(
            payload.data,
            (acc, item) => {
              // merge with existing state, if it exists
              acc[item.id] = assign(
                property(`personContactDocuments.${item.id}`)(state) || {},
                item
              );
              return acc;
            },
            {}
          )
        ),
        personContactDocumentsCount: {
          ...state.personContactDocumentsCount,
          [payload.personContactId]: {
            all: payload.metadata.documentsCount || 0,
            hidden: payload.metadata.hiddenDocumentsCount || 0,
          },
        },
      });
    }
    case types.LIST_PERSON_CONTACT_DOCUMENTS_FAILURE: {
      return assign(state, {
        personContactDocumentsListLoading: false,
      });
    }

    case types.GET_PERSON_CONTACT_DOCUMENT_SIGNEDURL_REQUEST:
    case types.ADD_TAG_TO_PERSON_CONTACT_DOCUMENT_REQUEST:
    case types.REMOVE_TAG_FROM_PERSON_CONTACT_DOCUMENT_REQUEST:
    case types.DELETE_PERSON_CONTACT_DOCUMENT_REQUEST:
    case types.HIDE_OR_UNHIDE_PERSON_CONTACT_DOCUMENT_REQUEST:
    case types.FILE_UPLOAD_S3_REQUEST:
    case types.FILE_DOWNLOAD_S3_REQUEST:
      return Object.assign({}, state, {
        personContactDocumentLoading: {
          ...state.personContactDocumentLoading,
          [payload.documentId]: true,
        },
        personContactDocumentError: {
          ...state.personContactDocumentError,
          [payload.documentId]: null,
        },
      });
    case types.GET_PERSON_CONTACT_DOCUMENT_SIGNEDURL_SUCCESS:
    case types.DELETE_PERSON_CONTACT_DOCUMENT_SUCCESS:
    case types.HIDE_OR_UNHIDE_PERSON_CONTACT_DOCUMENT_SUCCESS:
    case types.FILE_UPLOAD_S3_SUCCESS:
    case types.FILE_DOWNLOAD_S3_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        personContactDocumentLoading: {
          ...state.personContactDocumentLoading,
        },
        personContactDocumentError: {
          ...state.personContactDocumentError,
        },
      });
      // clear the target items
      delete newState.personContactDocumentLoading[payload.documentId];
      delete newState.personContactDocumentError[payload.documentId];
      return newState;
    }
    case types.GET_PERSON_CONTACT_DOCUMENT_SIGNEDURL_FAILURE:
    case types.ADD_TAG_TO_PERSON_CONTACT_DOCUMENT_FAILURE:
    case types.REMOVE_TAG_FROM_PERSON_CONTACT_DOCUMENT_FAILURE:
    case types.DELETE_PERSON_CONTACT_DOCUMENT_FAILURE:
    case types.HIDE_OR_UNHIDE_PERSON_CONTACT_DOCUMENT_FAILURE:
    case types.FILE_UPLOAD_S3_FAILURE:
    case types.FILE_DOWNLOAD_S3_FAILURE:
      return Object.assign({}, state, {
        personContactDocumentLoading: {
          ...state.personContactDocumentLoading,
          [payload.documentId]: false,
        },
        personContactDocumentError: {
          ...state.personContactDocumentError,
          [payload.documentId]: payload.message,
        },
      });

    // list tags
    case types.LIST_PERSON_CONTACT_DOCUMENT_TAGS_REQUEST:
      return Object.assign({}, state, {
        tagsListLoading: true,
      });
    case types.LIST_PERSON_CONTACT_DOCUMENT_TAGS_SUCCESS:
      return Object.assign({}, state, {
        tagsListLoading: false,
        tags: payload.data,
      });
    case types.LIST_PERSON_CONTACT_DOCUMENT_TAGS_FAILURE:
      return Object.assign({}, state, {
        tagsListLoading: false,
      });

    // add tag
    case types.ADD_TAG_TO_PERSON_CONTACT_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        personContactDocumentLoading: {
          ...state.personContactDocumentLoading,
          [payload.documentId]: false,
        },
        personContactDocuments: {
          ...state.personContactDocuments,
          [payload.documentId]: assign(
            state.personContactDocuments[payload.documentId],
            {
              tags: uniqBy(
                [
                  ...(property(
                    `personContactDocuments.${payload.documentId}.tags`
                  )(state) || []),
                  { name: payload.tag },
                ],
                t => t.name
              ),
            }
          ),
        },
      });

    // remove tag
    case types.REMOVE_TAG_FROM_PERSON_CONTACT_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        personContactDocumentLoading: {
          ...state.personContactDocumentLoading,
          [payload.documentId]: false,
        },
        personContactDocuments: {
          ...state.personContactDocuments,
          [payload.documentId]: assign(
            state.personContactDocuments[payload.documentId],
            {
              tags: (
                property(`personContactDocuments.${payload.documentId}.tags`)(
                  state
                ) || []
              ).filter(tag => tag.name !== payload.tag),
            }
          ),
        },
      });

    case types.CLEAR: {
      return assign(state, initialState);
    }

    default:
      return state;
  }
};
