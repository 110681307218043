/**
 * @format
 */

import request from '../../../helpers/request';
import isEmptyString from '../../../helpers/string/isEmptyString';
import { enqueueNotification } from '../../app';
import {
  ADD_CHART_TO_PIPELINE_DASHBOARD_FAILURE,
  ADD_CHART_TO_PIPELINE_DASHBOARD_REQUEST,
  ADD_CHART_TO_PIPELINE_DASHBOARD_SUCCESS,
} from '../actions';

export default (dashboardId, form) => {
  return async dispatch => {
    if (isEmptyString(dashboardId)) {
      throw new Error('Missing dashboard id');
    }

    dispatch({
      type: ADD_CHART_TO_PIPELINE_DASHBOARD_REQUEST,
    });

    let data;
    try {
      const response = await request.post(
        `/api/pipeline/dealflowbi/v1/dashboard/${dashboardId}/charts`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message = 'Something went wrong while adding chart to dashboard';
      dispatch({
        type: ADD_CHART_TO_PIPELINE_DASHBOARD_FAILURE,
        payload: message,
      });
      dispatch(enqueueNotification(message));
      throw err;
    }

    if (data.success) {
      dispatch({
        type: ADD_CHART_TO_PIPELINE_DASHBOARD_SUCCESS,
        payload: {
          dashboardId,
          data: data.data,
        },
      });

      return data.data;
    } else {
      dispatch({
        type: ADD_CHART_TO_PIPELINE_DASHBOARD_FAILURE,
        payload: {
          dashboardId,
          message: data.message,
        },
      });

      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
