/**
 * @format
 */
export const DealRowHeightOptions = {
  short: {
    key: 'short',
    name: 'Short',
    value: 45,
    isSet: true,
  },
  medium: {
    key: 'medium',
    name: 'Medium',
    value: 80,
    isSet: false,
  },
  tall: {
    key: 'tall',
    name: 'Tall',
    value: 120,
    isSet: false,
  },
  extraTall: {
    key: 'extraTall',
    name: 'Extra Tall',
    value: 160,
    isSet: false,
  },
};

export const RowHeights = {
  SHORT: 'short',
  MEDIUM: 'medium',
  TALL: 'tall',
  EXTRATALL: 'extraTall',
};

export const RowHeightsValues = {
  short: 45,
  medium: 80,
  tall: 120,
  extraTall: 160,
};

export const RowHeightsLinesToBeShown = {
  short: 1,
  medium: 3,
  tall: 6,
  extraTall: 8,
};
