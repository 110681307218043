/**
 * Converts a given founder to external referrer
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_REQUEST,
  CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_SUCCESS,
  CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_FAILURE } from '../actions';

export default (dealId, founderUserId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_REQUEST,
        payload: { dealId }
      });
      request.patch(`/api/v2/deals/${dealId}/convert-founder-to-external-referrer`, {
        founder_user_id: founderUserId
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_SUCCESS,
            payload: { dealId }
          });
          resolve(data);
        } else {
          dispatch({
            type: CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_FAILURE,
            payload: {
              dealId,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while converting founder to external referrer, please try again.';
        dispatch({
          type: CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_FAILURE,
          payload: {
            dealId,
            message,
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
