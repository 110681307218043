/**
 * Clears deal id list - list of deals currently being shown in deals page
 *
 * @format
 */
import { CLEAR_DEAL_ID_LIST } from '../actions';

export default () => {
  return dispatch => {
    dispatch({
      type: CLEAR_DEAL_ID_LIST,
    });
  };
};
