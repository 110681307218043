/**
 * @format
 */
import * as types from './actions';
import isEmptyString from '../../helpers/string/isEmptyString';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const loadEmailTemplates = (options = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_EMAIL_TEMPLATE_LIST_REQUEST,
      });
      request
        .get(
          `/api/v5/email-templates`,
          {
            includeInactive: options.includeInactive,
            ignoreCache: options.ignoreCache,
          },
          dispatch
        )
        .then(({ data }) => {
          if (data && data.success) {
            dispatch({
              type: types.LOAD_EMAIL_TEMPLATE_LIST_SUCCESS,
              payload: {
                data: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.LOAD_EMAIL_TEMPLATE_LIST_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          let message;
          message = `Something went wrong while loading email templates. Please try again`;
          dispatch({
            type: types.LOAD_EMAIL_TEMPLATE_LIST_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

export const addEmailTemplate = form => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.ADD_EMAIL_TEMPLATE_REQUEST,
        payload: form,
      });
      request
        .post(`/api/v5/email-templates`, form, dispatch)
        .then(({ data }) => {
          if (data && data.success) {
            dispatch({
              type: types.ADD_EMAIL_TEMPLATE_SUCCESS,
              payload: {
                data: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.ADD_EMAIL_TEMPLATE_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message = `Something went wrong while adding this email template. Please try again`;
          dispatch({
            type: types.ADD_EMAIL_TEMPLATE_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

export const updateEmailTemplate = (template_id, position_id, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (isEmptyString(template_id) || isNaN(position_id) || position_id < 0) {
        // nothing to update
        dispatch(enqueueNotification(`Nothing to update`));
        return reject(new Error(`Nothing to update`));
      }
      const propertiesToUpdate = [];
      if (!isEmptyString(form.title)) {
        propertiesToUpdate.push('title');
      }
      if (!isEmptyString(form.body_html)) {
        propertiesToUpdate.push('body_html');
      }
      if (!isEmptyString(form.body_plain)) {
        propertiesToUpdate.push('body_plain');
      }
      if (!isNaN(form.position_id) && form.position_id > -1) {
        propertiesToUpdate.push('position_id');
      }
      if (propertiesToUpdate.length === 0) {
        // nothing to update
        dispatch(enqueueNotification(`Nothing to update`));
        return reject(new Error(`Nothing to update`));
      }
      dispatch({
        type: types.UPDATE_EMAIL_TEMPLATE_REQUEST,
        payload: {
          position_id,
          propertiesToUpdate,
        },
      });
      request
        .put(`/api/v5/email-templates/${template_id}`, form, dispatch)
        .then(({ data }) => {
          if (data && data.success) {
            dispatch({
              type: types.UPDATE_EMAIL_TEMPLATE_SUCCESS,
              payload: {
                position_id,
                propertiesToUpdate,
                data: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_EMAIL_TEMPLATE_FAILURE,
              payload: {
                position_id,
                propertiesToUpdate,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message = `Something went wrong while updating this email template. Please try again`;
          dispatch({
            type: types.UPDATE_EMAIL_TEMPLATE_FAILURE,
            payload: {
              position_id,
              propertiesToUpdate,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

export const softDeleteEmailTemplate = (template_id, position_id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (isEmptyString(template_id) || isNaN(position_id) || position_id < 0) {
        // nothing to update
        dispatch(enqueueNotification(`Nothing to delete`));
        return reject(new Error(`Nothing to delete`));
      }
      dispatch({
        type: types.SOFT_DELETE_EMAIL_TEMPLATE_REQUEST,
        payload: {
          position_id,
        },
      });
      request
        .del(`/api/v5/email-templates/${template_id}`, {}, dispatch)
        .then(({ data }) => {
          if (data && data.success) {
            dispatch({
              type: types.SOFT_DELETE_EMAIL_TEMPLATE_SUCCESS,
              payload: {
                position_id,
                data: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.SOFT_DELETE_EMAIL_TEMPLATE_FAILURE,
              payload: {
                position_id,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message = `Something went wrong while deleting this email template. Please try again`;
          dispatch({
            type: types.SOFT_DELETE_EMAIL_TEMPLATE_FAILURE,
            payload: {
              position_id,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};
