/** @format */

import * as types from './actions';
import reducers from './reducers';
import request, { wrap, api, ApplicationError } from '../../helpers/request';
import { enqueueNotification } from '../app';

// mark reducers as the default export
export default reducers;

// LoadAllFunds loads all the fund in the currenct organization
export function loadAllFunds(queryParams = { limit: 100 }, options = {}) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.get(`/api/funds/v1/funds`, { params: queryParams, ...options })
    );
    if (!data.success) {
      throw new Error(data.message);
    }
    dispatch({ type: types.LOAD_ALL_FUNDS, payload: data.data });
    return data;
  };
}

/**
 * Loads the fund's details
 * @param {String} fundId Id of fund to load
 * @returns Promise that resolves fund's basic data
 */
export function loadFund(fundId, options = {}) {
  return async function(dispatch) {
    dispatch({ type: types.LOAD_FUND_REQUEST, payload: { fundId } });

    let data;
    if (!fundId) {
      throw new Error(`Fund id is noi passed`);
    }
    try {
      const response = await request.get(
        `/api/funds/v1/funds/${fundId}`,
        options,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading this fund. Please try again.';
      dispatch({
        type: types.LOAD_FUND_FAILURE,
        payload: {
          fundId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.LOAD_FUND_SUCCESS,
        payload: {
          fundId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.LOAD_FUND_FAILURE,
        payload: {
          fundId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
}

/**
 * Updates the fund's details
 * @param {Object} form Attributes to update in the fund
 * @param {String} fundId Id of fund to update
 * @returns Promise that resolves fund's basic data
 */
export function updateFund(form, fundId) {
  return async function(dispatch) {
    dispatch({ type: types.UPDATE_FUND_REQUEST, payload: { fundId } });

    let data;
    try {
      const response = await request.put(
        `/api/funds/v1/funds/${fundId}`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while updating this fund. Please try again.';
      dispatch({
        type: types.UPDATE_FUND_FAILURE,
        payload: {
          fundId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.UPDATE_FUND_SUCCESS,
        payload: {
          fundId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.UPDATE_FUND_FAILURE,
        payload: {
          fundId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
}

// AddFund adds a new fund to the organisation
export function addFund(fund) {
  return async function(dispatch) {
    const { data } = await wrap(api.post(`/api/funds/v1/funds`, fund));
    if (!data.success) {
      throw new Error(data.message);
    }
    dispatch({ type: types.ADD_FUND, payload: data.data });
    return data;
  };
}

// DeleteFund deletes the given fund
export function deleteFund(id) {
  return async function(dispatch) {
    const { data } = await wrap(api.delete(`/api/funds/v1/funds/${id}`));
    if (!data.success) {
      throw new Error(data.message);
    }
    dispatch({ type: types.DELETE_FUND, payload: { id } });
  };
}

export function loadExchangeRates(filters, page) {
  return async function(dispatch) {
    const params = { ...filters, ...page };
    const { data } = await wrap(api.get(`/api/funds/v1/rates`, { params }));
    if (!data.success) {
      throw new Error(data.message);
    }
    dispatch({
      type: types.LOAD_EXCHANGE_RATE,
      payload: { data: data.data, filters, page },
    });
    return data;
  };
}

export function saveExchangeRate(rate) {
  return async function(dispatch) {
    const { data } = await wrap(api.post(`/api/funds/v1/rates`, rate));
    if (!data.success) {
      throw new Error(data.message);
    }
    dispatch({ type: types.SAVE_EXCHANGE_RATE, payload: data.data });
    return data;
  };
}

export function deleteExchangeRate({ from, to, on }) {
  return async function(dispatch) {
    dispatch({ type: types.DELETE_EXCHANGE_RATE_REQUEST });
    const { data } = await wrap(
      api.delete(`/api/funds/v1/rates`, { params: { from, to, on } })
    );
    if (!data.success) {
      dispatch({ type: types.DELETE_EXCHANGE_RATE_FAILURE });
      throw new Error(data.message);
    }
    dispatch({
      type: types.DELETE_EXCHANGE_RATE_SUCCESS,
      payload: { from, to, on },
    });
    return data;
  };
}

export function loadTransactionReport(filters, page) {
  return async function(dispatch) {
    dispatch({ type: types.LOAD_TRANSACTION_REPORT_REQUEST });
    const { data } = await wrap(
      api.get(`/api/fund-transaction-reports/v1/transaction-ledger`, {
        params: { ...filters, ...page },
      })
    );
    if (!data.success) {
      dispatch({ type: types.LOAD_TRANSACTION_REPORT_FAILURE });
      throw new Error(data.message);
    }
    dispatch({
      type: types.LOAD_TRANSACTION_REPORT_SUCCESS,
      payload: { data: data.data },
    });
    return data;
  };
}

/**
 * Loads the fund's access control map (which tells us which user has which roles)
 * @param {String} fundId Id of fund to update
 * @returns Promise that resolves fund's access map
 */
export function loadFundAccessMap(fundId) {
  return async function(dispatch) {
    dispatch({ type: types.LOAD_FUND_ACCESS_MAP_REQUEST, payload: { fundId } });

    let data;
    try {
      const response = await request.get(
        `/api/funds/v1/funds/${fundId}/access`,
        {},
        dispatch
      );
      data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.LOAD_FUND_ACCESS_MAP_SUCCESS,
        payload: {
          fundId,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while loading access map for this fund. Please try again.';
      dispatch({
        type: types.LOAD_FUND_ACCESS_MAP_FAILURE,
        payload: {
          fundId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
}

/**
 * Update a member's access in the fund's
 * @param {String} fundId Id of fund to update
 * @param {String} userId Id of user whose roles are to be updated
 * @param {Object} accessMap Access map
 */
export function updateFundAccess(fundId, userId, accessMap) {
  return async function(dispatch) {
    dispatch({
      type: types.UPDATE_FUND_ACCESS_REQUEST,
      payload: { fundId, userId },
    });

    let data;
    try {
      const response = await request.put(
        `/api/funds/v1/funds/${fundId}/access`,
        { user_id: userId, role: accessMap },
        dispatch
      );
      data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.UPDATE_FUND_ACCESS_SUCCESS,
        payload: {
          fundId,
          userId,
          accessMap,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while updating access control for this fund. Please try again.';
      dispatch({
        type: types.UPDATE_FUND_ACCESS_FAILURE,
        payload: {
          fundId,
          userId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
}

export function clearTransactionReport() {
  return function(dispatch) {
    return dispatch({ type: types.CLEAR_TRANSACTION_REPORT });
  };
}

// Clears redux state
export const clearFundState = () => dispatch => dispatch({ type: types.CLEAR });

// Load custom fields for org
export const loadCustomFields = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.LOAD_CUSTOM_FIELDS_REQUEST,
    });
    request
      .get(`/api/funds/v1/funds/custom-fields/fields`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_CUSTOM_FIELDS_SUCCESS,
            payload: data.data,
          });
          resolve(data);
        } else {
          dispatch({
            type: types.LOAD_CUSTOM_FIELDS_FAILURE,
            payload: {
              message: data.message,
            },
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while loading custom fields, please try again.';
        dispatch({
          type: types.LOAD_CUSTOM_FIELDS_FAILURE,
          payload: { message },
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
  });
};

// Load Custom field values
export const loadCustomFieldValues = fundId => async dispatch => {
  let loadingMap = {};
  loadingMap[fundId] = true;
  dispatch({
    type: types.LOAD_CUSTOM_FIELD_VALUES_REQUEST,
    payload: loadingMap,
  });
  let data;
  try {
    const response = await request.get(
      `/api/funds/v1/funds/custom-fields/values?id=${fundId}`
    );
    data = response.data;
    if (data.success) {
      let dataMap = {};
      dataMap[fundId] = data.data[0];
      loadingMap[fundId] = false;
      dispatch({
        type: types.LOAD_CUSTOM_FIELD_VALUES_SUCCESS,
        payload: { dataMap: dataMap, loadingMap: loadingMap },
      });
      return data;
    } else {
      loadingMap[fundId] = false;
      dispatch({
        type: types.LOAD_CUSTOM_FIELD_VALUES_FAILURE,
        payload: { message: data.message, loadingMap: loadingMap },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  } catch (err) {
    const message =
      'Something went wrong while loading custom field values, please try again.';
    loadingMap[fundId] = false;
    dispatch({
      type: types.LOAD_CUSTOM_FIELD_VALUES_FAILURE,
      payload: { message: data.message, loadingMap: loadingMap },
    });
    dispatch(enqueueNotification(message));
    throw err;
  }
};

// Update Custom Field Values
export const updateCustomFieldValues = (form, fundId) => {
  let loadingMap = {};
  loadingMap[fundId] = true;
  return async dispatch => {
    dispatch({
      type: types.UPDATE_CUSTOM_FIELD_VALUE_REQUEST,
      payload: loadingMap,
    });
    let data;
    try {
      const response = await request.put(
        `/api/funds/v1/funds/${fundId}/custom-fields/values`,
        form
      );
      data = response.data;
      if (data.success) {
        let dataMap = {};
        dataMap[fundId] = data.data[0];
        loadingMap[fundId] = false;
        dispatch({
          type: types.UPDATE_CUSTOM_FIELD_VALUE_SUCCESS,
          payload: { loadingMap: loadingMap, dataMap: dataMap },
        });
        dispatch(
          enqueueNotification('custom fields value updated successfully!')
        );
        return data;
      } else {
        loadingMap[fundId] = false;
        dispatch({
          type: types.UPDATE_CUSTOM_FIELD_VALUE_FAILURE,
          payload: { message: data.message, loadingMap: loadingMap },
        });
        dispatch(enqueueNotification(data.message));
        throw new Error(data.message);
      }
    } catch (err) {
      loadingMap[fundId] = false;
      const message =
        'Something went wrong while updating custom field values, please try again.';
      dispatch({
        type: types.UPDATE_CUSTOM_FIELD_VALUE_FAILURE,
        payload: { message: message, loadingMap: loadingMap },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

// Update Custom fields
export const updateCustomFields = form => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_CUSTOM_FIELDS_REQUEST,
        paylod: form,
      });
      request
        .put(`/api/funds/v1/funds/custom-fields/fields`, form, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_CUSTOM_FIELDS_SUCCESS,
              payload: data.data,
            });
            dispatch(
              enqueueNotification(
                'Custom fields updated for the organization successfully!'
              )
            );
            resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_CUSTOM_FIELDS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating custom fields, please try again.';
          dispatch({
            type: types.UPDATE_CUSTOM_FIELDS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

// Delete custom fields
export const removeCustomFields = customFieldNames => {
  return async dispatch => {
    dispatch({
      type: types.REMOVE_CUSTOM_FIELDS_REQUEST,
    });
    let data;
    try {
      let deletedNames = customFieldNames
        .map(cfName => `name=${encodeURIComponent(cfName)}`)
        .join('&');

      let path = `/api/funds/v1/funds/custom-fields/fields?${deletedNames}`;

      const response = await request.del(path);
      data = response.data;
      if (data.success) {
        dispatch({ type: types.REMOVE_CUSTOM_FIELDS_SUCCESS });
        dispatch(enqueueNotification('removed custom field successfully!'));
        return data;
      } else {
        dispatch({
          type: types.REMOVE_CUSTOM_FIELDS_FAILURE,
          payload: { message: data.message },
        });
        dispatch(enqueueNotification(data.message));
        throw new Error(data.message);
      }
    } catch (err) {
      const message =
        'Something went wrong while deleting custom fields, please try again.';
      dispatch({
        type: types.REMOVE_CUSTOM_FIELDS_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

// Add custom fields
export const addCustomField = form => {
  return async dispatch => {
    dispatch({
      type: types.ADD_CUSTOM_FIELD_REQUEST,
    });
    let data;
    try {
      const response = await request.post(
        `/api/funds/v1/funds/custom-fields/fields`,
        form
      );
      data = response.data;
      if (data.success) {
        dispatch({
          type: types.ADD_CUSTOM_FIELD_SUCCESS,
          payload: data.data,
        });
        return data;
      } else {
        dispatch({
          type: types.ADD_CUSTOM_FIELD_FAILURE,
          payload: data.message,
        });
        dispatch(enqueueNotification(data.message));
        throw new Error(data.message);
      }
    } catch (err) {
      const message =
        'Something went wrong while adding custom fields, please try again.';
      dispatch({
        type: types.ADD_CUSTOM_FIELD_FAILURE,
        payload: message,
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};
