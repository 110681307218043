/** Actions supported on PersonContact module */
export const GET_PERSON_REQUEST = 'personContact/GET_PERSON_REQUEST';
export const GET_PERSON_SUCCESS = 'personContact/GET_PERSON_SUCCESS';
export const GET_PERSON_FAILURE = 'personContact/GET_PERSON_FAILURE';

export const UPDATE_PERSON_REQUEST = 'personContact/UPDATE_PERSON_REQUEST';
export const UPDATE_PERSON_SUCCESS = 'personContact/UPDATE_PERSON_SUCCESS';
export const UPDATE_PERSON_FAILURE = 'personContact/UPDATE_PERSON_FAILURE';

export const GET_RECENT_INTERACTIONS_REQUEST =
  'personContact/GET_RECENT_INTERACTIONS_REQUEST';
export const GET_RECENT_INTERACTIONS_SUCCESS =
  'personContact/GET_RECENT_INTERACTIONS_SUCCESS';
export const GET_RECENT_INTERACTIONS_FAILURE =
  'personContact/GET_RECENT_INTERACTIONS_FAILURE';
