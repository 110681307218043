import {
  LOAD_DEAL_LIST_REQUEST,
  LOAD_DEAL_LIST_SUCCESS,
  LOAD_DEAL_LIST_FAILURE,
} from '../actions';
import isUndefined from 'lodash/isUndefined'

export default (state, type, payload) => {
  switch(type) {
    case LOAD_DEAL_LIST_REQUEST:
      return Object.assign({}, state, {
        dealListLoadingState: true,
        dealCountWithWorkspaceId: payload.options.cursor==='null'?{
          ...state.dealCountWithWorkspaceId,
          [payload.workspace_id]: '-'}: state.dealCountWithWorkspaceId,
        dealListLoadingError: null,
        dealsOptions: {
          ...state.dealsOptions,
          ...((!isUndefined(payload.extra) && payload.extra.disableUpdateDealsOptions) ? {} : payload.options)
        }
      });
    case LOAD_DEAL_LIST_SUCCESS:
      return Object.assign({}, state, {
        dealsCount: (!isUndefined(payload.metadata) && payload.metadata.count )|| '-',
        dealListLoadingState: false,
        dealListLoadingError: null,
        dealsCountSource: payload.dealsCountSource,
        dealCountWithWorkspaceId: {
          ...state.dealCountWithWorkspaceId,
          [payload.workspace_id]: !isUndefined(payload.metadata) ? payload.metadata.count : '-'},
        deals: {
          ...state.deals,
          ...payload.data?.results.reduce((result, item) => {
              result[item.id] = state.deals[item.id] ? {
                ...state.deals[item.id],
                ...item
              } : item;
              return result;
            }, {})
        },
        dealsOptions: {
          ...state.dealsOptions,
          ...((!isUndefined(payload.extra) && payload.extra.disableUpdateDealsOptions) ? {} : payload.options),
          cursor: payload.data.next
        }
      });
    
    case LOAD_DEAL_LIST_FAILURE:
      return Object.assign({}, state, {
        dealListLoadingState: false,
        dealListLoadingError: payload,
      });
    default:
      return state;
  }
};
