/**
 * @format
 */

import reduce from 'lodash.reduce';
import * as types from '../actions';

import { assign } from '../../../helpers/object';

export default (state, type, payload) => {
  switch (type) {
    /** LOAD_DRAWDOWN_LIST */
    case types.LOAD_DRAWDOWN_LIST_REQUEST: {
      return assign(state, {
        drawdownListLoading: true,
        drawdownListError: null,
      });
    }
    case types.LOAD_DRAWDOWN_LIST_FAILURE: {
      return assign(state, {
        drawdownListLoading: false,
        drawdownListError: payload.message,
      });
    }
    case types.LOAD_DRAWDOWN_LIST_SUCCESS: {
      const drawdowns = reduce(
        payload.data,
        function(acc, drawdown) {
          acc[drawdown.id] = drawdown;
          return acc;
        },
        {}
      );
      return assign(state, {
        drawdownListLoading: false,
        drawdownListError: null,
        drawdown: assign(state.drawdown, drawdowns),
      });
    }

    default:
      return state;
  }
};
