/** @format */

import * as types from '../actions';
import request, { wrap } from '../../../helpers/request';
import isEmptyString from '../../../helpers/string/isEmptyString';
import { enqueueNotification } from '../../app';

/**
 * Deletes a drawdown notice
 * @param {UUID} drawdownId Id of drawdown notice to load
 * @param {UUID} fundId Fund's id
 */
export default function deleteDrawdown(drawdownId, fundId, limited_partner_id) {
  if (isEmptyString(fundId)) {
    throw new Error('Fund id missing');
  }
  if (isEmptyString(drawdownId)) {
    throw new Error('Drawdown id missing');
  }
  return async dispatch => {
    dispatch({
      type: types.DELETE_DRAWDOWN_REQUEST,
      payload: {
        fundId,
        drawdownId,
      },
    });

    try {
      const { data } = await wrap(
        request.del(
          `/api/fund-transactions/v1/fund/${fundId}/drawdowns/${drawdownId}`,
          { limited_partner_id },
          dispatch
        )
      );

      dispatch({
        type: types.DELETE_DRAWDOWN_SUCCESS,
        payload: { fundId, drawdownId, data: data.data },
      });

      return data.data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong when deleting the drawdown notice. Please try again.';
      dispatch({
        type: types.DELETE_DRAWDOWN_FAILURE,
        payload: { fundId, drawdownId, message: message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
  };
}
