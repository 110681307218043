/**
 * Firebase-specific methods
 */

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from 'firebase/app';
import "firebase/messaging";

import { updateFCMRegistrationTokens } from '../setting';
import { markNotificationAsRead, setNewIncomingNotification } from '../notification';

/**
 * Initialize firebase so that service worker can be registered and handlers can
 * be invoked. `user` attribute is required because we want to show notifications
 * only to our logged-in users
 *
 * @todo Experiment what happens when, from the same browser, we are logging in
 * to two different accounts on Taghash
 *
 * @param  {Object} user User object
 * @return {Promise}     Promise that resolves on success
 */
export const initializeFirebaseMessaging = async (dispatch) => {
  const isGranted = await requestPermission();
  // Conditions in which to return without further action
  // 1. If user has not granted permission
  // 2. If in parallel, we have already started initializing fcm
  // 3. If envs required to initialize firebase are not set
  if (!isGranted ||
    window.fcmInitializationStarted ||
    (!window.env || !window.env.FIREBASE_PROJECT_ID ||
      !window.env.FIREBASE_MESSAGING_SENDER_ID ||
      !window.env.FIREBASE_PUBLIC_VAPID_KEY)) {
    return;
  };
  // making this true will ensure no other concurrent call to initializeFirebaseMessaging
  // passes beyond this point, since this firebase has to be initialized exactly once
  window.fcmInitializationStarted = true;
  try {
    firebase.initializeApp({
      apiKey: window.env.FIREBASE_API_KEY,
      authDomain: window.env.FIREBASE_AUTH_DOMAIN,
      databaseURL: window.env.FIREBASE_DATABASE_URL,
      projectId: window.env.FIREBASE_PROJECT_ID,
      storageBucket: window.env.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: window.env.FIREBASE_MESSAGING_SENDER_ID,
      appId: window.env.FIREBASE_APP_ID,
      measurementId: window.env.FIREBASE_MEASUREMENT_ID,
    });
  } catch(err) {
    console.log('An error occurred while initializing firebase. ', err);
  }
  // Retrieve Firebase Messaging object.
  // @see https://firebase.google.com/docs/cloud-messaging/js/client#retrieve_a_messaging_object
  const messaging = firebase.messaging();
  // required initialization step
  // @see https://firebase.google.com/docs/cloud-messaging/js/client#configure_web_credentials_in_your_app
  messaging.usePublicVapidKey(window.env.FIREBASE_PUBLIC_VAPID_KEY);
  _registerFCMHandlers(dispatch);
  try {
    const newToken = await messaging.getToken();
    // Send Instance ID token to app server.
    updateFCMRegistrationTokens(newToken)(dispatch).catch(() => {});
    // @see https://github.com/invertase/react-native-firebase/issues/1374#issuecomment-416889605
    // firebase.notifications().onNotification((notification) => {
    //   console.log(`notification`, notification);
    //   firebase.notifications().displayNotification(notification);
    // });
  } catch(err) {
    console.error('An error occurred while retrieving token. ', err);
  }
};

/**
 * Request permission from user to show browser notifications
 * @see https://firebase.google.com/docs/cloud-messaging/js/client#request_permission_to_receive_notifications
 * @return {Boolean} Do we have user's permission to show notifications?
 */
export const requestPermission = async () => {
  if (!Notification) { return false }
  if (isNotificationPermissionGranted()) { return true; }
  const permission = await Notification.requestPermission();
  return permission === 'granted';
};

export const isNotificationPermissionGranted = () => (
  Notification && Notification.permission === 'granted'
);

/**
 * Registers event handlers, particularly what to do when a notification comes in
 */
const _registerFCMHandlers = (dispatch) => {
  const messaging = firebase.messaging();
  // Callback fired if Instance ID token is updated.
  // @see https://firebase.google.com/docs/cloud-messaging/js/client#monitor-token-refresh
  messaging.onTokenRefresh(async () => {
    try {
      // @see https://firebase.google.com/docs/cloud-messaging/js/client#retrieve-the-current-registration-token
      const newToken = await messaging.getToken();
      // Send Instance ID token to app server.
      updateFCMRegistrationTokens(newToken)(dispatch).catch(() => {});
    } catch (err) {
      console.error('Unable to retrieve refreshed token ', err);
    }
  });

  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.setBackgroundMessageHandler` handler.
  // This section is commented out since it's not working
  // messaging.onMessage((payload) => {
  //   console.log('Message received. ', payload);
  //   if (typeof payload === 'string') {
  //     enqueueNotification(payload);
  //   } else {
  //     enqueueNotification(JSON.stringify(payload));
  //   }
  //   // Update the UI to include the received message.
  //   // appendMessage(payload);
  // });

  // @see https://codeburst.io/how-to-add-push-notifications-on-firebase-cloud-messaging-to-react-web-app-de7c6f04c920
  navigator.serviceWorker.addEventListener("message", (message) => {
    if (message && message.data && message.data['firebase-messaging-msg-data'] &&
      message.data['firebase-messaging-msg-data'].notification) {
      // update flag that there's a new incoming notification so that dashboard's
      // notification panel can be updated
      setNewIncomingNotification()(dispatch);
      const messageData = message.data['firebase-messaging-msg-data'];
      const notificationPayload = messageData.notification;
      const notification = new Notification(notificationPayload.title,
        {
          ...notificationPayload,
          icon: '/logo-1.png',
          tag: messageData.collapse_key,
          // renotify: true, // if true tag shouldn't be empty
          renotify: true,
          // when requireInteraction is true, the notification stays until user
          // has interacted. But implementation is currently buggy, where website
          // will open (to homepage) even if the user is manually dismissing the
          // notification
          requireInteraction: false,
        });
      notification.onclick = function(e) {
        if (messageData && messageData.data && messageData.data.notificationId) {
          // mark notification as read since the user has clicked on the notification
          // This is foreground notification handling, ie, when the incoming
          // notification comes in when the dashboard is already open
          markNotificationAsRead(messageData.data.notificationId)(dispatch).catch(() => {});
        }
        const link = messageData.fcmOptions.link;
        if (link) {
          try {
            const linkOb = new URL(link);
            window.location.href = linkOb.toString();
          } catch(err) {
            console.error(`Malformed url in notification payload`, err);
          }
        }
      }
    }
  });
};

/**
 * Delete token. Likely not of use. Here for reference
 */
export const deleteFCMToken = async () => {
  try {
    const messaging = firebase.messaging();
    const currentToken = await messaging.getToken();
    await messaging.deleteToken(currentToken);
  } catch (err) {
    console.error('Unable to delete token. ', err);
  }
};


