/**
 * @format
 */

import fundCompaniesTableColumn from '../../constants/fundCompaniesTableColumn';

export default [
  {
    field: fundCompaniesTableColumn.COMPANY.name,
    id: fundCompaniesTableColumn.COMPANY.key,
    children: null,
    active: true,
  },
  {
    field: fundCompaniesTableColumn.FUNDS.name,
    id: fundCompaniesTableColumn.FUNDS.key,
    children: null,
    active: true,
  },
  {
    field: fundCompaniesTableColumn.FMV_SHARE.name,
    id: fundCompaniesTableColumn.FMV_SHARE.key,
    children: null,
    active: true,
  },
  {
    field: fundCompaniesTableColumn.AS_ON.name,
    id: fundCompaniesTableColumn.AS_ON.key,
    children: null,
    active: true,
  },
  {
    field: fundCompaniesTableColumn.FOREX_RATE.name,
    id: fundCompaniesTableColumn.FOREX_RATE.key,
    children: null,
    active: true,
  },
  {
    field: fundCompaniesTableColumn.FIRST_INVESTMENT_DATE.name,
    id: fundCompaniesTableColumn.FIRST_INVESTMENT_DATE.key,
    children: null,
    active: true,
  },
  {
    field: fundCompaniesTableColumn.MANAGERS.name,
    id: fundCompaniesTableColumn.MANAGERS.key,
    children: null,
    active: true,
  },
  {
    field: fundCompaniesTableColumn.FOUNDER_EMAIL.name,
    id: fundCompaniesTableColumn.FOUNDER_EMAIL.key,
    children: null,
    active: true,
  },
  {
    field: fundCompaniesTableColumn.HOLDINGS.name,
    id: fundCompaniesTableColumn.HOLDINGS.key,
    active: true,
    children: [
      {
        field: fundCompaniesTableColumn.HOLDINGS.EQUITY_SHARE.name,
        id: fundCompaniesTableColumn.HOLDINGS.EQUITY_SHARE.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.HOLDINGS.CCPS_DILUTED.name,
        id: fundCompaniesTableColumn.HOLDINGS.CCPS_DILUTED.key,
        children: null,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.HOLDINGS.CCD_DILUTED.name,
        id: fundCompaniesTableColumn.HOLDINGS.CCD_DILUTED.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.HOLDINGS.OTHERS_DILUTED.name,
        id: fundCompaniesTableColumn.HOLDINGS.OTHERS_DILUTED.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.HOLDINGS.SHARES_HELD.name,
        id: fundCompaniesTableColumn.HOLDINGS.SHARES_HELD.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.HOLDINGS.TOTAL_OUTSTANDING.name,
        id: fundCompaniesTableColumn.HOLDINGS.TOTAL_OUTSTANDING.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.HOLDINGS.PERCENTAGE_HOLDING.name,
        id: fundCompaniesTableColumn.HOLDINGS.PERCENTAGE_HOLDING.key,
        active: true,
      },
    ],
  },
  {
    field: fundCompaniesTableColumn.LATEST_FUNDING_ROUND.name,
    id: fundCompaniesTableColumn.LATEST_FUNDING_ROUND.key,
    active: true,
    children: [
      {
        field: fundCompaniesTableColumn.LATEST_FUNDING_ROUND.DATE.name,
        id: fundCompaniesTableColumn.LATEST_FUNDING_ROUND.DATE.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.LATEST_FUNDING_ROUND.NAME.name,
        id: fundCompaniesTableColumn.LATEST_FUNDING_ROUND.NAME.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.LATEST_FUNDING_ROUND.PRE_MONEY.name,
        id: fundCompaniesTableColumn.LATEST_FUNDING_ROUND.PRE_MONEY.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.LATEST_FUNDING_ROUND.POST_MONEY.name,
        id: fundCompaniesTableColumn.LATEST_FUNDING_ROUND.POST_MONEY.key,
        active: true,
      },
    ],
  },
  {
    field: fundCompaniesTableColumn.TOTAL_COMMITTED.name,
    id: fundCompaniesTableColumn.TOTAL_COMMITTED.key,
    active: true,
    children: [
      {
        field: fundCompaniesTableColumn.TOTAL_COMMITTED.LOCAL.name,
        id: fundCompaniesTableColumn.TOTAL_COMMITTED.LOCAL.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.TOTAL_COMMITTED.FUND.name,
        id: fundCompaniesTableColumn.TOTAL_COMMITTED.FUND.key,
        active: true,
      },
    ],
  },
  {
    field: fundCompaniesTableColumn.TOTAL_INVESTED.name,
    id: fundCompaniesTableColumn.TOTAL_INVESTED.key,
    active: true,
    children: [
      {
        field: fundCompaniesTableColumn.TOTAL_INVESTED.LOCAL.name,
        id: fundCompaniesTableColumn.TOTAL_INVESTED.LOCAL.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.TOTAL_INVESTED.FUND.name,
        id: fundCompaniesTableColumn.TOTAL_INVESTED.FUND.key,
        active: true,
      },
    ],
  },
  {
    field: fundCompaniesTableColumn.NET_COST.name,
    id: fundCompaniesTableColumn.NET_COST.key,
    active: true,
    children: [
      {
        field: fundCompaniesTableColumn.NET_COST.LOCAL.name,
        id: fundCompaniesTableColumn.NET_COST.LOCAL.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.NET_COST.FUND.name,
        id: fundCompaniesTableColumn.NET_COST.FUND.key,
        active: true,
      },
    ],
  },
  {
    field: fundCompaniesTableColumn.NET_REALISED_GAIN.name,
    id: fundCompaniesTableColumn.NET_REALISED_GAIN.key,
    active: true,
    children: [
      {
        field: fundCompaniesTableColumn.NET_REALISED_GAIN.LOCAL.name,
        id: fundCompaniesTableColumn.NET_REALISED_GAIN.LOCAL.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.NET_REALISED_GAIN.FUND.name,
        id: fundCompaniesTableColumn.NET_REALISED_GAIN.FUND.key,
        active: true,
      },
    ],
  },
  {
    field: fundCompaniesTableColumn.FMV_UNREALIZED.name,
    id: fundCompaniesTableColumn.FMV_UNREALIZED.key,
    active: true,
    children: [
      {
        field: fundCompaniesTableColumn.FMV_UNREALIZED.LOCAL.name,
        id: fundCompaniesTableColumn.FMV_UNREALIZED.LOCAL.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.FMV_UNREALIZED.FUND.name,
        id: fundCompaniesTableColumn.FMV_UNREALIZED.FUND.key,
        active: true,
      },
    ],
  },
  {
    field: fundCompaniesTableColumn.REALISED_AMOUNT.name,
    id: fundCompaniesTableColumn.REALISED_AMOUNT.key,
    active: true,
    children: [
      {
        field: fundCompaniesTableColumn.REALISED_AMOUNT.LOCAL.name,
        id: fundCompaniesTableColumn.REALISED_AMOUNT.LOCAL.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.REALISED_AMOUNT.FUND.name,
        id: fundCompaniesTableColumn.REALISED_AMOUNT.FUND.key,
        active: true,
      },
    ],
  },
  {
    field: fundCompaniesTableColumn.XIRR.name,
    id: fundCompaniesTableColumn.XIRR.key,
    active: true,
    children: [
      {
        field: fundCompaniesTableColumn.XIRR.LOCAL.name,
        id: fundCompaniesTableColumn.XIRR.LOCAL.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.XIRR.FUND.name,
        id: fundCompaniesTableColumn.XIRR.FUND.key,
        active: true,
      },
    ],
  },
  {
    field: fundCompaniesTableColumn.MOIC.name,
    id: fundCompaniesTableColumn.MOIC.key,
    active: true,
    children: [
      {
        field: fundCompaniesTableColumn.MOIC.LOCAL.name,
        id: fundCompaniesTableColumn.MOIC.LOCAL.key,
        active: true,
      },
      {
        field: fundCompaniesTableColumn.MOIC.FUND.name,
        id: fundCompaniesTableColumn.MOIC.FUND.key,
        active: true,
      },
    ],
  },
];
