/**
 * Feed reducers
 *
 * @author Ritesh Shrivastav
 */
import * as types from './actions';

const initialState = {
  feedList: [],
  // this was false before but that was causing Skeleton to fail as feedListLoading  value to false before
  // even sending request was causing Skeleton to never load
  feedListLoading: null,
  feedListError: null,
  feedListOptions: {
    page: 1,
    limit: 30
  },
  feedListMore: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
     * Feed list reducers
     */
    case types.LOAD_FEED_LIST_REQUEST:
      return Object.assign({}, state, {
        feedListLoading: true,
        feedListError: null
      });
    case types.LOAD_FEED_LIST_SUCCESS:
      return Object.assign({}, state, {
        feedList: ((payload.options.page === 1) ? payload.data : [...state.feedList,...payload.data]),
        feedListLoading: false,
        feedListError: null,
        feedListOptions: payload.options,
        feedListMore: (payload.data.length >= payload.options.limit ? true : false)
      });
    case types.LOAD_FEED_LIST_FAILURE:
      return Object.assign({}, state, {
        feedListLoading: false,
        feedListError: payload,
      });

    default:
      return state;
  }
};
