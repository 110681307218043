import address from './address';
import app from './app';
import attachment from './attachment';
import auth from './auth';
import autofill from './autofill';
import category from './category';
import ageingReport from './ageing-report';
import comment from './comment';
import contactBook from './contact-book'
import conversation from './conversation';
import consolidatedReports from './consolidated-reports'
import dataTransport from './data-transport';
import deal from './deal';
import dealAccessRequest from './deal-access-request'
import dealStage from './deal-stage';
import dealflowAnalytics from './dealflow-analytics';
import email from './email';
import emailProcessor from './emailProcessor';
import emailTemplate from './email-templates';
import event from './event';
import feed from './feed';
import holodeck from './holodeck';
import funds from './funds';
import fundDocument from './fund-documents';
import fundNote from './fund-note';
import fundTransaction from './fund-transactions'
import invitation from './invitation';
import limitedPartner from './limited-partner';
import note from './note';
import noteTemplate from './note-templates';
import notification from './notification';
import organization from './organization';
import orgContact from './orgContact';
import personContact from './personContact';
import pipelineCustomFields from './pipeline-custom-fileds'
import pipelineSignOffs from './pipeline-sign-offs';
import portfolioLanding from './portfolio/landing';
import portfolio from './portfolio';
import portfolioAnalytics from './portfolio-analytics';
import portfolioCategory from './portfolio-category';
import portfolioChart from './portfolio-chart';
import portfolioConversation from './portfolio-conversation';
import portfolioComment from './portfolio-comment';
import portfolioSheet from './portfolio-sheet';
import portfolioUpdate from './portfolio-update';
import portfolioContact from './portfolio-contact';
import portfolioCaptable from './portfolio-captable';
import portfolioValuation from './portfolio-valuation';
import reminder from './reminder';
import remindersDashboard from './reminders-dashboard'
import setting from './setting';
import signals from './signals';
import startup from './startup';
import stat from './stat';
import system from './system';
import whatsapp from './whatsapp';
import workspace from './workspace';
import magicSync from './magicSync';
import magicSyncBlocklist from './magicSyncBlocklist'
import fundPeople from './fund-people';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { LOGOUT_USER_CLEAR_REDUX } from './auth/actions';
import personContactDocument from './contact-documents/person-contact'
import personContactNotes from './contact-notes/PersonContact'
import organizationContactNotes from './contact-notes/OrganizationContact'
import organizationContactDocument from './contact-documents/organization-contact';

const createRootReducer = (history) => (state,action) => {

  //Creating reducers
  const appReducer = combineReducers({
  address,
  ageingReport,
  app,
  attachment,
  auth,
  autofill,
  category,
  comment,
  contactBook,
  conversation,
  consolidatedReports,
  dataTransport,
  deal,
  dealAccessRequest,
  dealStage,
  dealflowAnalytics,
  email,
  emailProcessor,
  emailTemplate,
  event,
  feed,
  holodeck,
  funds,
  fundDocument,
  fundNote,
  fundTransaction,
  invitation,
  limitedPartner,
  note,
  noteTemplate,
  notification,
  organization,
  orgContact,
  personContact,
  pipelineCustomFields,
  pipelineSignOffs,
  portfolio,
  portfolioAnalytics,
  portfolioCategory,
  portfolioChart,
  portfolioConversation,
  portfolioComment,
  portfolioCaptable,
  portfolioSheet,
  portfolioUpdate,
  portfolioLanding,
  portfolioContact,
  portfolioValuation,
  reminder,
  remindersDashboard,
  router: connectRouter(history),
  setting,
  signals,
  startup,
  stat,
  system,
  whatsapp,
  workspace,
  personContactDocument,
  organizationContactDocument,
  magicSync,
  magicSyncBlocklist,
  fundPeople,
  personContactNotes,
  organizationContactNotes,
})

  //https://netbasal.com/how-to-secure-your-users-data-after-logout-in-redux-30468c6848e8
  if(action.type === LOGOUT_USER_CLEAR_REDUX){
    state = undefined;
  }
  return appReducer(state,action)
};

export default createRootReducer;
