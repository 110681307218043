/** @format */

import * as types from './actions';
import reducers from './reducers';
import { wrap, api } from '../../helpers/request';

// mark reducers as the default export
export default reducers;

// LoadContactBook loads the contact book of a portfolio company identified by id
export function loadContactBook(id) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.get(`/api/portfolio/v1/companies/${id}/contacts`)
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.LOAD_PORTFOLIO_CONTACT_BOOK,
      payload: { id, data: data.data },
    });

    return data;
  };
}

// AddContact adds the provided contact object as a contacy to the portfolio company
// Ex - contact : { email_primary : 'moghe@taghash.io', first_name : 'Moghe', last_name : '' }
export function addContact(id, contactAttrs) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.post(`/api/portfolio/v1/companies/${id}/contacts`, contactAttrs)
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.ADD_PORTFOLIO_CONTACT,
      payload: {
        id,
        contact: Array.isArray(data?.data) ? data?.data[0]?.id : data?.data?.id, // Access id from the first item if it's an array, otherwise directly from the object
        data: Array.isArray(data?.data) ? data?.data : [data?.data], // Check if it's already an array
      },
    });
    return data;
  };
}

// SaveContact saves / updates the attributes of the provided contact in the given portfolio company
export function saveContact(id, contact, attrs) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.put(`/api/portfolio/v1/companies/${id}/contacts/${contact}`, attrs)
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.SAVE_PORTFOLIO_CONTACT,
      payload: {
        id,
        contact,
        data: Array.isArray(data?.data) ? data?.data : [data?.data], // Check if it's already an array
      },
    });

    return data;
  };
}

// DeleteContact deletes the contact from the portfolio company's contact book
export function deleteContact(id, contact) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.delete(`/api/portfolio/v1/companies/${id}/contacts/${contact}`)
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.DELETE_PORTFOLIO_CONTACT,
      payload: { id, contact, data: data.data },
    });

    return data;
  };
}
