/**
 * Update sign off item on a deal
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  UPDATE_SIGN_OFF_ITEM_REQUEST,
  UPDATE_SIGN_OFF_ITEM_SUCCESS,
  UPDATE_SIGN_OFF_ITEM_FAILURE,
} from '../actions';

export default (form, sign_off_id, sign_off_group_id, deal_id) => {
  return async dispatch => {
    dispatch({
      type: UPDATE_SIGN_OFF_ITEM_REQUEST,
      payload: {
        signOffId: sign_off_id,
      },
    });

    let data;
    try {
      const response = await request.put(
        `/api/pipeline/sign-offs/v1/deal/${deal_id}/sign-off-group/${sign_off_group_id}/sign-off/${sign_off_id}`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while updating this sign off item, please try again.';
      dispatch({
        type: UPDATE_SIGN_OFF_ITEM_FAILURE,
        payload: {
          signOffId: sign_off_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: UPDATE_SIGN_OFF_ITEM_SUCCESS,
        payload: {
          signOffId: sign_off_id,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: UPDATE_SIGN_OFF_ITEM_FAILURE,
        payload: {
          signOffId: sign_off_id,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
