////////////////////////////////////////////////////////////////////////////////
///                              Drawdown                                    ///
////////////////////////////////////////////////////////////////////////////////

export const ADD_DRAWDOWNS_REQUEST = 'fund-transactions/ADD_DRAWDOWNS_REQUEST';
export const ADD_DRAWDOWNS_SUCCESS = 'fund-transactions/ADD_DRAWDOWNS_SUCCESS';
export const ADD_DRAWDOWNS_FAILURE = 'fund-transactions/ADD_DRAWDOWNS_FAILURE';

export const LOAD_DRAWDOWN_LIST_REQUEST = 'fund-transactions/LOAD_DRAWDOWN_LIST_REQUEST';
export const LOAD_DRAWDOWN_LIST_SUCCESS = 'fund-transactions/LOAD_DRAWDOWN_LIST_SUCCESS';
export const LOAD_DRAWDOWN_LIST_FAILURE = 'fund-transactions/LOAD_DRAWDOWN_LIST_FAILURE';

export const GET_DRAWDOWN_REQUEST = 'fund-transactions/GET_DRAWDOWN_REQUEST';
export const GET_DRAWDOWN_SUCCESS = 'fund-transactions/GET_DRAWDOWN_SUCCESS';
export const GET_DRAWDOWN_FAILURE = 'fund-transactions/GET_DRAWDOWN_FAILURE';

export const DELETE_DRAWDOWN_REQUEST = 'fund-transactions/DELETE_DRAWDOWN_REQUEST';
export const DELETE_DRAWDOWN_SUCCESS = 'fund-transactions/DELETE_DRAWDOWN_SUCCESS';
export const DELETE_DRAWDOWN_FAILURE = 'fund-transactions/DELETE_DRAWDOWN_FAILURE';

////////////////////////////////////////////////////////////////////////////////
///                         Distributions                                    ///
////////////////////////////////////////////////////////////////////////////////

export const ADD_DISTRIBUTIONS_REQUEST = 'fund-transactions/ADD_DISTRIBUTIONS_REQUEST';
export const ADD_DISTRIBUTIONS_SUCCESS = 'fund-transactions/ADD_DISTRIBUTIONS_SUCCESS';
export const ADD_DISTRIBUTIONS_FAILURE = 'fund-transactions/ADD_DISTRIBUTIONS_FAILURE';

export const LOAD_DISTRIBUTION_LIST_REQUEST = 'fund-transactions/LOAD_DISTRIBUTION_LIST_REQUEST';
export const LOAD_DISTRIBUTION_LIST_SUCCESS = 'fund-transactions/LOAD_DISTRIBUTION_LIST_SUCCESS';
export const LOAD_DISTRIBUTION_LIST_FAILURE = 'fund-transactions/LOAD_DISTRIBUTION_LIST_FAILURE';

export const GET_DISTRIBUTION_REQUEST = 'fund-transactions/GET_DISTRIBUTION_REQUEST';
export const GET_DISTRIBUTION_SUCCESS = 'fund-transactions/GET_DISTRIBUTION_SUCCESS';
export const GET_DISTRIBUTION_FAILURE = 'fund-transactions/GET_DISTRIBUTION_FAILURE';

export const DELETE_DISTRIBUTION_REQUEST = 'fund-transactions/DELETE_DISTRIBUTION_REQUEST';
export const DELETE_DISTRIBUTION_SUCCESS = 'fund-transactions/DELETE_DISTRIBUTION_SUCCESS';
export const DELETE_DISTRIBUTION_FAILURE = 'fund-transactions/DELETE_DISTRIBUTION_FAILURE';

////////////////////////////////////////////////////////////////////////////////
///                           NAV updates                                    ///
////////////////////////////////////////////////////////////////////////////////

export const ADD_NAV_UPDATES_REQUEST = 'fund-transactions/ADD_NAV_UPDATES_REQUEST';
export const ADD_NAV_UPDATES_SUCCESS = 'fund-transactions/ADD_NAV_UPDATES_SUCCESS';
export const ADD_NAV_UPDATES_FAILURE = 'fund-transactions/ADD_NAV_UPDATES_FAILURE';

export const LOAD_NAV_UPDATE_LIST_REQUEST = 'fund-transactions/LOAD_NAV_UPDATE_LIST_REQUEST';
export const LOAD_NAV_UPDATE_LIST_SUCCESS = 'fund-transactions/LOAD_NAV_UPDATE_LIST_SUCCESS';
export const LOAD_NAV_UPDATE_LIST_FAILURE = 'fund-transactions/LOAD_NAV_UPDATE_LIST_FAILURE';

export const GET_NAV_UPDATE_REQUEST = 'fund-transactions/GET_NAV_UPDATE_REQUEST';
export const GET_NAV_UPDATE_SUCCESS = 'fund-transactions/GET_NAV_UPDATE_SUCCESS';
export const GET_NAV_UPDATE_FAILURE = 'fund-transactions/GET_NAV_UPDATE_FAILURE';

export const DELETE_NAV_UPDATE_REQUEST = 'fund-transactions/DELETE_NAV_UPDATE_REQUEST';
export const DELETE_NAV_UPDATE_SUCCESS = 'fund-transactions/DELETE_NAV_UPDATE_SUCCESS';
export const DELETE_NAV_UPDATE_FAILURE = 'fund-transactions/DELETE_NAV_UPDATE_FAILURE';

////////////////////////////////////////////////////////////////////////////////
///                           Commitments                                    ///
////////////////////////////////////////////////////////////////////////////////

export const ADD_COMMITMENT_REQUEST = 'fund-transactions/ADD_COMMITMENT_REQUEST';
export const ADD_COMMITMENT_SUCCESS = 'fund-transactions/ADD_COMMITMENT_SUCCESS';
export const ADD_COMMITMENT_FAILURE = 'fund-transactions/ADD_COMMITMENT_FAILURE';

export const LOAD_COMMITMENT_LIST_REQUEST = 'fund-transactions/LOAD_COMMITMENT_LIST_REQUEST';
export const LOAD_COMMITMENT_LIST_SUCCESS = 'fund-transactions/LOAD_COMMITMENT_LIST_SUCCESS';
export const LOAD_COMMITMENT_LIST_FAILURE = 'fund-transactions/LOAD_COMMITMENT_LIST_FAILURE';

export const GET_COMMITMENT_REQUEST = 'fund-transactions/GET_COMMITMENT_REQUEST';
export const GET_COMMITMENT_SUCCESS = 'fund-transactions/GET_COMMITMENT_SUCCESS';
export const GET_COMMITMENT_FAILURE = 'fund-transactions/GET_COMMITMENT_FAILURE';

export const DELETE_COMMITMENT_REQUEST = 'fund-transactions/DELETE_COMMITMENT_REQUEST';
export const DELETE_COMMITMENT_SUCCESS = 'fund-transactions/DELETE_COMMITMENT_SUCCESS';
export const DELETE_COMMITMENT_FAILURE = 'fund-transactions/DELETE_COMMITMENT_FAILURE';

export const CLEAR = 'fund-transactions/CLEAR';
