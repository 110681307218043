/**
 * Request lib
 *
 * @author  Ritesh Shrivastav
 * @format
 */
import axios from 'axios';
import supportsHTML5Storage from './supportsHTML5Storage';

export class ApplicationError extends Error {
  constructor(message, data) {
    super(message);
    this.isApplicationError = true;
    this.data = data;
  }
}

/**
 * Currently being served from the same host so absulute URL not required and
 * yes we are saving additional OPTION request. \m/
 */
export let api = axios.create({
  withCredentials: true,
});

/**
 * Helper to reject promise if data.success is not true
 */
export let wrap = request => {
  return request.then(response => {
    return !response.data.success
      ? Promise.reject(
          new Error(
            response.data.message ||
              'Something went wrong with processing this request. Please try again or contact support.'
          )
        )
      : response;
  });
};

/**
 * Makes request to given endpoint
 * @private
 * @param  {String} method   HTTP request method
 * @param  {String} endpoint Taghash endpoint
 * @param  {Object} data     Data to be passed
 * @param  {Object} params   Query params
 * @return {Object}          Promise object
 */
const _request = (method, endpoint, data, params, dispatch, options = {}) => {
  return (
    api
      .request({
        url: endpoint,
        method,
        data,
        params,
        headers: {
          token: supportsHTML5Storage()
            ? localStorage.getItem('jwtToken') || ''
            : '',
          'Content-Type': 'application/json',
        },
        ...options,
      })
      // Uncommenting this requires refactoring all existing action creators
      // .then(response => {
      //   return !response.data.success
      //     ? Promise.reject(
      //         new ApplicationError(
      //           response.data.message ||
      //             'Something went wrong with processing this request. Please try again or contact support.',
      //           { ...response.data, code: response.data.code }
      //         )
      //       )
      //     : response;
      // })
      .catch(err => {
        if (err.message === 'Network Error' && dispatch) {
          dispatch({ type: 'app/OFFLINE' });
        }
        return Promise.reject(err);
      })
  );
};

/**
 * Before consuming the API responses, if something has to be handled globally
 * then this is the right place to add that hook.
 *
 * @private
 */
const _beforeConsume = (promise, dispatch) => {
  return new Promise((resolve, reject) => {
    promise
      .then(response => {
        // do things which you want to do after each request
        return resolve(response);
      })
      .catch(reject);
  });
};

/**
 * Perform GET request on given endpoint
 * @param  {String} endpoint Taghash API endpoint
 * @param  {Object} params   Query params
 * @return {Object}          Promise object
 */
const get = (endpoint, params, dispatch, options = {}) => {
  return _beforeConsume(
    _request('get', endpoint, {}, params, dispatch, options),
    dispatch
  );
};

/**
 * Performs DELETE request
 * @param  {String} endpoint Taghash API endpoint
 * @param  {Object} params   Query params
 * @return {Object}          Promise object
 */
const del = (endpoint, params, dispatch, options = {}) => {
  return _beforeConsume(
    _request('delete', endpoint, {}, params, dispatch, options),
    dispatch
  );
};

/**
 * Performs POST request
 * @param  {String} endpoint Taghash API endpoint
 * @param  {Object} data     Post request body
 * @return {Object}          Promise object
 */
const post = (endpoint, data, dispatch, options = {}) => {
  return _beforeConsume(
    _request('post', endpoint, data, undefined, dispatch, options),
    dispatch
  );
};

/**
 * Performs PUT request
 * @param  {String} endpoint Taghash API endpoint
 * @param  {Object} data     PUT request body
 * @return {Object}          Promise object
 */
const put = (endpoint, data, dispatch, options = {}) => {
  return _beforeConsume(
    _request('put', endpoint, data, undefined, dispatch, options),
    dispatch
  );
};

/**
 * Performs PATCH request
 * @param  {String} endpoint Taghash API endpoint
 * @param  {Object} data     PATCH request body
 * @return {Object}          Promise object
 */
const patch = (endpoint, data, dispatch, options = {}) => {
  return _beforeConsume(
    _request('patch', endpoint, data, undefined, dispatch, options),
    dispatch
  );
};

export default {
  api,
  wrap,
  get,
  post,
  del,
  put,
  patch,
  axios,
};
