/** @format */

import reducers from './reducers';

import clearState from './actionCreators/clearState';

import addDrawdowns from './actionCreators/addDrawdowns';
import deleteDrawdown from './actionCreators/deleteDrawdown';
import getDrawdown from './actionCreators/getDrawdown';
import loadDrawdownList from './actionCreators/loadDrawdownList';

import addDistributions from './actionCreators/addDistributions';
import deleteDistribution from './actionCreators/deleteDistribution';
import getDistribution from './actionCreators/getDistribution';
import loadDistributionList from './actionCreators/loadDistributionList';

import addNavUpdates from './actionCreators/addNavUpdates';
import deleteNavUpdate from './actionCreators/deleteNavUpdate';
import getNavUpdate from './actionCreators/getNavUpdate';
import loadNavUpdateList from './actionCreators/loadNavUpdateList';

import addCommitments from './actionCreators/addCommitments';
import loadCommitmentList from './actionCreators/loadCommitmentList';
import deleteCommitment from './actionCreators/deleteCommitment';

// mark reducers as the default export
export default reducers;

export {
  clearState,
  getDrawdown,
  getDistribution,
  getNavUpdate,
  addDrawdowns,
  addCommitments,
  addDistributions,
  addNavUpdates,
  loadDrawdownList,
  loadDistributionList,
  loadNavUpdateList,
  loadCommitmentList,
  deleteDrawdown,
  deleteDistribution,
  deleteNavUpdate,
  deleteCommitment,
};
