/** @format */
import * as types from './actions';

const initialState = {
  /**
   * Holds state related to individual sheet loading, keys are the sheet ids and
   * the values are boolean indicating if it's loading or not.
   */
  sheetLoading: {},
  /**
   * Holds state related to individual sheet error, keys are the sheet ids and
   * the values are string error messages
   */
  sheetError: {},
  /**
   * Holds state related to individual sheet, keys are the sheet ids and
   * the values are object that contain the sheet metadata, its rows and columns
   */
  sheets: {},

  // state to keep track of bulk update / sheet import operation
  bulkUpdateSheetLoading: false,
  bulkUpdateSheetError: null,
  // state to keep track of report_mis_20210710 report
  // keyed by company id
  report_mis_20210710_loading: {},
  report_mis_20210710_error: {},
  report_mis_20210710_data: {},

  // state to keep track of add sheet operation
  addSheetLoading: false,
  addSheetError: null,

  // state to keep track of delete sheet operation
  deleteSheetLoading: {},
  deleteSheetError: {},

  // state to keep track of add-columns-to-sheet operation
  addColumnsLoading: {},
  addColumnsError: {},

  // state to keep track of get-sheet-list operation
  getSheetListLoading: {},
  getSheetListError: {},

  // state to keep track of delete-row operation
  deleteRowLoading: {},
  deleteRowError: {},

  // state to keep track of update-sheet operation
  updateSheetLoading: {},
  updateSheetError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /** GET_SHEET_LIST */
    case types.GET_SHEET_LIST_REQUEST:
      return Object.assign({}, state, {
        getSheetListLoading: {
          ...state.getSheetListLoading,
          [payload.portcoId]: true,
        },
        getSheetListError: {
          ...state.getSheetListError,
          [payload.portcoId]: null,
        },
      });
    case types.GET_SHEET_LIST_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        getSheetListLoading: {
          ...state.getSheetListLoading,
        },
        getSheetListError: {
          ...state.getSheetListError,
        },
      });
      // clear the target items
      delete newState.getSheetListLoading[payload.portcoId];
      delete newState.getSheetListError[payload.portcoId];
      return newState;
    }
    case types.GET_SHEET_LIST_FAILURE:
      return Object.assign({}, state, {
        getSheetListLoading: {
          ...state.getSheetListLoading,
          [payload.portcoId]: false,
        },
        getSheetListError: {
          ...state.getSheetListError,
          [payload.portcoId]: payload.message,
        },
      });
    /** GET_SHEET */
    case types.GET_SHEET_REQUEST:
      return Object.assign({}, state, {
        sheetLoading: {
          ...state.sheetLoading,
          [payload.sheetId]: true,
        },
        sheetError: {
          ...state.sheetError,
          [payload.sheetId]: null,
        },
      });
    case types.GET_SHEET_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        sheetLoading: {
          ...state.sheetLoading,
        },
        sheetError: {
          ...state.sheetError,
        },
        sheets: {
          ...state.sheets,
          [payload.sheetId]: {
            ...(state.sheets[payload.sheetId] || {}),
            ...payload.data,
          },
        },
      });
      // clear the target items
      delete newState.sheetLoading[payload.sheetId];
      delete newState.sheetError[payload.sheetId];
      return newState;
    }
    case types.GET_SHEET_FAILURE:
      return Object.assign({}, state, {
        sheetLoading: {
          ...state.sheetLoading,
          [payload.sheetId]: false,
        },
        sheetError: {
          ...state.sheetError,
          [payload.sheetId]: payload.message,
        },
      });

    /** UPDATE_SHEET */
    case types.UPDATE_SHEET_REQUEST:
      return Object.assign({}, state, {
        updateSheetLoading: {
          ...state.updateSheetLoading,
          [payload.sheetId]: true,
        },
        updateSheetError: {
          ...state.updateSheetError,
          [payload.sheetId]: null,
        },
      });
    case types.UPDATE_SHEET_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        updateSheetLoading: {
          ...state.updateSheetLoading,
        },
        updateSheetError: {
          ...state.updateSheetError,
        },
        sheets: {
          ...state.sheets,
          [payload.sheetId]: {
            ...(state.sheets[payload.sheetId] || {}),
            ...payload.data,
          },
        },
      });
      // clear the target items
      delete newState.updateSheetLoading[payload.sheetId];
      delete newState.updateSheetError[payload.sheetId];
      return newState;
    }
    case types.UPDATE_SHEET_FAILURE:
      return Object.assign({}, state, {
        updateSheetLoading: {
          ...state.updateSheetLoading,
          [payload.sheetId]: false,
        },
        updateSheetError: {
          ...state.updateSheetError,
          [payload.sheetId]: payload.message,
        },
      });

    /** GET_SHEET_COLUMNS */
    case types.GET_SHEET_COLUMNS_REQUEST:
      return Object.assign({}, state, {
        sheetLoading: {
          ...state.sheetLoading,
          [payload.sheetId]: true,
        },
        sheetError: {
          ...state.sheetError,
          [payload.sheetId]: null,
        },
      });
    case types.GET_SHEET_COLUMNS_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        sheetLoading: {
          ...state.sheetLoading,
        },
        sheetError: {
          ...state.sheetError,
        },
        sheets: {
          ...state.sheets,
          [payload.sheetId]: {
            ...(state.sheets[payload.sheetId] || { id: payload.sheetId }),
            columns: payload.data,
          },
        },
      });
      // clear the target items
      delete newState.sheetLoading[payload.sheetId];
      delete newState.sheetError[payload.sheetId];
      return newState;
    }
    case types.GET_SHEET_COLUMNS_FAILURE:
      return Object.assign({}, state, {
        sheetLoading: {
          ...state.sheetLoading,
          [payload.sheetId]: false,
        },
        sheetError: {
          ...state.sheetError,
          [payload.sheetId]: payload.message,
        },
      });
    /** GET_SHEET_ROWS */
    case types.GET_SHEET_ROWS_REQUEST:
      return Object.assign({}, state, {
        sheetLoading: {
          ...state.sheetLoading,
          [payload.sheetId]: true,
        },
        sheetError: {
          ...state.sheetError,
          [payload.sheetId]: null,
        },
      });
    case types.GET_SHEET_ROWS_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        sheetLoading: {
          ...state.sheetLoading,
        },
        sheetError: {
          ...state.sheetError,
        },
        sheets: {
          ...state.sheets,
          [payload.sheetId]: {
            ...(state.sheets[payload.sheetId] || { id: payload.sheetId }),
            // This will sort the rows by ascending order of "Added On" dates
            // @todo: Is it possible to have a cleaner way of doing this, rather
            // or doing it in the backend itself?
            rows: payload.data.sort((a, b) => {
              if (a.values[0] < b.values[0]) {
                return -1;
              } else if (a.values[0] > b.values[0]) {
                return 1;
              }
              return 0;
            }),
          },
        },
      });
      // clear the target items
      delete newState.sheetLoading[payload.sheetId];
      delete newState.sheetError[payload.sheetId];
      return newState;
    }
    case types.GET_SHEET_ROWS_FAILURE:
      return Object.assign({}, state, {
        sheetLoading: {
          ...state.sheetLoading,
          [payload.sheetId]: false,
        },
        sheetError: {
          ...state.sheetError,
          [payload.sheetId]: payload.message,
        },
      });

    /** DELETE_SHEET_ROW */
    case types.DELETE_SHEET_ROW_REQUEST:
      return Object.assign({}, state, {
        deleteRowLoading: {
          ...state.deleteRowLoading,
          [payload.sheetId]: true,
        },
        deleteRowError: {
          ...state.deleteRowError,
          [payload.sheetId]: null,
        },
      });
    case types.DELETE_SHEET_ROW_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        deleteRowLoading: {
          ...state.deleteRowLoading,
        },
        deleteRowError: {
          ...state.deleteRowError,
        },
        sheets: {
          ...state.sheets,
          [payload.sheetId]: {
            ...state.sheets[payload.sheetId],
          },
        },
      });
      const sheet = newState.sheets[payload.sheetId];
      // find and delete the row from sheet
      if (sheet && sheet.rows) {
        sheet.rows.find((row, index) => {
          if (row.position === payload.position) {
            delete sheet.rows[index];
            return true;
          }
          return false;
        });
      }
      // clear the target items
      delete newState.deleteRowLoading[payload.sheetId];
      delete newState.deleteRowError[payload.sheetId];
      return newState;
    }
    case types.DELETE_SHEET_ROW_FAILURE:
      return Object.assign({}, state, {
        deleteRowLoading: {
          ...state.deleteRowLoading,
          [payload.sheetId]: false,
        },
        deleteRowError: {
          ...state.deleteRowError,
          [payload.sheetId]: payload.message,
        },
      });

    case types.BULK_UPDATE_SHEET_REQUEST:
      return Object.assign({}, state, {
        bulkUpdateSheetLoading: true,
        bulkUpdateSheetError: null,
      });

    case types.BULK_UPDATE_SHEET_SUCCESS:
      return Object.assign({}, state, {
        bulkUpdateSheetLoading: false,
        bulkUpdateSheetError: null,
      });

    case types.BULK_UPDATE_SHEET_FAILURE:
      return Object.assign({}, state, {
        bulkUpdateSheetLoading: false,
        bulkUpdateSheetError: payload.message,
      });

    case types.REPORT_MIS_20210710_REQUEST:
      return Object.assign({}, state, {
        report_mis_20210710_loading: {
          ...state.report_mis_20210710_loading,
          [payload.portcoId]: true,
        },
        report_mis_20210710_error: {
          ...state.report_mis_20210710_error,
          [payload.portcoId]: null,
        },
      });

    case types.REPORT_MIS_20210710_SUCCESS:
      return Object.assign({}, state, {
        report_mis_20210710_loading: {
          ...state.report_mis_20210710_loading,
          [payload.portcoId]: false,
        },
        report_mis_20210710_error: {
          ...state.report_mis_20210710_error,
          [payload.portcoId]: null,
        },
        report_mis_20210710_data: {
          ...state.report_mis_20210710_data,
          [payload.portcoId]: payload.data,
        },
      });

    case types.REPORT_MIS_20210710_FAILURE: {
      return Object.assign({}, state, {
        report_mis_20210710_loading: {
          ...state.report_mis_20210710_loading,
          [payload.portcoId]: false,
        },
        report_mis_20210710_error: {
          ...state.report_mis_20210710_error,
          [payload.portcoId]: payload.message,
        },
        report_mis_20210710_data: {
          ...state.report_mis_20210710_data,
          // If error code 102 is returned, set empty data in state, else re-set
          // whatever we had before
          [payload.portcoId]:
            payload.errorCode === 102
              ? []
              : state.report_mis_20210710_data[payload.portcoId],
        },
      });
    }

    /** ADD_SHEET */
    case types.ADD_SHEET_REQUEST:
      return Object.assign({}, state, {
        addSheetLoading: true,
        addSheetError: null,
      });
    case types.ADD_SHEET_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        addSheetLoading: false,
        addSheetError: null,
        sheets: {
          ...state.sheets,
          [payload.data.id]: {
            ...(state.sheets[payload.data.id] || {}),
            ...payload.data,
          },
        },
      });
      return newState;
    }
    case types.ADD_SHEET_FAILURE:
      return Object.assign({}, state, {
        addSheetLoading: false,
        addSheetError: payload.message,
      });

    /** DELETE_SHEET */
    case types.DELETE_SHEET_REQUEST:
      return Object.assign({}, state, {
        deleteSheetLoading: {
          ...state.deleteSheetLoading,
          [payload.sheetId]: true,
        },
        deleteSheetError: {
          ...state.deleteSheetError,
          [payload.sheetId]: null,
        },
      });
    case types.DELETE_SHEET_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        deleteSheetLoading: {
          ...state.deleteSheetLoading,
        },
        deleteSheetError: {
          ...state.deleteSheetError,
        },
        sheets: {
          ...state.sheets,
        },
      });
      // remove the deleted sheet and all its data
      delete newState.sheets[payload.sheetId];
      // clear the target items
      delete newState.deleteSheetLoading[payload.sheetId];
      delete newState.deleteSheetError[payload.sheetId];
      return newState;
    }
    case types.DELETE_SHEET_FAILURE:
      return Object.assign({}, state, {
        deleteSheetLoading: {
          ...state.deleteSheetLoading,
          [payload.sheetId]: false,
        },
        deleteSheetError: {
          ...state.deleteSheetError,
          [payload.sheetId]: payload.message,
        },
      });

    /** ADD_COLUMNS */
    case types.ADD_COLUMNS_REQUEST:
      return Object.assign({}, state, {
        addColumnsLoading: {
          ...state.addColumnsLoading,
          [payload.sheetId]: true,
        },
        addColumnsError: {
          ...state.addColumnsError,
          [payload.sheetId]: null,
        },
      });
    case types.ADD_COLUMNS_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        addColumnsLoading: {
          ...state.addColumnsLoading,
        },
        addColumnsError: {
          ...state.addColumnsError,
        },
        sheets: {
          ...state.sheets,
          [payload.sheetId]: {
            ...(state.sheets[payload.sheetId] || { id: payload.sheetId }),
            columns: payload.data,
          },
        },
      });
      // clear the target items
      delete newState.addColumnsLoading[payload.sheetId];
      delete newState.addColumnsError[payload.sheetId];
      return newState;
    }
    case types.ADD_COLUMNS_FAILURE:
      return Object.assign({}, state, {
        addColumnsLoading: {
          ...state.addColumnsLoading,
          [payload.sheetId]: false,
        },
        addColumnsError: {
          ...state.addColumnsError,
          [payload.sheetId]: payload.message,
        },
      });

    case types.CLEAR_SHEETS:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
};
