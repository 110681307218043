import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const requestInvite = (form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.REQUEST_INVITE_REQUEST
      });
      request.post('/api/v1/invitations/request', form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.REQUEST_INVITE_SUCCESS
          });
          resolve(data);
        } else {
          dispatch({
            type: types.REQUEST_INVITE_FAILURE,
            payload: {
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong, please try again.'
        dispatch({
          type: types.REQUEST_INVITE_FAILURE,
          payload: { message }
        });
        reject(err);
      });
    });
  };
};
