import { 
  DEAL_CYCLE_REPORT_REQUEST,
  DEAL_CYCLE_REPORT_SUCCESS,
  DEAL_CYCLE_REPORT_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case DEAL_CYCLE_REPORT_REQUEST:
      return Object.assign({}, state, {
        cycleReportLoading: {
          ...state.cycleReportLoading,
          [payload.dealId]: true
        },
        cycleReportError: {
          ...state.cycleReportError,
          [payload.dealId]: null
        }
      });

    case DEAL_CYCLE_REPORT_SUCCESS:
      return Object.assign({}, state, {
        cycleReports: {
          ...state.cycleReports,
          [payload.dealId]: payload.data
        },
        cycleReportLoading: {
          ...state.cycleReportLoading,
          [payload.dealId]: false
        },
        cycleReportError: {
          ...state.cycleReportError,
          [payload.dealId]: null
        }
      });
      
    case DEAL_CYCLE_REPORT_FAILURE:
      return Object.assign({}, state, {
        cycleReportLoading: {
          ...state.cycleReportLoading,
          [payload.dealId]: false
        },
        cycleReportError: {
          ...state.cycleReportError,
          [payload.dealId]: payload.message
        },
      });

    default:
      return state;
  }
};
