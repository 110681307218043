/**
 * @format
 */

import reduce from 'lodash.reduce';
import * as types from '../actions';

import { assign } from '../../../helpers/object';

export default (state, type, payload) => {
  switch (type) {
    /** LOAD_DISTRIBUTION_LIST */
    case types.LOAD_DISTRIBUTION_LIST_REQUEST: {
      return assign(state, {
        distributionListLoading: true,
        distributionListError: null,
      });
    }
    case types.LOAD_DISTRIBUTION_LIST_FAILURE: {
      return assign(state, {
        distributionListLoading: false,
        distributionListError: payload.message,
      });
    }
    case types.LOAD_DISTRIBUTION_LIST_SUCCESS: {
      const distributions = reduce(
        payload.data,
        function(acc, distribution) {
          acc[distribution.id] = distribution;
          return acc;
        },
        {}
      );
      return assign(state, {
        distributionListLoading: false,
        distributionListError: null,
        distribution: assign(state.distribution, distributions),
      });
    }

    default:
      return state;
  }
};
