import {
  ADD_MULTIPLE_STARTUP_FOUNDERS_REQUEST,
  ADD_MULTIPLE_STARTUP_FOUNDERS_SUCCESS,
  ADD_MULTIPLE_STARTUP_FOUNDERS_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case ADD_MULTIPLE_STARTUP_FOUNDERS_REQUEST:
      return Object.assign({}, state, {
        addMultipleFoundersLoading: true,
        addMultipleFoundersError: null
      });

    case ADD_MULTIPLE_STARTUP_FOUNDERS_SUCCESS:
      return Object.assign({}, state, {
        addMultipleFoundersLoading: false,
        addMultipleFoundersError: null
      });

    case ADD_MULTIPLE_STARTUP_FOUNDERS_FAILURE:
      return Object.assign({}, state, {
        addMultipleFoundersLoading: false,
        addMultipleFoundersError: payload.message,
      });

    default:
      return state;
  }
};
