/**
 * @format
 */
import * as types from './actions';

const initialState = {
  // Data extracted from an email's message id. Key will be base64 encoded
  // message id of the email and value will be data returned by the emailProcessor api
  getDataFromMessageIdLoading: {},
  getDataFromMessageIdResponse: {},
  getDataFromMessageIdOptions: {},
  getDataFromMessageIdError: {},
  // key will be organization contact's id
  // why use org contact id and not email id, or message id?
  // Because at the same time, there can be multiple requests to create deal from
  // different org contacts for the same email id, but for a given org contact,
  // at any single point, there will only be one ongoing request
  createDealFromOrgContactLoading: {},
  createDealFromOrgContactResponse: {},
  createDealFromOrgContactError: {},
  addToPortcoLoading: false,
  addToPortcoError: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * get email data reducer
     */
    case types.GET_DATA_FROM_MESSAGE_ID_REQUEST:
      return Object.assign({}, state, {
        getDataFromMessageIdOptions: {
          ...state.getDataFromMessageIdOptions,
          [payload.messageId]: payload.options,
        },
        getDataFromMessageIdLoading: {
          ...state.getDataFromMessageIdLoading,
          [payload.messageId]: true,
        },
      });
    case types.GET_DATA_FROM_MESSAGE_ID_SUCCESS:
      return Object.assign({}, state, {
        getDataFromMessageIdResponse: {
          ...state.getDataFromMessageIdResponse,
          [payload.messageId]: payload.data,
        },
        getDataFromMessageIdLoading: {
          ...state.getDataFromMessageIdLoading,
          [payload.messageId]: false,
        },
      });
    case types.GET_DATA_FROM_MESSAGE_ID_ERROR:
      return Object.assign({}, state, {
        getDataFromMessageIdLoading: {
          ...state.getDataFromMessageIdLoading,
          [payload.messageId]: false,
        },
        getDataFromMessageIdError: {
          ...state.getDataFromMessageIdError,
          [payload.messageId]: payload.message,
        },
      });

    /**
     * CREATE_DEAL_FROM_ORGCONTACT reducer
     */
    case types.CREATE_DEAL_FROM_ORGCONTACT_REQUEST:
      return Object.assign({}, state, {
        createDealFromOrgContactLoading: {
          ...state.createDealFromOrgContactLoading,
          [payload.orgContactId]: true,
        },
      });
    case types.CREATE_DEAL_FROM_ORGCONTACT_SUCCESS:
      return Object.assign({}, state, {
        createDealFromOrgContactResponse: {
          ...state.createDealFromOrgContactResponse,
          [payload.orgContactId]: payload.data,
        },
        createDealFromOrgContactLoading: {
          ...state.createDealFromOrgContactLoading,
          [payload.orgContactId]: false,
        },
      });
    case types.CREATE_DEAL_FROM_ORGCONTACT_ERROR:
      return Object.assign({}, state, {
        createDealFromOrgContactLoading: {
          ...state.createDealFromOrgContactLoading,
          [payload.orgContactId]: false,
        },
        createDealFromOrgContactError: {
          ...state.createDealFromOrgContactError,
          [payload.orgContactId]: payload.message,
        },
      });

    /**
     * ADD_TO_PORTCO reducer
     */
    case types.ADD_TO_PORTCO_REQUEST:
      return Object.assign({}, state, {
        addToPortcoLoading: true,
        addToPortcoError: null,
      });
    case types.ADD_TO_PORTCO_SUCCESS:
      return Object.assign({}, state, {
        addToPortcoLoading: false,
        addToPortcoError: null,
      });
    case types.ADD_TO_PORTCO_ERROR:
      return Object.assign({}, state, {
        addToPortcoLoading: false,
        addToPortcoError: payload.message,
      });

    /**
     * Default
     */
    default:
      return state;
  }
};
