
export default {
  /**
   * Holds sign off groups, keys are the sign off group's id and values are the
   * sign off group's properties
   */
  signOffGroups: {},
  /**
   * Holds sign off items, keys are the sign off item's id and values are the
   * sign off item's properties
   */
  signOffs: {},
  /**
   * Holds list of sign off groups in each deals.
   * Keys are deal's ids, values are array of sign off group's ids
   */
  signOffGroupsDealsHashMap: {},
  /**
   * Holds association between sign off group and sign off items in the group.
   * Keys are sign off group's ids, values are array of sign off item's ids
   */
  signOffGroupsItemsHashMap: {},
  // States pertaining to adding a new sign off group
  addSignOffGroupLoading: false,
  addSignOffGroupError: null,
  // States pertaining to adding a new sign off item
  // Key is sign off group's id
  addSignOffItemLoading: {},
  addSignOffItemError: {},
  // States pertaining to updating a sign off group
  // Key is sign off group's id
  updateSignOffGroupLoading: {},
  updateSignOffGroupError: {},
  // States pertaining to updating a sign off item
  // Key is sign off item's id
  updateSignOffItemLoading: {},
  updateSignOffItemError: {},
  // States pertaining to loading sign off groups in a deal
  // Key is deal's id
  listSignOffGroupsLoading: {},
  listSignOffGroupsError: {},
  // States pertaining to loading an individual sign off group
  // Key is sign off group's id
  getSignOffGroupLoading: {},
  getSignOffGroupError: {},
  // States pertaining to loading sign off items in a group
  // Key is sign off group's id
  listSignOffItemsLoading: {},
  listSignOffItemsError: {},
  // States pertaining to loading an individual sign off item
  // Key is sign off item's id
  getSignOffItemLoading: {},
  getSignOffItemError: {},
  // States pertaining to loading activity log for an individual sign off item
  // Key is sign off item's id
  listSignOffActivityLogsLoading: {},
  listSignOffActivityLogsError: {},
  signOffActivityLogs: {},
};
