/**
 * UserAvatar component, displays user avatar
 *
 * @author Ritesh Shrivastav
 */
import Avatar from '../Avatar';
import PropTypes from 'prop-types';
import React from 'react';
import User from '../User';

class UserAvatar extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    // Since we show UserCard on the avatar, we don't need hint. If not able to
    // show UserCard consumer can pass hint by getting hint using
    // `User.getUserHint(user)`
    hint: PropTypes.string
  };

  static defaultProps = {
    hint: null
  }

  state = {
    imageError: false // handles rare situation where image loading fails
  }

  // Handle image load failure
  onImageLoadError = () => e => {
    this.setState({imageError: true})
  }

  render() {
    const { user, hint, ...others } = this.props;
    if (!user) {
      return null;
    }
    const { imageError } = this.state;
    const alt = User.getLetterTokesFromObject(user);
    if(alt === null) {
      return null;
    }

    return (
      <Avatar
        hint={hint}
        src={!imageError ? user.profile_pic || user?.logo_url : ''}
        imgProps={{onError: this.onImageLoadError()}}
        {...others}
      >
        {alt.toUpperCase()}
      </Avatar>
    );
  }
}

export default UserAvatar;
