/**
 * @format
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const loadCommentList = (conversationId, portcoId, options = {}) => {
  return dispatch => {
    dispatch({
      type: types.LOAD_COMMENT_LIST_REQUEST,
      payload: {
        conversationId: conversationId,
      },
    });
    request
      .get(
        `/api/portfolio/communications/v1/comments`,
        {
          ...options,
          conversation_id: conversationId,
          portco_id: portcoId,
          withAuthor:
            typeof options.withAuthor !== 'undefined'
              ? options.withAuthor
              : true,
          withToUsers:
            typeof options.withToUsers !== 'undefined'
              ? options.withToUsers
              : true,
          withCcUsers:
            typeof options.withCcUsers !== 'undefined'
              ? options.withCcUsers
              : true,

          withBccUsers:
            typeof options.withBccUsers !== 'undefined'
              ? options.withBccUsers
              : true,

          withtoHeaderRecipients:
            typeof options.withtoHeaderRecipients !== 'undefined'
              ? options.withtoHeaderRecipients
              : true,

          withAttachments:
            typeof options.withAttachments !== 'undefined'
              ? options.withAttachments
              : true,
        },
        dispatch
      )
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_COMMENT_LIST_SUCCESS,
            payload: {
              data: data.data,
              options: options,
              conversationId: conversationId,
            },
          });
        } else {
          dispatch({
            type: types.LOAD_COMMENT_LIST_FAILURE,
            payload: {
              message: data.message,
              conversationId: conversationId,
            },
          });
          dispatch(enqueueNotification(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while loading comments, please try again.';
        dispatch({
          type: types.LOAD_COMMENT_LIST_FAILURE,
          payload: {
            message,
            conversationId: conversationId,
          },
        });
        dispatch(enqueueNotification(message));
      });
  };
};

export const loadCommentsCount = (conversationId, portcoId) => {
  return dispatch => {
    dispatch({
      type: types.LOAD_COMMENTS_COUNT_REQUEST,
      payload: {
        conversationId: conversationId,
      },
    });
    request
      .get(
        `/api/portfolio/communications/v1/comments/count`,
        { portco_id: portcoId, conversation_id: conversationId },
        dispatch
      )
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_COMMENTS_COUNT_SUCCESS,
            payload: {
              data: data.data,
              conversationId: conversationId,
            },
          });
        } else {
          dispatch({
            type: types.LOAD_COMMENTS_COUNT_FAILURE,
            payload: {
              message: data.message,
              conversationId: conversationId,
            },
          });
          dispatch(enqueueNotification(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while loading comments, please try again.';
        dispatch({
          type: types.LOAD_COMMENTS_COUNT_FAILURE,
          payload: {
            message,
            conversationId: conversationId,
          },
        });
        dispatch(enqueueNotification(message));
      });
  };
};

export const addConversationReply = (conversationId, portcoId, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.ADD_REPLY_REQUEST,
        payload: {
          conversationId: conversationId,
        },
      });
      const body = {
        portco_id: portcoId,
        conversation_id: conversationId,
        ...form,
      };
      request
        .post(`/api/portfolio/communications/v1/comments`, body, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.ADD_REPLY_SUCCESS,
              payload: {
                data: data.data,
                conversationId: conversationId,
              },
            });
            return resolve(data.data);
          } else {
            dispatch({
              type: types.ADD_REPLY_FAILURE,
              payload: {
                message: data.message,
                conversationId: conversationId,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while adding reply, please try again.';
          dispatch({
            type: types.ADD_REPLY_FAILURE,
            payload: {
              message,
              conversationId: conversationId,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};
