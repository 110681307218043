import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import supportsHTML5Storage from '../../helpers/supportsHTML5Storage';
import { enqueueNotification } from '../app';
import { push } from 'connected-react-router';
import property from 'lodash.property';

export default reducers;

export const signup = (form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.SIGNUP_USER_REQUEST
      });
      request.post('/api/v1/invitations/connect', form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.SIGNUP_USER_SUCCESS
          });
          dispatch(enqueueNotification('Signed up successfully!, Please login to continue.'));
          resolve(data);
        } else {
          dispatch({
            type: types.SIGNUP_USER_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong, please try again.'
        dispatch({
          type: types.SIGNUP_USER_FAILURE,
          payload: message
        });
        reject(err);
      });
    });
  };
};

/**
 * User login
 * @param  {Object} form User data email and password
 */
export const login = (form, next) => {
  return dispatch => {
    supportsHTML5Storage() && localStorage.clear();
    dispatch({
      type: types.LOGIN_USER_REQUEST
    });
    request.post('/api/v1/auth/login', form, dispatch)
    .then(({ data }) => {
      if (data.success) {
        dispatch(dispatch => {
          // If auth endpoint returns error response, if this is not done,
          // it'll become an unhandled error and either page breaks, or user has
          // no clue what just happened
          // The case this handles: Login returns 200, but auth returns error
          loadUser(next || '/')(dispatch)
          .then(authData => {
            if (!authData.data || !authData.data.success) {
              dispatch({
                type: types.LOGIN_USER_FAILURE,
                payload: authData.message
              });
            }
          })
          .catch(err => {
            dispatch({
              type: types.LOGIN_USER_FAILURE,
              payload: 'Something went wrong, please try again.'
            });
          });
        });
      } else if (data.code === 106) {
        // user is already logged in
        dispatch(dispatch => {
          loadUser(next || '/')(dispatch)
          .then(authData => {
            if (!authData.data || !authData.data.success) {
              dispatch({
                type: types.LOGIN_USER_FAILURE,
                payload: authData.message
              });
            }
          })
          .catch(err => {
            dispatch({
              type: types.LOGIN_USER_FAILURE,
              payload: 'Something went wrong, please try again.'
            });
          });
        });
        dispatch(enqueueNotification(data.message));
      } else {
        dispatch({
          type: types.LOGIN_USER_FAILURE,
          payload: data.message
        });
        dispatch(enqueueNotification(data.message));
      }
    })
    .catch((err) => {
      const message = property('response.data.message')(err) || 'Something went wrong, please try again.'
      dispatch({
        type: types.LOGIN_USER_FAILURE,
        payload: message
      });
      dispatch(enqueueNotification(message));
    });
  };
};

/**
 * Load user
 * @param  {String} pathname Next path to be moved after user load
 */
export const loadUser = (pathname) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_USER_REQUEST
      });
      request.get('/api/v1/auth', {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOGIN_USER_SUCCESS,
            payload: data.data
          });
          dispatch({
            type: types.LOAD_USER_SUCCESS
          });
          pathname && dispatch(push(pathname));
          resolve(data.data);
        } else {
          dispatch({
            type: types.LOAD_USER_ERROR,
            payload: data.message || 'Something went wrong in authentication',
          });
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        dispatch({
          type: types.LOAD_USER_ERROR,
          payload: 'Something went wrong in authentication'
        });
        reject(err);
      });
    });
  };
};

/**
 * Logout from application
 */
export const logout = () => {
  return dispatch => {
    sessionStorage.setItem('authStatus', 'inactive');
    // remove all the localstorage values from the user browser
    supportsHTML5Storage() && localStorage.clear();
    dispatch({
      type: types.LOGOUT_USER_REQUEST
    });
    request.get('/api/v1/auth/logout')
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: types.LOGOUT_USER_SUCCESS
        });
        dispatch({
          type: types.LOGOUT_USER_CLEAR_REDUX
        })
        dispatch(push('/login'));

      } else {
        dispatch({
          type: types.LOGOUT_USER_FAILURE,
          payload: data.message
        });
        dispatch(enqueueNotification(data.message));
      }
    })
    .catch((err) => {
      dispatch({
        type: types.LOGOUT_USER_FAILURE,
        payload:'Something went wrong, please try again.'
      });
      dispatch(enqueueNotification('Something went wrong, please try again.'));
    });
  }
};

export const loadInviterData = (token, email) => {
  return dispatch => {
    dispatch({
      type: types.LOAD_INVITERDATA_REQUEST
    });
    request.get(`/api/v1/invitations/connect/${token}/${email}`, {}, dispatch)
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: types.LOAD_INVITERDATA_SUCCESS,
          payload: data.data
        });
      } else if (102 === data.code) {
        dispatch({
          type: types.LOAD_INVITERDATA_FAILURE,
          payload: 'Invalid request, please check the link!'
        });
        dispatch(enqueueNotification('Invalid request, please check the link!'));
      } else {
        dispatch({
          type: types.LOAD_INVITERDATA_FAILURE,
          payload: data.message
        });
        dispatch(enqueueNotification(data.message));
      }
    })
    .catch((err) => {
      const message = 'Something went wrong. Please try again.';
      dispatch({
        type: types.LOAD_INVITERDATA_FAILURE,
        payload: message
      });
       dispatch(enqueueNotification(message));
    });
  }
}

/**
 * Initiates reset-password
 * @param  {[String]} email User email
 * @return {[Promise]}
 */
export const resetPasswordInit = (email, token) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.RESET_PASSWORD_INIT_REQUEST
      });
      request.post('/api/v1/auth/reset-init', {email, token}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.RESET_PASSWORD_INIT_SUCCESS
          });
          resolve(data);
        } else {
          dispatch({
            type: types.RESET_PASSWORD_INIT_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong, please try again.';
        dispatch({
          type: types.RESET_PASSWORD_INIT_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

export const loadResetPasswordData = (token, email) => {
  return dispatch => {
    dispatch({
      type: types.RESET_PASSWORD_DATA_REQUEST
    });
    request.get(`/api/v1/auth/reset-finish/${token}/${email}`, dispatch)
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: types.RESET_PASSWORD_DATA_SUCCESS,
          payload: data.data
        });
      } else if (107 === data.code) {
        dispatch({
          type: types.RESET_PASSWORD_DATA_FAILURE,
          payload: data.message
        });
        dispatch(enqueueNotification(data.message));
      } else {
        dispatch({
          type: types.RESET_PASSWORD_DATA_FAILURE,
          payload: data.message
        });
        dispatch(enqueueNotification(data.message));
      }
    })
    .catch((err) => {
      const message = 'Something went wrong, please try again.';
      dispatch({
        type: types.RESET_PASSWORD_DATA_FAILURE,
        payload: message
      });
      dispatch(enqueueNotification(message));
    });
  };
};

export const resetPasswordFinish = (form) => {
  return dispatch => {
    supportsHTML5Storage() && localStorage.clear();
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.RESET_PASSWORD_FINISH_REQUEST
      });
      request.post(`/api/v1/auth/reset-finish`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.RESET_PASSWORD_FINISH_SUCCESS
          });
          resolve(data);
        } else {
          dispatch({
            type: types.RESET_PASSWORD_FINISH_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = property('response.data.message')(err) || 'Something went wrong, please try again.'
        dispatch({
          type: types.RESET_PASSWORD_FINISH_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Fetches google settings for login with Google
 */
export const fetchGoogleSettingsForLogin = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GOOGLE_SETTINGS_FOR_LOGIN_REQUEST
      });
      request.get('/api/v2/auth/google/settings', {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.GOOGLE_SETTINGS_FOR_LOGIN_SUCCESS,
            payload: data.data
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.GOOGLE_SETTINGS_FOR_LOGIN_FAILURE,
            payload: data.message
          });
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while fetching google settings, please try again.';
        dispatch({
          type: types.GOOGLE_SETTINGS_FOR_LOGIN_FAILURE,
          payload: message
        });
        return reject(new Error(message));
      });
    });
  };
};

export const fetchMicrosoftSettingsForLogin= () => {
  return async dispatch => {
    dispatch({
      type: types.MICROSOFT_SETTINGS_FOR_LOGIN_REQUEST
    });
    let data;
    try{
      const response = await request.get('/api/v2/auth/microsoft/settings',{}, dispatch);
      data = response.data
    }catch(err){
      const message = 'Something went wrong while fetching microsoft settings, please try again.';
      dispatch({
        type: types.MICROSOFT_SETTINGS_FOR_LOGIN_FAILURE,
        payload: message
      });
      dispatch(enqueueNotification(message))
      throw err;
    }

    if(data.success){
      dispatch({
        type: types.MICROSOFT_SETTINGS_FOR_LOGIN_SUCCESS,
        payload: data.data
      });
      return data
    } else {
      dispatch({
        type: types.MICROSOFT_SETTINGS_FOR_LOGIN_FAILURE,
        payload: data.message
      });
      dispatch(enqueueNotification(data.message))
      throw new Error(data.message)
    }
  }
}

/**
 * Initial authorization request for oauth2 flow
 */
export const oauth2AuthorizationInit = (params) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.OAUTH2_AUTHORIZATION_INIT_REQUEST
      });
      request.get(`/api/v2/auth/dialog/authorize?${params ? params.toString() : ''}`, {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.OAUTH2_AUTHORIZATION_INIT_SUCCESS,
            payload: data.data
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.OAUTH2_AUTHORIZATION_INIT_FAILURE,
            payload: data.message
          });
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while making the request, please try again.';
        dispatch({
          type: types.OAUTH2_AUTHORIZATION_INIT_FAILURE,
          payload: message
        });
        return reject(new Error(message));
      });
    });
  };
};

/**
 * Authorization decision request for oauth2 flow, where user approves/declines
 * access. Why are we not using ajax for this endpoint? Because the request
 * to be made for this will end up with a redirect, which will result in an
 * `OPTIONS` request to be made against the target server (who owns the redirect uri),
 * but the major place where we want to use it, in google app script, doesn't
 * support the resulting pre-flight request (throws 405 method not allowed error).
 *
 * That's the reason we use html form and allow the browser to handle submitting
 * the post request/redirect
 */
export const oauth2AuthorizationFinish = () => {
  return dispatch => {
    dispatch({
      type: types.OAUTH2_AUTHORIZATION_FINISH_REQUEST
    });
  };
};
