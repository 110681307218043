/**
 * @format
 */
import {
  UPDATE_TBABLE_ROW_SETTING_REQUEST,
  UPDATE_TBABLE_ROW_SETTING_SUCCESS,
  UPDATE_TBABLE_ROW_SETTING_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case UPDATE_TBABLE_ROW_SETTING_REQUEST:
      return Object.assign({}, state, {
        tableRowSettingsLoading: true,
        tableRowSettingsError: null,
      });

    case UPDATE_TBABLE_ROW_SETTING_SUCCESS:
      return Object.assign({}, state, {
        tableRowSettings: payload,
        tableRowSettingsLoading: false,
        tableRowSettingsError: null,
      });

    case UPDATE_TBABLE_ROW_SETTING_FAILURE:
      return Object.assign({}, state, {
        tableRowSettingsLoading: false,
        tableRowSettingsError: payload,
      });

    default:
      return state;
  }
};
