/**
 * Fetches the pipeline dashboards of a given org
 * @format
 */

import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_PIPELINE_DASHBOARDS_FAILURE,
  LOAD_PIPELINE_DASHBOARDS_REQUEST,
  LOAD_PIPELINE_DASHBOARDS_SUCCESS,
} from '../actions';

export default options => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LOAD_PIPELINE_DASHBOARDS_REQUEST,
        payload: {
          options,
        },
      });

      request
        .get(`/api/pipeline/dealflowbi/v1/dashboards`, options, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: LOAD_PIPELINE_DASHBOARDS_SUCCESS,
              payload: {
                data: data.data,
                options,
              },
            });
            resolve(data);
          } else {
            dispatch({
              type: LOAD_PIPELINE_DASHBOARDS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading dashboards, please try again.';
          dispatch({
            type: LOAD_PIPELINE_DASHBOARDS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
