/**
 * Conact us component
 * @format
 *
 */
import React from 'react';
import PublicPage from '../PublicPage';
import HeadManager from '../HeadManager';

const ContactUs = () => (
  <PublicPage header="Contact us">
    <HeadManager title="Contact us" />
    <div className="row">
      <div className="col-sm-6 col-sm-offset-3">
        <ul>
          <li>
            <h4>Email</h4>
            <p>Use the below details to contact us if you have any queries</p>
            <p>
              <strong>support@taghash.io</strong>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </PublicPage>
);

export default ContactUs;
