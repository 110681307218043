/**
 * Toggles expand/collapse state of a deal's summary page
 * Why is this being stored in redux? Because, if a user expands summary on a deal,
 * we can tell with reasonable guarantee that there is something of interest in it
 * for them. Which means we can predict they will interact with it a lot - by switching
 * to conversations and back. They might find some detail in a conversation that
 * our parser was not able to detect, which is not present in summary. Then they
 * switch to the appropriate conversation and switch back to summary. If we store
 * the info that they have expanded deal summary and pre-expand when they switch
 * back to the summary, we will be saving a click that might have make a difference
 * where they want to update and give up thinking it's too much effort.
 * 
 * @author Shrikrishna Holla
 */
import { TOGGLE_DEAL_SUMMARY } from '../actions';

export default (dealId) => {
  return dispatch => {
    dispatch({
      type: TOGGLE_DEAL_SUMMARY,
      payload: { dealId }
    });
  }
};
