/** @format */

import * as types from '../actions';
import request, { wrap } from '../../../helpers/request';
import isEmptyString from '../../../helpers/string/isEmptyString';
import { enqueueNotification } from '../../app';

/**
 * Deletes a distribution notice
 * @param {UUID} distributionId Id of distribution notice to load
 * @param {UUID} fundId Fund's id
 */
export default function deleteDistribution(
  distributionId,
  fundId,
  limited_partner_id
) {
  if (isEmptyString(fundId)) {
    throw new Error('Fund id missing');
  }
  if (isEmptyString(distributionId)) {
    throw new Error('Distribution id missing');
  }
  return async dispatch => {
    dispatch({
      type: types.DELETE_DISTRIBUTION_REQUEST,
      payload: {
        fundId,
        distributionId,
      },
    });

    try {
      const { data } = await wrap(
        request.del(
          `/api/fund-transactions/v1/fund/${fundId}/distributions/${distributionId}`,
          { limited_partner_id },
          dispatch
        )
      );

      dispatch({
        type: types.DELETE_DISTRIBUTION_SUCCESS,
        payload: { fundId, distributionId, data: data.data },
      });

      return data.data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong when deleting the distribution. Please try again.';
      dispatch({
        type: types.DELETE_DISTRIBUTION_FAILURE,
        payload: { fundId, distributionId, message: message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
  };
}
