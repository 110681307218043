/**
 * @format
 */
import {
  DELETE_DEAL_FAILURE,
  DELETE_DEAL_REQUEST,
  DELETE_DEAL_SUCCESS,
} from '../actions';

import { enqueueNotification } from '../../app';
/**
 * archive the deal
 *
 * @author Suraj
 */
import request from '../../../helpers/request';

export default (dealId, options) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DELETE_DEAL_REQUEST,
      });
      request
        .del(`/api/v5/deals/${dealId}`, options, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: DELETE_DEAL_SUCCESS,
              payload: {
                dealId: dealId,
              },
            });
            resolve(data);
          } else {
            dispatch({
              type: DELETE_DEAL_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while deleting deal, please try again.';
          dispatch({
            type: DELETE_DEAL_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
