/**
 * Managing notes in a fund
 *
 * @format
 */

import * as types from './actions';
import reducers from './reducers';
import request, { ApplicationError } from '../../helpers/request';
import { enqueueNotification } from '../app';
import isEmptyString from '../../helpers/string/isEmptyString';

export default reducers;

export const addFundNote = (fundId, form) => {
  return async dispatch => {
    dispatch({
      type: types.ADD_FUND_NOTE_REQUEST,
      payload: { fundId },
    });
    let data;
    try {
      const response = await request.post(
        `/api/fund-notes/v1/fund/${fundId}`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while adding a note on the fund, please try again or contact support.';
      dispatch({
        type: types.ADD_FUND_NOTE_FAILURE,
        payload: {
          message,
          fundId,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.ADD_FUND_NOTE_SUCCESS,
        payload: {
          fundId,
          data: data.data,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.ADD_FUND_NOTE_FAILURE,
        payload: {
          fundId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const getFundNoteList = (fundId, options) => {
  return async dispatch => {
    dispatch({
      type: types.LIST_FUND_NOTES_REQUEST,
      payload: { fundId, options },
    });
    let data;
    try {
      const response = await request.get(
        `/api/fund-notes/v1/fund/${fundId}`,
        options,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading list of notes on the fund, please try again or contact support.';
      dispatch({
        type: types.LIST_FUND_NOTES_FAILURE,
        payload: {
          message,
          fundId,
          options,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.LIST_FUND_NOTES_SUCCESS,
        payload: {
          fundId,
          data: data.data,
          options,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.LIST_FUND_NOTES_FAILURE,
        payload: {
          fundId,
          message: data.message,
          options,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const getFundNote = (noteId, fundId, options) => {
  return async dispatch => {
    dispatch({
      type: types.GET_FUND_NOTE_REQUEST,
      payload: { fundId, noteId, options },
    });
    let data;
    try {
      const response = await request.get(
        `/api/fund-notes/v1/fund/${fundId}/note/${noteId}`,
        options,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading a note, please try again or contact support.';
      dispatch({
        type: types.GET_FUND_NOTE_FAILURE,
        payload: {
          message,
          fundId,
          noteId,
          options,
        },
      });
      dispatch(enqueueNotification(message));
      throw new ApplicationError(message, data);
    }
    if (data.success) {
      dispatch({
        type: types.GET_FUND_NOTE_SUCCESS,
        payload: {
          fundId,
          noteId,
          data: data.data,
          options,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.GET_FUND_NOTE_FAILURE,
        payload: {
          fundId,
          noteId,
          message: data.message,
          options,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new ApplicationError(data.message, data);
    }
  };
};

export const updateFundNote = (noteId, fundId, form) => {
  return async dispatch => {
    dispatch({
      type: types.UPDATE_FUND_NOTE_REQUEST,
      payload: { noteId },
    });
    if (isEmptyString(noteId) || isEmptyString(fundId)) return;
    let data;
    try {
      const response = await request.put(
        `/api/fund-notes/v1/fund/${fundId}/note/${noteId}`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while updating a note, please try again or contact support.';
      dispatch({
        type: types.UPDATE_FUND_NOTE_FAILURE,
        payload: {
          message,
          noteId,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.UPDATE_FUND_NOTE_SUCCESS,
        payload: {
          noteId,
          data: data.data,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.UPDATE_FUND_NOTE_FAILURE,
        payload: {
          noteId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const deleteFundNote = (noteId, fundId) => {
  return async dispatch => {
    dispatch({
      type: types.DELETE_FUND_NOTE_REQUEST,
      payload: { fundId, noteId },
    });
    let data;
    try {
      const response = await request.del(
        `/api/fund-notes/v1/fund/${fundId}/note/${noteId}`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while deleting a note, please try again or contact support.';
      dispatch({
        type: types.DELETE_FUND_NOTE_FAILURE,
        payload: {
          message,
          fundId,
          noteId,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.DELETE_FUND_NOTE_SUCCESS,
        payload: {
          fundId,
          noteId,
        },
      });
      return;
    } else {
      dispatch({
        type: types.DELETE_FUND_NOTE_FAILURE,
        payload: {
          fundId,
          noteId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

/**
 * List replies to a fund note
 * @param  {String} threadId Note thread id
 * @param  {Object} fundId   Fund's id
 */
export const loadFundNoteReplyList = (threadId, fundId, options = {}) => {
  return dispatch => {
    dispatch({
      type: types.LOAD_NOTE_REPLY_LIST_REQUEST,
      payload: { threadId },
    });
    dispatch({
      type: types.LOAD_NOTE_REPLY_COUNT_REQUEST,
      payload: { threadId },
    });
    request
      .get(
        `/api/fund-notes/v1/fund/${fundId}/threads/${threadId}/replies`,
        {
          ...options,
          thread_id: threadId,
          fund_id: fundId,
          withAuthor:
            typeof options.withAuthor !== 'undefined'
              ? options.withAuthor
              : true,
          withAttachments:
            typeof options.withAttachments !== 'undefined'
              ? options.withAttachments
              : true,
        },
        dispatch
      )
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_NOTE_REPLY_LIST_SUCCESS,
            payload: {
              data: data.data,
              metadata: data.metadata,
              options: options,
              threadId,
            },
          });
          dispatch({
            type: types.LOAD_NOTE_REPLY_COUNT_SUCCESS,
            payload: {
              threadId,
              data: data.metadata,
            },
          });
        } else {
          dispatch({
            type: types.LOAD_NOTE_REPLY_LIST_FAILURE,
            payload: {
              threadId,
              message: data.message,
            },
          });
          dispatch({
            type: types.LOAD_NOTE_REPLY_COUNT_FAILURE,
            payload: {
              threadId,
              message: data.message,
            },
          });
          dispatch(enqueueNotification(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while loading replies, please try again.';
        dispatch({
          type: types.LOAD_NOTE_REPLY_LIST_FAILURE,
          payload: {
            threadId,
            message,
          },
        });
        dispatch({
          type: types.LOAD_NOTE_REPLY_COUNT_FAILURE,
          payload: {
            threadId,
            message,
          },
        });
        dispatch(enqueueNotification(message));
      });
  };
};

/**
 * Update pinned status
 * @param {String} noteId Note id
 * @param  {String} fundId Fund's id
 * @param  {Boolean} is_pinned  Pinned Status
 * @return {Promise}       Resolves on success
 */
export const updateIsPinnedFundNote = (noteId, fundId, is_pinned) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_FUND_NOTE_REQUEST,
        payload: { noteId, fundId },
      });
      request
        .put(
          `/api/fund-notes/v1/fund/${fundId}/note/${noteId}/is_pinned`,
          { is_pinned },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_FUND_NOTE_SUCCESS,
              payload: {
                noteId,
                fundId,
                data: data.data,
              },
            });
            resolve(data.data);
          } else {
            dispatch({
              type: types.UPDATE_FUND_NOTE_FAILURE,
              payload: {
                noteId,
                fundId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message = `Something went wrong while updating note, please try again.`;
          dispatch({
            type: types.UPDATE_FUND_NOTE_FAILURE,
            payload: {
              noteId,
              fundId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const loadPinnedFundNote = (fundId, options = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GET_PINNED_FUND_NOTE_REQUEST,
        payload: { fundId },
      });
      request
        .get(`/api/fund-notes/v1/fund/${fundId}/pinned`, options, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.GET_PINNED_FUND_NOTE_SUCCESS,
              payload: {
                data: data.data,
                fundId,
              },
            });
            resolve(data);
          } else {
            dispatch({
              type: types.GET_PINNED_FUND_NOTE_FAILURE,
              payload: {
                message: data.message,
                fundId,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading note, please try again.';
          dispatch({
            type: types.GET_PINNED_FUND_NOTE_FAILURE,
            payload: { message, fundId },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
