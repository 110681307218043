import * as types from './actions';
import safeParseInt from '../../helpers/string/safeParseInt';

const initialState = {
  notificationList: [],
  notificationListLoading: false,
  notificationError: null,
  notificationListOptions: {
    limit: 10,
    page: 1,
    sortBy: 'created_at',
    sortOrder: 'desc',
  },
  notificationListMore: false,
  unreadNotificationCount: 0,
  markNotificationReadLoading: {},
  markNotificationReadError: {},
  markAllNotificationsReadLoading: false,
  markAllNotificationsReadError: null,
  newIncomingNotification: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
     * Note list reducers
     */
    case types.LOAD_NOTIFICATION_LIST_REQUEST:
      return Object.assign({}, state, {
        notificationListLoading: true,
        notificationListError: null
      });
    case types.LOAD_NOTIFICATION_LIST_SUCCESS:
      const loadNotificationList__unreadNotificationCount = ((payload.metadata &&
        !isNaN(safeParseInt(payload.metadata.unreadNotificationCount))) ?
        safeParseInt(payload.metadata.unreadNotificationCount) :
        state.unreadNotificationCount
      );
      return Object.assign({}, state, {
        notificationList: ((payload.options.page === 1) ?
          payload.data : [ ...state.notificationList, ...payload.data ]
        ),
        unreadNotificationCount: loadNotificationList__unreadNotificationCount,
        notificationListLoading: false,
        notificationListError: null,
        notificationListOptions: {
          ...state.notificationListOptions,
          ...payload.options
        },
        notificationListMore: (payload.data.length >= payload.options.limit ? true : false),
        // reset flag
        newIncomingNotification: ((payload.options.page === 1) ?
          false : state.newIncomingNotification)
      });
    case types.LOAD_NOTIFICATION_LIST_FAILURE:
      return Object.assign({}, state, {
        notificationListLoading: false,
        notificationListError: payload.message,
      });


    /**
     * Mark a notification as read
     */
    case types.MARK_NOTIFICATION_READ_REQUEST:
      return Object.assign({}, state, {
        markNotificationReadLoading: {
          ...state.markNotificationReadLoading,
          [payload.notificationId]: true
        },
        markNotificationReadError: {
          ...state.markNotificationReadError,
          [payload.notificationId]: null
        }
      });
    case types.MARK_NOTIFICATION_READ_SUCCESS:
      // keep index of the notification in the list
      const markNotificationRead__index = (state.notificationList
        .findIndex(notification => notification.id === payload.notificationId));
      // cache the read status of the target notification
      const markNotificationRead__is_read = (
        state.notificationList[markNotificationRead__index].is_notification_read);

      return Object.assign({}, state, {
        notificationList: [...state.notificationList.slice(0, markNotificationRead__index),
          payload.data, // updated notification
          ...state.notificationList.slice(markNotificationRead__index + 1)
        ],
        // If it's already read, don't decrement the unread count
        unreadNotificationCount: (markNotificationRead__is_read ?
            state.unreadNotificationCount :
            state.unreadNotificationCount - 1),
        markNotificationReadLoading: {
          ...state.markNotificationReadLoading,
          [payload.notificationId]: false
        },
        markNotificationReadError: {
          ...state.markNotificationReadError,
          [payload.notificationId]: null
        }
      });
    case types.MARK_NOTIFICATION_READ_FAILURE:
      return Object.assign({}, state, {
        markNotificationReadLoading: {
          ...state.markNotificationReadLoading,
          [payload.notificationId]: false
        },
        markNotificationReadError: {
          ...state.markNotificationReadError,
          [payload.notificationId]: payload.message
        }
      });

    /**
     * Mark all as read
     */
    case types.MARK_ALL_NOTIFICATIONS_READ_REQUEST:
      return Object.assign({}, state, {
        markAllNotificationsReadLoading: true,
        markAllNotificationsReadError: null
      });
    case types.MARK_ALL_NOTIFICATIONS_READ_SUCCESS:
      return Object.assign({}, state, {
        notificationList: state.notificationList.map(notification => {
          /**
           * WARNING: Other attributes like updated_at would also have changed.
           * But, since, at this point in time, it's not being used, we are updating
           * just the attribute we care about. Doing this here means that we
           * don't need to go back to db and re-fetch all the notifications again.
           * Saves bandwidth, improves user experience
           */
          notification.is_notification_read = true;
          return notification;
        }),
        // if this api succeeded, it means all notifications, old and new, have
        // been marked as read
        unreadNotificationCount: 0,
        markAllNotificationsReadLoading: false,
        markAllNotificationsReadError: null
      });
    case types.MARK_ALL_NOTIFICATIONS_READ_FAILURE:
      return Object.assign({}, state, {
        markAllNotificationsReadLoading: false,
        markAllNotificationsReadError: payload.message,
      });

    case types.SET_NEW_INCOMING_NOTIFICATION:
      return Object.assign({}, state, {
        newIncomingNotification: true
      });

    default:
      return state;
  }
};
