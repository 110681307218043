/**
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_CUSTOM_FIELDS_REQUEST,
  LOAD_CUSTOM_FIELDS_SUCCESS,
  LOAD_CUSTOM_FIELDS_FAILURE,
} from '../actions';

export default () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LOAD_CUSTOM_FIELDS_REQUEST,
      });
      request
        .get(`/api/portfolio-fields/v1/fields`)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: LOAD_CUSTOM_FIELDS_SUCCESS,
              payload: data.data,
            });
            resolve(data);
          } else {
            dispatch({
              type: LOAD_CUSTOM_FIELDS_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading custom fields, please try again.';
          dispatch({
            type: LOAD_CUSTOM_FIELDS_FAILURE,
            payload: { message },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
