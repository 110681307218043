/**
 * Scroll to top of the page whenever location gets updated.
 * https://stackoverflow.com/a/46868351/5946125
 *
 * @author  Akhila
 */
import PropTypes from 'prop-types';
import React from 'react';
import { TOPBAR_HEIGHT } from '../../constants/View';
import { withRouter } from 'react-router-dom';

class Scroll extends React.Component {

  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  };

  /**
   * This handles checking '#' or anchor paramenter in the URL and the 
   * scroll to the element which has id as parameter.
   * @param  {String} id    Id of an element
   * @param  {Object} location 
   * @param  {Node} element  
   * @param {boolean} forced If this is true, without checkoing for 
   * location, scrolling to element takes place.
   * @return {Boolean} If scrolling happens, returns true otherwise false.
   */
  static scrollToView = (id, location, element, forced) => {
    // Return if required inputs are not present
    if (!id
      || !element
      || typeof element.scrollIntoView !== 'function') {
      return false;
    }
    if (!forced && (!location || !location.hash || (location.hash.split('#').join('')) !== id)) {
      return false;
    }
    // Element.scrollIntoView() method scrolls the element 
    // on which it's called into the visible area of the browser window.
    element.scrollIntoView(true);
    // This scrolls up with a scale of Topbar height so that we don't need a 
    // padding for the element
    window.scrollBy(0, -(TOPBAR_HEIGHT));
    return true;
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location && !this.props.location.hash) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(Scroll);
