import property from 'lodash.property';
import map from 'lodash.map';
import * as types from './actions';

const initial = {
  // keyed by portfolio company's unique identifier
  books: { /* store of contact books of various portfolio companies */}
};

const assign = (...args) => Object.assign({}, ...args); // for readability

export default function(state = initial, { type, payload }) {
  const id = payload? payload.id : null;

  switch(type) {
    case types.LOAD_PORTFOLIO_CONTACT_BOOK: {
      return assign(state, {
        books: assign(state.books, {
          [id]: payload.data
        })
      });
    }

    case types.ADD_PORTFOLIO_CONTACT: {
      const contacts = property(`books.${id}`)(state) || [];

      return assign(state, {
        books: assign(state.books, {
          [id]: [...contacts, ...payload.data]
        })
      });
    }

    case types.SAVE_PORTFOLIO_CONTACT: {
      const { contact: cid } = payload;
      const contacts = property(`books.${id}`)(state) || [];

      return assign(state, {
        books: assign(state.books, {
          [id]: map(contacts, contact => {
            // replace item in array without mutating
            return contact.id === cid? payload.data : contact
          })
        })
      });
    }

    case types.DELETE_PORTFOLIO_CONTACT: {
      const { contact: cid } = payload;
      const contacts = property(`books.${id}`)(state) || [];

      return assign(state, {
        books: assign(state.books, {
          [id]: contacts.filter(contact => contact.id !== cid)
        })
      });
    }
    default: 
      return state
  }

  
};
