/**
 * Deal component
 *
 * @format
 */
import React from 'react';
import Time from '../Time';
import { DEFAULT_ORDER_BY, ALLOWED_ORDER_BY } from '../../constants/DealList';
import { FUNDED } from '../DealStage/constants';
import property from 'lodash.property';
import { STATUS_MAP } from '../../constants/Status';
import { getMillionDollerFromDoller } from '../../helpers/conversionDollerAndMillionDoller';
import { getCroreFromRupee } from '../../helpers/conversionRupeeAndCrore';
import isEmptyString from '../../helpers/string/isEmptyString';
import { forEach, split, isEmpty } from 'lodash';

class Deal extends React.PureComponent {
  static ASSIGNEE = 'assignee';
  static CUSTOM_FIELD = 'custom_field';
  static CATEGORY = 'category';
  static PARTICIPANT = 'participant';
  static LAST_ACTIVE_AT = 'last_active_at';
  static EXTERNAL_REFERRER = 'external_referrer';
  static INTERNAL_REFERRERS = 'internal_referrer';
  static STARTUP = 'startup';
  static ATTACHMENTS = 'attachments';
  static NOTE = 'note';
  static REMINDERS = 'reminders';
  static RFERRAL_MEDIUM = 'referral_medium';
  static IS_ACTIVE = 'is_open';
  static INVESTMENT_REQUESTED = 'invetment_requested';
  static FIRST_EMAIL_INTERACTION_DATE = 'first_email_interaction_date';
  static ADDED_BY = 'added_by';
  static CREATED_AT = 'created_at';

  static ASSIGNEE_FILTER_STRATEGY = {
    AND: 'AND',
    OR: 'OR',
  };

  static ASSIGNEE_FILTER_STRATEGY_LABEL = {
    AND: 'And',
    OR: 'Or',
  };

  static getAppliedFilters = (queryParams, dealStages, teamMembers) => {
    let appliedFilters = [];
    // search term
    if (queryParams.q && queryParams.q.length > 0) {
      appliedFilters = [
        ...appliedFilters,
        {
          value: queryParams.q,
          label: queryParams.q,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'q',
        },
      ];
    }
    // Categories
    if (queryParams.categories && queryParams.categories.length > 0) {
      let selectedCategories = queryParams.categories;
      appliedFilters = [
        ...appliedFilters,
        ...selectedCategories.map(category => {
          return {
            value: category,
            label: category,
            // Since we are combining every type of filter inside one single array
            // while deleting we wouldn't know which filter to delete. Hence this
            // property.
            queryParamsName: 'categories',
          };
        }),
      ];
    }
    // NOT Categories
    if (queryParams.notCategories && queryParams.notCategories.length > 0) {
      let selectedNotCategories = queryParams.notCategories;
      appliedFilters = [
        ...appliedFilters,
        ...selectedNotCategories.map(category => {
          return {
            value: category,
            label: `No ${category}`,
            // Since we are combining every type of filter inside one single array
            // while deleting we wouldn't know which filter to delete. Hence this
            // property.
            queryParamsName: 'notCategories',
          };
        }),
      ];
    }
    // Assignees
    if (
      queryParams.assigneeInvestorIds &&
      queryParams.assigneeInvestorIds.length > 0
    ) {
      let selectedMembers = teamMembers.filter(({ id }) =>
        queryParams.assigneeInvestorIds.includes(id)
      );
      appliedFilters = [
        ...appliedFilters,
        ...selectedMembers.map((member, i) => {
          return {
            value: member.id,
            label: member.user.nameOrEmail,
            // Since we are combining every type of filter inside one single array
            // while deleting we wouldn't know which filter to delete. Hence this
            // property.
            queryParamsName: 'assigneeInvestorIds',
          };
        }),
      ];
    }
    //Assignee Filter strategy
    if (
      queryParams.assigneeFilterStrategy &&
      !isEmptyString(queryParams.assigneeFilterStrategy)
    ) {
      appliedFilters = [
        ...appliedFilters,
        {
          value: queryParams.assigneeFilterStrategy,
          label: queryParams.assigneeFilterStrategy,
          queryParamsName: 'assigneeFilterStrategy',
        },
      ];
    }

    // Internal referrer
    if (
      queryParams.internalReferrerInvestorIds &&
      queryParams.internalReferrerInvestorIds.length > 0
    ) {
      let selectedMembers = teamMembers.filter(({ id }) =>
        queryParams.internalReferrerInvestorIds.includes(id)
      );
      appliedFilters = [
        ...appliedFilters,
        ...selectedMembers.map((member, i) => {
          return {
            value: member.id,
            label: member.user.nameOrEmail,
            // Since we are combining every type of filter inside one single array
            // while deleting we wouldn't know which filter to delete. Hence this
            // property.
            queryParamsName: 'internalReferrerInvestorIds',
          };
        }),
      ];
    }
    // externalReferrerNameOrEmail term
    if (
      queryParams.externalReferrerNameOrEmail &&
      queryParams.externalReferrerNameOrEmail.length > 0
    ) {
      appliedFilters = [
        ...appliedFilters,
        {
          value: queryParams.externalReferrerNameOrEmail,
          label: queryParams.externalReferrerNameOrEmail,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'externalReferrerNameOrEmail',
        },
      ];
    }
    // startupFounderNameOrEmail term
    if (
      queryParams.startupFounderNameOrEmail &&
      queryParams.startupFounderNameOrEmail.length > 0
    ) {
      appliedFilters = [
        ...appliedFilters,
        {
          value: queryParams.startupFounderNameOrEmail,
          label: queryParams.startupFounderNameOrEmail,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'startupFounderNameOrEmail',
        },
      ];
    }
    // startupDescription term
    if (
      queryParams.startupDescription &&
      queryParams.startupDescription.length > 0
    ) {
      appliedFilters = [
        ...appliedFilters,
        {
          value: queryParams.startupDescription,
          label: queryParams.startupDescription,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'startupDescription',
        },
      ];
    }
    // Stages
    if (queryParams.deal_stages && queryParams.deal_stages.length > 0) {
      let selectedStages = dealStages.filter(stage =>
        queryParams.deal_stages.includes(stage.id)
      );
      appliedFilters = [
        ...appliedFilters,
        ...selectedStages.map(stage => {
          return {
            value: stage.id,
            label: stage.name,
            // Since we are combining every type of filter inside one single array
            // while deleting we wouldn't know which filter to delete. Hence this
            // property.
            queryParamsName: 'deal_stages',
          };
        }),
      ];
    }
    // attachmentFilename term
    if (
      queryParams.attachmentFilename &&
      queryParams.attachmentFilename.length > 0
    ) {
      appliedFilters = [
        ...appliedFilters,
        {
          value: queryParams.attachmentFilename,
          label: queryParams.attachmentFilename,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'attachmentFilename',
        },
      ];
    }
    // customData term
    if (queryParams.customData && queryParams.customData.length > 0) {
      appliedFilters = [
        ...appliedFilters,
        ...queryParams.customData.map(filter => {
          let value = filter.value;
          if (filter.datatype === 'datetime') {
            value = `${filter.value.from} - ${filter.value.to}`;
          }
          if (
            filter.datatype === 'numeric' ||
            filter.datatype === 'percent' ||
            filter.datatype === 'currency'
          ) {
            value = filter.value.from || filter.value.to || filter.value.eq;
          }
          return {
            value: filter.key,
            label: `${filter.key}: ${value}`,
            // Since we are combining every type of filter inside one single array
            // while deleting we wouldn't know which filter to delete. Hence this
            // property.
            queryParamsName: `customData:${filter.key}`,
          };
        }),
      ];
    }
    // globalOperationsAddress term
    if (
      queryParams.globalOperationsAddress &&
      queryParams.globalOperationsAddress.length > 0
    ) {
      appliedFilters = [
        ...appliedFilters,
        {
          value: queryParams.globalOperationsAddress,
          label: queryParams.globalOperationsAddress,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'globalOperationsAddress',
        },
      ];
    }
    // localOperationsAddress term
    if (
      queryParams.localOperationsAddress &&
      queryParams.localOperationsAddress.length > 0
    ) {
      appliedFilters = [
        ...appliedFilters,
        {
          value: queryParams.localOperationsAddress,
          label: queryParams.localOperationsAddress,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'localOperationsAddress',
        },
      ];
    }
    // referralMedium
    if (queryParams.referralMedium && queryParams.referralMedium.length > 0) {
      let selectedReferralMedia = queryParams.referralMedium;
      appliedFilters = [
        ...appliedFilters,
        ...selectedReferralMedia.map(referral_medium => {
          return {
            value: referral_medium,
            label: referral_medium,
            // Since we are combining every type of filter inside one single array
            // while deleting we wouldn't know which filter to delete. Hence this
            // property.
            queryParamsName: 'referralMedium',
          };
        }),
      ];
    }
    // investmentRequested term
    if (
      queryParams.investmentRequested &&
      Object.keys(queryParams.investmentRequested).length > 0
    ) {
      const investmentRequested = queryParams.investmentRequested;
      const investmentStages =
        investmentRequested.investmentStages &&
        investmentRequested.investmentStages.length
          ? investmentRequested.investmentStages.join(', ')
          : '';
      const ranges =
        investmentRequested.currency &&
        Object.keys(investmentRequested.currency).length
          ? Object.keys(investmentRequested.currency)
              .map(currency => {
                switch (currency) {
                  case '$':
                    return `${
                      investmentRequested.currency[currency].from
                        ? `${getMillionDollerFromDoller(
                            investmentRequested.currency[currency].from
                          )}M$`
                        : '0'
                    }-${
                      investmentRequested.currency[currency].to
                        ? `${getMillionDollerFromDoller(
                            investmentRequested.currency[currency].to
                          )}M$`
                        : ''
                    }`;
                  case '₹':
                    return `${
                      investmentRequested.currency[currency].from
                        ? `${getCroreFromRupee(
                            investmentRequested.currency[currency].from
                          )} Cr₹`
                        : '0'
                    }-${
                      investmentRequested.currency[currency].to
                        ? `${getCroreFromRupee(
                            investmentRequested.currency[currency].to
                          )} Cr₹`
                        : ''
                    }`;
                  default:
                    return '';
                }
              })
              .join(', ')
          : '';
      appliedFilters = [
        ...appliedFilters,
        {
          value: `${investmentStages}, ${ranges}`,
          label: `${investmentStages}, ${ranges}`,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'investmentRequested',
        },
      ];
    }
    // startupContactEmail term
    if (
      queryParams.startupContactEmail &&
      queryParams.startupContactEmail.length > 0
    ) {
      appliedFilters = [
        ...appliedFilters,
        {
          value: queryParams.startupContactEmail,
          label: queryParams.startupContactEmail,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'startupContactEmail',
        },
      ];
    }
    // startupContactPhoneNumber term
    if (
      queryParams.startupContactPhoneNumber &&
      queryParams.startupContactPhoneNumber.length > 0
    ) {
      appliedFilters = [
        ...appliedFilters,
        {
          value: queryParams.startupContactPhoneNumber,
          label: queryParams.startupContactPhoneNumber,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'startupContactPhoneNumber',
        },
      ];
    }
    // startupEmployeeCount
    if (
      queryParams.startupEmployeeCount &&
      queryParams.startupEmployeeCount.length > 0
    ) {
      let selectedStartupEmployeeCount = queryParams.startupEmployeeCount;
      appliedFilters = [
        ...appliedFilters,
        ...selectedStartupEmployeeCount.map(startupEmployeeCount => {
          return {
            value: startupEmployeeCount,
            label: startupEmployeeCount,
            // Since we are combining every type of filter inside one single array
            // while deleting we wouldn't know which filter to delete. Hence this
            // property.
            queryParamsName: 'startupEmployeeCount',
          };
        }),
      ];
    }
    // startupFoundingYear
    if (
      queryParams.startupFoundingYear &&
      Object.keys(queryParams.startupFoundingYear).length > 0
    ) {
      let selectedStartupFoundingYear = queryParams.startupFoundingYear;
      const range = `${
        selectedStartupFoundingYear.from ? selectedStartupFoundingYear.from : ''
      }-${
        selectedStartupFoundingYear.to ? selectedStartupFoundingYear.to : ''
      }`;
      appliedFilters = [
        ...appliedFilters,
        {
          value: range,
          label: range,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'startupFoundingYear',
        },
      ];
    }
    // startupOperationalStatus
    if (
      queryParams.startupOperationalStatus &&
      queryParams.startupOperationalStatus.length > 0
    ) {
      let selectedStartupOperationalStatus =
        queryParams.startupOperationalStatus;
      appliedFilters = [
        ...appliedFilters,
        ...selectedStartupOperationalStatus.map(startupOperationalStatus => {
          return {
            value: startupOperationalStatus,
            label: startupOperationalStatus,
            // Since we are combining every type of filter inside one single array
            // while deleting we wouldn't know which filter to delete. Hence this
            // property.
            queryParamsName: 'startupOperationalStatus',
          };
        }),
      ];
    }
    // startupRegisteredName term
    if (
      queryParams.startupRegisteredName &&
      queryParams.startupRegisteredName.length > 0
    ) {
      appliedFilters = [
        ...appliedFilters,
        {
          value: queryParams.startupRegisteredName,
          label: queryParams.startupRegisteredName,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'startupRegisteredName',
        },
      ];
    }
    // startupWebsite term
    if (queryParams.startupWebsite && queryParams.startupWebsite.length > 0) {
      appliedFilters = [
        ...appliedFilters,
        {
          value: queryParams.startupWebsite,
          label: queryParams.startupWebsite,
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'startupWebsite',
        },
      ];
    }
    // is_opem / status filter
    if (
      typeof queryParams.is_open === 'boolean' ||
      typeof queryParams.is_open === 'string'
    ) {
      let isOpen = queryParams.is_open;
      if (typeof queryParams.is_open === 'string') {
        isOpen = queryParams.is_open.toLowerCase === 'true';
      }
      appliedFilters = [
        ...appliedFilters,
        {
          value: isOpen,
          label: STATUS_MAP[isOpen],
          // Since we are combining every type of filter inside one single array
          // while deleting we wouldn't know which filter to delete. Hence this
          // property.
          queryParamsName: 'is_open',
        },
      ];
    }
    // Created from and to
    if (queryParams.created_from) {
      let createdFrom = Time.getFormattedString(
        queryParams.created_from,
        'DD MMM YYYY'
      );
      appliedFilters = [
        ...appliedFilters,
        {
          value: createdFrom,
          label: `From: ${createdFrom}`,
          queryParamsName: 'created_from',
        },
      ];
    }
    if (queryParams.created_to) {
      let createdTo = Time.getFormattedString(
        queryParams.created_to,
        'DD MMM YYYY'
      );
      appliedFilters = [
        ...appliedFilters,
        {
          value: createdTo,
          label: `To: ${createdTo}`,
          queryParamsName: 'created_to',
        },
      ];
    }
    // First Email Interaction Date from and to
    if (queryParams.first_email_interaction_date_from) {
      let FirstEmailInteractionDateFrom = Time.getFormattedString(
        queryParams.first_email_interaction_date_from,
        'DD MMM YYYY'
      );
      appliedFilters = [
        ...appliedFilters,
        {
          value: FirstEmailInteractionDateFrom,
          label: `From: ${FirstEmailInteractionDateFrom}`,
          queryParamsName: 'first_email_interaction_date_from',
        },
      ];
    }
    if (queryParams.first_email_interaction_date_to) {
      let FirstEmailInteractionDateTo = Time.getFormattedString(
        queryParams.first_email_interaction_date_to,
        'DD MMM YYYY'
      );
      appliedFilters = [
        ...appliedFilters,
        {
          value: FirstEmailInteractionDateTo,
          label: `To: ${FirstEmailInteractionDateTo}`,
          queryParamsName: 'first_email_interaction_date_to',
        },
      ];
    }
    return appliedFilters;
  };

  static areNonQuickFiltersApplied = queryParams => {
    let showAppliedFilter = false;

    // is_opem / status filter
    if (
      typeof queryParams.is_open === 'boolean' ||
      typeof queryParams.is_open === 'string'
    ) {
      showAppliedFilter = true;
    }

    // Assignees
    if (
      queryParams.assigneeInvestorIds &&
      queryParams.assigneeInvestorIds.length > 0
    ) {
      showAppliedFilter = false;
    }
    //Assignee Filter strategy
    if (
      queryParams.assigneeFilterStrategy &&
      !isEmptyString(queryParams.assigneeFilterStrategy)
    ) {
      showAppliedFilter = false;
    }

    // search term
    if (queryParams.q && queryParams.q.length > 0) {
      showAppliedFilter = true;
    }
    // Categories
    if (queryParams.categories && queryParams.categories.length > 0) {
      showAppliedFilter = true;
    }
    // NOT Categories
    if (queryParams.notCategories && queryParams.notCategories.length > 0) {
      showAppliedFilter = true;
    }

    // Internal referrer
    if (
      queryParams.internalReferrerInvestorIds &&
      queryParams.internalReferrerInvestorIds.length > 0
    ) {
      showAppliedFilter = true;
    }
    // externalReferrerNameOrEmail term
    if (
      queryParams.externalReferrerNameOrEmail &&
      queryParams.externalReferrerNameOrEmail.length > 0
    ) {
      showAppliedFilter = true;
    }
    // startupFounderNameOrEmail term
    if (
      queryParams.startupFounderNameOrEmail &&
      queryParams.startupFounderNameOrEmail.length > 0
    ) {
      showAppliedFilter = true;
    }
    // startupDescription term
    if (
      queryParams.startupDescription &&
      queryParams.startupDescription.length > 0
    ) {
      showAppliedFilter = true;
    }
    // Stages
    if (queryParams.deal_stages && queryParams.deal_stages.length > 0) {
      showAppliedFilter = true;
    }
    // attachmentFilename term
    if (
      queryParams.attachmentFilename &&
      queryParams.attachmentFilename.length > 0
    ) {
      showAppliedFilter = true;
    }
    // customData term
    if (queryParams.customData && queryParams.customData.length > 0) {
      showAppliedFilter = true;
    }
    // globalOperationsAddress term
    if (
      queryParams.globalOperationsAddress &&
      queryParams.globalOperationsAddress.length > 0
    ) {
      showAppliedFilter = true;
    }
    // localOperationsAddress term
    if (
      queryParams.localOperationsAddress &&
      queryParams.localOperationsAddress.length > 0
    ) {
      showAppliedFilter = true;
    }
    // referralMedium
    if (queryParams.referralMedium && queryParams.referralMedium.length > 0) {
      showAppliedFilter = true;
    }
    // investmentRequested term
    if (
      queryParams.investmentRequested &&
      Object.keys(queryParams.investmentRequested).length > 0
    ) {
      showAppliedFilter = true;
    }
    // startupContactEmail term
    if (
      queryParams.startupContactEmail &&
      queryParams.startupContactEmail.length > 0
    ) {
      showAppliedFilter = true;
    }
    // startupContactPhoneNumber term
    if (
      queryParams.startupContactPhoneNumber &&
      queryParams.startupContactPhoneNumber.length > 0
    ) {
      showAppliedFilter = true;
    }
    // startupEmployeeCount
    if (
      queryParams.startupEmployeeCount &&
      queryParams.startupEmployeeCount.length > 0
    ) {
      showAppliedFilter = true;
    }
    // startupFoundingYear
    if (
      queryParams.startupFoundingYear &&
      Object.keys(queryParams.startupFoundingYear).length > 0
    ) {
      showAppliedFilter = true;
    }
    // startupOperationalStatus
    if (
      queryParams.startupOperationalStatus &&
      queryParams.startupOperationalStatus.length > 0
    ) {
      showAppliedFilter = true;
    }
    // startupRegisteredName term
    if (
      queryParams.startupRegisteredName &&
      queryParams.startupRegisteredName.length > 0
    ) {
      showAppliedFilter = true;
    }
    // startupWebsite term
    if (queryParams.startupWebsite && queryParams.startupWebsite.length > 0) {
      showAppliedFilter = true;
    }

    // Created from and to
    if (queryParams.created_from) {
      showAppliedFilter = true;
    }
    if (queryParams.created_to) {
      showAppliedFilter = true;
    }

    // Last Active from and till
    if (queryParams.last_active_from) {
      showAppliedFilter = true;
    }
    if (queryParams.last_active_till) {
      showAppliedFilter = true;
    }
    // First Email Interaction Date from and to
    if (queryParams.first_email_interaction_date_from) {
      showAppliedFilter = true;
    }
    if (queryParams.first_email_interaction_date_to) {
      showAppliedFilter = true;
    }
    //Added By Filter strategy
    if (
      queryParams.addedByInvestorIds &&
      queryParams.addedByInvestorIds.length > 0
    ) {
      showAppliedFilter = true;
    }

    return showAppliedFilter;
  };

  /**
   * Returns true if deal can be edited
   */
  static canEdit = deal => {
    if (!deal || !deal._roles || deal._roles.length < 1) {
      return false;
    }
    for (let i = 0; i < deal._roles.length; i++) {
      if (
        deal._roles[i] === 'adminInvestor' ||
        deal._roles[i] === 'pipelineAdmin' ||
        deal._roles[i] === 'workspaceAdmin' ||
        deal._roles[i] === 'assigneeInvestor'
      ) {
        return true;
      }
    }
    return false;
  };

  /**
   * Returns true if user has read permission on `what` detail of the given
   * deal.
   */
  static canView = (deal, what) => {
    if (!deal || !deal._roles || deal._roles.length < 1) {
      return false;
    }
    switch (what) {
      /**
       * adminInvestor, pipelineAdmin, workspaceAdmin or assigneeInvestor can
       * see following details.
       */
      case Deal.PARTICIPANT:
      case Deal.LAST_ACTIVE_AT:
      case Deal.CREATED_AT:
      case Deal.ADDED_BY:
      case Deal.STARTUP:
      case Deal.ASSIGNEE:
      case Deal.CATEGORY:
      case Deal.EXTERNAL_REFERRER:
      case Deal.INTERNAL_REFERRERS:
      case Deal.CUSTOM_FIELD:
      case Deal.ATTACHMENTS:
      case Deal.NOTE:
      case Deal.REMINDERS:
      case Deal.RFERRAL_MEDIUM:
      case Deal.INVESTMENT_REQUESTED:
      case Deal.FIRST_EMAIL_INTERACTION_DATE:
      case Deal.IS_ACTIVE:
        return (
          deal._roles.indexOf('adminInvestor') > -1 ||
          deal._roles.indexOf('pipelineAdmin') > -1 ||
          deal._roles.indexOf('workspaceAdmin') > -1 ||
          deal._roles.indexOf('assigneeInvestor') > -1 ||
          deal._roles.indexOf('participantInvestor') > -1
        );

      default:
        return false;
    }
  };

  /**
   * Returns true if deal is loaded by someone who has access to deal
   */
  static canAccess = deal => {
    if (!deal || !deal._roles || deal._roles.length < 1) {
      return false;
    }
    return (
      deal._roles.indexOf('adminInvestor') > -1 ||
      deal._roles.indexOf('pipelineAdmin') > -1 ||
      deal._roles.indexOf('workspaceAdmin') > -1 ||
      deal._roles.indexOf('assigneeInvestor') > -1 ||
      deal._roles.indexOf('participantInvestor') > -1
    );
  };

  /**
   * Returns true if deal is loaded by an admin investor
   */
  static isAdminInvestor = deal => {
    if (!deal || !deal._roles || deal._roles.length < 1) {
      return false;
    }
    return deal && deal._roles && deal._roles.indexOf('adminInvestor') > -1;
  };

  /**
   * Returns name of startup given deal that has startup relation loaded
   */
  static getStartupName = deal =>
    deal && deal.startup && deal.startup.name
      ? deal.startup.name
      : 'Untitled Company';

  static isFundedDeal = deal => property('stage.name')(deal) === FUNDED.name;

  static AVAILABLE_FILTERS = {
    ids: 'ids',
    q: 'q',
    categories: 'categories',
    notCategories: 'notCategories',
    addedByInvestorIds: 'addedByInvestorIds',
    assigneeInvestorIds: 'assigneeInvestorIds',
    assigneeFilterStrategy: 'assigneeFilterStrategy',
    internalReferrerInvestorIds: 'internalReferrerInvestorIds',
    externalReferrerNameOrEmail: 'externalReferrerNameOrEmail',
    startupFounderNameOrEmail: 'startupFounderNameOrEmail',
    startupDescription: 'startupDescription',
    deal_stages: 'deal_stages',
    attachmentFilename: 'attachmentFilename',
    customData: 'customData',
    globalOperationsAddress: 'globalOperationsAddress',
    localOperationsAddress: 'localOperationsAddress',
    referralMedium: 'referralMedium',
    investmentRequested: 'investmentRequested',
    startupContactEmail: 'startupContactEmail',
    startupContactPhoneNumber: 'startupContactPhoneNumber',
    startupEmployeeCount: 'startupEmployeeCount',
    startupFoundingYear: 'startupFoundingYear',
    startupOperationalStatus: 'startupOperationalStatus',
    startupRegisteredName: 'startupRegisteredName',
    startupWebsite: 'startupWebsite',
    is_open: 'is_open',
    created_from: 'created_from',
    created_to: 'created_to',
    last_active_from: 'last_active_from',
    last_active_till: 'last_active_till',
    first_email_interaction_date_from: 'first_email_interaction_date_from',
    first_email_interaction_date_to: 'first_email_interaction_date_to',
    include_child: 'include_child',
    sortOrder: 'sortOrder',
    sortBy: 'sortBy',
    filter_changed: 'filter_changed',
  };

  /**
   * Clears the applied filters to deal list
   */
  static clearFilters = ({ history, location, shouldClearWorkspaceFilter }) => {
    if (!location || !history || !history.push) {
      return;
    }
    const params = new URLSearchParams(location.search || '');
    // workspace_id  filter is different from others, and is only deleted if
    // options is passed
    if (shouldClearWorkspaceFilter) {
      params.delete('workspace_id');
    }
    params.delete('ids');
    params.delete('q');
    params.delete('categories');
    params.delete('notCategories');
    params.delete('assigneeInvestorIds');
    params.delete('addedByInvestorIds');
    params.delete('assigneeFilterStrategy');
    params.delete('internalReferrerInvestorIds');
    params.delete('externalReferrerNameOrEmail');
    params.delete('startupFounderNameOrEmail');
    params.delete('startupDescription');
    params.delete('deal_stages');
    params.delete('attachmentFilename');
    params.delete('customData');
    params.delete('globalOperationsAddress');
    params.delete('localOperationsAddress');
    params.delete('referralMedium');
    params.delete('investmentRequested');
    params.delete('startupContactEmail');
    params.delete('startupContactPhoneNumber');
    params.delete('startupEmployeeCount');
    params.delete('startupFoundingYear');
    params.delete('startupOperationalStatus');
    params.delete('startupRegisteredName');
    params.delete('startupWebsite');
    params.delete('is_open');
    params.delete('created_from');
    params.delete('created_to');
    params.delete('last_active_from');
    params.delete('last_active_till');
    params.delete('first_email_interaction_date_from');
    params.delete('first_email_interaction_date_to');
    params.delete('include_child');
    params.set('filter_changed', true);
    // Redirect user to /deals route without any query params because all
    // filters are being taken from the query params.
    history.push(`/dashboard/deals?${params.toString()}`);
  };

  /**
   * From location, it extracts the query params which is applicable to
   * loading deal list. It also sets default query params if none set.
   */
  static getQueryParams = location => {
    if (!location) {
      return {};
    }
    const params = new URLSearchParams(location.search || '');
    // parse json attributes
    let customData = [],
      investmentRequested,
      startupFoundingYear;
    if (params.get('customData')) {
      try {
        customData = JSON.parse(params.get('customData'));
        if (customData && !Object.keys(customData).length) {
          customData = [];
        }
      } catch (e) {
        // ignore
      }
    }
    if (params.get('investmentRequested')) {
      try {
        investmentRequested = JSON.parse(params.get('investmentRequested'));
        if (investmentRequested && !Object.keys(investmentRequested).length) {
          investmentRequested = undefined;
        }
      } catch (e) {
        // ignore
      }
    }
    if (params.get('startupFoundingYear')) {
      try {
        startupFoundingYear = JSON.parse(params.get('startupFoundingYear'));
        if (startupFoundingYear && !Object.keys(startupFoundingYear).length) {
          startupFoundingYear = undefined;
        }
      } catch (e) {
        // ignore
      }
    }
    return {
      ids: params.getAll('ids'),
      //
      // used to search based on some term
      q: params.get('q'),
      workspace_id: params.get('workspace_id'),
      categories: params.getAll('categories'),
      notCategories: params.getAll('notCategories'),
      assigneeInvestorIds:
        params.getAll('assigneeInvestorIds') ||
        params.getAll('assignee_investor_id'),
      addedByInvestorIds: params.getAll('addedByInvestorIds'),
      assigneeFilterStrategy: params.get('assigneeFilterStrategy'),
      internalReferrerInvestorIds: params.getAll('internalReferrerInvestorIds'),
      externalReferrerNameOrEmail: params.get('externalReferrerNameOrEmail'),
      startupFounderNameOrEmail: params.get('startupFounderNameOrEmail'),
      startupDescription: params.get('startupDescription'),
      deal_stages: params.getAll('deal_stages'),
      attachmentFilename: params.get('attachmentFilename'),
      customData,
      globalOperationsAddress: params.get('globalOperationsAddress'),
      localOperationsAddress: params.get('localOperationsAddress'),
      referralMedium: params.getAll('referralMedium'),
      investmentRequested,
      startupContactEmail: params.get('startupContactEmail'),
      startupContactPhoneNumber: params.get('startupContactPhoneNumber'),
      startupEmployeeCount: params.getAll('startupEmployeeCount'),
      startupFoundingYear,
      startupOperationalStatus: params.getAll('startupOperationalStatus'),
      startupRegisteredName: params.get('startupRegisteredName'),
      startupWebsite: params.get('startupWebsite'),
      is_open:
        typeof params.get('is_open') === 'string'
          ? params.get('is_open') === 'true'
          : undefined,
      //
      created_from: params.get('created_from') || '',
      created_to: params.get('created_to') || '',
      last_active_from: params.get('last_active_from') || '',
      last_active_till: params.get('last_active_till') || '',
      first_email_interaction_date_from:
        params.get('first_email_interaction_date_from') || '',
      first_email_interaction_date_to:
        params.get('first_email_interaction_date_to') || '',
      // If set to false, it returns only the deals directly associated with
      // selected categories. By default it's true
      include_child: params.get('include_child') || '',
      //
      cursor: params.get('cursor') || null,
      sortOrder: params.get('sortOrder') || 'desc',
      sortBy: params.get('sortBy') || DEFAULT_ORDER_BY,
      //
      //If there is no filter change set to false
      filter_changed: params.get('filter_changed') || false,
    };
  };

  static areNonDefaultFiltersApplied = location => {
    const queryParams = Deal.getQueryParams(location);
    return (
      (queryParams.ids && queryParams.ids.length > 0) ||
      (queryParams.q && queryParams.q.length > 0) ||
      (queryParams.categories && queryParams.categories.length > 0) ||
      (queryParams.notCategories && queryParams.notCategories.length > 0) ||
      !!(
        queryParams.assigneeInvestorIds &&
        queryParams.assigneeInvestorIds.length > 0
      ) ||
      (queryParams.addedByInvestorIds &&
        queryParams.addedByInvestorIds.length > 0) ||
      (queryParams.assigneeFilterStrategy &&
        queryParams.assigneeFilterStrategy.length > 0) ||
      (queryParams.internalReferrerInvestorIds &&
        queryParams.internalReferrerInvestorIds.length > 0) ||
      (queryParams.externalReferrerNameOrEmail &&
        queryParams.externalReferrerNameOrEmail.length > 0) ||
      (queryParams.startupFounderNameOrEmail &&
        queryParams.startupFounderNameOrEmail.length > 0) ||
      (queryParams.startupDescription &&
        queryParams.startupDescription.length > 0) ||
      (queryParams.deal_stages && queryParams.deal_stages.length > 0) ||
      (queryParams.attachmentFilename &&
        queryParams.attachmentFilename.length > 0) ||
      (queryParams.customData && queryParams.customData.length > 0) ||
      (queryParams.globalOperationsAddress &&
        queryParams.globalOperationsAddress.length > 0) ||
      (queryParams.localOperationsAddress &&
        queryParams.localOperationsAddress.length > 0) ||
      (queryParams.referralMedium && queryParams.referralMedium.length > 0) ||
      (queryParams.investmentRequested &&
        queryParams.investmentRequested.length > 0) ||
      (queryParams.startupContactEmail &&
        queryParams.startupContactEmail.length > 0) ||
      (queryParams.startupContactPhoneNumber &&
        queryParams.startupContactPhoneNumber.length > 0) ||
      (queryParams.startupEmployeeCount &&
        queryParams.startupEmployeeCount.length > 0) ||
      (queryParams.startupFoundingYear &&
        Object.keys(queryParams.startupFoundingYear).length > 0) ||
      (queryParams.startupOperationalStatus &&
        queryParams.startupOperationalStatus.length > 0) ||
      (queryParams.startupRegisteredName &&
        queryParams.startupRegisteredName.length > 0) ||
      (queryParams.startupWebsite && queryParams.startupWebsite.length > 0) ||
      typeof queryParams.is_open === 'boolean' ||
      !!queryParams.created_from ||
      !!queryParams.created_to ||
      !!queryParams.last_active_from ||
      !!queryParams.last_active_till ||
      !!queryParams.first_email_interaction_date_from ||
      !!queryParams.first_email_interaction_date_to
    );
  };

  /**
   * Redirects to new filter for deal list
   * @param filters Filters to be applied
   */
  static redirectToNewFilter = (filters = {}, history, location) => {
    if (!history || !history.push || !location) {
      return;
    }
    const params = new URLSearchParams(location.search || '');
    // prepare ids filter
    const idsToBeApplied = new Set(filters.ids);
    params.delete('ids');
    idsToBeApplied.forEach(id => {
      !!id && params.append('ids', id);
    });
    // q filter
    if (filters.q) {
      params.set('q', filters.q);
    } else {
      params.delete('q');
    }
    // workspace_id filter
    if (filters.workspace_id) {
      params.set('workspace_id', filters.workspace_id);
    } else {
      params.delete('workspace_id');
    }
    // prepare category filter
    const categoriesToApplied = new Set(filters.categories);
    params.delete('categories');
    categoriesToApplied.forEach(category => {
      !!category && params.append('categories', category);
    });
    // prepare not-category filter
    const notCategoriesToBeApplied = new Set(filters.notCategories);
    params.delete('notCategories');
    notCategoriesToBeApplied.forEach(category => {
      !!category && params.append('notCategories', category);
    });
    // Filter on assignees
    const assigneesToBeApplied = new Set(filters.assigneeInvestorIds);
    params.delete('assigneeInvestorIds');
    assigneesToBeApplied.forEach(assignee_id => {
      !!assignee_id && params.append('assigneeInvestorIds', assignee_id);
    });
    //Filter on addedby
    const addedBysToBeApplied = new Set(filters.addedByInvestorIds);
    params.delete('addedByInvestorIds');
    addedBysToBeApplied.forEach(addedById => {
      !!addedById && params.append('addedByInvestorIds', addedById);
    });

    // Related to assignee filter: assignee filter strategy
    if (filters.assigneeFilterStrategy) {
      params.set('assigneeFilterStrategy', filters.assigneeFilterStrategy);
    } else {
      params.delete('assigneeFilterStrategy');
    }
    // Filter on internal referrers
    const internalReferrersToBeApplied = new Set(
      filters.internalReferrerInvestorIds
    );
    params.delete('internalReferrerInvestorIds');
    internalReferrersToBeApplied.forEach(investor_id => {
      !!investor_id &&
        params.append('internalReferrerInvestorIds', investor_id);
    });
    // Filter on external referrer name or email
    if (filters.externalReferrerNameOrEmail) {
      params.set(
        'externalReferrerNameOrEmail',
        filters.externalReferrerNameOrEmail
      );
    } else {
      params.delete('externalReferrerNameOrEmail');
    }
    // Filter on startup founder's name or email
    if (filters.startupFounderNameOrEmail) {
      params.set(
        'startupFounderNameOrEmail',
        filters.startupFounderNameOrEmail
      );
    } else {
      params.delete('startupFounderNameOrEmail');
    }
    // Filter on startup's description
    if (filters.startupDescription) {
      params.set('startupDescription', filters.startupDescription);
    } else {
      params.delete('startupDescription');
    }
    // prepare deal stages filter
    const stagesToBeApplied = new Set(filters.deal_stages);
    params.delete('deal_stages');
    stagesToBeApplied.forEach(deal_stage_id => {
      !!deal_stage_id && params.append('deal_stages', deal_stage_id);
    });
    // prepare attachment filename filter
    if (filters.attachmentFilename) {
      params.set('attachmentFilename', filters.attachmentFilename);
    } else {
      params.delete('attachmentFilename');
    }
    // prepare custom data filter
    if (filters.customData) {
      params.set('customData', JSON.stringify(filters.customData));
    } else {
      params.delete('customData');
    }
    // prepare global address filter
    if (filters.globalOperationsAddress) {
      params.set('globalOperationsAddress', filters.globalOperationsAddress);
    } else {
      params.delete('globalOperationsAddress');
    }
    // prepare local address filter
    if (filters.localOperationsAddress) {
      params.set('localOperationsAddress', filters.localOperationsAddress);
    } else {
      params.delete('localOperationsAddress');
    }
    // prepare referral medium filter
    const referralMediumOptionsToBeApplied = new Set(filters.referralMedium);
    params.delete('referralMedium');
    referralMediumOptionsToBeApplied.forEach(referral_medium => {
      !!referral_medium && params.append('referralMedium', referral_medium);
    });
    // prepare investment requested filter
    if (filters.investmentRequested) {
      params.set(
        'investmentRequested',
        JSON.stringify(filters.investmentRequested)
      );
    } else {
      params.delete('investmentRequested');
    }
    // prepare contact email filter
    if (filters.startupContactEmail) {
      params.set('startupContactEmail', filters.startupContactEmail);
    } else {
      params.delete('startupContactEmail');
    }
    // prepare contact phone filter
    if (filters.startupContactPhoneNumber) {
      params.set(
        'startupContactPhoneNumber',
        filters.startupContactPhoneNumber
      );
    } else {
      params.delete('startupContactPhoneNumber');
    }
    // prepare employee count filter
    const emplCountOptionsToBeApplied = new Set(filters.startupEmployeeCount);
    params.delete('startupEmployeeCount');
    emplCountOptionsToBeApplied.forEach(employee_count => {
      !!employee_count && params.append('startupEmployeeCount', employee_count);
    });
    // prepare founding year filter
    if (filters.startupFoundingYear) {
      params.set(
        'startupFoundingYear',
        JSON.stringify(filters.startupFoundingYear)
      );
    } else {
      params.delete('startupFoundingYear');
    }
    // prepare operational status filter
    const opStatusOptionsToBeApplied = new Set(
      filters.startupOperationalStatus
    );
    params.delete('startupOperationalStatus');
    opStatusOptionsToBeApplied.forEach(op_status => {
      !!op_status && params.append('startupOperationalStatus', op_status);
    });
    // prepare registered name filter
    if (filters.startupRegisteredName) {
      params.set('startupRegisteredName', filters.startupRegisteredName);
    } else {
      params.delete('startupRegisteredName');
    }
    // prepare startup website filter
    if (filters.startupWebsite) {
      params.set('startupWebsite', filters.startupWebsite);
    } else {
      params.delete('startupWebsite');
    }
    // prepare is_open filter
    if (typeof filters.is_open === 'boolean') {
      params.set('is_open', filters.is_open);
    } else if (typeof filters.is_open === 'string') {
      params.set('is_open', filters.is_open.toLowerCase() === 'true');
    } else {
      params.delete('is_open');
    }
    // Created from
    if (
      !!filters.created_from &&
      Time.isValidTimeString(filters.created_from)
    ) {
      params.set('created_from', filters.created_from);
    } else {
      params.delete('created_from');
    }
    // Created to
    if (!!filters.created_to && Time.isValidTimeString(filters.created_to)) {
      params.set('created_to', filters.created_to);
    } else {
      params.delete('created_to');
    }
    //Last active at from
    if (
      !!filters.last_active_from &&
      Time.isValidTimeString(filters.last_active_from)
    ) {
      params.set('last_active_from', filters.last_active_from);
    } else {
      params.delete('last_active_from');
    }

    //Last active at till
    if (
      !!filters.last_active_till &&
      Time.isValidTimeString(filters.last_active_till)
    ) {
      params.set('last_active_till', filters.last_active_till);
    } else {
      params.delete('last_active_till');
    }

    // first email interaction date from
    if (
      !!filters.first_email_interaction_date_from &&
      Time.isValidTimeString(filters.first_email_interaction_date_from)
    ) {
      params.set(
        'first_email_interaction_date_from',
        filters.first_email_interaction_date_from
      );
    } else {
      params.delete('first_email_interaction_date_from');
    }
    // first email interaction date to
    if (
      !!filters.first_email_interaction_date_to &&
      Time.isValidTimeString(filters.first_email_interaction_date_to)
    ) {
      params.set(
        'first_email_interaction_date_to',
        filters.first_email_interaction_date_to
      );
    } else {
      params.delete('first_email_interaction_date_to');
    }
    // prepare page which is not a a part of filter but, page is expected to
    // set to 1 whenever new filter is applied manually.
    // cursor
    if (filters.cursor) {
      params.set('cursor', filters.cursor);
    } else {
      params.set('cursor', null);
    }
    // If filters are intentionally changed, append temparoary query parameter
    // 'filter_changed' to indicate the same and also to preserve the applied
    // filters in query params.
    filters.filter_changed &&
      params.set('filter_changed', filters.filter_changed);
    // Prepare sortOrder params
    if (filters.sortOrder && ['asc', 'desc'].indexOf(filters.sortOrder) > -1) {
      params.set('sortOrder', filters.sortOrder);
    } else {
      params.set('sortOrder', 'desc');
    }

    // Prepare sortBy params
    if (filters.sortBy && ALLOWED_ORDER_BY.indexOf(filters.sortBy) > -1) {
      params.set('sortBy', filters.sortBy);
    } else {
      params.set('sortBy', DEFAULT_ORDER_BY);
    }

    history.push(`/dashboard/deals?${params.toString()}`);
  };

  static openSearchFilterTypes = {
    custom_data: 'io.taghash.custom_data',
    custom_categories: 'io.taghash.custom_categories',
    not_custom_categories: 'io.taghash.not_custom_categories',
    deal_stages: 'io.taghash.deal_stages',
    workspace_id: 'io.taghash.workspace_id',
    assignees: 'io.taghash.assignees',
    created_at: 'io.taghash.created_at',
    last_active_at: 'io.taghash.last_active_at',
    first_email_interaction_date: 'io.taghash.first_email_interaction_date',
    contact_number: 'io.taghash.contact_number',
    contact_email: 'io.taghash.contact_email',
    operational_status: 'io.taghash.operational_status',
    referral_medium: 'io.taghash.referral_medium',
    is_open: 'io.taghash.is_open',
    founder_name_or_email: 'io.taghash.founder_name_or_email',
    registered_name: 'io.taghash.registered_name',
    url: 'io.taghash.url',
    description: 'io.taghash.description',
    investment_stage: 'io.taghash.investment_stage',
    investment_amount: 'io.taghash.investment_amount',
    founding_year: 'io.taghash.founding_year',
    local_address: 'io.taghash.local_address',
    global_address: 'io.taghash.global_address',
    employee_count: 'io.taghash.employee_count',
    external_ref: 'io.taghash.external_ref',
    internal_ref: 'io.taghash.internal_ref',
    added_by: 'io.taghash.creator',
    attachments: 'io.taghash.attachments',
  };

  /**
   *
   * @param {Object} queryParams
   * @returns Array of Objects
   *  filters = [
        {
          type: 'io.taghash.custom_data',
          key: 'Direct CF 1',
          value: 'three',
          datatype: 'string',
        }, {}, {} ,..., {}
      ]
   *
   */
  static getOpenSearchFilterFormatFromParams = queryParams => {
    const filters = [];

    if (!isEmpty(queryParams.customData)) {
      forEach(queryParams.customData, cd => {
        filters.push({
          key: Deal.openSearchFilterTypes['custom_data'],
          field: cd.key,
          value: cd.value,
          datatype: cd.datatype,
        });
      });
    }
    if (!isEmpty(queryParams.categories)) {
      forEach(queryParams.categories, value => {
        const field = split(value, '/', 1)[0]?.trim();
        filters.push({
          key: Deal.openSearchFilterTypes['custom_categories'],
          field,
          value,
        });
      });
    }
    if (!isEmpty(queryParams.notCategories)) {
      filters.push({
        key: Deal.openSearchFilterTypes['not_custom_categories'],
        field: queryParams.notCategories,
      });
    }
    if (!isEmpty(queryParams.startupRegisteredName)) {
      filters.push({
        key: Deal.openSearchFilterTypes['registered_name'],
        value: queryParams.startupRegisteredName,
      });
    }
    if (!isEmpty(queryParams.startupWebsite)) {
      filters.push({
        key: Deal.openSearchFilterTypes['url'],
        value: queryParams.startupWebsite,
      });
    }
    if (!isEmpty(queryParams.startupDescription)) {
      filters.push({
        key: Deal.openSearchFilterTypes['description'],
        value: queryParams.startupDescription,
      });
    }
    if (!isEmpty(queryParams.startupContactPhoneNumber)) {
      filters.push({
        key: Deal.openSearchFilterTypes['contact_number'],
        value: queryParams.startupContactPhoneNumber,
      });
    }
    if (!isEmpty(queryParams.startupContactEmail)) {
      filters.push({
        key: Deal.openSearchFilterTypes['contact_email'],
        value: queryParams.startupContactEmail,
      });
    }
    if (!isEmpty(queryParams.startupOperationalStatus)) {
      filters.push({
        key: Deal.openSearchFilterTypes['operational_status'],
        value: queryParams.startupOperationalStatus,
      });
    }
    if (!isEmpty(queryParams.startupEmployeeCount)) {
      filters.push({
        key: Deal.openSearchFilterTypes['employee_count'],
        value: queryParams.startupEmployeeCount,
      });
    }
    if (!isEmpty(queryParams.investmentRequested?.investmentStages)) {
      filters.push({
        key: Deal.openSearchFilterTypes['investment_stage'],
        value: queryParams.investmentRequested.investmentStages,
      });
    }
    if (!isEmpty(queryParams.investmentRequested?.currency)) {
      filters.push({
        key: Deal.openSearchFilterTypes['investment_amount'],
        value: queryParams.investmentRequested.currency,
      });
    }
    if (!isEmpty(queryParams.startupFoundingYear)) {
      filters.push({
        key: Deal.openSearchFilterTypes['founding_year'],
        value: queryParams.startupFoundingYear,
      });
    }

    if (!isEmpty(queryParams.localOperationsAddress)) {
      filters.push({
        key: Deal.openSearchFilterTypes['local_address'],
        value: queryParams.localOperationsAddress,
      });
    }
    if (!isEmpty(queryParams.globalOperationsAddress)) {
      filters.push({
        key: Deal.openSearchFilterTypes['global_address'],
        value: queryParams.globalOperationsAddress,
      });
    }

    if (!isEmpty(queryParams.deal_stages)) {
      filters.push({
        key: Deal.openSearchFilterTypes['deal_stages'],
        value: queryParams.deal_stages,
      });
    }
    if (!isEmpty(queryParams.workspace_id)) {
      filters.push({
        key: Deal.openSearchFilterTypes['workspace_id'],
        value: queryParams.workspace_id,
      });
    }

    if (!isEmpty(queryParams.referralMedium)) {
      filters.push({
        key: Deal.openSearchFilterTypes['referral_medium'],
        value: queryParams.referralMedium,
      });
    }
    if (typeof queryParams.is_open === 'boolean') {
      filters.push({
        key: Deal.openSearchFilterTypes['is_open'],
        value: queryParams.is_open,
      });
    }

    if (!isEmpty(queryParams.startupFounderNameOrEmail)) {
      filters.push({
        key: Deal.openSearchFilterTypes['founder_name_or_email'],
        value: queryParams.startupFounderNameOrEmail,
      });
    }
    if (!isEmpty(queryParams.externalReferrerNameOrEmail)) {
      filters.push({
        key: Deal.openSearchFilterTypes['external_ref'],
        value: queryParams.externalReferrerNameOrEmail,
      });
    }
    if (
      !isEmpty(queryParams.internalReferrerInvestorIds) &&
      queryParams.internalReferrerInvestorIds.length > 0
    ) {
      filters.push({
        key: Deal.openSearchFilterTypes['internal_ref'],
        value: queryParams.internalReferrerInvestorIds,
      });
    }
    if (
      !isEmpty(queryParams.addedByInvestorIds) &&
      queryParams.addedByInvestorIds.length > 0
    ) {
      filters.push({
        key: Deal.openSearchFilterTypes['added_by'],
        value: queryParams.addedByInvestorIds,
      });
    }

    if (!isEmpty(queryParams.attachmentFilename)) {
      filters.push({
        key: Deal.openSearchFilterTypes['attachments'],
        value: queryParams.attachmentFilename,
      });
    }

    if (!isEmpty(queryParams.assigneeInvestorIds)) {
      filters.push({
        key: Deal.openSearchFilterTypes['assignees'],
        value: queryParams.assigneeInvestorIds,
        strategy: queryParams.assigneeFilterStrategy,
      });
    }
    if (
      !isEmpty(queryParams.created_from) ||
      !isEmpty(queryParams.created_to)
    ) {
      filters.push({
        key: Deal.openSearchFilterTypes['created_at'],
        value: { from: queryParams.created_from, to: queryParams.created_to },
      });
    }
    if (
      !isEmpty(queryParams.last_active_from) ||
      !isEmpty(queryParams.last_active_till)
    ) {
      filters.push({
        key: Deal.openSearchFilterTypes['last_active_at'],
        value: {
          from: queryParams.last_active_from,
          to: queryParams.last_active_till,
        },
      });
    }
    if (
      !isEmpty(queryParams.first_email_interaction_date_from) ||
      !isEmpty(queryParams.first_email_interaction_date_to)
    ) {
      filters.push({
        key: Deal.openSearchFilterTypes['first_email_interaction_date'],
        value: {
          from: queryParams.first_email_interaction_date_from,
          to: queryParams.first_email_interaction_date_to,
        },
      });
    }
    return filters;
  };

  static getOpenSearchSortOptionsFromParams = queryParams => {
    let sortBy = 'last_active_at';
    let sortOrder = 'desc';
    if (!isEmpty(queryParams.sortBy)) {
      if (queryParams.sortBy === 'last_active_at') sortBy = 'last_active_at';
      else if (queryParams.sortBy === 'created_at') sortBy = 'created_at';
      if (queryParams.sortBy === 'startup_name') sortBy = 'startup_name';
      else if (queryParams.sortBy === 'deal_stage_position_id')
        sortBy = 'deal_stage_position_id';
      else if (queryParams.sortBy === 'first_email_interaction_date')
        sortBy = 'first_email_interaction_date';
    }
    if (!isEmpty(queryParams.sortOrder)) {
      if (queryParams.sortOrder === 'asc') sortOrder = 'asc';
      else if (queryParams.sortOrder === 'desc') sortOrder = 'desc';
    }
    return { sortBy, sortOrder };
  };

  // helper functions to work with cursor
  static cursor = {
    encode(val) {
      return Buffer.from(JSON.stringify(val)).toString('base64');
    },

    decode(str) {
      try {
        return !isEmptyString(str)
          ? JSON.parse(Buffer.from(str, 'base64').toString('ascii'))
          : null;
      } catch {
        return null;
      }
    },
  };

  render() {
    return null;
  }
}

export default Deal;
