/**
 * Event reducers also exports action creators
 *
 * @author Ritesh Shrivastav
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

/**
 * Loads list of events
 * @param  {Object} options Options for the list
 */
export const loadEventList = (options) => {
  return dispatch => {
    dispatch({
      type: types.LOAD_EVENT_LIST_REQUEST
    });
    request.get(`/api/v1/events`, options, dispatch)
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: types.LOAD_EVENT_LIST_SUCCESS,
          payload: {
            data: data.data,
            options: options
          }
        });
      } else {
        dispatch({
          type: types.LOAD_EVENT_LIST_FAILURE,
          payload: data.message
        });
        dispatch(enqueueNotification(data.message));
      }
    })
    .catch((err) => {
      const message = 'Something went wrong while loading events, please try again.';
      dispatch({
        type: types.LOAD_EVENT_LIST_FAILURE,
        payload: message
      });
      dispatch(enqueueNotification(message));
    });
  };
};

/**
 * Loads specific event
 * @param  {String} eventId Event id to be fetched
 */
export const loadEvent = (eventId) => {
  return dispatch => {
    dispatch({
      type: types.LOAD_EVENT_REQUEST
    });
    request.get(`/api/v1/events/${eventId}`, {
      withPipeline: true
    }, dispatch)
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: types.LOAD_EVENT_SUCCESS,
          payload: data.data
        });
      } else {
        dispatch({
          type: types.LOAD_EVENT_FAILURE,
          payload: data.message
        });
        dispatch(enqueueNotification(data.message));
      }
    })
    .catch((err) => {
      const message = 'Something went wrong while loading event, please try again.';
      dispatch({
        type: types.LOAD_EVENT_FAILURE,
        payload: message
      });
      dispatch(enqueueNotification(message));
    });
  };
};

/**
 * Associates event to existing deal
 * @param  {String} eventId Event id
 * @param  {String} dealId  Deal id
 * @return {Promise}        Promise resolves on success
 */
export const assignEventToDeal = (eventId, dealId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.ASSIGN_TO_DEAL_REQUEST
      });
      request.post(`/api/v1/events/${eventId}/confirm`, {
        pipeline: dealId
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.ASSIGN_TO_DEAL_SUCCESS,
            payload: data.data
          });
          resolve(data.data);
        } else {
          dispatch({
            type: types.ASSIGN_TO_DEAL_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while associating event with deal, please try again.';
        dispatch({
          type: types.ASSIGN_TO_DEAL_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
