import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';
import { UPDATE_DEAL_NOTES, DELETE_DEAL_NOTE_SUCCESS, LOAD_DEAL_NOTE_SUCCESS } from '../deal/actions';

export default reducers;

/**
 * Updates a deal note
 * @param  {String} dealId Deal id
 * @param  {Object} form   Form data
 * @return {Promise}       Resolves on success
 */
export const updateDealNote = (noteId, dealId, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_DEAL_NOTE_REQUEST,
        payload: { noteId }
      });
      request.put(`/api/v3/notes/${noteId}?deal_id=${dealId}`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.UPDATE_DEAL_NOTE_SUCCESS,
            payload: {
              noteId,
              data: data.data
            }
          });
          dispatch({
            type: UPDATE_DEAL_NOTES,
            payload: {
              noteId,
              dealId,
              data: data.data
            }
          })
          resolve(data.data);
        } else {
          dispatch({
            type: types.UPDATE_DEAL_NOTE_FAILURE,
            payload: {
              noteId,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while updating note, please try again.';
        dispatch({
          type: types.UPDATE_DEAL_NOTE_FAILURE,
          payload: {
            noteId,
            message
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Deletes a deal note
 * @param  {String} noteId Note id
 * @param  {String} dealId Deal id
 * @return {Promise}       Resolves on success
 */
export const deleteDealNote = (noteId, dealId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!noteId || !dealId) {
        const message = 'Missing arguments while deleting note, please try again.';
        dispatch({
          type: types.DELETE_DEAL_NOTE_FAILURE,
          payload: {
            noteId,
            dealId,
            message
          }
        });
        return reject(new Error(message));
      }
      dispatch({
        type: types.DELETE_DEAL_NOTE_REQUEST,
        payload: { noteId, dealId }
      });
      request.del(`/api/v5/notes/${noteId}?deal_id=${dealId}`, {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.DELETE_DEAL_NOTE_SUCCESS,
            payload: {
              noteId,
              dealId,
            }
          });
          dispatch({
            type: DELETE_DEAL_NOTE_SUCCESS,
            payload: {
              noteId,
              dealId,
            }
          })
          return resolve();
        } else {
          dispatch({
            type: types.DELETE_DEAL_NOTE_FAILURE,
            payload: {
              noteId,
              dealId,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while deleting note, please try again.';
        dispatch({
          type: types.DELETE_DEAL_NOTE_FAILURE,
          payload: {
            noteId,
            dealId,
            message
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Updates a deal note
 * @param  {String} threadId Note thread id
 * @param  {Object} pipelineId   pipelineId
 */
 export const loadNoteReplyList = (threadId, pipelineId, options = {}) => {
  return dispatch => {
      dispatch({
        type: types.LOAD_NOTE_REPLY_LIST_REQUEST,
        payload: { threadId }
      });
      dispatch({
        type: types.LOAD_NOTE_REPLY_COUNT_REQUEST,
        payload: { threadId }
      });
      request.get(
        `/api/v3/notes/${pipelineId}/threads/${threadId}/replies`,
        {
          ...options,
          thread_id: threadId,
          pipeline_id: pipelineId,
          withAuthor:
            typeof options.withAuthor !== 'undefined'
              ? options.withAuthor
              : true,
          withAttachments:
            typeof options.withAttachments !== 'undefined'
              ? options.withAttachments
              : true,
        },
        dispatch
      )
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_NOTE_REPLY_LIST_SUCCESS,
            payload: {
              data: data.data,
              metadata: data.metadata,
              options: options,
              threadId,
            }
          });
          dispatch({
            type: types.LOAD_NOTE_REPLY_COUNT_SUCCESS,
            payload: {
              threadId,
              data: data.metadata
            }
          })
        } else {
          dispatch({
            type: types.LOAD_NOTE_REPLY_LIST_FAILURE,
            payload: {
              threadId,
              message: data.message
            }
          });
          dispatch({
            type: types.LOAD_NOTE_REPLY_COUNT_FAILURE,
            payload: {
              threadId,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading replies, please try again.';
        dispatch({
          type: types.LOAD_NOTE_REPLY_LIST_FAILURE,
          payload: {
            threadId,
            message,
          }
        });
        dispatch({
          type: types.LOAD_NOTE_REPLY_COUNT_FAILURE,
          payload: {
            threadId,
            message,
          }
        });
        dispatch(enqueueNotification(message));
      });
  };
};


export const transcribeNote = (noteId, dealId) => {
  return async dispatch => {
    if (!noteId || !dealId) {
      const message = 'Note id or deal id missing';
      dispatch({
        type: types.TRANSCRIBE_NOTE_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.TRANSCRIBE_NOTE_REQUEST,
      payload: {
        dealId,
        noteId
      },
    });
    let data;
    try {
      const response = await request.post(
        `/api/pipeline-notes/v1/deal/${dealId}/note/${noteId}/transcribe`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      let message =
        'Something went wrong while transcribing note. Please try again.';
      if(err?.response?.status === 429) {
        message = `Too fast! Try again in a couple of seconds.`
      }
      dispatch({
        type: types.TRANSCRIBE_NOTE_FAILURE,
        payload: {
          dealId,
          noteId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.TRANSCRIBE_NOTE_SUCCESS,
        payload: {
          dealId,
          noteId,
          data: data.data,
        },
      });
      // update note content as well
      dispatch({
        type: LOAD_DEAL_NOTE_SUCCESS,
        payload: {
          noteId,
          data: data.data
        }
      });
      dispatch({
        type: UPDATE_DEAL_NOTES,
        payload: {
          noteId,
          dealId,
          data: data.data
        }
      });
      return data.data;
    } else {
      dispatch({
        type: types.TRANSCRIBE_NOTE_FAILURE,
        payload: {
          dealId,
          noteId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};


