import {
  REMOVE_PARTICIPANT_REQUEST,
  REMOVE_PARTICIPANT_SUCCESS,
  REMOVE_PARTICIPANT_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case REMOVE_PARTICIPANT_REQUEST:
      return Object.assign({}, state, {
        removeParticipantLoading: true,
        removeParticipantError: null
      });

    case REMOVE_PARTICIPANT_SUCCESS:
      return Object.assign({}, state, {
        removeParticipantLoading: false,
        removeParticipantError: null,
      });

    case REMOVE_PARTICIPANT_FAILURE:
      return Object.assign({}, state, {
        removeParticipantLoading: false,
        removeParticipantError: payload,
      });

    default:
      return state;
  }
};
