import defaultTableViewColumnsConfig from "./defaultTableViewColumnsConfig";

/** Initial state for portfolio resources */
export default {
  // Loading and error states for add portfolio company requests
  addPortCoLoading: false,
  addPortCoError: null,

  /**
   * State that holds all the loaded portcos
   * This object is namespaced by a portco's id, and the value
   * will be the portco's data
   */
  portcos: {},

   /**
   * Holds limit and pages of the portfolio list
   */
  portCoOptions: { limit: 10, page: 1 },

  // Loading and error states for list portco requests
  portCoListLoading: false,
  portCoListError: null,
  // Holds the latest options passed when loading list of portcos
  portCoListOptions: {},
  // Stores the loading, err state for `getPortCo` calls. Namespaced by portcoId
  getPortCoLoading: {},
  getPortCoError: {},
  // namespaced by portcoid
  categoriesLoading: {},
  categoriesError: {},
  // no namespacing
  addMultipleFoundersLoading: false,
  addMultipleFoundersError: null,
  // no namespacing
  removeFounderLoading: false,
  removeFounderError: null,
  // delete portco
  deletePortcoLoading: false,
  deletePortcoError: null,
  // stores data, loading, err states for portco's acl. Keyed by portcoId
  portcoACL: {},
  portcoACLLoading: {},
  portcoACLError: {},
  // state used when portfolio company is being updated
  updatePortCoLoading: false,
  updatePortCoError: null,

  //funds
  funds: [],
  fundsLoading: false,
  fundsError: null,

  //Table view configuration for Portfolio Table View
  columns: defaultTableViewColumnsConfig,
  columnsLoading: false,
  columnsError: null,
  manageColumnOpen: false,

  //Filters made in portfolio
  filters:{},
  /**
   * Portfolio custom fields, these are common for all the portcos
   */
  customFields: [],
  customFieldsLoading: false,
  customFieldsError: null,
  customFieldValues: {},
  customFieldValuesLoading: {},
  customFieldValuesError: null
}
