/**
 * @format
 */
import request from '../../helpers/request';
import { enqueueNotification } from '../app';
import * as types from './actions';
import reducers, { DEAL_ACCESS_REQUEST_MUTATION_STATUS } from './reducers';

export default reducers;
export const loadDealAccessRequests = (dealId, options) => async dispatch => {
  dispatch({
    type: types.LOAD_DEAL_ACCESS_REQUEST_LIST_REQUEST,
    payload: {
      dealId,
    },
  });
  try {
    const { data } = await request.get(
      `/api/v1/pipelines/${dealId}/requests`,
      {},
      dispatch
    );
    if (data && data.success) {
      dispatch({
        type: types.LOAD_DEAL_ACCESS_REQUEST_LIST_SUCCESS,
        payload: {
          dealId,
          data: data.data,
        },
      });
    } else {
      dispatch({
        type: types.LOAD_DEAL_ACCESS_REQUEST_LIST_FAILURE,
        payload: {
          message: data.message,
          dealId,
        },
      });
      dispatch(enqueueNotification(data.message));
    }
  } catch (error) {
    const message = `Something went wrong while loading access requests. Please try again`;
    dispatch({
      type: types.LOAD_DEAL_ACCESS_REQUEST_LIST_FAILURE,
      payload: {
        message,
        dealId,
      },
    });
    dispatch(enqueueNotification(message));
  }
};

export const approveDealAccessRequest = (
  requestId,
  dealId
) => async dispatch => {
  dispatch({
    type: types.APPROVE_DEAL_ACCESS_REQUEST_REQUEST,
    payload: { dealId },
  });
  try {
    const res = await request.post(
      `/api/v1/pipelines/${dealId}/requests/${requestId}/approve`,
      null,
      dispatch
    );
    const { data } = res;
    if (data && data.success) {
      dispatch({
        type: types.APPROVE_DEAL_ACCESS_REQUEST_SUCCESS,
        payload: {
          dealId,
          requestId,
        },
      });
    } else {
      dispatch({
        type: types.APPROVE_DEAL_ACCESS_REQUEST_FAILURE,
        payload: {
          message: data.message,
          dealId,
          requestId,
        },
      });
      dispatch(enqueueNotification(data.message));
    }
  } catch (error) {
    const message = `Something went wrong while approving access request. Please try again`;
    dispatch({
      type: types.APPROVE_DEAL_ACCESS_REQUEST_FAILURE,
      payload: {
        message,
        dealId,
        requestId,
      },
    });
    dispatch(enqueueNotification(message));
  }
};

export const rejectDealAccessRequest = (
  requestId,
  dealId
) => async dispatch => {
  dispatch({
    type: types.REJECT_DEAL_ACCESS_REQUEST_REQUEST,
    payload: {
      dealId,
      requestId,
    },
  });
  try {
    const res = await request.post(
      `/api/v1/pipelines/${dealId}/requests/${requestId}/reject`,
      null,
      dispatch
    );
    const { data } = res;
    if (data && data.success) {
      dispatch({
        type: types.REJECT_DEAL_ACCESS_REQUEST_SUCCESS,
        payload: {
          dealId,
          requestId,
        },
      });
    } else {
      dispatch({
        type: types.REJECT_DEAL_ACCESS_REQUEST_FAILURE,
        payload: {
          message: data.message,
          requestId,
          dealId,
        },
      });
      dispatch(enqueueNotification(data.message));
    }
  } catch (error) {
    const message = `Something went wrong while rejecting access request. Please try again`;
    dispatch({
      type: types.REJECT_DEAL_ACCESS_REQUEST_FAILURE,
      payload: {
        message,
        requestId,
        dealId,
      },
    });
    dispatch(enqueueNotification(message));
  }
};

export const requestDealAccess = dealId => async dispatch => {
  try {
    const { data } = await request.post(
      `/api/v1/pipelines/${dealId}/requests`,
      null,
      dispatch
    );
    if (data?.success) {
      dispatch(
        enqueueNotification('Request to access this resource has been sent.')
      );
    } else {
      dispatch(enqueueNotification(data.message));
    }
  } catch (error) {
    const message = 'Something went wrong while requesting access.';
    dispatch(enqueueNotification(message));
  }
};

export const loadSingleAcccessRequest = (
  requestId,
  dealId
) => async dispatch => {
  dispatch({
    type: types.LOAD_SINGLE_DEAL_ACCESS_REQUEST_REQUEST,
    payload: {
      dealId,
      requestId,
    },
  });
  try {
    const { data } = await request.get(
      `/api/v1/pipelines/${dealId}/requests/${requestId}`,
      null,
      dispatch
    );
    if (data.success) {
      dispatch({
        type: types.LOAD_SINGLE_DEAL_ACCESS_REQUEST_SUCCESS,
        payload: {
          data: data.data,
        },
      });
    } else {
      const message = data.message;
      dispatch({
        type: types.LOAD_SINGLE_DEAL_ACCESS_REQUEST_FAILURE,
        payload: {
          message,
          dealId,
          requestId,
        },
      });
      dispatch(enqueueNotification(data.message));
    }
  } catch (error) {
    const message = 'Something went wrong while requesting access.';
    dispatch({
      type: types.LOAD_SINGLE_DEAL_ACCESS_REQUEST_FAILURE,
      payload: {
        message,
        dealId,
        requestId,
      },
    });
    dispatch(enqueueNotification(message));
  }
};

export { DEAL_ACCESS_REQUEST_MUTATION_STATUS };
