/**@format */

import * as types from './actions';
import reducers from './reducers';
import request, { wrap, api } from '../../helpers/request';
import isEmptyString from '../../helpers/string/isEmptyString';
import { enqueueNotification } from '../app';
import getPersonContactTableColumnsWidth from '../../helpers/getPersonContactTableColumnsWidth';
import setPersonContactTableColumnsWidth from '../../helpers/setPersonContactTableColumnsWidth';
import getOrgContactTableColumnsWidth from '../../helpers/getOrgContactTableColumnsWidth';
import setOrgContactTableColumnsWidth from '../../helpers/setOrgContactTableColumnsWidth';
import executeInBackground from '../../helpers/executeInBackground';

// mark reducers as the default export
export default reducers;

// ORGANIZATION CONTACTS

/**
 *
 * @param {Object} queryParams
 * @returns
 */
export function loadOrganizationContactsList(queryParams) {
  return async function(dispatch) {
    dispatch({
      type: types.LOAD_ORGANIZATION_CONTACTS_LIST_REQUEST,
      payload: {
        options: queryParams,
      },
    });

    try {
      const { data } = await wrap(
        api.get(
          `/api/contact/v1/organizations`,
          { params: queryParams },
          dispatch
        )
      );

      dispatch({
        type: types.LOAD_ORGANIZATION_CONTACTS_LIST_SUCCESS,
        payload: {
          data: data.data,
          metadata: data.metaData,
          noOfOrgContacts: data.metaData.noOfOrgContacts,
          options: queryParams,
        },
      });

      return data;
    } catch (err) {
      dispatch({
        type: types.LOAD_ORGANIZATION_CONTACTS_LIST_FAILURE,
        payload: { message: err.message },
      });

      dispatch(
        enqueueNotification(
          err.message || 'Something went wrong when loading the Contacts'
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {UUID} orgContactId Organization Contact ID
 * @param {Object} options
 * @returns
 */
export function loadOrganizationContact(orgContactId, options) {
  // should pass withTags: true to get tags
  return async function(dispatch) {
    dispatch({
      type: types.LOAD_ORGANIZATION_CONTACT_REQUEST,
      payload: {
        orgContactId,
      },
    });

    try {
      const { data } = await wrap(
        api.get(
          `/api/contact/v1/organizations/${orgContactId}`,
          { params: options },
          dispatch
        )
      );

      dispatch({
        type: types.LOAD_ORGANIZATION_CONTACT_SUCCESS,
        payload: {
          orgContactId,
          data: data.data,
          // options: queryParams,
        },
      });

      return data;
    } catch (err) {
      dispatch({
        type: types.LOAD_ORGANIZATION_CONTACT_FAILURE,
        payload: { message: err.message },
      });

      dispatch(
        enqueueNotification(
          err.message || 'Something went wrong when loading the Contact'
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {Array} arrayofOrgContact
 * arrayOfOrgContact = [
    {
      name: 'Boat Prod 10',
      url: 'boadprod10.com',
      socialLinks: {
         linkedin: linkedin.com/boatprod10,
         crunchbase: crunchbase.com/boatprod10,
         github: github.com/boatprod10
       }
    },
    {
      name: 'Boat Prod 11',
      url: 'boadprod11.com',
      socialLinks: {
         linkedin: linkedin.com/boatprod11,
         crunchbase: crunchbase.com/boatprod11,
         github: github.com/boatprod11
       }
    }
  ]

 */
export function addOrganizationContact(arrayofOrgContact) {
  return async dispatch => {
    dispatch({
      type: types.ADD_ORGANIZATION_CONTACTS_REQUEST,
    });

    try {
      const { data } = await wrap(
        api.post(
          `/api/contact/v1/organizations`,
          { orgContactArray: arrayofOrgContact },
          dispatch
        )
      );

      if (!data.success) {
        dispatch({
          type: types.ADD_ORGANIZATION_CONTACTS_FAILURE,
          payload: { message: data.message },
        });
        dispatch(
          enqueueNotification(
            `Something went wrong when adding the contact:${data.message}`
          )
        );
        throw new Error(data.message);
      }

      dispatch({
        type: types.ADD_ORGANIZATION_CONTACTS_SUCCESS,
        payload: { data: data.data },
      });
      return data;
    } catch (err) {
      let message = err.isApplicationError
        ? err.message
        : 'Something went wrong while adding organization contact. Please try again.';
      if (err?.response?.status === 429) {
        message = `Too fast! Try again in a couple of seconds.`;
      }
      dispatch({
        type: types.ADD_ORGANIZATION_CONTACTS_FAILURE,
        payload: { message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(err.message);
    }
  };
}

/**
 *
 * @param {Object} form
 * @param {UUID} orgContactId
 * @returns
 */
export function updateOrganizationContact(form, orgContactId) {
  if (isEmptyString(orgContactId))
    throw new Error('Organization Contact Id is missing');
  return async dispatch => {
    dispatch({
      type: types.UPDATE_ORGANIZATION_CONTACT_REQUEST,
      payload: { orgContactId },
    });

    try {
      const { data } = await wrap(
        api.put(`/api/contact/v1/organizations/${orgContactId}`, form, dispatch)
      );

      if (!data.success) {
        dispatch({
          type: types.UPDATE_ORGANIZATION_CONTACT_FAILURE,
          payload: { message: data.message },
        });
      }

      dispatch({
        type: types.UPDATE_ORGANIZATION_CONTACT_SUCCESS,
        payload: { orgContactId, data: data.data },
      });
    } catch (err) {
      dispatch({
        type: types.UPDATE_ORGANIZATION_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            `Something went wrong while updating org contact: ${err.message}`
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {UUID} orgContactId
 */
export function deleteOrganizationContact(orgContactId) {
  if (isEmptyString(orgContactId))
    throw new Error('Organization Contact Id is missing');

  return async dispatch => {
    dispatch({
      type: types.DELETE_ORGANIZATION_CONTACT_REQUEST,
      payload: { orgContactId },
    });

    try {
      const { data } = await wrap(
        api.delete(
          `/api/contact/v1/organizations/${orgContactId}`,
          {},
          dispatch
        )
      );

      if (!data.success) {
        dispatch({
          type: types.DELETE_ORGANIZATION_CONTACT_FAILURE,
          payload: { message: data.message },
        });

        dispatch(
          enqueueNotification(
            `Something went wrong while deleting the Contact: ${data.message}`
          )
        );
      }

      dispatch({
        type: types.DELETE_ORGANIZATION_CONTACT_SUCCESS,
        payload: { orgContactId, data: data.data },
      });
    } catch (err) {
      dispatch({
        type: types.DELETE_ORGANIZATION_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            `Something went wrong when deleting organization contact: ${err.message}`
        )
      );
      throw err;
    }
  };
}

export function loadAllOrganizationContactsTags() {
  return async dispatch => {
    dispatch({
      type: types.LOAD_ORGANIZATION_CONTACTS_TAGS_REQUEST,
    });

    try {
      const { data } = await wrap(
        api.get(`/api/contact/v1/tags/organizations`)
      );

      if (!data.success) {
        dispatch({
          type: types.LOAD_ORGANIZATION_CONTACTS_TAGS_FAILURE,
          payload: { message: data.message },
        });
        throw new Error(data.message);
      }

      dispatch({
        type: types.LOAD_ORGANIZATION_CONTACTS_TAGS_SUCCESS,
        payload: {
          tags: data.data,
        },
      });
    } catch (err) {
      dispatch({
        type: types.LOAD_ORGANIZATION_CONTACTS_TAGS_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            `Something went wrong while loading organization contact tags: ${err.message}`
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {UUID} orgContactId
 * @param {*} tag
 * @returns
 */
export function addTagToOrgContact(orgContactId, tag) {
  if (isEmptyString(orgContactId))
    throw new Error('Organization Contact Id is missing');
  return async dispatch => {
    dispatch({
      type: types.ADD_TAG_TO_ORGANIZATION_CONTACT_REQUEST,
    });

    try {
      const { data } = await wrap(
        api.post(
          `/api/contact/v1/organizations/${orgContactId}/tag`,
          { tag },
          dispatch
        )
      );

      if (!data.success) {
        dispatch({
          type: types.ADD_TAG_TO_ORGANIZATION_CONTACT_FAILURE,
          payload: { message: data.message },
        });
        dispatch(
          enqueueNotification(
            `Something went wrong when adding tag to the organization contact: ${data.message}`
          )
        );
        throw new Error(data.message);
      }

      dispatch({
        type: types.ADD_TAG_TO_ORGANIZATION_CONTACT_SUCCESS,
        payload: { orgContactId, tag },
      });
    } catch (err) {
      dispatch({
        type: types.ADD_TAG_TO_ORGANIZATION_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            `Something went wrong when adding tag to the organization contact: ${err.message}`
        )
      );
      throw err;
    }
  };
}

export function removeTagFromOrgContact(orgContactId, tag) {
  if (isEmptyString(orgContactId))
    throw new Error('Organization Contact Id is missing');
  return async dispatch => {
    dispatch({
      type: types.REMOVE_TAG_FROM_ORGANIZATION_CONTACT_REQUEST,
    });

    try {
      const { data } = await wrap(
        api.post(
          `/api/contact/v1/organizations/${orgContactId}/untag`,
          { tag },
          dispatch
        )
      );

      if (!data.success) {
        dispatch({
          type: types.REMOVE_TAG_FROM_ORGANIZATION_CONTACT_FAILURE,
          payload: { message: data.message },
        });
        dispatch(
          enqueueNotification(
            `Something went wrong while removing tag of the organization contact: ${data.message}`
          )
        );
        throw new Error(data.message);
      }

      dispatch({
        type: types.REMOVE_TAG_FROM_ORGANIZATION_CONTACT_SUCCESS,
        payload: { orgContactId, tag },
      });
    } catch (err) {
      dispatch({
        type: types.REMOVE_TAG_FROM_ORGANIZATION_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            `Something went wrong while removing tag of the organization contact: ${err.message}`
        )
      );
      throw err;
    }
  };
}

// PEOPLE CONTACTS

/**
 *
 * @param {*} queryParams
 * @returns
 */
export function loadPeopleContactsList(queryParams) {
  return async function(dispatch) {
    dispatch({
      type: types.LOAD_PEOPLE_CONTACTS_LIST_REQUEST,
      payload: {
        options: queryParams,
      },
    });

    try {
      const { data } = await wrap(
        api.get(`/api/contact/v1/people`, { params: queryParams }, dispatch)
      );

      dispatch({
        type: types.LOAD_PEOPLE_CONTACTS_LIST_SUCCESS,
        payload: {
          data: data.data,
          metadata: data.metaData,
          noOfPeopleContacts: data.metaData.noOfPeopleContacts,
          options: queryParams,
        },
      });

      return data;
    } catch (err) {
      dispatch({
        type: types.LOAD_PEOPLE_CONTACTS_LIST_FAILURE,
        payload: { message: err.message },
      });

      dispatch(
        enqueueNotification(
          err.message || 'Something went wrong when loading the Contacts'
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {UUID} personContactId
 * @param {*} options
 * @returns
 */
export function loadPersonContact(personContactId, options) {
  // should pass withTags: true to get tags
  return async function(dispatch) {
    dispatch({
      type: types.LOAD_PERSON_CONTACT_REQUEST,
      payload: {
        personContactId,
      },
    });

    try {
      const { data } = await wrap(
        api.get(
          `/api/contact/v1/people/${personContactId}`,
          { params: options },
          dispatch
        )
      );

      dispatch({
        type: types.LOAD_PERSON_CONTACT_SUCCESS,
        payload: {
          personContactId,
          data: data.data,
          // options: queryParams,
        },
      });

      return data;
    } catch (err) {
      dispatch({
        type: types.LOAD_PERSON_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message || 'Something went wrong when loading the Contact'
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {ArrayOfPeopleContacts} arrayofPeopleContact
 * arrayOfPeopleContact = {
  peopleContactArray: [
    {
      first_name: Aman,
      last_name: 04,
      email: aman04@gmail.com,
      socialLinks: {
        linkedin: linkedin.com/aman04,
        crunchbase: crunchbase.com/aman04,
        github: github.com/aman04,
        twitter: twitter.com/aman04
      }
    },
    {
      first_name: Aman,
      last_name: 05,
      email: aman05@gmail.com,
      socialLinks: {
        linkedin: linkedin.com/aman05,
        crunchbase: crunchbase.com/aman05,
        github: github.com/aman05,
        twitter: twitter.com/aman05
      }
    }
  ]
}
 */
export function addPeopleContact(arrayofPeopleContact) {
  return async dispatch => {
    dispatch({
      type: types.ADD_PEOPLE_CONTACTS_REQUEST,
    });

    try {
      const { data } = await wrap(
        api.post(
          `/api/contact/v1/people`,
          { peopleContactArray: arrayofPeopleContact },
          dispatch
        )
      );

      if (!data.success) {
        dispatch({
          type: types.ADD_PEOPLE_CONTACTS_FAILURE,
          payload: { message: data.message },
        });
        dispatch(
          enqueueNotification(
            `Something went wrong when adding the contact:${data.message}`
          )
        );
        throw new Error(data.message);
      }

      dispatch({
        type: types.ADD_PEOPLE_CONTACTS_SUCCESS,
        payload: { data: data.data },
      });
      return data;
    } catch (err) {
      // have to handle the error when contacts with similar email
      let message = err.isApplicationError
        ? err.message
        : 'Something went wrong while adding Contacts. Please try again.';
      if (err?.response?.status === 429) {
        message = `Too fast! Try again in a couple of seconds.`;
      }
      dispatch({
        type: types.ADD_PEOPLE_CONTACTS_FAILURE,
        payload: { message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(err.message);
    }
  };
}

/**
 *
 * @param {Object} form
 * @param {UUID} personContactId
 * @returns
 */
export function updatePersonContact(form, personContactId) {
  if (isEmptyString(personContactId))
    throw new Error('Person Contact Id is missing');
  return async dispatch => {
    dispatch({
      type: types.UPDATE_PERSON_CONTACT_REQUEST,
      payload: { personContactId },
    });

    try {
      const { data } = await wrap(
        api.put(`/api/contact/v1/people/${personContactId}`, form, dispatch)
      );

      if (!data.success) {
        dispatch({
          type: types.UPDATE_PERSON_CONTACT_FAILURE,
          payload: { message: data.message },
        });
        dispatch(
          enqueueNotification(
            `Something went wrong when updating organization contact: ${data.message}`
          )
        );
        throw new Error(data.message);
      }

      dispatch({
        type: types.UPDATE_PERSON_CONTACT_SUCCESS,
        payload: { personContactId, data: data.data },
      });
    } catch (err) {
      dispatch({
        type: types.UPDATE_PERSON_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            `Something went wrong while updating person contact: ${err.message}`
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {UUID} personContactId
 */
export function deletePersonContact(personContactId) {
  if (isEmptyString(personContactId))
    throw new Error('Person organization Contact Id is missing');

  return async dispatch => {
    dispatch({
      type: types.DELETE_PERSON_CONTACT_REQUEST,
      payload: { personContactId },
    });
    try {
      const { data } = await wrap(
        api.delete(`/api/contact/v1/people/${personContactId}`, {}, dispatch)
      );

      if (!data.success) {
        dispatch({
          type: types.DELETE_PERSON_CONTACT_FAILURE,
          payload: { message: data.message },
        });

        dispatch(
          enqueueNotification(
            `Something went wrong while deleting the Contact: ${data.message}`
          )
        );
      }

      dispatch({
        type: types.DELETE_PERSON_CONTACT_SUCCESS,
        payload: { personContactId, data: data.data },
      });
    } catch (err) {
      dispatch({
        type: types.DELETE_PERSON_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            `Something went wrong when deleting person contact: ${err.message}`
        )
      );
      throw err;
    }
  };
}

export function loadAllPeopleContactsTags() {
  return async dispatch => {
    dispatch({
      type: types.LOAD_PEOPLE_CONTACTS_TAGS_REQUEST,
    });

    const { data } = await wrap(api.get(`/api/contact/v1/tags/people`));

    if (!data.success) {
      dispatch({
        type: types.LOAD_PEOPLE_CONTACTS_TAGS_FAILURE,
        payload: { message: data.message },
      });
      throw new Error(data.message);
    }

    dispatch({
      type: types.LOAD_PEOPLE_CONTACTS_TAGS_SUCCESS,
      payload: {
        tags: data.data,
      },
    });
  };
}

/**
 *
 * @param {UUID} personContactId
 * @param {*} tag
 * @returns
 */
export function addTagToPersonContact(personContactId, tag) {
  if (isEmptyString(personContactId))
    throw new Error('Person Contact Id is missing');
  return async dispatch => {
    dispatch({
      type: types.ADD_TAG_TO_PERSON_CONTACT_REQUEST,
    });

    try {
      const { data } = await wrap(
        api.post(
          `/api/contact/v1/people/${personContactId}/tag`,
          { tag },
          dispatch
        )
      );

      if (!data.success) {
        dispatch({
          type: types.ADD_TAG_TO_PERSON_CONTACT_FAILURE,
          payload: { message: data.message },
        });
        dispatch(
          enqueueNotification(
            `Something went wrong when adding tag to the person contact: ${data.message}`
          )
        );
        throw new Error(data.message);
      }

      dispatch({
        type: types.ADD_TAG_TO_PERSON_CONTACT_SUCCESS,
        payload: { personContactId, tag },
      });
    } catch (err) {
      dispatch({
        type: types.ADD_TAG_TO_PERSON_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            `Something went wrong when adding tag to the person contact: ${err.message}`
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {UUID} personContactId
 * @param {*} tag
 * @returns
 */
export function removeTagFromPersonContact(personContactId, tag) {
  if (isEmptyString(personContactId))
    throw new Error('Person Contact Id is missing');
  return async dispatch => {
    dispatch({
      type: types.REMOVE_TAG_FROM_PERSON_CONTACT_REQUEST,
    });

    try {
      const { data } = await wrap(
        api.post(
          `/api/contact/v1/people/${personContactId}/untag`,
          { tag },
          dispatch
        )
      );

      if (!data.success) {
        dispatch({
          type: types.REMOVE_TAG_FROM_PERSON_CONTACT_FAILURE,
          payload: { message: data.message },
        });
        dispatch(
          enqueueNotification(
            `Something went wrong while removing tag of a person contact: ${data.message}`
          )
        );
        throw new Error(data.message);
      }
      dispatch({
        type: types.REMOVE_TAG_FROM_PERSON_CONTACT_SUCCESS,
        payload: { personContactId, tag },
      });
    } catch (err) {
      dispatch({
        type: types.REMOVE_TAG_FROM_PERSON_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            `Something went wrong while removing tag of a person contact: ${err.message}`
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {UUID} personContactId
 * @param {*} options
 * @returns
 */
export function loadTeamMembersAssociatedWithPersonConact(
  personContactId,
  options
) {
  // should pass withTags: true to get tags
  return async function(dispatch) {
    dispatch({
      type: types.LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT_REQUEST,
      payload: {
        personContactId,
      },
    });

    try {
      const { data } = await wrap(
        api.get(
          `/api/contact/v1/people/${personContactId}/team_members`,
          { params: options },
          dispatch
        )
      );

      dispatch({
        type: types.LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT_SUCCESS,
        payload: {
          personContactId,
          data: data.data,
          metaData: data.metaData,
          options,
        },
      });

      return data;
    } catch (err) {
      dispatch({
        type: types.LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            'Something went wrong when loading the associated team members'
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {UUID} personContactId
 * @param {*} options
 * @returns
 */
export function loadDealsAndPortfoliosAssociatedWithPersonConact(
  personContactId,
  options
) {
  return async function(dispatch) {
    dispatch({
      type:
        types.LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT_REQUEST,
      payload: {
        personContactId,
      },
    });

    try {
      const { data } = await wrap(
        api.get(
          `/api/contact/v1/people/${personContactId}/associated_deals_and_portfolios`,
          { params: options },
          dispatch
        )
      );

      dispatch({
        type:
          types.LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT_SUCCESS,
        payload: {
          personContactId,
          data: data.data,
          metaData: {
            deals: data.data.noOfDeals,
            portcos: data.data.noOfPortcos,
          },
          options,
        },
      });

      return data;
    } catch (err) {
      dispatch({
        type:
          types.LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            'Something went wrong when loading the associated deals and portfolio of person contact'
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {UUID} orgContactId
 * @param {*} options
 * @returns
 */
export function loadDealsAndPortfoliosAssociatedWithOrgConact(
  orgContactId,
  options
) {
  return async function(dispatch) {
    dispatch({
      type: types.LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT_REQUEST,
      payload: {
        orgContactId,
      },
    });

    try {
      const { data } = await wrap(
        api.get(
          `/api/contact/v1/organizations/${orgContactId}/associated_deals_and_portfolios`,
          { params: options },
          dispatch
        )
      );

      dispatch({
        type:
          types.LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT_SUCCESS,
        payload: {
          orgContactId,
          data: data.data,
          metaData: {
            deals: data.data.noOfDeals,
            portcos: data.data.noOfPortcos,
          },
          options,
        },
      });

      return data;
    } catch (err) {
      dispatch({
        type:
          types.LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            'Something went wrong when loading the associated deals and portfolio of org contact'
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {UUID} personContactId
 * @param {*} options
 * @returns
 */
export function loadPersonContactInteractions(personContactId, options) {
  return async function(dispatch) {
    dispatch({
      type: types.LOAD_PERSON_CONTACT_INTERACTIONS_REQUEST,
      payload: {
        personContactId,
      },
    });

    try {
      const { data } = await wrap(
        api.get(
          `/api/contact/v1/people/${personContactId}/interactions`,
          { params: options },
          dispatch
        )
      );

      dispatch({
        type: types.LOAD_PERSON_CONTACT_INTERACTIONS_SUCCESS,
        payload: {
          personContactId,
          data: data.data,
          metaData: data.metaData,
          options,
        },
      });

      return data;
    } catch (err) {
      dispatch({
        type: types.LOAD_PERSON_CONTACT_INTERACTIONS_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            'Something went wrong when loading the Contact Interactions'
        )
      );
      throw err;
    }
  };
}

/**
 *
 * @param {UUID} personContactId
 * @param {*} options
 * @returns
 */
export function loadMembersOfOrgContact(orgContactId, options) {
  return async function(dispatch) {
    dispatch({
      type: types.LOAD_MEMBERS_OF_ORG_CONTACT_REQUEST,
      payload: {
        orgContactId,
      },
    });

    try {
      const { data } = await wrap(
        api.get(
          `/api/contact/v1/organizations/${orgContactId}/members`,
          { params: options },
          dispatch
        )
      );

      dispatch({
        type: types.LOAD_MEMBERS_OF_ORG_CONTACT_SUCCESS,
        payload: {
          orgContactId,
          data: data.data,
          // options: queryParams,
        },
      });

      return data;
    } catch (err) {
      dispatch({
        type: types.LOAD_MEMBERS_OF_ORG_CONTACT_FAILURE,
        payload: { message: err.message },
      });
      dispatch(
        enqueueNotification(
          err.message ||
            'Something went wrong when loading the Members of Organization'
        )
      );
      throw err;
    }
  };
}

// Load custom fields for person contact
export const loadCustomFields = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.LOAD_CUSTOM_FIELDS_REQUEST,
    });
    request
      .get(`/api/contact/v1/person-contact/custom-fields/fields`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_CUSTOM_FIELDS_SUCCESS,
            payload: data.data,
          });
          resolve(data);
        } else {
          dispatch({
            type: types.LOAD_CUSTOM_FIELDS_FAILURE,
            payload: {
              message: data.message,
            },
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while loading custom fields, please try again.';
        dispatch({
          type: types.LOAD_CUSTOM_FIELDS_FAILURE,
          payload: { message },
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
  });
};

// Load Custom field values
export const loadCustomFieldValues = personContactId => async dispatch => {
  dispatch({
    type: types.LOAD_CUSTOM_FIELD_VALUES_REQUEST,
    payload: { personContactId },
  });

  let data;
  try {
    const response = await request.get(
      `/api/contact/v1/person-contact/custom-fields/values`,
      { id: personContactId }
    );
    data = response.data;
    if (data.success) {
      dispatch({
        type: types.LOAD_CUSTOM_FIELD_VALUES_SUCCESS,
        payload: { data: data.data },
      });
      return data;
    } else {
      dispatch({
        type: types.LOAD_CUSTOM_FIELD_VALUES_FAILURE,
        payload: { personContactId, message: data.message },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  } catch (err) {
    const message =
      'Something went wrong while loading custom field values, please try again.';
    dispatch({
      type: types.LOAD_CUSTOM_FIELD_VALUES_FAILURE,
      payload: { personContactId, message: data.message },
    });
    dispatch(enqueueNotification(message));
    throw err;
  }
};

// Update Custom Field Values
export const updateCustomFieldValues = (form, personContactId) => {
  let loadingMap = {};
  loadingMap[personContactId] = true;
  return async dispatch => {
    dispatch({
      type: types.UPDATE_CUSTOM_FIELD_VALUE_REQUEST,
      payload: loadingMap,
    });
    let data;
    try {
      const response = await request.put(
        `/api/contact/v1/person-contact/${personContactId}/custom-fields/values`,
        form
      );
      data = response.data;
      if (data.success) {
        let dataMap = {};
        dataMap[personContactId] = data.data[0];
        loadingMap[personContactId] = false;
        dispatch({
          type: types.UPDATE_CUSTOM_FIELD_VALUE_SUCCESS,
          payload: { loadingMap: loadingMap, dataMap: dataMap },
        });
        return data;
      } else {
        loadingMap[personContactId] = false;
        dispatch({
          type: types.UPDATE_CUSTOM_FIELD_VALUE_FAILURE,
          payload: { message: data.message, loadingMap: loadingMap },
        });
        dispatch(enqueueNotification(data.message));
        throw new Error(data.message);
      }
    } catch (err) {
      loadingMap[personContactId] = false;
      const message =
        'Something went wrong while updating custom field values, please try again.';
      dispatch({
        type: types.UPDATE_CUSTOM_FIELD_VALUE_FAILURE,
        payload: { message: message, loadingMap: loadingMap },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

// Update Custom fields
export const updateCustomFields = form => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_CUSTOM_FIELDS_REQUEST,
        paylod: form,
      });
      request
        .put(
          `/api/contact/v1/person-contact/custom-fields/fields`,
          form,
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_CUSTOM_FIELDS_SUCCESS,
              payload: data.data,
            });
            dispatch(
              enqueueNotification(
                'Custom fields updated for the organization successfully!'
              )
            );
            resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_CUSTOM_FIELDS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating custom fields, please try again.';
          dispatch({
            type: types.UPDATE_CUSTOM_FIELDS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

// Delete custom fields
export const removeCustomFields = customFieldNames => {
  return async dispatch => {
    dispatch({
      type: types.REMOVE_CUSTOM_FIELDS_REQUEST,
    });
    let data;
    try {
      let deletedNames = customFieldNames
        .map(cfName => `name=${cfName}`)
        .join('&');
      let path = `/api/contact/v1/person-contact/custom-fields/fields?${deletedNames}`;

      const response = await request.del(path);
      data = response.data;
      if (data.success) {
        dispatch({ type: types.REMOVE_CUSTOM_FIELDS_SUCCESS });
        dispatch(enqueueNotification('removed custom field successfully!'));

        return data;
      } else {
        dispatch({
          type: types.REMOVE_CUSTOM_FIELDS_FAILURE,
          payload: { message: data.message },
        });
        dispatch(enqueueNotification(data.message));
        throw new Error(data.message);
      }
    } catch (err) {
      const message =
        'Something went wrong while deleting custom fields, please try again.';
      dispatch({
        type: types.REMOVE_CUSTOM_FIELDS_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

// Add custom fields
export const addCustomField = form => {
  return async dispatch => {
    dispatch({
      type: types.ADD_CUSTOM_FIELD_REQUEST,
    });

    let data;
    try {
      const response = await request.post(
        `/api/contact/v1/person-contact/custom-fields/fields`,
        form
      );
      data = response.data;
      if (data.success) {
        dispatch({
          type: types.ADD_CUSTOM_FIELD_SUCCESS,
          payload: data.data,
        });
        return data;
      } else {
        dispatch({
          type: types.ADD_CUSTOM_FIELD_FAILURE,
          payload: data.message,
        });
        dispatch(enqueueNotification(data.message));
        throw new Error(data.message);
      }
    } catch (err) {
      const message =
        'Something went wrong while adding custom fields, please try again.';
      dispatch({
        type: types.ADD_CUSTOM_FIELD_FAILURE,
        payload: message,
      });
      dispatch(enqueueNotification(err.message || message));
      throw err;
    }
  };
};

// Load custom fields for organization contact
export const loadOrgContactCustomFields = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.LOAD_ORG_CONTACT_CUSTOM_FIELDS_REQUEST,
    });
    request
      .get(`/api/contact/v1/org-contact/custom-fields/fields`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_ORG_CONTACT_CUSTOM_FIELDS_SUCCESS,
            payload: data.data,
          });
          resolve(data);
        } else {
          dispatch({
            type: types.LOAD_ORG_CONTACT_CUSTOM_FIELDS_FAILURE,
            payload: {
              message: data.message,
            },
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while loading custom fields, please try again.';
        dispatch({
          type: types.LOAD_ORG_CONTACT_CUSTOM_FIELDS_FAILURE,
          payload: { message },
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
  });
};

// Load Custom field values
export const loadOrgContactCustomFieldValues = orgContactId => async dispatch => {
  dispatch({
    type: types.LOAD_ORG_CONTACT_CUSTOM_FIELD_VALUES_REQUEST,
    payload: { orgContactId },
  });

  let data;
  try {
    const response = await request.get(
      `/api/contact/v1/org-contact/custom-fields/values`,
      { id: orgContactId }
    );
    data = response.data;
    if (data.success) {
      dispatch({
        type: types.LOAD_ORG_CONTACT_CUSTOM_FIELD_VALUES_SUCCESS,
        payload: { data: data.data },
      });
      return data;
    } else {
      dispatch({
        type: types.LOAD_ORG_CONTACT_CUSTOM_FIELD_VALUES_FAILURE,
        payload: { orgContactId, message: data.message },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  } catch (err) {
    const message =
      'Something went wrong while loading custom field values, please try again.';
    dispatch({
      type: types.LOAD_ORG_CONTACT_CUSTOM_FIELD_VALUES_FAILURE,
      payload: { orgContactId, message: data.message },
    });
    dispatch(enqueueNotification(message));
    throw err;
  }
};

// Update Custom Field Values
export const updateOrgContactCustomFieldValues = (form, orgContactId) => {
  let loadingMap = {};
  loadingMap[orgContactId] = true;
  return async dispatch => {
    dispatch({
      type: types.UPDATE_ORG_CONTACT_CUSTOM_FIELD_VALUE_REQUEST,
      payload: loadingMap,
    });
    let data;
    try {
      const response = await request.put(
        `/api/contact/v1/org-contact/${orgContactId}/custom-fields/values`,
        form
      );
      data = response.data;
      if (data.success) {
        let dataMap = {};
        dataMap[orgContactId] = data.data[0];
        loadingMap[orgContactId] = false;
        dispatch({
          type: types.UPDATE_ORG_CONTACT_CUSTOM_FIELD_VALUE_SUCCESS,
          payload: { loadingMap: loadingMap, dataMap: dataMap },
        });
        // dispatch(
        //   enqueueNotification('custom fields value updated successfully!')
        // );
        return data;
      } else {
        loadingMap[orgContactId] = false;
        dispatch({
          type: types.UPDATE_ORG_CONTACT_CUSTOM_FIELD_VALUE_FAILURE,
          payload: { message: data.message, loadingMap: loadingMap },
        });
        dispatch(enqueueNotification(data.message));
        throw new Error(data.message);
      }
    } catch (err) {
      loadingMap[orgContactId] = false;
      const message =
        'Something went wrong while updating custom field values, please try again.';
      dispatch({
        type: types.UPDATE_ORG_CONTACT_CUSTOM_FIELD_VALUE_FAILURE,
        payload: { message: message, loadingMap: loadingMap },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

// Update Custom fields
export const updateOrgContactCustomFields = form => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_ORG_CONTACT_CUSTOM_FIELDS_REQUEST,
        paylod: form,
      });
      request
        .put(`/api/contact/v1/org-contact/custom-fields/fields`, form, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_ORG_CONTACT_CUSTOM_FIELDS_SUCCESS,
              payload: data.data,
            });
            dispatch(
              enqueueNotification(
                'Custom fields updated for the organization successfully!'
              )
            );
            resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_ORG_CONTACT_CUSTOM_FIELDS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating custom fields, please try again.';
          dispatch({
            type: types.UPDATE_ORG_CONTACT_CUSTOM_FIELDS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

// Delete custom fields
export const orgContactRemoveCustomFields = customFieldNames => {
  return async dispatch => {
    dispatch({
      type: types.REMOVE_ORG_CONTACT_CUSTOM_FIELDS_REQUEST,
    });
    let data;
    try {
      let deletedNames = customFieldNames
        .map(cfName => `name=${cfName}`)
        .join('&');
      let path = `/api/contact/v1/org-contact/custom-fields/fields?${deletedNames}`;

      const response = await request.del(path);
      data = response.data;
      if (data.success) {
        dispatch({ type: types.REMOVE_ORG_CONTACT_CUSTOM_FIELDS_SUCCESS });
        dispatch(enqueueNotification('removed custom field successfully!'));

        return data;
      } else {
        dispatch({
          type: types.REMOVE_ORG_CONTACT_CUSTOM_FIELDS_FAILURE,
          payload: { message: data.message },
        });
        dispatch(enqueueNotification(data.message));
        throw new Error(data.message);
      }
    } catch (err) {
      const message =
        'Something went wrong while deleting custom fields, please try again.';
      dispatch({
        type: types.REMOVE_ORG_CONTACT_CUSTOM_FIELDS_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

// Add custom fields
export const orgContactAddCustomField = form => {
  return async dispatch => {
    dispatch({
      type: types.ADD_ORG_CONTACT_CUSTOM_FIELD_REQUEST,
    });

    let data;
    try {
      const response = await request.post(
        `/api/contact/v1/org-contact/custom-fields/fields`,
        form
      );
      data = response.data;
      if (data.success) {
        dispatch({
          type: types.ADD_ORG_CONTACT_CUSTOM_FIELD_SUCCESS,
          payload: data.data,
        });
        return data;
      } else {
        dispatch({
          type: types.ADD_ORG_CONTACT_CUSTOM_FIELD_FAILURE,
          payload: data.message,
        });
        dispatch(enqueueNotification(data.message));
        throw new Error(data.message);
      }
    } catch (err) {
      const message =
        'Something went wrong while adding custom fields, please try again.';
      dispatch({
        type: types.ADD_ORG_CONTACT_CUSTOM_FIELD_FAILURE,
        payload: message,
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

/*
 * Person Contact Table Columns
 */

// Load person contact table columns
export const loadPersonContactTableColumns = (
  shouldForceAPIRequest = false,
  ignoreCache = false
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!shouldForceAPIRequest) {
        // If column information is present in local storage, fetch it from there
        // `shouldForceAPIRequest` allows for overriding this behaviour
        const columnData = getPersonContactTableColumnsWidth();
        if (columnData && columnData.length > 0) {
          dispatch({
            type: types.LOAD_PERSON_CONTACT_TABLE_COLUMNS_REQUEST,
            payload: columnData,
          });
          // also contact server to get up to date data, which gets updated in the background
          executeInBackground(() =>
            loadPersonContactTableColumns(true)(dispatch).catch(() => {})
          );
          return resolve(columnData);
        }
      }
      dispatch({ type: types.LOAD_PERSON_CONTACT_TABLE_COLUMNS_REQUEST });
      request
        .get(`/api/contact/v1/person_contact_table_columns`)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_PERSON_CONTACT_TABLE_COLUMNS_SUCCESS,
              payload: data.data,
            });

            setPersonContactTableColumnsWidth(data.data);
            resolve(data);
          } else {
            dispatch({
              type: types.LOAD_PERSON_CONTACT_TABLE_COLUMNS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            // reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading table columns, please try again.';
          dispatch({
            type: types.LOAD_PERSON_CONTACT_TABLE_COLUMNS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

// Update person contact table columns
export const updatePersonContactTableColumns = orderedColumns => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_PERSON_CONTACT_TABLE_COLUMNS_REQUEST,
        payload: orderedColumns,
      });
      request
        .put(
          `/api/contact/v1/person_contact_table_columns`,
          {
            columns: orderedColumns,
          },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_PERSON_CONTACT_TABLE_COLUMNS_SUCCESS,
              payload: orderedColumns,
            });
            setPersonContactTableColumnsWidth(orderedColumns);
            resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_PERSON_CONTACT_TABLE_COLUMNS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            // reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating table columns, please try again.';
          dispatch({
            type: types.UPDATE_PERSON_CONTACT_TABLE_COLUMNS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const openPersonContactManageColumn = () => {
  return dispatch => {
    dispatch({
      type: types.PERSON_CONTACT_OPEN_MANAGE_COLUMN,
    });
  };
};

export const closePersonContactManageColumn = () => {
  return dispatch => {
    dispatch({
      type: types.PERSON_CONTACT_CLOSE_MANAGE_COLUMN,
    });
  };
};

/*
 * Org Contact Table Columns
 */

// Load Org contact table columns
export const loadOrgContactTableColumns = (
  shouldForceAPIRequest = false,
  ignoreCache = false
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!shouldForceAPIRequest) {
        // If column information is present in local storage, fetch it from there
        // `shouldForceAPIRequest` allows for overriding this behaviour
        const columnData = getOrgContactTableColumnsWidth();
        if (columnData && columnData.length > 0) {
          dispatch({
            type: types.LOAD_ORG_CONTACT_TABLE_COLUMNS_REQUEST,
            payload: columnData,
          });
          // also contact server to get up to date data, which gets updated in the background
          executeInBackground(() =>
            loadOrgContactTableColumns(true)(dispatch).catch(() => {})
          );
          return resolve(columnData);
        }
      }
      dispatch({ type: types.LOAD_ORG_CONTACT_TABLE_COLUMNS_REQUEST });
      request
        .get(`/api/contact/v1/org_contact_table_columns`)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_ORG_CONTACT_TABLE_COLUMNS_SUCCESS,
              payload: data.data,
            });

            setOrgContactTableColumnsWidth(data.data);
            resolve(data);
          } else {
            dispatch({
              type: types.LOAD_ORG_CONTACT_TABLE_COLUMNS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            // reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading table columns, please try again.';
          dispatch({
            type: types.LOAD_ORG_CONTACT_TABLE_COLUMNS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

// Update person contact table columns
export const updateOrgContactTableColumns = orderedColumns => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_ORG_CONTACT_TABLE_COLUMNS_REQUEST,
        payload: orderedColumns,
      });
      request
        .put(
          `/api/contact/v1/org_contact_table_columns`,
          {
            columns: orderedColumns,
          },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_ORG_CONTACT_TABLE_COLUMNS_SUCCESS,
              payload: orderedColumns,
            });
            setOrgContactTableColumnsWidth(orderedColumns);
            resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_ORG_CONTACT_TABLE_COLUMNS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            // reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating table columns, please try again.';
          dispatch({
            type: types.UPDATE_ORG_CONTACT_TABLE_COLUMNS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const openOrgContactManageColumn = () => {
  return dispatch => {
    dispatch({
      type: types.ORG_CONTACT_OPEN_MANAGE_COLUMN,
    });
  };
};

export const closeOrgContactManageColumn = () => {
  return dispatch => {
    dispatch({
      type: types.ORG_CONTACT_CLOSE_MANAGE_COLUMN,
    });
  };
};
