/**
 * Track component, allows you to track application usages insights
 * 
 * @author Ritesh Shrivastav
 */
import ReactGA from 'react-ga';

class Track {
  static _initialised = false;

  /**
   * Initialise the tracker
   */
  static init = (id, options={}) => {
    if (typeof id !== 'string') {
      return;
    }
    ReactGA.initialize(id, options);
    Track._initialised = true;
  };

  /**
   * Checks if tracker is initialised
   */
  static isInitialised = () => {
    return !!Track._initialised;
  };

  /**
   * Set something, which gets shared with all the tracking like user reference
   * and so on.
   */
  static set = (fieldsObject = {}) => {
    if (!Track.isInitialised()) return;
    ReactGA.set(fieldsObject);
  };

  /**
   * Records page view hits
   */
  static pageview = (pageURL, title, trackerNames) => {
    if (!Track.isInitialised() || typeof pageURL !== 'string' || !pageURL.trim()) {
      return;
    }
    ReactGA.pageview(pageURL, trackerNames, title);
  };

  /**
   * Records modal view hits, modals are same as page but they do not depend
   * on the URL you are in. Same modal can be shown from any page you are in.
   */
  static modalview = (modalName) => {
    if (!Track.isInitialised() || typeof modalName !== 'string' || !modalName.trim()) {
      return;
    }
    ReactGA.modalview(modalName);
  };

  /**
   * Track events
   */
  static event = (args) => {
    if (!Track.isInitialised()) return;
    ReactGA.event(args);
  };

  /**
   * Track timings
   */
  static timing = (args) => {
    if (!Track.isInitialised()) return;
    ReactGA.timing(args);
  };

  /**
   * Track errors
   */
  static exception = (description, isFatal = false) => {
    if (!Track.isInitialised() || typeof description !== 'string' || !description.trim()) {
      return;
    }
    ReactGA.exception({
      description,
      fatal: !!isFatal
    });
  };

  /**
   * Tracks any type of error
   */
  static error = (err) => {
    if (err instanceof Error) {
      Track.exception(
        err.message,
        false
      );
    }
  };
}

export default Track;
