import {
  ADD_DEAL_NOTE_REQUEST,
  ADD_DEAL_NOTE_SUCCESS,
  ADD_DEAL_NOTE_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case ADD_DEAL_NOTE_REQUEST:
      return Object.assign({}, state, {
        addNoteLoading: true,
        addNoteError: null
      });

    case ADD_DEAL_NOTE_SUCCESS:
      return Object.assign({}, state, {
        dealNotes: {
          ...state.dealNotes,
          /**
           * paylod.data - object 
           */
          [payload.dealId]: [payload.data, 
            ...(state.dealNotes[payload.dealId] ? state.dealNotes[payload.dealId] : [])
          ] 
        },
        noteReplyList: {
          ...state.noteReplyList,
          [payload.data.note.thread_id]: [payload.data]
        },
        noteReplyCount: {
          ...state.noteReplyCount,
          [payload.data.note.thread_id]: 1,
        },
        noteReplyListOptions: {
          ...state.noteReplyCount,
          [payload.data.note.thread_id]: {page:1, limit: 10}
        },
        addNoteLoading: false,
        addNoteError: null,
        dealsResourceCount: {
          ...state.dealsResourceCount,
          [payload.dealId]: {
            ...state.dealsResourceCount[payload.dealId],
            notesCount: state.dealsResourceCount[payload.dealId] && state.dealsResourceCount[payload.dealId].notesCount ? (
              !(payload.data.note.parent_id) ?
              state.dealsResourceCount[payload.dealId].notesCount + 1 
              : state.dealsResourceCount[payload.dealId].notesCount
            ) : 1
          },

        }
      });

    case ADD_DEAL_NOTE_FAILURE:
      return Object.assign({}, state, {
        addNoteLoading: false,
        addNoteError: payload,
      });

    default:
      return state;
  }
};
