/**
 * Use this to asynchronously load components
 *
 * @author  Ritesh Shrivastav
 * @author Suraj G
 */
import React, { Component } from 'react';

export default function asyncComponent(importComponent, Loader) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };
      this.child = React.createRef();
    }

    async componentDidMount() {
      const { default: component } = await importComponent();
      this.child && this.setState({
        component: component
      });
    }

    render() {
      const C = this.state.component;
      return (
        <div ref={this.child}>
          { C ? (<C {...this.props} />) : (Loader && <Loader />) }
        </div>
      );
    }
  }
  return AsyncComponent;
}
