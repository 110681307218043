import { 
  LOAD_PINNED_DEAL_NOTE_REQUEST,
  LOAD_PINNED_DEAL_NOTE_SUCCESS,
  LOAD_PINNED_DEAL_NOTE_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_PINNED_DEAL_NOTE_REQUEST:
      return Object.assign({}, state, {
        pinnedNoteLoading: true,
        pinnedNoteError: null
      });

    case LOAD_PINNED_DEAL_NOTE_SUCCESS:
      return Object.assign({}, state, {
        pinnedNote: payload.data,
        pinnedNoteLoading: false,
        pinnedNoteError: null
      });
      
    case LOAD_PINNED_DEAL_NOTE_FAILURE:
      return Object.assign({}, state, {
        pinnedNoteLoading: false,
        pinnedNoteError: payload.message,
      });

    default:
      return state;
  }
};
