/** Initial state for orgContact resources */
export default {
  /**
   * State that holds all the loaded orgContacts
   * This object is namespaced by a org's id, and the value
   * will be the org's data
   */
  organizationContacts: {},
  // Stores the loading, err state for `getOrgContact` calls. Namespaced by orgContactId
  getOrgContactLoading: {},
  getOrgContactError: {},
  // update org contact.  Namespaced by orgContactId
  updateOrgContactLoading: {},
  updateOrgContactError: {},
};
