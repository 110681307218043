/**
 * @format
 */

import { SET_PORTCO_FILTERS } from '../actions';

export default filters => {
  return dispatch => {
    dispatch({
      type: SET_PORTCO_FILTERS,
      payload: filters && Object.keys(filters).length > 0 ? filters : {},
    });
  };
};
