/**
 * @format
 */
import supportsHTML5Storage from './supportsHTML5Storage';
const getTableRowsettingsCache = workspace_id => {
  if (!supportsHTML5Storage()) {
    return null;
  }
  let tableRowsettings = window.localStorage.getItem('tableRowsettings');
  if (tableRowsettings && typeof tableRowsettings === 'string') {
    try {
      tableRowsettings = JSON.parse(tableRowsettings);
      // If expires_on is in the past, expire
      if (new Date(tableRowsettings.expires_on) < new Date()) {
        window.localStorage.removeItem('tableRowsettings');
        return null;
      }
      // Return value if it has not expired
      return tableRowsettings.value && tableRowsettings.value[workspace_id]
        ? tableRowsettings.value[workspace_id]
        : null;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export default getTableRowsettingsCache;
