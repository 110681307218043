import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const updateStartupAddress = (deal_id, startup_id, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!deal_id || !startup_id) {
        let message = `Something went wrong while updating this startup's addresses. Please try again. ` +
            'If this keeps happening, contact your administrator';
        dispatch({
          type: types.UPDATE_STARTUP_ADDRESS_FAILURE,
          payload: {
            startup_id,
            message
          }
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.UPDATE_STARTUP_ADDRESS_REQUEST,
        payload: {
          startup_id,
          data: form
        }
      });
      request.put(`/api/v2/addresses?deal_id=${deal_id}`, form, dispatch)
      .then(({ data }) => {
        if (data && data.success) {
          dispatch({
            type: types.UPDATE_STARTUP_ADDRESS_SUCCESS,
            payload: {
              startup_id,
              data: data.data
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.UPDATE_STARTUP_ADDRESS_FAILURE,
            payload: {
              startup_id,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        let message;
        message = `Something went wrong while updating this startup's details. Please try again`;
        dispatch({
          type: types.UPDATE_STARTUP_ADDRESS_FAILURE,
          payload: {
            startup_id,
            message
          }
        });
        dispatch(enqueueNotification(message));
        return reject(err);
      });
    });
  };
};

////////////////////////////////////////////////////////////////////////////////
///                              Portfolio
////////////////////////////////////////////////////////////////////////////////


export const updatePortcoStartupAddress = (portco_id, startup_id, form) => {
  return async dispatch => {
    if (!portco_id || !startup_id) {
      let message = `Something went wrong while updating this startup's addresses. Please try again. ` +
          'If this keeps happening, contact your administrator';
      dispatch({
        type: types.UPDATE_STARTUP_ADDRESS_FAILURE,
        payload: {
          startup_id,
          message
        }
      });
      dispatch(enqueueNotification(message));
      throw (new Error(message));
    }
    dispatch({
      type: types.UPDATE_STARTUP_ADDRESS_REQUEST,
      payload: {
        startup_id,
        data: form
      }
    });
    let data;
    try {
      const response = await request.put(`/api/portfolio/v1/companies/${portco_id}/startup/address`, form, dispatch)
      data = response.data;
    } catch (err) {
      let message;
      message = `Something went wrong while updating this startup's details. Please try again`;
      dispatch({
        type: types.UPDATE_STARTUP_ADDRESS_FAILURE,
        payload: {
          startup_id,
          message
        }
      });
      dispatch(enqueueNotification(message));
      throw (err);
    }
    if (data && data.success) {
      dispatch({
        type: types.UPDATE_STARTUP_ADDRESS_SUCCESS,
        payload: {
          startup_id,
          data: data.data
        }
      });
      return (data.data);
    } else {
      dispatch({
        type: types.UPDATE_STARTUP_ADDRESS_FAILURE,
        payload: {
          startup_id,
          message: data.message
        }
      });
      dispatch(enqueueNotification(data.message));
      throw (new Error(data.message));
    }
  };
};
