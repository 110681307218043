/**
 * @format
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const loadDealSignalsList = (dealId, options = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_DEAL_SIGNALS_LIST_REQUEST,
        payload: {
          dealId: dealId,
        },
      });
      request
        .get(`/api/v3/deal-signals/deal/${dealId}`, options, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_DEAL_SIGNALS_LIST_SUCCESS,
              payload: {
                data: data.data,
                dealId: dealId,
              },
            });
            return resolve(data.data);
          } else {
            dispatch({
              type: types.LOAD_DEAL_SIGNALS_LIST_FAILURE,
              payload: {
                message: data.message,
                dealId: dealId,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            "Something went wrong while loading deal's signals, please try again.";
          dispatch({
            type: types.LOAD_DEAL_SIGNALS_LIST_FAILURE,
            payload: {
              message,
              dealId: dealId,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

export const loadDealSignal = (dealId, signalId, options = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_DEAL_SIGNAL_REQUEST,
        payload: {
          signalId: signalId,
        },
      });
      request
        .get(
          `/api/v3/deal-signals/deal/${dealId}/signal/${signalId}`,
          options,
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_DEAL_SIGNAL_SUCCESS,
              payload: {
                data: data.data,
                signalId: signalId,
              },
            });
            return resolve(data.data);
          } else {
            dispatch({
              type: types.LOAD_DEAL_SIGNAL_FAILURE,
              payload: {
                message: data.message,
                signalId: signalId,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading the signal, please try again.';
          dispatch({
            type: types.LOAD_DEAL_SIGNAL_FAILURE,
            payload: {
              message,
              signalId: signalId,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

/**
 * LOAD_ORGANIZATION_SIGNALS
 */
export const loadOrganizationSignals = (options = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_ORGANIZATION_SIGNALS_REQUEST,
        payload: {
          options,
        },
      });
      request
        .get(`/api/v3/signals`, options, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_ORGANIZATION_SIGNALS_SUCCESS,
              payload: {
                data: data.data,
                options,
              },
            });
            return resolve(data.data);
          } else {
            dispatch({
              type: types.LOAD_ORGANIZATION_SIGNALS_FAILURE,
              payload: {
                message: data.message,
                options,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading signals, please try again.';
          dispatch({
            type: types.LOAD_ORGANIZATION_SIGNALS_FAILURE,
            payload: {
              message,
              options,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

/**
 * LOAD_SIGNALS_STATS
 */
export const loadSignalsStats = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_SIGNALS_STATS_REQUEST,
        payload: {},
      });
      request
        .get(`/api/v3/signals/stats`, {}, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_SIGNALS_STATS_SUCCESS,
              payload: {
                data: data.data,
              },
            });
            return resolve(data.data);
          } else {
            dispatch({
              type: types.LOAD_SIGNALS_STATS_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading signals stats, please try again.';
          dispatch({
            type: types.LOAD_SIGNALS_STATS_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

/**
 * CREATE_DEAL_FROM_SUPER_SIGNAL
 */
export const createDealFromSuperSignal = (
  superSignalId,
  signalId,
  form = {}
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.CREATE_DEAL_FROM_SUPER_SIGNAL_REQUEST,
        payload: {
          superSignalId,
          signalId,
        },
      });
      request
        .post(
          `/api/v3/signals/super-signals/${superSignalId}/create-deal`,
          form,
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.CREATE_DEAL_FROM_SUPER_SIGNAL_SUCCESS,
              payload: {
                superSignalId,
                signalId,
                data: data.data,
              },
            });
            return resolve(data.data);
          } else {
            dispatch({
              type: types.CREATE_DEAL_FROM_SUPER_SIGNAL_FAILURE,
              payload: {
                superSignalId,
                signalId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while adding deal from super signal, please try again.';
          dispatch({
            type: types.CREATE_DEAL_FROM_SUPER_SIGNAL_FAILURE,
            payload: {
              superSignalId,
              signalId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

/**
 * CREATE_DEAL_FROM_MOVEMENT
 */
export const createDealFromMovement = (movementId, signalId, form = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.CREATE_DEAL_FROM_MOVEMENT_REQUEST,
        payload: {
          signalId,
          movementId,
        },
      });
      request
        .post(
          `/api/v3/signals/movements/${movementId}/create-deal`,
          form,
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.CREATE_DEAL_FROM_MOVEMENT_SUCCESS,
              payload: {
                movementId,
                signalId,
                data: data.data,
              },
            });
            return resolve(data.data);
          } else {
            dispatch({
              type: types.CREATE_DEAL_FROM_MOVEMENT_FAILURE,
              payload: {
                movementId,
                signalId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while adding deal from movement, please try again.';
          dispatch({
            type: types.CREATE_DEAL_FROM_MOVEMENT_FAILURE,
            payload: {
              movementId,
              signalId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

/**
 * LOAD_SIGNALS_FOR_DEAL
 */
export const loadSignalsForDeal = (dealId, options = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_SIGNALS_FOR_DEAL_REQUEST,
        payload: {
          dealId,
          options,
        },
      });
      request
        .get(`/api/v3/signals/deal/${dealId}`, options, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_SIGNALS_FOR_DEAL_SUCCESS,
              payload: {
                dealId,
                data: data.data,
                options,
              },
            });
            return resolve(data.data);
          } else {
            dispatch({
              type: types.LOAD_SIGNALS_FOR_DEAL_FAILURE,
              payload: {
                dealId,
                message: data.message,
                options,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading signals for deal, please try again.';
          dispatch({
            type: types.LOAD_SIGNALS_FOR_DEAL_FAILURE,
            payload: {
              dealId,
              message,
              options,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};
