import { 
  REMOVE_STARTUP_FOUNDER_REQUEST,
  REMOVE_STARTUP_FOUNDER_SUCCESS,
  REMOVE_STARTUP_FOUNDER_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case REMOVE_STARTUP_FOUNDER_REQUEST:
      return Object.assign({}, state, {
        removeFounderLoading: true,
        removeFounderError: null
      });

    case REMOVE_STARTUP_FOUNDER_SUCCESS:
      return Object.assign({}, state, {
        removeFounderLoading: false,
        removeFounderError: null
      });
      
    case REMOVE_STARTUP_FOUNDER_FAILURE:
      return Object.assign({}, state, {
        removeFounderLoading: false,
        removeFounderError: payload,
      });

    default:
      return state;
  }
};
