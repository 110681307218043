/**
 * APPROVE_SIGN_OFF reducer
 * @format
 */
import {
  APPROVE_SIGN_OFF_REQUEST,
  APPROVE_SIGN_OFF_SUCCESS,
  APPROVE_SIGN_OFF_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case APPROVE_SIGN_OFF_REQUEST:
      return Object.assign({}, state, {
        updateSignOffItemLoading: {
          ...state.updateSignOffItemLoading,
          [payload.signOffId]: true,
        },
        updateSignOffItemError: {
          ...state.updateSignOffItemError,
          [payload.signOffId]: null,
        },
      });

    case APPROVE_SIGN_OFF_SUCCESS:
      return Object.assign({}, state, {
        updateSignOffItemLoading: {
          ...state.updateSignOffItemLoading,
          [payload.signOffId]: false,
        },
        updateSignOffItemError: {
          ...state.updateSignOffItemError,
          [payload.signOffId]: null,
        },
        signOffs: {
          ...state.signOffs,
          [payload.signOffId]: {
            ...(state.signOffs[payload.signOffId] || {}),
            ...payload.data,
            creator: {
              ...((state.signOffs[payload.signOffId] &&
                state.signOffs[payload.signOffId].creator) ||
                {}),
              ...(payload.data.creator || {}),
            },
            attachments:
              payload.data.attachments &&
              payload.data.attachments instanceof Array
                ? payload.data.attachments
                : (state.signOffs[payload.signOffId] &&
                    state.signOffs[payload.signOffId].attachments) ||
                  [],
            assignees:
              payload.data.assignees && payload.data.assignees.length > 0
                ? payload.data.assignees
                : (state.signOffs[payload.signOffId] &&
                    state.signOffs[payload.signOffId].assignees) ||
                  [],
          },
        },
      });
    case APPROVE_SIGN_OFF_FAILURE:
      return Object.assign({}, state, {
        updateSignOffItemLoading: {
          ...state.updateSignOffItemLoading,
          [payload.signOffId]: false,
        },
        updateSignOffItemError: {
          ...state.updateSignOffItemError,
          [payload.signOffId]: payload.message,
        },
      });

    default:
      return state;
  }
};
