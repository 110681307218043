/**
 * @format
 */
import * as types from './actions';
import {
  ACTIVE_WORKSPACE_POSTION_ID,
  INTERNAL_NAMES,
} from '../../constants/Workspace';
import { getActiveWorkspacePositionId } from '../../helpers/getActiveWorkspacePositionId';

const initialState = {
  /**
   * Holds state related to loading all of organization's workspaces. No
   * namespacing needed for this state
   */
  workspacesLoading: false,
  /**
   * Holds state related to error in loading all of organization's workspaces.
   * No namespacing needed for this state
   */
  workspacesError: null,
  /**
   * Holds state related to loading state of request to add new workspace
   * No namespacing needed for this state
   */
  addWorkspaceLoading: false,
  /**
   * Holds state related to error in adding new organization's workspaces.
   * No namespacing needed for this state
   */
  addWorkspaceError: null,
  /**
   * Holds workspaces for an organization. Namespacing will be needed when we
   * support multiple single user to be part of multiple organizations
   *
   * This here is an object keyed by position_id, which are numbers, so it can be
   * treated both as an object (if you have the position_id), or as an array
   *
   * Warning: If you are planning to iterate through this list, iterate using
   * `for(let key in ob){...}` instead of the regular `for(let i=0;i<length;i++){...}`
   * because there may be indexes with missing values, because of logical bugs on
   * server end that you'll need to check for otherwise
   */
  workspaces: [],
  pipelineWorkspacePositionId: -1,
  portfolioWorkspacePositionId: -1,
  fundsWorkspacePositionId: -1,
  investorsWorkspacePositionId: -1,
  contactsWorkspacePositionId: -1,
  activeWorkspacePositionId:
    getActiveWorkspacePositionId(ACTIVE_WORKSPACE_POSTION_ID) || 0,

  /**
   * Members' roles for different workspaces.
   * Key: workspace id
   * value: [{id:user_id<String>,workspaceAdmin:Boolean,workspaceMember:boolean}]
   */
  membersRoles: {},
  membersRolesLoading: {},
  membersRolesError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // List organization workspaces
    case types.LOAD_ORGANIZATION_WORKSPACE_LIST_REQUEST:
      return Object.assign({}, state, {
        workspacesLoading: true,
        workspacesError: null,
      });
    case types.LOAD_ORGANIZATION_WORKSPACE_LIST_SUCCESS: {
      let workspaces = [];
      let pipelineWorkspacePositionId = -1;
      let portfolioWorkspacePositionId = -1;
      let fundsWorkspacePositionId = -1;
      let investorsWorkspacePositionId = -1;
      let contactsWorkspacePositionId = -1;
      if (
        payload &&
        payload.data &&
        payload.data instanceof Array &&
        payload.data.length > 0
      ) {
        // map list to workspaces array, keying with position_id
        payload.data.forEach(workspace => {
          switch (workspace.internal_name) {
            case INTERNAL_NAMES.PIPELINE:
              pipelineWorkspacePositionId = workspace.position_id;
              break;
            case INTERNAL_NAMES.PORTFOLIO:
              portfolioWorkspacePositionId = workspace.position_id;
              break;
            case INTERNAL_NAMES.FUNDS:
              fundsWorkspacePositionId = workspace.position_id;
              break;
            case INTERNAL_NAMES.INVESTORS:
              investorsWorkspacePositionId = workspace.position_id;
              break;
            case INTERNAL_NAMES.CONTACTS:
              contactsWorkspacePositionId = workspace.position_id;
              break;
            default:
              break;
          }
          workspaces[workspace.position_id] = {
            // preserve any previously loaded related data
            ...workspaces[workspace.position_id],
            ...workspace,
          };
        });
      }
      return Object.assign({}, state, {
        workspacesLoading: false,
        workspacesError: null,
        workspaces,
        pipelineWorkspacePositionId,
        portfolioWorkspacePositionId,
        fundsWorkspacePositionId,
        investorsWorkspacePositionId,
        contactsWorkspacePositionId,
      });
    }
    case types.LOAD_ORGANIZATION_WORKSPACE_LIST_FAILURE:
      return Object.assign({}, state, {
        workspacesLoading: false,
        workspacesError: payload.message,
      });

    // List organization workspaces
    case types.ADD_WORKSPACE_REQUEST:
      return Object.assign({}, state, {
        addWorkspaceLoading: true,
        addWorkspaceError: null,
      });
    case types.ADD_WORKSPACE_SUCCESS: {
      return Object.assign({}, state, {
        addWorkspaceLoading: false,
        addWorkspaceError: null,
        workspaces: [...state.workspaces, payload.data],
      });
    }
    case types.ADD_WORKSPACE_FAILURE:
      return Object.assign({}, state, {
        addWorkspaceLoading: false,
        addWorkspaceError: payload.message,
      });

    // List workspace's members' roles
    case types.LOAD_WORKSPACE_MEMBERS_ROLES_REQUEST:
      return Object.assign({}, state, {
        membersRolesLoading: {
          ...state.membersRolesLoading,
          [payload.workspaceId]: true,
        },
        membersRolesError: {
          ...state.membersRolesError,
          [payload.workspaceId]: null,
        },
      });
    case types.LOAD_WORKSPACE_MEMBERS_ROLES_SUCCESS: {
      return Object.assign({}, state, {
        membersRolesLoading: {
          ...state.membersRolesLoading,
          [payload.workspaceId]: false,
        },
        membersRolesError: {
          ...state.membersRolesError,
          [payload.workspaceId]: null,
        },
        membersRoles: {
          ...state.membersRoles,
          [payload.workspaceId]: payload.data,
        },
      });
    }
    case types.LOAD_WORKSPACE_MEMBERS_ROLES_FAILURE:
      return Object.assign({}, state, {
        membersRolesLoading: {
          ...state.membersRolesLoading,
          [payload.workspaceId]: false,
        },
        membersRolesError: {
          ...state.membersRolesError,
          [payload.workspaceId]: payload.message,
        },
      });

    // Update a workspace
    case types.UPDATE_WORKSPACE_REQUEST:
      // Deep clone target workspace as it is in the current state
      const clonedWorkspaceForUpdateRequest = {
        ...state.workspaces[payload.position_id],
      };
      // If the target workspace is undefined (not present), then do nothing
      if (Object.keys(clonedWorkspaceForUpdateRequest).length === 0) {
        return state; // this shouldn't happen, really
      }
      // Deep clone second-level props
      clonedWorkspaceForUpdateRequest.loading = {
        ...clonedWorkspaceForUpdateRequest.loading,
      };
      clonedWorkspaceForUpdateRequest.error = {
        ...clonedWorkspaceForUpdateRequest.error,
      };
      // Update loading/error for the properties being updated
      payload.propertiesToUpdate.forEach(propertyToUpdate => {
        clonedWorkspaceForUpdateRequest.loading[propertyToUpdate] = true;
        clonedWorkspaceForUpdateRequest.error[propertyToUpdate] = null;
      });
      // Duplicate the array, placing the updated workspace object at its position index
      return Object.assign({}, state, {
        workspaces: [
          ...state.workspaces.slice(0, payload.position_id),
          clonedWorkspaceForUpdateRequest,
          ...state.workspaces.slice(payload.position_id + 1),
        ],
      });
    case types.UPDATE_WORKSPACE_SUCCESS:
      // NOTE: We will not be taking care of re-positioning workspaces even
      // if position is updated with this request. The update in position has
      // to be synced with state by making a separate request to reload workspace
      // list. If we try to manage that here, we will end up introducing weird,
      // hard-to-debug bugs
      // Deep clone target workspace as it is in the current state
      const clonedWorkspaceForUpdateSuccess = {
        ...state.workspaces[payload.position_id],
      };
      // If the target workspace is undefined (not present), then do nothing
      if (Object.keys(clonedWorkspaceForUpdateSuccess).length === 0) {
        return state; // this shouldn't happen, really
      }
      clonedWorkspaceForUpdateSuccess.loading = {
        ...clonedWorkspaceForUpdateSuccess.loading,
      };
      clonedWorkspaceForUpdateSuccess.error = {
        ...clonedWorkspaceForUpdateSuccess.error,
      };
      // Remove unnecessary props
      payload.propertiesToUpdate.forEach(propertyToUpdate => {
        delete clonedWorkspaceForUpdateSuccess.loading[propertyToUpdate];
        delete clonedWorkspaceForUpdateSuccess.error[propertyToUpdate];
      });
      // If there are no keys left after deletion, delete loading and error props too
      if (Object.keys(clonedWorkspaceForUpdateSuccess.loading).length === 0) {
        delete clonedWorkspaceForUpdateSuccess.loading;
      }
      if (Object.keys(clonedWorkspaceForUpdateSuccess.error).length === 0) {
        delete clonedWorkspaceForUpdateSuccess.error;
      }
      // Duplicate the array, placing the updated workspace object at its position index
      return Object.assign({}, state, {
        workspaces: [
          ...state.workspaces.slice(0, payload.position_id),
          clonedWorkspaceForUpdateSuccess,
          ...state.workspaces.slice(payload.position_id + 1),
        ],
        membersRoles: {
          ...state.membersRoles,
          [state.workspaces[payload.position_id].id]: payload.membersRoles,
        },
      });
    case types.UPDATE_WORKSPACE_FAILURE:
      // Deep clone target workspace as it is in the current state
      const clonedWorkspaceForUpdateFailure = {
        ...state.workspaces[payload.position_id],
      };
      // If the target workspace is undefined (not present), then do nothing
      if (Object.keys(clonedWorkspaceForUpdateFailure).length === 0) {
        return state; // this shouldn't happen, really
      }
      // Deep clone second-level props
      clonedWorkspaceForUpdateFailure.loading = {
        ...clonedWorkspaceForUpdateFailure.loading,
      };
      clonedWorkspaceForUpdateFailure.error = {
        ...clonedWorkspaceForUpdateFailure.error,
      };
      // Update loading/error for the properties being updated
      payload.propertiesToUpdate.forEach(propertyToUpdate => {
        clonedWorkspaceForUpdateFailure.loading[propertyToUpdate] = false;
        clonedWorkspaceForUpdateFailure.error[propertyToUpdate] =
          payload.message;
      });
      // Duplicate the array, placing the updated workspace object at its position index
      return Object.assign({}, state, {
        workspaces: [
          ...state.workspaces.slice(0, payload.position_id),
          clonedWorkspaceForUpdateFailure,
          ...state.workspaces.slice(payload.position_id + 1),
        ],
      });

    // soft-delete a workspace
    case types.SOFT_DELETE_WORKSPACE_REQUEST:
      // Deep clone target workspace as it is in the current state
      const clonedWorkspaceForDeleteRequest = {
        ...state.workspaces[payload.position_id],
      };
      // If the target workspace is undefined (not present), then do nothing
      if (Object.keys(clonedWorkspaceForDeleteRequest).length === 0) {
        return state; // this shouldn't happen, really
      }
      clonedWorkspaceForDeleteRequest.loading = {
        ...clonedWorkspaceForDeleteRequest.loading,
        softDelete: true,
      };
      clonedWorkspaceForDeleteRequest.error = {
        ...clonedWorkspaceForDeleteRequest.error,
        softDelete: null,
      };
      // Duplicate the array, placing the updated workspace object at its position index
      return Object.assign({}, state, {
        workspaces: [
          ...state.workspaces.slice(0, payload.position_id),
          clonedWorkspaceForDeleteRequest,
          ...state.workspaces.slice(payload.position_id + 1),
        ],
      });
    case types.SOFT_DELETE_WORKSPACE_SUCCESS:
      // NOTE: We will not be taking care of re-positioning workspaces or
      // marking it hidden even though position is updated with this request.
      // The update in its situation has to be synced with state by making a
      // separate request to reload workspace list. If we try to manage that
      // here, we will end up introducing weird, hard-to-debug bugs
      // Deep clone target workspace as it is in the current state
      const clonedWorkspaceForDeleteSuccess = {
        ...state.workspaces[payload.position_id],
      };
      // If the target workspace is undefined (not present), then do nothing
      if (Object.keys(clonedWorkspaceForDeleteSuccess).length === 0) {
        return state; // this shouldn't happen, really
      }
      clonedWorkspaceForDeleteSuccess.loading = {
        ...clonedWorkspaceForDeleteSuccess.loading,
      };
      clonedWorkspaceForDeleteSuccess.error = {
        ...clonedWorkspaceForDeleteSuccess.error,
      };
      // Remove unnecessary props
      delete clonedWorkspaceForDeleteSuccess.loading.softDelete;
      delete clonedWorkspaceForDeleteSuccess.error.softDelete;
      // If there are no keys left after deletion, delete loading and error props too
      if (Object.keys(clonedWorkspaceForDeleteSuccess.loading).length === 0) {
        delete clonedWorkspaceForDeleteSuccess.loading;
      }
      if (Object.keys(clonedWorkspaceForDeleteSuccess.error).length === 0) {
        delete clonedWorkspaceForDeleteSuccess.error;
      }
      // Duplicate the array, placing the updated workspace object at its position index
      return Object.assign({}, state, {
        workspaces: [
          ...state.workspaces.slice(0, payload.position_id),
          clonedWorkspaceForDeleteSuccess,
          ...state.workspaces.slice(payload.position_id + 1),
        ],
      });
    case types.SOFT_DELETE_WORKSPACE_FAILURE:
      // Deep clone target workspace as it is in the current state
      const clonedWorkspaceForDeleteFailure = {
        ...state.workspaces[payload.position_id],
      };
      // If the target workspace is undefined (not present), then do nothing
      if (Object.keys(clonedWorkspaceForDeleteFailure).length === 0) {
        return state; // this shouldn't happen, really
      }
      clonedWorkspaceForDeleteFailure.loading = {
        ...clonedWorkspaceForDeleteFailure.loading,
        softDelete: false,
      };
      clonedWorkspaceForDeleteFailure.error = {
        ...clonedWorkspaceForDeleteFailure.error,
        softDelete: payload.message,
      };
      // Duplicate the array, placing the updated workspace object at its position index
      return Object.assign({}, state, {
        workspaces: [
          ...state.workspaces.slice(0, payload.position_id),
          clonedWorkspaceForDeleteFailure,
          ...state.workspaces.slice(payload.position_id + 1),
        ],
      });
    case types.SWITCH_WORKSPACE:
      if (
        payload.position_id < 0 ||
        payload.position_id > state.workspaces.length
      ) {
        return state;
      }
      return Object.assign({}, state, {
        activeWorkspacePositionId: payload.position_id,
      });
    default:
      return state;
  }
};
