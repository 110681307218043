/**
 * GoogleConnect container
 *
 * @author  Akhila
*/
import Button from '@material-ui/core/Button';
import Footer from '../../components/Footer';
import GuestPage from '../../components/GuestPage';
import PropTypes from 'prop-types';
import React from 'react';
import WelcomePage from '../../components/WelcomePage';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadUser } from '../../modules/auth';
import { saveStage, fetchGoogleSetting } from '../../modules/setting';
import { withStyles } from '@material-ui/core/styles';

const INTERVAL = 5000;

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  }
});

class GoogleConnect extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    completedStages: PropTypes.array.isRequired,
    saveStage: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    googleSettingLoading: PropTypes.bool.isRequired,
    fetchGoogleSetting: PropTypes.func.isRequired,
    saveStageLoading: PropTypes.bool.isRequired,
    googleSetting: PropTypes.object
  };

  state = {
    timer: null,
  };

  componentDidMount() {
    const { completedStages, history } = this.props;
    if (completedStages.indexOf('google-connect') > -1 ) {
      history.push('/dashboard');
      return;
    }
    this.getGoogleData();
    const timer = setInterval(this.getGoogleData, INTERVAL);
    this.setState({
      timer
    });
  }

  componentWillUnmount() {
    if(this.state.timer) {
      clearInterval(this.state.timer);
    }
  }

  getGoogleData = () => {
    const { googleSettingLoading, fetchGoogleSetting } = this.props;
    if (googleSettingLoading) return;
    fetchGoogleSetting();
  };

  handleSubmit = event => {
    event && event.preventDefault();
    const { saveStage, loadUser, saveStageLoading } = this.props;
    if (saveStageLoading) return;
    saveStage('google-connect')
    .then((data) => loadUser('/welcome/finish'))
    .catch((err) => {});
  };

  /**
   * When the user is already connected to his/her google account we show this
   * message
   * @return {Node}
   */
  renderSuccessGoogleConnectMessage = () => {
    return (
      <div>
        <p>
          Successfully connected your google account, please
          click 'Next' to continue.
        </p>
      </div>
    );
  };

  /**
   * When user is signing up for the first time he/she is not connect to google
   * account we show this message
   * @return {Node}
   */
  renderGoogleConnectMessage = () => {
    return (
      <div>
        <p>
          You will need to <strong>connect your Google account</strong> so that you can maximise
          your productivity with Taghash.
        </p>
        <p>
          We import only the emails you mark using the label
          <b> TagHashPipeline</b> in your Gmail inbox.
        </p>
      </div>
    );
  };

  render() {
    const { classes, saveStageLoading } = this.props;
    const { googleSetting, googleSettingLoading } = this.props;
    const isActive = (
      googleSetting && googleSetting.google_auth_status === 'active'
    );
    return (
      <GuestPage>
        <WelcomePage
          header={isActive ? "Successfully connected your Google account!"
           : "Connect your Google account" }
          loader={saveStageLoading || (!googleSetting && googleSettingLoading)}
          buttonName={isActive ? "Next" : ""}
          handleSubmit={isActive ? this.handleSubmit : null}
        >
          {isActive ? (
            this.renderSuccessGoogleConnectMessage() ) : (
            this.renderGoogleConnectMessage()
          )}
          {googleSetting && !isActive && (
            <div>
              <Button
                className={classes.button}
                as="a"
                href={googleSetting.google_auth_url}
                size="large"
                variant="contained"
                color="primary"
                disabled={saveStageLoading}
              >
                Connect Google Account
              </Button>
              <Button
                className={classes.button}
                onClick={this.handleSubmit}
                size="large"
                variant="text"
                color="default"
                disabled={saveStageLoading}
              >
                I'll do it later
              </Button>
            </div>
          )}
        </WelcomePage>
        <Footer />
      </GuestPage>
    );
  }
}

const mapStateToProps = (state) => ({
  completedStages: state.auth.stage,
  googleSettingLoading: state.setting.googleSettingLoading,
  saveStageLoading: state.setting.saveStageLoading,
  googleSetting: state.setting.googleSetting,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchGoogleSetting,
  saveStage,
  loadUser
}, dispatch);

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleConnect));

