/**
 * Returns the deal overview fields
 * @format
 */

import supportsHTML5Storage from './supportsHTML5Storage';

const getDealOverviewFields = workspace_id => {
  if (!supportsHTML5Storage()) {
    return null;
  }
  let dealOverviewFields = window.localStorage.getItem('dealOverviewFields');

  if (dealOverviewFields && typeof dealOverviewFields === 'string') {
    try {
      dealOverviewFields = JSON.parse(dealOverviewFields);
      // If expires_on is in the past, expire
      if (new Date(dealOverviewFields.expires_on) < new Date()) {
        window.localStorage.removeItem('dealOverviewFields');
        return null;
      }
      // Return value if it has not expired
      return dealOverviewFields.value && dealOverviewFields.value[workspace_id]
        ? dealOverviewFields.value[workspace_id]
        : null;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export default getDealOverviewFields;
