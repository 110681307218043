/**
 * @format
 */

import DealsOverviewFields from '../../constants/DealsOverviewFields';

export default [
  { id: DealsOverviewFields.v1.STARTUP_EMAIL.key, active: true },
  { id: DealsOverviewFields.v1.STARTUP_URL.key, active: true },
  { id: DealsOverviewFields.v1.STARTUP_FOUNDERS.key, active: true },
  { id: DealsOverviewFields.v1.CREATED_AT.key, active: true },
  { id: DealsOverviewFields.v1.LAST_ACTIVE_AT.key, active: false },
  { id: DealsOverviewFields.v1.STARTUP_DESCRIPTION.key, active: true },
  { id: DealsOverviewFields.v1.STARTUP_EMPLOYEE_COUNT.key, active: false },
  { id: DealsOverviewFields.v1.STARTUP_FOUNDING_YEAR.key, active: true },
  { id: DealsOverviewFields.v1.STARTUP_GLOBAL_OP_ADDRESS.key, active: false },
  { id: DealsOverviewFields.v1.STARTUP_PHONE_NUMBER.key, active: false },
  { id: DealsOverviewFields.v1.STARTUP_REGISTERED_NAME.key, active: false },
  { id: DealsOverviewFields.v1.STARTUP_LOCAL_OP_ADDRESS.key, active: false },
  { id: DealsOverviewFields.v1.EXTERNAL_REFERRER.key, active: false },
  { id: DealsOverviewFields.v1.INTERNAL_REFERRERS.key, active: false },
  { id: DealsOverviewFields.v1.INVESTMENT_REQUESTED.key, active: false },
  { id: DealsOverviewFields.v1.REFERRAL_MEDIUM.key, active: false },
  { id: DealsOverviewFields.v1.SOCIAL_LINKS.key, active: false },
  { id: DealsOverviewFields.v1.STARTUP_OPERATIONAL_STATUS.key, active: true },
  { id: DealsOverviewFields.v1.ADDED_BY.key, active: false },
  { id: DealsOverviewFields.v2.FIRST_EMAIL_INTERACTION_DATE.key, active: true },
];
