export const LOAD_NOTIFICATION_LIST_REQUEST = 'notification/LOAD_NOTIFICATION_LIST_REQUEST';
export const LOAD_NOTIFICATION_LIST_SUCCESS = 'notification/LOAD_NOTIFICATION_LIST_SUCCESS';
export const LOAD_NOTIFICATION_LIST_FAILURE = 'notification/LOAD_NOTIFICATION_LIST_FAILURE';

export const MARK_NOTIFICATION_READ_REQUEST = 'notification/MARK_NOTIFICATION_READ_REQUEST';
export const MARK_NOTIFICATION_READ_SUCCESS = 'notification/MARK_NOTIFICATION_READ_SUCCESS';
export const MARK_NOTIFICATION_READ_FAILURE = 'notification/MARK_NOTIFICATION_READ_FAILURE';

export const MARK_ALL_NOTIFICATIONS_READ_REQUEST = 'notification/MARK_ALL_NOTIFICATIONS_READ_REQUEST';
export const MARK_ALL_NOTIFICATIONS_READ_SUCCESS = 'notification/MARK_ALL_NOTIFICATIONS_READ_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_READ_FAILURE = 'notification/MARK_ALL_NOTIFICATIONS_READ_FAILURE';

export const SET_NEW_INCOMING_NOTIFICATION = 'notification/SET_NEW_INCOMING_NOTIFICATION';
