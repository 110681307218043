/**
 * @format
 * Delete [prtcp] reducer handler
 */
import {
  DELETE_PORTCO_FAILURE,
  DELETE_PORTCO_REQUEST,
  DELETE_PORTCO_SUCCESS,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case DELETE_PORTCO_REQUEST:
      return Object.assign({}, state, {
        deletePortcoLoading: true,
        deletePortcoError: null,
      });

    case DELETE_PORTCO_SUCCESS:
      const portcosFiltered = Object.keys(state.portcos)
        .filter(portcoId => portcoId !== payload.portcoId)
        .reduce((obj, item) => {
          obj[item] = state.portcos[item];
          return obj;
        }, {});
      return Object.assign({}, state, {
        portcos: {
          ...portcosFiltered,
        },
        deletePortcoLoading: false,
        deletePortcoError: null,
      });

    case DELETE_PORTCO_FAILURE:
      return Object.assign({}, state, {
        deletePortcoLoading: false,
        deletePortcoError: payload.message,
      });

    default:
      return state;
  }
};
