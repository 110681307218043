import {
  LOAD_DEAL_CATEGORY_REQUEST,
  LOAD_DEAL_CATEGORY_SUCCESS,
  LOAD_DEAL_CATEGORY_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_DEAL_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        dealCategoryLoading: {
          ...state.dealCategoryLoading,
          [payload.dealId]: true
        },
        dealCategoryError: {
          ...state.dealCategoryError,
          [payload.dealId]: null
        },
      });

    case LOAD_DEAL_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        deals: {
          ...state.deals,
          [payload.dealId]: (state.deals[payload.dealId] && {
            ...state.deals[payload.dealId],
            categories: payload.data
          })
        },
        dealCategoryLoading: {
          ...state.dealCategoryLoading,
          [payload.dealId]: false
        },
        dealCategoryError: {
          ...state.dealCategoryError,
          [payload.dealId]: null
        },
      });

    case LOAD_DEAL_CATEGORY_FAILURE:
      return Object.assign({}, state, {
        dealCategoryLoading: {
          ...state.dealCategoryLoading,
          [payload.dealId]: false
        },
        dealCategoryError: {
          ...state.dealCategoryError,
          [payload.dealId]: payload.message
        },
      });

    default:
      return state;
  }
};
