
//ORGANIZATION CONTACT

/**
 * LOAD_ORGANIZATION_CONTACTS_LIST actions
 */
export const LOAD_ORGANIZATION_CONTACTS_LIST_REQUEST = 'organization-contacts/LOAD_ORGANIZATION_CONTACTS_LIST_REQUEST';
export const LOAD_ORGANIZATION_CONTACTS_LIST_SUCCESS = 'organization-contacts/LOAD_ORGANIZATION_CONTACTS_LIST_SUCCESS';
export const LOAD_ORGANIZATION_CONTACTS_LIST_FAILURE = 'organization-contacts/LOAD_ORGANIZATION_CONTACTS_LIST_FAILURE';

/**
 * LOAD_ORGANIZATION_CONTACT actions
 */
export const LOAD_ORGANIZATION_CONTACT_REQUEST = 'organization-contacts/LOAD_ORGANIZATION_CONTACT_REQUEST';
export const LOAD_ORGANIZATION_CONTACT_SUCCESS = 'organization-contacts/LOAD_ORGANIZATION_CONTACT_SUCCESS';
export const LOAD_ORGANIZATION_CONTACT_FAILURE = 'organization-contacts/LOAD_ORGANIZATION_CONTACT_FAILURE';

/**
 * ADD_ORGANIZATION_CONTACTS actions
 */
export const ADD_ORGANIZATION_CONTACTS_REQUEST = 'organization-contacts/ADD_ORGANIZATION_CONTACTS_REQUEST';
export const ADD_ORGANIZATION_CONTACTS_SUCCESS = 'organization-contacts/ADD_ORGANIZATION_CONTACTS_SUCCESS';
export const ADD_ORGANIZATION_CONTACTS_FAILURE = 'organization-contacts/ADD_ORGANIZATION_CONTACTS_FAILURE';

/**
 * UPDATE_ORGANIZATION_CONTACT actions
 */
export const UPDATE_ORGANIZATION_CONTACT_REQUEST = 'organization-contacts/UPDATE_ORGANIZATION_CONTACT_REQUEST';
export const UPDATE_ORGANIZATION_CONTACT_SUCCESS = 'organization-contacts/UPDATE_ORGANIZATION_CONTACT_SUCCESS';
export const UPDATE_ORGANIZATION_CONTACT_FAILURE = 'organization-contacts/UPDATE_ORGANIZATION_CONTACT_FAILURE';

/**
 * DELETE_ORGANIZATION_CONTACT actions
 */
export const DELETE_ORGANIZATION_CONTACT_REQUEST = 'organization-contacts/DELETE_ORGANIZATION_CONTACT_REQUEST';
export const DELETE_ORGANIZATION_CONTACT_SUCCESS = 'organization-contacts/DELETE_ORGANIZATION_CONTACT_SUCCESS';
export const DELETE_ORGANIZATION_CONTACT_FAILURE = 'organization-contacts/DELETE_ORGANIZATION_CONTACT_FAILURE';

/**
 * LOAD_ORGANIZATION_CONTACTS_TAGS actions 
 */
export const LOAD_ORGANIZATION_CONTACTS_TAGS_REQUEST = 'organization-contacts/LOAD_ORGANIZATION_CONTACTS_TAGS_REQUEST';
export const LOAD_ORGANIZATION_CONTACTS_TAGS_SUCCESS = 'organization-contacts/LOAD_ORGANIZATION_CONTACTS_TAGS_SUCCESS';
export const LOAD_ORGANIZATION_CONTACTS_TAGS_FAILURE = 'organization-contacts/LOAD_ORGANIZATION_CONTACTS_TAGS_FAILURE'

/**
 * ADD_TAG_TO_ORGANIZATION_CONTACT
 */
export const ADD_TAG_TO_ORGANIZATION_CONTACT_REQUEST = 'organization-contacts/ADD_TAG_TO_ORGANIZATION_CONTACT_REQUEST';
export const ADD_TAG_TO_ORGANIZATION_CONTACT_SUCCESS = 'organization-contacts/ADD_TAG_TO_ORGANIZATION_CONTACT_SUCCESS';
export const ADD_TAG_TO_ORGANIZATION_CONTACT_FAILURE = 'organization-contacts/ADD_TAG_TO_ORGANIZATION_CONTACT_FAILURE'

/**
 * REMOVE_TAG_FROM_ORGANIZATION_CONTACT
 */
export const REMOVE_TAG_FROM_ORGANIZATION_CONTACT_REQUEST = 'organization-contacts/REMOVE_TAG_FROM_ORGANIZATION_CONTACT_REQUEST';
export const REMOVE_TAG_FROM_ORGANIZATION_CONTACT_SUCCESS = 'organization-contacts/REMOVE_TAG_FROM_ORGANIZATION_CONTACT_SUCCESS';
export const REMOVE_TAG_FROM_ORGANIZATION_CONTACT_FAILURE = 'organization-contacts/REMOVE_TAG_FROM_ORGANIZATION_CONTACT_FAILURE'

// PEOPLE CONTACT

/**
 * LOAD_PEOPLE_CONTACTS_LIST actions
 */
export const LOAD_PEOPLE_CONTACTS_LIST_REQUEST = 'people-contacts/LOAD_PEOPLE_CONTACTS_LIST_REQUEST';
export const LOAD_PEOPLE_CONTACTS_LIST_SUCCESS = 'people-contacts/LOAD_PEOPLE_CONTACTS_LIST_SUCCESS';
export const LOAD_PEOPLE_CONTACTS_LIST_FAILURE = 'people-contacts/LOAD_PEOPLE_CONTACTS_LIST_FAILURE';


/**
 * LOAD_PERSON_CONTACT actions
 */
export const LOAD_PERSON_CONTACT_REQUEST = 'people-contacts/LOAD_PERSON_CONTACT_REQUEST';
export const LOAD_PERSON_CONTACT_SUCCESS = 'people-contacts/LOAD_PERSON_CONTACT_SUCCESS';
export const LOAD_PERSON_CONTACT_FAILURE = 'people-contacts/LOAD_PERSON_CONTACT_FAILURE';

/**
 * ADD_PEOPLE_CONTACTS actions
 */
export const ADD_PEOPLE_CONTACTS_REQUEST = 'people-contacts/ADD_PEOPLE_CONTACTS_REQUEST';
export const ADD_PEOPLE_CONTACTS_SUCCESS = 'people-contacts/ADD_PEOPLE_CONTACTS_SUCCESS';
export const ADD_PEOPLE_CONTACTS_FAILURE = 'people-contacts/ADD_PEOPLE_CONTACTS_FAILURE';

/**
 * UPDATE_PERSON_CONTACT actions
 */
export const UPDATE_PERSON_CONTACT_REQUEST = 'organization-contacts/UPDATE_PERSON_CONTACT_REQUEST';
export const UPDATE_PERSON_CONTACT_SUCCESS = 'organization-contacts/UPDATE_PERSON_CONTACT_SUCCESS';
export const UPDATE_PERSON_CONTACT_FAILURE = 'organization-contacts/UPDATE_PERSON_CONTACT_FAILURE';

/**
 * DELETE_PERSON_CONTACT actions
 */
export const DELETE_PERSON_CONTACT_REQUEST = 'organization-contacts/DELETE_PERSON_CONTACT_REQUEST';
export const DELETE_PERSON_CONTACT_SUCCESS = 'organization-contacts/DELETE_PERSON_CONTACT_SUCCESS';
export const DELETE_PERSON_CONTACT_FAILURE = 'organization-contacts/DELETE_PERSON_CONTACT_FAILURE';

/**
 * LOAD_PEOPLE_CONTACTS_TAGS actions 
 */
export const LOAD_PEOPLE_CONTACTS_TAGS_REQUEST = 'organization-contacts/LOAD_PEOPLE_CONTACTS_TAGS_REQUEST';
export const LOAD_PEOPLE_CONTACTS_TAGS_SUCCESS = 'organization-contacts/LOAD_PEOPLE_CONTACTS_TAGS_SUCCESS';
export const LOAD_PEOPLE_CONTACTS_TAGS_FAILURE = 'organization-contacts/LOAD_PEOPLE_CONTACTS_TAGS_FAILURE'

/**
 * ADD_TAG_TO_PERSON_CONTACT
 */
export const ADD_TAG_TO_PERSON_CONTACT_REQUEST = 'organization-contacts/ADD_TAG_TO_PERSON_CONTACT_REQUEST';
export const ADD_TAG_TO_PERSON_CONTACT_SUCCESS = 'organization-contacts/ADD_TAG_TO_PERSON_CONTACT_SUCCESS';
export const ADD_TAG_TO_PERSON_CONTACT_FAILURE = 'organization-contacts/ADD_TAG_TO_PERSON_CONTACT_FAILURE'

/**
 * REMOVE_TAG_FROM_PERSON_CONTACT
 */
export const REMOVE_TAG_FROM_PERSON_CONTACT_REQUEST = 'organization-contacts/REMOVE_TAG_FROM_PERSON_CONTACT_REQUEST';
export const REMOVE_TAG_FROM_PERSON_CONTACT_SUCCESS = 'organization-contacts/REMOVE_TAG_FROM_PERSON_CONTACT_SUCCESS';
export const REMOVE_TAG_FROM_PERSON_CONTACT_FAILURE = 'organization-contacts/REMOVE_TAG_FROM_PERSON_CONTACT_FAILURE'

/**
 * LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT actions
 */
export const LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT_REQUEST = 'people-contacts/LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT_REQUEST';
export const LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT_SUCCESS = 'people-contacts/LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT_SUCCESS';
export const LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT_FAILURE = 'people-contacts/LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT_FAILURE';

/**
 * LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT actions
 */
export const LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT_REQUEST = 'people-contacts/LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT_REQUEST';
export const LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT_SUCCESS = 'people-contacts/LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT_SUCCESS';
export const LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT_FAILURE = 'people-contacts/LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT_FAILURE';

/**
 * LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT actions
 */
export const LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT_REQUEST = 'organization-contacts/LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT_REQUEST';
export const LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT_SUCCESS = 'organization-contacts/LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT_SUCCESS';
export const LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT_FAILURE = 'organization-contacts/LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT_FAILURE';

/**
 * LOAD_PERSON_CONTACT_INTERACTIONS actions
 */
export const LOAD_PERSON_CONTACT_INTERACTIONS_REQUEST = 'people-contacts/LOAD_PERSON_CONTACT_INTERACTIONS_REQUEST';
export const LOAD_PERSON_CONTACT_INTERACTIONS_SUCCESS = 'people-contacts/LOAD_PERSON_CONTACT_INTERACTIONS_SUCCESS';
export const LOAD_PERSON_CONTACT_INTERACTIONS_FAILURE = 'people-contacts/LOAD_PERSON_CONTACT_INTERACTIONS_FAILURE';

/**
 * LOAD_MEMBERS_OF_ORG_CONTACT actions 
 */
export const LOAD_MEMBERS_OF_ORG_CONTACT_REQUEST = 'organization-contacts/LOAD_MEMBERS_OF_ORG_CONTACT_REQUEST';
export const LOAD_MEMBERS_OF_ORG_CONTACT_SUCCESS = 'organization-contacts/LOAD_MEMBERS_OF_ORG_CONTACT_SUCCESS';
export const LOAD_MEMBERS_OF_ORG_CONTACT_FAILURE = 'organization-contacts/LOAD_MEMBERS_OF_ORG_CONTACT_FAILURE'

export const CLEAR = 'contacts/CLEAR';


// Custom Fields
export const ADD_CUSTOM_FIELD_REQUEST = 'people-contacts/ADD_CUSTOM_FIELD_REQUEST';
export const ADD_CUSTOM_FIELD_SUCCESS = 'people-contacts/ADD_CUSTOM_FIELD_SUCCESS';
export const ADD_CUSTOM_FIELD_FAILURE = 'people-contacts/ADD_CUSTOM_FIELD_FAILURE';
export const LOAD_CUSTOM_FIELDS_REQUEST = 'people-contacts/LOAD_CUSTOM_FIELDS_REQUEST';
export const LOAD_CUSTOM_FIELDS_SUCCESS = 'people-contacts/LOAD_CUSTOM_FIELDS_SUCCESS';
export const LOAD_CUSTOM_FIELDS_FAILURE = 'people-contacts/LOAD_CUSTOM_FIELDS_FAILURE';
export const UPDATE_CUSTOM_FIELDS_REQUEST = 'people-contacts/UPDATE_CUSTOM_FIELDS_REQUEST';
export const UPDATE_CUSTOM_FIELDS_SUCCESS = 'people-contacts/UPDATE_CUSTOM_FIELDS_SUCCESS';
export const UPDATE_CUSTOM_FIELDS_FAILURE = 'people-contacts/UPDATE_CUSTOM_FIELDS_FAILURE';
export const REMOVE_CUSTOM_FIELDS_REQUEST = 'people-contacts/REMOVE_CUSTOM_FIELDS_REQUEST';
export const REMOVE_CUSTOM_FIELDS_SUCCESS = 'people-contacts/REMOVE_CUSTOM_FIELDS_SUCCESS';
export const REMOVE_CUSTOM_FIELDS_FAILURE = 'people-contacts/REMOVE_CUSTOM_FIELDS_FAILURE';
export const LOAD_CUSTOM_FIELD_VALUES_REQUEST = 'people-contacts/LOAD_CUSTOM_FIELD_VALUES_REQUEST';
export const LOAD_CUSTOM_FIELD_VALUES_SUCCESS = 'people-contacts/LOAD_CUSTOM_FIELD_VALUES_SUCCESS';
export const LOAD_CUSTOM_FIELD_VALUES_FAILURE = 'people-contacts/LOAD_CUSTOM_FIELD_VALUES_FAILURE';
export const UPDATE_CUSTOM_FIELD_VALUE_REQUEST = 'people-contacts/UPDATE_CUSTOM_FIELD_VALUE_REQUEST';
export const UPDATE_CUSTOM_FIELD_VALUE_SUCCESS = 'people-contacts/UPDATE_CUSTOM_FIELD_VALUE_SUCCESS';
export const UPDATE_CUSTOM_FIELD_VALUE_FAILURE = 'people-contacts/UPDATE_CUSTOM_FIELD_VALUE_FAILURE';

// Organization contact Custom Fields
export const ADD_ORG_CONTACT_CUSTOM_FIELD_REQUEST = 'Organization-contacts/ADD_CUSTOM_FIELD_REQUEST';
export const ADD_ORG_CONTACT_CUSTOM_FIELD_SUCCESS = 'Organization-contacts/ADD_CUSTOM_FIELD_SUCCESS';
export const ADD_ORG_CONTACT_CUSTOM_FIELD_FAILURE = 'Organization-contacts/ADD_CUSTOM_FIELD_FAILURE';
export const LOAD_ORG_CONTACT_CUSTOM_FIELDS_REQUEST = 'Organization-contacts/LOAD_CUSTOM_FIELDS_REQUEST';
export const LOAD_ORG_CONTACT_CUSTOM_FIELDS_SUCCESS = 'Organization-contacts/LOAD_CUSTOM_FIELDS_SUCCESS';
export const LOAD_ORG_CONTACT_CUSTOM_FIELDS_FAILURE = 'Organization-contacts/LOAD_CUSTOM_FIELDS_FAILURE';
export const UPDATE_ORG_CONTACT_CUSTOM_FIELDS_REQUEST = 'Organization-contacts/UPDATE_CUSTOM_FIELDS_REQUEST';
export const UPDATE_ORG_CONTACT_CUSTOM_FIELDS_SUCCESS = 'Organization-contacts/UPDATE_CUSTOM_FIELDS_SUCCESS';
export const UPDATE_ORG_CONTACT_CUSTOM_FIELDS_FAILURE = 'Organization-contacts/UPDATE_CUSTOM_FIELDS_FAILURE';
export const REMOVE_ORG_CONTACT_CUSTOM_FIELDS_REQUEST = 'Organization-contacts/REMOVE_CUSTOM_FIELDS_REQUEST';
export const REMOVE_ORG_CONTACT_CUSTOM_FIELDS_SUCCESS = 'Organization-contacts/REMOVE_CUSTOM_FIELDS_SUCCESS';
export const REMOVE_ORG_CONTACT_CUSTOM_FIELDS_FAILURE = 'Organization-contacts/REMOVE_CUSTOM_FIELDS_FAILURE';
export const LOAD_ORG_CONTACT_CUSTOM_FIELD_VALUES_REQUEST = 'Organization-contacts/LOAD_CUSTOM_FIELD_VALUES_REQUEST';
export const LOAD_ORG_CONTACT_CUSTOM_FIELD_VALUES_SUCCESS = 'Organization-contacts/LOAD_CUSTOM_FIELD_VALUES_SUCCESS';
export const LOAD_ORG_CONTACT_CUSTOM_FIELD_VALUES_FAILURE = 'Organization-contacts/LOAD_CUSTOM_FIELD_VALUES_FAILURE';
export const UPDATE_ORG_CONTACT_CUSTOM_FIELD_VALUE_REQUEST = 'Organization-contacts/UPDATE_CUSTOM_FIELD_VALUE_REQUEST';
export const UPDATE_ORG_CONTACT_CUSTOM_FIELD_VALUE_SUCCESS = 'Organization-contacts/UPDATE_CUSTOM_FIELD_VALUE_SUCCESS';
export const UPDATE_ORG_CONTACT_CUSTOM_FIELD_VALUE_FAILURE = 'Organization-contacts/UPDATE_CUSTOM_FIELD_VALUE_FAILURE';

/**
 * Person Contact Table Columns
 */

export const LOAD_PERSON_CONTACT_TABLE_COLUMNS_REQUEST = 'people-contacts/LOAD_PERSON_CONTACT_TABLE_COLUMNS_REQUEST';
export const LOAD_PERSON_CONTACT_TABLE_COLUMNS_SUCCESS = 'people-contacts/LOAD_PERSON_CONTACT_TABLE_COLUMNS_SUCCESS';
export const LOAD_PERSON_CONTACT_TABLE_COLUMNS_FAILURE = 'people-contacts/LOAD_PERSON_CONTACT_TABLE_COLUMNS_FAILURE';

export const UPDATE_PERSON_CONTACT_TABLE_COLUMNS_REQUEST = 'people-contacts/UPDATE_PERSON_CONTACT_TABLE_COLUMNS_REQUEST';
export const UPDATE_PERSON_CONTACT_TABLE_COLUMNS_SUCCESS = 'people-contacts/UPDATE_PERSON_CONTACT_TABLE_COLUMNS_SUCCESS';
export const UPDATE_PERSON_CONTACT_TABLE_COLUMNS_FAILURE = 'people-contacts/UPDATE_PERSON_CONTACT_TABLE_COLUMNS_FAILURE';

export const PERSON_CONTACT_OPEN_MANAGE_COLUMN = 'people-contacts/PERSON_CONTACT_OPEN_MANAGE_COLUMN'
export const PERSON_CONTACT_CLOSE_MANAGE_COLUMN = 'people-contacts/PERSON_CONTACT_CLOSE_MANAGE_COLUMN'

/**
 * Org Contact Table Columns
 */

export const LOAD_ORG_CONTACT_TABLE_COLUMNS_REQUEST = 'organization-contacts/LOAD_ORG_CONTACT_TABLE_COLUMNS_REQUEST';
export const LOAD_ORG_CONTACT_TABLE_COLUMNS_SUCCESS = 'organization-contacts/LOAD_ORG_CONTACT_TABLE_COLUMNS_SUCCESS';
export const LOAD_ORG_CONTACT_TABLE_COLUMNS_FAILURE = 'organization-contacts/LOAD_ORG_CONTACT_TABLE_COLUMNS_FAILURE';

export const UPDATE_ORG_CONTACT_TABLE_COLUMNS_REQUEST = 'organization-contacts/UPDATE_ORG_CONTACT_TABLE_COLUMNS_REQUEST';
export const UPDATE_ORG_CONTACT_TABLE_COLUMNS_SUCCESS = 'organization-contacts/UPDATE_ORG_CONTACT_TABLE_COLUMNS_SUCCESS';
export const UPDATE_ORG_CONTACT_TABLE_COLUMNS_FAILURE = 'organization-contacts/UPDATE_ORG_CONTACT_TABLE_COLUMNS_FAILURE';

export const ORG_CONTACT_OPEN_MANAGE_COLUMN = 'organization-contacts/ORG_CONTACT_OPEN_MANAGE_COLUMN'
export const ORG_CONTACT_CLOSE_MANAGE_COLUMN = 'organization-contacts/ORG_CONTACT_CLOSE_MANAGE_COLUMN'