/**
 * Whatsapp action creators
 * @format
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';
export default reducers;

/**
 * Get user's whatsapp association information. Returns null if there's no
 * association
 */
export const getWhatsappAssociation = () => {
  return async dispatch => {
    dispatch({
      type: types.GET_ASSOCIATION_REQUEST,
    });
    let data;
    try {
      const response = await request.get(
        `/api/whatsapp/v1/association`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading information about your whatsapp association. Please try again.';
      dispatch({
        type: types.GET_ASSOCIATION_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.GET_ASSOCIATION_SUCCESS,
        payload: { data: data.data },
      });
      return data.data;
    } else {
      dispatch({
        type: types.GET_ASSOCIATION_FAILURE,
        payload: { message: data.message },
      });
      dispatch(enqueueNotification(data.message));
      const err = new Error(data.message);
      err.code = data.code;
      err.data = data.data;
      throw err;
    }
  };
};

/**
 * Deletes user's whatsapp association information.
 */
export const deleteWhatsappAssociation = () => {
  return async dispatch => {
    dispatch({
      type: types.DELETE_ASSOCIATION_REQUEST,
    });
    let data;
    try {
      const response = await request.del(
        `/api/whatsapp/v1/association`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while removing your whatsapp association. Please try again.';
      dispatch({
        type: types.DELETE_ASSOCIATION_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.DELETE_ASSOCIATION_SUCCESS,
      });
      return data.data;
    } else {
      dispatch({
        type: types.DELETE_ASSOCIATION_FAILURE,
        payload: { message: data.message },
      });
      dispatch(enqueueNotification(data.message));
      const err = new Error(data.message);
      err.code = data.code;
      err.data = data.data;
      throw err;
    }
  };
};

/**
 * Generates challenge code to complete the whatsapp setup.
 */
export const generateChallengeCode = () => {
  return async dispatch => {
    dispatch({
      type: types.GENERATE_CHALLENGE_CODE_REQUEST,
    });
    let data;
    try {
      const response = await request.post(
        `/api/whatsapp/v1/challenge`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while generating your challenge code. Please try again.';
      dispatch({
        type: types.GENERATE_CHALLENGE_CODE_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.GENERATE_CHALLENGE_CODE_SUCCESS,
        payload: { data: data.data },
      });
      return data.data;
    } else {
      dispatch({
        type: types.GENERATE_CHALLENGE_CODE_FAILURE,
        payload: { message: data.message },
      });
      dispatch(enqueueNotification(data.message));
      const err = new Error(data.message);
      err.code = data.code;
      err.data = data.data;
      throw err;
    }
  };
};
