import DefaultTableColumnConfig from './defaultTableColumnsConfig';

export default {
  /**
   * Holds deals, keys are the deal id and values are the deal properties and
   * subresources.
   */
  deals: {},
  /**
   * Holds the number of deals
   */
  dealsCount: '-',
  dealCountWithWorkspaceId:{},
  // Holds the source of the dealcounr, card/ kanban / table
  dealsCountSource: '',
  /**
   * Holds limit and pages of the deals list
   */
  dealsOptions: { limit: 10, page: 1 },
  /**
   * Holds list of deal ids corresponding to deals loaded in deals page
   */
  dealsIdsList: [],
  /**
   * Holds state related to individual deal loading, keys are the deal ids and
   * the values are boolean indicating if it's loading or not. Use `dealsLoading`
   * if you want to check loading status of deal list requests
   */
  dealLoading: {},
  dealListLoadingState: false,
  dealListLoadingError: null,

  batchRequests: 0,

  /**
   * Holds state related to individual deal error, keys are the deal ids and
   * the values are the string in case if there is any error.
   */
  dealError: {},
  /**
   * Holds state related to individual deal category loading, keys are the deal
   * ids and values are boolean indicating if it's loading or not.
   */
  dealCategoryLoading: {},
  /**
   * Holds state related to individual deal category loading error, keys are
   * the deal ids and values are string type holding the error message.
   */
  dealCategoryError: {},
  /**
   * Holds state related to individual deal resource counts loading, keys are
   * the deal ids and values are boolean indicating if it's loading or not.
   */
  dealResourceCountLoading: {},
  /**
   * Holds state related to individual deal resource counts loading error, keys
   * are the deal ids and values are string type holding the error message.
   */
  dealResourceCountError: {},
  /**
   * Holds deals' attachments, comments, conversations, notes counts.
   *
   */
  dealsResourceCount: {},
  dealsResourceCountLoading: false,
  dealsResourceCountError: null,
  /**
   * Holds if for a deal removing external referrer is in progress, keys are
   * the deal ids and values are bool type indicating if the request is in
   * progress.
   */
  removeExternalReferrerLoading: {},
  /**
   * Holds if for a deal removing external referrer has any error, keys are
   * the deal ids and values are string type holding the error message.
   */
  removeExternalReferrerError: {},
  /**
   * Holds if for a deal removing internal referrer is in progress, keys are
   * the deal ids and values are bool type indicating if the request is in
   * progress.
   */
  removeInternalReferrerLoading: {},
  /**
   * Holds if for a deal removing internal referrer has any error, keys are
   * the deal ids and values are string type holding the error message.
   */
  removeInternalReferrerError: {},
  /**
   * Holds the loading state for addigne internal referrer, hashmap with dealId
   */
  addInternalReferrerLoading: {},
  /**
   * Holds the error state for addigne internal referrer, hashmap with dealId
   */
  addInternalReferrerError: {},
  /**
   * Multiple inernal referrers
   */
  addInternalReferrersLoading: {},
  addInternalReferrersError: {},

  associateCategoryLoading: false,
  associateCategoryError: null,

  deassociateCategoryLoading: false,
  deassociateCategoryError: null,

  /**
   * Holds participants if deals, key is deal id
   */
  dealParticipantList: {},
  dealParticipantListLoading: {},
  dealParticipantListError: {},

  updateDealLoading: false,
  updateDealError: null,

  /**
   * List of Events associated with a deal/deal id
   */
  dealEvents: {},
  dealEventsLoading: {},
  dealEventsError: {},
  dealEventsOptions: {},
  dealEventsMore: {},

  /**
   * Holds event, key is event id
   */
  event: {},
  eventLoading: {},
  eventError: {},

  /**
   * Holds deal event notes, key is event is
   */
  dealEventNotes: {},
  dealEventNotesLoading: {},
  dealEventNotesError: {},
  dealEventNotesOptions: {},
  dealEventNotesMore: {},

  addEventNoteLoading: false,
  addEventNoteError: null,

  /**
   * List of Notes associated with a deal/deal id
   */
  dealNotes: {},
  dealNotesLoading: {},
  dealNotesError: {},
  dealNotesOptions: {},
  dealNotesMore: {},

  addNoteLoading: false,
  addNoteError: null,

  /**
   * An individual Note associated with note id
   */
  note: {},
  noteLoading: {},
  noteError: {},


  // TODO: In future, setup pinnedNote state in such a way that it is a hash
  // that holds { deal_id: pinned-not-corresponding-to-deal-id }
  // It currently holds state of the note that is marked as pinned, fetched
  // in the last request, irrespective of deal id
  pinnedNote: null,
  pinnedNoteLoading: false,
  pinnedNoteError: null,

  addFounderLoading: false,
  addFounderError: null,

  addFoundersLoading: false,
  addFoundersError: null,

  removeFounderLoading: false,
  removeFounderError: null,

  /**
   * Holds state associated with converting the last founder to external referrer
   * in any given deal
   * Key is dealId
   */
  convertFounderToExternalReferrerLoading: {},
  convertFounderToExternalReferrerError: {},

  /**
   * Holds list of assignees, key is deal id.
   */
  assigneeList: {},
  assigneeListLoading: {},
  assigneeListError: {},

  addAssigneeLoading: {},
  addAssigneeError: {},

  removeAssigneeLoading: {},
  removeAssigneeError: {},

  addParticipantLoading: false,
  addParticipantError: null,

  removeParticipantLoading: false,
  removeParticipantError: null,

  /**
   * Keeps the suggestions, the structure is as given below-
   * {
   *   <startupName>: {...}
   * }
   */
  suggestions: {},
  suggestionsLoading: false,
  suggestionsError: null,

  /**
   * Holds ids of deals where summary is expanded
   */
  dealSummaryExpanded: {},

  /**
   * Sheet view column configurations
   */
  columns: DefaultTableColumnConfig,
  columnsLoading: false,
  columnsError: null,
  manageColumnOpen: false,

  /**
   * Deal custom fields, these are common for all the deals
   */
  customFields: [],
  customFieldsLoading: false,
  customFieldsError: null,

  /**
   * Add deal loading and error
   */
  addDealLoading: false,
  addDealError: null,

  /**
   * delete deal loading and error
   */
  deleteDealLoading: false,
  deleteDealError: null,

  /**
   * Holds the value for deal table row's settings
   */
  rowSettingsLoading: false,
  rowSettingsError: null,
  rowSettings: {},

  /** convertToPortfolio, keyed by deal id */
  convertToPortfolioLoading: {},
  convertToPortfolioError: {},

  /** unlinkFromPortfolio, keyed by deal id */
  unlinkFromPortfolioLoading: {},
  unlinkFromPortfolioError: {},

  /** ageing / cycle report, keyed by deal id */
  cycleReportLoading: {},
  cycleReports: {},
  cycleReportError: {},

  /** activity logs, keyed by deal id */
  dealActivityLogLoading: {},
  dealActivityLogOptions: {},
  dealActivityLogMore: {},
  dealActivityLogs: {},
  dealActivityLogError: {},

  // Filters made in dealFlow
  filters:{}
};
