/**
 * Custom theme for Taghash
 *
 * Read more: https://material-ui.com/customization/themes/
 * @author Ritesh Shrivastav
 */
import { createMuiTheme } from '@material-ui/core/styles';
import { teal } from '@material-ui/core/colors';
import {
  ScaleTransition,
  SlideTransition,
} from './transition';
// https://fonts.adobe.com/fonts/faricy-new
// https://blogfonts.com/faricynew-bold.font
// logo is in this font
//
// logo's color: #1536f1



const borderRadius = 8; // Default border radius for all components, can be changed later
const buttonTransition = 'all 0.15s ease-in-out';
const buttonActiveScale = '0.98'; // Slight inward press effect
const iconPrimaryColor= '#80838D';
const iconSecondaryColor= '#B9BBC6';
const errorColor= '#CE2C31';
const hoverColor= "#f5f5f5"

export {iconPrimaryColor, iconSecondaryColor, errorColor, hoverColor, borderRadius};


const theme = createMuiTheme({
  grid: 8,
  typography: {
    // Not from the Mui theme. Taken from the design
    default: '#555555',
    taghashTextPrimary:'#529AF8',
    taghashTextTertiary: '#AFAFAF',
    fontWeightMedium: 400,
    fontFamily: 'Inter',
    //This property allows the dev to use this font for building their feature
    //NOTE: The eventual expectation is we override the application's font with this
    /**
     * Example usage
     * styles = theme =>({
     *  btnlabel:{
     *    fontFamily: theme.typography.taghashFontFamily
     *  }
     * })
     */
    taghashFontFamily:'Inter',
    // This is just for better readability
    inter: 'Inter',
  },

  // Finally believing Material Ui allows you to customize anything and everything.
  // https://material-ui.com/customization/globals/#default-props
  // After v3 to v4, by default typography font incresed from 14 to 16px
  // So adding by default adding 14px
  props: {
    // Name of the component ⚛️
    MuiTypography: {
      // The default props to change
      variant: 'body2' // 14px
    }, 
      MuiDialog: {
      TransitionComponent: ScaleTransition, // Smooth scaling for dialogs
    },
    MuiSnackbar: {
      TransitionComponent: SlideTransition, // Slide from right for snackbar
    },
    MuiAlert: {
      TransitionComponent: SlideTransition, // Slide from right for alerts
    }, 
  },
  shape:{
    borderRadius: borderRadius,
  },
  card: {
    borderRadius: borderRadius,
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.4)',
  },
  avatar:{
    background: {
      default: teal[400],
    },
  },
  icon:{
    color:'#80838D'
  },
  palette: {
    //from the figma file (color library): https://www.figma.com/design/PASPQumgJbuYDJan9ti9GS/Colour-Library?node-id=0-1&p=f&t=nOO1EJ7E0B8phcjH-0
    text: {
      primary: '#1C2024',
      secondary: '#717171',
      tertiary: '#AFAFAF', // Same as taghashTextTertiary
      // disabled: '#D8D8D8', //if we override this, in many places such as on deleting custom fields, deleting mis metrics, it will look odd over the error color 
      link: '#006AEB',
      linkHover: '#2D66BA',
      tab: '#717171',
    },
    error: {
    main: '#CE2C31', 
    contrastText: '#fff',
  },
    primary: {
      main: '#379AFF',
      contrastText: '#fff',
    },
    background: {
      default: '#F1F3F5',
      paper: '#FFF',
    },
    mentions: {
      blue: '#d3e1eb',
    },
    grey: {
      // Experimented colors
      25: '#f7f8f9',
      50: '#F1F3F5',
      100: '#555555',
      150: '#E0E0E0',
      200: '#333333',
      250: '#666666',
      300: '#D8D8D8'
    },
    formLabelText: {
      // From the design
      25: '#979797',
      50: '#666666'
    },
    blue: {
      50: '#3E9CFC',
      100: '#3E5063',
      150: '#ECF4FC',
      //Usually used when something is selected
      selected:'#006AEB'
    },
   
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowY: 'scroll !important', // Keep scrollbar always visible
          paddingRight: '0px !important', // supposed to prevent unwanted shifts
        },
      },
    },
  },
overrides: {
  MuiDialog: {
      paper: {
        borderRadius: borderRadius,
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: borderRadius,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: borderRadius,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
      },
    },
    MuiSelect: {
      select: {
        borderRadius: borderRadius,
      },
    },
    MuiAutocomplete: {
      paper: {
        borderRadius: borderRadius,
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: borderRadius,
        padding: '8px 12px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: borderRadius,
        textTransform: 'none', // No uppercase text
        padding: '0.625rem 1rem', // 10px 16px in rem
        transition: buttonTransition,
        '&:hover': {
          transform: 'translateY(-0.015rem)', // Minimal lift
        },
        '&:active': {
          transform: `scale(${buttonActiveScale})`, // Press effect
        },
      },
      contained: {
        backgroundColor: 'theme.palette.primary.main', 
        color: 'theme.palette.primary.contrastText',
        boxShadow: 'none', // No default shadow
        '&:active': {
          boxShadow: 'inset 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2)', // Subtle pressed effect
        },
      },
      outlined: {
        borderColor: 'theme.palette.primary.main',
        color: 'theme.palette.primary.main',
        '&:hover': {
          backgroundColor: hoverColor,
        },
        '&:active': {
          backgroundColor: hoverColor,
        },
      },
      text: {
        color: 'theme.palette.text.primary',
          fontWeight: 500,
        '&:hover': {
          backgroundColor: 'theme.palette.action.hover',
        },
        '&:active': {
          backgroundColor: 'theme.palette.action.selected',
        },
      },
    },

    MuiIconButton: {
      root: {
        borderRadius: borderRadius,
        transition: buttonTransition,
        '&:active': {
          transform: `scale(${buttonActiveScale})`, // Slight inward press
        },
      },
      colorPrimary: {
        color: 'theme.palette.primary.main',
      
      },
      colorSecondary: {
        color: 'theme.palette.secondary.main',
      },
    },

    MuiTextField: {
    root: {
    borderRadius: borderRadius/2,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: borderRadius/2,
      },
      '&:hover fieldset': {
        borderColor: 'theme.palette.primary.main',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '2px',
      },
    },
  },
},
MuiCheckbox: {
  root: {
    borderRadius: borderRadius,
    transition: 'transform 0.1s ease-in-out',
    '&:active': {
      transform: `scale(${buttonActiveScale})`, // Slight inward press effect
    },
    '&.Mui-checked': {
      color: 'theme.palette.primary.main',
    },
  },
},

MuiRadio: {
  root: {
    borderRadius: borderRadius,
    transition: 'transform 0.1s ease-in-out',
    '&:active': {
      transform: `scale(${buttonActiveScale})`,
    },
    '&.Mui-checked': {
      color: 'theme.palette.primary.main',
    },
  },
},
MuiPopper: {
  root: {
    animation: 'fadeIn 0.2s ease-in-out',
    borderRadius: borderRadius,
  },
},

'@global': {
  '@keyframes fadeIn': {
    '0%': { opacity: 0, transform: 'scale(0.95)' },
    '100%': { opacity: 1, transform: 'scale(1)' },
  },
},

  
  },

});

export default theme;
