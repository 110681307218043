import {
  LOAD_DEAL_RESOURCE_COUNT_REQUEST,
  LOAD_DEAL_RESOURCE_COUNT_SUCCESS,
  LOAD_DEAL_RESOURCE_COUNT_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_DEAL_RESOURCE_COUNT_REQUEST:
      return Object.assign({}, state, {
        dealResourceCountLoading: {
          ...state.dealResourceCountLoading,
          [payload.dealId]: true
        },
        dealResourceCountError: {
          ...state.dealResourceCountError,
          [payload.dealId]: null
        },
      });
    case LOAD_DEAL_RESOURCE_COUNT_SUCCESS:
      return Object.assign({}, state, {
        deals: {
          ...state.deals,
          [payload.dealId]: (state.deals[payload.dealId] && {
            ...state.deals[payload.dealId],
            counts: payload.data
          })
        },
        dealResourceCountLoading: {
          ...state.dealResourceCountLoading,
          [payload.dealId]: false
        },
        dealResourceCountError: {
          ...state.dealResourceCountError,
          [payload.dealId]: null
        },
      });
    case LOAD_DEAL_RESOURCE_COUNT_FAILURE:
      return Object.assign({}, state, {
        dealResourceCountLoading: {
          ...state.dealResourceCountLoading,
          [payload.dealId]: false
        },
        dealResourceCountError: {
          ...state.dealResourceCountError,
          [payload.dealId]: payload.message
        },
      });
    default:
      return state;
  }
};
