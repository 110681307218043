import * as types from './actions';

const initialState = {
  noteList: [],
  noteListLoading: false,
  noteListError: null,
  noteListOptions: {
    page: 1,
    limit: 10
  },
  noteListMore: false,

  updateNoteLoading: {},
  updateNoteError: {},

  deleteNoteLoading: {},
  deleteNoteError: {},

  noteReplyList: {},
  noteReplyListLoading: {},
  noteReplyListError: {},
  noteReplyListOptions: {},
  noteReplyListMore: {},

  noteReplyCount: {},
  noteReplyCountLoading: {},
  noteReplyCountError: {},

  transcribeNoteLoading: {},
  transcribeNoteError: {},
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
     * Note list reducers
     */
    case types.LOAD_NOTE_LIST_REQUEST:
      return Object.assign({}, state, {
        noteListLoading: true,
        noteListError: null
      });
    case types.LOAD_NOTE_LIST_SUCCESS:
      return Object.assign({}, state, {
        noteList: ((payload.options.page === 1) ? payload.data : [...state.noteList,...payload.data]),
        noteListLoading: false,
        noteListError: null,
        noteListOptions: payload.options,
        noteListMore: (payload.data.length >= payload.options.limit ? true : false)
      });
    case types.LOAD_NOTE_LIST_FAILURE:
      return Object.assign({}, state, {
        noteListLoading: false,
        noteListError: payload,
      });

    /**
     * Update Deal Note reducers
     */
    case types.UPDATE_DEAL_NOTE_REQUEST:
      return Object.assign({}, state, {
        updateNoteLoading: {
          ...state.updateNoteLoading,
          [payload.noteId]: true
        },
        updateNoteError: {
          ...state.updateNoteError,
          [payload.noteId]: null
        }
      });
    case types.UPDATE_DEAL_NOTE_SUCCESS:
      // create a deep copy of the section of states that are heading for clearance
      const stateForClearingUpdateNoteOnSuccess = Object.assign({}, state, {
        updateNoteLoading: {
          ...state.updateNoteLoading,
        },
        updateNoteError: {
          ...state.updateNoteError,
        }
      });
      // clear the target items
      delete stateForClearingUpdateNoteOnSuccess.updateNoteLoading[payload.noteId];
      delete stateForClearingUpdateNoteOnSuccess.updateNoteError[payload.noteId];
      return stateForClearingUpdateNoteOnSuccess;
    case types.UPDATE_DEAL_NOTE_FAILURE:
      return Object.assign({}, state, {
        updateNoteLoading: {
          ...state.updateNoteLoading,
          [payload.noteId]: false
        },
        updateNoteError: {
          ...state.updateNoteError,
          [payload.noteId]: payload.message
        }
      });

    /**
     * Delete Deal Note reducers
     */
    case types.DELETE_DEAL_NOTE_REQUEST:
      return Object.assign({}, state, {
        deleteNoteLoading: {
          ...state.deleteNoteLoading,
          [payload.noteId]: true
        },
        deleteNoteError: {
          ...state.deleteNoteError,
          [payload.noteId]: null
        }
      });
    case types.DELETE_DEAL_NOTE_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const stateForClearingDeleteNoteOnSuccess = Object.assign({}, state, {
        deleteNoteLoading: {
          ...state.deleteNoteLoading,
        },
        deleteNoteError: {
          ...state.deleteNoteError,
        },
        noteList: state.noteList.filter(dealNote => dealNote.note.id === payload.noteId),
      });
      // clear the target items
      delete stateForClearingDeleteNoteOnSuccess.deleteNoteLoading[payload.noteId];
      delete stateForClearingDeleteNoteOnSuccess.deleteNoteError[payload.noteId];
      return stateForClearingDeleteNoteOnSuccess;
    }
    case types.DELETE_DEAL_NOTE_FAILURE:
      return Object.assign({}, state, {
        deleteNoteLoading: {
          ...state.deleteNoteLoading,
          [payload.noteId]: false
        },
        deleteNoteError: {
          ...state.deleteNoteError,
          [payload.noteId]: payload.message
        }
      });

    case types.LOAD_NOTE_REPLY_LIST_REQUEST:
      return Object.assign({}, state, {
        noteReplyListLoading: {
          ...state.noteReplyListLoading,
          [payload.threadId]: true,
        },
        noteReplyListError: {
          ...state.noteReplyListError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_LIST_SUCCESS:
      return Object.assign({}, state, {
        noteReplyList: {
          ...state.noteReplyList,
          [payload.threadId]:
            payload.options.page === 1
              ? payload.data
              : [
                ...state.noteReplyList[payload.threadId],
                ...payload.data,
              ],
        },
        noteReplyListLoading: {
          ...state.noteReplyListLoading,
          [payload.threadId]: false,
        },
        noteReplyListError: {
          ...state.noteReplyListError,
          [payload.threadId]: null,
        },
        noteReplyListOptions: {
          ...state.noteReplyListOptions,
          [payload.threadId]: payload.options,
        },
        noteReplyListMore: {
          ...state.noteReplyListMore,
          [payload.threadId]:
            payload.metadata.length >= payload.options.limit ? true : false,
        },
        noteReplyCount: {
          ...state.noteReplyCount,
          [payload.threadId]: payload.metadata.length,
        },
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: false,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_LIST_FAILURE:
      return Object.assign({}, state, {
        noteReplyListLoading: {
          ...state.noteReplyListLoading,
          [payload.threadId]: false,
        },
        noteReplyListError: {
          ...state.noteReplyListError,
          [payload.threadId]: payload.message,
        },
      });

    case types.LOAD_NOTE_REPLY_COUNT_REQUEST:
      return Object.assign({}, state, {
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: true,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_COUNT_SUCCESS:
      return Object.assign({}, state, {
        noteReplyCount: {
          ...state.noteReplyCount,
          [payload.threadId]: payload.data.length,
        },
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: false,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_COUNT_FAILURE:
      return Object.assign({}, state, {
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: false,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: payload.message,
        },
      });

    case types.TRANSCRIBE_NOTE_REQUEST:
      return Object.assign({}, state, {
        transcribeNoteLoading: {
          ...state.transcribeNoteLoading,
          [payload.noteId]: true,
        },
        transcribeNoteError: {
          ...state.transcribeNoteError,
          [payload.noteId]: null,
        },
      });
    case types.TRANSCRIBE_NOTE_SUCCESS:
      return Object.assign({}, state, {
        transcribeNoteLoading: {
          ...state.transcribeNoteLoading,
          [payload.noteId]: false,
        },
        transcribeNoteError: {
          ...state.transcribeNoteError,
          [payload.noteId]: null,
        },
      });
    case types.TRANSCRIBE_NOTE_FAILURE:
      return Object.assign({}, state, {
        transcribeNoteLoading: {
          ...state.transcribeNoteLoading,
          [payload.noteId]: false,
        },
        transcribeNoteError: {
          ...state.transcribeNoteError,
          [payload.noteId]: payload.message,
        },
      });

    default:
      return state;
  }
};
