/**
 * Feed action creators
 *
 * @author Ritesh Shrivastav
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const loadFeedList = (options) => {
  return dispatch => {
    dispatch({
      type: types.LOAD_FEED_LIST_REQUEST
    });
    request.get(`/api/v1/holodeck/feeds`, options, dispatch)
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: types.LOAD_FEED_LIST_SUCCESS,
          payload: {
            data: data.data,
            options: options
          }
        });
      } else {
        dispatch({
          type: types.LOAD_FEED_LIST_FAILURE,
          payload: data.message
        });
        dispatch(enqueueNotification(data.message));
      }
    })
    .catch((err) => {
      const message = 'Something went wrong while loading feeds, please try again.';
      dispatch({
        type: types.LOAD_FEED_LIST_FAILURE,
        payload: message
      });
      dispatch(enqueueNotification(message));
    });
  };
};
