/** Actions supported on OrgContact module */
export const GET_ORG_CONTACT_REQUEST = 'orgContact/GET_ORG_CONTACT_REQUEST';
export const GET_ORG_CONTACT_SUCCESS = 'orgContact/GET_ORG_CONTACT_SUCCESS';
export const GET_ORG_CONTACT_FAILURE = 'orgContact/GET_ORG_CONTACT_FAILURE';

export const UPDATE_ORG_CONTACT_REQUEST =
  'orgContact/UPDATE_ORG_CONTACT_REQUEST';
export const UPDATE_ORG_CONTACT_SUCCESS =
  'orgContact/UPDATE_ORG_CONTACT_SUCCESS';
export const UPDATE_ORG_CONTACT_FAILURE =
  'orgContact/UPDATE_ORG_CONTACT_FAILURE';
