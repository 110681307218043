/**
 * Execute a function in the background. Useful when you don't want to block the
 * UI thread for an expensive opeation
 *
 * Ex: API calls on keystrokes
 *
 * @format
 */

export default fn => {
  if (typeof fn !== 'function') {
    return;
  }
  // SetTimeout so it doesn't affect the ui thread
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback
  if ('requestIdleCallback' in window) {
    // requestIdleCallback supported
    requestIdleCallback(fn);
  } else {
    setTimeout(fn);
  }
};
