/**
 * Ageing Report methods
 *
 * @format
 */
import * as types from './actions';
import { property } from 'lodash';
import isEmptyString from '../../helpers/string/isEmptyString';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';
import reducers from './reducers';
export default reducers;

/**
 *
 * @param    {Object} dateRange                    Date range which indicates when to fetch ageing report from
 * @property {String} dateRange.created_at_from    Date to start from
 * @property {String} dateRange.created_at_to      Date to end till
 * @param    {String} workspace_id                 id of the workspace to fetch deals from
 * @param    {Object} options                      Contains pagination info
 * @property {Number} options.page
 * @property {Number} options.limit
 * @returns
 */
export const fetchAgeingReportData = (
  dateRange,
  workspace_id,
  assigneeInvestorIds,
  options
) => async dispatch => {
  if (
    (dateRange && isEmptyString(property('created_at_from')(dateRange))) ||
    (dateRange && isEmptyString(property('created_at_to')(dateRange)))
  ) {
    const message = `Something went wrong when fetching ageing data`;
    dispatch({
      type: types.FETCH_AGEING_REPORT_FAILURE,
      payload: {
        workspace_id,
        message,
      },
    });
    dispatch(enqueueNotification(message));
    throw new Error(message);
  }

  dispatch({
    type: types.FETCH_AGEING_REPORT_REQUEST,
    payload: {
      workspace_id,
    },
  });

  let data;
  try {
    const response = await request.get(`/api/pipeline/ageing_report/v1`, {
      workspace_id,
      ...dateRange,
      assigneeInvestorIds,
      page: options.page,
      limit: options.limit,
    });
    data = response.data;
  } catch (err) {
    dispatch({
      type: types.FETCH_AGEING_REPORT_FAILURE,
      payload: {
        ...dateRange,
        workspace_id,
        message: err.message,
      },
    });

    throw err;
  }

  if (!data.success) {
    dispatch({
      type: types.FETCH_AGEING_REPORT_FAILURE,
      payload: {
        workspace_id,
        message: data.message,
      },
    });

    dispatch(enqueueNotification(data.message));

    throw new Error(data.message);
  }
  dispatch({
    type: types.FETCH_AGEING_REPORT_SUCCESS,
    payload: {
      workspace_id,
      data: data.data.tableData,
    },
  });

  return data.data.tableData;
};

//We fetch this from the workspaces state
export const getWorkspaces = workspaces => dispatch => {
  dispatch({
    type: types.FETCH_WORKSPACES,
    payload: {
      data: workspaces,
    },
  });

  return workspaces;
};

export const switchWorkspace = positionId => dispatch =>
  dispatch({
    type: types.SWITCH_WORKSPACE,
    payload: {
      positionId,
    },
  });
