/**
 * SecurityPolicyPage component
 *
 * @author SK
*/
import DoneIcon from '@material-ui/icons/Done';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Footer from '../Footer';
import green from '@material-ui/core/colors/green';
import HeadManager from '../HeadManager';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PublicPage from '../PublicPage';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {},
  greenColor: { color: green[500] },
  sectionPadding: { paddingTop: theme.spacing(2)},
  subSectionPadding: { paddingTop: theme.spacing(1) },
  linkText: { textDecoration: 'none', marginLeft: theme.spacing(0.5) },
});

class SecurityPolicyPage extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return(
      <PublicPage header="Information Security Policies and Practices">
        <HeadManager title="Information Security Policies and Practices" />
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <Typography variant="body1" gutterBottom>
              The Company is engaged in the providing content and database management
              solutions through its product (hereinafter referred to as “Taghash”)
            </Typography>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <Typography variant="body1" gutterBottom>
              Because Taghash is a hosted Software-as-a-service product,
              we recognize that security is crucial. Keeping customer data safe
              and secure is a huge responsibility and a top priority. We work hard
              to protect our customers from the latest threats. Your input and
              feedback on our security is always appreciated. This page outlines our
              information security policies and practices.
            </Typography>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <div id="data-security-practices" className={classes.sectionPadding}>
              <Typography variant="h5" gutterBottom>
                1. DATA SECURITY PRACTICES
              </Typography>
              <div>
                <Typography variant="h6" gutterBottom>
                  1.1 ACCESS TO ALL TAGHASH SERVERS IS SECURE
                </Typography>
                <List>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <DoneIcon className={classes.greenColor} />
                    </ListItemIcon>
                    <ListItemText>
                      Firewalls on all servers are set to default-deny all
                      incoming network connections.
                    </ListItemText>
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <DoneIcon className={classes.greenColor} />
                    </ListItemIcon>
                    <ListItemText>
                      Database connections are only accepted from other
                      Taghash servers on the internal private subnet
                    </ListItemText>
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <DoneIcon className={classes.greenColor} />
                    </ListItemIcon>
                    <ListItemText>
                      All communication with servers (outside of public
                        HTTP/HTTPS access) is over encrypted secure shell (SSH)
                      and password authentication is disabled. SSH authentication
                      is available only via public/private key authentication
                    </ListItemText>
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <DoneIcon className={classes.greenColor} />
                    </ListItemIcon>
                    <ListItemText>
                      Taghash's servers are hosted on Amazon Web Services (AWS),
                      DigitalOcean, and Google Compute Cloud
                    </ListItemText>
                  </ListItem>
                </List>
              </div>
              <div className={classes.subSectionPadding}>
                <Typography variant="h6" gutterBottom>
                  1.2 SOFTWARE VERSIONS AND SECURITY PATCHES
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We strive to keep all server software on the latest version;
                  however, when that is not possible, we do ensure that the
                  latest security patches are installed and up-to-date.
                </Typography>
              </div>
              <div className={classes.subSectionPadding}>
                <Typography variant="h6" gutterBottom>
                  1.3 DATA IS STORED SECURELY
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Data is hosted on AWS, DigitalOcean, Google Compute Cloud with
                  encryption enabled wherever feasible, both at the
                  infrastructure layer, as well as the application layer, sometimes both.
                </Typography>
              </div>
              <div className={classes.subSectionPadding}>
                <Typography variant="h6" gutterBottom>
                  1.4 ACCESS TO TAGHASH IS SECURE
                </Typography>
                <Typography variant="body1" gutterBottom>
                  All access to Taghash and its APIs is over a secure
                  (SSL encrypted) connection.
                </Typography>
              </div>
              <div className={classes.subSectionPadding}>
                <Typography variant="h6" gutterBottom>
                  1.5 ACCESS IS LOGGED
                </Typography>
                <Typography variant="body1" gutterBottom>
                  All requests are logged and logs are maintained in the system
                  for a meaningful duration of time.
                </Typography>
              </div>
              <div className={classes.subSectionPadding}>
                <Typography variant="h6" gutterBottom>
                  1.6 EMPLOYEE SECURITY
                </Typography>
                <Typography variant="body1" gutterBottom>
                  All employees are required to sign a confidentiality agreement.
                  Employees are not allowed to access unencrypted email content,
                  unless it is shared by users in the form of screenshots or
                  other medium for debugging purposes.
                </Typography>
              </div>
              <div className={classes.subSectionPadding}>
                <Typography variant="h6" gutterBottom>
                  1.7 BACKUP POLICY
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Backups are stored offsite. Taghash performs
                  daily, weekly, and monthly backups of the entire system.
                </Typography>
              </div>
              <div className={classes.subSectionPadding}>
                <Typography variant="h6" gutterBottom>
                  1.8 COOKIES
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Information about what we collect is outlined in our
                  privacy policy at:
                  <Link to="/privacy-policy/v1" className={classes.linkText}>
                    https://taghash.io/privacy-policy/v1
                  </Link>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Cookies are required for normal operation of Taghash.
                </Typography>
              </div>
              <div className={classes.subSectionPadding}>
                <Typography variant="h6" gutterBottom>
                  1.9 TERMS OF USE AND PRIVACY POLICY
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Terms of Use that is agreed to between our users and the Company
                  are defined at:
                  <Link to="/terms-and-conditions/v1" className={classes.linkText}>
                    https://taghash.io/terms-and-conditions/v1
                  </Link>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Information about what we collect and how we handle data is
                  outlined in our privacy policy at:
                  <Link to="/privacy-policy/v1" className={classes.linkText}>
                    https://taghash.io/privacy-policy/v1
                  </Link>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <div id="vulnerability-detection-and-disclosure" className={classes.sectionPadding}>
              <Typography variant="h5" gutterBottom>
                2. VULNERABILITY DETECTION AND DISCLOSURE
              </Typography>
              <div className={classes.subSectionPadding}>
                <Typography variant="h6" gutterBottom>
                  2.1 REPORTING SECURITY PROBLEMS
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Send urgent or sensitive reports directly to
                  <a href="mailto:security AT taghash DOT email" className={classes.linkText}>
                    security AT taghash DOT email
                  </a>.
                  We’ll get back to you as soon as we can, usually within 24 hours.
                  Please follow up if you don’t hear back
                </Typography>
              </div>
              <div className={classes.subSectionPadding}>
                <Typography variant="h6" gutterBottom>
                  2.2 TRACKING AND DISCLOSING SECURITY ISSUES
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We keep ourselves up to date on developments in security research
                  to keep up with the state-of-the-art in web security. Have you
                  discovered a web security flaw that might impact our products?
                  Please let us know. If you submit a report, here’s what will happen:
                </Typography>
                <List>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <FiberManualRecordIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>
                      We’ll acknowledge your report & tell you the best way to
                      track the status of your issue.
                    </ListItemText>
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <FiberManualRecordIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>
                      We’ll investigate the issue and determine how it impacts
                      our products. We won’t disclose issues until our investigation
                      is finished, but we’ll work with you to ensure we fully understand the issue.
                    </ListItemText>
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <FiberManualRecordIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>
                      Once the issue is resolved, we’ll get back to you with thanks
                      and credit for the discovery (and a crispy dosa on us too,
                      if you are around Bengaluru!).
                    </ListItemText>
                  </ListItem>
                </List>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <div id="incident-response-practices" className={classes.sectionPadding}>
              <Typography variant="h5" gutterBottom>
                3. INCIDENT RESPONSE PRACTICES
              </Typography>
              <div className={classes.subSectionPadding}>
                <Typography variant="body1" gutterBottom>
                  An “incident” is any unplanned disruption or degradation of
                  service that is actively affecting customers ability to use Taghash.
                  We follow an organized approach to addressing and managing an incident.
                  The goal isn't just to solve the incident, but to handle the situation
                  in a way that limits damage and reduces recovery time and costs.
                  Our incident response process will be initiated for any
                  major incident. It provides a framework for effectively
                  responding and reaching a fast resolution time. Our incident response
                  process can be triggered one of two ways, either via
                  automated monitoring and alerting, or via human actions.
                </Typography>
              </div>
              <div className={classes.subSectionPadding}>
                <Typography variant="h6" gutterBottom>
                  3.1 AUTOMATED MONITORING
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Throughout our system, we monitor various metrics to determine
                  if our system is functioning according to parameters. Any
                  deviation is reported back to the development team as an alert.
                </Typography>
              </div>
              <div className={classes.subSectionPadding}>
                <Typography variant="h6" gutterBottom>
                  3.2 RESPONSE TO AN INCIDENT
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We have an exhaustive, step-by-step procedure to respond to
                  various types of incidents, ranging from system failures to
                  security breaches. If you want to know more, write to us at
                  <a href="mailto:security AT taghash DOT email" className={classes.linkText}>
                    security AT taghash DOT email
                  </a>.
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </PublicPage>
    )
  }
}

export default withStyles(styles, { withTheme: true })(SecurityPolicyPage);
