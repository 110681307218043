/**
 * @format
 */
import {
  UPDATE_CUSTOM_FIELDS_FAILURE,
  UPDATE_CUSTOM_FIELDS_REQUEST,
  UPDATE_CUSTOM_FIELDS_SUCCESS,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case UPDATE_CUSTOM_FIELDS_REQUEST:
      return Object.assign({}, state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case UPDATE_CUSTOM_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: null,
      });

    case UPDATE_CUSTOM_FIELDS_FAILURE:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: payload,
      });

    default:
      return state;
  }
};
