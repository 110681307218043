/** @format */
// Redux setup for portfolio landing page

import { createAction, createReducer, createAsyncThunk } from '@reduxjs/toolkit'; // prettier-ignore
import request from '../../../helpers/request';

// the initial state for the module
const state = { status: 'idle', companies: [], error: null };

// LoadPage is an async action that loads a new page given its offset and limit
export const LoadPage = createAsyncThunk(
  createAction('portfolio/LOAD_PAGE'),
  async (args, { dispatch }) => {
    const { page, limit } = args;
    let { data } = await request.get(`/api/portfolio/v1/companies`, { page, limit }, dispatch); // prettier-ignore
    return { page, limit, data };
  }
);

// export a reducer for portfolio landing page state
export default createReducer(state, {
  // reducers for when we load a new page
  // ----------------------

  [LoadPage.pending]: state => {
    state.status = 'loading';
  },

  [LoadPage.fulfilled]: (state, { payload }) => {
    state.status = 'idle'; // we go back to idling
    const { page, data } = payload;
    if (page === 1) {
      state.companies = data;
    } else {
      state.companies = state.companies.concat(data);
    }
  },

  [LoadPage.rejected]: (state, { error }) => {
    state.status = 'failed';
    state.error = error;
  },
});
