/**
 * Loads deal table row settings
 */
import request from '../../../helpers/request';
import getTableRowsettingsCache from '../../../helpers/getTableRowsettingsCache';
import setTableRowsettingsCache from '../../../helpers/setTableRowsettingsCache';
import { enqueueNotification } from '../../app';
import {
  LOAD_DEAL_TABLE_ROW_SETTINGS_REQUEST,
  LOAD_DEAL_TABLE_ROW_SETTINGS_SUCCESS,
  LOAD_DEAL_TABLE_ROW_SETTINGS_FAILURE } from '../actions';
import isEmptyString from '../../../helpers/string/isEmptyString'


export default (workspace_id, shouldForceAPIRequest=false) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!shouldForceAPIRequest) {
        const rowSettings = getTableRowsettingsCache(workspace_id);
        if (rowSettings) {
          dispatch({
            type: LOAD_DEAL_TABLE_ROW_SETTINGS_SUCCESS,
            payload: rowSettings
          });
          return resolve(rowSettings);
        }
      }
      if (isEmptyString(workspace_id)) {
        dispatch({
          type: LOAD_DEAL_TABLE_ROW_SETTINGS_FAILURE,
          payload: 'Something went wrong while loading row settings, please try again.'
        });
        return reject(new Error('Something went wrong while loading row settings, please try again.'));
      }
      dispatch({
        type: LOAD_DEAL_TABLE_ROW_SETTINGS_REQUEST
      });
      request.get(`/api/v5/organizations/deal_table_row_settings`, { workspace_id }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: LOAD_DEAL_TABLE_ROW_SETTINGS_SUCCESS,
            payload: data.data
          });
          setTableRowsettingsCache(data.data, workspace_id);
          resolve(data);
        } else {
          dispatch({
            type: LOAD_DEAL_TABLE_ROW_SETTINGS_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading row settings, please try again.';
        dispatch({
          type: LOAD_DEAL_TABLE_ROW_SETTINGS_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
