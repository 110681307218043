/**
 * @format
 */
import {
  DELETE_PORTCO_FAILURE,
  DELETE_PORTCO_REQUEST,
  DELETE_PORTCO_SUCCESS,
} from '../actions';

import { enqueueNotification } from '../../app';
/**
 * archive the portco
 *
 */
import request from '../../../helpers/request';

export default (portcoId, options) => {
  return async dispatch => {
    dispatch({
      type: DELETE_PORTCO_REQUEST,
    });
    let data;
    try {
      const response = await request.del(
        `/api/portfolio/v1/companies/${portcoId}`,
        options,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while deleting portco, please try again.';
      dispatch({
        type: DELETE_PORTCO_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: DELETE_PORTCO_SUCCESS,
        payload: {
          portcoId: portcoId,
        },
      });
      return data;
    } else {
      dispatch({
        type: DELETE_PORTCO_FAILURE,
        payload: {
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
