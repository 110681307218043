/**
 * @format
 */
import React from 'react';
import { Icon } from '@material-ui/core';
import MicrosoftLogoIcon from '../../assets/icons/icons8-microsoft.svg';
import { withStyles } from '@material-ui/styles';

const useStyles = () => ({
  imageIcon: {
    height: '100%',
  },
  iconRoot: {
    textAlign: 'center',
  },
});

const MicrosoftLogo = props => {
  const { classes } = props;
  return (
    <Icon className={{ root: classes.iconRoot }}>
      <img
        className={classes.imageIcon}
        src={MicrosoftLogoIcon}
        alt="microsoft-logo"
      />
    </Icon>
  );
};

export default withStyles(useStyles)(MicrosoftLogo);
