/** @format */
/**
 * State of attatchments currently
 * The `dealAttachments` state is used in indiviudal deal page under documents tab
 * to access the deal's respective attachments
 *
 *
 * The 'deal.attachments' state is used in tableview under attachments column
 * to access the deal's respective attachments
 */
import filter from 'lodash.filter';
import map from 'lodash.map';
import * as types from './actions';

const initialState = {
  /**
   * Holds state related to individual attachment loading, keys are the attachment ids and
   * the values are boolean indicating if it's loading or not.
   */
  attachmentLoading: {},
  /**
   * Holds state related to individual attachment error, keys are the attachment ids and
   * the values are string error messages
   */
  attachmentError: {},

  /**
   * State for uploading attachments to a particular deal
   */
  dealAttachmentUploadLoading: {},
  dealAttachmentUploadError: {},
  /**
   * States for uploading a new attachment
   */
  getNoteAttachmentUploadSignedUrlLoading: false,
  getNoteAttachmentUploadSignedUrlError: null,

  getEmailAttachmentUploadSignedUrlLoading: false,
  getEmailAttachmentUploadSignedUrlError: null,

  /**
   * State for uploading attachments to a particular portco
   */
  portcoAttachmentUploadLoading: {},
  portcoAttachmentUploadError: {},
  /**
   * State for uploading attachments from an update draft of a particular portco
   */
  portcoUpdateAttachentUploadLoading: {},
  portcoUpdateAttachmentUploadError: {},
  /**
   * State for uploading attachments from an email draft of a particular portco
   */
  portcoEmailAttachmentUploadLoading: {},
  portcoEmailAttachmentUploadError: {},
  /**
   * Stores porfolio attachments, keyed by portco_id
   */
  portfolioAttachments: {},
  getPortfolioAttachmentsLoading: {},
  getPortfolioAttachmentsError: {},
  getPortfolioAttachmentsOptions: {},
  getPortfolioAttachmentsMore: {},
  portfolioAttachmentsCount: {},

  /**
   * Stores deal attachments, keyed by deal_id
   */
  dealAttachments: {},
  dealAttachmentsLoading: {},
  dealAttachmentsError: {},
  getDealAttachmentOptions: {},
  getDealAttachmentsMore: {},

  //States when a tag is added to an attachment of a deal
  addDealAttachmentTagLoading: {},
  addDealAttachmentTagError: {},

  //States when a tag is removed from an attachment to a deal
  removeDealAttachmentTagLoading: {},
  removeDealAttachmentTagError: {},
  dealAttachmentTags: [
    //List of tags defined in context of dealflow attachments
  ],
  dealAttachmentTagsLoading: {},
  dealAttachmentsTagsError: {},

  tags: [
    // list of tags defined in context of portfolio attachments
  ],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.EMAIL_ATTACHMENT_GETSIGNEDURL_REQUEST:
    case types.DEAL_ATTACHMENT_GETSIGNEDURL_REQUEST:
    case types.PORTCO_ATTACHMENT_GETSIGNEDURL_REQUEST:
    case types.FILE_UPLOAD_S3_REQUEST:
    case types.FILE_DOWNLOAD_S3_REQUEST:
    case types.DEAL_ATTACHMENT_DELETE_REQUEST:
    case types.HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_REQUEST:
    case types.PORTCO_ATTACHMENT_DELETE_REQUEST:
      return Object.assign({}, state, {
        attachmentLoading: {
          ...state.attachmentLoading,
          [payload.attachmentId]: true,
        },
        attachmentError: {
          ...state.attachmentError,
          [payload.attachmentId]: null,
        },
      });
    case types.EMAIL_ATTACHMENT_GETSIGNEDURL_SUCCESS:
    case types.DEAL_ATTACHMENT_GETSIGNEDURL_SUCCESS:
    case types.PORTCO_ATTACHMENT_GETSIGNEDURL_SUCCESS:
    case types.FILE_UPLOAD_S3_SUCCESS:
    case types.FILE_DOWNLOAD_S3_SUCCESS:
    case types.DEAL_ATTACHMENT_DELETE_SUCCESS:
    case types.HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_SUCCESS:
    case types.PORTCO_ATTACHMENT_DELETE_SUCCESS:
      // create a deep copy of the section of states that are heading for clearance
      const stateForClearingAttachmentOnSuccess = Object.assign({}, state, {
        attachmentLoading: {
          ...state.attachmentLoading,
        },
        attachmentError: {
          ...state.attachmentError,
        },
        dealAttachments: {
          ...state.dealAttachments,
        },
      });
      // clear the target items
      delete stateForClearingAttachmentOnSuccess.attachmentLoading[
        payload.attachmentId
      ];
      delete stateForClearingAttachmentOnSuccess.attachmentError[
        payload.attachmentId
      ];

      if (
        stateForClearingAttachmentOnSuccess &&
        stateForClearingAttachmentOnSuccess.dealAttachments &&
        stateForClearingAttachmentOnSuccess.dealAttachments[payload.dealId]
      ) {
        stateForClearingAttachmentOnSuccess.dealAttachments[
          payload.dealId
        ] = stateForClearingAttachmentOnSuccess.dealAttachments[
          payload.dealId
        ].filter(attachment => attachment.id !== payload.attachmentId);
      }
      return stateForClearingAttachmentOnSuccess;
    case types.EMAIL_ATTACHMENT_GETSIGNEDURL_FAILURE:
    case types.DEAL_ATTACHMENT_GETSIGNEDURL_FAILURE:
    case types.PORTCO_ATTACHMENT_GETSIGNEDURL_FAILURE:
    case types.FILE_UPLOAD_S3_FAILURE:
    case types.FILE_DOWNLOAD_S3_FAILURE:
    case types.DEAL_ATTACHMENT_DELETE_FAILURE:
    case types.HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_FAILURE:
    case types.PORTCO_ATTACHMENT_DELETE_FAILURE:
      return Object.assign({}, state, {
        attachmentLoading: {
          ...state.attachmentLoading,
          [payload.attachmentId]: false,
        },
        attachmentError: {
          ...state.attachmentError,
          [payload.attachmentId]: payload.message,
        },
      });

    case types.DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST:
      return Object.assign({}, state, {
        dealAttachmentUploadLoading: {
          ...state.dealAttachmentUploadLoading,
          [payload.dealId]: true,
        },
        dealAttachmentUploadError: {
          ...state.dealAttachmentUploadError,
          [payload.dealId]: null,
        },
      });
    case types.DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS:
      // create a deep copy of the section of states that are heading for clearance
      const stateForCleaningDeal = Object.assign({}, state, {
        dealAttachmentUploadLoading: {
          ...state.dealAttachmentUploadLoading,
        },
        dealAttachmentUploadError: {
          ...state.dealAttachmentUploadError,
        },
        dealAttachments: {
          ...state.dealAttachments,
          [payload.dealId]:
            // checking if dealattachments present and new dealattachment both are
            //  there only then we are concatinating prev attachments and new attachmments otherwise
            // only returning prev attachment
            state.dealAttachments && state.dealAttachments[payload.dealId]
              ? [
                  ...map(payload.data, 'attachment'),
                  ...state.dealAttachments[payload.dealId],
                ]
              : map(payload.data, 'attachment'),
        },
      });
      // clear the target items
      delete stateForCleaningDeal.dealAttachmentUploadLoading[payload.dealId];
      delete stateForCleaningDeal.dealAttachmentUploadError[payload.dealId];
      return stateForCleaningDeal;
    case types.DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE:
      return Object.assign({}, state, {
        dealAttachmentUploadLoading: {
          ...state.dealAttachmentUploadLoading,
          [payload.dealId]: false,
        },
        dealAttachmentUploadError: {
          ...state.dealAttachmentUploadError,
          [payload.dealId]: payload.message,
        },
      });

    case types.GET_DEAL_ATTACHMENTS_REQUEST:
      return Object.assign({}, state, {
        attachmentLoading: {
          ...state.attachmentLoading,
          [payload.attachmentId]: true,
        },
        attachmentError: {
          ...state.attachmentError,
          [payload.attachmentId]: null,
        },
        dealAttachmentsLoading: {
          ...state.dealAttachmentsLoading,
          [payload.dealId]: true,
        },
        dealAttachmentsError: {
          ...state.dealAttachmentsError,
          [payload.dealId]: null,
        },
      });
    case types.GET_DEAL_ATTACHMENTS_FAILURE:
      return Object.assign({}, state, {
        attachmentLoading: {
          ...state.attachmentLoading,
          [payload.attachmentId]: false,
        },
        attachmentError: {
          ...state.attachmentError,
          [payload.attachmentId]: payload.message,
        },
        dealAttachmentsLoading: {
          ...state.dealAttachmentsLoading,
          [payload.dealId]: false,
        },
        dealAttachmentsError: {
          ...state.dealAttachmentsError,
          [payload.dealId]: payload.message,
        },
      });
    case types.GET_DEAL_ATTACHMENTS_SUCCESS: {
      const dealAttachments = payload.data.filter(a => a && a.id);

      return Object.assign({}, state, {
        attachmentLoading: {
          ...state.attachmentLoading,
          [payload.attachmentId]: false,
        },
        attachmentError: {
          ...state.attachmentError,
          [payload.attachmentId]: null,
        },
        dealAttachmentsLoading: {
          ...state.dealAttachmentsLoading,
          [payload.dealId]: false,
        },
        dealAttachmentsError: {
          ...state.dealAttachmentsError,
          [payload.dealId]: null,
        },
        dealAttachments: {
          ...state.dealAttachments,
          [payload.dealId]:
            payload.options.page === 1
              ? dealAttachments
              : [...state.dealAttachments[payload.dealId], ...dealAttachments],
        },
        getDealAttachmentOptions: {
          ...state.getDealAttachmentOptions,
          [payload.dealId]: payload.options,
        },
        getDealAttachmentsMore: {
          ...state.getDealAttachmentsMore,
          [payload.dealId]: payload.data.length === payload.options.limit,
        },
      });
    }

    case types.DEAL_ATTACHMENT_LIST_TAGS_REQUEST:
      return Object.assign({}, state, {
        dealAttachmentTagsLoading: true,
        dealAttachmentsTagsError: null,
      });
    case types.DEAL_ATTACHMENT_LIST_TAGS_FAILURE:
      return Object.assign({}, state, {
        dealAttachmentTagsLoading: false,
        dealAttachmentsTagsError: payload.message,
      });
    case types.DEAL_ATTACHMENT_LIST_TAGS_SUCCESS:
      return Object.assign({}, state, {
        dealAttachmentTags: payload.tags,
        dealAttachmentTagsLoading: false,
        dealAttachmentsTagsError: null,
      });

    case types.DEAL_ATTACHMENT_ADD_TAG_REQUEST:
      return Object.assign({}, state, {
        addDealAttachmentTagLoading: {
          ...state.addDealAttachmentTagLoading,
          [payload.dealId]: true,
        },
        addDealAttachmentTagError: {
          ...state.addDealAttachmentTagError,
          [payload.dealId]: null,
        },
      });
    case types.DEAL_ATTACHMENT_ADD_TAG_FAILURE:
      return Object.assign({}, state, {
        addDealAttachmentTagLoading: {
          ...state.addDealAttachmentTagLoading,
          [payload.dealId]: false,
        },
        addDealAttachmentTagError: {
          ...state.addDealAttachmentTagError,
          [payload.dealId]: payload.message,
        },
      });
    case types.DEAL_ATTACHMENT_ADD_TAG_SUCCESS:
      return Object.assign({}, state, {
        addDealAttachmentTagLoading: {
          ...state.addDealAttachmentTagLoading,
          [payload.dealId]: false,
        },
        addDealAttachmentTagError: {
          ...state.addDealAttachmentTagError,
          [payload.dealId]: null,
        },
        dealAttachments: {
          ...state.dealAttachments,
          [payload.dealId]: state.dealAttachments[payload.dealId].map(
            attachment => {
              if (attachment.id === payload.attachmentId) {
                attachment.tags = [...attachment.tags, { name: payload.tag }];
              }
              return attachment;
            }
          ),
        },
      });

    case types.DEAL_ATTACHMENT_REMOVE_TAG_REQUEST:
      return Object.assign({}, state, {
        removeDealAttachmentTagError: {
          ...state.removeDealAttachmentTagError,
          [payload.dealId]: null,
        },
        removeDealAttachmentTagLoading: {
          ...state.removeDealAttachmentTagLoading,
          [payload.dealId]: true,
        },
      });
    case types.DEAL_ATTACHMENT_REMOVE_TAG_FAILURE:
      return Object.assign({}, state, {
        removeDealAttachmentTagLoading: {
          ...state.removeDealAttachmentTagLoading,
          [payload.dealId]: false,
        },
        removeDealAttachmentTagError: {
          ...state.removeDealAttachmentTagError,
          [payload.dealId]: payload.message,
        },
      });
    case types.DEAL_ATTACHMENT_REMOVE_TAG_SUCCESS:
      return Object.assign({}, state, {
        removeDealAttachmentTagLoading: {
          ...state.removeDealAttachmentTagLoading,
          [payload.dealId]: false,
        },
        removeDealAttachmentTagError: {
          ...state.removeDealAttachmentTagError,
          [payload.dealId]: null,
        },
        dealAttachments: {
          ...state.dealAttachments,
          [payload.dealId]: map(
            state.dealAttachments[payload.dealId],
            attachment => {
              if (attachment.id === payload.attachmentId) {
                attachment.tags = filter(
                  attachment.tags,
                  tag => tag.name !== payload.tag
                );
              }
              return attachment;
            }
          ),
        },
      });

    case types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST: {
      return Object.assign({}, state, {
        portcoAttachmentUploadLoading: {
          ...state.portcoAttachmentUploadLoading,
          [payload.portcoId]: true,
        },
        portcoAttachmentUploadError: {
          ...state.portcoAttachmentUploadError,
          [payload.portcoId]: null,
        },
      });
    }
    case types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const mergedState = Object.assign({}, state, {
        portcoAttachmentUploadLoading: {
          ...state.portcoAttachmentUploadLoading,
        },
        portcoAttachmentUploadError: {
          ...state.portcoAttachmentUploadError,
        },
        portfolioAttachments: {
          ...state.portfolioAttachments,
          [payload.portcoId]: [
            ...map(payload.data, data => {
              return { ...data.attachment, tags: [] };
            }),
            ...state.portfolioAttachments[payload.portcoId],
          ],
        },
        portfolioAttachmentsCount: {
          ...state.portfolioAttachmentsCount,
          [payload.portcoId]: {
            ...state.portfolioAttachmentsCount[payload.portcoId],
            all:
              state.portfolioAttachmentsCount[payload.portcoId] &&
              state.portfolioAttachmentsCount[payload.portcoId].all
                ? state.portfolioAttachmentsCount[payload.portcoId].all +
                  payload.data.length
                : 1,
          },
        },
      });
      // clear the target items
      delete mergedState.portcoAttachmentUploadLoading[payload.portcoId];
      delete mergedState.portcoAttachmentUploadError[payload.portcoId];
      return mergedState;
    }
    case types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE: {
      return Object.assign({}, state, {
        portcoAttachmentUploadLoading: {
          ...state.portcoAttachmentUploadLoading,
          [payload.portcoId]: false,
        },
        portcoAttachmentUploadError: {
          ...state.portcoAttachmentUploadError,
          [payload.portcoId]: payload.message,
        },
      });
    }

    case types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_UPDATE_REQUEST: {
      return Object.assign({}, state, {
        portcoUpdateAttachentUploadLoading: {
          ...state.portcoUpdateAttachentUploadLoading,
          [payload.portcoId]: true,
        },
        portcoUpdateAttachmentUploadError: {
          ...state.portcoUpdateAttachmentUploadError,
          [payload.portcoId]: null,
        },
      });
    }

    case types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_UPDATE_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const mergedState = Object.assign({}, state, {
        portcoUpdateAttachentUploadLoading: {
          ...state.portcoUpdateAttachentUploadLoading,
        },
        portcoUpdateAttachmentUploadError: {
          ...state.portcoUpdateAttachmentUploadError,
        },
      });
      // clear the target items
      delete mergedState.portcoUpdateAttachentUploadLoading[payload.portcoId];
      delete mergedState.portcoUpdateAttachmentUploadError[payload.portcoId];
      return mergedState;
    }

    case types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_UPDATE_FAILURE: {
      return Object.assign({}, state, {
        portcoUpdateAttachentUploadLoading: {
          ...state.portcoUpdateAttachentUploadLoading,
          [payload.portcoId]: false,
        },
        portcoUpdateAttachmentUploadError: {
          ...state.portcoUpdateAttachmentUploadError,
          [payload.portcoId]: payload.message,
        },
      });
    }

    case types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_EMAIL_REQUEST: {
      return Object.assign({}, state, {
        portcoEmailAttachmentUploadLoading: {
          ...state.portcoEmailAttachmentUploadLoading,
          [payload.portcoId]: true,
        },
        portcoEmailAttachmentUploadError: {
          ...state.portcoEmailAttachmentUploadError,
          [payload.portcoId]: null,
        },
      });
    }

    case types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_EMAIL_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const mergedState = Object.assign({}, state, {
        portcoEmailAttachmentUploadLoading: {
          ...state.portcoEmailAttachmentUploadLoading,
        },
        portcoEmailAttachmentUploadError: {
          ...state.portcoEmailAttachmentUploadError,
        },
      });
      // clear the target items
      delete mergedState.portcoEmailAttachmentUploadLoading[payload.portcoId];
      delete mergedState.portcoEmailAttachmentUploadError[payload.portcoId];
      return mergedState;
    }

    case types.NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST:
      return Object.assign({}, state, {
        getNoteAttachmentUploadSignedUrlLoading: true,
        getNoteAttachmentUploadSignedUrlError: null,
      });
    case types.NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS:
      return Object.assign({}, state, {
        getNoteAttachmentUploadSignedUrlLoading: false,
        getNoteAttachmentUploadSignedUrlError: null,
      });
    case types.NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE:
      return Object.assign({}, state, {
        getNoteAttachmentUploadSignedUrlLoading: false,
        getNoteAttachmentUploadSignedUrlError: payload,
      });

    case types.EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST:
      return Object.assign({}, state, {
        getEmailAttachmentUploadSignedUrlLoading: true,
        getEmailAttachmentUploadSignedUrlError: null,
      });
    case types.EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS:
      return Object.assign({}, state, {
        getEmailAttachmentUploadSignedUrlLoading: false,
        getEmailAttachmentUploadSignedUrlError: null,
      });
    case types.EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE:
      return Object.assign({}, state, {
        getEmailAttachmentUploadSignedUrlLoading: false,
        getEmailAttachmentUploadSignedUrlError: payload,
      });

    case types.ATTACHMENT_DELETE_REQUEST:
      return Object.assign({}, state, {
        attachmentLoading: {
          ...state.attachmentLoading,
          [payload.attachmentId]: true,
        },
        attachmentError: {
          ...state.attachmentError,
          [payload.attachmentId]: null,
        },
      });
    case types.ATTACHMENT_DELETE_SUCCESS:
      return Object.assign({}, state, {
        attachmentLoading: {
          ...state.attachmentLoading,
          [payload.attachmentId]: false,
        },

        attachmentError: {
          ...state.attachmentError,
          [payload.attachmentId]: null,
        },
      });
    case types.ATTACHMENT_DELETE_FAILURE:
      return Object.assign({}, state, {
        attachmentLoading: {
          ...state.attachmentLoading,
          [payload.attachmentId]: false,
        },
        attachmentError: {
          ...state.attachmentError,
          [payload.attachmentId]: payload.message,
        },
      });

    case types.GET_PORTFOLIO_ATTACHMENTS_REQUEST:
      return Object.assign({}, state, {
        getPortfolioAttachmentsLoading: {
          ...state.getPortfolioAttachmentsLoading,
          [payload.portcoId]: true,
        },
        getPortfolioAttachmentsError: {
          ...state.getPortfolioAttachmentsError,
          [payload.portcoId]: null,
        },
      });
    case types.GET_PORTFOLIO_ATTACHMENTS_SUCCESS: {
      const portfolioAttachments = payload.data.filter(
        a => a && a.id && a.file && a.file.id
      );
      return Object.assign({}, state, {
        getPortfolioAttachmentsLoading: {
          ...state.getPortfolioAttachmentsLoading,
          [payload.portcoId]: false,
        },

        getPortfolioAttachmentsError: {
          ...state.getPortfolioAttachmentsError,
          [payload.portcoId]: null,
        },
        portfolioAttachments: {
          ...state.portfolioAttachments,
          [payload.portcoId]:
            payload.options.page === 1
              ? portfolioAttachments
              : [
                  ...state.portfolioAttachments[payload.portcoId],
                  ...portfolioAttachments,
                ],
        },
        getPortfolioAttachmentsOptions: {
          ...state.getPortfolioAttachmentsOptions,
          [payload.portcoId]: payload.options,
        },
        getPortfolioAttachmentsMore: {
          ...state.getPortfolioAttachmentsMore,
          [payload.portcoId]: payload.data.length === payload.options.limit,
        },
        portfolioAttachmentsCount: {
          ...state.portfolioAttachmentsCount,
          [payload.portcoId]: {
            all: payload.metadata.attachmentsCount || 0,
            hidden: payload.metadata.hiddenAttachmentsCount || 0,
          },
        },
      });
    }
    case types.GET_PORTFOLIO_ATTACHMENTS_FAILURE:
      return Object.assign({}, state, {
        getPortfolioAttachmentsLoading: {
          ...state.getPortfolioAttachmentsLoading,
          [payload.portcoId]: false,
        },
        getPortfolioAttachmentsError: {
          ...state.getPortfolioAttachmentsError,
          [payload.portcoId]: payload.message,
        },
      });

    case types.PORTFOLIO_ATTACHMENT_LIST_TAGS:
      return Object.assign({}, state, { tags: payload.tags });

    case types.PORTFOLIO_ATTACHMENT_REMOVE_TAG:
      return Object.assign({}, state, {
        portfolioAttachments: {
          ...state.portfolioAttachments,
          [payload.portcoId]: map(
            state.portfolioAttachments[payload.portcoId],
            attachment => {
              if (attachment.id === payload.attachmentId) {
                attachment.tags = filter(
                  attachment.tags,
                  tag => tag.name !== payload.tag
                );
              }
              return attachment;
            }
          ),
        },
      });

    case types.PORTFOLIO_ATTACHMENT_ADD_TAG:
      return Object.assign({}, state, {
        portfolioAttachments: {
          ...state.portfolioAttachments,
          [payload.portcoId]: map(
            state.portfolioAttachments[payload.portcoId],
            attachment => {
              if (attachment.id === payload.attachmentId) {
                attachment.tags = [...attachment.tags, { name: payload.tag }];
              }
              return attachment;
            }
          ),
        },
      });

    default:
      return state;
  }
};
