/**
 * Delete a sign off group
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  DELETE_SIGN_OFF_GROUP_REQUEST,
  DELETE_SIGN_OFF_GROUP_SUCCESS,
  DELETE_SIGN_OFF_GROUP_FAILURE,
} from '../actions';

export default (sign_off_group_id, deal_id) => {
  return async dispatch => {
    dispatch({
      type: DELETE_SIGN_OFF_GROUP_REQUEST,
      payload: {
        dealId: deal_id,
        signOffGroupId: sign_off_group_id,
      },
    });

    let data;
    try {
      const response = await request.del(
        `/api/pipeline/sign-offs/v1/deal/${deal_id}/sign-off-group/${sign_off_group_id}`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while deleting a sign off group, please try again.';
      dispatch({
        type: DELETE_SIGN_OFF_GROUP_FAILURE,
        payload: {
          dealId: deal_id,
          signOffGroupId: sign_off_group_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: DELETE_SIGN_OFF_GROUP_SUCCESS,
        payload: {
          dealId: deal_id,
          signOffGroupId: sign_off_group_id,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: DELETE_SIGN_OFF_GROUP_FAILURE,
        payload: {
          dealId: deal_id,
          signOffGroupId: sign_off_group_id,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
