/**
 * @format
 */
import {
  UPDATE_CUSTOM_FIELD_VALUE_FAILURE,
  UPDATE_CUSTOM_FIELD_VALUE_REQUEST,
  UPDATE_CUSTOM_FIELD_VALUE_SUCCESS,
} from '../actions';
import { enqueueNotification } from '../../app';
import request from '../../../helpers/request';

export default (form, portcoId) => {
  let loadingMap = {};
  loadingMap[portcoId] = true;
  return async dispatch => {
    dispatch({ type: UPDATE_CUSTOM_FIELD_VALUE_REQUEST, payload: loadingMap });
    let data;
    try {
      const response = await request.put(
        `/api/portfolio-fields/v1/companies/${portcoId}/fields`,
        form
      );
      data = response.data;
    } catch (err) {
      loadingMap[portcoId] = false;
      const message =
        'Something went wrong while updating custom field values, please try again.';
      dispatch({
        type: UPDATE_CUSTOM_FIELD_VALUE_FAILURE,
        payload: { message: message, loadingMap: loadingMap },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      let dataMap = {};
      dataMap[portcoId] = data.data[0];
      loadingMap[portcoId] = false;
      dispatch({
        type: UPDATE_CUSTOM_FIELD_VALUE_SUCCESS,
        payload: { loadingMap: loadingMap, dataMap: dataMap },
      });
      dispatch(
        enqueueNotification('custom fields value updated successfully!')
      );
      return data;
    } else {
      loadingMap[portcoId] = false;
      dispatch({
        type: UPDATE_CUSTOM_FIELD_VALUE_FAILURE,
        payload: { message: data.message, loadingMap: loadingMap },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
