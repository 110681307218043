/**
 * @format
 */
import * as types from './actions';
import initialState from './initialState';
import getPipelineDashboard from './reducerHandlers/getPipelineDashboard';

import loadDashboardsForOrg from './reducerHandlers/loadDashboardsForOrg';

/**
 * Reducers
 */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * List pipeline dashboard reducers
     */
    case types.LOAD_PIPELINE_DASHBOARDS_REQUEST:
    case types.LOAD_PIPELINE_DASHBOARDS_SUCCESS:
    case types.LOAD_PIPELINE_DASHBOARDS_FAILURE:
      return loadDashboardsForOrg(state, type, payload);

    /**
     * GET pipeline dashboard reducers
     */
    case types.GET_PIPELINE_DASHBOARD_REQUEST:
    case types.GET_PIPELINE_DASHBOARD_SUCCESS:
    case types.GET_PIPELINE_DASHBOARD_FAILURE:
      return getPipelineDashboard(state, type, payload);

    default:
      return state;
  }
};
