/**
 * Deal reducers
 *
 * @author Ritesh Shrivastav
 * @author Shrikrishna Holla
 */
import * as types from './actions';

/**
 * Reducer handlers imports
 */
import addAssignee from './reducerHandlers/addAssignee';
import addDeal from './reducerHandlers/addDeal';
import addDealEventNote from './reducerHandlers/addDealEventNote';
import addDealNote from './reducerHandlers/addDealNote';
import addInternalReferrer from './reducerHandlers/addInternalReferrer';
import addInternalReferrers from './reducerHandlers/addInternalReferrers';
import addParticipant from './reducerHandlers/addParticipant';
import addStartupFounders from './reducerHandlers/addStartupFounders';
import addStartupFounder from './reducerHandlers/addStartupFounder';
import associateCategory from './reducerHandlers/associateCategory';
import clearDeals from './reducerHandlers/clearDeals';
import closeManageColumn from './reducerHandlers/closeManageColumn';
import convertFounderToExternalReferrer from './reducerHandlers/convertFounderToExternalReferrer';
import deassociateCategory from './reducerHandlers/deassociateCategory';
import deleteDeal from './reducerHandlers/deleteDeal';
import deleteDealNote from './reducerHandlers/deleteDealNote';
import incrementDealNoteCount from './reducerHandlers/incrementDealNoteCount';
import initialState from './initialState';
import loadAssigneeList from './reducerHandlers/loadAssigneeList';
import loadBatchDeals from './reducerHandlers/loadBatchDeals';
import loadCustomFields from './reducerHandlers/loadCustomFields';
import loadDeal from './reducerHandlers/loadDeal';
import loadDealCategories from './reducerHandlers/loadDealCategories';
import loadDealEvent from './reducerHandlers/loadDealEvent';
import loadDealEventNotes from './reducerHandlers/loadDealEventNotes';
import loadDealEvents from './reducerHandlers/loadDealEvents';
import loadDealList from './reducerHandlers/loadDealList';
import loadDealNote from './reducerHandlers/loadDealNote';
import loadDealNotes from './reducerHandlers/loadDealNotes';
import loadDealParticipants from './reducerHandlers/loadDealParticipants';
import loadDealResourceCounts from './reducerHandlers/loadDealResourceCounts';
import loadDealSuggestions from './reducerHandlers/loadDealSuggestions';
import loadDealTableColumns from './reducerHandlers/loadDealTableColumns';
import loadPinnedDealNote from './reducerHandlers/loadPinnedDealNote';
import openManageColumn from './reducerHandlers/openManageColumn';
import removeAssignee from './reducerHandlers/removeAssignee';
import removeExternalReferrer from './reducerHandlers/removeExternalReferrer';
import removeInternalReferrer from './reducerHandlers/removeInternalReferrer';
import removeParticipant from './reducerHandlers/removeParticipant';
import removeStartupFounder from './reducerHandlers/removeStartupFounder';
import setDealIdList from './reducerHandlers/setDealIdList';
import clearDealIdList from './reducerHandlers/clearDealIdList';
import toggleDealSummary from './reducerHandlers/toggleDealSummary';
import updateDeal from './reducerHandlers/updateDeal';
import updateDealTableColumn from './reducerHandlers/updateDealTableColumn';
import updatePinnedStatus from './reducerHandlers/updatePinnedStatus';
import updateDealNotes from './reducerHandlers/updateDealNotes';
import loadDealsResourceCount from './reducerHandlers/loadDealsResourceCount';
import modifyDealConversationCount from './reducerHandlers/modifyDealConversationCount'
import modifyAttachmentCount from './reducerHandlers/modifyAttachmentCount';
import loadDealTableRowSetting from './reducerHandlers/loadDealTableRowSetting'
import updateDealTableRowSetting from './reducerHandlers/updateDealTableRowSetting'
import convertToPortfolio from './reducerHandlers/convertToPortfolio';
import unlinkFromPortfolio from './reducerHandlers/unlinkFromPortfolio';
import loadCycleReport from './reducerHandlers/loadCycleReport';
import loadActivityLog from './reducerHandlers/loadActivityLog';
import setFilters from './reducerHandlers/setFilters';
/**
 * Reducers
 */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * ADD_ASSIGNEE reducers
     */
    case types.ADD_ASSIGNEE_REQUEST:
    case types.ADD_ASSIGNEE_SUCCESS:
    case types.ADD_ASSIGNEE_FAILURE:
      return addAssignee(state, type, payload);

    /**
     * ADD_DEAL_EVENT_NOTE reducer
     */
    case types.ADD_DEAL_EVENT_NOTE_REQUEST:
    case types.ADD_DEAL_EVENT_NOTE_SUCCESS:
    case types.ADD_DEAL_EVENT_NOTE_FAILURE:
      return addDealEventNote(state, type, payload);

    /**
     * ADD_DEAL_NOTE reducer
     */
    case types.ADD_DEAL_NOTE_REQUEST:
    case types.ADD_DEAL_NOTE_SUCCESS:
    case types.ADD_DEAL_NOTE_FAILURE:
      return addDealNote(state, type, payload);

    /**
     * ADD_PARTICIPANT reducers
     */
    case types.ADD_PARTICIPANT_REQUEST:
    case types.ADD_PARTICIPANT_SUCCESS:
    case types.ADD_PARTICIPANT_FAILURE:
      return addParticipant(state, type, payload);

    /**
     * ADD_STARTUP_FOUNDER reducers
     */
    case types.ADD_STARTUP_FOUNDER_REQUEST:
    case types.ADD_STARTUP_FOUNDER_SUCCESS:
    case types.ADD_STARTUP_FOUNDER_FAILURE:
      return addStartupFounder(state, type, payload);

    /**
     * ADD_STARTUP_FOUNDER reducers
     */
    case types.ADD_STARTUP_FOUNDERS_REQUEST:
    case types.ADD_STARTUP_FOUNDERS_SUCCESS:
    case types.ADD_STARTUP_FOUNDERS_FAILURE:
      return addStartupFounders(state, type, payload);

    /**
     * ASSOCIATE_CATEGORY reducers
     */
    case types.ASSOCIATE_CATEGORY_REQUEST:
    case types.ASSOCIATE_CATEGORY_SUCCESS:
    case types.ASSOCIATE_CATEGORY_FAILURE:
      return associateCategory(state, type, payload);

    /**
     * CLEAR_DEALS reducers
     */
    case types.CLEAR_DEALS:
      return clearDeals(state);

    /**
     * CLOSE_MANAGE_COLUMN reducers
     */
    case types.CLOSE_MANAGE_COLUMN:
      return closeManageColumn(state, type, payload);

    /**
     * CONVERT_FOUNDER_TO_EXTERNAL_REFERRER reducers
     */
    case types.CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_REQUEST:
    case types.CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_SUCCESS:
    case types.CONVERT_FOUNDER_TO_EXTERNAL_REFERRER_FAILURE:
      return convertFounderToExternalReferrer(state, type, payload);

    /**
     * DEASSOCIATE_CATEGORY reducers
     */
    case types.DEASSOCIATE_CATEGORY_REQUEST:
    case types.DEASSOCIATE_CATEGORY_SUCCESS:
    case types.DEASSOCIATE_CATEGORY_FAILURE:
      return deassociateCategory(state, type, payload);

    /**
     * LOAD_ASSIGNEE_LIST reducers
     */
    case types.LOAD_ASSIGNEE_LIST_REQUEST:
    case types.LOAD_ASSIGNEE_LIST_SUCCESS:
    case types.LOAD_ASSIGNEE_LIST_FAILURE:
      return loadAssigneeList(state, type, payload);

    /**
     * LOAD_BATCH_DEAL reducers
     */
    case types.LOAD_BATCH_DEAL_REQUEST:
    case types.LOAD_BATCH_DEAL_SUCCESS:
    case types.LOAD_BATCH_DEAL_FAILURE:
      return loadBatchDeals(state, type, payload);

    /**
     * LOAD_CUSTOM_FIELDS reducers
     */
    case types.LOAD_CUSTOM_FIELDS_REQUEST:
    case types.LOAD_CUSTOM_FIELDS_SUCCESS:
    case types.LOAD_CUSTOM_FIELDS_FAILURE:
      return loadCustomFields(state, type, payload);

    /**
     * LOAD_DEAL reducers
     */
    case types.LOAD_DEAL_REQUEST:
    case types.LOAD_DEAL_SUCCESS:
    case types.LOAD_DEAL_FAILURE:
      return loadDeal(state, type, payload);

    /**
     * LOAD_DEAL_CATEGORY reducers
     */
    case types.LOAD_DEAL_CATEGORY_REQUEST:
    case types.LOAD_DEAL_CATEGORY_SUCCESS:
    case types.LOAD_DEAL_CATEGORY_FAILURE:
      return loadDealCategories(state, type, payload);

    /**
     * LOAD_DEAL_EVENT reducers
     */
    case types.LOAD_DEAL_EVENT_REQUEST:
    case types.LOAD_DEAL_EVENT_SUCCESS:
    case types.LOAD_DEAL_EVENT_FAILURE:
      return loadDealEvent(state, type, payload);

    /**
     * LOAD_DEAL_EVENT_LIST reducers
     */
    case types.LOAD_DEAL_EVENT_LIST_REQUEST:
    case types.LOAD_DEAL_EVENT_LIST_SUCCESS:
    case types.LOAD_DEAL_EVENT_LIST_FAILURE:
      return loadDealEvents(state, type, payload);

    /**
     * LOAD_DEAL_EVENT_NOTE_LIST reducers
     */
    case types.LOAD_DEAL_EVENT_NOTE_LIST_REQUEST:
    case types.LOAD_DEAL_EVENT_NOTE_LIST_SUCCESS:
    case types.LOAD_DEAL_EVENT_NOTE_LIST_FAILURE:
      return loadDealEventNotes(state, type, payload);

    /**
     * LOAD_DEAL_LIST reducers
     */
    case types.LOAD_DEAL_LIST_REQUEST:
    case types.LOAD_DEAL_LIST_SUCCESS:
    case types.LOAD_DEAL_LIST_FAILURE:
      return loadDealList(state, type, payload);

    /**
     * SET_DEAL_ID_LIST reducers
     */
    case types.SET_DEAL_ID_LIST:
      return setDealIdList(state, type, payload);

    /**
     * CLEAR_DEAL_ID_LIST reducers
     */
    case types.CLEAR_DEAL_ID_LIST:
      return clearDealIdList(state, type, payload);

    /**
     * LOAD_DEAL_NOTE reducers
     */
    case types.LOAD_DEAL_NOTE_REQUEST:
    case types.LOAD_DEAL_NOTE_SUCCESS:
    case types.LOAD_DEAL_NOTE_FAILURE:
      return loadDealNote(state, type, payload);

    case types.DELETE_DEAL_NOTE_SUCCESS:
      return deleteDealNote(state, type, payload);
    /**
     * LOAD_DEAL_NOTE_LIST reducers
     */
    case types.LOAD_DEAL_NOTE_LIST_REQUEST:
    case types.LOAD_DEAL_NOTE_LIST_SUCCESS:
    case types.LOAD_DEAL_NOTE_LIST_FAILURE:
      return loadDealNotes(state, type, payload);

    /**
     * LOAD_DEAL_PARTICIPANT_LIST reducers
     */
    case types.LOAD_DEAL_PARTICIPANT_LIST_REQUEST:
    case types.LOAD_DEAL_PARTICIPANT_LIST_SUCCESS:
    case types.LOAD_DEAL_PARTICIPANT_LIST_FAILURE:
      return loadDealParticipants(state, type, payload);

    /**
     * LOAD_DEAL_RESOURCE_COUNT reducers
     */
    case types.LOAD_DEAL_RESOURCE_COUNT_REQUEST:
    case types.LOAD_DEAL_RESOURCE_COUNT_SUCCESS:
    case types.LOAD_DEAL_RESOURCE_COUNT_FAILURE:
      return loadDealResourceCounts(state, type, payload);

    /**
     * LOAD_DEAL_SUGGESTIONS reducer
     */
    case types.LOAD_DEAL_SUGGESTIONS_REQUEST:
    case types.LOAD_DEAL_SUGGESTIONS_SUCCESS:
    case types.LOAD_DEAL_SUGGESTIONS_FAILURE:
      return loadDealSuggestions(state, type, payload);

    /**
     * LOAD_DEAL_TABLE_COLUMNS reducer
     */
    case types.LOAD_DEAL_TABLE_COLUMNS_REQUEST:
    case types.LOAD_DEAL_TABLE_COLUMNS_SUCCESS:
    case types.LOAD_DEAL_TABLE_COLUMNS_FAILURE:
      return loadDealTableColumns(state, type, payload);

    // LOAD_DEAL_FILTERS reducers
    case types.SET_DEAL_FILTERS:
      return setFilters(state, type, payload);
      
    /**
     * OPEN_MANAGE_COLUMN reducers
     */
    case types.OPEN_MANAGE_COLUMN:
      return openManageColumn(state, type, payload);

    /**
     * REMOVE_ASSIGNEE reducers
     */
    case types.REMOVE_ASSIGNEE_REQUEST:
    case types.REMOVE_ASSIGNEE_SUCCESS:
    case types.REMOVE_ASSIGNEE_FAILURE:
      return removeAssignee(state, type, payload);

    /**
     * REMOVE_EXTERNAL_REFERRER reducers
     */
    case types.REMOVE_EXTERNAL_REFERRER_REQUEST:
    case types.REMOVE_EXTERNAL_REFERRER_SUCCESS:
    case types.REMOVE_EXTERNAL_REFERRER_FAILURE:
      return removeExternalReferrer(state, type, payload);

    /**
     * REMOVE_INTERNAL_REFERRER reducers
     */
    case types.REMOVE_INTERNAL_REFERRER_REQUEST:
    case types.REMOVE_INTERNAL_REFERRER_SUCCESS:
    case types.REMOVE_INTERNAL_REFERRER_FAILURE:
      return removeInternalReferrer(state, type, payload);

    /**
     * REMOVE_PARTICIPANT reducers
     */
    case types.REMOVE_PARTICIPANT_REQUEST:
    case types.REMOVE_PARTICIPANT_SUCCESS:
    case types.REMOVE_PARTICIPANT_FAILURE:
      return removeParticipant(state, type, payload);

    /**
     * REMOVE_STARTUP_FOUNDER reducers
     */
    case types.REMOVE_STARTUP_FOUNDER_REQUEST:
    case types.REMOVE_STARTUP_FOUNDER_SUCCESS:
    case types.REMOVE_STARTUP_FOUNDER_FAILURE:
      return removeStartupFounder(state, type, payload);

    /**
     * TOGGLE_DEAL_SUMMARY reducers
     */
    case types.TOGGLE_DEAL_SUMMARY:
      return toggleDealSummary(state, type, payload);

    /**
     * UPDATE reducers
     */
    case types.UPDATE_REQUEST:
    case types.UPDATE_SUCCESS:
    case types.UPDATE_FAILURE:
      return updateDeal(state, type, payload);

    /**
     * UPDATE_DEAL_TABLE_COLUMN reducers
     */
    case types.UPDATE_DEAL_TABLE_COLUMN_REQUEST:
    case types.UPDATE_DEAL_TABLE_COLUMN_SUCCESS:
    case types.UPDATE_DEAL_TABLE_COLUMN_FAILURE:
      return updateDealTableColumn(state, type, payload);

    /**
     * ADD_DEAL reducer
     */
    case types.ADD_DEAL_REQUEST:
    case types.ADD_DEAL_SUCCESS:
    case types.ADD_DEAL_FAILURE:
      return addDeal(state, type, payload);

    case types.INCREMENT_DEAL_NOTE_COUNT:
        return incrementDealNoteCount(state, type, payload);

    case types.ADD_INTERNAL_REFERRER_REQUEST:
    case types.ADD_INTERNAL_REFERRER_SUCCESS:
    case types.ADD_INTERNAL_REFERRER_FAILURE:
        return addInternalReferrer(state, type, payload);

    case types.ADD_INTERNAL_REFERRERS_REQUEST:
    case types.ADD_INTERNAL_REFERRERS_SUCCESS:
    case types.ADD_INTERNAL_REFERRERS_FAILURE:
        return addInternalReferrers(state, type, payload);

    /**
     * LOAD_PINNED_DEAL_NOTE reducers
     */
    case types.LOAD_PINNED_DEAL_NOTE_REQUEST:
    case types.LOAD_PINNED_DEAL_NOTE_SUCCESS:
    case types.LOAD_PINNED_DEAL_NOTE_FAILURE:
      return loadPinnedDealNote(state, type, payload);

    case types.UPDATE_PINNED_STATUS_REQUEST:
    case types.UPDATE_PINNED_STATUS_SUCCESS:
    case types.UPDATE_PINNED_STATUS_FAILURE:
      return updatePinnedStatus(state, type, payload);

    /**
     * ARCHIVE_DEAL reducer
     */
    case types.DELETE_DEAL_REQUEST:
    case types.DELETE_DEAL_SUCCESS:
    case types.DELETE_DEAL_FAILURE:
      return deleteDeal(state, type, payload);

    /**
     * LOAD_DEALS_RESOURCE_COUNT reducer
     */
    case types.LOAD_DEALS_RESOURCE_COUNT_REQUEST:
    case types.LOAD_DEALS_RESOURCE_COUNT_SUCCESS:
    case types.LOAD_DEALS_RESOURCE_COUNT_FAILURE:
      return loadDealsResourceCount(state, type, payload);

    case types.UPDATE_DEAL_NOTES:
        return updateDealNotes(state, type, payload);

    case types.INCREMENT_DEAL_CONVERSATION_COUNT:
    case types.DECREMENT_DEAL_CONVERSATION_COUNT:
        return modifyDealConversationCount(state, type, payload);

    case types.INCREMENT_DEAL_ATTACHMENT_COUNT:
    case types.DECREMENT_DEAL_ATTACHMENT_COUNT:
        return modifyAttachmentCount(state, type, payload);


    case types.LOAD_DEAL_TABLE_ROW_SETTINGS_REQUEST:
    case types.LOAD_DEAL_TABLE_ROW_SETTINGS_SUCCESS:
    case types.LOAD_DEAL_TABLE_ROW_SETTINGS_FAILURE:
      return loadDealTableRowSetting(state, type, payload);


    case types.UPDATE_TBABLE_ROW_SETTING_REQUEST:
    case types.UPDATE_TBABLE_ROW_SETTING_SUCCESS:
    case types.UPDATE_TBABLE_ROW_SETTING_FAILURE:
      return updateDealTableRowSetting(state, type, payload);

    case types.CONVERT_TO_PORTFOLIO_REQUEST:
    case types.CONVERT_TO_PORTFOLIO_SUCCESS:
    case types.CONVERT_TO_PORTFOLIO_FAILURE:
      return convertToPortfolio(state, type, payload);

    case types.UNLINK_FROM_PORTFOLIO_REQUEST:
    case types.UNLINK_FROM_PORTFOLIO_SUCCESS:
    case types.UNLINK_FROM_PORTFOLIO_FAILURE:
      return unlinkFromPortfolio(state, type, payload);

    case types.DEAL_CYCLE_REPORT_REQUEST:
    case types.DEAL_CYCLE_REPORT_SUCCESS:
    case types.DEAL_CYCLE_REPORT_FAILURE:
        return loadCycleReport(state, type, payload);

    case types.GET_DEAL_ACTIVITY_LOG_REQUEST:
    case types.GET_DEAL_ACTIVITY_LOG_SUCCESS:
    case types.GET_DEAL_ACTIVITY_LOG_FAILURE:
        return loadActivityLog(state, type, payload);

    default:
      return state;
  }
};
