/**
 * Update personContact reducer handler
 * @format
 */
import {
  UPDATE_PERSON_REQUEST,
  UPDATE_PERSON_SUCCESS,
  UPDATE_PERSON_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case UPDATE_PERSON_REQUEST:
      return Object.assign({}, state, {
        updatePersonContactLoading: {
          ...state.updatePersonContactLoading,
          [payload.personContactId]: true,
        },
        updatePersonContactError: {
          ...state.updatePersonContactError,
          [payload.personContactId]: null,
        },
      });

    case UPDATE_PERSON_SUCCESS:
      return Object.assign({}, state, {
        people: {
          ...state.people,
          [payload.personContactId]: state.people[payload.personContactId]
            ? {
                ...state.people[payload.personContactId],
                ...payload.data,
                user:
                  // @todo: add validation on structure of startup, and its
                  // internal resources, like founders, addresses etc
                  // Ref nucleus#886
                  payload.data.user
                    ? {
                        ...state.people[payload.personContactId].user,
                        ...payload.data.user,
                      }
                    : state.people[payload.personContactId].user,
                _roles:
                  // array
                  payload.data._roles
                    ? payload.data._roles
                    : state.people[payload.personContactId]._roles,
                organizationContacts:
                  // array
                  payload.data.organizationContacts
                    ? payload.data.organizationContacts
                    : state.people[payload.personContactId]
                        .organizationContacts,
                deals:
                  // array
                  payload.data.deals
                    ? payload.data.deals
                    : state.people[payload.personContactId].deals,
                portcos:
                  // array
                  payload.data.portcos
                    ? payload.data.portcos
                    : state.people[payload.personContactId].portcos,
              }
            : payload.data,
        },
        updatePersonContactLoading: {
          ...state.updatePersonContactLoading,
          [payload.personContactId]: false,
        },
        updatePersonContactError: {
          ...state.updatePersonContactError,
          [payload.personContactId]: null,
        },
      });

    case UPDATE_PERSON_FAILURE:
      return Object.assign({}, state, {
        updatePersonContactLoading: {
          ...state.updatePersonContactLoading,
          [payload.personContactId]: false,
        },
        updatePersonContactError: {
          ...state.updatePersonContactError,
          [payload.personContactId]: payload.error,
        },
      });

    default:
      return state;
  }
};
