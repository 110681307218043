/**
 * Adds a new update in a portfolio company
 *
 * @format
 */

import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';
import isEmptyString from '../../helpers/string/isEmptyString';

export default reducers;

export const addPortfolioUpdate = (portcoId, form) => {
  return async dispatch => {
    dispatch({
      type: types.ADD_PORTFOLIO_UPDATE_REQUEST,
      payload: { portcoId },
    });
    let data;
    try {
      const response = await request.post(
        `/api/portfolio/reporting/v1/updates`,
        {
          ...form,
          portco_id: portcoId,
        },
        dispatch
      );
      data = response.data;
    } catch (err) {
      let message =
        'Something went wrong while adding an update on the portfolio company, please try again or contact support.';
      if (err?.response?.status === 429) {
        message = `Too fast! Try again in a couple of seconds.`;
      }
      dispatch({
        type: types.ADD_PORTFOLIO_UPDATE_FAILURE,
        payload: {
          message,
          portcoId,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.ADD_PORTFOLIO_UPDATE_SUCCESS,
        payload: {
          portcoId,
          data: data,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.ADD_PORTFOLIO_UPDATE_FAILURE,
        payload: {
          portcoId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const getPortfolioUpdateList = (portcoId, options) => {
  return async dispatch => {
    dispatch({
      type: types.GET_PORTFOLIO_UPDATE_LIST_REQUEST,
      payload: { portcoId, options },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/reporting/v1/updates`,
        {
          ...options,
          portco_id: portcoId,
        },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading list of updates on the portfolio company, please try again or contact support.';
      dispatch({
        type: types.GET_PORTFOLIO_UPDATE_LIST_FAILURE,
        payload: {
          message,
          portcoId,
          options,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.GET_PORTFOLIO_UPDATE_LIST_SUCCESS,
        payload: {
          portcoId,
          data: data.data,
          options,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.GET_PORTFOLIO_UPDATE_LIST_FAILURE,
        payload: {
          portcoId,
          message: data.message,
          options,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const getPortfolioUpdate = (updateId, portcoId, options) => {
  return async dispatch => {
    dispatch({
      type: types.GET_PORTFOLIO_UPDATE_REQUEST,
      payload: { portcoId, updateId, options },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/reporting/v1/updates/${updateId}`,
        {
          ...options,
          portco_id: portcoId,
        },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading a portfolio update, please try again or contact support.';
      dispatch({
        type: types.GET_PORTFOLIO_UPDATE_FAILURE,
        payload: {
          message,
          portcoId,
          updateId,
          options,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.GET_PORTFOLIO_UPDATE_SUCCESS,
        payload: {
          portcoId,
          updateId,
          data: data.data,
          options,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.GET_PORTFOLIO_UPDATE_FAILURE,
        payload: {
          portcoId,
          updateId,
          message: data.message,
          options,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
//update pinnedPortfolioStatus

export const updatePinnedPortfolioStatus = (
  update_id,
  portco_id,
  is_pinned
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_PINNED_STATUS_REQUEST,
        payload: { update_id, portco_id },
      });
      request
        .put(
          `/api/portfolio/reporting/v1/updates/${update_id}/pin`,
          { is_pinned, portco_id },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_PINNED_STATUS_SUCCESS,
              payload: {
                update_id,
                portco_id,
                data: data.data,
              },
            });
            resolve(data.data);
          } else {
            dispatch({
              type: types.UPDATE_PINNED_STATUS_FAILURE,
              payload: {
                update_id,
                portco_id,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating note, please try again.';
          dispatch({
            type: types.UPDATE_PINNED_STATUS_FAILURE,
            payload: {
              update_id,
              portco_id,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

// get pinnedPortfolioUpdateStatus

export const getPinnedStatusPortfolioUpdates = (portco_id, options = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GET_PORTCO_PINNNED_UPDATE_REQUEST,
        payload: { portco_id },
      });
      request
        .get(
          `/api/portfolio/reporting/v1/updates/${portco_id}/pin`,
          options,
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.GET_PORTCO_PINNED_UPDATE_SUCCESS,
              payload: {
                data: data.data,
                portco_id,
              },
            });
            resolve(data);
          } else {
            dispatch({
              type: types.GET_PORTCO_PINNED_UPDATE_FAILURE,
              payload: {
                message: data.message,
                portco_id,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading note, please try again.';
          dispatch({
            type: types.GET_PORTCO_PINNED_UPDATE_FAILURE,
            payload: { message, portco_id },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const updatePortfolioUpdate = (updateId, portcoId, form) => {
  return async dispatch => {
    dispatch({
      type: types.UPDATE_PORTFOLIO_UPDATE_REQUEST,
      payload: { updateId },
    });
    if (isEmptyString(updateId) || isEmptyString(portcoId)) return;
    let data;
    try {
      const response = await request.put(
        `/api/portfolio/reporting/v1/updates/${updateId}`,
        {
          ...form,
          portco_id: portcoId,
        },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while updating a portfolio update, please try again or contact support.';
      dispatch({
        type: types.UPDATE_PORTFOLIO_UPDATE_FAILURE,
        payload: {
          message,
          updateId,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.UPDATE_PORTFOLIO_UPDATE_SUCCESS,
        payload: {
          updateId,
          data: data.data,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.UPDATE_PORTFOLIO_UPDATE_FAILURE,
        payload: {
          updateId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const deletePortfolioUpdate = (updateId, portcoId) => {
  return async dispatch => {
    dispatch({
      type: types.DELETE_PORTFOLIO_UPDATE_REQUEST,
      payload: { portcoId, updateId },
    });
    let data;
    try {
      const response = await request.del(
        `/api/portfolio/reporting/v1/updates/${updateId}?portco_id=${portcoId}`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while deleting a portfolio update, please try again or contact support.';
      dispatch({
        type: types.DELETE_PORTFOLIO_UPDATE_FAILURE,
        payload: {
          message,
          portcoId,
          updateId,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.DELETE_PORTFOLIO_UPDATE_SUCCESS,
        payload: {
          portcoId,
          updateId,
        },
      });
      return;
    } else {
      dispatch({
        type: types.DELETE_PORTFOLIO_UPDATE_FAILURE,
        payload: {
          portcoId,
          updateId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const transcribeUpdate = (updateId, portcoId) => {
  return async dispatch => {
    if (!updateId || !portcoId) {
      const message = 'update id or portfolio id missing';
      dispatch({
        type: types.TRANSCRIBE_UPDATE_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.TRANSCRIBE_UPDATE_REQUEST,
      payload: {
        portcoId,
        updateId,
      },
    });
    let data;
    try {
      const response = await request.post(
        `/api/portfolio/reporting/v1/updates/${portcoId}/update/${updateId}/transcribe`,
        {},
        dispatch
      );
      data = await response.data;
    } catch (err) {
      let message =
        'Something went wrong while transcribing note. Please try again.';
      if (err?.response?.status === 429) {
        message = `Too fast! Try again in a couple of seconds.`;
      }
      dispatch({
        type: types.TRANSCRIBE_UPDATE_FAILURE,
        payload: {
          portcoId,
          updateId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.TRANSCRIBE_UPDATE_SUCCESS,
        payload: {
          portcoId,
          updateId,
          data: data.data,
        },
      });
      // update portCoUpdate content as well
      dispatch({
        type: types.GET_PORTFOLIO_UPDATE_SUCCESS,
        payload: {
          portcoId,
          updateId,
          data: data.data,
        },
      });
      dispatch({
        type: types.UPDATE_PORTFOLIO_UPDATE_SUCCESS,
        payload: {
          updateId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.TRANSCRIBE_UPDATE_FAILURE,
        payload: {
          portcoId,
          updateId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
