/**
 * Clear all the prviously loaded deals in state if user visits deal list page
 * 
 * @author Shrikrishna Holla
 */
import { CLEAR_DEALS } from '../actions';

export default () => {
  return dispatch => {
    return new Promise((resolve) => {
      dispatch({
        type: CLEAR_DEALS
      });
      resolve();
    });
  };
};