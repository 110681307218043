/**
 * Deal list related constants
 */
export const DEFAULT_ORDER_BY = 'last_active_at';
export const ALLOWED_ORDER_BY = ['last_active_at', 'created_at','first_email_interaction_date', 'startup_name', 'deal_stage_position_id'];

export const ORDER_BY_MAP = {
  LAST_ACTIVE_AT: 'last_active_at',
  CREATED_AT: 'created_at',
  STARTUP_NAME: 'startup_name',
  DEAL_STAGE_POSITION_ID: 'deal_stage_position_id',
  FIRST_EMAIL_INTERACTION_DATE: 'first_email_interaction_date'
};

export const ORDER_BY_LABEL = {
  [ORDER_BY_MAP.LAST_ACTIVE_AT]: {
    asc: 'Recently active last',
    desc: 'Recently active first'
  },
  [ORDER_BY_MAP.CREATED_AT]: {
    asc: 'Oldest deal first',
    desc: 'Newest deal first'
  },
  [ORDER_BY_MAP.FIRST_EMAIL_INTERACTION_DATE]: {
    asc: 'Oldest to newest',
    desc: 'Newest to oldest'
  },
  [ORDER_BY_MAP.STARTUP_NAME]: {
    asc: 'A - Z',
    desc: 'Z - A'
  },
  [ORDER_BY_MAP.DEAL_STAGE_POSITION_ID]: {
    asc: 'Stage ascending',
    desc: 'Stage descending'
  },
}

export const ALLOWED_SORT_ORDER = ['asc', 'desc'];
export const DEFAULT_SORT_ORDER = 'desc';

export const DEALS_VIEW_TYPE_CARD = 'card';
export const DEALS_VIEW_TYPE_TABLE = 'table';
export const DEALS_VIEW_TYPE_KANBAN = 'kanban';
