import * as types from './actions';

const initialState = {
  blockList: [],
  blocklistLoading: false,
  blocklistError: null,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
     * MagicSync Blocklist reducers
     */
    case types.LOAD_MAGIC_SYNC_BLOCKLIST_REQUEST:
      return Object.assign({}, state, {
        blocklistLoading: true,
        blocklistError: null
      });
    case types.LOAD_MAGIC_SYNC_BLOCKLIST_SUCCESS:
      return Object.assign({}, state, {
        blockList: payload,
        blocklistLoading: false,
      });
    case types.LOAD_MAGIC_SYNC_BLOCKLIST_FAILURE:
      return Object.assign({}, state, {
        blocklistLoading: false,
        blocklistError: payload.message,
      });
    case types.UPDATE_MAGIC_SYNC_BLOCKLIST_REQUEST:
      return Object.assign({}, state, {
        blocklistLoading: true,
        blocklistError: null
      });
    case types.UPDATE_MAGIC_SYNC_BLOCKLIST_SUCCESS:
      return Object.assign({}, state, {
        blockList: payload,
        blocklistLoading: false,
      });
    case types.UPDATE_MAGIC_SYNC_BLOCKLIST_FAILURE:
      return Object.assign({}, state, {
        blocklistLoading: false,
        blocklistError: payload.message,
      });  
    default:
      return state;
  }
};
