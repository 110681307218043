/**
 * Event resource actions
 *
 * @author Ritesh Shrivastav
 */
export const LOAD_EVENT_LIST_REQUEST = 'event/LOAD_EVENT_LIST_REQUEST';
export const LOAD_EVENT_LIST_SUCCESS = 'event/LOAD_EVENT_LIST_SUCCESS';
export const LOAD_EVENT_LIST_FAILURE = 'event/LOAD_EVENT_LIST_FAILURE';

export const LOAD_EVENT_REQUEST = 'event/LOAD_EVENT_REQUEST';
export const LOAD_EVENT_SUCCESS = 'event/LOAD_EVENT_SUCCESS';
export const LOAD_EVENT_FAILURE = 'event/LOAD_EVENT_FAILURE';

export const ASSIGN_TO_DEAL_REQUEST = 'event/ASSIGN_TO_DEAL_REQUEST';
export const ASSIGN_TO_DEAL_SUCCESS = 'event/ASSIGN_TO_DEAL_SUCCESS';
export const ASSIGN_TO_DEAL_FAILURE = 'event/ASSIGN_TO_DEAL_FAILURE';
