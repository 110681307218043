/**
 * Fetches portcos list
 *
 * @format
 */
import request from '../../../helpers/request';
import isEmptyString from '../../../helpers/string/isEmptyString';
import { enqueueNotification } from '../../app';
import {
  GET_PORTCO_REQUEST,
  GET_PORTCO_SUCCESS,
  GET_PORTCO_FAILURE,
} from '../actions';

export default (portcoId, options) => {
  return async dispatch => {
    if (isEmptyString(portcoId)) {
      throw new Error(`Missing portcoId`);
    }
    dispatch({
      type: GET_PORTCO_REQUEST,
      payload: {
        portcoId,
        options: options,
      },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/v1/companies/${portcoId}`,
        options,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading portfolio company, please try again.';
      dispatch({
        type: GET_PORTCO_FAILURE,
        payload: {
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    // dispatch({
    //   type: GET_PORTCO_SUCCESS,
    //   payload: {
    //     portcoId,
    //     data: data,
    //     options: options,
    //   },
    // });
    // return data;
    if (data.success) {
      dispatch({
        type: GET_PORTCO_SUCCESS,
        payload: {
          portcoId,
          data: data.data,
          options: options,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: GET_PORTCO_FAILURE,
        payload: {
          portcoId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
