import * as types from './actions';

const initialState = {
  /**
   * Holds state related to individual startup name update request loading,
   * keys are the startup ids and the values are boolean indicating if it's
   * loading or not.
   */
  startupNameUpdateLoading: {},
  /**
   * Holds state related to individual startup name update error, keys are the
   * startup ids and the values are string error messages
   */
  startupNameUpdateError: {},
  /**
   * Holds state related to individual startup details' update request loading,
   * keys are the startup ids and the values are boolean indicating if it's
   * loading or not.
   */
  startupDetailsUpdateLoading: {},
  /**
   * Holds state related to individual startup details update error, keys are the
   * startup ids and the values are string error messages
   */
  startupDetailsUpdateError: {},
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case types.UPDATE_STARTUP_NAME_REQUEST:
      return Object.assign({}, state, {
        startupNameUpdateLoading: {
          ...state.startupNameUpdateLoading,
          [payload.startup_id]: true
        },
        startupNameUpdateError: {
          ...state.startupNameUpdateError,
          [payload.startup_id]: null
        }
      });
    case types.UPDATE_STARTUP_NAME_SUCCESS:
      return Object.assign({}, state, {
        startupNameUpdateLoading: {
          ...state.startupNameUpdateLoading,
          [payload.startup_id]: false
        },
        startupNameUpdateError: {
          ...state.startupNameUpdateError,
          [payload.startup_id]: null
        }
      });
    case types.UPDATE_STARTUP_NAME_FAILURE:
      return Object.assign({}, state, {
        startupNameUpdateLoading: {
          ...state.startupNameUpdateLoading,
          [payload.startup_id]: false
        },
        startupNameUpdateError: {
          ...state.startupNameUpdateError,
          [payload.startup_id]: payload.message
        }
      });
    case types.UPDATE_STARTUP_DETAILS_REQUEST:
      return Object.assign({}, state, {
        startupDetailsUpdateLoading: {
          ...state.startupDetailsUpdateLoading,
          [payload.startup_id]: true
        },
        startupDetailsUpdateError: {
          ...state.startupDetailsUpdateError,
          [payload.startup_id]: null
        }
      });
    case types.UPDATE_STARTUP_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        startupDetailsUpdateLoading: {
          ...state.startupDetailsUpdateLoading,
          [payload.startup_id]: false
        },
        startupDetailsUpdateError: {
          ...state.startupDetailsUpdateError,
          [payload.startup_id]: null
        }
      });
    case types.UPDATE_STARTUP_DETAILS_FAILURE:
      return Object.assign({}, state, {
        startupDetailsUpdateLoading: {
          ...state.startupDetailsUpdateLoading,
          [payload.startup_id]: false
        },
        startupDetailsUpdateError: {
          ...state.startupDetailsUpdateError,
          [payload.startup_id]: payload.message
        }
      });
    default:
      return state;
  }
};
