/**
 * Action creator to add internal referrer
 */
import {
  ADD_INTERNAL_REFERRERS_REQUEST,
  ADD_INTERNAL_REFERRERS_SUCCESS,
  ADD_INTERNAL_REFERRERS_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case ADD_INTERNAL_REFERRERS_REQUEST:
      return Object.assign({}, state, {
        addInternalReferrersLoading: {
          ...state.addInternalReferrersLoading,
          [payload.dealId]: true
        },
        addInternalReferrersError: {
          ...state.addInternalReferrersError,
          [payload.dealId]: null
        },
      });
    
    case ADD_INTERNAL_REFERRERS_SUCCESS:
      return Object.assign({}, state, {
        deals: {
          ...state.deals,
          [payload.dealId]: (state.deals[payload.dealId] ? {
            ...state.deals[payload.dealId],
            internalReferrers: [...payload.data.internalReferrers]
          } : payload.data)
        },
        addInternalReferrersLoading: {
          ...state.addInternalReferrersLoading,
          [payload.dealId]: false
        },
        addInternalReferrersError: {
          ...state.addInternalReferrersError,
          [payload.dealId]: null
        },
      });
    
    case ADD_INTERNAL_REFERRERS_FAILURE:
      return Object.assign({}, state, {
        addInternalReferrersLoading: {
          ...state.addInternalReferrersLoading,
          [payload.dealId]: false
        },
        addInternalReferrersError: {
          ...state.addInternalReferrersError,
          [payload.dealId]: payload.message
        },
      });

    default:
      return state;
  }
};
