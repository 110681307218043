import * as types from './actions';

const initialState = {
  /**
   * Holds deal stages for current workspace. Namespacing will be needed when we
   * support multiple single user to be part of multiple organizations
   *
   * This here is an object keyed by position_id, which are numbers, so it can be
   * treated both as an object (if you have the position_id), or as an array
   *
   * Warning: If you are planning to iterate through this list, iterate using
   * `for(let key in ob){...}` instead of the regular `for(let i=0;i<length;i++){...}`
   * because there may be indexes with missing values, because of logical bugs on
   * server end that you'll need to check for otherwise
   */
  dealStages: [],
  /**
   * key: workspace id
   * value: array of deal stages
   */
  workspaceDealStages: {},
  /**
   * Holds state related to loading each workspace's deal-stages.
   * key: workspace id
   * value: boolean indicating whether deal stages for the workspace is loading
   * or not
   */
  workspaceDealStagesLoading: {},
  /**
   * Holds state related to error in loading each workspace's deal-stages.
   * key: workspace id
   * value: error if deal stages for the workspace errors out, null otherwise
   */
  workspaceDealStagesError: {},
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    // List workspace deal stages
    case types.LOAD_WORKSPACE_DEAL_STAGE_LIST_REQUEST:
      return Object.assign({}, state, {
        workspaceDealStagesLoading:{
          ...state.workspaceDealStagesLoading,
          [payload.workspaceId]: true,
        },
        workspaceDealStagesError:{
          ...state.workspaceDealStagesError,
          [payload.workspaceId]: null,
        },
      });
    case types.LOAD_WORKSPACE_DEAL_STAGE_LIST_SUCCESS: {
      let dealStages = [...(state.workspaceDealStages[payload.workspaceId] || [])];
      if (payload &&
        payload.workspaceId &&
        payload.data &&
        payload.data instanceof Array &&
        payload.data.length > 0) {
        // map list to dealStages array, keying with position_id
        payload.data.forEach(dealStage => {
          dealStages[dealStage.position_id] = dealStage;
        });
      };
      let newState = Object.assign({}, state, {
        workspaceDealStages: {
          ...state.workspaceDealStages,
          [payload.workspaceId]: dealStages,
        },
        workspaceDealStagesLoading:{
          ...state.workspaceDealStagesLoading,
          [payload.workspaceId]: false,
        },
        workspaceDealStagesError:{
          ...state.workspaceDealStagesError,
          [payload.workspaceId]: null,
        },
      });
      if (!payload.shouldSkipDealStagesState) {
        newState = Object.assign({}, newState, {
          dealStages: dealStages
        });
      }
      return newState;
    }
    case types.LOAD_WORKSPACE_DEAL_STAGE_LIST_FAILURE:
      return Object.assign({}, state, {
        workspaceDealStagesLoading: {
          ...state.workspaceDealStagesLoading,
          [payload.workspaceId]: false,
        },
        workspaceDealStagesError:{
          ...state.workspaceDealStagesError,
          [payload.workspaceId]: payload.message,
        },
      });

    // Update a deal stage
    case types.UPDATE_DEAL_STAGE_REQUEST:
      // Deep clone target deal stage as it is in the current state
      const clonedDealStageForUpdateRequest = {...state.dealStages[payload.position_id]};
      // If the target deal stage is undefined (not present), then do nothing
      if (Object.keys(clonedDealStageForUpdateRequest).length === 0) {
        return state; // this shouldn't happen, really
      }
      // Deep clone second-level props
      clonedDealStageForUpdateRequest.loading = {...clonedDealStageForUpdateRequest.loading};
      clonedDealStageForUpdateRequest.error = {...clonedDealStageForUpdateRequest.error};
      // Update loading/error for the properties being updated
      payload.propertiesToUpdate.forEach(propertyToUpdate => {
        clonedDealStageForUpdateRequest.loading[propertyToUpdate] = true;
        clonedDealStageForUpdateRequest.error[propertyToUpdate] = null;
      });
      // Duplicate the array, placing the updated stage object at its position index
      return Object.assign({}, state, {
        dealStages: [
          ...state.dealStages.slice(0, payload.position_id),
          clonedDealStageForUpdateRequest,
          ...state.dealStages.slice(payload.position_id + 1),
        ]
      });
    case types.UPDATE_DEAL_STAGE_SUCCESS:
      // NOTE: We will not be taking care of re-positioning deal stages even
      // if position is updated with this request. The update in position has
      // to be synced with state by making a separate request to reload deal stage
      // list. If we try to manage that here, we will end up introducing weird,
      // hard-to-debug bugs
      // Deep clone target deal stage as it is in the current state
      const clonedDealStageForUpdateSuccess = {...state.dealStages[payload.position_id]};
      // If the target deal stage is undefined (not present), then do nothing
      if (Object.keys(clonedDealStageForUpdateSuccess).length === 0) {
        return state; // this shouldn't happen, really
      }
      clonedDealStageForUpdateSuccess.loading = {...clonedDealStageForUpdateSuccess.loading};
      clonedDealStageForUpdateSuccess.error = {...clonedDealStageForUpdateSuccess.error};
      // Remove unnecessary props
      payload.propertiesToUpdate.forEach(propertyToUpdate => {
        delete clonedDealStageForUpdateSuccess.loading[propertyToUpdate];
        delete clonedDealStageForUpdateSuccess.error[propertyToUpdate];
      });
      // If there are no keys left after deletion, delete loading and error props too
      if(Object.keys(clonedDealStageForUpdateSuccess.loading).length === 0) {
        delete clonedDealStageForUpdateSuccess.loading;
      }
      if(Object.keys(clonedDealStageForUpdateSuccess.error).length === 0) {
        delete clonedDealStageForUpdateSuccess.error;
      }
      // Duplicate the array, placing the updated stage object at its position index
      return Object.assign({}, state, {
        dealStages: [
          ...state.dealStages.slice(0, payload.position_id),
          clonedDealStageForUpdateSuccess,
          ...state.dealStages.slice(payload.position_id + 1),
        ]
      });
    case types.UPDATE_DEAL_STAGE_FAILURE:
      // Deep clone target deal stage as it is in the current state
      const clonedDealStageForUpdateFailure = {...state.dealStages[payload.position_id]};
      // If the target deal stage is undefined (not present), then do nothing
      if (Object.keys(clonedDealStageForUpdateFailure).length === 0) {
        return state; // this shouldn't happen, really
      }
      // Deep clone second-level props
      clonedDealStageForUpdateFailure.loading = {...clonedDealStageForUpdateFailure.loading};
      clonedDealStageForUpdateFailure.error = {...clonedDealStageForUpdateFailure.error};
      // Update loading/error for the properties being updated
      payload.propertiesToUpdate.forEach(propertyToUpdate => {
        clonedDealStageForUpdateFailure.loading[propertyToUpdate] = false;
        clonedDealStageForUpdateFailure.error[propertyToUpdate] = payload.message;
      });
      // Duplicate the array, placing the updated stage object at its position index
      return Object.assign({}, state, {
        dealStages: [
          ...state.dealStages.slice(0, payload.position_id),
          clonedDealStageForUpdateFailure,
          ...state.dealStages.slice(payload.position_id + 1),
        ]
      });

    // soft-delete a deal stage
    case types.SOFT_DELETE_DEAL_STAGE_REQUEST:
      // Deep clone target deal stage as it is in the current state
      const clonedDealStageForDeleteRequest = {...state.dealStages[payload.position_id]};
      // If the target deal stage is undefined (not present), then do nothing
      if (Object.keys(clonedDealStageForDeleteRequest).length === 0) {
        return state; // this shouldn't happen, really
      }
      clonedDealStageForDeleteRequest.loading = {
        ...clonedDealStageForDeleteRequest.loading,
        softDelete: true,
      };
      clonedDealStageForDeleteRequest.error = {
        ...clonedDealStageForDeleteRequest.error,
        softDelete: null,
      };
      // Duplicate the array, placing the updated stage object at its position index
      return Object.assign({}, state, {
        dealStages: [
          ...state.dealStages.slice(0, payload.position_id),
          clonedDealStageForDeleteRequest,
          ...state.dealStages.slice(payload.position_id + 1),
        ]
      });
    case types.SOFT_DELETE_DEAL_STAGE_SUCCESS:
      // NOTE: We will not be taking care of re-positioning deal stages or
      // marking it unused even though position is updated with this request.
      // The update in its situation has to be synced with state by making a
      // separate request to reload deal stage list. If we try to manage that
      // here, we will end up introducing weird, hard-to-debug bugs
      // Deep clone target deal stage as it is in the current state
      const clonedDealStageForDeleteSuccess = {...state.dealStages[payload.position_id]};
      // If the target deal stage is undefined (not present), then do nothing
      if (Object.keys(clonedDealStageForDeleteSuccess).length === 0) {
        return state; // this shouldn't happen, really
      }
      clonedDealStageForDeleteSuccess.loading = {...clonedDealStageForDeleteSuccess.loading};
      clonedDealStageForDeleteSuccess.error = {...clonedDealStageForDeleteSuccess.error};
      // Remove unnecessary props
      delete clonedDealStageForDeleteSuccess.loading.softDelete;
      delete clonedDealStageForDeleteSuccess.error.softDelete;
      // If there are no keys left after deletion, delete loading and error props too
      if(Object.keys(clonedDealStageForDeleteSuccess.loading).length === 0) {
        delete clonedDealStageForDeleteSuccess.loading;
      }
      if(Object.keys(clonedDealStageForDeleteSuccess.error).length === 0) {
        delete clonedDealStageForDeleteSuccess.error;
      }
      // Duplicate the array, placing the updated stage object at its position index
      return Object.assign({}, state, {
        dealStages: [
          ...state.dealStages.slice(0, payload.position_id),
          clonedDealStageForDeleteSuccess,
          ...state.dealStages.slice(payload.position_id + 1),
        ]
      });
    case types.SOFT_DELETE_DEAL_STAGE_FAILURE:
      // Deep clone target deal stage as it is in the current state
      const clonedDealStageForDeleteFailure = {...state.dealStages[payload.position_id]};
      // If the target deal stage is undefined (not present), then do nothing
      if (Object.keys(clonedDealStageForDeleteFailure).length === 0) {
        return state; // this shouldn't happen, really
      }
      clonedDealStageForDeleteFailure.loading = {
        ...clonedDealStageForDeleteFailure.loading,
        softDelete: false,
      };
      clonedDealStageForDeleteFailure.error = {
        ...clonedDealStageForDeleteFailure.error,
        softDelete: payload.message,
      };
      // Duplicate the array, placing the updated stage object at its position index
      return Object.assign({}, state, {
        dealStages: [
          ...state.dealStages.slice(0, payload.position_id),
          clonedDealStageForDeleteFailure,
          ...state.dealStages.slice(payload.position_id + 1),
        ]
      });
    default:
      return state;
  }
};
