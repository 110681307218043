/**
 * @format
 * Fetches deal list
 *
 * @author Ritesh Shrivastav
 */
import Deal from '../../../components/Deal';
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_DEAL_LIST_REQUEST,
  LOAD_DEAL_LIST_SUCCESS,
  LOAD_DEAL_LIST_FAILURE,
} from '../actions';
import isUndefined from 'lodash/isUndefined';

export default (queryParams, extra = {}) => {
  // This will help us determine where the dealcount is from card, kanban or table view
  const { countSource, workspace_id } = queryParams;
  const filters = Deal.getOpenSearchFilterFormatFromParams(queryParams);
  const { sortBy, sortOrder } = Deal.getOpenSearchSortOptionsFromParams(
    queryParams
  );
  const body = {
    q: queryParams.q,
    contexts: [
      {
        type: 'io.taghash.deal',
        limit: queryParams.limit,
        cursor: queryParams.cursor || [],
        filters,
        sortBy: sortBy,
        sortOrder: sortOrder,
      },
    ],
    withAssignees: true,
    withCreator: true,
    withStage: true,
    withStartup: true,
    withWorkspace: true,
    withCategories: true,
    withAddresses: true,
    withReferrers: true,
    withAttachments: true,
    withFounders: true,
    withPreviousStage: true,
  };
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LOAD_DEAL_LIST_REQUEST,
        payload: {
          options: queryParams,
          extra: extra,
          workspace_id,
        },
      });
      request
        .post(`/api/search/v2/search`, body, dispatch, extra)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: LOAD_DEAL_LIST_SUCCESS,
              payload: {
                data: data.data[0],
                options: queryParams,
                extra: extra,
                metadata: data.data[0].meta,
                dealsCountSource: !isUndefined(countSource) ? countSource : '',
                workspace_id,
              },
            });
            resolve(data);
          } else {
            dispatch({
              type: LOAD_DEAL_LIST_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          if (request.axios.isCancel(err)) {
            return reject(err);
          }
          const message =
            'Something went wrong while loading deals, please try again.';
          dispatch({
            type: LOAD_DEAL_LIST_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
