const getRootCategoryChildren = (categoryList, underName) => {
  if (!categoryList || categoryList.length < 1) return [];
  for (let i = 0; i < categoryList.length; i++) {
    const category = categoryList[i];
    if (category.name === underName) {
      return category.children;
    }
  }
  return [];
};

export default getRootCategoryChildren;
