/**
 * Get portco reducer handler
 * @format
 */
import {
  GET_PORTCO_REQUEST,
  GET_PORTCO_SUCCESS,
  GET_PORTCO_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case GET_PORTCO_REQUEST:
      return Object.assign({}, state, {
        getPortCoLoading: {
          ...state.getPortCoLoading,
          [payload.portcoId]: true,
        },
        getPortCoError: {
          ...state.getPortCoError,
          [payload.portcoId]: null,
        },
      });

    case GET_PORTCO_SUCCESS:
      return Object.assign({}, state, {
        portcos: {
          ...state.portcos,
          [payload.portcoId]: state.portcos[payload.portcoId]
            ? {
                ...state.portcos[payload.portcoId],
                ...payload.data,
                // This 👇 is because internal properties may get updated in between
                // two requests and unless we go to the last level and do a deep clone,
                // we will end up with state not updating even when it should
                attachments:
                  // array
                  payload.data.attachments
                    ? payload.data.attachments
                    : state.portcos[payload.portcoId].attachments,
                categories: payload.data.categories
                  ? payload.data.categories
                  : state.portcos[payload.portcoId].categories,
                stage:
                  // @todo: add validation on structure of internal referrer
                  payload.data.stage
                    ? payload.data.stage
                    : state.portcos[payload.portcoId].stage,
                startup:
                  // @todo: add validation on structure of startup, and its
                  // internal resources, like founders, addresses etc
                  // Ref nucleus#886
                  payload.data.startup
                    ? {
                        ...state.portcos[payload.portcoId].startup,
                        ...payload.data.startup,
                      }
                    : state.portcos[payload.portcoId].startup,
                deal:
                  // @todo: add validation on structure of deal, and its
                  // internal resources
                  payload.data.deal
                    ? {
                        ...state.portcos[payload.portcoId].deal,
                        ...payload.data.deal,
                      }
                    : state.portcos[payload.portcoId].startup,
                _roles:
                  // array
                  payload.data._roles
                    ? payload.data._roles
                    : state.portcos[payload.portcoId]._roles,
              }
            : payload.data,
        },
        getPortCoLoading: {
          ...state.getPortCoLoading,
          [payload.portcoId]: false,
        },
        getPortCoError: {
          ...state.getPortCoError,
          [payload.portcoId]: null,
        },
      });

    case GET_PORTCO_FAILURE:
      return Object.assign({}, state, {
        getPortCoLoading: {
          ...state.getPortCoLoading,
          [payload.portcoId]: false,
        },
        getPortCoError: {
          ...state.getPortCoError,
          [payload.portcoId]: payload.error,
        },
      });

    default:
      return state;
  }
};
