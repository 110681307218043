/**
 * BlockMessage component, use this to display block message.
 *
 * @author Ritesh Shrivastav
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  compact: {
    margin: '0'
  },
  center: {
    textAlign: "center"
  },
  success: {},
  error: {},
});

class BlockMessage extends React.PureComponent {
  static propTypes = {
    align: PropTypes.string,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    compact: PropTypes.bool,
    type: PropTypes.string,
    removeMarginPadding: PropTypes.bool,
  };

  render() {
    const { classes, children, compact, type, align, removeMarginPadding } = this.props;
    return (
      <div className={classNames(
        !removeMarginPadding && classes.root,
        type && classes[type],
        align && classes[align],
        compact && classes.compact
      )}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(BlockMessage);
