/**
 * withActionTracker high order component
 *
 * @format
 */
import React from 'react';
import Track from '../../Track';

export default (Component, category) => props => (
  <Component
    trackAction={(action, label, value) => {
      // SetTimeout so it doesn't affect the ui thread
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback
      if ('requestIdleCallback' in window) {
        // requestIdleCallback supported
        requestIdleCallback(() =>
          Track.event({
            category,
            action,
            label,
            value,
          })
        );
      } else {
        setTimeout(() =>
          Track.event({
            category,
            action,
            label,
            value,
          })
        );
      }
    }}
    {...props}
  />
);
