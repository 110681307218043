export const GET_SHEET_REQUEST = 'sheet/GET_SHEET_REQUEST';
export const GET_SHEET_SUCCESS = 'sheet/GET_SHEET_SUCCESS';
export const GET_SHEET_FAILURE = 'sheet/GET_SHEET_FAILURE';

export const UPDATE_SHEET_REQUEST = 'sheet/UPDATE_SHEET_REQUEST';
export const UPDATE_SHEET_SUCCESS = 'sheet/UPDATE_SHEET_SUCCESS';
export const UPDATE_SHEET_FAILURE = 'sheet/UPDATE_SHEET_FAILURE';

export const GET_SHEET_COLUMNS_REQUEST = 'sheet/GET_SHEET_COLUMNS_REQUEST';
export const GET_SHEET_COLUMNS_SUCCESS = 'sheet/GET_SHEET_COLUMNS_SUCCESS';
export const GET_SHEET_COLUMNS_FAILURE = 'sheet/GET_SHEET_COLUMNS_FAILURE';

export const GET_SHEET_ROWS_REQUEST = 'sheet/GET_SHEET_ROWS_REQUEST';
export const GET_SHEET_ROWS_SUCCESS = 'sheet/GET_SHEET_ROWS_SUCCESS';
export const GET_SHEET_ROWS_FAILURE = 'sheet/GET_SHEET_ROWS_FAILURE';

export const BULK_UPDATE_SHEET_REQUEST = 'sheet/BULK_UPDATE_SHEET_REQUEST';
export const BULK_UPDATE_SHEET_SUCCESS = 'sheet/BULK_UPDATE_SHEET_SUCCESS';
export const BULK_UPDATE_SHEET_FAILURE = 'sheet/BULK_UPDATE_SHEET_FAILURE';

export const REPORT_MIS_20210710_REQUEST = 'sheet/REPORT_MIS_20210710_REQUEST';
export const REPORT_MIS_20210710_SUCCESS = 'sheet/REPORT_MIS_20210710_SUCCESS';
export const REPORT_MIS_20210710_FAILURE = 'sheet/REPORT_MIS_20210710_FAILURE';
export const ADD_SHEET_REQUEST = 'sheet/ADD_SHEET_REQUEST';
export const ADD_SHEET_SUCCESS = 'sheet/ADD_SHEET_SUCCESS';
export const ADD_SHEET_FAILURE = 'sheet/ADD_SHEET_FAILURE';

export const DELETE_SHEET_REQUEST = 'sheet/DELETE_SHEET_REQUEST';
export const DELETE_SHEET_SUCCESS = 'sheet/DELETE_SHEET_SUCCESS';
export const DELETE_SHEET_FAILURE = 'sheet/DELETE_SHEET_FAILURE';

export const ADD_COLUMNS_REQUEST = 'sheet/ADD_COLUMNS_REQUEST';
export const ADD_COLUMNS_SUCCESS = 'sheet/ADD_COLUMNS_SUCCESS';
export const ADD_COLUMNS_FAILURE = 'sheet/ADD_COLUMNS_FAILURE';

export const GET_SHEET_LIST_REQUEST = 'sheet/GET_SHEET_LIST_REQUEST';
export const GET_SHEET_LIST_SUCCESS = 'sheet/GET_SHEET_LIST_SUCCESS';
export const GET_SHEET_LIST_FAILURE = 'sheet/GET_SHEET_LIST_FAILURE';

export const DELETE_SHEET_ROW_REQUEST = 'sheet/DELETE_SHEET_ROW_REQUEST';
export const DELETE_SHEET_ROW_SUCCESS = 'sheet/DELETE_SHEET_ROW_SUCCESS';
export const DELETE_SHEET_ROW_FAILURE = 'sheet/DELETE_SHEET_ROW_FAILURE';

export const CLEAR_SHEETS = 'sheet/CLEAR_SHEETS';
