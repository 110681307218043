/**
 * Adds a new deal
 * 
 * @author Akhila
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  ADD_DEAL_REQUEST,
  ADD_DEAL_SUCCESS,
  ADD_DEAL_FAILURE } from '../actions';

export default (pitchData) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_DEAL_REQUEST,
      });
      request.post(`/api/v3/deals`, pitchData, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: ADD_DEAL_SUCCESS,
            payload: {
              dealId: data.id,
              data: data,
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: ADD_DEAL_FAILURE,
            payload: {
              message: data.message,
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while creating deal, please try again.';
        dispatch({
          type: ADD_DEAL_FAILURE,
          payload: {
            message,
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
