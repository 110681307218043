/**
 * Avatar component, use this when you want to show popover with some hints.
 *
 * @author Ritesh Shrivastav
 */
import classNames from 'classnames';
import DefaultAvatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
    padding: theme.spacing(1),
  },
  avatar: {},
  small: {
    width: 24,
    height: 24,
    fontSize: '12px'
  },
  medium: {
    width: 30,
    height: 30,
    fontSize: '15px'
  }, 
  root: {
    backgroundColor: '#D9D9D9',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '12.8px',
    color: '#000000'
  }
});

class Avatar extends React.PureComponent {
  static propTypes = {
    hint: PropTypes.string,
    classes: PropTypes.object.isRequired,
    size: PropTypes.string,
    imgProps: PropTypes.object,
  };

  render() {
    const { hint, classes, imgProps, size,baseCompClasses,useRootStyle=false, ...others } = this.props;
    const avatarClass = classNames(classes.avatar, size && classes[size]);
    if (!hint) {
      return (
        <DefaultAvatar 
          className={avatarClass}
          // If the avatar has images, we don't want those image loading to block other
          // contents of the page. So loading lazy prop applied. 
          // According to the documentation it's not prduction ready. Experimented
          // in chrome, the images load in the end when loading = 'lazy'.
          imgProps={{
            loading: 'lazy',
            ...imgProps
          }}
          //For passing css styles directly via mui component
          classes={{root: useRootStyle? classes.root: '',...baseCompClasses,}}

          {...others}
        />
      );
    }
    return (
      <Tooltip title={hint} placement="bottom">
        <DefaultAvatar 
          className={avatarClass}
          imgProps={{
            loading: 'lazy',
            ...imgProps
          }}
          //For passing css styles directly via mui component
           classes={{...baseCompClasses,root: classes.root}}
          {...others}
        />
      </Tooltip>
    );
  }
}

export default withStyles(styles)(Avatar);
