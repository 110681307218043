/**
 * This component is only for user avatar on the Navbar, once we click
 * it popups menu which has logout option.
 *
 * @author Akhila
 * @format
 */
import classNames from 'classnames';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deviceWidth from '../../helpers/deviceWidth';
import eventForElement from '../../helpers/eventForElement';
import Grid from '@material-ui/core/Grid';
import IconKeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import React from 'react';
import UserAvatar from '../UserAvatar';
import UserDropdownMenu from './UserDropdownMenu';
import withActionTracker from '../Track/hoc/withActionTracker';
import { Manager, Target } from 'react-popper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    color: '#000',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
      marginLeft: '0px',
    },
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  hideSm: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  clickElement: {
    cursor: 'pointer',
  },
  paper: {
    padding: theme.spacing(1),
  },
  popperClose: {
    pointerEvents: 'none',
  },
  avatar: {
    width: '27px',
    height: '27px',
    fontSize: '14px',
    backgroundColor: deepOrange[500],
  },
  fullname: {
    display: 'inline-block',
  },
  avatarContainer: {
    marginRight: theme.spacing(1),
  },
  fullnameContainer: {
    fontSize: '12px',
    marginRight: theme.spacing(1),
    color: theme.typography.default,
    maxWidth: '66px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  downArrow: {
    width: '16px',
    height: '16px',
  },
});

class UserDropdown extends React.PureComponent {
  static propTypes = {
    me: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    hideSm: PropTypes.bool.isRequired,
    /**
     * Function to track actions
     */
    trackAction: PropTypes.func.isRequired,
  };

  state = {
    clickAvatarEl: null,
    clickAvatarOpen: false,
  };

  static defaultProps = {
    hideSm: true,
  };

  handleToggle = e => {
    // Do not open menu if avatar is not clicked, in other way
    // pop up on avatar is clicked.
    if (eventForElement.isEventOnElement(e, this.target)) {
      this.setState({ clickAvatarOpen: !this.state.clickAvatarOpen });
    }
  };

  handleClose = event => {
    if (this.target.contains(event.target)) {
      return;
    }
    this.setState({ clickAvatarOpen: false });
  };

  render() {
    const { classes, me, logout, hideSm, trackAction } = this.props;
    const { clickAvatarEl, clickAvatarOpen } = this.state;
    const isSmallDevice = deviceWidth.down('sm');
    return (
      <div className={classNames(classes.root, hideSm && classes.hideSm)}>
        <Manager>
          <Target>
            <div
              className={classes.clickElement}
              aria-owns={clickAvatarEl ? 'avatar-menu' : null}
              aria-haspopup="true"
              onClick={this.handleToggle}
              ref={node => {
                this.target = node;
              }}
            >
              <Grid
                container
                direction="row"
                spacing={0}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item className={classes.avatarContainer}>
                  <UserAvatar
                    user={me}
                    className={classes.avatar}
                    hint={null}
                  />
                </Grid>
                {!isSmallDevice && (
                  <Grid item>
                    <IconKeyboardArrowDown className={classes.downArrow} />
                  </Grid>
                )}
              </Grid>
            </div>
          </Target>
          <UserDropdownMenu
            id="avatar-menu"
            anchorEl={clickAvatarEl}
            open={clickAvatarOpen}
            handleToggle={this.handleToggle}
            handleClose={this.handleClose}
            logout={logout}
            logoutText={`Logout (${me.email_primary})`}
            trackAction={trackAction}
          />
        </Manager>
      </div>
    );
  }
}

export default withActionTracker(
  withStyles(styles)(UserDropdown),
  'Navbar.UserDropDown'
);
