import {
  LOAD_BATCH_DEAL_REQUEST,
  LOAD_BATCH_DEAL_SUCCESS,
  LOAD_BATCH_DEAL_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_BATCH_DEAL_REQUEST:
      return Object.assign({}, state, {
        batchRequests: state.batchRequests + 1,
        dealLoading: {
          ...state.dealLoading,
          ...(payload.dealIds.reduce((result, dealId) => {
              result[dealId] = true;
              return result;
            }, {})
          )
        },
        dealError: {
          ...state.dealError,
          ...(payload.dealIds.reduce((result, dealId) => {
              result[dealId] = null;
              return result;
            }, {})
          )
        },
      });
    
    case LOAD_BATCH_DEAL_SUCCESS:
      return Object.assign({}, state, {
        batchRequests: state.batchRequests - 1,
        deals: {
          ...state.deals,
          ...(payload.data.reduce((result, deal) => {
              result[deal.id] = (state.deals[deal.id] ? {
                ...state.deals[deal.id],
                ...deal,
                // This 👇 is because internal properties may get updated in between
                // two requests and unless we go to the last level and do a deep clone,
                // we will end up with state not updating even when it should
                // @todo: add validation on structure of deal, and its
                // internal resources, like startup, assignees, categories etc
                // Ref nucleus#886
                assignees: (deal.assignees ?
                  deal.assignees : state.deals[deal.id].assignees),
                //
                attachments: (deal.attachments ?
                  deal.attachments : state.deals[deal.id].attachments),
                //
                categories: (deal.categories ?
                  deal.categories : state.deals[deal.id].categories),
                //
                creator: (deal.creator ?
                  deal.creator : state.deals[deal.id].creator),
                //
                externalReferrer: (deal.externalReferrer ?
                  deal.externalReferrer : state.deals[deal.id].externalReferrer),
                //
                internalReferrer: (deal.internalReferrer ?
                  deal.internalReferrer : state.deals[deal.id].internalReferrer),
                //
                participants: (deal.participants ?
                  deal.participants : state.deals[deal.id].participants),
                //
                stage: (deal.stage ?
                  deal.stage : state.deals[deal.id].stage),
                //
                _roles: (deal._roles ?
                  deal._roles : state.deals[deal.id]._roles),
                //
                startup: (deal.startup ? {
                  ...state.deals[deal.id].startup,
                  ...deal.startup
                } : state.deals[deal.id].startup),
                  

              } : deal);
              return result;
            }, {})
          )
        },
        dealLoading: {
          ...state.dealLoading,
          ...(payload.dealIds.reduce((result, dealId) => {
              result[dealId] = false;
              return result;
            }, {})
          )
        },
        dealError: {
          ...state.dealError,
          ...(payload.dealIds.reduce((result, dealId) => {
              result[dealId] = null;
              return result;
            }, {})
          )
        },
      });
    
    case LOAD_BATCH_DEAL_FAILURE:
      return Object.assign({}, state, {
        batchRequests: state.batchRequests - 1,
        dealLoading: {
          ...state.dealLoading,
          ...(payload.dealIds.reduce((result, dealId) => {
              result[dealId] = false;
              return result;
            }, {})
          )
        },
        dealError: {
          ...state.dealError,
          ...(payload.dealIds.reduce((result, dealId) => {
              result[dealId] = payload.message;
              return result;
            }, {})
          )
        },
      });

    default:
      return state;
  }
};
