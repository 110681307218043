/** @format */
import reduce from 'lodash.reduce';
import * as types from './actions';

import { assign } from '../../helpers/object';
import filter from 'lodash.filter';
import property from 'lodash.property';
import { isArray } from 'lodash';
import defaultPersonContactTableViewColumnsConfig from './defaultPersonContactTableViewColumnsConfig';
import defaultOrgContactTableViewColumnsConfig from './defaultOrgContactTableViewColumnsConfig';
import map from 'lodash.map';

const initial = {
  organizationContacts: {},
  organizationContactsLoading: false,
  organizationContactsError: null,
  addOrganizationContactsLoading: false,
  addOrganizationContactsError: null,
  organizationContactsTags: [
    //List of all tags defined in the context of orgContacts
  ],
  organizationContactsTagsLoading: false,
  organizationContactsTagsError: null,

  organizationContactMembers: {},
  organizationContactMembersLoading: false,
  organizationContactMembersError: null,

  noOfAssociatedDealsAndPortcosOrgContact: {},
  associatedDealsAndPorcosOrgContact: {},
  associatedDealsAndPorcosOrgContactLoading: false,
  associatedDealsAndPorcosOrgContactError: null,

  peopleContacts: {},
  peopleContactsLoading: false,
  peopleContactsError: null,
  addPeopleContactsLoading: false,
  addPeopleContactsError: null,
  peopleContactsTags: [
    //List of all tags defined in the context of peopleContacts
  ],
  peopleContactsTagsLoading: false,
  peopleContactsTagsError: null,
  associatedTeamMembers: {},
  associatedTeamMembersLoading: false,
  associatedTeamMembersError: null,
  noOfAssociatedTeamMembers: null,
  noOfAssociatedDealsAndPortcosPersonContact: {},
  associatedDealsAndPorcosPersonContact: {},
  associatedDealsAndPorcosPersonContactLoading: false,
  associatedDealsAndPorcosPersonContactError: null,

  noOfPersonContactInteractions: null,
  personContactInteractions: {},
  personContactInteractionsLoading: false,
  personContactInteractionsError: null,

  // Custom Fields
  customFields: [],
  customFieldsLoading: false,
  customFieldsError: null,
  customFieldValues: {},
  customFieldValuesLoading: {},
  customFieldValuesError: null,

  // Organization Contact Custom Fields
  OrgCustomFields: [],
  OrgCustomFieldsLoading: false,
  OrgCustomFieldsError: null,
  OrgCustomFieldValues: {},
  OrgCustomFieldValuesLoading: {},
  OrgCustomFieldValuesError: null,

  //Table view configuration for Person Contact Table View
  personContactColumnsLoading: false,
  personContactColumnsError: null,
  personContactColumns: defaultPersonContactTableViewColumnsConfig,

  //Table view configuration for Org Contact View
  orgContactColumnsLoading: false,
  orgContactColumnsError: null,
  orgContactColumns: defaultOrgContactTableViewColumnsConfig,
};

export default function(state = initial, { type, payload }) {
  switch (type) {
    case types.LOAD_ORGANIZATION_CONTACTS_LIST_REQUEST: {
      return assign(state, {
        organizationContactsLoading: true,
        organizationContactsError: null,
      });
    }
    case types.LOAD_ORGANIZATION_CONTACTS_LIST_FAILURE: {
      return assign(state, {
        organizationContactsLoading: false,
        organizationContactsError: payload.message,
      });
    }
    case types.LOAD_ORGANIZATION_CONTACTS_LIST_SUCCESS: {
      const organizationContacts = reduce(
        payload.data,
        function(organizationContacts, organizationContactObj) {
          organizationContacts[
            organizationContactObj.id
          ] = organizationContactObj;
          return organizationContacts;
        },
        {}
      );

      return assign(state, {
        organizationContactsLoading: false,
        organizationContactsError: null,

        noOfOrgContacts: payload.noOfOrgContacts,
        organizationContacts: assign(
          state.organizationContacts,
          organizationContacts
        ),
      });
    }

    //Load Custom Fields
    case types.LOAD_CUSTOM_FIELDS_REQUEST:
      return Object.assign({}, state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case types.LOAD_CUSTOM_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: null,
        customFields: payload,
      });

    case types.LOAD_CUSTOM_FIELDS_FAILURE:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: payload.message,
      });

    case types.LOAD_CUSTOM_FIELD_VALUES_REQUEST: {
      let loadingMap = {};
      let personContactArray = isArray(payload.personContactId)
        ? payload.personContactId
        : [payload.personContactId];
      map(personContactArray, contactId => {
        loadingMap[contactId] = true;
      });

      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...loadingMap,
        },
        customFieldValuesError: null,
      });
    }

    case types.LOAD_CUSTOM_FIELD_VALUES_SUCCESS: {
      let dataMap = {};
      let loadingMap = {};
      map(payload.data, contact => {
        dataMap[contact.id] = contact;
        loadingMap[contact.id] = false;
      });

      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...loadingMap,
        },
        customFieldValues: {
          ...state.customFieldValues,
          ...dataMap,
        },
        customFieldValuesError: null,
      });
    }

    case types.LOAD_CUSTOM_FIELD_VALUES_FAILURE: {
      let loadingMap = {};
      let personContactArray = isArray(payload.personContactId)
        ? payload.personContactId
        : [payload.personContactId];
      map(personContactArray, contactId => {
        loadingMap[contactId] = false;
      });

      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...loadingMap,
        },
        customFieldValuesError: payload.message,
      });
    }

    case types.UPDATE_CUSTOM_FIELD_VALUE_REQUEST:
      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...payload,
        },
        customFieldsError: null,
      });
    case types.UPDATE_CUSTOM_FIELD_VALUE_SUCCESS:
      return Object.assign({}, state, {
        customFieldsError: null,
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...payload.loadingMap,
        },
        customFieldValues: {
          ...state.customFieldValues,
          ...payload.dataMap,
        },
      });

    case types.UPDATE_CUSTOM_FIELD_VALUE_FAILURE:
      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...payload.loadingMap,
        },
        customFieldsError: payload.message,
      });

    case types.UPDATE_CUSTOM_FIELDS_REQUEST:
      return Object.assign({}, state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case types.UPDATE_CUSTOM_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: null,
      });

    case types.UPDATE_CUSTOM_FIELDS_FAILURE:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: payload,
      });

    case types.REMOVE_CUSTOM_FIELDS_REQUEST:
      return Object.assign({}, state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case types.REMOVE_CUSTOM_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: null,
      });

    case types.REMOVE_CUSTOM_FIELDS_FAILURE:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: payload,
      });

    case types.ADD_CUSTOM_FIELD_REQUEST:
      return Object.assign({}, state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case types.ADD_CUSTOM_FIELD_SUCCESS:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFields: payload,
        customFieldsError: null,
      });

    case types.ADD_CUSTOM_FIELD_FAILURE:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: payload,
      });

    //Load Organization Contact Custom Fields
    case types.LOAD_ORG_CONTACT_CUSTOM_FIELDS_REQUEST:
      return Object.assign({}, state, {
        OrgCustomFieldsLoading: true,
        OrgCustomFieldsError: null,
      });

    case types.LOAD_ORG_CONTACT_CUSTOM_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        OrgCustomFieldsLoading: false,
        OrgCustomFieldsError: null,
        OrgCustomFields: payload,
      });

    case types.LOAD_ORG_CONTACT_CUSTOM_FIELDS_FAILURE:
      return Object.assign({}, state, {
        OrgCustomFieldsLoading: false,
        OrgCustomFieldsError: payload.message,
      });

    case types.LOAD_ORG_CONTACT_CUSTOM_FIELD_VALUES_REQUEST: {
      let loadingMap = {};
      let orgContactArray = isArray(payload.orgContactId)
        ? payload.orgContactId
        : [payload.orgContactId];
      map(orgContactArray, contactId => {
        loadingMap[contactId] = true;
      });
      return Object.assign({}, state, {
        OrgCustomFieldValuesLoading: {
          ...state.OrgCustomFieldValuesLoading,
          ...loadingMap,
        },
        OrgCustomFieldValuesError: null,
      });
    }

    case types.LOAD_ORG_CONTACT_CUSTOM_FIELD_VALUES_SUCCESS: {
      let dataMap = {};
      let loadingMap = {};
      map(payload.data, contact => {
        dataMap[contact.id] = contact;
        loadingMap[contact.id] = false;
      });

      return Object.assign({}, state, {
        OrgCustomFieldValuesLoading: {
          ...state.OrgCustomFieldValuesLoading,
          ...loadingMap,
        },

        OrgCustomFieldValues: {
          ...state.OrgCustomFieldValues,
          ...dataMap,
        },
        OrgCustomFieldValuesError: null,
      });
    }

    case types.LOAD_ORG_CONTACT_CUSTOM_FIELD_VALUES_FAILURE: {
      let loadingMap = {};
      let orgContactArray = isArray(payload.orgContactId)
        ? payload.orgContactId
        : [payload.orgContactId];
      map(orgContactArray, contactId => {
        loadingMap[contactId] = false;
      });
      return Object.assign({}, state, {
        OrgCustomFieldValuesLoading: {
          ...state.OrgCustomFieldValuesLoading,
          ...loadingMap,
        },
        OrgCustomFieldValuesError: payload.message,
      });
    }

    case types.UPDATE_ORG_CONTACT_CUSTOM_FIELD_VALUE_REQUEST:
      return Object.assign({}, state, {
        OrgCustomFieldValuesLoading: {
          ...state.OrgCustomFieldValuesLoading,
          ...payload,
        },
        OrgCustomFieldsError: null,
      });
    case types.UPDATE_ORG_CONTACT_CUSTOM_FIELD_VALUE_SUCCESS:
      return Object.assign({}, state, {
        OrgCustomFieldsError: null,
        OrgCustomFieldValuesLoading: {
          ...state.OrgCustomFieldValuesLoading,
          ...payload.loadingMap,
        },
        OrgCustomFieldValues: {
          ...state.OrgCustomFieldValues,
          ...payload.dataMap,
        },
      });

    case types.UPDATE_ORG_CONTACT_CUSTOM_FIELD_VALUE_FAILURE:
      return Object.assign({}, state, {
        OrgCustomFieldValuesLoading: {
          ...state.OrgCustomFieldValuesLoading,
          ...payload.loadingMap,
        },
        OrgCustomFieldsError: payload.message,
      });

    case types.UPDATE_ORG_CONTACT_CUSTOM_FIELDS_REQUEST:
      return Object.assign({}, state, {
        OrgCustomFieldsLoading: true,
        OrgCustomFieldsError: null,
      });

    case types.UPDATE_ORG_CONTACT_CUSTOM_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        OrgCustomFieldsLoading: false,
        OrgCustomFieldsError: null,
      });

    case types.UPDATE_ORG_CONTACT_CUSTOM_FIELDS_FAILURE:
      return Object.assign({}, state, {
        OrgCustomFieldsLoading: false,
        OrgCustomFieldsError: payload,
      });

    case types.REMOVE_ORG_CONTACT_CUSTOM_FIELDS_REQUEST:
      return Object.assign({}, state, {
        OrgCustomFieldsLoading: true,
        OrgCustomFieldsError: null,
      });

    case types.REMOVE_ORG_CONTACT_CUSTOM_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        OrgCustomFieldsLoading: false,
        OrgCustomFieldsError: null,
      });

    case types.REMOVE_ORG_CONTACT_CUSTOM_FIELDS_FAILURE:
      return Object.assign({}, state, {
        OrgCustomFieldsLoading: false,
        OrgCustomFieldsError: payload,
      });

    case types.ADD_ORG_CONTACT_CUSTOM_FIELD_REQUEST:
      return Object.assign({}, state, {
        OrgCustomFieldsLoading: true,
        OrgCustomFieldsError: null,
      });

    case types.ADD_ORG_CONTACT_CUSTOM_FIELD_SUCCESS:
      return Object.assign({}, state, {
        OrgCustomFieldsLoading: false,
        OrgCustomFields: payload,
        OrgCustomFieldsError: null,
      });

    case types.ADD_ORG_CONTACT_CUSTOM_FIELD_FAILURE:
      return Object.assign({}, state, {
        OrgCustomFieldsLoading: false,
        OrgCustomFieldsError: payload,
      });

    case types.LOAD_PEOPLE_CONTACTS_LIST_REQUEST: {
      return assign(state, {
        peopleContactsLoading: true,
        peopleContactsError: null,
      });
    }

    case types.LOAD_PEOPLE_CONTACTS_LIST_FAILURE: {
      return assign(state, {
        peopleContactsLoading: false,
        peopleContactsError: payload.message,
      });
    }

    case types.LOAD_PEOPLE_CONTACTS_LIST_SUCCESS: {
      const peopleContacts = reduce(
        payload.data,
        function(peopleContacts, peopleContactObj) {
          peopleContacts[peopleContactObj.id] = peopleContactObj;
          return peopleContacts;
        },
        {}
      );
      return assign(state, {
        peopleContactsLoading: false,
        peopleContactsError: null,

        noOfPeopleContacts: payload.noOfPeopleContacts,
        peopleContacts: assign(state.peopleContacts, peopleContacts),
      });
    }

    case types.LOAD_ORGANIZATION_CONTACT_REQUEST: {
      return assign(state, {
        organizationContactsLoading: true,
        organizationContactsError: null,
      });
    }

    case types.LOAD_ORGANIZATION_CONTACT_FAILURE: {
      return assign(state, {
        organizationContactsLoading: false,
        organizationContactsError: payload.message,
      });
    }

    case types.LOAD_ORGANIZATION_CONTACT_SUCCESS: {
      return assign(state, {
        organizationContactsLoading: false,
        organizationContactsError: null,
        organizationContacts: assign(state.organizationContacts, {
          [payload.orgContactId]: payload.data,
        }),
      });
    }

    case types.LOAD_PERSON_CONTACT_REQUEST: {
      return assign(state, {
        peopleContactsLoading: true,
        peopleContactsError: null,
      });
    }

    case types.LOAD_PERSON_CONTACT_FAILURE: {
      return assign(state, {
        peopleContactsLoading: false,
        peopleContactsError: payload.message,
      });
    }

    case types.LOAD_PERSON_CONTACT_SUCCESS: {
      return assign(state, {
        peopleContactsLoading: false,
        peopleContactsError: null,
        peopleContacts: assign(state.peopleContacts, {
          [payload.personContactId]: payload.data,
        }),
      });
    }

    case types.ADD_ORGANIZATION_CONTACTS_REQUEST: {
      return assign(state, {
        addOrganizationContactsLoading: true,
        addOrganizationContactsError: null,
      });
    }

    case types.ADD_ORGANIZATION_CONTACTS_FAILURE: {
      return assign(state, {
        addOrganizationContactsLoading: false,
        addOrganizationContactsError: payload.message,
      });
    }

    case types.ADD_ORGANIZATION_CONTACTS_SUCCESS: {
      const newOrgContacts = reduce(payload.data, (acc, orgContactRes) => {
        if (orgContactRes.success) {
          //only successful results are getting added, helps in bulk importing
          const newOrgContact = orgContactRes.data;
          acc[newOrgContact.id] = newOrgContact;
        }
        return acc;
      });
      return assign(state, {
        addOrganizationContactsLoading: false,
        addOrganizationContactsError: null,
        organizationContacts: assign(
          state.organizationContacts,
          newOrgContacts
        ),
      });
    }

    case types.ADD_PEOPLE_CONTACTS_REQUEST: {
      return assign(state, {
        addPeopleContactsLoading: true,
        addPeopleContactsError: null,
      });
    }

    case types.ADD_PEOPLE_CONTACTS_FAILURE: {
      return assign(state, {
        addPeopleContactsLoading: false,
        addPeopleContactsError: payload.message,
      });
    }

    case types.ADD_PEOPLE_CONTACTS_SUCCESS: {
      const newPeopleContacts = reduce(
        payload.data,
        (acc, personContactRes) => {
          if (personContactRes.success) {
            //only successful results are getting added, helps in bulk importing
            const newPersonContact = personContactRes.data;
            acc[newPersonContact.id] = newPersonContact;
          }
          return acc;
        }
      );
      return assign(state, {
        addPeopleContactsLoading: false,
        addPeopleContactsError: null,
        peopleContacts: assign(state.peopleContacts, newPeopleContacts),
      });
    }

    case types.UPDATE_ORGANIZATION_CONTACT_REQUEST: {
      return assign(state, {
        organizationContactsLoading: true,
        organizationContactsError: null,
      });
    }

    case types.UPDATE_ORGANIZATION_CONTACT_FAILURE: {
      return assign(state, {
        organizationContactsLoading: false,
        organizationContactsError: payload.message,
      });
    }

    case types.UPDATE_ORGANIZATION_CONTACT_SUCCESS: {
      return assign(state, {
        organizationContactsLoading: false,
        organizationContactsError: null,
        organizationContacts: assign(state.organizationContacts, {
          [payload.orgContactId]: {
            ...property(payload.orgContactId)(state.organizationContacts),
            ...payload.data,
          },
        }),
      });
    }

    case types.UPDATE_PERSON_CONTACT_REQUEST: {
      return assign(state, {
        peopleContactsLoading: true,
        peopleContactsError: null,
      });
    }

    case types.UPDATE_PERSON_CONTACT_FAILURE: {
      return assign(state, {
        peopleContactsLoading: false,
        peopleContactsError: payload.message,
      });
    }

    case types.UPDATE_PERSON_CONTACT_SUCCESS: {
      return assign(state, {
        peopleContactsLoading: false,
        peopleContactsError: null,
        peopleContacts: assign(state.peopleContacts, {
          [payload.personContactId]: {
            ...property(payload.personContactId)(state.peopleContacts),
            ...payload.data,
          },
        }),
      });
    }

    case types.DELETE_ORGANIZATION_CONTACT_REQUEST: {
      return assign(state, {
        organizationContactsLoading: true,
        organizationContactsError: null,
      });
    }

    case types.DELETE_ORGANIZATION_CONTACT_FAILURE: {
      return assign(state, {
        organizationContactsLoading: false,
        organizationContactsError: payload.message,
      });
    }

    case types.DELETE_ORGANIZATION_CONTACT_SUCCESS: {
      const copyOfOrganizationContacts = { ...state.organizationContacts };
      delete copyOfOrganizationContacts[payload.orgContactId];
      return assign(state, {
        organizationContactsLoading: false,
        organizationContactsError: null,
        organizationContacts: { ...copyOfOrganizationContacts },
      });
    }

    case types.DELETE_PERSON_CONTACT_REQUEST: {
      return assign(state, {
        peopleContactsLoading: true,
        peopleContactsError: null,
      });
    }

    case types.DELETE_PERSON_CONTACT_FAILURE: {
      return assign(state, {
        peopleContactsLoading: false,
        peopleContactsError: payload.message,
      });
    }

    case types.DELETE_PERSON_CONTACT_SUCCESS: {
      const copyOfPeopleContacts = { ...state.peopleContacts };
      delete copyOfPeopleContacts[payload.personContactId];
      return assign(state, {
        peopleContactsLoading: false,
        peopleContactsError: null,
        peopleContacts: { ...copyOfPeopleContacts },
      });
    }

    case types.LOAD_ORGANIZATION_CONTACTS_TAGS_REQUEST: {
      return assign(state, {
        organizationContactsTagsLoading: true,
        organizationContactsTagsError: null,
      });
    }

    case types.LOAD_ORGANIZATION_CONTACTS_TAGS_FAILURE: {
      return assign(state, {
        organizationContactsTagsLoading: false,
        organizationContactsTagsError: payload.message,
      });
    }

    case types.LOAD_ORGANIZATION_CONTACTS_TAGS_SUCCESS: {
      return assign(state, {
        organizationContactsTagsLoading: false,
        organizationContactsTagsError: null,
        organizationContactsTags: payload.tags,
      });
    }

    case types.LOAD_PEOPLE_CONTACTS_TAGS_REQUEST: {
      return assign(state, {
        peopleContactsTagsLoading: true,
        peopleContactsTagsError: null,
      });
    }

    case types.LOAD_PEOPLE_CONTACTS_TAGS_FAILURE: {
      return assign(state, {
        peopleContactsTagsLoading: false,
        peopleContactsTagsError: payload.message,
      });
    }

    case types.LOAD_PEOPLE_CONTACTS_TAGS_SUCCESS: {
      return assign(state, {
        peopleContactsTagsLoading: false,
        peopleContactsTagsError: null,
        peopleContactsTags: payload.tags,
      });
    }

    case types.ADD_TAG_TO_ORGANIZATION_CONTACT_REQUEST: {
      return assign(state, {
        organizationContactsTagsLoading: true,
        organizationContactsTagsError: null,
      });
    }

    case types.ADD_TAG_TO_ORGANIZATION_CONTACT_FAILURE: {
      return assign(state, {
        organizationContactsTagsLoading: false,
        organizationContactsTagsError: payload.message,
      });
    }

    case types.ADD_TAG_TO_ORGANIZATION_CONTACT_SUCCESS: {
      const tags = reduce(
        Object.keys(state.organizationContacts),
        function(organizationContacts, organizationContactId) {
          organizationContacts[organizationContactId] = {
            ...state.organizationContacts[organizationContactId],
          };

          if (organizationContactId === payload.orgContactId) {
            organizationContacts[organizationContactId].tags = [
              ...state.organizationContacts[organizationContactId].tags,
              { name: payload.tag },
            ];
          }

          return organizationContacts;
        },
        {}
      );

      return assign(state, {
        organizationContactsTagsLoading: false,
        organizationContactsTagsError: null,
        organizationContacts: assign(state.organizationContacts, tags),
      });
    }

    case types.ADD_TAG_TO_PERSON_CONTACT_REQUEST: {
      return assign(state, {
        peopleContactsTagsLoading: true,
        peopleContactsTagsError: null,
      });
    }

    case types.ADD_TAG_TO_PERSON_CONTACT_FAILURE: {
      return assign(state, {
        peopleContactsTagsLoading: false,
        peopleContactsTagsError: payload.message,
      });
    }

    case types.ADD_TAG_TO_PERSON_CONTACT_SUCCESS: {
      const tags = reduce(
        Object.keys(state.peopleContacts),
        function(peopleContacts, personContactId) {
          peopleContacts[personContactId] = {
            ...state.peopleContacts[personContactId],
          };

          if (personContactId === payload.personContactId) {
            peopleContacts[personContactId].tags = [
              ...state.peopleContacts[personContactId].tags,
              { name: payload.tag },
            ];
          }

          return peopleContacts;
        },
        {}
      );

      return assign(state, {
        peopleContactsTagsLoading: false,
        peopleContactsTagsError: null,
        peopleContacts: assign(state.peopleContacts, tags),
      });
    }

    case types.REMOVE_TAG_FROM_ORGANIZATION_CONTACT_REQUEST: {
      return assign(state, {
        organizationContactsTagsLoading: true,
        organizationContactsTagsError: null,
      });
    }

    case types.REMOVE_TAG_FROM_ORGANIZATION_CONTACT_FAILURE: {
      return assign(state, {
        organizationContactsTagsLoading: false,
        organizationContactsTagsError: payload.message,
      });
    }

    case types.REMOVE_TAG_FROM_ORGANIZATION_CONTACT_SUCCESS: {
      const tagsAfterRemoval = reduce(
        Object.keys(state.organizationContacts),
        function(organizationContacts, organizationContactId) {
          if (organizationContactId === payload.orgContactId) {
            organizationContacts[organizationContactId] = {
              ...state.organizationContacts[organizationContactId],

              tags: filter(
                state.organizationContacts[organizationContactId].tags,
                tag => tag.name !== payload.tag.name
              ),
            };
          }
          return organizationContacts;
        },
        {}
      );

      return assign(state, {
        organizationContactsTagsLoading: false,
        organizationContactsTagsError: null,
        organizationContacts: assign(
          state.organizationContacts,
          tagsAfterRemoval
        ),
      });
    }

    case types.REMOVE_TAG_FROM_PERSON_CONTACT_REQUEST: {
      return assign(state, {
        peopleContactsTagsLoading: true,
        peopleContactsTagsError: null,
      });
    }

    case types.REMOVE_TAG_FROM_PERSON_CONTACT_FAILURE: {
      return assign(state, {
        peopleContactsTagsLoading: false,
        peopleContactsTagsError: payload.message,
      });
    }

    case types.REMOVE_TAG_FROM_PERSON_CONTACT_SUCCESS: {
      const tagsAfterRemoval = reduce(
        Object.keys(state.peopleContacts),
        function(peopleContacts, personContactId) {
          if (personContactId === payload.personContactId) {
            peopleContacts[personContactId] = {
              ...state.peopleContacts[personContactId],

              tags: filter(
                state.peopleContacts[personContactId].tags,
                tag => tag.name !== payload.tag.name
              ),
            };
          }
          return peopleContacts;
        },
        {}
      );

      return assign(state, {
        peopleContactsTagsLoading: false,
        peopleContactsTagsError: null,
        peopleContacts: assign(state.peopleContacts, tagsAfterRemoval),
      });
    }

    case types.LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT_REQUEST: {
      return assign(state, {
        associatedTeamMembersLoading: true,
        associatedTeamMembersError: null,
      });
    }

    case types.LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT_FAILURE: {
      return assign(state, {
        associatedTeamMembersLoading: false,
        associatedTeamMembersError: payload.message,
      });
    }

    case types.LOAD_TEAM_MEMBERS_ASSOCIATED_WITH_PERSON_CONTACT_SUCCESS: {
      return assign(state, {
        associatedTeamMembersLoading: false,
        associatedTeamMembersError: null,
        noOfAssociatedTeamMembers: payload.metaData,
        associatedTeamMembers: assign(state.associatedTeamMembers, {
          [payload.personContactId]:
            payload.options.page === 1
              ? payload.data
              : [
                  ...state.associatedTeamMembers[payload.personContactId],
                  ...payload.data,
                ],
        }),
      });
    }

    case types.LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT_REQUEST: {
      return assign(state, {
        associatedDealsAndPorcosPersonContactLoading: true,
        associatedDealsAndPorcosPersonContactError: null,
      });
    }

    case types.LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT_FAILURE: {
      return assign(state, {
        associatedDealsAndPorcosPersonContactLoading: false,
        associatedDealsAndPorcosPersonContactError: payload.message,
      });
    }

    case types.LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_PERSON_CONTACT_SUCCESS: {
      return assign(state, {
        associatedDealsAndPorcosPersonContactLoading: false,
        associatedDealsAndPorcosPersonContactError: null,
        noOfAssociatedDealsAndPortcosPersonContact: payload.metaData,
        associatedDealsAndPorcosPersonContact: assign(
          state.associatedDealsAndPorcosPersonContact,
          {
            [payload.personContactId]:
              payload.options.dealsPage === 1 &&
              payload.options.portcosPage === 1
                ? payload.data
                : {
                    deals: [
                      ...state.associatedDealsAndPorcosPersonContact[
                        payload.personContactId
                      ].deals,
                      ...payload.data.deals,
                    ],
                    portfolios: [
                      ...state.associatedDealsAndPorcosPersonContact[
                        payload.personContactId
                      ].portfolios,
                      ...payload.data.portfolios,
                    ],
                  },
          }
        ),
      });
    }

    case types.LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT_REQUEST: {
      return assign(state, {
        associatedDealsAndPorcosOrgContactLoading: true,
        associatedDealsAndPorcosOrgContactError: null,
      });
    }

    case types.LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT_FAILURE: {
      return assign(state, {
        associatedDealsAndPorcosOrgContactLoading: false,
        associatedDealsAndPorcosOrgContactError: payload.message,
      });
    }

    case types.LOAD_DEALS_AND_PORTFOLIOS_ASSOCIATED_WITH_ORG_CONTACT_SUCCESS: {
      return assign(state, {
        associatedDealsAndPorcosOrgContactLoading: false,
        associatedDealsAndPorcosOrgContactError: null,
        noOfAssociatedDealsAndPortcosOrgContact: payload.metaData,
        associatedDealsAndPorcosOrgContact: assign(
          state.associatedDealsAndPorcosOrgContact,
          {
            [payload.orgContactId]:
              payload.options.dealsPage === 1 &&
              payload.options.portcosPage === 1
                ? payload.data
                : {
                    deals: [
                      ...state.associatedDealsAndPorcosOrgContact[
                        payload.orgContactId
                      ].deals,
                      ...payload.data.deals,
                    ],
                    portfolios: [
                      ...state.associatedDealsAndPorcosOrgContact[
                        payload.orgContactId
                      ].portfolios,
                      ...payload.data.portfolios,
                    ],
                  },
          }
        ),
      });
    }

    case types.LOAD_PERSON_CONTACT_INTERACTIONS_REQUEST: {
      return assign(state, {
        personContactInteractionsLoading: true,
        personContactInteractionsError: null,
      });
    }

    case types.LOAD_PERSON_CONTACT_INTERACTIONS_FAILURE: {
      return assign(state, {
        personContactInteractionsLoading: false,
        personContactInteractionsError: payload.message,
      });
    }

    case types.LOAD_PERSON_CONTACT_INTERACTIONS_SUCCESS: {
      return assign(state, {
        personContactInteractionsLoading: false,
        personContactInteractionsError: null,
        noOfPersonContactInteractions: payload.metaData.count,
        personContactInteractions: assign(state.personContactInteractions, {
          [payload.personContactId]:
            payload.options.page === 1
              ? payload.data
              : [
                  ...state.personContactInteractions[payload.personContactId],
                  ...payload.data,
                ],
        }),
      });
    }

    case types.LOAD_MEMBERS_OF_ORG_CONTACT_REQUEST: {
      return assign(state, {
        organizationContactMembersLoading: true,
        organizationContactMembersError: null,
      });
    }

    case types.LOAD_MEMBERS_OF_ORG_CONTACT_FAILURE: {
      return assign(state, {
        organizationContactMembersLoading: false,
        organizationContactMembersError: payload.message,
      });
    }

    case types.LOAD_MEMBERS_OF_ORG_CONTACT_SUCCESS: {
      return assign(state, {
        organizationContactMembersLoading: false,
        organizationContactMembersError: null,
        organizationContactMembers: assign(state.organizationContactMembers, {
          [payload.orgContactId]: payload.data,
        }),
      });
    }

    // LOAD PERSON CONTACT TABLE COLUMNS
    case types.LOAD_PERSON_CONTACT_TABLE_COLUMNS_REQUEST:
      return Object.assign({}, state, {
        personContactColumnsLoading: true,
        personContactColumnsError: null,
      });
    case types.LOAD_PERSON_CONTACT_TABLE_COLUMNS_SUCCESS:
      return payload.length > 0
        ? Object.assign({}, state, {
            personContactColumnsLoading: false,
            personContactColumnsError: null,
            personContactColumns: payload,
          })
        : Object.assign({}, state, {
            personContactColumns: defaultPersonContactTableViewColumnsConfig,
            personContactColumnsLoading: false,
            personContactColumnsError: null,
          });
    case types.LOAD_PERSON_CONTACT_TABLE_COLUMNS_FAILURE:
      return Object.assign({}, state, {
        personContactColumnsLoading: false,
        personContactColumnsError: payload.message,
      });

    // UPDATE PERSON CONTACT TABLE COLUMNS
    case types.UPDATE_PERSON_CONTACT_TABLE_COLUMNS_REQUEST:
      return Object.assign({}, state, {
        personContactColumnsLoading: true,
        personContactColumnsError: null,
      });
    case types.UPDATE_PERSON_CONTACT_TABLE_COLUMNS_SUCCESS:
      return Object.assign({}, state, {
        personContactColumns: payload,
        personContactColumnsLoading: false,
        personContactColumnsError: null,
      });
    case types.UPDATE_PERSON_CONTACT_TABLE_COLUMNS_FAILURE:
      return Object.assign({}, state, {
        personContactColumnsLoading: false,
        personContactColumnsError: payload,
      });

    // Manage Columns Person Contact
    case types.PERSON_CONTACT_OPEN_MANAGE_COLUMN:
      return Object.assign({}, state, {
        personContactManageColumnOpen: true,
      });

    case types.PERSON_CONTACT_CLOSE_MANAGE_COLUMN:
      return Object.assign({}, state, {
        personContactManageColumnOpen: false,
      });

    // LOAD ORG CONTACT TABLE COLUMNS
    case types.LOAD_ORG_CONTACT_TABLE_COLUMNS_REQUEST:
      return Object.assign({}, state, {
        orgContactColumnsLoading: true,
        orgContactColumnsError: null,
      });
    case types.LOAD_ORG_CONTACT_TABLE_COLUMNS_SUCCESS:
      return payload.length > 0
        ? Object.assign({}, state, {
            orgContactColumnsLoading: false,
            orgContactColumnsError: null,
            orgContactColumns: payload,
          })
        : Object.assign({}, state, {
            orgContactColumns: defaultOrgContactTableViewColumnsConfig,
            orgContactColumnsLoading: false,
            orgContactColumnsError: null,
          });
    case types.LOAD_ORG_CONTACT_TABLE_COLUMNS_FAILURE:
      return Object.assign({}, state, {
        orgContactColumnsLoading: false,
        orgContactColumnsError: payload.message,
      });

    // UPDATE ORG CONTACT TABLE COLUMNS
    case types.UPDATE_ORG_CONTACT_TABLE_COLUMNS_REQUEST:
      return Object.assign({}, state, {
        orgContactColumnsLoading: true,
        orgContactColumnsError: null,
      });
    case types.UPDATE_ORG_CONTACT_TABLE_COLUMNS_SUCCESS:
      return Object.assign({}, state, {
        orgContactColumns: payload,
        orgContactColumnsLoading: false,
        orgContactColumnsError: null,
      });
    case types.UPDATE_ORG_CONTACT_TABLE_COLUMNS_FAILURE:
      return Object.assign({}, state, {
        orgContactColumnsLoading: false,
        orgContactColumnsError: payload,
      });

    // Manage Columns Org Contact
    case types.ORG_CONTACT_OPEN_MANAGE_COLUMN:
      return Object.assign({}, state, {
        orgContactManageColumnOpen: true,
      });

    case types.ORG_CONTACT_CLOSE_MANAGE_COLUMN:
      return Object.assign({}, state, {
        orgContactManageColumnOpen: false,
      });

    // Resets the redux state
    case types.CLEAR: {
      return assign(state, initial);
    }

    default:
      return state;
  }
}
