import {
  REMOVE_EXTERNAL_REFERRER_REQUEST,
  REMOVE_EXTERNAL_REFERRER_SUCCESS,
  REMOVE_EXTERNAL_REFERRER_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case REMOVE_EXTERNAL_REFERRER_REQUEST:
      return Object.assign({}, state, {
        removeExternalReferrerLoading: {
          ...state.removeExternalReferrerLoading,
          [payload.dealId]: true
        },
        removeExternalReferrerError: {
          ...state.removeExternalReferrerError,
          [payload.dealId]: null
        },
      });
    
    case REMOVE_EXTERNAL_REFERRER_SUCCESS:
      return Object.assign({}, state, {
        deals: {
          ...state.deals,
          [payload.dealId]: (state.deals[payload.dealId] ? {
            ...state.deals[payload.dealId],
            externalReferrer: null,
            external_referrer_id: null
          } : payload.data)
        },
        removeExternalReferrerLoading: {
          ...state.removeExternalReferrerLoading,
          [payload.dealId]: false
        },
        removeExternalReferrerError: {
          ...state.removeExternalReferrerError,
          [payload.dealId]: null
        },
      });
    
    case REMOVE_EXTERNAL_REFERRER_FAILURE:
      return Object.assign({}, state, {
        removeExternalReferrerLoading: {
          ...state.removeExternalReferrerLoading,
          [payload.dealId]: false
        },
        removeExternalReferrerError: {
          ...state.removeExternalReferrerError,
          [payload.dealId]: payload.message
        },
      });

    default:
      return state;
  }
};
