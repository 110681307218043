/**
 * @format
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';
import { BULK_LOAD_COMMENT_LIST } from '../portfolio-comment/actions';
import {
  INCREMENT_PORTCO_CONVERSATION_COUNT,
  DECREMENT_PORTCO_CONVERSATION_COUNT,
} from '../portfolio/actions';

export default reducers;

export const loadConversations = (portcoId, options = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_CONVERSATION_LIST_REQUEST,
        payload: {
          portcoId: portcoId,
        },
      });
      options = {
        ...options,
        portco_id: portcoId,
        withCreator:
          typeof options.withCreator !== 'undefined'
            ? options.withCreator
            : true,
        withFirstPageComments:
          typeof options.withFirstPageComments !== 'undefined'
            ? options.withFirstPageComments
            : true,
        withCommentCount:
          typeof options.withCommentCount !== 'undefined'
            ? options.withCommentCount
            : true,
        withParticipants:
          typeof options.withParticipants !== 'undefined'
            ? options.withParticipants
            : true,
        withPartcipantsAndParticipantLevels:
          typeof options.withPartcipantsAndParticipantLevels !== 'undefined'
            ? options.withPartcipantsAndParticipantLevels
            : true,
        withTeams:
          typeof options.withTeams !== 'undefined' ? options.withTeams : true,
        onlyStarredConversations:
          typeof options.onlyStarredConversations !== 'undefined'
            ? options.onlyStarredConversations
            : false,
      };
      request
        .get(
          `/api/portfolio/communications/v1/conversations`,
          options,
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_CONVERSATION_LIST_SUCCESS,
              payload: {
                data: data.data,
                options: options,
                portcoId: portcoId,
              },
            });
            if (
              data.data &&
              data.data.length > 0 &&
              // If either of these options is not set, then BULK_LOAD_COMMENT_LIST
              // should not be dispatched
              options.withFirstPageComments &&
              options.withCommentCount
            ) {
              dispatch({
                type: BULK_LOAD_COMMENT_LIST,
                payload: {
                  conversations: data.data,
                  options: { limit: 10, page: 1 },
                },
              });
            }
            return resolve(data.data);
          } else {
            dispatch({
              type: types.LOAD_CONVERSATION_LIST_FAILURE,
              payload: {
                message: data.message,
                portcoId: portcoId,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading conversations, please try again.';
          dispatch({
            type: types.LOAD_CONVERSATION_LIST_FAILURE,
            payload: {
              message,
              portcoId: portcoId,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

export const loadConversation = (conversationId, portcoId, options = {}) => {
  return dispatch => {
    dispatch({
      type: types.LOAD_CONVERSATION_REQUEST,
      payload: {
        conversationId: conversationId,
      },
    });
    options = {
      ...options,
      portco_id: portcoId,
      withAuthor:
        typeof options.withAuthor !== 'undefined' ? options.withAuthor : true,
      withPortCo:
        typeof options.withPortCo !== 'undefined' ? options.withPortCo : true,
      withReminders:
        typeof options.withReminders !== 'undefined'
          ? options.withReminders
          : true,
      withTeams:
        typeof options.withTeams !== 'undefined' ? options.withTeams : true,
      // withParticipant:
      //   typeof options.withParticipant !== 'undefined'
      //     ? options.withParticipant
      //     : true,
    };
    request
      .get(
        `/api/portfolio/communications/v1/conversations/${conversationId}`,
        options,
        dispatch
      )
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_CONVERSATION_SUCCESS,
            payload: {
              data: data.data,
              conversationId: conversationId,
            },
          });
        } else {
          dispatch({
            type: types.LOAD_CONVERSATION_FAILURE,
            payload: {
              message: data.message,
              conversationId: conversationId,
            },
          });
          dispatch(enqueueNotification(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while loading conversation, please try again.';
        dispatch({
          type: types.LOAD_CONVERSATION_FAILURE,
          payload: {
            message,
            conversationId: conversationId,
          },
        });
        dispatch(enqueueNotification(message));
      });
  };
};

export const createConversation = (portcoId, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.CREATE_CONVERSATION_REQUEST,
        payload: {
          portcoId: portcoId,
        },
      });
      request
        .post(
          `/api/portfolio/communications/v1/conversations`,
          {
            portco_id: portcoId,
            ...form,
          },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.CREATE_CONVERSATION_SUCCESS,
              payload: {
                data: data.data,
                portcoId: portcoId,
              },
            });
            dispatch({
              type: INCREMENT_PORTCO_CONVERSATION_COUNT,
              payload: {
                portcoId,
                increment: 1,
              },
            });
            resolve(data.data);
          } else {
            dispatch({
              type: types.CREATE_CONVERSATION_FAILURE,
              payload: {
                message: data.message,
                portcoId: portcoId,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          let message =
            'Something went wrong while creating conversation, please try again.';
          if (err?.response?.status === 429) {
            message = `Too fast! Try again in a couple of seconds.`;
          }
          dispatch({
            type: types.CREATE_CONVERSATION_FAILURE,
            payload: {
              message,
              portcoId: portcoId,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

/**
 * Toggles the conversation as starred
 * @param {String} portco_id
 * @param {String} conversation_id
 * @param {Boolean} is_starred
 * @returns
 */
export const toggleConversationAsStarred = (
  portco_id,
  conversation_id,
  is_starred
) => {
  return async dispatch => {
    if (!portco_id || !conversation_id || typeof is_starred !== 'boolean') {
      let message = `Something went wrong when toggling this conversation as starred, one of the required fields might be missing`;
      dispatch({
        type: types.TOGGLE_CONVERSATION_AS_STARRED_FAILURE,
        payload: { message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }

    dispatch({
      type: types.TOGGLE_CONVERSATION_AS_STARRED_REQUEST,
      payload: {
        portcoId: portco_id,
        conversationId: conversation_id,
      },
    });

    let data;
    try {
      const response = await request.put(
        `/api/portfolio/communications/v1/conversations/${conversation_id}/is_starred`,
        {
          portco_id,
          is_starred,
        },
        dispatch
      );
      data = response.data;
    } catch (err) {
      let message;
      message = `Something went wrong while toggling this conversation as starred. Please try again`;
      dispatch({
        type: types.TOGGLE_CONVERSATION_AS_STARRED_FAILURE,
        payload: {
          portcoId: portco_id,
          conversationId: conversation_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (!data.success) {
      dispatch({
        type: types.TOGGLE_CONVERSATION_AS_STARRED_FAILURE,
        payload: {
          portcoId: portco_id,
          conversationId: conversation_id,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }

    dispatch({
      type: types.TOGGLE_CONVERSATION_AS_STARRED_SUCCESS,
      payload: {
        portcoId: portco_id,
        conversationId: conversation_id,
        is_starred,
        data: data.data,
      },
    });
    return data.data;
  };
};

export const loadConversationParticipants = (conversationId, portcoId) => {
  return async dispatch => {
    dispatch({
      type: types.LOAD_PARTICIPANT_LIST_REQUEST,
      payload: {
        conversationId: conversationId,
      },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/communications/v1/conversations/${conversationId}/participants`,
        { portco_id: portcoId },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading conversation participants, please try again.';
      dispatch({
        type: types.LOAD_PARTICIPANT_LIST_FAILURE,
        payload: {
          message,
          conversationId: conversationId,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    if (data.success) {
      dispatch({
        type: types.LOAD_PARTICIPANT_LIST_SUCCESS,
        payload: {
          data: data.data,
          conversationId: conversationId,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.LOAD_PARTICIPANT_LIST_FAILURE,
        payload: {
          message: data.message,
          conversationId: conversationId,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const deleteConversation = (conversationId, portcoId) => {
  return async dispatch => {
    dispatch({
      type: types.DELETE_CONVERSATION_REQUEST,
      payload: { conversationId },
    });
    let data;
    try {
      const requestUrl = `/api/portfolio/communications/v1/conversations/${conversationId}?portco_id=${portcoId}`;
      const response = await request.del(requestUrl, {}, dispatch);
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while deleting this conversation, ' +
        'please try again.';
      dispatch({
        type: types.DELETE_CONVERSATION_FAILURE,
        payload: {
          conversationId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.DELETE_CONVERSATION_SUCCESS,
        payload: {
          conversationId,
          data: data.data,
        },
      });
      dispatch(
        enqueueNotification(
          `Deleting this conversation. ` +
            `You will be redirected to the email page once this action is complete. ` +
            `Please wait.`
        )
      );
      dispatch(
        enqueueNotification(
          `Conversation deleted. Taking you to the email page.`
        )
      );
      dispatch({
        type: DECREMENT_PORTCO_CONVERSATION_COUNT,
        payload: {
          portcoId,
          decrement: 1,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.DELETE_CONVERSATION_FAILURE,
        payload: {
          conversationId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
