/**
 * Action creator to add internal referrer
 */
import {
  ADD_INTERNAL_REFERRER_REQUEST,
  ADD_INTERNAL_REFERRER_SUCCESS,
  ADD_INTERNAL_REFERRER_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case ADD_INTERNAL_REFERRER_REQUEST:
      return Object.assign({}, state, {
        addInternalReferrerLoading: {
          ...state.addInternalReferrerLoading,
          [payload.dealId]: true
        },
        addInternalReferrerError: {
          ...state.addInternalReferrerError,
          [payload.dealId]: null
        },
      });
    
    case ADD_INTERNAL_REFERRER_SUCCESS:
      return Object.assign({}, state, {
        deals: {
          ...state.deals,
          [payload.dealId]: (state.deals[payload.dealId] ? {
            ...state.deals[payload.dealId],
            internalReferrers: [...payload.data.internalReferrers]
          } : payload.data)
        },
        addInternalReferrerLoading: {
          ...state.addInternalReferrerLoading,
          [payload.dealId]: false
        },
        addInternalReferrerError: {
          ...state.addInternalReferrerError,
          [payload.dealId]: null
        },
      });
    
    case ADD_INTERNAL_REFERRER_FAILURE:
      return Object.assign({}, state, {
        addInternalReferrerLoading: {
          ...state.addInternalReferrerLoading,
          [payload.dealId]: false
        },
        addInternalReferrerError: {
          ...state.addInternalReferrerError,
          [payload.dealId]: payload.message
        },
      });

    default:
      return state;
  }
};
