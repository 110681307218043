/**
 *
 * @format
 */
import request from '../../../helpers/request';
import executeInBackground from '../../../helpers/executeInBackground';
import { enqueueNotification } from '../../app';
import {
  LOAD_PORTFOLIO_TABLE_COLUMNS_REQUEST,
  LOAD_PORTFOLIO_TABLE_COLUMNS_SUCCESS,
  LOAD_PORTFOLIO_TABLE_COLUMNS_FAILURE,
} from '../actions';
import getPortfolioTableColumnsWidths from '../../../helpers/getPortfolioTableColumnsWidth';
import setPortfolioTableColumnWidths from '../../../helpers/setPortfolioTableColumnsWidth';

const loadPortfolioTableColumns = (
  shouldForceAPIRequest = false,
  ignoreCache = false
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!shouldForceAPIRequest) {
        // If column information is present in local storage, fetch it from there
        // `shouldForceAPIRequest` allows for overriding this behaviour
        const columnData = getPortfolioTableColumnsWidths();
        if (columnData && columnData.length > 0) {
          dispatch({
            type: LOAD_PORTFOLIO_TABLE_COLUMNS_SUCCESS,
            payload: columnData,
          });
          // also contact server to get up to date data, which gets updated in the background
          executeInBackground(() =>
            loadPortfolioTableColumns(true)(dispatch).catch(() => {})
          );
          return resolve(columnData);
        }
      }
      dispatch({ type: LOAD_PORTFOLIO_TABLE_COLUMNS_REQUEST });
      request
        .get(`/api/portfolio/v1/portfolio_table_columns`)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: LOAD_PORTFOLIO_TABLE_COLUMNS_SUCCESS,
              payload: data.data,
            });

            setPortfolioTableColumnWidths(data.data);
            resolve(data);
          } else {
            dispatch({
              type: LOAD_PORTFOLIO_TABLE_COLUMNS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            // reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading table columns, please try again.';
          dispatch({
            type: LOAD_PORTFOLIO_TABLE_COLUMNS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export default loadPortfolioTableColumns;
