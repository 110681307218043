/** @format */

import * as types from '../actions';
import request, { wrap } from '../../../helpers/request';
import isEmptyString from '../../../helpers/string/isEmptyString';
import { enqueueNotification } from '../../app';

/**
 * Deletes a NAV update
 * @param {UUID} navUpdateId Id of NAV update to load
 * @param {UUID} fundId Fund's id
 */
export default function deleteNavUpdate(navUpdateId, fundId) {
  if (isEmptyString(fundId)) {
    throw new Error('Fund id missing');
  }
  if (isEmptyString(navUpdateId)) {
    throw new Error('NAV Update id missing');
  }
  return async dispatch => {
    dispatch({
      type: types.DELETE_NAV_UPDATE_REQUEST,
      payload: {
        fundId,
        navUpdateId,
      },
    });

    try {
      const { data } = await wrap(
        request.del(
          `/api/fund-transactions/v1/fund/${fundId}/nav-updates/${navUpdateId}`,
          {},
          dispatch
        )
      );

      dispatch({
        type: types.DELETE_NAV_UPDATE_SUCCESS,
        payload: { fundId, navUpdateId, data: data.data },
      });

      return data.data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong when deleting the NAV Update. Please try again.';
      dispatch({
        type: types.DELETE_NAV_UPDATE_FAILURE,
        payload: { fundId, navUpdateId, message: message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
  };
}
