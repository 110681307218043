import * as types from './actions';
import { assign } from '../../helpers/object';

const initial = {
  // keyed by portfolio company's unique identifier
  captable: { /* store of captable of various portfolio companies */},
  captableLoading: { /** key is portfolio company's id */ },
  sharesLogLoading: false,
  // Hashmap keyed by portfolio company's id
  sharesLog: {},
  // keyed by record's id
  deleteLogLoading: {},
};

export default function(state = initial, { type, payload }) {
  const id = payload? payload.id : null;

  switch(type) {
    case types.LOAD_PORTFOLIO_CAPTABLE_REQUEST: {
      return assign(state, {
        captableLoading: assign(state.captableLoading, {
          [id]: true
        })
      });
    }
    case types.LOAD_PORTFOLIO_CAPTABLE_SUCCESS: {
      return assign(state, {
        captableLoading: assign(state.captableLoading, {
          [id]: false
        }),
        captable: assign(state.captable, {
          [id]: payload.data
        })
      });
    }
    case types.LOAD_PORTFOLIO_CAPTABLE_FAILURE: {
      return assign(state, {
        captableLoading: assign(state.captableLoading, {
          [id]: false
        }),
        captable: assign(state.captable, {
          [id]: null
        })
      });
    }

    case types.UPDATE_PORTFOLIO_CAPTABLE: {
      return assign(state, {
        captable: assign(state.captable, {
          [id]: payload.data
        })
      });
    }
    case types.LOAD_PORTFOLIO_CAPTABLE_SHARES_LOG_REQUEST: {
      return assign(state, {
        sharesLogLoading: true,
      });
    }
    case types.LOAD_PORTFOLIO_CAPTABLE_SHARES_LOG_SUCCESS: {
      return assign(state, {
        sharesLogLoading: false,
        sharesLog: assign(state.sharesLog, {
          [id]: payload.data
        })
      });
    }
    case types.LOAD_PORTFOLIO_CAPTABLE_SHARES_LOG_FAILURE: {
      return assign(state, {
        sharesLogLoading: false,
      });
    }

    // DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG
    case types.DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG_REQUEST: {
      return assign(state, {
        deleteLogLoading: assign(state.deleteLogLoading, {
          [payload.key]: true
        }),
      });
    }
    case types.DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG_SUCCESS: {
      return assign(state, {
        deleteLogLoading: assign(state.deleteLogLoading, {
          [payload.key]: false
        }),
      });
    }
    case types.DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG_FAILURE: {
      return assign(state, {
        deleteLogLoading: assign(state.deleteLogLoading, {
          [payload.key]: false
        }),
      });
    }

    case types.CLEAR_PORTFOLIO_CAPTABLE_SHARES_LOG: {
      return assign(state, {
        sharesLog: { ...initial.sharesLog }
      });
    }
    default:
      return state;
  }

};
