/**
 * Opens manage column
 * @format
 */

import { PORTCO_OPEN_MANAGE_COLUMN } from '../actions';

export default () => {
  return dispatch => {
    dispatch({
      type: PORTCO_OPEN_MANAGE_COLUMN,
    });
  };
};
