
export const ADD_PERSON_CONTACT_NOTE_REQUEST = 'person-contact-note/ADD_PERSON_CONTACT_NOTE_REQUEST';
export const ADD_PERSON_CONTACT_NOTE_SUCCESS = 'person-contact-note/ADD_PERSON_CONTACT_NOTE_SUCCESS';
export const ADD_PERSON_CONTACT_NOTE_FAILURE = 'person-contact-note/ADD_PERSON_CONTACT_NOTE_FAILURE';

export const UPDATE_PERSON_CONTACT_NOTE_REQUEST = 'person-contact-note/UPDATE_PERSON_CONTACT_NOTE_REQUEST';
export const UPDATE_PERSON_CONTACT_NOTE_SUCCESS = 'person-contact-note/UPDATE_PERSON_CONTACT_NOTE_SUCCESS';
export const UPDATE_PERSON_CONTACT_NOTE_FAILURE = 'person-contact-note/UPDATE_PERSON_CONTACT_NOTE_FAILURE';

export const LIST_PERSON_CONTACT_NOTES_REQUEST = 'person-contact-note/LIST_PERSON_CONTACT_NOTES_REQUEST';
export const LIST_PERSON_CONTACT_NOTES_SUCCESS = 'person-contact-note/LIST_PERSON_CONTACT_NOTES_SUCCESS';
export const LIST_PERSON_CONTACT_NOTES_FAILURE = 'person-contact-note/LIST_PERSON_CONTACT_NOTES_FAILURE';

export const GET_PERSON_CONTACT_NOTE_REQUEST = 'person-contact-note/GET_PERSON_CONTACT_NOTE_REQUEST';
export const GET_PERSON_CONTACT_NOTE_SUCCESS = 'person-contact-note/GET_PERSON_CONTACT_NOTE_SUCCESS';
export const GET_PERSON_CONTACT_NOTE_FAILURE = 'person-contact-note/GET_PERSON_CONTACT_NOTE_FAILURE';

export const GET_PINNED_PERSON_CONTACT_NOTE_REQUEST = 'person-contact-note/GET_PINNED_PERSON_CONTACT_NOTE_REQUEST';
export const GET_PINNED_PERSON_CONTACT_NOTE_SUCCESS = 'person-contact-note/GET_PINNED_PERSON_CONTACT_NOTE_SUCCESS';
export const GET_PINNED_PERSON_CONTACT_NOTE_FAILURE = 'person-contact-note/GET_PINNED_PERSON_CONTACT_NOTE_FAILURE';

export const DELETE_PERSON_CONTACT_NOTE_REQUEST = 'person-contact-note/DELETE_PERSON_CONTACT_NOTE_REQUEST';
export const DELETE_PERSON_CONTACT_NOTE_SUCCESS = 'person-contact-note/DELETE_PERSON_CONTACT_NOTE_SUCCESS';
export const DELETE_PERSON_CONTACT_NOTE_FAILURE = 'person-contact-note/DELETE_PERSON_CONTACT_NOTE_FAILURE';

export const LOAD_NOTE_REPLY_LIST_REQUEST ='person-contact-note/LOAD_NOTE_REPLY_LIST_REQUEST'
export const LOAD_NOTE_REPLY_LIST_SUCCESS ='person-contact-note/LOAD_NOTE_REPLY_LIST_SUCCESS'
export const LOAD_NOTE_REPLY_LIST_FAILURE ='person-contact-note/LOAD_NOTE_REPLY_LIST_FAILURE'

export const LOAD_NOTE_REPLY_COUNT_REQUEST ='person-contact-note/LOAD_NOTE_REPLY_COUNT_REQUEST'
export const LOAD_NOTE_REPLY_COUNT_SUCCESS ='person-contact-note/LOAD_NOTE_REPLY_COUNT_SUCCESS'
export const LOAD_NOTE_REPLY_COUNT_FAILURE ='person-contact-note/LOAD_NOTE_REPLY_COUNT_FAILURE'