/**
 * @format
 */
export const LOAD_DEAL_SIGNALS_LIST_REQUEST =
  'signals/LOAD_DEAL_SIGNALS_LIST_REQUEST';
export const LOAD_DEAL_SIGNALS_LIST_SUCCESS =
  'signals/LOAD_DEAL_SIGNALS_LIST_SUCCESS';
export const LOAD_DEAL_SIGNALS_LIST_FAILURE =
  'signals/LOAD_DEAL_SIGNALS_LIST_FAILURE';

export const LOAD_DEAL_SIGNAL_REQUEST = 'signals/LOAD_DEAL_SIGNAL_REQUEST';
export const LOAD_DEAL_SIGNAL_SUCCESS = 'signals/LOAD_DEAL_SIGNAL_SUCCESS';
export const LOAD_DEAL_SIGNAL_FAILURE = 'signals/LOAD_DEAL_SIGNAL_FAILURE';

//
// LOAD_ORGANIZATION_SIGNALS actions
// used when loading org's signals with its filters
export const LOAD_ORGANIZATION_SIGNALS_REQUEST =
  'signals/LOAD_ORGANIZATION_SIGNALS_REQUEST';
export const LOAD_ORGANIZATION_SIGNALS_SUCCESS =
  'signals/LOAD_ORGANIZATION_SIGNALS_SUCCESS';
export const LOAD_ORGANIZATION_SIGNALS_FAILURE =
  'signals/LOAD_ORGANIZATION_SIGNALS_FAILURE';

//
// LOAD_SIGNALS_STATS actions
// used when loading org's signals stats
export const LOAD_SIGNALS_STATS_REQUEST = 'signals/LOAD_SIGNALS_STATS_REQUEST';
export const LOAD_SIGNALS_STATS_SUCCESS = 'signals/LOAD_SIGNALS_STATS_SUCCESS';
export const LOAD_SIGNALS_STATS_FAILURE = 'signals/LOAD_SIGNALS_STATS_FAILURE';

//
// CREATE_DEAL_FROM_SUPER_SIGNAL actions
// used when creating deal from super signal
export const CREATE_DEAL_FROM_SUPER_SIGNAL_REQUEST =
  'signals/CREATE_DEAL_FROM_SUPER_SIGNAL_REQUEST';
export const CREATE_DEAL_FROM_SUPER_SIGNAL_SUCCESS =
  'signals/CREATE_DEAL_FROM_SUPER_SIGNAL_SUCCESS';
export const CREATE_DEAL_FROM_SUPER_SIGNAL_FAILURE =
  'signals/CREATE_DEAL_FROM_SUPER_SIGNAL_FAILURE';

//
// CREATE_DEAL_FROM_MOVEMENT actions
// used when creating deal from movement
export const CREATE_DEAL_FROM_MOVEMENT_REQUEST =
  'signals/CREATE_DEAL_FROM_MOVEMENT_REQUEST';
export const CREATE_DEAL_FROM_MOVEMENT_SUCCESS =
  'signals/CREATE_DEAL_FROM_MOVEMENT_SUCCESS';
export const CREATE_DEAL_FROM_MOVEMENT_FAILURE =
  'signals/CREATE_DEAL_FROM_MOVEMENT_FAILURE';

//
// LOAD_SIGNALS_FOR_DEAL actions
// used when loading all types of signals for a given deal
export const LOAD_SIGNALS_FOR_DEAL_REQUEST =
  'signals/LOAD_SIGNALS_FOR_DEAL_REQUEST';
export const LOAD_SIGNALS_FOR_DEAL_SUCCESS =
  'signals/LOAD_SIGNALS_FOR_DEAL_SUCCESS';
export const LOAD_SIGNALS_FOR_DEAL_FAILURE =
  'signals/LOAD_SIGNALS_FOR_DEAL_FAILURE';
