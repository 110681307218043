export const ADD_REMINDER_TO_RESOURCE_REQUEST = 'reminder/ADD_REMINDER_TO_RESOURCE_REQUEST';
export const ADD_REMINDER_TO_RESOURCE_FAILURE = 'reminder/ADD_REMINDER_TO_RESOURCE_FAILURE';
export const ADD_REMINDER_TO_RESOURCE_SUCCESS = 'reminder/ADD_REMINDER_TO_RESOURCE_SUCCESS';


export const GET_REMINDER_FOR_RESOURCE_REQUEST = 'reminder/GET_REMINDER_FOR_RESOURCE_REQUEST'
export const GET_REMINDER_FOR_RESOURCE_FAILURE = 'reminder/GET_REMINDER_FOR_RESOURCE_FAILURE'
export const GET_REMINDER_FOR_RESOURCE_SUCCESS = 'reminder/GET_REMINDER_FOR_RESOURCE_SUCCESS'

export const GET_BATCH_RESOURCES_REMINDERS_REQUEST = 'reminder/GET_BATCH_RESOURCES_REMINDERS_REQUEST';
export const GET_BATCH_RESOURCES_REMINDERS_SUCCESS = 'reminder/GET_BATCH_RESOURCES_REMINDERS_SUCCESS';
export const GET_BATCH_RESOURCES_REMINDERS_FAILURE = 'reminder/GET_BATCH_RESOURCES_REMINDERS_FAILURE';


export const UPDATE_REMINDER_FOR_RESOURCE_REQUEST = 'reminder/UPDATE_REMINDER_FOR_RESOURCE_REQUEST';
export const UPDATE_REMINDER_FOR_RESOURCE_FAILURE = 'reminder/UPDATE_REMINDER_FOR_RESOURCE_FAILURE';
export const UPDATE_REMINDER_FOR_RESOURCE_SUCCESS = 'reminder/UPDATE_REMINDER_FOR_RESOURCE_SUCCESS';


export const DELETE_REMINDER_FOR_RESOURCE_REQUEST = 'reminder/DELETE_REMINDER_FOR_RESOURCE_REQUEST';
export const DELETE_REMINDER_FOR_RESOURCE_FAILURE = 'reminder/DELETE_REMINDER_FOR_RESOURCE_FAILURE';
export const DELETE_REMINDER_FOR_RESOURCE_SUCCESS = 'reminder/DELETE_REMINDER_FOR_RESOURCE_SUCCESS';
