import {
  LOAD_PORTCO_COMPANIES_SUCCESS,
  LOAD_PORTCO_COMPANIES_REQUEST,
  LOAD_PORTCO_COMPANIES_FAILURE,
} from '../actions';
import isUndefined from 'lodash/isUndefined'

export default (state, type, payload) => {
    switch(type) {
    case LOAD_PORTCO_COMPANIES_REQUEST:
      return Object.assign({}, state, {
        portCoListLoading: true,
        portCoOptions: {
          ...state.portCoOptions,
          ...(!isUndefined(payload.extra)  ? {} : payload.options)
        }
      });
      case LOAD_PORTCO_COMPANIES_SUCCESS:
        return Object.assign({}, state, {
        portCosCount: (!isUndefined(payload.metadata) && payload.metadata.count )|| 0,
        portCoListLoading: false,
        portcos: {
          ...state.portcos,
          ...payload.data.reduce((result, item) => {
              result[item.id] = state.portcos[item.id] ? {
                ...state.portcos[item.id],
                ...item
              } : item;
              return result;
            }, {})
        },
        portCoOptions: {
          ...state.portCoOptions,
          ...(!isUndefined(payload.extra)  ? {} : payload.options)
        }
        
      }
      );
        
      
      
      case LOAD_PORTCO_COMPANIES_FAILURE:
        return Object.assign({}, state, {
        portCoListLoading: false,
      });
      default:
      return state;
    }
}