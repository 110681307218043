/**
 * Unlink a deal from portfolio
 *
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  UNLINK_FROM_PORTFOLIO_REQUEST,
  UNLINK_FROM_PORTFOLIO_SUCCESS,
  UNLINK_FROM_PORTFOLIO_FAILURE,
} from '../actions';

export default dealId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UNLINK_FROM_PORTFOLIO_REQUEST,
        payload: { dealId },
      });
      request
        .put(`/api/pipeline/v6/unlink-portfolio`, { deal_id: dealId }, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: UNLINK_FROM_PORTFOLIO_SUCCESS,
              payload: {
                dealId: dealId,
                data: data.data,
              },
            });
            dispatch(
              enqueueNotification(
                `Unlinked deal from portfolio successfully. You can now update this deal`
              )
            );
            resolve(data.data);
          } else {
            dispatch({
              type: UNLINK_FROM_PORTFOLIO_FAILURE,
              payload: {
                dealId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while converting to portfolio, please try again.';
          dispatch({
            type: UNLINK_FROM_PORTFOLIO_FAILURE,
            payload: {
              dealId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
