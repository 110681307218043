/**
 * @format
 */

import './styles/index.css';
// css for `CalendarInput` component
import './styles/Calendar.css';
// css for `Editor` component
import 'react-quill/dist/quill.snow.css';
// css for react-placeholder
import 'react-placeholder/lib/reactPlaceholder.css';
// css for react-grid-layout
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import App from './containers/App';
// For  css normalization
// See https://v3.material-ui.com/style/css-baseline/
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import ScrollToTop from './components/Scroll';
import store, { history } from './store';
import theme from './theme';
import Track from './components/Track';
import { JssProvider, SheetsRegistry } from 'react-jss';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';

import { Provider } from 'react-redux';
// https://stackoverflow.com/a/50218321/5946125
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
const sheets = new SheetsRegistry();

// Initialize the tracker
Track.init(window.env.GOOGLE_ANALYTICS_TRACKING_ID, {
  titleCase: false,
  gaOptions: {
    // Specifies what percentage of users should be tracked. This defaults to
    // 100 (no users are sampled out) but large sites may need to use a lower
    // sample rate to stay within Google Analytics processing limits.
    sampleRate: 100,
    // This setting determines how often site speed beacons will be sent. By
    // default, 1% of users will be automatically be tracked.
    siteSpeedSampleRate: 100,
  },
});

ReactDOM.render(
  <React.Fragment>
    <CssBaseline />
    <Provider store={store}>
      <BrowserRouter>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <JssProvider registry={sheets}>
              <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <App />
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            </JssProvider>
          </ScrollToTop>
        </ConnectedRouter>
      </BrowserRouter>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);
registerServiceWorker();
