/**
 * Exports all the email constants
 *
 * @format
 */

export const EMAIL_SYNC_METHODS = {
  // DEFAULT's value should be the key to the prop that needs to be used as default
  DEFAULT: 'GMAIL_GSUITE',
  GMAIL_GSUITE: {
    key: 'gmail_gsuite',
    name: 'G Suite / Gmail Sync',
  },
  OUTLOOK_MICROSOFT: {
    key: 'outlook_microsoft',
    name: 'Microsoft / Outlook Sync',
    vendor: 'microsoft',
  },
  // If we add support for new forwarding email services, make sure to not
  // expose the names of services in these keys, since they will be returned
  // to the client in responses and will expose our intenal vendor stack. The
  // decision to not call this `forwarding_email_mailgun` is deliberate
  FORWARDING_EMAIL: {
    key: 'forwarding_email',
    name: 'Forwarding Email',
    vendor: 'mailgun',
  },
};

// participation level of the parties to an email
export const PARTICIPATION_LEVEL = {
  TO: 'to',
  CC: 'cc',
  BCC: 'bcc',
  FROM: 'from',
  REPLY_TO: 'reply_to',
};
