/**
 * Organizaion actions
 *
 * @author Ritesh Shrivastav
 */
export const LOAD_TEAM_MEMBERS_REQUEST = 'organization/LOAD_TEAM_MEMBERS_REQUEST';
export const LOAD_TEAM_MEMBERS_SUCCESS = 'organization/LOAD_TEAM_MEMBERS_SUCCESS';
export const LOAD_TEAM_MEMBERS_FAILURE = 'organization/LOAD_TEAM_MEMBERS_FAILURE';

export const LOAD_INVITEE_LIST_REQUEST = 'organization/LOAD_INVITEE_LIST_REQUEST';
export const LOAD_INVITEE_LIST_SUCCESS = 'organization/LOAD_INVITEE_LIST_SUCCESS';
export const LOAD_INVITEE_LIST_FAILURE = 'organization/LOAD_INVITEE_LIST_FAILURE';

export const INVITE_MEMBER_REQUEST = 'organization/INVITE_MEMBER_REQUEST';
export const INVITE_MEMBER_SUCCESS = 'organization/INVITE_MEMBER_SUCCESS';
export const INVITE_MEMBER_FAILURE = 'organization/INVITE_MEMBER_FAILURE';

export const INVITE_MEMBERS_REQUEST = 'organization/INVITE_MEMBERS_REQUEST';
export const INVITE_MEMBERS_SUCCESS = 'organization/INVITE_MEMBERS_SUCCESS';
export const INVITE_MEMBERS_FAILURE = 'organization/INVITE_MEMBERS_FAILURE';

export const LOAD_MEMBER_ROLES_REQUEST = 'organization/LOAD_MEMBER_ROLES_REQUEST';
export const LOAD_MEMBER_ROLES_SUCCESS = 'organization/LOAD_MEMBER_ROLES_SUCCESS';
export const LOAD_MEMBER_ROLES_FAILURE = 'organization/LOAD_MEMBER_ROLES_FAILURE';

export const UPDATE_MEMBER_ROLES_REQUEST = 'organization/UPDATE_MEMBER_ROLES_REQUEST';
export const UPDATE_MEMBER_ROLES_SUCCESS = 'organization/UPDATE_MEMBER_ROLES_SUCCESS';
export const UPDATE_MEMBER_ROLES_FAILURE = 'organization/UPDATE_MEMBER_ROLES_FAILURE';

export const LIST_ORGANIZATIONS_REQUEST = 'organization/LIST_ORGANIZATIONS_REQUEST';
export const LIST_ORGANIZATIONS_SUCCESS = 'organization/LIST_ORGANIZATIONS_SUCCESS';
export const LIST_ORGANIZATIONS_FAILURE = 'organization/LIST_ORGANIZATIONS_FAILURE';

export const LOAD_MEMBER_DEALS_REQUEST = 'organization/LOAD_MEMBER_DEALS_REQUEST';
export const LOAD_MEMBER_DEALS_SUCCESS = 'organization/LOAD_MEMBER_DEALS_SUCCESS';
export const LOAD_MEMBER_DEALS_FAILURE = 'organization/LOAD_MEMBER_DEALS_FAILURE';

export const DISABLE_MEMBER_ACCOUNT_REQUEST = 'organization/DISABLE_MEMBER_ACCOUNT_REQUEST';
export const DISABLE_MEMBER_ACCOUNT_SUCCESS = 'organization/DISABLE_MEMBER_ACCOUNT_SUCCESS';
export const DISABLE_MEMBER_ACCOUNT_FAILURE = 'organization/DISABLE_MEMBER_ACCOUNT_FAILURE';

export const ASSIGN_MEMBER_DEAL_TO_SELF_REQUEST = 'organization/ASSIGN_MEMBER_DEAL_TO_SELF_REQUEST';
export const ASSIGN_MEMBER_DEAL_TO_SELF_SUCCESS = 'organization/ASSIGN_MEMBER_DEAL_TO_SELF_SUCCESS';
export const ASSIGN_MEMBER_DEAL_TO_SELF_FAILURE = 'organization/ASSIGN_MEMBER_DEAL_TO_SELF_FAILURE';

export const PORTFOLIO_TAB_REQUEST = 'organization/PORTFOLIO_TAB_REQUEST';
export const PORTFOLIO_TAB_SUCCESS = 'organization/PORTFOLIO_TAB_SUCCESS';
export const PORTFOLIO_TAB_FAILURE = 'organization/PORTFOLIO_TAB_FAILURE';

export const UPDATE_PORTFOLIO_TAB_REQUEST = 'organization/UPDATE_PORTFOLIO_TAB_REQUEST';
export const UPDATE_PORTFOLIO_TAB_SUCCESS = 'organization/UPDATE_PORTFOLIO_TAB_SUCCESS';
export const UPDATE_PORTFOLIO_TAB_FAILURE = 'organization/UPDATE_PORTFOLIO_TAB_FAILURE';

export const DEAL_OVERVIEW_FIELDS_REQUEST = 'organization/DEAL_OVERVIEW_FIELDS_REQUEST';
export const DEAL_OVERVIEW_FIELDS_SUCCESS = 'organization/DEAL_OVERVIEW_FIELDS_SUCCESS';
export const DEAL_OVERVIEW_FIELDS_FAILURE = 'organization/DEAL_OVERVIEW_FIELDS_FAILURE';

export const UPDATE_DEAL_OVERVIEW_FIELDS_REQUEST = 'organization/UPDATE_DEAL_OVERVIEW_FIELDS_REQUEST';
export const UPDATE_DEAL_OVERVIEW_FIELDS_SUCCESS = 'organization/UPDATE_DEAL_OVERVIEW_FIELDS_SUCCESS';
export const UPDATE_DEAL_OVERVIEW_FIELDS_FAILURE = 'organization/UPDATE_DEAL_OVERVIEW_FIELDS_FAILURE';

export const LOAD_SHARE_DEAL_TEMPLATES_REQUEST = 'organization/LOAD_SHARE_DEAL_TEMPLATES_REQUEST';
export const LOAD_SHARE_DEAL_TEMPLATES_SUCCESS = 'organization/LOAD_SHARE_DEAL_TEMPLATES_SUCCESS';
export const LOAD_SHARE_DEAL_TEMPLATES_FAILURE = 'organization/LOAD_SHARE_DEAL_TEMPLATES_FAILURE';

export const UPDATE_SHARE_DEAL_TEMPLATE_NAME_REQUEST = 'organization/UPDATE_SHARE_DEAL_TEMPLATE_NAME_REQUEST';
export const UPDATE_SHARE_DEAL_TEMPLATE_NAME_SUCCESS = 'organization/UPDATE_SHARE_DEAL_TEMPLATE_NAME_SUCCESS';
export const UPDATE_SHARE_DEAL_TEMPLATE_NAME_FAILURE = 'organization/UPDATE_SHARE_DEAL_TEMPLATE_NAME_FAILURE';

export const DELETE_SHARE_DEAL_TEMPLATE_REQUEST = 'organization/DELETE_SHARE_DEAL_TEMPLATE_REQUEST';
export const DELETE_SHARE_DEAL_TEMPLATE_SUCCESS = 'organization/DELETE_SHARE_DEAL_TEMPLATE_SUCCESS';
export const DELETE_SHARE_DEAL_TEMPLATE_FAILURE = 'organization/DELETE_SHARE_DEAL_TEMPLATE_FAILURE';