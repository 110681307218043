import supportsHTML5Storage from './supportsHTML5Storage';
import Time from '../components/Time';

const setTableRowsettingsCache = (data, workspace_id) => {
  if (!supportsHTML5Storage()) { return };
  let tableRowsettings = window.localStorage.getItem(
    'tableRowsettings'
  );
  if (tableRowsettings && typeof tableRowsettings === 'string') {
    try {
      tableRowsettings = JSON.parse(tableRowsettings);
      // If expires_on is in the past, expire
      if (new Date(tableRowsettings.expires_on) < new Date()) {
        window.localStorage.removeItem('tableRowsettings');
        tableRowsettings = {value:{}};
      }
    } catch (e) {
      return null;
    }
  } else {
    tableRowsettings = {value:{}};
  }
  const payload = {
    value: {
      ...tableRowsettings.value,
      [workspace_id]: data
    },
    expires_on: Time.add(new Date(), 15, 'minute'), 
  };
  window.localStorage.removeItem("tableRowsettings");
  window.localStorage.setItem("tableRowsettings", JSON.stringify(payload));
}

export default setTableRowsettingsCache;
