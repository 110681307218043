/**
 * CLEAR_DEAL_ID_LIST reducer
 *
 * @format
 */
import { CLEAR_DEAL_ID_LIST } from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case CLEAR_DEAL_ID_LIST:
      return Object.assign({}, state, {
        dealsIdsList: [], // clears deals ids list
      });

    default:
      return state;
  }
};
