export const LOAD_PORTFOLIO_CAPTABLE_REQUEST = 'portfolio-captable/LOAD_PORTFOLIO_CAPTABLE_REQUEST';
export const LOAD_PORTFOLIO_CAPTABLE_SUCCESS = 'portfolio-captable/LOAD_PORTFOLIO_CAPTABLE_SUCCESS';
export const LOAD_PORTFOLIO_CAPTABLE_FAILURE = 'portfolio-captable/LOAD_PORTFOLIO_CAPTABLE_FAILURE';

export const UPDATE_PORTFOLIO_CAPTABLE = 'portfolio-captable/UPDATE_PORTFOLIO_CAPTABLE';

export const LOAD_PORTFOLIO_CAPTABLE_SHARES_LOG_REQUEST = `portfolio-captable/LOAD_PORTFOLIO_CAPTABLE_SHARES_LOG_REQUEST`;
export const LOAD_PORTFOLIO_CAPTABLE_SHARES_LOG_SUCCESS = `portfolio-captable/LOAD_PORTFOLIO_CAPTABLE_SHARES_LOG_SUCCESS`;
export const LOAD_PORTFOLIO_CAPTABLE_SHARES_LOG_FAILURE = `portfolio-captable/LOAD_PORTFOLIO_CAPTABLE_SHARES_LOG_FAILURE`;
export const CLEAR_PORTFOLIO_CAPTABLE_SHARES_LOG = `portfolio-captable/CLEAR_PORTFOLIO_CAPTABLE_SHARES_LOG`;

export const DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG_REQUEST = `portfolio-captable/DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG_REQUEST`;
export const DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG_SUCCESS = `portfolio-captable/DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG_SUCCESS`;
export const DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG_FAILURE = `portfolio-captable/DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG_FAILURE`;
