export const CLEAR_EMAILS = 'email/CLEAR_EMAILS';


export const LOAD_MY_EMAIL_LIST_REQUEST = 'email/LOAD_MY_EMAIL_LIST_REQUEST';
export const LOAD_MY_EMAIL_LIST_SUCCESS = 'email/LOAD_MY_EMAIL_LIST_SUCCESS';
export const LOAD_MY_EMAIL_LIST_FAILURE = 'email/LOAD_MY_EMAIL_LIST_FAILURE';

export const LOAD_EMAIL_LIST_REQUEST = 'email/LOAD_EMAIL_LIST_REQUEST';
export const LOAD_EMAIL_LIST_SUCCESS = 'email/LOAD_EMAIL_LIST_SUCCESS';
export const LOAD_EMAIL_LIST_FAILURE = 'email/LOAD_EMAIL_LIST_FAILURE';

export const LOAD_TEAM_EMAIL_LIST_REQUEST = 'email/LOAD_TEAM_EMAIL_LIST_REQUEST';
export const LOAD_TEAM_EMAIL_LIST_SUCCESS = 'email/LOAD_TEAM_EMAIL_LIST_SUCCESS';
export const LOAD_TEAM_EMAIL_LIST_FAILURE = 'email/LOAD_TEAM_EMAIL_LIST_FAILURE';

export const LOAD_EMAIL_REQUEST = 'email/LOAD_EMAIL_REQUEST';
export const LOAD_EMAIL_SUCCESS = 'email/LOAD_EMAIL_SUCCESS';
export const LOAD_EMAIL_FAILURE = 'email/LOAD_EMAIL_FAILURE';

export const CREATE_DEAL_REQUEST = 'email/CREATE_DEAL_REQUEST';
export const CREATE_DEAL_SUCCESS = 'email/CREATE_DEAL_SUCCESS';
export const CREATE_DEAL_FAILURE = 'email/CREATE_DEAL_FAILURE';

export const ADD_TO_DEAL_REQUEST = 'email/ADD_TO_DEAL_REQUEST';
export const ADD_TO_DEAL_SUCCESS = 'email/ADD_TO_DEAL_SUCCESS';
export const ADD_TO_DEAL_FAILURE = 'email/ADD_TO_DEAL_FAILURE';

export const MARK_SPAM_REQUEST = 'email/MARK_SPAM_REQUEST';
export const MARK_SPAM_SUCCESS = 'email/MARK_SPAM_SUCCESS';
export const MARK_SPAM_FAILURE = 'email/MARK_SPAM_FAILURE';

export const IGNORE_EMAIL_REQUEST = 'email/IGNORE_EMAIL_REQUEST';
export const IGNORE_EMAIL_SUCCESS = 'email/IGNORE_EMAIL_SUCCESS';
export const IGNORE_EMAIL_FAILURE = 'email/IGNORE_EMAIL_FAILURE';

export const SET_EMAIL_DATA = 'email/SET_EMAIL_DATA';
