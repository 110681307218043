/** @format */
import axios from 'axios';
import * as types from './actions';
import reducers from './reducers';
import request, { ApplicationError } from '../../../helpers/request';
import { enqueueNotification } from '../../app';

export default reducers;

export const createPersonContactDocuments = (
  personContactId,
  files,
  options
) => {
  return async dispatch => {
    try {
      if (!personContactId || !files || !files.length) {
        throw new ApplicationError('Please select a file to upload');
      }
      dispatch({
        type: types.CREATE_PERSON_CONTACT_DOCUMENT_REQUEST,
        payload: {
          personContactId,
        },
      });
      const response = await request.post(
        `/api/contact-documents/v1/person/${personContactId}/documents`,
        {
          files,
          // optional. also creates a v1 attachment record for this document
          shouldCreateV1Attachment: options.shouldCreateV1Attachment,
        },
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.CREATE_PERSON_CONTACT_DOCUMENT_SUCCESS,
        payload: {
          personContactId,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      let message = err.isApplicationError
        ? err.message
        : 'Something went wrong while creating person contact document. Please try again.';

      if (err?.response?.status === 429) {
        message = `Too fast! Try again in a couple of seconds.`;
      }
      dispatch({
        type: types.CREATE_PERSON_CONTACT_DOCUMENT_FAILURE,
        payload: {
          personContactId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const listPersonContactDocuments = (personContactId, options = {}) => {
  return async dispatch => {
    try {
      dispatch({
        type: types.LIST_PERSON_CONTACT_DOCUMENTS_REQUEST,
        payload: {
          personContactId,
          options,
        },
      });
      const response = await request.get(
        `/api/contact-documents/v1/person/${personContactId}/documents`,
        options,
        dispatch
      );
      const data = response.data;

      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.LIST_PERSON_CONTACT_DOCUMENTS_SUCCESS,
        payload: {
          personContactId,
          options,
          data: data.data,
          metadata: data.metadata,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while listing person contact documents. Please try again.';
      dispatch({
        type: types.LIST_PERSON_CONTACT_DOCUMENTS_FAILURE,
        payload: {
          personContactId,
          options,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const getPersonContactDocumentSignedUrl = (
  personContactId,
  documentId,
  options = {}
) => {
  return async dispatch => {
    try {
      dispatch({
        type: types.GET_PERSON_CONTACT_DOCUMENT_SIGNEDURL_REQUEST,
        payload: {
          personContactId,
          documentId,
        },
      });
      const response = await request.get(
        `/api/contact-documents/v1/person/${personContactId}/documents/${documentId}/url`,
        options,
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.GET_PERSON_CONTACT_DOCUMENT_SIGNEDURL_SUCCESS,
        payload: {
          personContactId,
          documentId,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while loading document. Please try again.';
      dispatch({
        type: types.GET_PERSON_CONTACT_DOCUMENT_SIGNEDURL_FAILURE,
        payload: {
          personContactId,
          documentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const addTag = (personContactId, documentId, tag) => {
  return async dispatch => {
    try {
      dispatch({
        type: types.ADD_TAG_TO_PERSON_CONTACT_DOCUMENT_REQUEST,
        payload: {
          personContactId,
          documentId,
          tag,
        },
      });
      const response = await request.post(
        `/api/contact-documents/v1/person/${personContactId}/documents/${documentId}/tag`,
        { tag },
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.ADD_TAG_TO_PERSON_CONTACT_DOCUMENT_SUCCESS,
        payload: {
          personContactId,
          documentId,
          tag,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while adding tag to document. Please try again.';
      dispatch({
        type: types.ADD_TAG_TO_PERSON_CONTACT_DOCUMENT_FAILURE,
        payload: {
          personContactId,
          documentId,
          tag,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const removeTag = (personContactId, documentId, tag) => {
  return async dispatch => {
    try {
      dispatch({
        type: types.REMOVE_TAG_FROM_PERSON_CONTACT_DOCUMENT_REQUEST,
        payload: {
          personContactId,
          documentId,
          tag,
        },
      });
      const response = await request.del(
        `/api/contact-documents/v1/person/${personContactId}/documents/${documentId}/tag`,
        { tag },
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.REMOVE_TAG_FROM_PERSON_CONTACT_DOCUMENT_SUCCESS,
        payload: {
          personContactId,
          documentId,
          tag,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while removing tag from document. Please try again.';
      dispatch({
        type: types.REMOVE_TAG_FROM_PERSON_CONTACT_DOCUMENT_FAILURE,
        payload: {
          personContactId,
          documentId,
          tag,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const deletePersonContactDocument = (personContactId, documentId) => {
  return async dispatch => {
    try {
      dispatch({
        type: types.DELETE_PERSON_CONTACT_DOCUMENT_REQUEST,
        payload: {
          personContactId,
          documentId,
        },
      });
      const response = await request.del(
        `/api/contact-documents/v1/person/${personContactId}/documents/${documentId}`,
        {},
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.DELETE_PERSON_CONTACT_DOCUMENT_SUCCESS,
        payload: {
          personContactId,
          documentId,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while deleting document. Please try again.';
      dispatch({
        type: types.DELETE_PERSON_CONTACT_DOCUMENT_FAILURE,
        payload: {
          personContactId,
          documentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const hideOrUnhidePersonContactDocument = (
  personContactId,
  documentId,
  isHidden
) => {
  return async dispatch => {
    try {
      dispatch({
        type: types.HIDE_OR_UNHIDE_PERSON_CONTACT_DOCUMENT_REQUEST,
        payload: {
          personContactId,
          documentId,
        },
      });
      const response = await request.put(
        `/api/contact-documents/v1/person/${personContactId}/documents/${documentId}/hide_unhide`,
        { is_hidden: isHidden },
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.HIDE_OR_UNHIDE_PERSON_CONTACT_DOCUMENT_SUCCESS,
        payload: {
          personContactId,
          documentId,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while deleting document. Please try again.';
      dispatch({
        type: types.HIDE_OR_UNHIDE_PERSON_CONTACT_DOCUMENT_FAILURE,
        payload: {
          personContactId,
          documentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const listTags = () => {
  return async dispatch => {
    try {
      dispatch({
        type: types.LIST_PERSON_CONTACT_DOCUMENT_TAGS_REQUEST,
        payload: {},
      });
      const response = await request.get(
        `/api/contact-documents/v1/person/tags`,
        {},
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.LIST_PERSON_CONTACT_DOCUMENT_TAGS_SUCCESS,
        payload: {
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while listing tags. Please try again.';
      dispatch({
        type: types.LIST_PERSON_CONTACT_DOCUMENT_TAGS_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

// https://medium.com/@kevinwu/client-side-file-upload-to-s3-using-axios-c9363ec7b530
export const fileUploadToS3 = (documentId, signedURL, file) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.FILE_UPLOAD_S3_REQUEST,
        payload: { documentId },
      });
      const attachmentContentType =
        file?.mime_type || file?.attachment?.mime_type;
      const fileContentType = file.ref?.type;
      if (
        attachmentContentType &&
        fileContentType &&
        attachmentContentType !== fileContentType
      ) {
        const message =
          'Something went wrong while uploading document, please try again.';
        dispatch({
          type: types.FILE_UPLOAD_S3_FAILURE,
          payload: {
            documentId,
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return Promise.reject(new Error(message));
      }
      axios
        .put(signedURL, file.ref ? file.ref : file, {
          headers: {
            'Content-Type': attachmentContentType
              ? attachmentContentType
              : file.ref
              ? file.ref.type
              : file.mime_type || file.type,
          },
        })
        .then(({ data }) => {
          dispatch({
            type: types.FILE_UPLOAD_S3_SUCCESS,
            payload: { documentId, data },
          });
          resolve(data);
        })
        .catch(err => {
          const message =
            'Something went wrong while uploading document, please try again.';
          dispatch({
            type: types.FILE_UPLOAD_S3_FAILURE,
            payload: {
              documentId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const fileDownloadFromS3 = (documentId, signedURL, options) => {
  return async dispatch => {
    dispatch({
      type: types.FILE_DOWNLOAD_S3_REQUEST,
      payload: { documentId },
    });
    let data;
    try {
      // Note the difference here
      const response = await axios.get(signedURL, options);
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while downloading document, please try again.';
      dispatch({
        type: types.FILE_DOWNLOAD_S3_FAILURE,
        payload: {
          documentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    dispatch({
      type: types.FILE_DOWNLOAD_S3_SUCCESS,
      payload: { documentId, data },
    });
    return data;
  };
};

// Clears redux state
export const clearPersonContactDocumentsState = () => dispatch =>
  dispatch({ type: types.CLEAR });
