export const LOAD_CONVERSATION_LIST_REQUEST = 'portfolio-conversation/LOAD_CONVERSATION_LIST_REQUEST';
export const LOAD_CONVERSATION_LIST_SUCCESS = 'portfolio-conversation/LOAD_CONVERSATION_LIST_SUCCESS';
export const LOAD_CONVERSATION_LIST_FAILURE = 'portfolio-conversation/LOAD_CONVERSATION_LIST_FAILURE';

export const LOAD_CONVERSATION_REQUEST = 'portfolio-conversation/LOAD_CONVERSATION_REQUEST';
export const LOAD_CONVERSATION_SUCCESS = 'portfolio-conversation/LOAD_CONVERSATION_SUCCESS';
export const LOAD_CONVERSATION_FAILURE = 'portfolio-conversation/LOAD_CONVERSATION_FAILURE';

export const LOAD_PARTICIPANT_LIST_REQUEST = 'portfolio-conversation/LOAD_PARTICIPANT_LIST_REQUEST';
export const LOAD_PARTICIPANT_LIST_SUCCESS = 'portfolio-conversation/LOAD_PARTICIPANT_LIST_SUCCESS';
export const LOAD_PARTICIPANT_LIST_FAILURE = 'portfolio-conversation/LOAD_PARTICIPANT_LIST_FAILURE';

export const CREATE_CONVERSATION_REQUEST = 'portfolio-conversation/CREATE_CONVERSATION_REQUEST';
export const CREATE_CONVERSATION_SUCCESS = 'portfolio-conversation/CREATE_CONVERSATION_SUCCESS';
export const CREATE_CONVERSATION_FAILURE = 'portfolio-conversation/CREATE_CONVERSATION_FAILURE';

export const DELETE_CONVERSATION_REQUEST = 'portfolio-conversation/DELETE_CONVERSATION_REQUEST';
export const DELETE_CONVERSATION_SUCCESS = 'portfolio-conversation/DELETE_CONVERSATION_SUCCESS';
export const DELETE_CONVERSATION_FAILURE = 'portfolio-conversation/DELETE_CONVERSATION_FAILURE';

export const TOGGLE_CONVERSATION_AS_STARRED_REQUEST = 'portfolio-conversation/TOGGLE_CONVERSATION_AS_STARRED_REQUEST';
export const TOGGLE_CONVERSATION_AS_STARRED_SUCCESS = 'portfolio-conversation/TOGGLE_CONVERSATION_AS_STARRED_SUCCESS';
export const TOGGLE_CONVERSATION_AS_STARRED_FAILURE = 'portfolio-conversation/TOGGLE_CONVERSATION_AS_STARRED_FAILURE'; 
