import * as types from './actions';
import reducers from './reducers';
import { wrap, api } from '../../helpers/request';

// mark reducers as the default export
export default reducers;

// FetchBoards fetches a list of all registered boards this user has access to.
export function fetchBoards() {
  return async function(dispatch) {
    const { data } = await wrap(api.get('/api/portfolio-analytics/v1/boards'));
    if (!data.success) {
      throw new Error(data.message);
    }
    dispatch({ type: types.FETCH_ALL_BOARDS, payload: data.data });
    return data.data;
  }
}

// CreateBoard creates a new user-defined dashboard.
export function createBoard(body) {
  return async function(dispatch) {
    const { data } = await wrap(api.post('/api/portfolio-analytics/v1/boards', body));
    if (!data.success) {
      throw new Error(data.message);
    }
    await fetchBoards()(dispatch);

    return data.data
  }
}