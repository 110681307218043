/**
 * Function which checks for email validity.
 * @author Akhila
 * 
 * @param  {[String]} email To be checked.
 * @return {[Boolean]} 
 */
export const isEmailValid = (email) => {
  const regEx = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); 
  return regEx.test(email);
}
