
export const ADD_FUND_NOTE_REQUEST = 'fund-note/ADD_FUND_NOTE_REQUEST';
export const ADD_FUND_NOTE_SUCCESS = 'fund-note/ADD_FUND_NOTE_SUCCESS';
export const ADD_FUND_NOTE_FAILURE = 'fund-note/ADD_FUND_NOTE_FAILURE';

export const UPDATE_FUND_NOTE_REQUEST = 'fund-note/UPDATE_FUND_NOTE_REQUEST';
export const UPDATE_FUND_NOTE_SUCCESS = 'fund-note/UPDATE_FUND_NOTE_SUCCESS';
export const UPDATE_FUND_NOTE_FAILURE = 'fund-note/UPDATE_FUND_NOTE_FAILURE';

export const LIST_FUND_NOTES_REQUEST = 'fund-note/LIST_FUND_NOTES_REQUEST';
export const LIST_FUND_NOTES_SUCCESS = 'fund-note/LIST_FUND_NOTES_SUCCESS';
export const LIST_FUND_NOTES_FAILURE = 'fund-note/LIST_FUND_NOTES_FAILURE';

export const GET_FUND_NOTE_REQUEST = 'fund-note/GET_FUND_NOTE_REQUEST';
export const GET_FUND_NOTE_SUCCESS = 'fund-note/GET_FUND_NOTE_SUCCESS';
export const GET_FUND_NOTE_FAILURE = 'fund-note/GET_FUND_NOTE_FAILURE';

export const GET_PINNED_FUND_NOTE_REQUEST = 'fund-note/GET_PINNED_FUND_NOTE_REQUEST';
export const GET_PINNED_FUND_NOTE_SUCCESS = 'fund-note/GET_PINNED_FUND_NOTE_SUCCESS';
export const GET_PINNED_FUND_NOTE_FAILURE = 'fund-note/GET_PINNED_FUND_NOTE_FAILURE';

export const DELETE_FUND_NOTE_REQUEST = 'fund-note/DELETE_FUND_NOTE_REQUEST';
export const DELETE_FUND_NOTE_SUCCESS = 'fund-note/DELETE_FUND_NOTE_SUCCESS';
export const DELETE_FUND_NOTE_FAILURE = 'fund-note/DELETE_FUND_NOTE_FAILURE';

export const LOAD_NOTE_REPLY_LIST_REQUEST ='fund-note/LOAD_NOTE_REPLY_LIST_REQUEST'
export const LOAD_NOTE_REPLY_LIST_SUCCESS ='fund-note/LOAD_NOTE_REPLY_LIST_SUCCESS'
export const LOAD_NOTE_REPLY_LIST_FAILURE ='fund-note/LOAD_NOTE_REPLY_LIST_FAILURE'

export const LOAD_NOTE_REPLY_COUNT_REQUEST ='fund-note/LOAD_NOTE_REPLY_COUNT_REQUEST'
export const LOAD_NOTE_REPLY_COUNT_SUCCESS ='fund-note/LOAD_NOTE_REPLY_COUNT_SUCCESS'
export const LOAD_NOTE_REPLY_COUNT_FAILURE ='fund-note/LOAD_NOTE_REPLY_COUNT_FAILURE'


// This is handled with `GET_FUND_NOTE`
// export const GET_FUND_PINNED_NOTE_REQUEST = 'fund-note/GET_FUND_PINNED_NOTE_REQUEST';
// export const GET_FUND_PINNED_NOTE_SUCCESS = 'fund-note/GET_FUND_PINNED_NOTE_SUCCESS';
// export const GET_FUND_PINNED_NOTE_FAILURE = 'fund-note/GET_FUND_PINNED_NOTE_FAILURE';

// This is handled with `UPDATE_FUND_NOTE`
// export const UPDATE_FUND_PINNED_NOTE_REQUEST = 'fund-note/UPDATE_FUND_PINNED_NOTE_REQUEST';
// export const UPDATE_FUND_PINNED_NOTE_SUCCESS = 'fund-note/UPDATE_FUND_PINNED_NOTE_SUCCESS';
// export const UPDATE_FUND_PINNED_NOTE_FAILURE = 'fund-note/UPDATE_FUND_PINNED_NOTE_FAILURE';
