/**
 * Holds all the deal columns, do not modify keys.
 *
 * To add a new column:
 * - add here
 * - implement appropriate AbcCell container, AbcCell, AbcCell/Viewer, AbcCell/Viewers
 * - add spec in getColumns
 * - add in defaultTableColumnConfig
 * - add transformation in getRowStructure
 * - add support in backend to save new column
 */
const PortfolioTableViewColumn = {
    SERIAL_NO:{
      key: 'serial_no',
      sticky: true,
      name: ''
    },
    PORTCO: {
      //unique deal
      key: 'portco',
      // the columns with `sticky` property set to true are considered fixed,
      // which is mostly first column of the table - deal name
      sticky: true,
      // Name of the column to be displayed
      name: 'Company',
    },
    MANAGERS: {
      key: 'managers',
      name: 'Managers',
    },
    CURRENCY: {
      key: 'currency',
      name: 'currency',
    },
    CREATED_AT: {
      key: 'created_at',
      name: 'Created At',
    },
    LAST_ACTIVE_AT: {
      key: 'last_active_at',
      name: 'Last Active At',
    },
    ADDED_BY: {
      key: 'creator',
      name: 'Added By',
    },
    STARTUP_FOUNDERS: {
      key: 'startup_founders',
      name: 'Founders',
    },
    STARTUP_DESCRIPTION: {
      key: 'startup_description',
      name: 'Description',
    },
    STARTUP_REGISTERED_NAME: {
      key: 'startup_registered_name',
      name: 'Registered Name',
    },
    STARTUP_URL: {
      key: 'startup_url',
      name: 'Website',
    },
    STARTUP_EMAIL: {
      key: 'startup_email',
      name: 'Contact Email',
    },
    STARTUP_PHONE_NUMBER: {
      key: 'startup_phone_number',
      name: 'Contact Phone Number',
    },
    STARTUP_OPERATIONAL_STATUS: {
      key: 'startup_operational_status',
      name: 'Operational Status',
    },
    STARTUP_LOCAL_OP_ADDRESS: {
      key: 'startup_local_ops_address',
      name: 'Local Operational Address',
    },
    STARTUP_GLOBAL_OP_ADDRESS: {
      key: 'startup_global_ops_address',
      name: 'Global Operational Address',
    },
    STARTUP_FOUNDING_YEAR: {
      key: 'startup_founding_year',
      name: 'Founding Year',
    },
    STARTUP_EMPLOYEE_COUNT: {
      key: 'startup_employee_count',
      name: 'Employee Count',
    },
    // ATTACHMENTS: {
    //   key: 'attachments',
    //   name: 'Attachments',
    // },
    SOCIAL_LINKS: {
      key: 'social_links',
      name: 'Social links',
    },
    FUNDS:{
      key: 'funds',
      name: 'Funds'
    }
}

export default PortfolioTableViewColumn