/**
 * User Components renders nothing but name or email. Implimented mostly to
 * check the user object, on type, structure etc
 *
 * @author Akhila
 * @author Ritesh Shrivastav
 *
 * @format
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Hint from '../Hint';
import indigo from '@material-ui/core/colors/indigo';
import { isEmailValid } from '../../helpers/string/validEmail';
import { organizationRoles } from '../../constants/Organization';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    padding: '4px 6px',
    background: indigo[50],
    margin: theme.spacing(0.5),
    borderRadius: '2px',
  },
  inline: {
    display: 'inline-block',
  },
  small: {
    fontSize: '12px',
  },
});

class User extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    inline: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['small']),

    id: PropTypes.string,
    nameOrEmail: PropTypes.string.isRequired,
    email_primary: PropTypes.string.isRequired,
  };

  static defaultProps = {
    inline: false,
    nameOrEmail: '',
  };

  /**
   * This returns name or full name or email or an empty string
   * @param  {Object} userObj
   * @return {String}   nameOrEmail, fullname, email_primary of user obj
   */
  static getNameOrEmail = userObj => {
    if (!userObj || typeof userObj !== 'object') return null;
    const { nameOrEmail, fullname, email_primary } = userObj;
    if (typeof nameOrEmail === 'string' && nameOrEmail.trim().length > 0) {
      return nameOrEmail;
    }
    if (typeof fullname === 'string' && fullname.trim().length > 0) {
      return fullname;
    }
    return email_primary;
  };

  /**
   * Validates the  user object by checking if user has valid `email_primary`
   * property.
   *
   * @param  {Object} userObj
   * @return {Boolean} true only if userObject has valid email_primary
   */
  static isValidUser = userObj => {
    if (
      userObj &&
      typeof userObj === 'object' &&
      userObj.email_primary &&
      isEmailValid(userObj.email_primary)
    )
      return true;
    return false;
  };

  /**
   * Validates the  user object by checking if user is an investor user
   *
   * @param  {Object} userObj
   * @return {Boolean} true only if userObject is a valid investor
   */
  static isValidInvestorUser = userObj => {
    if (
      User.isValidUser(userObj) &&
      userObj.investor &&
      userObj.investor.organizations &&
      userObj.investor.organizations.length > 0 &&
      userObj.investor.organizations[0] &&
      userObj.investor.organizations[0].id
    )
      return true;
    return false;
  };

  /**
   * Returns the organization for the given user, or returns null
   *
   * @param  {Object} userObj
   * @return {Object} returns organization if userObject is a valid investor
   */
  static getFirstOrganizationSync = userObj => {
    if (User.isValidInvestorUser(userObj))
      return userObj.investor.organizations[0];
    return null;
  };

  /**
   * Validates if the passed user is a taghash admin
   * @param  {Object} userObj User object
   * @return {Boolean}        True only if passed user object is a Taghash admin
   */
  static isTaghashAdmin = userObj => {
    return (
      User.isValidUser(userObj) &&
      userObj.roles &&
      userObj.roles.indexOf('super-admin') > -1
    );
  };

  /**
   * Validates if the passed user is an admin in their organization
   * @param  {Object} userObj User object
   * @return {Boolean}        True only if passed user object is an Org admin
   */
  static isOrganizationAdmin = userObj => {
    if (!User.isValidUser(userObj)) {
      return false;
    }
    const orgRoles = User.getOrgRoles(userObj);
    return orgRoles.indexOf(organizationRoles.ADMIN) > -1;
  };

  /**
   * Validates if the passed user is an account manager in their organization.
   * @param  {Object} userObj User object
   * @return {Boolean}        True only if passed user object is an Account manager
   */
  static isAccountManager = userObj => {
    if (!User.isValidUser(userObj)) {
      return false;
    }
    const orgRoles = User.getOrgRoles(userObj);
    return (
      orgRoles.indexOf(organizationRoles.ACCOUNT_MANAGER) > -1 ||
      User.isOrganizationAdmin(userObj)
    );
  };

  /**
   * Validates if the passed user is an portfolioAdmin
   * @param  {Object} userObj User object
   * @return {Boolean}        True only if passed user object is an portfolioAdmin
   */
  static isPortfolioAdmin = userObj => {
    if (!User.isValidUser(userObj)) {
      return false;
    }
    const orgRoles = User.getOtherOrgRoles(userObj);
    return orgRoles.indexOf(organizationRoles.PORTFOLIO_ADMIN) > -1;
  };

  /**
   * Validates if the passed user is an portfolioAnalyst
   * @param  {Object} userObj User object
   * @return {Boolean}        True only if passed user object is an portfolioAnalyst
   */
  static isPortfolioAnalyst = userObj => {
    if (!User.isValidUser(userObj)) {
      return false;
    }
    const orgRoles = User.getOtherOrgRoles(userObj);
    return orgRoles.indexOf(organizationRoles.PORTFOLIO_ANALYST) > -1;
  };

  /**
   * Validates if the passed user is an not an admin, not a portfolio-admin only portfolioAnalyst
   * @param  {Object} userObj User object
   * @return {Boolean}        True only if passed user object is only portfolioAnalyst
   */
  static isOnlyPortfolioAnalyst = userObj => {
    if (!User.isValidUser(userObj)) {
      return false;
    }
    const orgRoles = User.getOtherOrgRoles(userObj);
    return (
      orgRoles.indexOf(organizationRoles.ADMIN) < 0 &&
      orgRoles.indexOf(organizationRoles.PORTFOLIO_ADMIN) < 0 &&
      orgRoles.indexOf(organizationRoles.PORTFOLIO_ANALYST) > -1
    );
  };

  /**
   * Validates if the passed user is a pipelineAdmin
   * @param  {Object} userObj User object
   * @return {Boolean}        True only if passed user object is a pipelineAdmin
   */
  static isPipelineAdmin = userObj => {
    if (!User.isValidUser(userObj)) {
      return false;
    }
    const orgRoles = User.getOtherOrgRoles(userObj);
    return orgRoles.indexOf(organizationRoles.PIPELINE_ADMIN) > -1;
  };

  // We need to change the name of the existing function as it only loads the default roles, but
  // not all the organizational level roles. Food for thought?
  /**
   * Returns org-roles for a passed user. Returns empty array if user object is
   * not a valid user, or not an investor
   * @param  {Object} userObj User object
   * @return {String[]}       Array of roles for user in organization
   */
  static getOrgRoles = userObj => {
    if (!User.isValidUser(userObj)) {
      return [];
    }
    return userObj.investor &&
      userObj.investor.organizations &&
      userObj.investor.organizations.length &&
      userObj.investor.organizations[0] &&
      userObj.investor.organizations[0]._roles &&
      userObj.investor.organizations[0]._roles.length
      ? userObj.investor.organizations[0]._roles
      : [];
  };

  /**
   * Returns optional org-roles for a passed user. Returns empty array if user object is
   * not a valid user, or not an investor
   * @param  {Object} userObj User object
   * @return {String[]}       Array of roles for user in organization
   */
  static getOtherOrgRoles = userObj => {
    if (!User.isValidUser(userObj)) {
      return [];
    }
    return userObj && userObj.roles && userObj.roles.length
      ? userObj.roles
      : [];
  };

  /**
   * This is used to check just the structure of the user object, we are not
   * checking if there is correct value(should not be `undefined` though) for
   * any property or not. Currently we need `nameOrEmail` and `email_primary.
   *
   * Other properties in user object are optional.
   * @param  {Object} userObj
   * @return {Object} If user is valid, returns the userObj as it is, otherwise
   * null.
   */
  static checkStructureAndGetUser = userObj => {
    if (User.isValidUser(userObj)) return userObj;
    return null;
  };

  /**
   * This method joins the first letters of first_name and last_name.
   *
   * @param  {Object} userObj User object
   * @param  {Object} userObj.fullname Full name of the ser
   * @return {String} Combination of first letters from first_name and
   * last_name of userObj
   */
  static getLetterTokesFromObject = userObj => {
    if (!userObj) return null;
    const nameOrEmail = User.getNameOrEmail(userObj);
    if (!nameOrEmail) return null;
    // nameOrEmail can be also be in this form 'akhihegde12@gmail.com
    // <akhihegde12@gmail.com>' , so we take the 'akhihegde12'
    const nameTokens = nameOrEmail
      .split('<')[0]
      .split('@')[0]
      .split(' ')
      .filter(s => s.trim().length > 0);

    return nameTokens.length > 1
      ? nameTokens
          .map(words => words.charAt(0))
          .join('')
          .substring(0, 2)
      : nameTokens[0]
      ? nameTokens[0].substring(0, 2)
      : '';
  };

  /**
   * Hint will be the amalgamation user's name and email.
   *
   * @param  {Object} userObj
   * @param {String} userObj.nameOrEmail Contains fullname or email of userObj
   * @param {String} userObj.email_primary Contains email of userObj
   * @return {String} Hint will be combination of the nameOrEmail email_primary
   * example:  'Akhila Hegde <akhihegde12@gmail.com>'
   */
  static getUserHint = userObj => {
    if (!userObj) return null;
    const nameOrEmail = User.getNameOrEmail(userObj);
    if (!nameOrEmail) return null;
    return nameOrEmail === userObj.email_primary
      ? nameOrEmail
      : `${nameOrEmail} <${userObj.email_primary}>`;
  };

  /**
   * Returns true if given user will be able to manage deal table columns
   */
  static canManageDealTableColumns = user => {
    return (
      User.isAccountManager(user) || User.isOrganizationAdmin(user) || false
    );
  };

  /**
   * Returns true if given user will be able to manage table row settings
   */
  static canManageDealTableRowSettings = user => {
    return (
      User.isAccountManager(user) || User.isOrganizationAdmin(user) || false
    );
  };

  render() {
    const { nameOrEmail, email_primary, classes, inline, size } = this.props;
    return (
      <>
        <Hint title={email_primary}>
          {nameOrEmail && email_primary ? (
            <div
              className={classNames(
                classes.root,
                inline && classes.inline,
                size && classes[size]
              )}
            >
              {nameOrEmail.trim() || email_primary}{' '}
            </div>
          ) : (
            <div className={classNames(inline && classes.inline)}> -</div>
          )}
        </Hint>
      </>
    );
  }
}

export default withStyles(styles)(User);
