export const LOAD_NOTE_LIST_REQUEST = 'note/LOAD_NOTE_LIST_REQUEST';
export const LOAD_NOTE_LIST_SUCCESS = 'note/LOAD_NOTE_LIST_SUCCESS';
export const LOAD_NOTE_LIST_FAILURE = 'note/LOAD_NOTE_LIST_FAILURE';

export const UPDATE_DEAL_NOTE_REQUEST = 'note/UPDATE_DEAL_NOTE_REQUEST';
export const UPDATE_DEAL_NOTE_SUCCESS = 'note/UPDATE_DEAL_NOTE_SUCCESS';
export const UPDATE_DEAL_NOTE_FAILURE = 'note/UPDATE_DEAL_NOTE_FAILURE';

export const DELETE_DEAL_NOTE_REQUEST = 'note/DELETE_DEAL_NOTE_REQUEST';
export const DELETE_DEAL_NOTE_SUCCESS = 'note/DELETE_DEAL_NOTE_SUCCESS';
export const DELETE_DEAL_NOTE_FAILURE = 'note/DELETE_DEAL_NOTE_FAILURE';

export const LOAD_NOTE_REPLY_LIST_REQUEST ='note/LOAD_NOTE_REPLY_LIST_REQUEST'
export const LOAD_NOTE_REPLY_LIST_SUCCESS ='note/LOAD_NOTE_REPLY_LIST_SUCCESS'
export const LOAD_NOTE_REPLY_LIST_FAILURE ='note/LOAD_NOTE_REPLY_LIST_FAILURE'
export const LOAD_NOTE_REPLY_COUNT_REQUEST ='note/LOAD_NOTE_REPLY_COUNT_REQUEST'
export const LOAD_NOTE_REPLY_COUNT_SUCCESS ='note/LOAD_NOTE_REPLY_COUNT_SUCCESS'
export const LOAD_NOTE_REPLY_COUNT_FAILURE ='note/LOAD_NOTE_REPLY_COUNT_FAILURE'

export const TRANSCRIBE_NOTE_REQUEST = 'note/TRANSCRIBE_NOTE_REQUEST';
export const TRANSCRIBE_NOTE_SUCCESS = 'note/TRANSCRIBE_NOTE_SUCCESS';
export const TRANSCRIBE_NOTE_FAILURE = 'note/TRANSCRIBE_NOTE_FAILURE';
