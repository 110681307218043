import {
  DEASSOCIATE_CATEGORY_REQUEST,
  DEASSOCIATE_CATEGORY_SUCCESS,
  DEASSOCIATE_CATEGORY_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case DEASSOCIATE_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        deassociateCategoryLoading: true,
        deassociateCategoryError: null
      });
    
    case DEASSOCIATE_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        deassociateCategoryLoading: false,
        deassociateCategoryError: null
      });
    
    case DEASSOCIATE_CATEGORY_FAILURE:
      return Object.assign({}, state, {
        deassociateCategoryLoading: false,
        deassociateCategoryError: payload,
      });

    default:
      return state;
  }
};
