/**
 * @format
 */
import * as types from './actions';

const initialState = {
  dealAccessRequestsLoading: {},
  dealAccessRequestsError: {},
  dealAccessRequests: {},

  dealAccessRequestsMutationStatus: {},

  singleAccessRequest: null,
  singleAccessRequestLoading: true,
  singleAccessRequestError: null,
};

export const DEAL_ACCESS_REQUEST_MUTATION_STATUS = {
  APPROVING: 'approving',
  REJECTING: 'rejecting',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOAD_DEAL_ACCESS_REQUEST_LIST_REQUEST:
      return Object.assign({}, state, {
        dealAccessRequestsLoading: {
          ...state.dealAccessRequestsLoading,
          [payload.dealId]: true,
        },
        dealAccessRequestsError: {
          ...state.dealAccessRequestsError,
          [payload.dealId]: null,
        },
      });
    case types.LOAD_DEAL_ACCESS_REQUEST_LIST_SUCCESS:
      return Object.assign({}, state, {
        dealAccessRequestsLoading: {
          ...state.dealAccessRequestsLoading,
          [payload.dealId]: false,
        },
        dealAccessRequests: {
          ...state.dealAccessRequests,
          [payload.dealId]: payload.data,
        },
        dealAccessRequestsError: {
          ...state.dealAccessRequestsError,
          [payload.dealId]: null,
        },
      });
    case types.LOAD_DEAL_ACCESS_REQUEST_LIST_FAILURE:
      return Object.assign({}, state, {
        dealAccessRequestsLoading: {
          ...state.dealAccessRequestsLoading,
          [payload.dealId]: false,
        },
        dealAccessRequestsError: {
          ...state.dealAccessRequestsError,
          [payload.dealId]: payload.message,
        },
      });

    case types.APPROVE_DEAL_ACCESS_REQUEST_REQUEST:
      return Object.assign({}, state, {
        dealAccessRequestsMutationStatus: {
          ...state.dealAccessRequestsMutationStatus,
          [payload.requestId]: DEAL_ACCESS_REQUEST_MUTATION_STATUS.APPROVING,
        },
      });
    case types.APPROVE_DEAL_ACCESS_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        dealAccessRequestsMutationStatus: {
          ...state.dealAccessRequestsMutationStatus,
          [payload.requestId]: undefined,
        },
        dealAccessRequests: {
          ...state.dealAccessRequests,
          // remove the approved request from state
          [payload.dealId]: state.dealAccessRequests[payload.dealId].filter(
            req => req.id !== payload.requestId
          ),
        },
      });
    case types.APPROVE_DEAL_ACCESS_REQUEST_FAILURE:
      return Object.assign({}, state, {
        dealAccessRequestsMutationStatus: {
          ...state.dealAccessRequestsMutationStatus,
          [payload.requestId]: undefined,
        },
      });

    case types.REJECT_DEAL_ACCESS_REQUEST_REQUEST:
      return Object.assign({}, state, {
        dealAccessRequestsMutationStatus: {
          ...state.dealAccessRequestsMutationStatus,
          [payload.requestId]: DEAL_ACCESS_REQUEST_MUTATION_STATUS.REJECTING,
        },
      });
    case types.REJECT_DEAL_ACCESS_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        dealAccessRequestsMutationStatus: {
          ...state.dealAccessRequestsMutationStatus,
          [payload.requestId]: undefined,
        },
        dealAccessRequests: {
          ...state.dealAccessRequests,
          // remove the approved request from state
          [payload.dealId]: state.dealAccessRequests[payload.dealId].filter(
            req => req.id !== payload.requestId
          ),
        },
      });
    case types.REJECT_DEAL_ACCESS_REQUEST_FAILURE:
      return Object.assign({}, state, {
        dealAccessRequestsMutationStatus: {
          ...state.dealAccessRequestsMutationStatus,
          [payload.requestId]: undefined,
        },
      });

    case types.LOAD_SINGLE_DEAL_ACCESS_REQUEST_REQUEST:
      return Object.assign({}, state, {
        singleAccessRequestLoading: true,
        singleAccessRequest: null,
        singleAccessRequestError: null,
      });

    case types.LOAD_SINGLE_DEAL_ACCESS_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        singleAccessRequestLoading: false,
        singleAccessRequest: payload.data,
        singleAccessRequestError: null,
      });
    case types.LOAD_SINGLE_DEAL_ACCESS_REQUEST_FAILURE:
      return Object.assign({}, state, {
        singleAccessRequestLoading: false,
        singleAccessRequest: null,
        singleAccessRequestError: payload.message,
      });
    default:
      return state;
  }
};
