/**
 * Finish container
 *
 * @author  Akhila
*/
import Footer from '../../components/Footer';
import GuestPage from '../../components/GuestPage';
import PropTypes from 'prop-types';
import React from 'react';
import WelcomePage from '../../components/WelcomePage';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadUser } from '../../modules/auth';
import { saveStage } from '../../modules/setting';

class Finish extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    completedStages: PropTypes.array.isRequired,
    saveStage: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    saveStageLoading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { completedStages, history } = this.props;
    if (completedStages.indexOf('finish') > -1 ) {
      history.push('/dashboard');
    }
  }

  handleSubmit = event => {
    event && event.preventDefault();
    const { saveStage, loadUser, saveStageLoading } = this.props;
    if (saveStageLoading) return;
    saveStage('finish')
    .then((data) => loadUser('/dashboard'))
    .catch((err) => {});
  };

  render() {
    const { saveStageLoading } = this.props;
    return (
      <div>
        <GuestPage>
          <WelcomePage
            header="Super Awesome!"
            buttonName="Go to my Dashboard"
            handleSubmit={this.handleSubmit}
            loader={saveStageLoading}
          >
            <p>Now that you have added all your details, you can start using the
            platform! </p>
            <p>In case you need any help we are always happy to help,
            please reach us at <b>
              <a href='mailto:support@taghash.io'>support@taghash.io</a>
            </b>.</p>
          </WelcomePage>
        </GuestPage>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  completedStages: state.auth.stage,
  saveStageLoading: state.setting.saveStageLoading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveStage,
  loadUser,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Finish);

