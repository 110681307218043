import {
  LOAD_PORTCO_LIST_REQUEST,
  LOAD_PORTCO_LIST_SUCCESS,
  LOAD_PORTCO_LIST_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_PORTCO_LIST_REQUEST:
      return Object.assign({}, state, {
        portCoListLoading: true,
        portCoListError: null,
        portCoListOptions: {
          ...state.portCoListOptions,
          ...payload.options
        }
      });
    case LOAD_PORTCO_LIST_SUCCESS:
      return Object.assign({}, state, {
        portCoListLoading: false,
        portCoListError: null,
        portcos: {
          ...state.portcos,
          ...payload.data.reduce((result, item) => {
              result[item.id] = state.portcos[item.id] ? {
                ...state.portcos[item.id],
                ...item
              } : item;
              return result;
            }, {})
        },
        portCoListOptions: {
          ...state.portCoListOptions,
          ...payload.options
        }
      });
    case LOAD_PORTCO_LIST_FAILURE:
      return Object.assign({}, state, {
        portCoListLoading: false,
        portCoListError: payload
      });
    default:
      return state;
  }
};
