export const LIST_PORTCO_CHARTS_REQUEST = 'portfolio-chart/LIST_PORTCO_CHARTS_REQUEST';
export const LIST_PORTCO_CHARTS_SUCCESS = 'portfolio-chart/LIST_PORTCO_CHARTS_SUCCESS';
export const LIST_PORTCO_CHARTS_FAILURE = 'portfolio-chart/LIST_PORTCO_CHARTS_FAILURE';

export const CREATE_PORTCO_CHART_REQUEST = 'portfolio-chart/CREATE_PORTCO_CHART_REQUEST';
export const CREATE_PORTCO_CHART_SUCCESS = 'portfolio-chart/CREATE_PORTCO_CHART_SUCCESS';
export const CREATE_PORTCO_CHART_FAILURE = 'portfolio-chart/CREATE_PORTCO_CHART_FAILURE';

export const DELETE_PORTCO_CHARTS_REQUEST = 'portfolio-chart/DELETE_PORTCO_CHARTS_REQUEST';
export const DELETE_PORTCO_CHARTS_SUCCESS = 'portfolio-chart/DELETE_PORTCO_CHARTS_SUCCESS';
export const DELETE_PORTCO_CHARTS_FAILURE = 'portfolio-chart/DELETE_PORTCO_CHARTS_FAILURE';

export const CLEAR_CHARTS = 'portfolio-chart/CLEAR_CHARTS'
