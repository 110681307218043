/**
 * @format
 */
import { DELETE_DEAL_NOTE_SUCCESS } from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case DELETE_DEAL_NOTE_SUCCESS: {
      const copyOfNotes = { ...state.notes };
      if (copyOfNotes[payload.noteId]) {
        delete copyOfNotes[payload.noteId];
      }
      const copyOfNoteLoading = { ...state.noteLoading };
      if (typeof copyOfNoteLoading[payload.noteId] !== 'undefined') {
        delete copyOfNoteLoading[payload.noteId];
      }
      const copyOfNoteError = { ...state.noteError };
      if (typeof copyOfNoteError[payload.noteId] !== 'undefined') {
        delete copyOfNoteError[payload.noteId];
      }
      const copyOfDealNotes = { ...state.dealNotes };
      if (
        copyOfDealNotes[payload.dealId] &&
        copyOfDealNotes[payload.dealId].length > 0
      ) {
        copyOfDealNotes[payload.dealId] = [...copyOfDealNotes[payload.dealId]];
        copyOfDealNotes[payload.dealId] = copyOfDealNotes[
          payload.dealId
        ].filter(
          dealNote =>
            dealNote &&
            dealNote.note &&
            dealNote.note.id &&
            dealNote.note.id !== payload.noteId
        );
      }
      return Object.assign({}, state, {
        note: {
          ...copyOfNotes,
        },
        noteLoading: {
          ...copyOfNoteLoading,
        },
        noteError: {
          ...copyOfNoteError,
        },
        dealNotes: {
          ...copyOfDealNotes,
        },
        pinnedNote:
          state.pinnedNote &&
          state.pinnedNote.note &&
          state.pinnedNote.note.id &&
          state.pinnedNote.note.id === payload.noteId
            ? null
            : state.pinnedNote,
      });
    }

    default:
      return state;
  }
};
