/**
 * @format
 */
import HeadManager from '../HeadManager';
import Fab from '@material-ui/core/Fab';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconHome from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import babyWaitingToGetOut from '../../assets/animations/19518-baby-waiting-for-getting-out.json';
import notFoundSign from '../../assets/animations/19749-not-found.json';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
// @see https://github.com/gamote/lottie-react
import { Animator } from '@lottie-web/react';

const styles = theme => ({
  root: {
    padding: '1em',
    marginBottom: '1em',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  goHomeBtn: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
});

const NotFound = ({ classes }) => {
  const isProfessional = Math.random() > 0.5;
  return (
    <div className={classes.root}>
      <HeadManager title="Page not found" />
      <Grid container justify="center" alignContent="center">
        <Grid item xs={12}>
          <Typography variant="h1" align="center" display="block">
            {isProfessional ? '404' : '404 Not found'}
          </Typography>
          <Typography variant="h3" align="center" display="block" gutterBottom>
            {isProfessional ? 'Page not found' : 'You seem a little lost...'}
          </Typography>
          <Typography variant="overline" align="center" display="block">
            The page you are looking for was moved, removed, renamed or might
            have never existed.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.goHomeBtn}>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="Go Home"
            component={Link}
            to="/"
          >
            <IconHome className={classes.extendedIcon} />
            Go Home
          </Fab>
        </Grid>
        <Grid item xs={12}>
          <Animator
            animationData={isProfessional ? notFoundSign : babyWaitingToGetOut}
            loop
            autoplay
            style={{
              height: '300px',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(NotFound);
