export const ADD_SIGN_OFF_GROUP_REQUEST = 'pipeline-sign-offs/ADD_SIGN_OFF_GROUP_REQUEST';
export const ADD_SIGN_OFF_GROUP_SUCCESS = 'pipeline-sign-offs/ADD_SIGN_OFF_GROUP_SUCCESS';
export const ADD_SIGN_OFF_GROUP_FAILURE = 'pipeline-sign-offs/ADD_SIGN_OFF_GROUP_FAILURE';

export const ADD_SIGN_OFF_ITEM_REQUEST = 'pipeline-sign-offs/ADD_SIGN_OFF_ITEM_REQUEST';
export const ADD_SIGN_OFF_ITEM_SUCCESS = 'pipeline-sign-offs/ADD_SIGN_OFF_ITEM_SUCCESS';
export const ADD_SIGN_OFF_ITEM_FAILURE = 'pipeline-sign-offs/ADD_SIGN_OFF_ITEM_FAILURE';

export const UPDATE_SIGN_OFF_GROUP_REQUEST = 'pipeline-sign-offs/UPDATE_SIGN_OFF_GROUP_REQUEST';
export const UPDATE_SIGN_OFF_GROUP_SUCCESS = 'pipeline-sign-offs/UPDATE_SIGN_OFF_GROUP_SUCCESS';
export const UPDATE_SIGN_OFF_GROUP_FAILURE = 'pipeline-sign-offs/UPDATE_SIGN_OFF_GROUP_FAILURE';

export const UPDATE_SIGN_OFF_ITEM_REQUEST = 'pipeline-sign-offs/UPDATE_SIGN_OFF_ITEM_REQUEST';
export const UPDATE_SIGN_OFF_ITEM_SUCCESS = 'pipeline-sign-offs/UPDATE_SIGN_OFF_ITEM_SUCCESS';
export const UPDATE_SIGN_OFF_ITEM_FAILURE = 'pipeline-sign-offs/UPDATE_SIGN_OFF_ITEM_FAILURE';

export const ADD_SIGN_OFF_ASSIGNEE_REQUEST = 'pipeline-sign-offs/ADD_SIGN_OFF_ASSIGNEE_REQUEST';
export const ADD_SIGN_OFF_ASSIGNEE_SUCCESS = 'pipeline-sign-offs/ADD_SIGN_OFF_ASSIGNEE_SUCCESS';
export const ADD_SIGN_OFF_ASSIGNEE_FAILURE = 'pipeline-sign-offs/ADD_SIGN_OFF_ASSIGNEE_FAILURE';

export const REMOVE_SIGN_OFF_ASSIGNEE_REQUEST = 'pipeline-sign-offs/REMOVE_SIGN_OFF_ASSIGNEE_REQUEST';
export const REMOVE_SIGN_OFF_ASSIGNEE_SUCCESS = 'pipeline-sign-offs/REMOVE_SIGN_OFF_ASSIGNEE_SUCCESS';
export const REMOVE_SIGN_OFF_ASSIGNEE_FAILURE = 'pipeline-sign-offs/REMOVE_SIGN_OFF_ASSIGNEE_FAILURE';

export const APPROVE_SIGN_OFF_REQUEST = 'pipeline-sign-offs/APPROVE_SIGN_OFF_REQUEST';
export const APPROVE_SIGN_OFF_SUCCESS = 'pipeline-sign-offs/APPROVE_SIGN_OFF_SUCCESS';
export const APPROVE_SIGN_OFF_FAILURE = 'pipeline-sign-offs/APPROVE_SIGN_OFF_FAILURE';

export const REJECT_SIGN_OFF_REQUEST = 'pipeline-sign-offs/REJECT_SIGN_OFF_REQUEST';
export const REJECT_SIGN_OFF_SUCCESS = 'pipeline-sign-offs/REJECT_SIGN_OFF_SUCCESS';
export const REJECT_SIGN_OFF_FAILURE = 'pipeline-sign-offs/REJECT_SIGN_OFF_FAILURE';

export const ADD_SIGN_OFF_ATTACHMENT_REQUEST = 'pipeline-sign-offs/ADD_SIGN_OFF_ATTACHMENT_REQUEST';
export const ADD_SIGN_OFF_ATTACHMENT_SUCCESS = 'pipeline-sign-offs/ADD_SIGN_OFF_ATTACHMENT_SUCCESS';
export const ADD_SIGN_OFF_ATTACHMENT_FAILURE = 'pipeline-sign-offs/ADD_SIGN_OFF_ATTACHMENT_FAILURE';

export const REMOVE_SIGN_OFF_ATTACHMENT_REQUEST = 'pipeline-sign-offs/REMOVE_SIGN_OFF_ATTACHMENT_REQUEST';
export const REMOVE_SIGN_OFF_ATTACHMENT_SUCCESS = 'pipeline-sign-offs/REMOVE_SIGN_OFF_ATTACHMENT_SUCCESS';
export const REMOVE_SIGN_OFF_ATTACHMENT_FAILURE = 'pipeline-sign-offs/REMOVE_SIGN_OFF_ATTACHMENT_FAILURE';

export const DELETE_SIGN_OFF_ITEM_REQUEST = 'pipeline-sign-offs/DELETE_SIGN_OFF_ITEM_REQUEST';
export const DELETE_SIGN_OFF_ITEM_SUCCESS = 'pipeline-sign-offs/DELETE_SIGN_OFF_ITEM_SUCCESS';
export const DELETE_SIGN_OFF_ITEM_FAILURE = 'pipeline-sign-offs/DELETE_SIGN_OFF_ITEM_FAILURE';

export const DELETE_SIGN_OFF_GROUP_REQUEST = 'pipeline-sign-offs/DELETE_SIGN_OFF_GROUP_REQUEST';
export const DELETE_SIGN_OFF_GROUP_SUCCESS = 'pipeline-sign-offs/DELETE_SIGN_OFF_GROUP_SUCCESS';
export const DELETE_SIGN_OFF_GROUP_FAILURE = 'pipeline-sign-offs/DELETE_SIGN_OFF_GROUP_FAILURE';

export const LIST_SIGN_OFF_GROUPS_REQUEST = 'pipeline-sign-offs/LIST_SIGN_OFF_GROUPS_REQUEST';
export const LIST_SIGN_OFF_GROUPS_SUCCESS = 'pipeline-sign-offs/LIST_SIGN_OFF_GROUPS_SUCCESS';
export const LIST_SIGN_OFF_GROUPS_FAILURE = 'pipeline-sign-offs/LIST_SIGN_OFF_GROUPS_FAILURE';

export const GET_SIGN_OFF_GROUP_REQUEST = 'pipeline-sign-offs/GET_SIGN_OFF_GROUP_REQUEST';
export const GET_SIGN_OFF_GROUP_SUCCESS = 'pipeline-sign-offs/GET_SIGN_OFF_GROUP_SUCCESS';
export const GET_SIGN_OFF_GROUP_FAILURE = 'pipeline-sign-offs/GET_SIGN_OFF_GROUP_FAILURE';

export const LIST_SIGN_OFF_ITEMS_REQUEST = 'pipeline-sign-offs/LIST_SIGN_OFF_ITEMS_REQUEST';
export const LIST_SIGN_OFF_ITEMS_SUCCESS = 'pipeline-sign-offs/LIST_SIGN_OFF_ITEMS_SUCCESS';
export const LIST_SIGN_OFF_ITEMS_FAILURE = 'pipeline-sign-offs/LIST_SIGN_OFF_ITEMS_FAILURE';

export const GET_SIGN_OFF_ITEM_REQUEST = 'pipeline-sign-offs/GET_SIGN_OFF_ITEM_REQUEST';
export const GET_SIGN_OFF_ITEM_SUCCESS = 'pipeline-sign-offs/GET_SIGN_OFF_ITEM_SUCCESS';
export const GET_SIGN_OFF_ITEM_FAILURE = 'pipeline-sign-offs/GET_SIGN_OFF_ITEM_FAILURE';

export const LIST_SIGN_OFF_ACTIVITY_LOGS_REQUEST = 'pipeline-sign-offs/LIST_SIGN_OFF_ACTIVITY_LOGS_REQUEST';
export const LIST_SIGN_OFF_ACTIVITY_LOGS_SUCCESS = 'pipeline-sign-offs/LIST_SIGN_OFF_ACTIVITY_LOGS_SUCCESS';
export const LIST_SIGN_OFF_ACTIVITY_LOGS_FAILURE = 'pipeline-sign-offs/LIST_SIGN_OFF_ACTIVITY_LOGS_FAILURE';

export const CLEAR_SIGN_OFFS_STATE = 'pipeline-sign-offs/CLEAR_SIGN_OFFS_STATE';
