// @see http://diveintohtml5.info/storage.html
const supportsHTML5Storage = () => {
  try {
    return 'localStorage' in window && window['localStorage'] !== null;
  } catch (e) {
    return false;
  }
}

export default supportsHTML5Storage;
