/**
 * Fetches cycle report for a deal
 */
 import request from '../../../helpers/request';
 import { enqueueNotification } from '../../app';
 import {
   DEAL_CYCLE_REPORT_REQUEST,
   DEAL_CYCLE_REPORT_SUCCESS,
   DEAL_CYCLE_REPORT_FAILURE } from '../actions';
 
 export default (dealId) => {
   return dispatch => {
     return new Promise((resolve, reject) => {
       dispatch({
         type: DEAL_CYCLE_REPORT_REQUEST,
         payload: {
           dealId: dealId
         }
       });
       request.get(`/api/v5/deals/${dealId}/cycle-report`, {}, dispatch)
       .then(({ data }) => {
         if (data.success) {
           dispatch({
             type: DEAL_CYCLE_REPORT_SUCCESS,
             payload: {
               data: data.data,
               dealId: dealId,
             }
           });
           return resolve(data.data);
         } else {
           dispatch({
             type: DEAL_CYCLE_REPORT_FAILURE,
             payload: {
               message: data.message,
               dealId: dealId,
             }
           });
           dispatch(enqueueNotification(data.message));
           return reject(new Error(data.message));
         }
       })
       .catch((err) => {
         const message = 'Something went wrong while loading cycle report for this deal, please try again.';
         dispatch({
           type: DEAL_CYCLE_REPORT_FAILURE,
           payload: {
             message,
             dealId: dealId,
           }
         });
         dispatch(enqueueNotification(message));
         return reject(new Error(message));
       });
     });
   };
 };
 