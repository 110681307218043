import supportsHTML5Storage from './supportsHTML5Storage';
import Time from '../components/Time';

const setDealTableColumnsWidths = (data, workspace_id) => {
  if (!supportsHTML5Storage()) { return };
  let columnIdResizedPairs = window.localStorage.getItem(
    'columnIdResizedPairs'
  );
  if (columnIdResizedPairs && typeof columnIdResizedPairs === 'string') {
    try {
      columnIdResizedPairs = JSON.parse(columnIdResizedPairs);
      // If expires_on is in the past, expire
      if (new Date(columnIdResizedPairs.expires_on) < new Date()) {
        window.localStorage.removeItem('columnIdResizedPairs');
        columnIdResizedPairs = {value:{}};
      }
    } catch (e) {
      return null;
    }
  } else {
    columnIdResizedPairs = {value:{}};
  }
  const payload = {
    value: {
      ...columnIdResizedPairs.value,
      [workspace_id]: data
    }, // array of columns and their configs
    expires_on: Time.add(new Date(), 15, 'minute'), // when we consider this key expired
  };
  window.localStorage.removeItem("columnIdResizedPairs");
  window.localStorage.setItem("columnIdResizedPairs", JSON.stringify(payload));
}

export default setDealTableColumnsWidths;
