const getMillionDollerFromDoller = doller => {
  return ((doller/1000000).toFixed(2));
};

const getDollerFromMillionDoller = mDoller => {
  return (mDoller * 1000000);
};

export {
  getMillionDollerFromDoller,
  getDollerFromMillionDoller
};
