export const ADD_CUSTOM_FIELD_REQUEST = 'pipeline/ADD_CUSTOM_FIELD_REQUEST';
export const ADD_CUSTOM_FIELD_SUCCESS = 'pipeline/ADD_CUSTOM_FIELD_SUCCESS';
export const ADD_CUSTOM_FIELD_FAILURE = 'pipeline/ADD_CUSTOM_FIELD_FAILURE';
export const LOAD_CUSTOM_FIELDS_REQUEST = 'pipeline/LOAD_CUSTOM_FIELDS_REQUEST';
export const LOAD_CUSTOM_FIELDS_SUCCESS = 'pipeline/LOAD_CUSTOM_FIELDS_SUCCESS';
export const LOAD_CUSTOM_FIELDS_FAILURE = 'pipeline/LOAD_CUSTOM_FIELDS_FAILURE';
export const UPDATE_CUSTOM_FIELDS_REQUEST = 'pipeline/UPDATE_CUSTOM_FIELDS_REQUEST';
export const UPDATE_CUSTOM_FIELDS_SUCCESS = 'pipeline/UPDATE_CUSTOM_FIELDS_SUCCESS';
export const UPDATE_CUSTOM_FIELDS_FAILURE = 'pipeline/UPDATE_CUSTOM_FIELDS_FAILURE';
export const REMOVE_CUSTOM_FIELDS_REQUEST = 'pipeline/REMOVE_CUSTOM_FIELDS_REQUEST';
export const REMOVE_CUSTOM_FIELDS_SUCCESS = 'pipeline/REMOVE_CUSTOM_FIELDS_SUCCESS';
export const REMOVE_CUSTOM_FIELDS_FAILURE = 'pipeline/REMOVE_CUSTOM_FIELDS_FAILURE';
export const LOAD_CUSTOM_FIELD_VALUES_REQUEST = 'pipeline/LOAD_CUSTOM_FIELD_VALUES_REQUEST';
export const LOAD_CUSTOM_FIELD_VALUES_SUCCESS = 'pipeline/LOAD_CUSTOM_FIELD_VALUES_SUCCESS';
export const LOAD_CUSTOM_FIELD_VALUES_FAILURE = 'pipeline/LOAD_CUSTOM_FIELD_VALUES_FAILURE';
export const UPDATE_CUSTOM_FIELD_VALUE_REQUEST = 'pipeline/UPDATE_CUSTOM_FIELD_VALUE_REQUEST';
export const UPDATE_CUSTOM_FIELD_VALUE_SUCCESS = 'pipeline/UPDATE_CUSTOM_FIELD_VALUE_SUCCESS';
export const UPDATE_CUSTOM_FIELD_VALUE_FAILURE = 'pipeline/UPDATE_CUSTOM_FIELD_VALUE_FAILURE';