/** @format */
import merge from 'lodash.merge';
import * as types from './actions';
import { uniq } from 'lodash';
import filter from 'lodash.filter';

const initialState = {
  // key is organizationContact id and value is an array of ids of organizationContact notes
  organizationContactNoteList: {},
  // key is organizationContact id and value is the id of the pinned organizationContact note
  pinnedNote: {},
  // key is the note id of a organizationContact note and value is the data
  organizationContactNotes: {},
  // key is organizationContact id and value is boolean
  addOrganizationContactNoteLoading: {},
  addOrganizationContactNoteError: {},
  // key is note id and value is boolean
  getOrganizationContactNoteLoading: {},
  getOrganizationContactNoteError: {},
  // key is organizationContact id and value is boolean
  getPinnedOrganizationContactNoteLoading: {},
  getPinnedOrganizationContactNoteError: {},
  // key is organizationContact id and value is boolean
  getOrganizationContactNoteListLoading: {},
  getOrganizationContactNoteListOptions: {},
  getOrganizationContactNoteListMore: {},
  getOrganizationContactNoteListError: {},
  // key is note's id
  updateOrganizationContactNoteLoading: {},
  updateOrganizationContactNoteError: {},
  // key is note's id
  deleteOrganizationContactNoteLoading: {},
  deleteOrganizationContactNoteError: {},

  // states to hold reply data. Key is thread id
  noteReplyList: {},
  noteReplyListLoading: {},
  noteReplyListError: {},
  noteReplyListOptions: {},
  noteReplyListMore: {},

  noteReplyCount: {},
  noteReplyCountLoading: {},
  noteReplyCountError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * Add organizationContact note list reducers
     */
    case types.ADD_ORGANIZATION_CONTACT_NOTE_REQUEST:
      return Object.assign({}, state, {
        addOrganizationContactNoteLoading: {
          ...state.addOrganizationContactNoteLoading,
          [payload.organizationContactId]: true,
        },
        addOrganizationContactNoteError: {
          ...state.addOrganizationContactNoteError,
          [payload.organizationContactId]: null,
        },
      });
    case types.ADD_ORGANIZATION_CONTACT_NOTE_SUCCESS: {
      const shouldAddToOrganizationContactNoteList = !payload.data.note
        .parent_id;
      let newOrganizationContactNoteList = {
        ...state.organizationContactNoteList,
      };
      if (shouldAddToOrganizationContactNoteList) {
        newOrganizationContactNoteList = {
          ...newOrganizationContactNoteList,
          [payload.organizationContactId]:
            state.organizationContactNoteList[payload.organizationContactId] &&
            state.organizationContactNoteList[payload.organizationContactId]
              .length > 0
              ? [
                  // append to the beginning
                  payload.data.id,
                  ...state.organizationContactNoteList[
                    payload.organizationContactId
                  ],
                ]
              : [payload.data.id],
        };
      }

      return Object.assign({}, state, {
        organizationContactNoteList: newOrganizationContactNoteList,
        // add data to the hashmap
        organizationContactNotes: {
          ...state.organizationContactNotes,
          [payload.data.id]: payload.data,
        },
        addOrganizationContactNoteLoading: {
          ...state.addOrganizationContactNoteLoading,
          [payload.organizationContactId]: false,
        },
        addOrganizationContactNoteError: {
          ...state.addOrganizationContactNoteError,
          [payload.organizationContactId]: null,
        },
      });
    }
    case types.ADD_ORGANIZATION_CONTACT_NOTE_FAILURE:
      return Object.assign({}, state, {
        addOrganizationContactNoteLoading: {
          ...state.addOrganizationContactNoteLoading,
          [payload.organizationContactId]: false,
        },
        addOrganizationContactNoteError: {
          ...state.addOrganizationContactNoteError,
          [payload.organizationContactId]: payload.message,
        },
      });

    /**
     * Get organizationContact note reducers
     */
    case types.GET_ORGANIZATION_CONTACT_NOTE_REQUEST:
      return Object.assign({}, state, {
        getOrganizationContactNoteLoading: {
          ...state.getOrganizationContactNoteLoading,
          [payload.noteId]: true,
        },
        getOrganizationContactNoteError: {
          ...state.getOrganizationContactNoteError,
          [payload.noteId]: null,
        },
      });
    case types.GET_ORGANIZATION_CONTACT_NOTE_SUCCESS:
      return Object.assign({}, state, {
        // add data to the hashmap
        organizationContactNotes: {
          ...state.organizationContactNotes,
          [payload.data.id]: payload.data,
        },
        getOrganizationContactNoteLoading: {
          ...state.getOrganizationContactNoteLoading,
          [payload.noteId]: false,
        },
        getOrganizationContactNoteError: {
          ...state.getOrganizationContactNoteError,
          [payload.noteId]: null,
        },
      });
    case types.GET_ORGANIZATION_CONTACT_NOTE_FAILURE:
      return Object.assign({}, state, {
        getOrganizationContactNoteLoading: {
          ...state.getOrganizationContactNoteLoading,
          [payload.noteId]: false,
        },
        getOrganizationContactNoteError: {
          ...state.getOrganizationContactNoteError,
          [payload.noteId]: payload.message,
        },
      });

    /**
     * Get pinned organizationContact note reducers
     */
    case types.GET_PINNED_ORGANIZATION_CONTACT_NOTE_REQUEST:
      return Object.assign({}, state, {
        getPinnedOrganizationContactNoteLoading: {
          ...state.getPinnedOrganizationContactNoteLoading,
          [payload.organizationContactId]: true,
        },
        getPinnedOrganizationContactNoteError: {
          ...state.getPinnedOrganizationContactNoteError,
          [payload.organizationContactId]: null,
        },
      });
    case types.GET_PINNED_ORGANIZATION_CONTACT_NOTE_SUCCESS: {
      const pinnedNoteId = payload.data?.id;
      // if pinned note is present, add its payload to the organizationContactNotes hashmap
      const updatedOrganizationContactNotes = pinnedNoteId
        ? merge(state.organizationContactNotes, {
            [payload.data.id]: payload.data,
          })
        : state.organizationContactNotes;
      return Object.assign({}, state, {
        // add data to the hashmap
        organizationContactNotes: updatedOrganizationContactNotes,
        pinnedNote: {
          ...state.pinnedNote,
          // update reference to the pinned note for this organizationContact
          // if non-existent, this will be undefined
          [payload.organizationContactId]: pinnedNoteId,
        },
        getPinnedOrganizationContactNoteLoading: {
          ...state.getPinnedOrganizationContactNoteLoading,
          [payload.organizationContactId]: false,
        },
        getPinnedOrganizationContactNoteError: {
          ...state.getPinnedOrganizationContactNoteError,
          [payload.organizationContactId]: null,
        },
      });
    }
    case types.GET_PINNED_ORGANIZATION_CONTACT_NOTE_FAILURE:
      return Object.assign({}, state, {
        getPinnedOrganizationContactNoteLoading: {
          ...state.getPinnedOrganizationContactNoteLoading,
          [payload.organizationContactId]: false,
        },
        getPinnedOrganizationContactNoteError: {
          ...state.getPinnedOrganizationContactNoteError,
          [payload.organizationContactId]: payload.message,
        },
      });

    /**
     * Load organizationContact notes reducers
     */
    case types.LIST_ORGANIZATION_CONTACT_NOTES_REQUEST:
      return Object.assign({}, state, {
        getOrganizationContactNoteListLoading: {
          ...state.getOrganizationContactNoteListLoading,
          [payload.organizationContactId]: true,
        },
        getOrganizationContactNoteListError: {
          ...state.getOrganizationContactNoteListError,
          [payload.organizationContactId]: null,
        },
      });
    case types.LIST_ORGANIZATION_CONTACT_NOTES_SUCCESS: {
      const currentOrganizationContactNoteList =
        state.organizationContactNoteList[payload.organizationContactId] &&
        state.organizationContactNoteList[payload.organizationContactId]
          .length > 0
          ? state.organizationContactNoteList[payload.organizationContactId]
          : [];
      const newOrganizationContactNoteList = payload.data.map(
        note => note.note_id
      );
      return Object.assign({}, state, {
        organizationContactNoteList: {
          ...state.organizationContactNoteList,
          [payload.organizationContactId]:
            // if we are loading first page of results, reset the list, otherwise
            // append to it
            payload.options.page === 1
              ? newOrganizationContactNoteList
              : uniq([
                  ...currentOrganizationContactNoteList,
                  ...newOrganizationContactNoteList,
                ]),
        },
        // add data to the hashmap
        organizationContactNotes: {
          ...state.organizationContactNotes,
          // create hashmap for returned notes
          ...payload.data.reduce(
            (acc, note) => ({ ...acc, [note.note_id]: note }),
            {}
          ),
        },
        getOrganizationContactNoteListLoading: {
          ...state.getOrganizationContactNoteListLoading,
          [payload.organizationContactId]: false,
        },
        getOrganizationContactNoteListOptions: {
          ...state.getOrganizationContactNoteListOptions,
          [payload.organizationContactId]: payload.options,
        },
        getOrganizationContactNoteListMore: {
          ...state.getOrganizationContactNoteListMore,
          [payload.organizationContactId]:
            payload.data.length === payload.options.limit,
        },
        getOrganizationContactNoteListError: {
          ...state.getOrganizationContactNoteListError,
          [payload.organizationContactId]: null,
        },
      });
    }
    case types.LIST_ORGANIZATION_CONTACT_NOTES_FAILURE:
      return Object.assign({}, state, {
        getOrganizationContactNoteListLoading: {
          ...state.getOrganizationContactNoteListLoading,
          [payload.organizationContactId]: false,
        },
        getOrganizationContactNoteListError: {
          ...state.getOrganizationContactNoteListError,
          [payload.organizationContactId]: payload.message,
        },
      });

    /**
     * Update organizationContact note reducers
     */
    case types.UPDATE_ORGANIZATION_CONTACT_NOTE_REQUEST:
      return Object.assign({}, state, {
        updateOrganizationContactNoteLoading: {
          ...state.updateOrganizationContactNoteLoading,
          [payload.noteId]: true,
        },
        updateOrganizationContactNoteError: {
          ...state.updateOrganizationContactNoteError,
          [payload.noteId]: null,
        },
      });
    case types.UPDATE_ORGANIZATION_CONTACT_NOTE_SUCCESS: {
      const newState = Object.assign({}, state, {
        // add data to the hashmap
        organizationContactNotes: {
          ...state.organizationContactNotes,
          [payload.noteId]: merge(
            state.organizationContactNotes[payload.noteId] || {},
            payload.data
          ),
        },
        updateOrganizationContactNoteLoading: {
          ...state.updateOrganizationContactNoteLoading,
          [payload.noteId]: false,
        },
        updateOrganizationContactNoteError: {
          ...state.updateOrganizationContactNoteError,
          [payload.noteId]: null,
        },
      });
      return newState;
    }
    case types.UPDATE_ORGANIZATION_CONTACT_NOTE_FAILURE:
      return Object.assign({}, state, {
        updateOrganizationContactNoteLoading: {
          ...state.updateOrganizationContactNoteLoading,
          [payload.noteId]: false,
        },
        updateOrganizationContactNoteError: {
          ...state.updateOrganizationContactNoteError,
          [payload.noteId]: payload.message,
        },
      });

    /**
     * Delete organizationContact note reducers
     */
    case types.DELETE_ORGANIZATION_CONTACT_NOTE_REQUEST:
      return Object.assign({}, state, {
        deleteOrganizationContactNoteLoading: {
          ...state.deleteOrganizationContactNoteLoading,
          [payload.noteId]: true,
        },
        deleteOrganizationContactNoteError: {
          ...state.deleteOrganizationContactNoteError,
          [payload.noteId]: null,
        },
      });
    case types.DELETE_ORGANIZATION_CONTACT_NOTE_SUCCESS: {
      const copyOfState = Object.assign({}, state, {
        organizationContactNoteList: {
          ...state.organizationContactNoteList,
        },
        // add data to the hashmap
        organizationContactNotes: {
          ...state.organizationContactNotes,
        },
        deleteOrganizationContactNoteLoading: {
          ...state.deleteOrganizationContactNoteLoading,
          [payload.noteId]: false,
        },
        deleteOrganizationContactNoteError: {
          ...state.deleteOrganizationContactNoteError,
          [payload.noteId]: null,
        },
      });
      copyOfState.organizationContactNoteList[
        payload.organizationContactId
      ] = filter(
        copyOfState.organizationContactNoteList[payload.organizationContactId],
        noteId => noteId !== payload.noteId
      );
      delete copyOfState.organizationContactNotes[payload.noteId];
      return copyOfState;
    }
    case types.DELETE_ORGANIZATION_CONTACT_NOTE_FAILURE:
      return Object.assign({}, state, {
        deleteOrganizationContactNoteLoading: {
          ...state.deleteOrganizationContactNoteLoading,
          [payload.noteId]: false,
        },
        deleteOrganizationContactNoteError: {
          ...state.deleteOrganizationContactNoteError,
          [payload.noteId]: payload.message,
        },
      });

    case types.LOAD_NOTE_REPLY_LIST_REQUEST:
      return Object.assign({}, state, {
        noteReplyListLoading: {
          ...state.noteReplyListLoading,
          [payload.threadId]: true,
        },
        noteReplyListError: {
          ...state.noteReplyListError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_LIST_SUCCESS:
      return Object.assign({}, state, {
        noteReplyList: {
          ...state.noteReplyList,
          [payload.threadId]:
            payload.options.page === 1
              ? payload.data
              : [...state.noteReplyList[payload.threadId], ...payload.data],
        },
        noteReplyListLoading: {
          ...state.noteReplyListLoading,
          [payload.threadId]: false,
        },
        noteReplyListError: {
          ...state.noteReplyListError,
          [payload.threadId]: null,
        },
        noteReplyListOptions: {
          ...state.noteReplyListOptions,
          [payload.threadId]: payload.options,
        },
        noteReplyListMore: {
          ...state.noteReplyListMore,
          [payload.threadId]:
            payload.metadata.length >= payload.options.limit ? true : false,
        },
        noteReplyCount: {
          ...state.noteReplyCount,
          [payload.threadId]: payload.metadata.length,
        },
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: false,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_LIST_FAILURE:
      return Object.assign({}, state, {
        noteReplyListLoading: {
          ...state.noteReplyListLoading,
          [payload.threadId]: false,
        },
        noteReplyListError: {
          ...state.noteReplyListError,
          [payload.threadId]: payload.message,
        },
      });

    case types.LOAD_NOTE_REPLY_COUNT_REQUEST:
      return Object.assign({}, state, {
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: true,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_COUNT_SUCCESS:
      return Object.assign({}, state, {
        noteReplyCount: {
          ...state.noteReplyCount,
          [payload.threadId]: payload.data.length,
        },
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: false,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_COUNT_FAILURE:
      return Object.assign({}, state, {
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: false,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: payload.message,
        },
      });

    default:
      return state;
  }
};
