/**
 * Whatsapp reducers
 * @format
 */

import * as types from './actions';

const initialState = {
  whatsappAssociationLoading: false,
  whatsappAssociationError: null,
  whatsappAssociation: null,

  deleteAssociationLoading: false,
  deleteAssociationError: null,

  generateChallengeCodeLoading: false,
  generateChallengeCodeError: null,
  challengeCode: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * GET_ASSOCIATION reducers
     */
    case types.GET_ASSOCIATION_REQUEST:
      return Object.assign({}, state, {
        whatsappAssociationLoading: true,
        whatsappAssociationError: null,
      });
    case types.GET_ASSOCIATION_SUCCESS:
      return Object.assign({}, state, {
        whatsappAssociation: payload.data,
        whatsappAssociationLoading: false,
        whatsappAssociationError: null,
      });
    case types.GET_ASSOCIATION_FAILURE:
      return Object.assign({}, state, {
        whatsappAssociationLoading: false,
        whatsappAssociationError: payload.message,
      });

    /**
     * DELETE_ASSOCIATION reducers
     */
    case types.DELETE_ASSOCIATION_REQUEST:
      return Object.assign({}, state, {
        deleteAssociationLoading: true,
        deleteAssociationError: null,
      });
    case types.DELETE_ASSOCIATION_SUCCESS:
      return Object.assign({}, state, {
        whatsappAssociation: null, // association is successfully unlinked
        deleteAssociationLoading: false,
        deleteAssociationError: null,
      });
    case types.DELETE_ASSOCIATION_FAILURE:
      return Object.assign({}, state, {
        deleteAssociationLoading: false,
        deleteAssociationError: payload.message,
      });

    /**
     * GENERATE_CHALLENGE_CODE reducers
     */
    case types.GENERATE_CHALLENGE_CODE_REQUEST:
      return Object.assign({}, state, {
        generateChallengeCodeLoading: true,
        generateChallengeCodeError: null,
      });
    case types.GENERATE_CHALLENGE_CODE_SUCCESS:
      return Object.assign({}, state, {
        challengeCode: payload.data,
        generateChallengeCodeLoading: false,
        generateChallengeCodeError: null,
      });
    case types.GENERATE_CHALLENGE_CODE_FAILURE:
      return Object.assign({}, state, {
        generateChallengeCodeLoading: false,
        generateChallengeCodeError: payload.message,
      });

    default:
      return state;
  }
};
