/**
 * @format
 */

import * as types from '../actions';

import { assign } from '../../../helpers/object';
import property from 'lodash.property';

export default (state, type, payload) => {
  switch (type) {
    /** GET_DISTRIBUTION */
    case types.GET_DISTRIBUTION_REQUEST: {
      return assign(state, {
        distributionLoading: assign(state.distributionLoading, {
          [payload.distributionId]: true,
        }),
        distributionError: assign(state.distributionError, {
          [payload.distributionId]: null,
        }),
      });
    }
    case types.GET_DISTRIBUTION_FAILURE: {
      return assign(state, {
        distributionLoading: assign(state.distributionLoading, {
          [payload.distributionId]: false,
        }),
        distributionError: assign(state.distributionError, {
          [payload.distributionId]: payload.message,
        }),
      });
    }
    case types.GET_DISTRIBUTION_SUCCESS: {
      return assign(state, {
        distributionLoading: assign(state.distributionLoading, {
          [payload.distributionId]: false,
        }),
        distributionError: assign(state.distributionError, {
          [payload.distributionId]: null,
        }),
        distribution: assign(state.distribution, {
          [payload.distributionId]: assign(
            property(payload.distributionId)(state.distribution),
            payload.data
          ),
        }),
      });
    }

    default:
      return state;
  }
};
