/**
 * Action creators for loading deal suggestions
 *
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_DEAL_SUGGESTIONS_REQUEST,
  LOAD_DEAL_SUGGESTIONS_SUCCESS,
  LOAD_DEAL_SUGGESTIONS_FAILURE} from '../actions';

export default (q, options={}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LOAD_DEAL_SUGGESTIONS_REQUEST
      });
      const queryParams = {
        q,
        withStartup: true,
        limit: 10,
      };
      request.get(`/api/search/v1/search`, queryParams, dispatch)
      .then(({ data }) => {
        // this is a hack solution. 
        // To do: Need to solve this permenantly on the backend
        if (data.success && data.data) {
          dispatch({
            type: LOAD_DEAL_SUGGESTIONS_SUCCESS,
            payload: data.data
          });
          resolve(data);
        } else {
          dispatch({
            type: LOAD_DEAL_SUGGESTIONS_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading suggestions, please try again.'
        dispatch({
          type: LOAD_DEAL_SUGGESTIONS_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
