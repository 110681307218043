/**
 * Setting actions
 *
 * @author Ritesh Shrivastav
 * @author Akhila
 */
export const UPDATE_PROFILE_REQUEST = 'setting/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'setting/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'setting/UPDATE_PROFILE_FAILURE';

export const FETCH_GOOGLE_SETTING_REQUEST = 'setting/FETCH_GOOGLE_SETTING_REQUEST';
export const FETCH_GOOGLE_SETTING_SUCCESS = 'setting/FETCH_GOOGLE_SETTING_SUCCESS';
export const FETCH_GOOGLE_SETTING_FAILURE = 'setting/FETCH_GOOGLE_SETTING_FAILURE';

export const FETCH_MICROSOFT_SETTING_REQUEST = 'setting/FETCH_MICROSOFT_SETTING_REQUEST';
export const FETCH_MICROSOFT_SETTING_SUCCESS = 'setting/FETCH_MICROSOFT_SETTING_SUCCESS';
export const FETCH_MICROSOFT_SETTING_FAILURE = 'setting/FETCH_MICROSOFT_SETTING_FAILURE';

export const SAVE_STAGE_REQUEST = 'setting/SAVE_STAGE_REQUEST';
export const SAVE_STAGE_SUCCESS = 'setting/SAVE_STAGE_SUCCESS';
export const SAVE_STAGE_FAILURE = 'setting/SAVE_STAGE_FAILURE';

export const GOOGLE_CONNECT_REQUEST = 'setting/GOOGLE_CONNECT_REQUEST';
export const GOOGLE_CONNECT_SUCCESS = 'setting/GOOGLE_CONNECT_SUCCESS';
export const GOOGLE_CONNECT_FAILURE = 'setting/GOOGLE_CONNECT_FAILURE';

export const MICROSOFT_CONNECT_REQUEST = 'setting/MICROSOFT_CONNECT_REQUEST';
export const MICROSOFT_CONNECT_SUCCESS = 'setting/MICROSOFT_CONNECT_SUCCESS';
export const MICROSOFT_CONNECT_FAILURE = 'setting/MICROSOFT_CONNECT_FAILURE';

export const UPDATE_DEAL_VIEW_TYPE_REQUEST = 'setting/UPDATE_DEAL_VIEW_TYPE_REQUEST';
export const UPDATE_DEAL_VIEW_TYPE_SUCCESS = 'setting/UPDATE_DEAL_VIEW_TYPE_SUCCESS';
export const UPDATE_DEAL_VIEW_TYPE_FAILURE = 'setting/UPDATE_DEAL_VIEW_TYPE_FAILURE';

export const UPDATE_PORTFOLIO_VIEW_TYPE_REQUEST = 'settings/UPDATE_PORTFOLIO_VIEW_TYPE_REQUEST';
export const UPDATE_PORTFOLIO_VIEW_TYPE_SUCCESS = 'settings/UPDATE_PORTFOLIO_VIEW_TYPE_SUCCESS';
export const UPDATE_PORTFOLIO_VIEW_TYPE_FAILURE = 'settings/UPDATE_PORTFOLIO_VIEW_TYPE_FAILURE'; 

export const LOAD_DEAL_VIEW_TYPE_REQUEST = 'setting/LOAD_DEAL_VIEW_TYPE_REQUEST';
export const LOAD_DEAL_VIEW_TYPE_SUCCESS = 'setting/LOAD_DEAL_VIEW_TYPE_SUCCESS';
export const LOAD_DEAL_VIEW_TYPE_FAILURE = 'setting/LOAD_DEAL_VIEW_TYPE_FAILURE';

export const LOAD_PORTFOLIO_VIEW_TYPE_REQUEST = 'setting/LOAD_PORTFOLIO_VIEW_TYPE_REQUEST';
export const LOAD_PORTFOLIO_VIEW_TYPE_SUCCESS = 'setting/LOAD_PORTFOLIO_VIEW_TYPE_SUCCESS';
export const LOAD_PORTFOLIO_VIEW_TYPE_FAILURE = 'setting/LOAD_PORTFOLIO_VIEW_TYPE_FAILURE';

export const UPDATE_FCM_REGISTRATION_TOKENS_REQUEST = 'setting/UPDATE_FCM_REGISTRATION_TOKENS_REQUEST';
export const UPDATE_FCM_REGISTRATION_TOKENS_SUCCESS = 'setting/UPDATE_FCM_REGISTRATION_TOKENS_SUCCESS';
export const UPDATE_FCM_REGISTRATION_TOKENS_FAILURE = 'setting/UPDATE_FCM_REGISTRATION_TOKENS_FAILURE';

export const LOAD_SETTING_VALUE_BY_KEY_REQUEST = 'setting/LOAD_SETTING_VALUE_BY_KEY_REQUEST';
export const LOAD_SETTING_VALUE_BY_KEY_SUCCESS = 'setting/LOAD_SETTING_VALUE_BY_KEY_SUCCESS';
export const LOAD_SETTING_VALUE_BY_KEY_FAILURE = 'setting/LOAD_SETTING_VALUE_BY_KEY_FAILURE';

export const UPDATE_SETTING_VALUE_BY_KEY_REQUEST= 'setting/UPDATE_SETTING_VALUE_BY_KEY_REQUEST'
export const UPDATE_SETTING_VALUE_BY_KEY_SUCCESS= 'setting/UPDATE_SETTING_VALUE_BY_KEY_SUCCESS'
export const UPDATE_SETTING_VALUE_BY_KEY_FAILURE= 'setting/UPDATE_SETTING_VALUE_BY_KEY_FAILURE'

export const LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_REQUEST = 'setting/LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_REQUEST';
export const LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_SUCCESS = 'setting/LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_SUCCESS';
export const LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_FAILURE = 'setting/LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_FAILURE';

export const UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_REQUEST = 'setting/UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_REQUEST';
export const UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_SUCCESS = 'setting/UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_SUCCESS';
export const UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_FAILURE = 'setting/UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_FAILURE';

export const UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_REQUEST = 'setting/UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_REQUEST';
export const UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_SUCCESS = 'setting/UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_SUCCESS';
export const UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_FAILURE = 'setting/UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_FAILURE';

export const UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_REQUEST = 'setting/UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_REQUEST';
export const UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_SUCCESS = 'setting/UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_SUCCESS';
export const UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_FAILURE = 'setting/UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_FAILURE';




export const UPDATE_FLAG_FULL_SYNC_GMAIL_REQUEST = 'setting/UPDATE_FLAG_FULL_SYNC_GMAIL_REQUEST';
export const UPDATE_FLAG_FULL_SYNC_GMAIL_SUCCESS = 'setting/UPDATE_FLAG_FULL_SYNC_GMAIL_SUCCESS';
export const UPDATE_FLAG_FULL_SYNC_GMAIL_FAILURE = 'setting/UPDATE_FLAG_FULL_SYNC_GMAIL_FAILURE';

export const UPDATE_HIDE_DEAL_ACTIVITY_LOG_REQUEST = 'setting/UPDATE_HIDE_DEAL_ACTIVITY_LOG_REQUEST';
export const UPDATE_HIDE_DEAL_ACTIVITY_LOG_SUCCESS = 'setting/UPDATE_HIDE_DEAL_ACTIVITY_LOG_SUCCESS';
export const UPDATE_HIDE_DEAL_ACTIVITY_LOG_FAILURE = 'setting/UPDATE_HIDE_DEAL_ACTIVITY_LOG_FAILURE';

export const UPDATE_CUBEJS_SCHEMA_VERSION_REQUEST = 'setting/UPDATE_CUBEJS_SCHEMA_VERSION_REQUEST';
export const UPDATE_CUBEJS_SCHEMA_VERSION_SUCCESS = 'setting/UPDATE_CUBEJS_SCHEMA_VERSION_SUCCESS';
export const UPDATE_CUBEJS_SCHEMA_VERSION_FAILURE = 'setting/UPDATE_CUBEJS_SCHEMA_VERSION_FAILURE';

export const UPDATE_ORGANIZATION_SETTING_BY_KEY_REQUEST = 'setting/UPDATE_ORGANIZATION_SETTING_BY_KEY_REQUEST';
export const UPDATE_ORGANIZATION_SETTING_BY_KEY_SUCCESS = 'setting/UPDATE_ORGANIZATION_SETTING_BY_KEY_SUCCESS';
export const UPDATE_ORGANIZATION_SETTING_BY_KEY_FAILURE = 'setting/UPDATE_ORGANIZATION_SETTING_BY_KEY_FAILURE';
