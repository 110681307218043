/**
 * Updates deal information
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE } from '../actions';

export default (id, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UPDATE_REQUEST
      });
      request.post(`/api/v1/pipelines/${id}`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: UPDATE_SUCCESS });
          resolve(data.data);
        } else {
          dispatch({
            type: UPDATE_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while updating deal details, please try again.';
        dispatch({
          type: UPDATE_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
