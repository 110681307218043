/**@format */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

// mark reducers as the default export
export default reducers;

// Load custom fields for deal
export const loadCustomFields = options => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: types.LOAD_CUSTOM_FIELDS_REQUEST,
    });
    request

      .get(`/api/pipeline/custom-fields/v1/fields`, { source: options?.source })
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_CUSTOM_FIELDS_SUCCESS,
            payload: { data: data.data, options },
          });
          resolve(data);
        } else {
          dispatch({
            type: types.LOAD_CUSTOM_FIELDS_FAILURE,
            payload: {
              message: data.message,
            },
          });
          reject(new Error(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while loading custom fields, please try again.';
        dispatch({
          type: types.LOAD_CUSTOM_FIELDS_FAILURE,
          payload: { message },
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
  });
};

// Add custom fields
export const addCustomField = form => {
  return async dispatch => {
    dispatch({
      type: types.ADD_CUSTOM_FIELD_REQUEST,
    });

    let data;
    try {
      const response = await request.post(
        `/api/pipeline/custom-fields/v1/fields`,
        form
      );
      data = response.data;
      if (data.success) {
        dispatch({
          type: types.ADD_CUSTOM_FIELD_SUCCESS,
          payload: data.data,
        });
        return data;
      } else {
        dispatch({
          type: types.ADD_CUSTOM_FIELD_FAILURE,
          payload: data.message,
        });
        throw new Error(data.message);
      }
    } catch (err) {
      const message =
        'Something went wrong while adding custom fields, please try again.';
      dispatch({
        type: types.ADD_CUSTOM_FIELD_FAILURE,
        payload: message,
      });
      dispatch(enqueueNotification(err.message || message));
      throw err;
    }
  };
};

// Update Custom fields
export const updateCustomFields = form => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_CUSTOM_FIELDS_REQUEST,
        paylod: form,
      });
      request
        .put(`/api/pipeline/custom-fields/v1/fields`, form, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_CUSTOM_FIELDS_SUCCESS,
              payload: data.data,
            });
            resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_CUSTOM_FIELDS_FAILURE,
              payload: data.message,
            });
            throw new Error(data.message);
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating custom fields, please try again.';
          dispatch({
            type: types.UPDATE_CUSTOM_FIELDS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(err.message || message));
          reject(err);
        });
    });
  };
};

// Delete custom fields
export const removeCustomFields = customFieldNames => {
  return async dispatch => {
    dispatch({
      type: types.REMOVE_CUSTOM_FIELDS_REQUEST,
    });
    let data;
    try {
      let deletedNames = customFieldNames
        .map(cfName => `name=${encodeURIComponent(cfName)}`)
        .join('&');
      let path = `/api/pipeline/custom-fields/v1/fields?${deletedNames}`;

      const response = await request.del(path);
      data = response.data;
      if (data.success) {
        dispatch({ type: types.REMOVE_CUSTOM_FIELDS_SUCCESS });
        return data;
      } else {
        dispatch({
          type: types.REMOVE_CUSTOM_FIELDS_FAILURE,
          payload: { message: data.message },
        });
        throw new Error(data.message);
      }
    } catch (err) {
      const message =
        'Something went wrong while deleting custom fields, please try again.';
      dispatch({
        type: types.REMOVE_CUSTOM_FIELDS_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(err.message || message));
      throw err;
    }
  };
};

// Load Custom field values
export const loadCustomFieldValues = (dealId, options) => async dispatch => {
  dispatch({
    type: types.LOAD_CUSTOM_FIELD_VALUES_REQUEST,
    payload: { dealId },
  });

  let data;
  try {
    const response = await request.get(
      `/api/pipeline/custom-fields/v1/values`,
      { id: dealId, source: options?.source }
    );
    data = response.data;
    if (data.success) {
      dispatch({
        type: types.LOAD_CUSTOM_FIELD_VALUES_SUCCESS,
        payload: { data: data.data, options },
      });
      return data;
    } else {
      dispatch({
        type: types.LOAD_CUSTOM_FIELD_VALUES_FAILURE,
        payload: { dealId, message: data.message },
      });
      // throw new Error(data.message);
    }
  } catch (err) {
    const message =
      'Something went wrong while loading custom field values, please try again.';
    dispatch({
      type: types.LOAD_CUSTOM_FIELD_VALUES_FAILURE,
      payload: { dealId, message: data.message },
    });
    dispatch(enqueueNotification(message));
    throw err;
  }
};

// Update Custom Field Values
export const updateCustomFieldValues = (form, dealId) => {
  let loadingMap = {};
  loadingMap[dealId] = true;
  return async dispatch => {
    dispatch({
      type: types.UPDATE_CUSTOM_FIELD_VALUE_REQUEST,
      payload: loadingMap,
    });
    let data;
    try {
      const response = await request.put(
        `/api/pipeline/custom-fields/v1/${dealId}/values`,
        form
      );
      data = response.data;
      if (data.success) {
        let dataMap = {};
        dataMap[dealId] = data.data;
        loadingMap[dealId] = false;
        dispatch({
          type: types.UPDATE_CUSTOM_FIELD_VALUE_SUCCESS,
          payload: { loadingMap: loadingMap, dataMap: dataMap },
        });
        return data;
      } else {
        loadingMap[dealId] = false;
        dispatch({
          type: types.UPDATE_CUSTOM_FIELD_VALUE_FAILURE,
          payload: { message: data.message, loadingMap: loadingMap },
        });
        throw new Error(data.message);
      }
    } catch (err) {
      loadingMap[dealId] = false;
      const message =
        'Something went wrong while updating custom field values, please try again.';
      dispatch({
        type: types.UPDATE_CUSTOM_FIELD_VALUE_FAILURE,
        payload: { message: message, loadingMap: loadingMap },
      });
      dispatch(enqueueNotification(err.message || message));
      throw err;
    }
  };
};
