export const CONSOLIDATED_REPORTS_RESOURCE_TYPES = {
    PORTFOLIO_COMPANY: 'portfolio_company'
}

export const CONSOLIDATED_REPORT_STATUS = {
    PENDING: 'pending',
    COMPLETED: 'completed',
    FAILED: 'failed'
}

export const CONSOLIDATED_REPORTS_RESOURCE_ALLOWED_TYPES = Object.values(CONSOLIDATED_REPORTS_RESOURCE_TYPES)