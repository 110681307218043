/**
 * Used to only increment the deal note count by 1
 * @format
 */
import { INCREMENT_DEAL_NOTE_COUNT } from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case INCREMENT_DEAL_NOTE_COUNT:
      return Object.assign({}, state, {
        deals: {
          ...state.deals,
          [payload.dealId]: {
            ...state.deals[payload.dealId],
            counts: {
              ...(state.deals[payload.dealId]
                ? state.deals[payload.dealId].counts
                : {}),
              notes:
                state.deals[payload.dealId] &&
                state.deals[payload.dealId].counts &&
                typeof state.deals[payload.dealId].counts.notes === 'number'
                  ? state.deals[payload.dealId].counts.notes + 1
                  : 1,
            },
          },
        },
      });

    default:
      return state;
  }
};
