/**
 * OPEN_MANAGE_COLUMN reducer
 * 
 * @author Ritesh Shrivastav
 */
import { OPEN_MANAGE_COLUMN } from '../actions';

export default (state, type) => {
  switch(type) {
    case OPEN_MANAGE_COLUMN:
      return Object.assign({}, state, {
        manageColumnOpen: true
      });

    default:
      return state;
  }
};