/**
 * Adds new founder to the startup associated with the deal
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  ADD_STARTUP_FOUNDER_REQUEST,
  ADD_STARTUP_FOUNDER_SUCCESS,
  ADD_STARTUP_FOUNDER_FAILURE } from '../actions';

export default (dealId, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_STARTUP_FOUNDER_REQUEST
      });
      request.post(`/api/v1/pipelines/${dealId}/startup/founders/add`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: ADD_STARTUP_FOUNDER_SUCCESS,
            payload: data.data
          });
          resolve(data.data);
        } else {
          dispatch({
            type: ADD_STARTUP_FOUNDER_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while adding founder, please try again.';
        dispatch({
          type: ADD_STARTUP_FOUNDER_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};