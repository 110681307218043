/**
 * Fetches list of notes associated with the given deal
 *
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_DEAL_NOTE_LIST_REQUEST,
  LOAD_DEAL_NOTE_LIST_SUCCESS,
  LOAD_DEAL_NOTE_LIST_FAILURE } from '../actions';
import { deleteOrphanCaches } from '../../../helpers/Cache';

export default (dealId, options) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback
      if ('requestIdleCallback' in window) {
        // requestIdleCallback supported
        requestIdleCallback(deleteOrphanCaches);
      }
      else {
        // no support - do something else
        setTimeout(deleteOrphanCaches, 1);
      }
      dispatch({
        type: LOAD_DEAL_NOTE_LIST_REQUEST,
        payload: {
          dealId: dealId
        }
      });
      request.get(`/api/v1/pipelines/${dealId}/notes`, options, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: LOAD_DEAL_NOTE_LIST_SUCCESS,
            payload: {
              data: data.data,
              options: options,
              dealId: dealId,
            }
          });
          return resolve(data.data);
        } else {
          dispatch({
            type: LOAD_DEAL_NOTE_LIST_FAILURE,
            payload: {
              message: data.message,
              dealId: dealId,
            }
          });
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading notes, please try again.';
        dispatch({
          type: LOAD_DEAL_NOTE_LIST_FAILURE,
          payload: {
            message,
            dealId: dealId,
          }
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      });
    });
  };
};
