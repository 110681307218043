import PdfIcon from '@material-ui/icons/PictureAsPdf';
import {ReactComponent as DocIcon} from '../assets/icons/doc-icon.svg';
import VideocamIcon from '@material-ui/icons/Videocam';
import ArchiveIcon from '@material-ui/icons/Archive';
import ImageIcon from '@material-ui/icons/Image';
import {ReactComponent as PptIcon} from '../assets/icons/ppt-icon.svg';
import {ReactComponent as ExcelIcon} from '../assets/icons/xlsx_icon.svg'
import OtherIcon from '@material-ui/icons/DescriptionRounded';

import blue from '@material-ui/core/colors/blue';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import brown from '@material-ui/core/colors/brown';


const CONSTANTS = {
  VARIANTS: {
    DEAL_ATTACHMENTS_BATCH_UPLOAD: 'deal_attachment_batch_upload',
    NOTE_ATTACHMENTS_BATCH_UPLOAD: 'note_attachment_batch_upload',
    EMAIL_ATTACHMENTS_BATCH_UPLOAD: 'email_attachment_batch_upload',
  },
  PORTCO_ATTACHMENT_SOURCE_KEY:{
    PORTFOLIO_UPDATE: {
      key: 'portfolio.update',
      value: 'Uploaded as part of an update to a Portfolio Company',
    },

    EMAIL: {
      key: 'email',
      value: 'Uploaded from Email',
    },

  },
  EXTENSIONS: {
    // Text
    txt: {
      Icon: DocIcon,
      color: blue[500],
      button: {
        color: blue[500],
        borderColor: blue[500],
        '&:hover': {
          backgroundColor: blue[100],
        },
      },
      chip: {
       color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: blue[100],
        },
      },
      avatar: {
        backgroundColor: blue[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: blue[100],
        color: blue[300],
      },
    },

    // Word
    doc: {
      Icon: DocIcon,
      color: blue[500],
      button: {
        color: blue[500],
        borderColor: blue[500],
        '&:hover': {
          backgroundColor: blue[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: blue[100],
        },
      },
      avatar: {
        backgroundColor: blue[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: blue[100],
        color: blue[300],
      },
    },
    docx: {
      Icon: DocIcon,
      color: blue[500],
      button: {
        color: blue[500],
        borderColor: blue[500],
        '&:hover': {
          backgroundColor: blue[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: blue[100],
        },
      },
      avatar: {
        backgroundColor: blue[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: blue[100],
        color: blue[300],
      },
    },
    odf: {
      Icon: DocIcon,
      color: blue[500],
      button: {
        color: blue[500],
        borderColor: blue[500],
        '&:hover': {
          backgroundColor: blue[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: blue[100],
        },
      },
      avatar: {
        backgroundColor: blue[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: blue[100],
        color: blue[300],
      },
    },

    // Excel
    xls: {
      Icon: ExcelIcon,
      color: teal[500],
      button: {
        color: teal[500],
        borderColor: teal[500],
        '&:hover': {
          backgroundColor: teal[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: teal[100],
        },
      },
      avatar: {
        backgroundColor: teal[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: teal[100],
        color: teal[300],
      },
    },
    xlsx: {
      Icon: ExcelIcon,
      color: teal[500],
      button: {
        color: teal[500],
        borderColor: teal[500],
        '&:hover': {
          backgroundColor: teal[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: teal[100],
        },
      },
      avatar: {
        backgroundColor: teal[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: teal[100],
        color: teal[300],
      },
    },

    ppt: {
      Icon: PptIcon,
      color: amber[500],
      width: '100px',
      button: {
        color: amber[500],
        borderColor: amber[500],
        '&:hover': {
          backgroundColor: amber[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: amber[100],
        },
      },
      avatar: {
        backgroundColor: amber[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: amber[100],
        color: amber[300],
      },
    },
    pptx: {
      Icon: PptIcon,
      color: amber[500],
      width: '100px',
      button: {
        color: amber[500],
        borderColor: amber[500],
        '&:hover': {
          backgroundColor: amber[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: amber[100],
        },
      },
      avatar: {
        backgroundColor: amber[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: amber[100],
        color: amber[300],
      },
    },

    pdf: {
      Icon: PdfIcon,
      color: red[500],
      width: '100px',
      button: {
        color: red[500],
        borderColor: red[500],
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      avatar: {
        backgroundColor: red[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: red[100],
        color: red[300],
      },
    },

    jpg: {
      Icon: ImageIcon,
      color: '#529AF8',
      button: {
        color: red[500],
        borderColor: red[500],
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      avatar: {
        backgroundColor: red[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: red[100],
        color: red[300],
      },
    },
    jpeg: {
      Icon: ImageIcon,
      color: '#529AF8',
      button: {
        color: red[500],
        borderColor: red[500],
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      chip: {
        color: red[500],
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      avatar: {
        backgroundColor: red[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: red[100],
        color: red[300],
      },
    },
    png: {
      Icon: ImageIcon,
      color: '#529AF8',
      button: {
        color: red[500],
        borderColor: red[500],
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      chip: {
        color:'#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      avatar: {
        backgroundColor: red[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: red[100],
        color: red[300],
      },
    },

    mov: {
      Icon: VideocamIcon,
      color: '#529AF8',
      button: {
        color: '#CDCED6',
        borderColor: red[500],
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      chip: {
        color: red[500],
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      avatar: {
        backgroundColor: red[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: red[100],
        color: red[300],
      },
    },
    mp4: {
      Icon: VideocamIcon,
      color: '#529AF8',
      button: {
        color: red[500],
        borderColor: red[500],
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      avatar: {
        backgroundColor: red[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: red[100],
        color: red[300],
      },
    },
    mkv: {
      Icon: VideocamIcon,
      color: '#529AF8',
      button: {
        color: red[500],
        borderColor: red[500],
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      chip: {
        color: red[500],
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      avatar: {
        backgroundColor: red[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: red[100],
        color: red[300],
      },
    },
    avi: {
      Icon: VideocamIcon,
      color: '#529AF8',
      button: {
        color: red[500],
        borderColor: red[500],
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: red[100],
        },
      },
      avatar: {
        backgroundColor: red[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: red[100],
        color: red[300],
      },
    },

    // Compressed archives
    zip: {
      Icon: ArchiveIcon,
      color: '#529AF8',
      button: {
        color: brown[500],
        borderColor: brown[500],
        '&:hover': {
          backgroundColor: brown[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: brown[100],
        },
      },
      avatar: {
        backgroundColor: brown[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: brown[100],
        color: brown[300],
      },
    },
    tar: {
      Icon: ArchiveIcon,
      color: '#529AF8',
      button: {
        color: brown[500],
        borderColor: brown[500],
        '&:hover': {
          backgroundColor: brown[100],
        },
      },
      chip: {
        color:'#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: brown[100],
        },
      },
      avatar: {
        backgroundColor: brown[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: brown[100],
        color: brown[300],
      },
    },
    rar: {
      Icon: ArchiveIcon,
      color: '#529AF8',
      button: {
        color: brown[500],
        borderColor: brown[500],
        '&:hover': {
          backgroundColor: brown[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: brown[100],
        },
      },
      avatar: {
        backgroundColor: brown[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: brown[100],
        color: brown[300],
      },
    },
    "tar.gz": {
      Icon: ArchiveIcon,
      color: '#529AF8',
      button: {
        color: brown[500],
        borderColor: brown[500],
        '&:hover': {
          backgroundColor: brown[100],
        },
      },
      chip: {
        color:'#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: brown[100],
        },
      },
      avatar: {
        backgroundColor: brown[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: brown[100],
        color: brown[300],
      },
    },
    "tar.xz": {
      Icon: ArchiveIcon,
      color: '#529AF8',
      button: {
        color: brown[500],
        borderColor: brown[500],
        '&:hover': {
          backgroundColor: brown[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: brown[100],
        },
      },
      avatar: {
        backgroundColor: brown[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: brown[100],
        color: brown[300],
      },
    },

    default: {
      Icon: OtherIcon,
      color: '#529AF8',
      button: {
        color: blue[500],
        borderColor: blue[500],
        '&:hover': {
          backgroundColor: blue[100],
        },
      },
      chip: {
        color: '#CDCED6',
        fontWeight: 'bold',
        border: `1px solid #CDCED6`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: blue[100],
        },
      },
      avatar: {
        backgroundColor: blue[100]
      },
      deleteIcon: {
        borderRadius: '14px',
        fontSize: '20px',
        padding: '4px',
        marginRight: '3px',
        backgroundColor: blue[100],
        color: blue[300],
      },
    }
  },
  SOURCES: {
    EMAIL: {
      key: 'email',
      value: 'Uploaded from Email',
      displayInTable: 'Email',
    },
    DEAL: {
      key: 'deal',
      value: 'Uploaded from Deal',
      displayInTable: 'Direct upload',
    },
    DEAL_IMPORT: {
      key: 'deal_import',
      value: 'Uploaded to import deals',
      displayInTable: 'Deal import',
    },
    DEAL_EXPORT: {
      key: 'deal_export',
      value: 'Uploaded to export deals',
      displayInTable: 'Deal export',
    },
    NOTE: {
      key: 'note',
      value: 'Uploaded from note',
      displayInTable: 'Note',
    },
    PORTFOLIO_DIRECT: {
      key: 'portfolio.direct',
      value: 'Uploaded into a Portfolio Company',
      displayInTable: 'Direct',
    },
    PORTFOLIO_UPDATE: {
      key: 'portfolio.update',
      value: 'Uploaded as part of an update to a Portfolio Company',
      displayInTable: 'Portfolio Update',
    },
    PORTFOLIO_IMPORT_SHEET: {
      key: 'portfolio.import-sheet',
      value: 'Uploaded when importing data into a sheet in a portco',
      displayInTable: 'MIS Data Import',
    },
    PORTFOLIO_EXPORT_SHEET: {
      key: 'portfolio.export-sheet',
      value: 'Uploaded when exporting data from a sheet in a portco',
      displayInTable: 'MIS Data Export',
    },
    PORTFOLIO_IMPORT_PORTFOLIO_COMPANY: {
      key: 'portfolio.import-portfolio-company',
      value: 'Uploaded to import list of portfolio companies',
      displayInTable: 'Portfolio Import',
    },
    PORTFOLIO_EXPORT_PORTFOLIO_COMPANY: {
      key: 'portfolio.export-portfolio-company',
      value: 'Uploaded to export list of portfolio companies',
      displayInTable: 'Portfolio Export',
    },
    PORTFOLIO_LANDING_PAGE: {
      key: 'portfolio.landing-page',
      value: 'Uploaded the Portfolio Landing Page',
      displayInTable: 'Portfolio Landing Page',
    },
    FUND_DIRECT: {
      key: 'fund.direct',
      value: 'Uploaded to a fund directly',
      displayInTable: 'Direct',
    },
    PERSON_CONTACT_DIRECT: {
      key: 'person_contact.direct',
      value: 'Uploaded into a person contact document drive',
      displayInTable: 'Direct'
    },
    ORGANIZATION_CONTACT_DIRECT: {
      key: 'organization_contact.direct',
      value: ' Uploaded into a organization contact document drive',
      displayInTable: 'Direct'
    },
    WHATSAPP: {
      key: 'whatsapp',
      value: 'Uploaded from WhatsApp',
      displayInTable: 'WhatsApp'
    },
  },

  UPLOAD: {
    ALLOWED_MIME_TYPES: [
      // Data interchange
      'application/atom+xml',
      'application/json',
      'application/ld+json',
      'application/rss+xml',
      'application/vnd.geo+json',
      'application/xml',
      // Media
      'audio/midi',
      'audio/x-midi',
      'audio/mp4',
      'audio/mpeg',
      'audio/ogg',
      'audio/aac',
      'application/x-cdf',
      'audio/x-realaudio',
      'audio/x-wav',
      'audio/opus',
      'audio/wav',
      'audio/webm',
      'audio/3gpp',
      'audio/3gpp2',
      'image/bmp',
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/svg+xml',
      'image/tiff',
      'image/vnd.wap.wbmp',
      'image/webp',
      'image/x-jng',
      'image/heic',
      'image/heif',
      'video/3gpp',
      'video/3gpp2',
      'video/mp4',
      'video/mpeg',
      'video/ogg',
      'video/quicktime',
      'video/webm',
      'video/x-flv',
      'video/x-mng',
      'video/x-ms-asf',
      'video/x-ms-wmv',
      'video/x-msvideo',
      // .ico files
      'image/x-icon',
      'image/vnd.microsoft.icon',
      // ms office
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.visio',
      'text/csv',
      // fonts
      'application/font-woff',
      'application/font-woff2',
      'application/vnd.ms-fontobject',
      'application/x-font-ttf',
      'font/opentype',
      'font/otf',
      'font/woff',
      'font/woff2',
      // others
      'text/calendar',
      'application/mac-binhex40',
      'application/pdf',
      'application/octet-stream',
      'application/postscript',
      'application/rtf',
      'application/vnd.google-earth.kml+xml',
      'application/vnd.google-earth.kmz',
      'application/vnd.wap.wmlc',
      'application/x-7z-compressed',
      'application/x-bb-appworld',
      'application/x-chrome-extension',
      'application/x-cocoa',
      'application/x-java-archive-diff',
      'application/x-java-jnlp-file',
      'application/x-makeself',
      'application/x-opera-extension',
      'application/x-perl',
      'application/x-pilot',
      'application/x-rar-compressed',
      'application/x-sea',
      // 'application/x-shockwave-flash',
      'application/x-stuffit',
      'application/x-tcl',
      'application/x-x509-ca-cert',
      'application/x-xpinstall',
      'application/xhtml+xml',
      'application/xslt+xml',
      'application/zip',
      'application/vnd.rar',
      'application/x-bzip',
      'application/x-bzip2',
      'application/gzip',
      'application/epub+zip',
      'application/vnd.amazon.ebook',
      'application/ogg',
      'text/css',
      'text/html',
      'text/mathml',
      'text/plain',
      'text/vcard',
      'text/vnd.rim.location.xloc',
      'text/vnd.sun.j2me.app-descriptor',
      'text/vnd.wap.wml',
      'text/vtt',
      'text/x-component',
      'text/xml',
      'application/vnd.mozilla.xul+xml',
      'application/pgp-signature', //Generally used by email clients to encrypt/decrypt emails
      // Deliberately disallowed mime types
      // 'application/x-sh',
      // 'application/x-csh', // bash
      // 'application/x-redhat-package-manager',
      // 'application/x-bittorrent',
      // 'application/java-archive',
      // 'application/javascript',
      // 'application/manifest+json',
      // 'application/x-web-app-manifest+json',
      // 'text/cache-manifest',
      // 'application/vnd.apple.installer+xml',
      // 'application/x-httpd-php',
      // // .exe files
      // // @see https://www.file-extensions.org/exe-file-extension
      // // @see https://stackoverflow.com/a/43916291
      // 'application/vnd.microsoft.portable-executable',
      // 'application/x-msdownload',
      // 'application/exe',
      // 'application/x-exe',
      // 'application/dos-exe',
      // 'vms/exe',
      // 'application/x-winexe',
      // 'application/msdos-windows',
      // 'application/x-msdos-program',
    ],
    SPECIAL_MIME_TYPES: {
      OCTET_STREAM: {
        name: 'application/octet-stream',
        allowedExtensions: [
          'pdf',
          'png',
          'gif',
          'jpg',
          'jpeg',
          'heic',
          'heif',
          'xls',
          'xlsx',
          'xlsb',
          'csv',
          'ods',
          'doc',
          'docx',
          'odt',
          'ppt',
          'pptx',
          'odp',
          'zip',
          'mp4',
          'wav',
          'webm',
          'eml',
        ],
      },
      OGG: 'audio/ogg',
    },
  }
};

export default CONSTANTS;
