/** @format */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const updateStartupName = (
  deal_id,
  startup_id,
  updated_name,
  old_name
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!deal_id || !startup_id) {
        let message;
        if (old_name) {
          message =
            `Something went wrong while updating "${old_name}"'s name. Please try again. ` +
            'If this keeps happening, contact your administrator';
        } else {
          message =
            `Something went wrong while updating this startup's name. Please try again. ` +
            'If this keeps happening, contact your administrator';
        }
        dispatch({
          type: types.UPDATE_STARTUP_NAME_FAILURE,
          payload: {
            startup_id,
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      } else if (!updated_name) {
        const message =
          `A startup's name cannot be empty. If this is a stealth startup, ` +
          `please try setting a name that helps you identify this deal later.`;
        dispatch({
          type: types.UPDATE_STARTUP_NAME_FAILURE,
          payload: {
            startup_id,
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.UPDATE_STARTUP_NAME_REQUEST,
        payload: {
          startup_id,
        },
      });
      request
        .post(
          `/api/v1/pipelines/${deal_id}/startup`,
          { name: updated_name },
          dispatch
        )
        .then(({ data }) => {
          if (data && data.success) {
            dispatch({
              type: types.UPDATE_STARTUP_NAME_SUCCESS,
              payload: {
                startup_id,
                data: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_STARTUP_NAME_FAILURE,
              payload: {
                startup_id,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          let message;
          if (old_name) {
            message = `Something went wrong while updating "${old_name}"'s name. Please try again.`;
          } else {
            message = `Something went wrong while updating this startup's name. Please try again`;
          }
          dispatch({
            type: types.UPDATE_STARTUP_NAME_FAILURE,
            payload: {
              startup_id,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

export const updateStartupDetails = (deal_id, startup_id, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!deal_id || !startup_id) {
        let message =
          `Something went wrong while updating this startup's details. Please try again. ` +
          'If this keeps happening, contact your administrator';
        dispatch({
          type: types.UPDATE_STARTUP_DETAILS_FAILURE,
          payload: {
            startup_id,
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.UPDATE_STARTUP_DETAILS_REQUEST,
        payload: {
          startup_id,
          data: form,
        },
      });
      request
        .post(`/api/v1/pipelines/${deal_id}/startup`, form, dispatch)
        .then(({ data }) => {
          if (data && data.success) {
            dispatch({
              type: types.UPDATE_STARTUP_DETAILS_SUCCESS,
              payload: {
                startup_id,
                data: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_STARTUP_DETAILS_FAILURE,
              payload: {
                startup_id,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          let message;
          message = `Something went wrong while updating this startup's details. Please try again`;
          dispatch({
            type: types.UPDATE_STARTUP_DETAILS_FAILURE,
            payload: {
              startup_id,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

////////////////////////////////////////////////////////////////////////////////
///                              Portfolio
////////////////////////////////////////////////////////////////////////////////

export const updatePortfolioStartupName = (
  portco_id,
  startup_id,
  updated_name,
  old_name
) => {
  return async dispatch => {
    if (!portco_id || !startup_id) {
      let message;
      if (old_name) {
        message =
          `Something went wrong while updating "${old_name}"'s name. Please try again. ` +
          'If this keeps happening, contact your administrator';
      } else {
        message =
          `Something went wrong while updating this startup's name. Please try again. ` +
          'If this keeps happening, contact your administrator';
      }
      dispatch({
        type: types.UPDATE_STARTUP_NAME_FAILURE,
        payload: {
          startup_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    } else if (!updated_name) {
      const message =
        `A startup's name cannot be empty. If this is a stealth startup, ` +
        `please try setting a name that helps you identify this deal later.`;
      dispatch({
        type: types.UPDATE_STARTUP_NAME_FAILURE,
        payload: {
          startup_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.UPDATE_STARTUP_NAME_REQUEST,
      payload: {
        startup_id,
      },
    });
    let data;
    try {
      const response = await request.put(
        `/api/portfolio/v1/companies/${portco_id}/startup`,
        { name: updated_name },
        dispatch
      );
      data = response.data;
    } catch (err) {
      let message;
      if (old_name) {
        message = `Something went wrong while updating "${old_name}"'s name. Please try again.`;
      } else {
        message = `Something went wrong while updating this startup's name. Please try again`;
      }
      dispatch({
        type: types.UPDATE_STARTUP_NAME_FAILURE,
        payload: {
          startup_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data && data.success) {
      dispatch({
        type: types.UPDATE_STARTUP_NAME_SUCCESS,
        payload: {
          startup_id,
          data: data.data,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.UPDATE_STARTUP_NAME_FAILURE,
        payload: {
          startup_id,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const updatePortfolioStartupDetails = (portco_id, startup_id, form) => {
  return async dispatch => {
    if (!portco_id || !startup_id) {
      let message =
        `Something went wrong while updating this startup's details. Please try again. ` +
        'If this keeps happening, contact your administrator';
      dispatch({
        type: types.UPDATE_STARTUP_DETAILS_FAILURE,
        payload: {
          startup_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.UPDATE_STARTUP_DETAILS_REQUEST,
      payload: {
        startup_id,
        data: form,
      },
    });
    let data;
    try {
      const response = await request.put(
        `/api/portfolio/v1/companies/${portco_id}/startup`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      let message;
      message = `Something went wrong while updating this startup's details. Please try again`;
      dispatch({
        type: types.UPDATE_STARTUP_DETAILS_FAILURE,
        payload: {
          startup_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data && data.success) {
      dispatch({
        type: types.UPDATE_STARTUP_DETAILS_SUCCESS,
        payload: {
          startup_id,
          data: data.data,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.UPDATE_STARTUP_DETAILS_FAILURE,
        payload: {
          startup_id,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
