import {
  LOAD_DEAL_EVENT_NOTE_LIST_REQUEST,
  LOAD_DEAL_EVENT_NOTE_LIST_SUCCESS,
  LOAD_DEAL_EVENT_NOTE_LIST_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_DEAL_EVENT_NOTE_LIST_REQUEST:
      return Object.assign({}, state, {
        dealEventNotesLoading: {
          ...state.dealEventNotesLoading,
          [payload.eventId]: true
        },
        dealEventNotesError: {
          ...state.dealEventNotesError,
          [payload.eventId]: null
        }
      });

    case LOAD_DEAL_EVENT_NOTE_LIST_SUCCESS:
      return Object.assign({}, state, {
        dealEventNotes: {
          ...state.dealEventNotes,
          [payload.eventId]: (
            (payload.options.page === 1) ? payload.data
            : [...state.dealEventNotes[payload.eventId],...payload.data]
          )
        },
        dealEventNotesLoading: {
          ...state.dealEventNotesLoading,
          [payload.eventId]: false
        },
        dealEventNotesError: {
          ...state.dealEventNotesError,
          [payload.eventId]: null
        },
        dealEventNotesOptions: {
          ...state.dealEventNotesOptions,
          [payload.eventId]: payload.options
        },
        dealEventNotesMore: {
          ...state.dealEventNotesMore,
          [payload.eventId]: (payload.data.length >= payload.options.limit ? true : false)
        }
      });

    case LOAD_DEAL_EVENT_NOTE_LIST_FAILURE:
      return Object.assign({}, state, {
        dealEventNotesLoading: {
          ...state.dealEventNotesLoading,
          [payload.eventId]: false
        },
        dealEventNotesError: {
          ...state.dealEventNotesError,
          [payload.eventId]: payload.message
        },
      });

    default:
      return state;
  }
};
