export const GET_DATA_FROM_MESSAGE_ID_REQUEST =
  'emailProcessor/GET_DATA_FROM_MESSAGE_ID_REQUEST';
export const GET_DATA_FROM_MESSAGE_ID_SUCCESS =
  'emailProcessor/GET_DATA_FROM_MESSAGE_ID_SUCCESS';
export const GET_DATA_FROM_MESSAGE_ID_ERROR =
  'emailProcessor/GET_DATA_FROM_MESSAGE_ID_ERROR';

export const CREATE_DEAL_FROM_ORGCONTACT_REQUEST =
  'emailProcessor/CREATE_DEAL_FROM_ORGCONTACT_REQUEST';
export const CREATE_DEAL_FROM_ORGCONTACT_SUCCESS =
  'emailProcessor/CREATE_DEAL_FROM_ORGCONTACT_SUCCESS';
export const CREATE_DEAL_FROM_ORGCONTACT_ERROR =
  'emailProcessor/CREATE_DEAL_FROM_ORGCONTACT_ERROR';

export const ADD_TO_PORTCO_REQUEST = 'emailProcessor/ADD_TO_PORTCO_REQUEST';
export const ADD_TO_PORTCO_SUCCESS = 'emailProcessor/ADD_TO_PORTCO_SUCCESS';
export const ADD_TO_PORTCO_ERROR = 'emailProcessor/ADD_TO_PORTCO_ERROR';
