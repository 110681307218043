/**
 * Welcome container
 *
 * @author akhila
 */
import Finish from './Finish';
import GoogleConnect from './GoogleConnect';
import MicrosoftConnect from './MicrosoftConnect'
import NotFound from '../../components/NotFound';
import React from 'react';
import WelcomeAdmin from './WelcomeAdmin';
import WelcomeMember from './WelcomeMember';
import { Route, Switch } from 'react-router-dom';

const Welcome = () => (
  <Switch>
    <Route path="/welcome/welcome-admin" component={WelcomeAdmin} />
    <Route path="/welcome/welcome-member" component={WelcomeMember} />
    <Route path="/welcome/google-connect" component={GoogleConnect} />
    <Route path="/welcome/ms-connect" component={MicrosoftConnect} />
    <Route path="/welcome/finish"  component={Finish} />
    <Route path="*" component={NotFound}/>
  </Switch> 
);

export default Welcome;


