/**
 * @format
 */

import {
  INCREMENT_DEAL_ATTACHMENT_COUNT,
  DECREMENT_DEAL_ATTACHMENT_COUNT,
} from '../actions';

//By default increment 1
export default (dealId, modifier) => {
  return dispatch => {
    if (modifier.increment && modifier.increment > 0) {
      dispatch({
        type: INCREMENT_DEAL_ATTACHMENT_COUNT,
        payload: {
          dealId,
          increment: modifier.increment,
        },
      });
    } else if (modifier.decrement && modifier.decrement > 0) {
      dispatch({
        type: DECREMENT_DEAL_ATTACHMENT_COUNT,
        payload: {
          dealId,
          decrement: modifier.decrement,
        },
      });
    }
  };
};
