import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import isEmptyString from '../../helpers/string/isEmptyString';
import Promise from 'bluebird';
import { enqueueNotification } from '../app';
import { AVAILABLE_RESOURCE_TYPES } from '../../constants/Reminder';
import filter from 'lodash.filter';
import omit from '../../helpers/object/omit';


export default reducers;

/**
 *
 * @param {String} resource_id        The id of the resource you want to add the reminder to
 * @param {String} primaryResourceId  If the resource id has a primary resource ex: deal-notes(resource_id) belongs to a deal
 *                                    Then this resource  would contain that deal_id
 * @param {String} resource_type      The type of resource:
 *        - deals
 *        - deal - emails(pipeline convos)
 *        - deal - notes (pipeline notes)
 *        - portfolios
 *        - portfolio -emails
 *        - portolio - updates
 * @param {Object} form
 * @returns
 */
export const addReminderToResource = (resource_id, primaryResourceId, resource_type, form) =>{
  return async dispatch =>{
    if(isEmptyString(resource_id) || isEmptyString(resource_type)){
      const message = `Something went wrong when adding this reminder.
       Please try again. If this keeps happening, contact your admin`;

       dispatch(enqueueNotification(message));
       throw new Error(message);
    }
    dispatch({
      type: types.ADD_REMINDER_TO_RESOURCE_REQUEST,
      payload:{
        resource_type,
        resource_id
      }
    });

    //Depending on the resource_type we modify the form
    switch(resource_type) {
      //Resource types dependent on deals
      case AVAILABLE_RESOURCE_TYPES.PIPELINE_CONVERSATIONS:
      case AVAILABLE_RESOURCE_TYPES.DEAL_NOTES:
      case AVAILABLE_RESOURCE_TYPES.DEALS_SIGNALS: {
        form = {
          ...form,
          deal_id: primaryResourceId,
        };
        break;
      }

      //Resource types dependent on portcos
      case AVAILABLE_RESOURCE_TYPES.PORTFOLIO_CONVERSATIONS:
      case AVAILABLE_RESOURCE_TYPES.PORTFOLIO_UPDATES: {
        form = {
          ...form,
          portco_id: primaryResourceId,
        }
        break;
      }

      //Resource types dependent on funds
      case AVAILABLE_RESOURCE_TYPES.FUND_NOTES: {
        form = {
          ...form,
          fund_id: primaryResourceId,
        }
        break;
      }

      //Resource types dependent on person contacts notes
      case AVAILABLE_RESOURCE_TYPES.PERSON_CONTACT_NOTES: {
        form={
          ...form,
          person_contact_id: primaryResourceId,
        }
        break;
      }

      //Resource types dependent on organization contacts notes
      case AVAILABLE_RESOURCE_TYPES.ORGANIZATION_CONTACT_NOTES: {
        form={
          ...form,
          organization_contact_id: primaryResourceId,
        }
        break;
      }
      
      default:
        //For all resources which are not dependent on a primary resource id(Ex: deals, portfolios)
        form = {...form}
    }
    //

    let data;
    try{
      const response = await request.post(`/api/reminders/v1/resource/${resource_id}`,{
        ...form,
        resource_type
      }, dispatch)
      data = response.data
    }catch(err){
      const message = 'Something went wrong while adding this reminder. ' +
          'Please try again.';
        dispatch({
          type: types.ADD_REMINDER_TO_RESOURCE_FAILURE,
          payload: {
            resource_id,
            resource_type,
            message
          }
        });
        dispatch(enqueueNotification(message));
        throw err;
    }
    if(data.success){
      dispatch({
        type: types.ADD_REMINDER_TO_RESOURCE_SUCCESS,
        payload:{
          resource_type,
          resource_id,
          data: data.data
        }
      });

      dispatch(enqueueNotification('Reminder added successfully'));
      return data.data;
    } else{
      dispatch({
        type: types.ADD_REMINDER_TO_RESOURCE_FAILURE,
        payload:{
          resource_id,
          resource_type,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const getRemindersForResource = (resource_id, resource_type, options={}) => {
  return async dispatch => {
    if(isEmptyString(resource_id) || isEmptyString(resource_type)){
      const message = `Something went wrong when fetching reminders
       Please try again. If this keeps happening, contact your admin`;

       dispatch(enqueueNotification(message));
       throw new Error(message);
    }
    dispatch({
      type: types.GET_REMINDER_FOR_RESOURCE_REQUEST,
      payload: {
        resource_id,
        resource_type
      }
    });
    let data;
    try{
      const response = await request.get(`/api/reminders/v1/resource/${resource_id}`,{
        resource_type,
        limit: options.limit || 10,
        page: options.page || 1,
        orderBy: options.orderBy || 'asc'
      },dispatch);
      data = response.data

    }catch(err){
      const message = `Something went wrong while fetching reminders`
      dispatch({
        type: types.GET_REMINDER_FOR_RESOURCE_FAILURE,
        payload:{
          resource_type,
          resource_id,
          message
        }
      });
      dispatch(enqueueNotification(message))
      throw err;
    }
    if(data.success){
      dispatch({
        type: types.GET_REMINDER_FOR_RESOURCE_SUCCESS,
        payload:{
          resource_id,
          resource_type,
          data: data.data,
          metadata: data.metadata,
        }
      });
      return data.data;
    } else {
      dispatch({
        type: types.GET_REMINDER_FOR_RESOURCE_FAILURE,
        payload:{
          resource_id,
          resource_type,
          message: data.message
        }
      });

      throw new Error(data.message);
    }
  };
};

//Function that updates a reminder
export const updateReminderById = (
  reminderId,
  resource_type,
  resource_id,
  form={remindAt: '',title: ''}) => {
  return async dispatch =>{
    if(isEmptyString(reminderId) || isEmptyString( resource_type) || isEmptyString(resource_id)){
      const message = `Something went wrong when updating reminder
      Please try again`;

      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.UPDATE_REMINDER_FOR_RESOURCE_REQUEST,
      payload:{
        resource_id,
        resource_type,
        reminderId
      }
    });
   
    let data;
    try{
      const response = await request.patch(`/api/reminders/v1/${reminderId}`,{...form},dispatch);
      data = response.data
    }catch(err){
      const message = `Something went wrong while updating reminder`
      dispatch({
        type: types.UPDATE_REMINDER_FOR_RESOURCE_FAILURE,
        payload:{
          resource_type,
          resource_id,
          reminderId,
          message
        }
      });
   
      dispatch(enqueueNotification(message));
      throw err;
    }
    if(data.success){
      dispatch({
        type: types.UPDATE_REMINDER_FOR_RESOURCE_SUCCESS,
        payload:{
          resource_type,
          resource_id,
          reminderId,
          data: data.data
        }
      });
  
      dispatch(enqueueNotification('Reminder updated successfully'))
      return data.data
    } else{
      dispatch({
        type: types.UPDATE_REMINDER_FOR_RESOURCE_FAILURE,
        payload:{
          resource_id,
          resource_type,
          reminderId,
          message: data.message
        }
      });
     
      throw new Error(data.message);
    }

  }

}



//Deletes a reminder
export const deleteReminderById = (
  reminderId,
  resource_type,
  resource_id,
  form={is_recurring: false}
) =>{
  return async dispatch => {
    if(isEmptyString(reminderId) || isEmptyString( resource_type) || isEmptyString(resource_id)){
      const message = `Something went wrong when deleting reminder
      Please try again`;

      dispatch(enqueueNotification(message));
      throw new Error(message);
    }

    dispatch({
      type: types.DELETE_REMINDER_FOR_RESOURCE_REQUEST,
      payload:{
        resource_id,
        resource_type,
        reminderId
      }
    });

  

    let data;
    try{
      const response = await request.del(`/api/reminders/v1/${reminderId}`,{...form},dispatch);
      data = response.data;
    }catch(err){
      const message = `Something went wrong while deleting reminder`
      dispatch({
        type: types.DELETE_REMINDER_FOR_RESOURCE_FAILURE,
        payload:{
          resource_type,
          resource_id,
          reminderId,
          message
        }
      });
    
      dispatch(enqueueNotification(message));
      throw err;
    }

    if(data.success){
      dispatch({
        type: types.DELETE_REMINDER_FOR_RESOURCE_SUCCESS,
        payload:{
          resource_type,
          resource_id,
          reminderId,
          isRecurring: form.is_recurring,
        }
      });
   
      dispatch(enqueueNotification('Reminder deleted successfully'));
    } else {
      dispatch({
        type: types.DELETE_REMINDER_FOR_RESOURCE_FAILURE,
        payload:{
          resource_id,
          resource_type,
          reminderId,
          message: data.message
        }
      });
  
      throw new Error(data.message)
    }
  }
}

// export const addConversationReminder = (conversationId, dealId, form) => {
//   return dispatch => {
//     return new Promise((resolve, reject) => {
//       if (isEmptyString(conversationId) || isEmptyString(dealId)) {
//         const message = 'Something went wrong while adding this reminder. ' +
//           'Please try again. If this keeps happening, contact your administrator';
//         dispatch(enqueueNotification(message));
//         return reject(new Error(message));
//       }
//       dispatch({
//         type: types.ADD_CONVERSATION_REMINDER_REQUEST,
//         payload: {
//           conversationId
//         }
//       });
//       request.post(`/api/reminders/v1/resource/${conversationId}`, {
//         ...form,
//         deal_id: dealId,
//         resource_type: 'pipeline_conversations'
//       }, dispatch)
//       .then(({ data }) => {
//         if (data.success) {
//           dispatch({
//             type: types.ADD_CONVERSATION_REMINDER_SUCCESS,
//             payload: {
//               conversationId,
//               data: data.data
//             }
//           });
//           dispatch(enqueueNotification('Reminder added successfully'));
//           return resolve(data);
//         } else {
//           dispatch({
//             type: types.ADD_CONVERSATION_REMINDER_FAILURE,
//             payload: {
//               conversationId,
//               message: data.message
//             }
//           });
//           dispatch(enqueueNotification(data.message));
//           return reject(new Error(data.message));
//         }
//       })
//       .catch((err) => {
//         const message = 'Something went wrong while adding this reminder. ' +
//           'Please try again.';
//         dispatch({
//           type: types.ADD_CONVERSATION_REMINDER_FAILURE,
//           payload: {
//             conversationId,
//             message
//           }
//         });
//         dispatch(enqueueNotification(message));
//         return reject(err);
//       });
//     });
//   };
// };








export const getBatchResourcesReminders = (resourceIds=[],resource_type, options={}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GET_BATCH_RESOURCES_REMINDERS_REQUEST,
        payload: {
          resourceIds,
          resource_type
        }
      });
      request.get(`/api/reminders/v1`, {ids: resourceIds,resource_type}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.GET_BATCH_RESOURCES_REMINDERS_SUCCESS,
            payload: {
              resourceIds,
              resource_type,
              // {deal_id: [{reminders, count, limit, page}]}
              data: data.data,
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.GET_BATCH_RESOURCES_REMINDERS_FAILURE,
            payload: {
              resourceIds,
              resource_type,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while fetching reminders. ' +
          'Please try again.';
        dispatch({
          type: types.GET_BATCH_RESOURCES_REMINDERS_FAILURE,
          payload: {
            resourceIds,
            resource_type,
            message
          }
        });
        dispatch(enqueueNotification(message));
        return reject(err);
      });
    });
  };
};

/**
 * Removes reminders which contain the recurring reminder id 
 * from the state and returns the object
 * @param {String} recurringReminderId The id of the recurring reminder id whose reminders have to be removed from the state
 * @param {Object} reduxStateForResourceType The object whose reminder ids have to be removed
 * @returns {Object} Object The original object where the reminders have been removed
 */
export const removeRemindersWithRecurringReminderId = (recurringReminderId, reduxStateForResourceType) =>{
//Fetch reminder ids with the recurring reminder id
const reminderIdsWithTheRecurringReminder = filter(
  Object.keys(reduxStateForResourceType),
  (reminderId) =>{
  //Add it to the accumlator if it is part of the recurring reminder
  return reduxStateForResourceType[reminderId]?.recurringReminder?.id === recurringReminderId
})

//Remove them from the redux reminders state
reduxStateForResourceType = omit(reduxStateForResourceType, reminderIdsWithTheRecurringReminder)
return reduxStateForResourceType;
};



// export const getBatchPortcosReminders = (portcoIds=[], options={}) => {
//   return dispatch => {
//     return new Promise((resolve, reject) => {
//       dispatch({
//         type: types.GET_BATCH_PORTCOS_REMINDERS_REQUEST,
//         payload: {
//           portcoIds
//         }
//       });
//       request.get(`/api/reminders/v1/portcos`, {ids: portcoIds}, dispatch)
//       .then(({ data }) => {
//         if (data.success) {
//           dispatch({
//             type: types.GET_BATCH_PORTCOS_REMINDERS_SUCCESS,
//             payload: {
//               portcoIds,
//               // {portco_id: [{reminders, count, limit, page}]}
//               data: data.data
//             }
//           });
//           return resolve(data);
//         } else {
//           dispatch({
//             type: types.GET_BATCH_PORTCOS_REMINDERS_FAILURE,
//             payload: {
//               portcoIds,
//               message: data.message
//             }
//           });
//           dispatch(enqueueNotification(data.message));
//           return reject(new Error(data.message));
//         }
//       })
//       .catch((err) => {
//         const message = 'Something went wrong while fetching reminders. ' +
//           'Please try again.';
//         dispatch({
//           type: types.GET_BATCH_PORTCOS_REMINDERS_FAILURE,
//           payload: {
//             portcoIds,
//             message
//           }
//         });
//         dispatch(enqueueNotification(message));
//         return reject(err);
//       });
//     });
//   };
// };


////////////////////////////////////////////////////////////////////////////////
///
/// Wrappers
///
////////////////////////////////////////////////////////////////////////////////

export const getRemindersForResourceFromState = (resource_id, resource_type)=> {
  return (dispatch, getState) => {
    const { reminders } = getState().reminder;
    if(!reminders) return [];
    const reminderIds = reminders[resource_type][resource_id]
    && Object.keys(reminders[resource_type][resource_id]);
    return reminderIds && reminderIds.map(id => reminders[resource_type][resource_id][id])
  }
};

export const getRemindersLoadingForResourceFromState = (resource_id, resource_type)=>{
  return (dispatch, getState) => {
    const {
      addReminderToResourceLoading,
      getRemindersForResourceLoading,
      batchDealsRemindersLoading,
      batchPortcosRemindersLoading,
    } = getState().reminder;

    let loadingState =  (addReminderToResourceLoading[resource_type][resource_id] ||
      getRemindersForResourceLoading[resource_type][resource_id]);

    if(resource_type === AVAILABLE_RESOURCE_TYPES.PIPELINES_OR_DEALS) {
      loadingState = loadingState || batchDealsRemindersLoading;
    } else if (resource_type === AVAILABLE_RESOURCE_TYPES.PORTFOLIOS) {
      loadingState = loadingState || batchPortcosRemindersLoading;
    }


    return loadingState || false;
  };
};

export const addRemindersForResource = async (resource_id,primaryResourceId, resource_type) =>{
  return async (dispatch,getState) => async form => {
    if(getRemindersLoadingForResourceFromState(resource_id,resource_type)(dispatch,getState)){
      return;
    }
    return await addReminderToResource(resource_id,primaryResourceId,resource_type,form)
  };
}

// export const getDealRemindersFromState = (dealId) => {
//   return (dispatch, getState) => {
//     const { reminders } = getState().reminder;
//     const reminderIds = Object.keys(reminders).filter(reminder_id => {
//       return (reminders[reminder_id].resource_type === 'pipelines' &&
//         reminders[reminder_id].resource_id === dealId)
//       });
//     return reminderIds.map(id => reminders[id]);
//   };
// };

export const getDealRemindersLoadingFromState = (dealId) => {
  return (dispatch, getState) => {
    const {
      addDealReminderLoading,
      getDealRemindersLoading,
      batchDealsRemindersLoading } = getState().reminder;
    return (addDealReminderLoading[dealId] || getDealRemindersLoading[dealId] ||
      batchDealsRemindersLoading[dealId] || false);
  };
};

export const getConversationRemindersFromState = (conversationId) => {
  return (dispatch, getState) => {
    const { reminders } = getState().reminder;
    const reminderIds = Object.keys(reminders).filter(reminder_id => {
      return (reminders[reminder_id].resource_type === 'pipeline_conversations' &&
        reminders[reminder_id].resource_id === conversationId)
      });
    return reminderIds.map(id => reminders[id]);
  };
};

export const getConversationRemindersLoadingFromState = (conversationId) => {
  return (dispatch, getState) => {
    const {
      addConversationReminderLoading,
      getConversationRemindersLoading } = getState().reminder;
    return (addConversationReminderLoading[conversationId] ||
      getConversationRemindersLoading[conversationId] || false);
  };
};

export const getNoteRemindersFromState = (noteId) => {
  return (dispatch, getState) => {
    const { reminders } = getState().reminder;
    const reminderIds = Object.keys(reminders).filter(reminder_id => {
      return (reminders[reminder_id].resource_type === 'notes' &&
        reminders[reminder_id].resource_id === noteId)
      });
    return reminderIds.map(id => reminders[id]);
  };
};

export const getNoteRemindersLoadingFromState = noteId => {
  return (dispatch, getState) => {
    const {
      addNoteReminderLoading,
      getNoteRemindersLoading } = getState().reminder;
    return (addNoteReminderLoading[noteId] ||
      getNoteRemindersLoading[noteId] || false);
  };
};

export const addReminderForDeal = dealId => {
  return (dispatch, getState) => form => {
    if(getDealRemindersLoadingFromState(dealId)(dispatch, getState)) {
      return Promise.resolve();
    }
    // return addDealReminder(dealId, form)(dispatch);
  };
};

export const addReminderForConversation = (conversationId, dealId) => {
  return (dispatch, getState) => form => {
    if(getConversationRemindersLoadingFromState(conversationId)(dispatch, getState)) {
      return Promise.resolve();
    }
  };
};

export const addReminderForNote = (noteId, dealId) => {
  return (dispatch, getState) => form => {
    if(getNoteRemindersLoadingFromState(noteId)(dispatch, getState)) {
      return Promise.resolve();
    }
    // return addNoteReminder(noteId, dealId, form)(dispatch);
  };
};


///
//////////////////////////// Portfolio begins //////////////////////////////////
///

export const getPortcoRemindersFromState = (portcoId) => {
  return (dispatch, getState) => {
    const { reminders } = getState().reminder;
    const reminderIds = Object.keys(reminders).filter(reminder_id => {
      return (reminders[reminder_id].resource_type === 'portfolio.companies' &&
        reminders[reminder_id].resource_id === portcoId)
      });
    return reminderIds.map(id => reminders[id]);
  };
};

export const getPortcoRemindersLoadingFromState = (portcoId) => {
  return (dispatch, getState) => {
    const {
      addPortcoReminderLoading,
      getPortcoRemindersLoading,
      batchPortcosRemindersLoading } = getState().reminder;
    return (addPortcoReminderLoading[portcoId] || getPortcoRemindersLoading[portcoId] ||
      batchPortcosRemindersLoading[portcoId] || false);
  };
};

export const getPortfolioConversationRemindersFromState = (conversationId) => {
  return (dispatch, getState) => {
    const { reminders } = getState().reminder;
    const reminderIds = Object.keys(reminders).filter(reminder_id => {
      return (reminders[reminder_id].resource_type === 'portfolio.conversations' &&
        reminders[reminder_id].resource_id === conversationId)
      });
    return reminderIds.map(id => reminders[id]);
  };
};

export const getPortfolioConversationRemindersLoadingFromState = (conversationId) => {
  return (dispatch, getState) => {
    const {
      addPortfolioConversationReminderLoading,
      getPortfolioConversationRemindersLoading } = getState().reminder;
    return (addPortfolioConversationReminderLoading[conversationId] ||
      getPortfolioConversationRemindersLoading[conversationId] || false);
  };
};

export const getPortfolioUpdateRemindersFromState = (updateId) => {
  return (dispatch, getState) => {
    const { reminders } = getState().reminder;
    const reminderIds = Object.keys(reminders).filter(reminder_id => {
      return (reminders[reminder_id].resource_type === 'portfolio.updates' &&
        reminders[reminder_id].resource_id === updateId)
      });
    return reminderIds.map(id => reminders[id]);
  };
};

export const getPortfolioUpdateRemindersLoadingFromState = updateId => {
  return (dispatch, getState) => {
    const {
      addPortfolioUpdateReminderLoading,
      getPortfolioUpdateRemindersLoading } = getState().reminder;
    return (addPortfolioUpdateReminderLoading[updateId] ||
      getPortfolioUpdateRemindersLoading[updateId] || false);
  };
};

export const addReminderForPortco = portcoId => {
  return (dispatch, getState) => form => {
    if(getPortcoRemindersLoadingFromState(portcoId)(dispatch, getState)) {
      return Promise.resolve();
    }
    // return addPortcoReminder(portcoId, form)(dispatch);
  };
};

export const addReminderForPortfolioConversation = (conversationId, portcoId) => {
  return (dispatch, getState) => form => {
    if(getPortfolioConversationRemindersLoadingFromState(conversationId)(dispatch, getState)) {
      return Promise.resolve();
    }
    // return addPortfolioConversationReminder(conversationId, portcoId, form)(dispatch);
  };
};

export const addReminderForPortfolioUpdate = (updateId, portcoId) => {
  return (dispatch, getState) => form => {
    if(getPortfolioUpdateRemindersLoadingFromState(updateId)(dispatch, getState)) {
      return Promise.resolve();
    }
    // return addPortfolioUpdateReminder(updateId, portcoId, form)(dispatch);
  };
};
