export const LOAD_EMAIL_TEMPLATE_LIST_REQUEST = 'email-template/LOAD_EMAIL_TEMPLATE_LIST_REQUEST';
export const LOAD_EMAIL_TEMPLATE_LIST_SUCCESS = 'email-template/LOAD_EMAIL_TEMPLATE_LIST_SUCCESS';
export const LOAD_EMAIL_TEMPLATE_LIST_FAILURE = 'email-template/LOAD_EMAIL_TEMPLATE_LIST_FAILURE';

export const ADD_EMAIL_TEMPLATE_REQUEST = 'email-template/ADD_EMAIL_TEMPLATE_REQUEST';
export const ADD_EMAIL_TEMPLATE_SUCCESS = 'email-template/ADD_EMAIL_TEMPLATE_SUCCESS';
export const ADD_EMAIL_TEMPLATE_FAILURE = 'email-template/ADD_EMAIL_TEMPLATE_FAILURE';

export const UPDATE_EMAIL_TEMPLATE_REQUEST = 'email-template/UPDATE_EMAIL_TEMPLATE_REQUEST';
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = 'email-template/UPDATE_EMAIL_TEMPLATE_SUCCESS';
export const UPDATE_EMAIL_TEMPLATE_FAILURE = 'email-template/UPDATE_EMAIL_TEMPLATE_FAILURE';

export const SOFT_DELETE_EMAIL_TEMPLATE_REQUEST = 'email-template/SOFT_DELETE_EMAIL_TEMPLATE_REQUEST';
export const SOFT_DELETE_EMAIL_TEMPLATE_SUCCESS = 'email-template/SOFT_DELETE_EMAIL_TEMPLATE_SUCCESS';
export const SOFT_DELETE_EMAIL_TEMPLATE_FAILURE = 'email-template/SOFT_DELETE_EMAIL_TEMPLATE_FAILURE';
