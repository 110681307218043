/**
 * ADD_SIGN_OFF_GROUP reducer
 * @format
 */
import {
  ADD_SIGN_OFF_GROUP_REQUEST,
  ADD_SIGN_OFF_GROUP_SUCCESS,
  ADD_SIGN_OFF_GROUP_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case ADD_SIGN_OFF_GROUP_REQUEST:
      return Object.assign({}, state, {
        addSignOffGroupLoading: true,
        addSignOffGroupError: null,
      });

    case ADD_SIGN_OFF_GROUP_SUCCESS:
      return Object.assign({}, state, {
        addSignOffGroupLoading: false,
        addSignOffGroupError: null,
        signOffGroups: {
          ...state.signOffGroups,
          [payload.data.id]: payload.data,
        },
        signOffGroupsDealsHashMap: {
          ...state.signOffGroupsDealsHashMap,
          [payload.dealId]: state.signOffGroupsDealsHashMap[payload.dealId]
            ? [
                // If the deal already has other sign offs, append the newest
                // one to the top
                payload.data.id,
                ...state.signOffGroupsDealsHashMap[payload.dealId],
              ]
            : // If it's the first one for this deal, initialize
              [payload.data.id],
        },
      });
    case ADD_SIGN_OFF_GROUP_FAILURE:
      return Object.assign({}, state, {
        addSignOffGroupLoading: false,
        addSignOffGroupError: payload.message,
      });

    default:
      return state;
  }
};
