const getCroreFromRupee = rupee => {
  return ((rupee/10000000).toFixed(2));
};

const getRupeeFromCrore = crore => {
  return (crore * 10000000);
};

export {
  getCroreFromRupee,
  getRupeeFromCrore
};
