/**
 * This function checks if the event triggers on specified element passed
 * 
 * @param  {Object} event  Any DOM event which can happen of element
 * @param  {Object} element 
 * @return {Boolean} True only if the event happens on the element 
 */
const isEventOnElement = (event, element) => {
  if ( !!!event || !!!element ) return false;
  // https://stackoverflow.com/a/42234988/5946125
  return !!element.contains(event.target);
};

/**
 * This function checks if event triggers away from the passed element
 * 
 * @param  {Object} event Any DOM event which can happen of element
 * @param  {Object} element 
 * @return {Boolean} True only if the event happens out from the element
 */
const isEventAwayFromElement = (event, element) => {
  if ( !!!event || !!!element ) return false;
  return !(isEventOnElement(event, element));
};

export default {
  isEventOnElement: isEventOnElement,
  isEventAwayFromElement: isEventAwayFromElement
};
