/** @format */
import {
  LOAD_CATEGORIES_REQUEST,
  LOAD_CATEGORIES_SUCCESS,
  LOAD_CATEGORIES_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case LOAD_CATEGORIES_REQUEST:
      return Object.assign({}, state, {
        categoriesLoading: {
          ...state.categoriesLoading,
          [payload.portcoId]: true,
        },
        categoriesError: {
          ...state.categoriesError,
          [payload.portcoId]: null,
        },
      });

    case LOAD_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        portcos: {
          ...state.portcos,
          [payload.portcoId]: state.portcos[payload.portcoId] && {
            ...state.portcos[payload.portcoId],
            categories: payload.data,
          },
        },
        categoriesLoading: {
          ...state.categoriesLoading,
          [payload.portcoId]: false,
        },
        categoriesError: {
          ...state.categoriesError,
          [payload.portcoId]: null,
        },
      });

    case LOAD_CATEGORIES_FAILURE:
      return Object.assign({}, state, {
        categoriesLoading: {
          ...state.categoriesLoading,
          [payload.portcoId]: false,
        },
        categoriesError: {
          ...state.categoriesError,
          [payload.portcoId]: payload.message,
        },
      });

    default:
      return state;
  }
};
