import * as types from './actions';
import reducers from './reducers';

export default reducers;

export const enqueueNotification = (message, options={reload:false, sticky:false, duration: 6000}) => {
  return dispatch => {
    if (message) {
      dispatch({ type: types.ENQUEUE_NOTIFICATION, payload: {
        message,
        reload: typeof options === 'object' ? (options.reload || false) : false,
        sticky: typeof options === 'object' ? (options.sticky || false) : false,
        duration: options.duration
      }});
    }
  };
};

export const dequeueNotification = () => {
  return dispatch => {
    dispatch({ type: types.DEQUEUE_NOTIFICATION });
  };
};

export const addHistory = (pathname) => {
  return dispatch => {
    dispatch({
      type: types.ADD_HISTORY,
      payload: pathname
    });
  };
};

// This reducer is to be called once the the main request(s) for a given page
// are loaded. The state changes that this does, informs components/containers
// throughout the application that the page is (almost) ready. Atleast that the
// main resource(s) required to render the content in the dashboard has been loaded
export const pageLoaded = () => {
  return dispatch => {
    dispatch({
      type: types.PAGE_LOADED
    });
  };
};

export const mainSidebarToggle = () => {
  return dispatch => {
    dispatch({
      type: types.MAIN_SIDEBAR_TOGGLE
    });
  };
};

export const mainSidebarOpen = () => {
  return dispatch => {
    dispatch({
      type: types.MAIN_SIDEBAR_OPEN
    });
  };
};

export const mainSidebarClose = () => {
  return dispatch => {
    dispatch({
      type: types.MAIN_SIDEBAR_CLOSE
    });
  };
};
