/**
 * @format
 */

import request from '../../../helpers/request';
import * as types from '../actions';
import { LOAD_DEAL_SUCCESS } from '../../deal/actions';
import { GET_PORTCO_SUCCESS } from '../../portfolio/actions';
import { GET_PERSON_SUCCESS } from '../../personContact/actions';
import { GET_ORG_CONTACT_SUCCESS } from '../../orgContact/actions';
import { SET_EMAIL_DATA } from '../../email/actions';
import { enqueueNotification } from '../../app';

/**
 * Load data for a given email by its message id
 */
export default (messageId, params = {}, options = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GET_DATA_FROM_MESSAGE_ID_REQUEST,
        payload: {
          messageId,
          options: params,
        },
      });
      request
        .get(
          '/api/email-processor/v1/data',
          {
            message_id: messageId,
            clientPlatform: 'dashboard',
            ...params,
          },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.GET_DATA_FROM_MESSAGE_ID_SUCCESS,
              payload: {
                messageId,
                data: data.data,
              },
            });
            const apiResponseData = data.data;
            if (
              apiResponseData &&
              apiResponseData.deals &&
              apiResponseData.deals.length > 0
            ) {
              apiResponseData.deals.forEach(deal => {
                dispatch({
                  type: LOAD_DEAL_SUCCESS,
                  payload: {
                    dealId: deal.id,
                    data: deal,
                  },
                });
              });
            }
            if (
              apiResponseData &&
              apiResponseData.portcos &&
              apiResponseData.portcos.length > 0
            ) {
              apiResponseData.portcos.forEach(portco => {
                dispatch({
                  type: GET_PORTCO_SUCCESS,
                  payload: {
                    portcoId: portco.id,
                    data: portco,
                  },
                });
              });
            }
            if (
              apiResponseData &&
              apiResponseData.personContacts &&
              apiResponseData.personContacts.length > 0
            ) {
              apiResponseData.personContacts.forEach(person => {
                dispatch({
                  type: GET_PERSON_SUCCESS,
                  payload: {
                    personContactId: person.id,
                    data: person,
                  },
                });
              });
            }
            if (
              apiResponseData &&
              apiResponseData.organizationContacts &&
              apiResponseData.organizationContacts.length > 0
            ) {
              apiResponseData.organizationContacts.forEach(orgContact => {
                dispatch({
                  type: GET_ORG_CONTACT_SUCCESS,
                  payload: {
                    orgContactId: orgContact.id,
                    data: orgContact,
                  },
                });
              });
            }
            // @todo: portco, personContact, orgContact
            if (
              apiResponseData &&
              apiResponseData.email &&
              apiResponseData.email.id
            ) {
              //commented out because causing the manipulation of emails state

              // dispatch({
              //   type: LOAD_EMAIL_SUCCESS,
              //   payload: {
              //     emailId: apiResponseData.email.id,
              //     data: apiResponseData.email,
              //   },
              // });
              // record the ids of different resources associated with the email
              dispatch({
                type: SET_EMAIL_DATA,
                payload: {
                  emailId: apiResponseData.email.id,
                  data: apiResponseData,
                },
              });
            }
            resolve(apiResponseData);
          } else {
            dispatch({
              type: types.GET_DATA_FROM_MESSAGE_ID_ERROR,
              payload: {
                messageId,
                message:
                  data.message || 'Something went wrong in fetching data',
              },
            });
            !options.isBackgroundRequest &&
              dispatch(
                enqueueNotification(
                  data.message || 'Something went wrong in fetching data'
                )
              );
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          dispatch({
            type: types.GET_DATA_FROM_MESSAGE_ID_ERROR,
            payload: {
              messageId,
              message: 'Something went wrong in fetching data',
            },
          });
          !options.isBackgroundRequest &&
            dispatch(
              enqueueNotification('Something went wrong in fetching data')
            );
          reject(err);
        });
    });
  };
};
