
export const CREATE_PERSON_CONTACT_DOCUMENT_REQUEST = 'person-contact-document/CREATE_PERSON_CONTACT_DOCUMENT_REQUEST';
export const CREATE_PERSON_CONTACT_DOCUMENT_SUCCESS = 'person-contact-document/CREATE_PERSON_CONTACT_DOCUMENT_SUCCESS';
export const CREATE_PERSON_CONTACT_DOCUMENT_FAILURE = 'person-contact-document/CREATE_PERSON_CONTACT_DOCUMENT_FAILURE';

export const LIST_PERSON_CONTACT_DOCUMENTS_REQUEST = 'person-contact-document/LIST_PERSON_CONTACT_DOCUMENTS_REQUEST';
export const LIST_PERSON_CONTACT_DOCUMENTS_SUCCESS = 'person-contact-document/LIST_PERSON_CONTACT_DOCUMENTS_SUCCESS';
export const LIST_PERSON_CONTACT_DOCUMENTS_FAILURE = 'person-contact-document/LIST_PERSON_CONTACT_DOCUMENTS_FAILURE';

export const GET_PERSON_CONTACT_DOCUMENT_SIGNEDURL_REQUEST = 'person-contact-document/GET_PERSON_CONTACT_DOCUMENT_SIGNEDURL_REQUEST';
export const GET_PERSON_CONTACT_DOCUMENT_SIGNEDURL_SUCCESS = 'person-contact-document/GET_PERSON_CONTACT_DOCUMENT_SIGNEDURL_SUCCESS';
export const GET_PERSON_CONTACT_DOCUMENT_SIGNEDURL_FAILURE = 'person-contact-document/GET_PERSON_CONTACT_DOCUMENT_SIGNEDURL_FAILURE';

export const ADD_TAG_TO_PERSON_CONTACT_DOCUMENT_REQUEST = 'person-contact-document/ADD_TAG_TO_PERSON_CONTACT_DOCUMENT_REQUEST';
export const ADD_TAG_TO_PERSON_CONTACT_DOCUMENT_SUCCESS = 'person-contact-document/ADD_TAG_TO_PERSON_CONTACT_DOCUMENT_SUCCESS';
export const ADD_TAG_TO_PERSON_CONTACT_DOCUMENT_FAILURE = 'person-contact-document/ADD_TAG_TO_PERSON_CONTACT_DOCUMENT_FAILURE';

export const REMOVE_TAG_FROM_PERSON_CONTACT_DOCUMENT_REQUEST = 'person-contact-document/REMOVE_TAG_FROM_PERSON_CONTACT_DOCUMENT_REQUEST';
export const REMOVE_TAG_FROM_PERSON_CONTACT_DOCUMENT_SUCCESS = 'person-contact-document/REMOVE_TAG_FROM_PERSON_CONTACT_DOCUMENT_SUCCESS';
export const REMOVE_TAG_FROM_PERSON_CONTACT_DOCUMENT_FAILURE = 'person-contact-document/REMOVE_TAG_FROM_PERSON_CONTACT_DOCUMENT_FAILURE';

export const DELETE_PERSON_CONTACT_DOCUMENT_REQUEST = 'person-contact-document/DELETE_PERSON_CONTACT_DOCUMENT_REQUEST';
export const DELETE_PERSON_CONTACT_DOCUMENT_SUCCESS = 'person-contact-document/DELETE_PERSON_CONTACT_DOCUMENT_SUCCESS';
export const DELETE_PERSON_CONTACT_DOCUMENT_FAILURE = 'person-contact-document/DELETE_PERSON_CONTACT_DOCUMENT_FAILURE';

export const HIDE_OR_UNHIDE_PERSON_CONTACT_DOCUMENT_REQUEST = 'person-contact-document/HIDE_OR_UNHIDE_PERSON_CONTACT_DOCUMENT_REQUEST';
export const HIDE_OR_UNHIDE_PERSON_CONTACT_DOCUMENT_SUCCESS = 'person-contact-document/HIDE_OR_UNHIDE_PERSON_CONTACT_DOCUMENT_SUCCESS';
export const HIDE_OR_UNHIDE_PERSON_CONTACT_DOCUMENT_FAILURE = 'person-contact-document/HIDE_OR_UNHIDE_PERSON_CONTACT_DOCUMENT_FAILURE';

export const LIST_PERSON_CONTACT_DOCUMENT_TAGS_REQUEST = 'person-contact-document/LIST_PERSON_CONTACT_DOCUMENT_TAGS_REQUEST';
export const LIST_PERSON_CONTACT_DOCUMENT_TAGS_SUCCESS = 'person-contact-document/LIST_PERSON_CONTACT_DOCUMENT_TAGS_SUCCESS';
export const LIST_PERSON_CONTACT_DOCUMENT_TAGS_FAILURE = 'person-contact-document/LIST_PERSON_CONTACT_DOCUMENT_TAGS_FAILURE';

export const FILE_UPLOAD_S3_REQUEST = 'person-contact-document/FILE_UPLOAD_S3_REQUEST';
export const FILE_UPLOAD_S3_SUCCESS = 'person-contact-document/FILE_UPLOAD_S3_SUCCESS';
export const FILE_UPLOAD_S3_FAILURE = 'person-contact-document/FILE_UPLOAD_S3_FAILURE';

export const FILE_DOWNLOAD_S3_REQUEST = 'person-contact-document/FILE_DOWNLOAD_S3_REQUEST';
export const FILE_DOWNLOAD_S3_SUCCESS = 'person-contact-document/FILE_DOWNLOAD_S3_SUCCESS';
export const FILE_DOWNLOAD_S3_FAILURE = 'person-contact-document/FILE_DOWNLOAD_S3_FAILURE';

export const CLEAR = 'person-contact-document/CLEAR';
