/** @format */
import CONSTANTS from '../../constants/Attachment';
import axios from 'axios';
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const getSignedUrlForEmailAttachment = (
  emailId,
  attachmentId,
  options = {}
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!emailId || !attachmentId) {
        const message =
          'Something went wrong while downloading this attachment. Please try again. ' +
          'If this keeps happening, contact your administrator';
        dispatch({
          type: types.EMAIL_ATTACHMENT_GETSIGNEDURL_FAILURE,
          payload: {
            attachmentId,
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.EMAIL_ATTACHMENT_GETSIGNEDURL_REQUEST,
        payload: {
          attachmentId,
        },
      });
      request
        .get(
          `/api/v1/emails/${emailId}/attachments/${attachmentId}/getSignedURL`,
          { download: options.download },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.EMAIL_ATTACHMENT_GETSIGNEDURL_SUCCESS,
              payload: {
                attachmentId,
                data: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.EMAIL_ATTACHMENT_GETSIGNEDURL_FAILURE,
              payload: {
                attachmentId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while downloading this attachment. Please try again.';
          dispatch({
            type: types.EMAIL_ATTACHMENT_GETSIGNEDURL_FAILURE,
            payload: {
              attachmentId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

export const getSignedUrlForDealAttachment = (
  dealId,
  attachmentId,
  options = {}
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!dealId || !attachmentId) {
        const message =
          'Something went wrong while downloading this attachment. Please try again. ' +
          'If this keeps happening, contact your administrator';
        dispatch({
          type: types.DEAL_ATTACHMENT_GETSIGNEDURL_FAILURE,
          payload: {
            attachmentId,
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.DEAL_ATTACHMENT_GETSIGNEDURL_REQUEST,
        payload: {
          attachmentId,
        },
      });
      request
        .get(
          `/api/v1/pipelines/${dealId}/attachments/${attachmentId}/getSignedURL`,
          { download: options.download },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.DEAL_ATTACHMENT_GETSIGNEDURL_SUCCESS,
              payload: {
                attachmentId,
                data: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.DEAL_ATTACHMENT_GETSIGNEDURL_FAILURE,
              payload: {
                attachmentId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while downloading this attachment. Please try again.';
          dispatch({
            type: types.DEAL_ATTACHMENT_GETSIGNEDURL_FAILURE,
            payload: {
              attachmentId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

export const getSignedUrlForPortCoAttachment = (
  portcoId,
  attachmentId,
  options = {}
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!portcoId || !attachmentId) {
        const message =
          'Something went wrong while processing this attachment. Please try again. ' +
          'If this keeps happening, contact your administrator';
        dispatch({
          type: types.PORTCO_ATTACHMENT_GETSIGNEDURL_FAILURE,
          payload: {
            attachmentId,
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.PORTCO_ATTACHMENT_GETSIGNEDURL_REQUEST,
        payload: {
          attachmentId,
        },
      });
      request
        .get(
          `/api/portfolio/attachments/v1/getSignedUrl`,
          {
            download: options.download,
            method: options.method || 'getObject',
            expires: options.expires,
            portco_id: portcoId,
            attachment_id: attachmentId,
          },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.PORTCO_ATTACHMENT_GETSIGNEDURL_SUCCESS,
              payload: {
                attachmentId,
                data: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.PORTCO_ATTACHMENT_GETSIGNEDURL_FAILURE,
              payload: {
                attachmentId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while processing this attachment. Please try again.';
          dispatch({
            type: types.PORTCO_ATTACHMENT_GETSIGNEDURL_FAILURE,
            payload: {
              attachmentId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

export const getSignedUrlForDealAttachmentUpload = (dealId, files) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!dealId || !files || !files.length) {
        const message = 'Please select a file to upload';
        dispatch({
          type: types.DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE,
          payload: {
            dealId,
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST,
        payload: {
          dealId,
        },
      });
      request
        .post(
          `/api/v2/attachments/getSignedURL?variant=deal_attachment_batch_upload&deal_id=${dealId}`,
          { files },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS,
              payload: {
                dealId,
                data: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE,
              payload: {
                dealId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          let message =
            'Something went wrong while uploading attachment(s). Please try again.';
          if (err?.response?.status === 429) {
            message = `Too fast! Try again in a couple of seconds.`;
          }
          dispatch({
            type: types.DEAL_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE,
            payload: {
              dealId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

export const getSignedUrlForPortCoAttachmentUpload = (
  portcoId,
  files,
  options = {}
) => {
  return async dispatch => {
    if (!portcoId || !files || !files.length) {
      const message = 'Please select a file to upload';
      dispatch({
        type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE,
        payload: {
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST,
      payload: {
        portcoId,
      },
    });
    let data;
    try {
      const response = await request.post(
        `/api/portfolio/attachments/v1/getSignedUrlForUpload`,
        {
          files,
          portco_id: portcoId,
          attachment_source_key: options.attachment_source_key,
          comment_id: options.comment_id,
          update_id: options.update_id,
          expires: options.expires,
        },
        dispatch
      );
      data = response.data;
    } catch (err) {
      let message =
        'Something went wrong while uploading attachment(s). Please try again.';
      if (err?.response?.status === 429) {
        message = `Too fast! Try again in a couple of seconds.`;
      }
      dispatch({
        type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE,
        payload: {
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS,
        payload: {
          portcoId,
          data: data.data,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE,
        payload: {
          portcoId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const getSignedUrlForPortCoUpdateAttachmentUpload = (
  portcoId,
  files,
  options = {}
) => async dispatch => {
  if (!portcoId || !files || !files.length) {
    const message = 'Please select a file to upload';
    dispatch({
      type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_UPDATE_FAILURE,
      payload: {
        portcoId,
        message,
      },
    });
    dispatch(enqueueNotification(message));
    throw new Error(message);
  }
  dispatch({
    type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_UPDATE_REQUEST,
    payload: {
      portcoId,
    },
  });
  let data;
  try {
    const response = await request.post(
      `/api/portfolio/attachments/v1/getSignedUrlForUpload`,
      {
        files,
        portco_id: portcoId,
        attachment_source_key:
          CONSTANTS.PORTCO_ATTACHMENT_SOURCE_KEY.PORTFOLIO_UPDATE.key,
        comment_id: options.comment_id,
        update_id: options.update_id,
        expires: options.expires,
      },
      dispatch
    );
    data = response.data;
  } catch (err) {
    const message =
      'Something went wrong while uploading attachment(s) for update. Please try again.';
    dispatch({
      type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_UPDATE_FAILURE,
      payload: {
        portcoId,
        message,
      },
    });
    dispatch(enqueueNotification(message));
    throw err;
  }
  if (data.success) {
    dispatch({
      type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_UPDATE_SUCCESS,
      payload: {
        portcoId,
        data: data.data,
      },
    });
    return data;
  } else {
    dispatch({
      type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_UPDATE_FAILURE,
      payload: {
        portcoId,
        message: data.message,
      },
    });
    dispatch(enqueueNotification(data.message));
    throw new Error(data.message);
  }
};

export const getDealAttachments = (dealId, options = {}) => {
  if (!dealId) {
    throw new Error('Deal id empty');
  }
  return async dispatch => {
    dispatch({
      type: types.GET_DEAL_ATTACHMENTS_REQUEST,
      payload: { dealId },
    });

    let data;
    try {
      const response = await request.get(
        `/api/pipeline/attachments/v1/${dealId}/list`,
        { ...options },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message = `Something went wrong when loading attachments from deal`;
      dispatch({
        type: types.GET_DEAL_ATTACHMENTS_FAILURE,
        dealId,
        message,
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (!data.success) {
      dispatch({
        type: types.GET_DEAL_ATTACHMENTS_FAILURE,
        dealId,
        message: data.message,
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
    dispatch({
      type: types.GET_DEAL_ATTACHMENTS_SUCCESS,
      payload: {
        dealId,
        data: data.data,
        options,
      },
    });
  };
};

export const loadAllTagsForDealsAttachments = () => {
  return async dispatch => {
    dispatch({
      type: types.DEAL_ATTACHMENT_LIST_TAGS_REQUEST,
    });
    let data;
    try {
      const response = await request.get(
        `/api/pipeline/attachments/v1/tags`,
        null,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message = `Something went wrong when loading tags`;
      dispatch({
        type: types.DEAL_ATTACHMENT_LIST_TAGS_FAILURE,
        payload: { message },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (!data.success) {
      dispatch({
        type: types.DEAL_ATTACHMENT_LIST_TAGS_FAILURE,
        payload: { message: data.message },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
    dispatch({
      type: types.DEAL_ATTACHMENT_LIST_TAGS_SUCCESS,
      payload: { tags: data.data },
    });

    return data.data;
  };
};

export const addTagToDealAttachment = (
  dealId,
  attachmentId,
  tag,
  options = {}
) => {
  return async dispatch => {
    if (!dealId || !attachmentId || !tag) {
      const message = `Deal id, attachment id or tag not present`;
      dispatch({
        type: types.DEAL_ATTACHMENT_ADD_TAG_FAILURE,
        payload: { dealId, message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.DEAL_ATTACHMENT_ADD_TAG_REQUEST,
      payload: { dealId },
    });

    let data;
    try {
      const response = await request.post(
        `/api/pipeline/attachments/v1/${attachmentId}/tag`,
        { deal_id: dealId, tag },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message = `Something went wrong when adding tag to attachment`;
      dispatch({
        type: types.DEAL_ATTACHMENT_ADD_TAG_FAILURE,
        payload: { message },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }

    if (!data.success) {
      dispatch({
        type: types.DEAL_ATTACHMENT_ADD_TAG_FAILURE,
        payload: {
          message: data.message,
          dealId,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }

    dispatch({
      type: types.DEAL_ATTACHMENT_ADD_TAG_SUCCESS,
      payload: { dealId, attachmentId, tag },
    });
  };
};

export const removeTagFromDealAttachment = (
  dealId,
  attachmentId,
  tag,
  options = {}
) => async dispatch => {
  if (!dealId || !attachmentId || !tag) {
    const message = `dealId/attachmentId/tag missing when trying to remove tag from said attachment`;
    dispatch({
      type: types.DEAL_ATTACHMENT_REMOVE_TAG_FAILURE,
      payload: { dealId, message },
    });
    enqueueNotification(message);
    throw new Error(message);
  }

  dispatch({
    type: types.DEAL_ATTACHMENT_REMOVE_TAG_REQUEST,
    payload: { dealId },
  });

  let data;
  try {
    const response = await request.del(
      `/api/pipeline/attachments/v1/${attachmentId}/untag`,
      { deal_id: dealId, tag },
      dispatch
    );
    data = response.data;
  } catch (err) {
    const message = `Something went wrong when removing tag from attachment`;
    dispatch({
      type: types.DEAL_ATTACHMENT_REMOVE_TAG_FAILURE,
      payload: { dealId, message },
    });
    dispatch(enqueueNotification(message));
    throw err;
  }
  if (!data.success) {
    dispatch({
      tye: types.DEAL_ATTACHMENT_REMOVE_TAG_FAILURE,
      payload: { dealId, message: data.message },
    });
    dispatch(enqueueNotification(data.message));
    throw new Error(data.message);
  }
  dispatch({
    type: types.DEAL_ATTACHMENT_REMOVE_TAG_SUCCESS,
    payload: { dealId, attachmentId, tag },
  });
};
export const getSignedUrlForPortCoEmailAttachmentUpload = (
  portcoId,
  files,
  options = {}
) => async dispatch => {
  if (!portcoId || !files || !files.length) {
    const message = 'Please select a file to upload';
    dispatch({
      type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_EMAIL_FAILURE,
      payload: {
        portcoId,
        message,
      },
    });
    dispatch(enqueueNotification(message));
    throw new Error(message);
  }
  dispatch({
    type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_EMAIL_REQUEST,
    payload: {
      portcoId,
    },
  });
  let data;
  try {
    const response = await request.post(
      `/api/portfolio/attachments/v1/getSignedUrlForUpload`,
      {
        files,
        portco_id: portcoId,
        attachment_source_key: CONSTANTS.PORTCO_ATTACHMENT_SOURCE_KEY.EMAIL.key,
        comment_id: options.comment_id,
        update_id: options.update_id,
        expires: options.expires,
      },
      dispatch
    );
    data = response.data;
  } catch (err) {
    const message =
      'Something went wrong while uploading attachment(s) for email. Please try again.';
    dispatch({
      type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_EMAIL_FAILURE,
      payload: {
        portcoId,
        message,
      },
    });
    dispatch(enqueueNotification(message));
    throw err;
  }
  if (data.success) {
    dispatch({
      type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_EMAIL_SUCCESS,
      payload: {
        portcoId,
        data: data.data,
      },
    });
    return data;
  } else {
    dispatch({
      type: types.PORTCO_ATTACHMENT_UPLOAD_GETSIGNEDURL_EMAIL_FAILURE,
      payload: {
        portcoId,
        message: data.message,
      },
    });
    dispatch(enqueueNotification(data.message));
    throw new Error(data.message);
  }
};

// https://medium.com/@kevinwu/client-side-file-upload-to-s3-using-axios-c9363ec7b530
export const fileUploadToS3 = (attachmentId, signedURL, file) => {
  return dispatch => {
    const attachmentContentType = file?.attachment?.mime_type;
    const fileContentType = file.ref?.type;
    if (
      attachmentContentType &&
      fileContentType &&
      attachmentContentType !== fileContentType
    ) {
      const message =
        'Something went wrong while uploading document, please try again.';
      dispatch({
        type: types.FILE_UPLOAD_S3_FAILURE,
        payload: {
          attachmentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      return Promise.reject(new Error(message));
    }
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.FILE_UPLOAD_S3_REQUEST,
        payload: { attachmentId },
      });
      // Note the difference here
      axios
        .put(signedURL, file?.ref ? file.ref : file, {
          headers: {
            'Content-Type': file?.attachment?.type
              ? file.attachment.type
              : file.ref
              ? file.ref.type
              : file.type,
          },
        })
        .then(({ data }) => {
          dispatch({
            type: types.FILE_UPLOAD_S3_SUCCESS,
            payload: { attachmentId, data },
          });
          resolve(data);
        })
        .catch(err => {
          const message =
            'Something went wrong while uploading document, please try again.';
          dispatch({
            type: types.FILE_UPLOAD_S3_FAILURE,
            payload: {
              attachmentId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const fileDownloadFromS3 = (attachmentId, signedURL, options) => {
  return async dispatch => {
    dispatch({
      type: types.FILE_DOWNLOAD_S3_REQUEST,
      payload: { attachmentId },
    });
    let data;
    try {
      // Note the difference here
      const response = await axios.get(signedURL, options);
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while downloading document, please try again.';
      dispatch({
        type: types.FILE_DOWNLOAD_S3_FAILURE,
        payload: {
          attachmentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    dispatch({
      type: types.FILE_DOWNLOAD_S3_SUCCESS,
      payload: { attachmentId, data },
    });
    return data;
  };
};

export const deleteDealAttachment = (attachmentId, dealId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!attachmentId || !dealId) {
        const message = 'Please select a file to delete';
        dispatch({
          type: types.DEAL_ATTACHMENT_DELETE_FAILURE,
          payload: {
            attachmentId,
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.DEAL_ATTACHMENT_DELETE_REQUEST,
        payload: {
          attachmentId,
        },
      });
      request
        .del(
          `/api/v2/attachments`,
          { deal_id: dealId, attachment_id: attachmentId },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.DEAL_ATTACHMENT_DELETE_SUCCESS,
              payload: {
                attachmentId,
                dealId,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.DEAL_ATTACHMENT_DELETE_FAILURE,
              payload: {
                attachmentId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while deleting attachment(s). Please try again.';
          dispatch({
            type: types.DEAL_ATTACHMENT_DELETE_FAILURE,
            payload: {
              attachmentId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

export const deleteAttachment = attachmentId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!attachmentId) {
        const message = 'Please select a file to delete';
        dispatch({
          type: types.ATTACHMENT_DELETE_FAILURE,
          payload: {
            attachmentId,
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.ATTACHMENT_DELETE_REQUEST,
        payload: {
          attachmentId,
        },
      });
      request
        .del(`/api/v3/attachments`, { attachment_id: attachmentId }, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.ATTACHMENT_DELETE_SUCCESS,
              payload: {
                attachmentId,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.ATTACHMENT_DELETE_FAILURE,
              payload: {
                attachmentId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while deleting attachment(s). Please try again.';
          dispatch({
            type: types.DEAL_ATTACHMENT_DELETE_FAILURE,
            payload: {
              attachmentId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

export const deleteDealNoteAttachment = (attachmentId, noteId, dealId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!attachmentId || !noteId || !dealId) {
        const message = 'Please select a file to delete';
        dispatch({
          type: types.NOTE_ATTACHMENT_DELETE_FAILURE,
          payload: {
            attachmentId,
            noteId,
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.NOTE_ATTACHMENT_DELETE_REQUEST,
        payload: {
          attachmentId,
          noteId,
        },
      });
      request
        .del(
          `/api/v3/attachments/note`,
          { deal_id: dealId, note_id: noteId, attachment_id: attachmentId },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.NOTE_ATTACHMENT_DELETE_SUCCESS,
              payload: {
                attachmentId,
                noteId,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.NOTE_ATTACHMENT_DELETE_FAILURE,
              payload: {
                attachmentId,
                noteId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while deleting attachment(s). Please try again.';
          dispatch({
            type: types.NOTE_ATTACHMENT_DELETE_FAILURE,
            payload: {
              attachmentId,
              noteId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

export const deletePortCoAttachment = (attachmentId, portcoId) => {
  return async dispatch => {
    if (!attachmentId || !portcoId) {
      const message = 'Please select a file to delete';
      dispatch({
        type: types.PORTCO_ATTACHMENT_DELETE_FAILURE,
        payload: {
          attachmentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.PORTCO_ATTACHMENT_DELETE_REQUEST,
      payload: {
        attachmentId,
      },
    });
    let data;
    try {
      const response = await request.del(
        `/api/portfolio/attachments/v1/${attachmentId}`,
        { portco_id: portcoId },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while deleting attachment(s). Please try again.';
      dispatch({
        type: types.PORTCO_ATTACHMENT_DELETE_FAILURE,
        payload: {
          attachmentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.PORTCO_ATTACHMENT_DELETE_SUCCESS,
        payload: {
          attachmentId,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.PORTCO_ATTACHMENT_DELETE_FAILURE,
        payload: {
          attachmentId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const hideOrUnhidePortCoAttachment = (
  portcoId,
  attachmentId,
  isHidden
) => {
  return async dispatch => {
    if (!attachmentId) {
      const message = 'Attachment id is missing';
      dispatch({
        type: types.HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_FAILURE,
        payload: {
          attachmentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_REQUEST,
      payload: {
        attachmentId,
      },
    });
    let data;
    try {
      const response = await request.put(
        `/api/portfolio/attachments/v1/${attachmentId}/hide_unhide`,
        { portco_id: portcoId, is_hidden: isHidden },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while deleting attachment(s). Please try again.';
      dispatch({
        type: types.HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_FAILURE,
        payload: {
          attachmentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_SUCCESS,
        payload: {
          attachmentId,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.HIDE_OR_UNHIDE_PORTCO_ATTACHMENT_FAILURE,
        payload: {
          attachmentId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

/**
 * The method name is get but actually it's post request
 * getSignedUrlForAttachmentUpload
 */
export const getSignedUrlForDealNoteAttachmentUpload = files => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!files || !files.length) {
        const message = 'Please select a file to upload';
        dispatch({
          type: types.NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE,
          payload: {
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST,
      });
      request
        .post(
          `/api/v2/attachments/getSignedURL?variant=note_attachment_batch_upload`,
          { files },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS,
              payload: {
                data: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while uploading attachment(s). Please try again.';
          dispatch({
            type: types.NOTE_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};

export const getSignedUrlForDealEmailAttachmentUpload = files => {
  return async dispatch => {
    if (!files || !files.length) {
      const message = 'Please select a file to upload';
      dispatch({
        type: types.EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_REQUEST,
    });

    let data;
    try {
      const response = await request.post(
        `/api/v2/attachments/getSignedURL?variant=${CONSTANTS.VARIANTS.EMAIL_ATTACHMENTS_BATCH_UPLOAD}`,
        { files },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while uploading attachment(s). Please try again.';
      dispatch({
        type: types.EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }

    if (data.success) {
      dispatch({
        type: types.EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_SUCCESS,
        payload: {
          data: data.data,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.EMAIL_ATTACHMENT_UPLOAD_GETSIGNEDURL_FAILURE,
        payload: {
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const getPortCoAttachments = (portcoId, options = {}) => {
  return async dispatch => {
    dispatch({
      type: types.GET_PORTFOLIO_ATTACHMENTS_REQUEST,
      payload: {
        portcoId,
      },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/attachments/v1/list`,
        { ...options, company: portcoId },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while listing attachment(s). Please try again.';
      dispatch({
        type: types.GET_PORTFOLIO_ATTACHMENTS_FAILURE,
        payload: {
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.GET_PORTFOLIO_ATTACHMENTS_SUCCESS,
        payload: {
          portcoId,
          data: data.data,
          metadata: data.metadata,
          options,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.GET_PORTFOLIO_ATTACHMENTS_FAILURE,
        payload: {
          portcoId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const loadAllTags = () => {
  return async dispatch => {
    const { data } = await request.get(`/api/portfolio/attachments/v1/tags`, null, dispatch); // prettier-ignore

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.PORTFOLIO_ATTACHMENT_LIST_TAGS,
      payload: { tags: data.data },
    });

    return data.data;
  };
};

export const removeTagFromAttachment = (
  portcoId,
  attachmentId,
  tag,
  options = {}
) => {
  return async dispatch => {
    const { data } = await request.post(
      `/api/portfolio/attachments/v1/${attachmentId}/untag?portco_id=${portcoId}&tag=${tag}`,
      options,
      dispatch
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.PORTFOLIO_ATTACHMENT_REMOVE_TAG,
      payload: { portcoId, attachmentId, tag },
    });
  };
};

export const addTagToAttachment = (
  portcoId,
  attachmentId,
  tag,
  options = {}
) => {
  return async dispatch => {
    const { data } = await request.post(
      `/api/portfolio/attachments/v1/${attachmentId}/tag?portco_id=${portcoId}&tag=${tag}`,
      options,
      dispatch
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.PORTFOLIO_ATTACHMENT_ADD_TAG,
      payload: { portcoId, attachmentId, tag },
    });
  };
};
