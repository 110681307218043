/**@format */
export const LOAD_CONSOLIDATED_REPORT_TEMPLATES_REQUEST =
  'consolidated-reports/LOAD_CONSOLIDATED_REPORT_TEMPLATES_REQUEST';

export const LOAD_CONSOLIDATED_REPORT_TEMPLATES_SUCCESS =
  'consolidated-reports/LOAD_CONSOLIDATED_REPORT_TEMPLATES_SUCCESS';

export const LOAD_CONSOLIDATED_REPORT_TEMPLATES_ERROR =
  'consolidated-reports/LOAD_CONSOLIDATED_REPORT_TEMPLATES_ERROR';

// Load generated reports
export const LOAD_CONSOLIDATED_REPORTS_REQUEST =
  'consolidated-reports/LOAD_CONSOLIDATED_REPORTS_REQUEST';

export const LOAD_CONSOLIDATED_REPORTS_SUCCESS =
  'consolidated-reports/LOAD_CONSOLIDATED_REPORTS_SUCCESS';

export const LOAD_CONSOLIDATED_REPORTS_ERROR =
  'consolidated-reports/LOAD_CONSOLIDATED_REPORTS_ERROR';

export const DELETE_REPORTS_REQUEST =
  'consolidated-reports/DELETE_REPORTS_REQUEST';
export const DELETE_REPORTS_SUCCESS =
  'consolidated-reports/DELETE_REPORTS_SUCCESS';
export const DELETE_REPORTS_ERROR = 'consolidated-reports/DELETE_REPORTS_ERROR';
