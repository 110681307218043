export const GET_EMAIL_ACTIONS_POINTS_REQUEST = 'stat/GET_EMAIL_ACTIONS_POINTS_REQUEST';
export const GET_EMAIL_ACTIONS_POINTS_SUCCESS = 'stat/GET_EMAIL_ACTIONS_POINTS_SUCCESS';
export const GET_EMAIL_ACTIONS_POINTS_FAILURE = 'stat/GET_EMAIL_ACTIONS_POINTS_FAILURE';

export const GET_EMAIL_ACTIONS_COUNT_REQUEST = 'stat/GET_EMAIL_ACTIONS_COUNT_REQUEST';
export const GET_EMAIL_ACTIONS_COUNT_SUCCESS = 'stat/GET_EMAIL_ACTIONS_COUNT_SUCCESS';
export const GET_EMAIL_ACTIONS_COUNT_FAILURE = 'stat/GET_EMAIL_ACTIONS_COUNT_FAILURE';

export const GET_MEMBER_EMAIL_ACTIONS_COUNT_REQUEST = 'stat/GET_MEMBER_EMAIL_ACTIONS_COUNT_REQUEST';
export const GET_MEMBER_EMAIL_ACTIONS_COUNT_SUCCESS = 'stat/GET_MEMBER_EMAIL_ACTIONS_COUNT_SUCCESS';
export const GET_MEMBER_EMAIL_ACTIONS_COUNT_FAILURE = 'stat/GET_MEMBER_EMAIL_ACTIONS_COUNT_FAILURE';

export const GET_DEAL_STATUSES_POINTS_REQUEST = 'stat/GET_DEAL_STATUSES_POINTS_REQUEST';
export const GET_DEAL_STATUSES_POINTS_SUCCESS = 'stat/GET_DEAL_STATUSES_POINTS_SUCCESS';
export const GET_DEAL_STATUSES_POINTS_FAILURE = 'stat/GET_DEAL_STATUSES_POINTS_FAILURE';

export const GET_DEAL_STATUSES_COUNT_REQUEST = 'stat/GET_DEAL_STATUSES_COUNT_REQUEST';
export const GET_DEAL_STATUSES_COUNT_SUCCESS = 'stat/GET_DEAL_STATUSES_COUNT_SUCCESS';
export const GET_DEAL_STATUSES_COUNT_FAILURE = 'stat/GET_DEAL_STATUSES_COUNT_FAILURE';

export const GET_DEAL_STAGES_POINTS_REQUEST = 'stat/GET_DEAL_STAGES_POINTS_REQUEST';
export const GET_DEAL_STAGES_POINTS_SUCCESS = 'stat/GET_DEAL_STAGES_POINTS_SUCCESS';
export const GET_DEAL_STAGES_POINTS_FAILURE = 'stat/GET_DEAL_STAGES_POINTS_FAILURE';

export const GET_DEAL_STAGES_COUNT_REQUEST = 'stat/GET_DEAL_STAGES_COUNT_REQUEST';
export const GET_DEAL_STAGES_COUNT_SUCCESS = 'stat/GET_DEAL_STAGES_COUNT_SUCCESS';
export const GET_DEAL_STAGES_COUNT_FAILURE = 'stat/GET_DEAL_STAGES_COUNT_FAILURE';

export const GET_DEAL_CATEGORIES_COUNT_REQUEST = 'stat/GET_DEAL_CATEGORIES_COUNT_REQUEST';
export const GET_DEAL_CATEGORIES_COUNT_SUCCESS = 'stat/GET_DEAL_CATEGORIES_COUNT_SUCCESS';
export const GET_DEAL_CATEGORIES_COUNT_FAILURE = 'stat/GET_DEAL_CATEGORIES_COUNT_FAILURE';
