/**
 * Adds a new portfolio company
 *
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  ADD_PORTCO_REQUEST,
  ADD_PORTCO_SUCCESS,
  ADD_PORTCO_FAILURE,
} from '../actions';

export default form => {
  return async dispatch => {
    dispatch({
      type: ADD_PORTCO_REQUEST,
    });
    let data;
    try {
      const response = await request.post(
        `/api/portfolio/v1/companies`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while adding the portfolio company, please try again or contact support.';
      dispatch({
        type: ADD_PORTCO_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: ADD_PORTCO_SUCCESS,
        payload: {
          portcoId: data.id,
          data: data,
        },
      });
      return data;
    } else {
      dispatch({
        type: ADD_PORTCO_FAILURE,
        payload: {
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
