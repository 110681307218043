/**
 * WelcomeMember container
 *
 * @format
 */
import Footer from '../../components/Footer';
import GuestPage from '../../components/GuestPage';
import PropTypes from 'prop-types';
import React from 'react';
import WelcomePage from '../../components/WelcomePage';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadUser } from '../../modules/auth';
import { saveStage } from '../../modules/setting';
import {
  EMAIL_SYNC_METHOD,
  FORWARDING_EMAIL_ADDRESS,
} from '../../constants/OrganizationSetting';
import isEmptyString from '../../helpers/string/isEmptyString';
import { getOrganizationSettingValueByKey } from '../../modules/setting';
import { EMAIL_SYNC_METHODS } from '../../constants/Email';

class WelcomeMember extends React.Component {
  static propTypes = {
    me: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    completedStages: PropTypes.array.isRequired,
    saveStage: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    saveStageLoading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { completedStages, history, me, organizationSettings } = this.props;
    if (
      completedStages.indexOf('welcome-member') > -1 ||
      me.roles.indexOf('admin') > -1
    ) {
      history.push('/dashboard');
    }

    const emailSyncMethod = organizationSettings[EMAIL_SYNC_METHOD];
    if (isEmptyString(emailSyncMethod)) {
      this.loadOrganizationSettings();
    }
  }

  loadOrganizationSettings = () => {
    Promise.all([
      this.props.getOrganizationSettingValueByKey(
        EMAIL_SYNC_METHOD,
        {
          ignoreCache: true,
        },
        {
          useCache: false,
        }
      ),
      this.props.getOrganizationSettingValueByKey(
        FORWARDING_EMAIL_ADDRESS,
        {
          ignoreCache: true,
        },
        {
          useCache: false,
        }
      ),
    ]).catch(() => {});
  };

  handleSubmit = event => {
    event && event.preventDefault();
    const {
      saveStage,
      loadUser,
      saveStageLoading,
      organizationSettings,
    } = this.props;
    if (saveStageLoading) return;
    saveStage('welcome-member')
      .then(data => {
        if (
          organizationSettings[EMAIL_SYNC_METHOD] ===
          EMAIL_SYNC_METHODS.FORWARDING_EMAIL.key
        ) {
          loadUser('/welcome/finish');
        } else if (
          organizationSettings[EMAIL_SYNC_METHOD] ===
          EMAIL_SYNC_METHODS.GMAIL_GSUITE.key
        ) {
          loadUser('/welcome/google-connect');
        } else {
          loadUser('/welcome/ms-connect');
        }
      })
      .catch(err => {});
  };

  render() {
    const { me, saveStageLoading } = this.props;
    return (
      <GuestPage>
        <WelcomePage
          header={`Welcome ${me.nameOrEmail}`}
          buttonName="Let's begin"
          handleSubmit={this.handleSubmit}
          loader={saveStageLoading}
        >
          <p>We are very delighted and happy to see you in here!</p>
          <p>
            We will take you through some features of <b>Taghash</b>, to make it
            easier to get started.
          </p>
        </WelcomePage>
        <Footer />
      </GuestPage>
    );
  }
}

const mapStateToProps = state => ({
  me: state.auth.user,
  completedStages: state.auth.stage,
  saveStageLoading: state.setting.saveStageLoading,
  organizationSettings: state.setting.organizationSettings,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveStage,
      loadUser,
      getOrganizationSettingValueByKey,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeMember);
