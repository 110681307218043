/**
 * @format
 */

import * as types from '../actions';

import { assign } from '../../../helpers/object';
import property from 'lodash.property';

export default (state, type, payload) => {
  switch (type) {
    /** GET_DRAWDOWN */
    case types.GET_DRAWDOWN_REQUEST: {
      return assign(state, {
        drawdownLoading: assign(state.drawdownLoading, {
          [payload.drawdownId]: true,
        }),
        drawdownError: assign(state.drawdownError, {
          [payload.drawdownId]: null,
        }),
      });
    }
    case types.GET_DRAWDOWN_FAILURE: {
      return assign(state, {
        drawdownLoading: assign(state.drawdownLoading, {
          [payload.drawdownId]: false,
        }),
        drawdownError: assign(state.drawdownError, {
          [payload.drawdownId]: payload.message,
        }),
      });
    }
    case types.GET_DRAWDOWN_SUCCESS: {
      return assign(state, {
        drawdownLoading: assign(state.drawdownLoading, {
          [payload.drawdownId]: false,
        }),
        drawdownError: assign(state.drawdownError, {
          [payload.drawdownId]: null,
        }),
        drawdown: assign(state.drawdown, {
          [payload.drawdownId]: assign(
            property(payload.drawdownId)(state.drawdown),
            payload.data
          ),
        }),
      });
    }

    default:
      return state;
  }
};
