
export const LOAD_DEAL_ACCESS_REQUEST_LIST_REQUEST = 'access-request/LOAD_DEAL_ACCESS_REQUEST_LIST_REQUEST'
export const LOAD_DEAL_ACCESS_REQUEST_LIST_SUCCESS = 'access-request/LOAD_DEAL_ACCESS_REQUEST_LIST_SUCCESS'
export const LOAD_DEAL_ACCESS_REQUEST_LIST_FAILURE = 'access-request/LOAD_DEAL_ACCESS_REQUEST_LIST_FAILURE'

// Accept access request
export const APPROVE_DEAL_ACCESS_REQUEST_REQUEST = 'access-request/APPROVE_DEAL_ACCESS_REQUEST_REQUEST'
export const APPROVE_DEAL_ACCESS_REQUEST_SUCCESS = 'access-request/APPROVE_DEAL_ACCESS_REQUEST_SUCCESS'
export const APPROVE_DEAL_ACCESS_REQUEST_FAILURE = 'access-request/APPROVE_DEAL_ACCESS_REQUEST_FAILURE'


// Reject access request
export const REJECT_DEAL_ACCESS_REQUEST_REQUEST = 'access-request/REJECT_DEAL_ACCESS_REQUEST_REQUEST'
export const REJECT_DEAL_ACCESS_REQUEST_SUCCESS = 'access-request/REJECT_DEAL_ACCESS_REQUEST_SUCCESS'
export const REJECT_DEAL_ACCESS_REQUEST_FAILURE = 'access-request/REJECT_DEAL_ACCESS_REQUEST_FAILURE'


// Single access request usefule when user is coming 
// form email url or notification url which will be
// /deals/:dealId/requests/:id
export const LOAD_SINGLE_DEAL_ACCESS_REQUEST_REQUEST = 'access-request/LOAD_SINGLE_DEAL_ACCESS_REQUEST_REQUEST'
export const LOAD_SINGLE_DEAL_ACCESS_REQUEST_SUCCESS = 'access-request/LOAD_SINGLE_DEAL_ACCESS_REQUEST_SUCCESS'
export const LOAD_SINGLE_DEAL_ACCESS_REQUEST_FAILURE = 'access-request/LOAD_SINGLE_DEAL_ACCESS_REQUEST_FAILURE'