import {
  LOAD_DEAL_NOTE_REQUEST,
  LOAD_DEAL_NOTE_SUCCESS,
  LOAD_DEAL_NOTE_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_DEAL_NOTE_REQUEST:
      return Object.assign({}, state, {
        noteLoading: {
          ...state.noteLoading,
          [payload.noteId]: true
        },
        noteError: {
          ...state.noteError,
          [payload.noteId]: null
        }
      });

    case LOAD_DEAL_NOTE_SUCCESS:
      return Object.assign({}, state, {
        note: {
          ...state.note,
          [payload.noteId]: payload.data
        },
        noteLoading: {
          ...state.noteLoading,
          [payload.noteId]: false
        },
        noteError: {
          ...state.noteError,
          [payload.noteId]: null
        }
      });

    case LOAD_DEAL_NOTE_FAILURE:
      return Object.assign({}, state, {
        noteLoading: {
          ...state.noteLoading,
          [payload.noteId]: false
        },
        noteError: {
          ...state.noteError,
          [payload.noteId]: payload.message
        },
      });

    default:
      return state;
  }
};
