export const LOAD_WORKSPACE_DEAL_STAGE_LIST_REQUEST = 'dealStage/LOAD_WORKSPACE_DEAL_STAGE_LIST_REQUEST';
export const LOAD_WORKSPACE_DEAL_STAGE_LIST_SUCCESS = 'dealStage/LOAD_WORKSPACE_DEAL_STAGE_LIST_SUCCESS';
export const LOAD_WORKSPACE_DEAL_STAGE_LIST_FAILURE = 'dealStage/LOAD_WORKSPACE_DEAL_STAGE_LIST_FAILURE';

export const UPDATE_DEAL_STAGE_REQUEST = 'dealStage/UPDATE_DEAL_STAGE_REQUEST';
export const UPDATE_DEAL_STAGE_SUCCESS = 'dealStage/UPDATE_DEAL_STAGE_SUCCESS';
export const UPDATE_DEAL_STAGE_FAILURE = 'dealStage/UPDATE_DEAL_STAGE_FAILURE';

export const SOFT_DELETE_DEAL_STAGE_REQUEST = 'dealStage/SOFT_DELETE_DEAL_STAGE_REQUEST';
export const SOFT_DELETE_DEAL_STAGE_SUCCESS = 'dealStage/SOFT_DELETE_DEAL_STAGE_SUCCESS';
export const SOFT_DELETE_DEAL_STAGE_FAILURE = 'dealStage/SOFT_DELETE_DEAL_STAGE_FAILURE';
