/**
 *
 * @format
 */
import request from '../../../helpers/request';
import {
  UPDATE_PORTFOLIO_TABLE_COLUMNS_REQUEST,
  UPDATE_PORTFOLIO_TABLE_COLUMNS_SUCCESS,
  UPDATE_PORTFOLIO_TABLE_COLUMNS_FAILURE,
} from '../actions';
import { enqueueNotification } from '../../app';
import setPortfolioTableColumnWidths from '../../../helpers/setPortfolioTableColumnsWidth';

export default orderedColumns => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UPDATE_PORTFOLIO_TABLE_COLUMNS_REQUEST,
        payload: orderedColumns,
      });
      request
        .put(
          `/api/portfolio/v1/portfolio_table_columns`,
          {
            columns: orderedColumns,
          },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: UPDATE_PORTFOLIO_TABLE_COLUMNS_SUCCESS,
              payload: orderedColumns,
            });
            setPortfolioTableColumnWidths(orderedColumns);
            resolve(data);
          } else {
            dispatch({
              type: UPDATE_PORTFOLIO_TABLE_COLUMNS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            // reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating table columns, please try again.';
          dispatch({
            type: UPDATE_PORTFOLIO_TABLE_COLUMNS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
