import * as types from './actions';
import getCategoryMap from '../../helpers/getCategoryMap';
import getRootCategoryChildren from '../../helpers/getRootCategoryChildren';
import { INDUSTRY, REVENUE_MODEL, TECHNOLOGY } from '../../constants/CATEGORY_TYPES';

const initialState = {
  //
  // specific types of categories, automatically gets populated when
  // categoryList is loaded
  industries: {},
  revenueModels: {},
  technologies: {},
  userDefinedCategoryTypes: {},

  categoryTypes: [],

  categoryList: {},
  categoryListLoading: false,
  categoryListError: null,

  addCategoryLoading: false,
  addCategoryError: null,

  removeCategoryLoading: {},
  removeCategoryError: {},

  categorySettings :{}
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
     * Category list reducers
     */
    case types.LOAD_CATEGORY_LIST_REQUEST:
      return Object.assign({}, state, {
        categoryListLoading: true,
        categoryListError: null
      });
    case types.LOAD_CATEGORY_LIST_SUCCESS:
      const categoryTypes=[]
      const categorySettings=[] 
      payload.forEach(rootCategoryOb=>{
        categoryTypes.push(rootCategoryOb.name)
        categorySettings[rootCategoryOb.name] = {disable_custom_category_addition_for_non_admins:rootCategoryOb.settings?.disable_custom_category_addition_for_non_admins}
      })
      return Object.assign({}, state, {
        categoryList: getCategoryMap(payload),
        industries: getCategoryMap(getRootCategoryChildren(payload, INDUSTRY)),
        revenueModels: getCategoryMap(getRootCategoryChildren(payload, REVENUE_MODEL)),
        technologies: getCategoryMap(getRootCategoryChildren(payload, TECHNOLOGY)),
        userDefinedCategoryTypes: (
          payload.filter(categoryTypeOb => ([INDUSTRY, REVENUE_MODEL, TECHNOLOGY]
          .indexOf(categoryTypeOb.name) === -1))
          .reduce((acc, categoryTypeSubTree) => {
            acc[categoryTypeSubTree.name] = getCategoryMap(getRootCategoryChildren(payload, categoryTypeSubTree.name));
            return acc
          }, {})
        ),
        categoryTypes,
        categorySettings,
        categoryListLoading: false,
        categoryListError: null
      });
    case types.LOAD_CATEGORY_LIST_FAILURE:
      return Object.assign({}, state, {
        categoryListLoading: false,
        categoryListError: payload,
      });

    /**
     * Add category reducers
     */
    case types.ADD_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        addCategoryLoading: true,
        addCategoryError: null
      });
    case types.ADD_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        categoryTypes: [...state.categoryTypes,payload.name],
        addCategoryLoading: false,
        addCategoryError: null
      });
    case types.ADD_CATEGORY_FAILURE:
      return Object.assign({}, state, {
        addCategoryLoading: false,
        addCategoryError: payload,
      });

    /**
     * Remove category reducers
     */
    case types.REMOVE_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        removeCategoryLoading: {
          ...state.removeCategoryLoading,
          [payload.name]: true
        },
        removeCategoryError: {
          ...state.removeCategoryError,
          [payload.name]: null
        }
      });
    case types.REMOVE_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        removeCategoryLoading: {
          ...state.removeCategoryLoading,
          [payload.name]: false
        },
        removeCategoryError: {
          ...state.removeCategoryError,
          [payload.name]: null
        }
      });
    case types.REMOVE_CATEGORY_FAILURE:
      return Object.assign({}, state, {
        removeCategoryLoading: {
          ...state.removeCategoryLoading,
          [payload.name]: false
        },
        removeCategoryError: {
          ...state.removeCategoryError,
          [payload.name]: payload.message
        }
      });
    case types.UPDATE_ROOT_CATEGORY_SETTING:
      return Object.assign({},state,{
        categorySettings: {...state.categorySettings,[payload.rootCategory]:{
          disable_custom_category_addition_for_non_admins:payload.disableCategoryAddition
        }}
      }); 
    default:
      return state;
  }
};
