/**
 * GET_SIGN_OFF_GROUP reducer
 * @format
 */
import {
  GET_SIGN_OFF_GROUP_REQUEST,
  GET_SIGN_OFF_GROUP_SUCCESS,
  GET_SIGN_OFF_GROUP_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case GET_SIGN_OFF_GROUP_REQUEST:
      return Object.assign({}, state, {
        getSignOffGroupLoading: {
          ...state.getSignOffGroupLoading,
          [payload.signOffGroupId]: true,
        },
        getSignOffGroupError: {
          ...state.getSignOffGroupError,
          [payload.signOffGroupId]: null,
        },
      });

    case GET_SIGN_OFF_GROUP_SUCCESS:
      return Object.assign({}, state, {
        getSignOffGroupLoading: {
          ...state.getSignOffGroupLoading,
          [payload.signOffGroupId]: false,
        },
        getSignOffGroupError: {
          ...state.getSignOffGroupError,
          [payload.signOffGroupId]: null,
        },
        // Populate sign off groups hashmap with returned data
        signOffGroups: {
          ...state.signOffGroups,
          [payload.signOffGroupId]: {
            ...(state.signOffGroups[payload.signOffGroupId] || {}),
            ...payload.data,
          },
        },
      });
    case GET_SIGN_OFF_GROUP_FAILURE:
      return Object.assign({}, state, {
        getSignOffGroupLoading: {
          ...state.getSignOffGroupLoading,
          [payload.signOffGroupId]: false,
        },
        getSignOffGroupError: {
          ...state.getSignOffGroupError,
          [payload.signOffGroupId]: payload.message,
        },
      });

    default:
      return state;
  }
};
