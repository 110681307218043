/**
 * Stats reducers
 *
 * @author Ritesh Shrivastav
 */
import * as types from './actions';
const initialState = {
  /**
   * `emailActionsPoints` has following structure:
   * {
   *   query: {}, // holds the last query which was used to fetch the data
   *   loading: (true || false),
   *   data: ({} || []), // depending on type of stats
   *   error: 'Error message' // if there were any error while fetching the
   *                          // stats
   * }
   */
  emailActionsPoints: {},
  /**
   * `emailActionsCount` has following structure:
   * {
   *   query: {}, // holds the last query which was used to fetch the data
   *   loading: (true || false),
   *   data: ({} || []), // depending on type of stats
   *   error: 'Error message' // if there were any error while fetching the
   *                          // stats
   * }
   */
  emailActionsCount: {},
  /**
   * `memberEmailActionsCount` keeps the investor id as the key and value will
   * have following structure:
   * {
   *   query: {}, // holds the last query which was used to fetch the data
   *   loading: (true || false),
   *   data: ({} || []), // depending on type of stats
   *   error: 'Error message' // if there were any error while fetching the
   *                          // stats
   * }
   */
  memberEmailActionsCount: {},
  /**
   * `dealStatusesPoints` has following structure:
   * {
   *   query: {}, // holds the last query which was used to fetch the data
   *   loading: (true || false),
   *   data: ({} || []), // depending on type of stats
   *   error: 'Error message' // if there were any error while fetching the
   *                          // stats
   * }
   */
  dealStatusesPoints: {},
  /**
   * `dealStatusesCount` has following structure:
   * {
   *   query: {}, // holds the last query which was used to fetch the data
   *   loading: (true || false),
   *   data: ({} || []), // depending on type of stats
   *   error: 'Error message' // if there were any error while fetching the
   *                          // stats
   * }
   */
  dealStatusesCount: {},
  /**
   * `dealStagesPoints` has following structure:
   * {
   *   query: {}, // holds the last query which was used to fetch the data
   *   loading: (true || false),
   *   data: ({} || []), // depending on type of stats
   *   error: 'Error message' // if there were any error while fetching the
   *                          // stats
   * }
   */
  dealStagesPoints: {},
  /**
   * `dealStagesCount` has following structure:
   * {
   *   workspaceId: {
   *     query: {}, // holds the last query which was used to fetch the data
   *     loading: (true || false),
   *     data: ({} || []), // depending on type of stats
   *     error: 'Error message' // if there were any error while fetching the
   *                            // stats
   *   }
   * }
   */
  dealStagesCount: {},
  /**
   * `dealCategoriesCount` has following structure:
   * {
   *   workspaceId: {
   *     query: {}, // holds the last query which was used to fetch the data
   *     loading: (true || false),
   *     data: ({} || []), // depending on type of stats
   *     error: 'Error message' // if there were any error while fetching the
   *                            // stats
   *   }
   * }
   */
  dealCategoriesCount: {},
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
     * GET_DEAL_STATUSES_POINTS related cases
     */
    case types.GET_DEAL_STATUSES_POINTS_REQUEST:
      return Object.assign({}, state, {
        dealStatusesPoints: {
          ...state.dealStatusesPoints,
          error: null,
          loading: true,
          query: payload.query,
        }
      });
    case types.GET_DEAL_STATUSES_POINTS_SUCCESS:
      return Object.assign({}, state, {
        dealStatusesPoints: {
          ...state.dealStatusesPoints,
          cacheTime: (payload.cacheTime || null),
          data: payload.data,
          error: null,
          loading: false,
          query: payload.query,
        }
      });
    case types.GET_DEAL_STATUSES_POINTS_FAILURE:
      return Object.assign({}, state, {
        dealStatusesPoints: {
          ...state.dealStatusesPoints,
          error: payload.message,
          loading: false,
          query: payload.query,
        }
      });

    /**
     * GET_DEAL_STATUSES_COUNT related cases
     */
    case types.GET_DEAL_STATUSES_COUNT_REQUEST:
      return Object.assign({}, state, {
        dealStatusesCount: {
          ...state.dealStatusesCount,
          error: null,
          loading: true,
          query: payload.query,
        }
      });
    case types.GET_DEAL_STATUSES_COUNT_SUCCESS:
      return Object.assign({}, state, {
        dealStatusesCount: {
          ...state.dealStatusesCount,
          cacheTime: (payload.cacheTime || null),
          data: payload.data,
          error: null,
          loading: false,
          query: payload.query,
        }
      });
    case types.GET_DEAL_STATUSES_COUNT_FAILURE:
      return Object.assign({}, state, {
        dealStatusesCount: {
          ...state.dealStatusesCount,
          error: payload.message,
          loading: false,
          query: payload.query,
        }
      });

    /**
     * GET_DEAL_STAGES_POINTS related cases
     */
    case types.GET_DEAL_STAGES_POINTS_REQUEST:
      return Object.assign({}, state, {
        dealStagesPoints: {
          ...state.dealStagesPoints,
          [payload.workspaceId]: {
            ...state.dealStagesPoints[payload.workspaceId],
            error: null,
            loading: true,
            query: payload.query,
          },
        },
      });
    case types.GET_DEAL_STAGES_POINTS_SUCCESS:
      return Object.assign({}, state, {
        dealStagesPoints: {
          ...state.dealStagesPoints,
          [payload.workspaceId]: {
            ...state.dealStagesPoints[payload.workspaceId],
            cacheTime: (payload.cacheTime || null),
            data: payload.data,
            error: null,
            loading: false,
            query: payload.query,
          },
        },
      });
    case types.GET_DEAL_STAGES_POINTS_FAILURE:
      return Object.assign({}, state, {
        dealStagesPoints: {
          ...state.dealStagesPoints,
          [payload.workspaceId]: {
            ...state.dealStagesPoints[payload.workspaceId],
            error: payload.message,
            loading: false,
            query: payload.query,
          },
        },
      });

    /**
     * GET_DEAL_STAGES_COUNT related cases
     */
    case types.GET_DEAL_STAGES_COUNT_REQUEST:
      return Object.assign({}, state, {
        dealStagesCount: {
          ...state.dealStagesCount,
          [payload.workspaceId]: {
            ...state.dealStagesCount[payload.workspaceId],
            error: null,
            loading: true,
            query: payload.query,
          },
        },
      });
    case types.GET_DEAL_STAGES_COUNT_SUCCESS:
      return Object.assign({}, state, {
        dealStagesCount: {
          ...state.dealStagesCount,
          [payload.workspaceId]: {
            ...state.dealStagesCount[payload.workspaceId],
            cacheTime: (payload.cacheTime || null),
            data: payload.data,
            error: null,
            loading: false,
            query: payload.query,
          },
        },
      });
    case types.GET_DEAL_STAGES_COUNT_FAILURE:
      return Object.assign({}, state, {
        dealStagesCount: {
          ...state.dealStagesCount,
          [payload.workspaceId]: {
            ...state.dealStagesCount[payload.workspaceId],
            error: payload.message,
            loading: false,
            query: payload.query,
          },
        },
      });

    /**
     * GET_DEAL_CATEGORIES_COUNT related cases
     */
    case types.GET_DEAL_CATEGORIES_COUNT_REQUEST:
      return Object.assign({}, state, {
        dealCategoriesCount: {
          ...state.dealCategoriesCount,
          error: null,
          loading: true,
          query: payload.query,
        }
      });
    case types.GET_DEAL_CATEGORIES_COUNT_SUCCESS:
      return Object.assign({}, state, {
        dealCategoriesCount: {
          ...state.dealCategoriesCount,
          cacheTime: (payload.cacheTime || null),
          data: payload.data,
          error: null,
          loading: false,
          query: payload.query,
        }
      });
    case types.GET_DEAL_CATEGORIES_COUNT_FAILURE:
      return Object.assign({}, state, {
        dealCategoriesCount: {
          ...state.dealCategoriesCount,
          error: payload.message,
          loading: false,
          query: payload.query,
        }
      });

    /**
     * GET_EMAIL_ACTIONS_COUNT related cases
     */
    case types.GET_EMAIL_ACTIONS_COUNT_REQUEST:
      return Object.assign({}, state, {
        emailActionsCount: {
          ...state.emailActionsCount,
          error: null,
          loading: true,
          query: payload.query,
        }
      });
    case types.GET_EMAIL_ACTIONS_COUNT_SUCCESS:
      return Object.assign({}, state, {
        emailActionsCount: {
          ...state.emailActionsCount,
          cacheTime: (payload.cacheTime || null),
          data: payload.data,
          error: null,
          loading: false,
          query: payload.query,
        }
      });
    case types.GET_EMAIL_ACTIONS_COUNT_FAILURE:
      return Object.assign({}, state, {
        emailActionsCount: {
          ...state.emailActionsCount,
          error: payload.message,
          loading: false,
          query: payload.query,
        }
      });

    /**
     * GET_EMAIL_ACTIONS_POINTS related cases
     */
    case types.GET_EMAIL_ACTIONS_POINTS_REQUEST:
      return Object.assign({}, state, {
        emailActionsPoints: {
          ...state.emailActionsPoints,
          error: null,
          loading: true,
          query: payload.query,
        }
      });
    case types.GET_EMAIL_ACTIONS_POINTS_SUCCESS:
      return Object.assign({}, state, {
        emailActionsPoints: {
          ...state.emailActionsPoints,
          cacheTime: (payload.cacheTime || null),
          data: payload.data,
          error: null,
          loading: false,
          query: payload.query,
        }
      });
    case types.GET_EMAIL_ACTIONS_POINTS_FAILURE:
      return Object.assign({}, state, {
        emailActionsPoints: {
          ...state.emailActionsPoints,
          error: payload.message,
          loading: false,
          query: payload.query,
        }
      });

    /**
     * GET_MEMBER_EMAIL_ACTIONS_COUNT related cases
     */
    case types.GET_MEMBER_EMAIL_ACTIONS_COUNT_REQUEST:
      return Object.assign({}, state, {
        memberEmailActionsCount: {
          ...state.memberEmailActionsCount,
          [payload.investor_id]: (state.memberEmailActionsCount[payload.investor_id] ? {
            ...state.memberEmailActionsCount[payload.investor_id],
            error: null,
            loading: true,
            query: payload.query,
          } : {
            loading: true,
            query: payload.query,
          })
        }
      });
    case types.GET_MEMBER_EMAIL_ACTIONS_COUNT_SUCCESS:
      return Object.assign({}, state, {
        memberEmailActionsCount: {
          ...state.memberEmailActionsCount,
          [payload.investor_id]: (state.memberEmailActionsCount[payload.investor_id] ? {
            ...state.memberEmailActionsCount[payload.investor_id],
            cacheTime: (payload.cacheTime || null),
            data: payload.data,
            error: null,
            loading: false,
            query: payload.query,
          } : {
            data: payload.data,
            query: payload.query,
          })
        }
      });
    case types.GET_MEMBER_EMAIL_ACTIONS_COUNT_FAILURE:
      return Object.assign({}, state, {
        memberEmailActionsCount: {
          ...state.memberEmailActionsCount,
          [payload.investor_id]: (state.memberEmailActionsCount[payload.investor_id] ? {
            ...state.memberEmailActionsCount[payload.investor_id],
            error: payload.message,
            loading: false,
            query: payload.query,
          } : {
            error: payload.message,
            query: payload.query,
          })
        }
      });

    default:
      return state;
  }
};
