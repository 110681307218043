/**
 * Add Custom Field
 * @format
 */

import {
  ADD_CUSTOM_FIELD_REQUEST,
  ADD_CUSTOM_FIELD_SUCCESS,
  ADD_CUSTOM_FIELDS_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case ADD_CUSTOM_FIELD_REQUEST:
      return Object.assign({}, state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case ADD_CUSTOM_FIELD_SUCCESS:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFields: payload,
        customFieldsError: null,
      });

    case ADD_CUSTOM_FIELDS_FAILURE:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: payload,
      });

    default:
      return state;
  }
};
