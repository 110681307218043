/**
 * @format
 */

import * as types from '../actions';
import { assign } from '../../../helpers/object';

export default (state, type, payload) => {
  switch (type) {
    /** DELETE_COMMITMENT */
    case types.DELETE_COMMITMENT_REQUEST: {
      return assign(state, {
        deleteCommitmentLoading: true,
      });
    }
    case types.DELETE_COMMITMENT_FAILURE: {
      return assign(state, {
        deleteCommitmentLoading: false,
      });
    }
    case types.DELETE_COMMITMENT_SUCCESS: {
      const commitmentState = { ...state.commitment };
      delete commitmentState[payload.commitmentId];
      return assign(state, {
        deleteCommitmentLoading: false,
        commitment: commitmentState,
      });
    }

    default:
      return state;
  }
};
