/**
 * @format
 */
import { UPDATE_DEAL_NOTES } from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case UPDATE_DEAL_NOTES:
      if (state.dealNotes[payload.dealId]) {
        let indexOfNpteToBeReplaced = state.dealNotes[payload.dealId].findIndex(
          note => note.note.id === payload.noteId
        );
        let dealNotesArrayClone = [...state.dealNotes[payload.dealId]];
        if (indexOfNpteToBeReplaced > -1) {
          dealNotesArrayClone[indexOfNpteToBeReplaced] = payload.data;
        }
        return Object.assign({}, state, {
          dealNotes: {
            ...state.dealNotes,
            [payload.dealId]: dealNotesArrayClone,
          },
        });
      } else {
        return Object.assign({}, state, {
          ...state,
        });
      }

    default:
      return state;
  }
};
