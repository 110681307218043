/**
 * Loads deal table columns
 *
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import getDealTableColumnsWidths from '../../../helpers/getDealTableColumnsWidths';
import setDealTableColumnsWidths from '../../../helpers/setDealTableColumnsWidths';
import executeInBackground from '../../../helpers/executeInBackground';
import { enqueueNotification } from '../../app';
import {
  LOAD_DEAL_TABLE_COLUMNS_REQUEST,
  LOAD_DEAL_TABLE_COLUMNS_SUCCESS,
  LOAD_DEAL_TABLE_COLUMNS_FAILURE } from '../actions';

const loadDealTableColumns = (workspace_id, shouldForceAPIRequest=false, ignoreCache=false) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!shouldForceAPIRequest) {
        // If column information is present in local storage, fetch it from there
        // `shouldForceAPIRequest` allows for overriding this behaviour
        const columnData = getDealTableColumnsWidths(workspace_id);
        if (columnData && columnData.length > 0) {
          dispatch({
            type: LOAD_DEAL_TABLE_COLUMNS_SUCCESS,
            payload: columnData
          });
          // also contact server to get up to date data, which gets updated in the background
          executeInBackground(
            () => loadDealTableColumns(workspace_id, true)(dispatch).catch(() => {})
          );
          return resolve(columnData);
        }
      }
      dispatch({
        type: LOAD_DEAL_TABLE_COLUMNS_REQUEST
      });
      request.get(`/api/v3/organizations/deal_table_columns`, { workspace_id, ignoreCache }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: LOAD_DEAL_TABLE_COLUMNS_SUCCESS,
            payload: data.data
          });
          setDealTableColumnsWidths(data.data, workspace_id);
          resolve(data);
        } else {
          dispatch({
            type: LOAD_DEAL_TABLE_COLUMNS_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading table columns, please try again.';
        dispatch({
          type: LOAD_DEAL_TABLE_COLUMNS_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

export default loadDealTableColumns;
