/**
 * Returns the portfolio tabs fields
 * @format
 */

import supportsHTML5Storage from './supportsHTML5Storage';

const getPortfolioTabs = () => {
  if (!supportsHTML5Storage()) {
    return null;
  }
  let portfolioTabs = window.localStorage.getItem('portfolioTabs');

  if (portfolioTabs && typeof portfolioTabs === 'string') {
    try {
      portfolioTabs = JSON.parse(portfolioTabs);
      // If expires_on is in the past, expire
      if (new Date(portfolioTabs.expires_on) < new Date()) {
        window.localStorage.removeItem('portfolioTabs');
        return null;
      }
      // Return value if it has not expired
      return portfolioTabs && portfolioTabs.value ? portfolioTabs.value : null;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export default getPortfolioTabs;
