/**
 * @format
 * Holds all the person contacts columns, do not modify keys.
 *
 * To add a new column:
 * - add here
 * - implement appropriate AbcCell container, AbcCell, AbcCell/Viewer, AbcCell/Viewers
 * - add spec in getColumns
 * - add in defaultTableColumnConfig
 * - add transformation in getRowStructure
 * - add support in backend to save new column
 */
const OrgContactTableViewColumn = {
  SERIAL_NO: {
    key: 'serial_no',
    sticky: true,
    name: '',
  },
  NAME: {
    key: 'name',
    sticky: true,
    name: 'Name',
  },
  WEBSITE: {
    key: 'primary_domain',
    name: 'Website',
  },
  SOCIAL_LINKS: {
    key: 'social_links',
    name: 'Social links',
  },
  TAGS: {
    key: 'tags',
    name: 'Tags',
  },
};

export default OrgContactTableViewColumn;
