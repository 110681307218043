/**
 * @format
 */
export const KEY_TYPES = {
  ID: 'id',
  DEAL_STAGE_ID: 'deal_stage_id',
  WORKSPACE_ID: 'workspace_id',
  IS_OPEN: 'is_open',
  ASSIGNEES: 'assignees',
  POSSIBLE_MISSING: 'possible_missing',
};

export const ACTION_TYPES = {
  INSERT: 'insert',
  UPDATE: 'update',
  DELETE: 'delete',
};
