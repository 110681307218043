/**
 * System reducers
 *
 * @format
 */
import * as types from './actions';

const initialState = {
  inviteOrganizationLoading: false,
  inviteOrganizationError: null,

  dealImportGetSignedURLLoading: false,
  dealImportGetSignedURLError: null,

  dealImportS3UploadLoading: false,
  dealImportS3UploadError: null,

  dealImportEnqueueJobLoading: false,
  dealImportEnqueueJobError: null,

  /**
   * Holds deal import job details, keys are the deal import job id and values
   * are the import job properties and subresources.
   */
  dealImportJobs: {},

  dealImportJobsOptions: {
    withOwner: true,
    withOrganization: true,
    page: 1,
    limit: 10,
  },
  dealImportJobsMore: false,
  dealImportJobsLoading: false,
  dealImportJobsError: null,

  downloadCSVSampleLoading: false,
  downloadCSVSampleError: null,
  sampleCsvUrl: null,

  faqWithAnswers: [],
  faqLoading: false,
  faqError: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * Invite organization reducers
     */
    case types.INVITE_ORGANIZATION_REQUEST:
      return Object.assign({}, state, {
        inviteOrganizationLoading: true,
        inviteOrganizationError: null,
      });
    case types.INVITE_ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        inviteOrganizationLoading: false,
        inviteOrganizationError: null,
      });
    case types.INVITE_ORGANIZATION_FAILURE:
      return Object.assign({}, state, {
        inviteOrganizationLoading: false,
        inviteOrganizationError: payload,
      });

    /**
     * List deal import jobs
     */
    case types.LIST_DEAL_IMPORT_REQUEST:
      return Object.assign({}, state, {
        dealImportJobsLoading: true,
        dealImportJobsError: null,
      });
    case types.LIST_DEAL_IMPORT_SUCCESS:
      return Object.assign({}, state, {
        dealImportJobs: {
          ...state.dealImportJobs,
          ...payload.data.reduce((acc, dealImportJob) => {
            acc[dealImportJob.id] = dealImportJob;
            return acc;
          }, {}),
        },
        dealImportJobsLoading: false,
        dealImportJobsError: null,
        dealImportJobsOptions: payload.options,
        dealImportJobsMore:
          payload.data.length >= payload.options.limit ? true : false,
      });
    case types.LIST_DEAL_IMPORT_FAILURE:
      return Object.assign({}, state, {
        dealImportJobsLoading: false,
        dealImportJobsError: payload,
      });
    case types.LIST_DEAL_IMPORT_CLEAR:
      return Object.assign({}, state, {
        dealImportJobs: {},
      });

    /**
     * Get signed url to upload csv for import - reducers
     */
    case types.DEAL_IMPORT_GET_SIGNED_URL_REQUEST:
      return Object.assign({}, state, {
        dealImportGetSignedURLLoading: true,
        dealImportGetSignedURLError: null,
      });
    case types.DEAL_IMPORT_GET_SIGNED_URL_SUCCESS:
      return Object.assign({}, state, {
        dealImportGetSignedURLLoading: false,
        dealImportGetSignedURLError: null,
      });
    case types.DEAL_IMPORT_GET_SIGNED_URL_FAILURE:
      return Object.assign({}, state, {
        dealImportGetSignedURLLoading: false,
        dealImportGetSignedURLError: payload,
      });

    /**
     * Uploading csv to S3 for import - reducers
     */
    case types.DEAL_IMPORT_S3_UPLOAD_REQUEST:
      return Object.assign({}, state, {
        dealImportS3UploadLoading: true,
        dealImportS3UploadError: null,
      });
    case types.DEAL_IMPORT_S3_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        dealImportS3UploadLoading: false,
        dealImportS3UploadError: null,
      });
    case types.DEAL_IMPORT_S3_UPLOAD_FAILURE:
      return Object.assign({}, state, {
        dealImportS3UploadLoading: false,
        dealImportS3UploadError: payload,
      });

    /**
     * Enqueue import job for processing - reducers
     */
    case types.DEAL_IMPORT_ENQUEUE_JOB_REQUEST:
      return Object.assign({}, state, {
        dealImportEnqueueJobLoading: true,
        dealImportEnqueueJobError: null,
      });
    case types.DEAL_IMPORT_ENQUEUE_JOB_SUCCESS:
      return Object.assign({}, state, {
        dealImportEnqueueJobLoading: false,
        dealImportEnqueueJobError: null,
      });
    case types.DEAL_IMPORT_ENQUEUE_JOB_FAILURE:
      return Object.assign({}, state, {
        dealImportEnqueueJobLoading: false,
        dealImportEnqueueJobError: payload,
      });

    /**
     * Downloading sample csv template
     */
    case types.DOWNLOAD_CSV_SAMPLE_REQUEST:
      return Object.assign({}, state, {
        downloadCSVSampleLoading: true,
        downloadCSVSampleError: null,
      });
    case types.DOWNLOAD_CSV_SAMPLE_SUCCESS:
      return Object.assign({}, state, {
        sampleCsvUrl: payload,
        downloadCSVSampleLoading: false,
        downloadCSVSampleError: null,
      });
    case types.DOWNLOAD_CSV_SAMPLE_FAILURE:
      return Object.assign({}, state, {
        downloadCSVSampleLoading: false,
        downloadCSVSampleError: payload,
      });

    case types.LOAD_TAGHASH_FAQ_REQUEST:
      return Object.assign({}, state, {
        faqLoading: true,
        faqError: null,
      });

    case types.LOAD_TAGHASH_FAQ_SUCCESS:
      return Object.assign({}, state, {
        faqLoading: false,
        faqWithAnswers: payload,
        faqError: null,
      });

    case types.LOAD_TAGHASH_FAQ_FAILURE:
      return Object.assign({}, state, {
        faqLoading: false,
        faqError: payload,
      });

    default:
      return state;
  }
};
