/** @format */
import property from 'lodash.property';
import uniqBy from 'lodash.uniqby';
import * as types from './actions';
import { assign } from '../../helpers/object';
import find from 'lodash.find';
import { assign as lodashAssign } from 'lodash';

// prettier-ignore
const initial = {
  // keyed by portfolio company's unique identifier
  transactionsLoading: { /* store of transactions of various portfolio companies */},
  transactionsFilters: { /* store of transactions of various portfolio companies */},
  transactions: { /* store of transactions of various portfolio companies */},

  // keyed by portfolio company's unique identifier
  valuationsLoading: { /* store of valuation events of various portfolio companies */},
  valuationsFilters: { /* store of valuation events of various portfolio companies */},
  valuations: { /* store of valuation events of various portfolio companies */},

  // keyed by portfolio company's unique identifier
  fundingRounds: { /* store of funding rounds of various portfolio companies */ },

  // Set to true when any valuation record is being deleted
  deleteValuationLoading: false,

  // Bulk upload funding rounds
  isBulkUploadFundingRounds: false,
  isBulkUploadDirectInvestments: false,
  isBulkUploadInDirectInvestments: false,
  isBulkUploadValuations: false
};

export default function(state = initial, { type, payload }) {
  const id = payload ? payload.id : null;

  switch (type) {
    case types.LOAD_PORTFOLIO_TRANSACTIONS_REQUEST: {
      return assign(state, {
        transactionsLoading: assign(state.transactionsLoading, {
          [id]: true,
        }),
        transactionsFilters: assign(state.transactionsFilters, {
          [id]: payload.filters,
        }),
      });
    }
    case types.LOAD_PORTFOLIO_TRANSACTIONS_SUCCESS: {
      // To prevent race conditions in updating state
      if (
        JSON.stringify(payload.filters) !==
        JSON.stringify(property(`transactionsFilters.${id}`)(state))
      ) {
        return state;
      }
      return assign(state, {
        transactionsLoading: assign(state.transactions, {
          [id]: false,
        }),
        transactions: assign(state.transactions, {
          [id]: payload.data,
        }),
      });
    }
    case types.LOAD_PORTFOLIO_TRANSACTIONS_FAILURE: {
      return assign(state, {
        transactionsLoading: assign(state.transactions, {
          [id]: false,
        }),
      });
    }

    case types.ADD_PORTFOLIO_TRANSACTION: {
      const transactions = property(`transactions.${id}`)(state) || [];

      // TODO(@riyaz): insert the transaction object at the correct place
      return assign(state, {
        transactions: assign(state.transactions, {
          [id]: [payload.data, ...transactions],
        }),
      });
    }

    case types.UPDATE_PORTFOLIO_TRANSACTION: {
      const transactions = property(`transactions.${id}`)(state) || [];
      const index = transactions.findIndex(txn => txn.id === payload.txn);
      transactions[index] = payload.data;

      return assign(state, {
        transactions: assign(state.transactions, {
          [id]: transactions,
        }),
      });
    }

    case types.DELETE_PORTFOLIO_TRANSACTION: {
      return assign(state, {
        transactions: assign(state.transactions, {
          [id]: (state.transactions[id] || []).filter(
            txn => txn.id !== payload.txn
          ),
        }),
      });
    }

    case types.LOAD_PORTFOLIO_VALUATIONS_REQUEST: {
      return assign(state, {
        valuationsLoading: assign(state.valuationsLoading, {
          [id]: true,
        }),
        valuationsFilters: assign(state.valuationsFilters, {
          [id]: payload.filters,
        }),
      });
    }
    case types.LOAD_PORTFOLIO_VALUATIONS_SUCCESS: {
      // To prevent race conditions in updating state
      if (
        JSON.stringify(payload.filters) !==
        JSON.stringify(property(`valuationsFilters.${id}`)(state))
      ) {
        return state;
      }
      return assign(state, {
        valuationsLoading: assign(state.valuationsLoading, {
          [id]: false,
        }),
        valuations: assign(state.valuations, {
          [id]: payload.data,
        }),
      });
    }
    case types.LOAD_PORTFOLIO_VALUATIONS_FAILURE: {
      return assign(state, {
        valuationsLoading: assign(state.valuationsLoading, {
          [id]: false,
        }),
      });
    }

    case types.ADD_PORTFOLIO_VALUATION: {
      const valuations = property(`valuations.${id}`)(state) || [];

      // TODO(@riyaz): insert the valuation object at the correct place
      return assign(state, {
        valuations: assign(state.valuations, {
          // `uniqBy` is so that, if valuation records for different methods
          // are entered separately, instead of showing duplicate records, this
          // will show a single record
          [id]: uniqBy([payload.data, ...valuations], val => val.id),
        }),
      });
    }

    case types.UPDATE_PORTFOLIO_VALUATION: {
      const events = property(`valuations.${id}`)(state) || [];
      const index = events.findIndex(evt => evt.id === payload.val);
      events[index] = payload.data;

      return assign(state, {
        events: assign(state.events, {
          [id]: events,
        }),
      });
    }

    case types.DELETE_PORTFOLIO_VALUATION_REQUEST: {
      return assign(state, {
        deleteValuationLoading: true,
      });
    }
    case types.DELETE_PORTFOLIO_VALUATION_SUCCESS: {
      // prettier-ignore
      const currValuations = property(`valuations.${payload.portcoId}`)(state) || [];
      const newValuations = currValuations.filter(
        v => v.id !== payload.valuationId
      );
      return assign(state, {
        deleteValuationLoading: false,
        valuations: assign(state.valuations, {
          // filter out the valuation from the list
          [payload.portcoId]: newValuations,
        }),
      });
    }
    case types.DELETE_PORTFOLIO_VALUATION_FAILURE: {
      return assign(state, {
        deleteValuationLoading: false,
      });
    }

    case types.LOAD_PORTFOLIO_FUNDING_ROUNDS: {
      return assign(state, {
        fundingRounds: assign(state.fundingRounds, {
          [id]: payload.data,
        }),
      });
    }

    case types.ADD_PORTFOLIO_FUNDING_ROUND: {
      const fundingRounds = property(`fundingRounds.${id}`)(state) || [];
      // Update or add the funding round
      // Step 1. Check if the funding round exist
      const round = find(fundingRounds, { id: payload.data.id });

      if (round) {
        // Update the round details
        lodashAssign(round, payload.data);
        return assign(state, {
          fundingRounds: assign(state.fundingRounds, {
            [id]: fundingRounds,
          }),
        });
      } else {
        // Add the new funding round
        return assign(state, {
          fundingRounds: assign(state.fundingRounds, {
            [id]: [payload.data, ...fundingRounds],
          }),
        });
      }
    }

    case types.DELETE_PORTFOLIO_FUNDING_ROUND: {
      const current = property(`fundingRounds.${payload.portcoId}`)(state) || []; // prettier-ignore
      const updated = current.filter(v => v.id !== payload.roundId);

      return assign(state, {
        fundingRounds: assign(state.fundingRounds, {
          [payload.portcoId]: updated,
        }),
      });
    }

    case types.ADD_FUNDING_ROUND_IN_BULK_REQUEST: {
      return assign(state, { isBulkUploadFundingRounds: true });
    }

    case types.ADD_FUNDING_ROUND_IN_BULK_SUCCESS:
    case types.ADD_FUNDING_ROUND_IN_BULK_FAILURE: {
      return assign(state, { isBulkUploadFundingRounds: false });
    }

    case types.ADD_DIRECT_INVESTMENT_IN_BULK_REQUEST: {
      return assign(state, { isBulkUploadDirectInvestments: true });
    }

    case types.ADD_DIRECT_INVESTMENT_IN_BULK_SUCCESS:
    case types.ADD_DIRECT_INVESTMENT_IN_BULK_FAILURE: {
      return assign(state, { isBulkUploadDirectInvestments: false });
    }

    case types.ADD_IN_DIRECT_INVESTMENT_IN_BULK_REQUEST: {
      return assign(state, { isBulkUploadInDirectInvestments: true });
    }

    case types.ADD_IN_DIRECT_INVESTMENT_IN_BULK_SUCCESS:
    case types.ADD_IN_DIRECT_INVESTMENT_IN_BULK_FAILURE: {
      return assign(state, { isBulkUploadInDirectInvestments: false });
    }

    case types.ADD_VALUATION_IN_BULK_REQUEST: {
      return assign(state, { isBulkUploadValuations: true });
    }

    case types.ADD_VALUATION_IN_BULK_SUCCESS:
    case types.ADD_VALUATION_IN_BULK_FAILURE: {
      return assign(state, { isBulkUploadValuations: false });
    }

    case types.CLEAR: {
      return assign(state, initial);
    }

    default: {
      return state;
    }
  }
}
