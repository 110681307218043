import * as types from './actions';

const initialState = {
  // Indicates if the invite request is loading
  requestInviteLoading: false,
  requestInviteError: null,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
    * Request invite reducer
    */
    case types.REQUEST_INVITE_REQUEST:
      return Object.assign({}, state, {
        requestInviteLoading: true,
        requestInviteError: null
      });
    case types.REQUEST_INVITE_SUCCESS:
      return Object.assign({}, state, {
        requestInviteLoading: false,
        requestInviteError: null
      });
    case types.REQUEST_INVITE_FAILURE:
      return Object.assign({}, state, {
        requestInviteLoading: false,
        requestInviteError: payload.message
      });

    /**
    * Default
    */
    default:
      return state;
  }
};
