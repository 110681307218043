/** @format */
import axios from 'axios';
import * as types from './actions';
import reducers from './reducers';
import request, { ApplicationError } from '../../helpers/request';
import { enqueueNotification } from '../app';
import property from 'lodash.property';

export default reducers;

export const createFundDocuments = (fundId, files, options) => {
  return async dispatch => {
    try {
      if (!fundId || !files || !files.length) {
        throw new ApplicationError('Please select a file to upload');
      }
      dispatch({
        type: types.CREATE_FUND_DOCUMENT_REQUEST,
        payload: {
          fundId,
        },
      });
      const response = await request.post(
        `/api/funds-documents/v1/funds/${fundId}/documents`,
        {
          files,
          // optional. associates the fund document to an LP
          limited_partner_id: property('limited_partner_id')(options),
          // optional. also creates a v1 attachment record for this document
          shouldCreateV1Attachment: options.shouldCreateV1Attachment,
        },
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.CREATE_FUND_DOCUMENT_SUCCESS,
        payload: {
          fundId,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      let message = err.isApplicationError
        ? err.message
        : 'Something went wrong while creating fund document. Please try again.';

      if (err?.response?.status === 429) {
        message = `Too fast! Try again in a couple of seconds.`;
      }
      dispatch({
        type: types.CREATE_FUND_DOCUMENT_FAILURE,
        payload: {
          fundId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

/**
 * List fund documents
 *
 * @param {String} fundId Fund's id
 * @param {Object} options Options object
 * @prop {String} [options.q] Prefix of filename
 * @prop {String} [options.limited_partner_id] Limited partner to filter by
 * @prop {String[]} [options.tags] Tags to limit to
 * @prop {String} [options.sortBy] Sorting criteria (created_at)
 * @prop {String} [options.sortOrder] Sorting order (asc, desc)
 * @prop {String} [options.limit] Limit
 * @prop {String} [options.page] page
 * @returns
 */
export const listFundDocuments = (fundId, options = {}) => {
  return async dispatch => {
    try {
      dispatch({
        type: types.LIST_FUND_DOCUMENTS_REQUEST,
        payload: {
          fundId,
          options,
        },
      });
      const response = await request.get(
        `/api/funds-documents/v1/funds/${fundId}/documents`,
        options,
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.LIST_FUND_DOCUMENTS_SUCCESS,
        payload: {
          fundId,
          options,
          data: data.data,
          metadata: data.metadata,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while listing fund documents. Please try again.';
      dispatch({
        type: types.LIST_FUND_DOCUMENTS_FAILURE,
        payload: {
          fundId,
          options,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const getFundDocumentSignedUrl = (fundId, documentId, options = {}) => {
  return async dispatch => {
    try {
      dispatch({
        type: types.GET_FUND_DOCUMENT_SIGNEDURL_REQUEST,
        payload: {
          fundId,
          documentId,
        },
      });
      const response = await request.get(
        `/api/funds-documents/v1/funds/${fundId}/documents/${documentId}/url`,
        options,
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.GET_FUND_DOCUMENT_SIGNEDURL_SUCCESS,
        payload: {
          fundId,
          documentId,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while loading document. Please try again.';
      dispatch({
        type: types.GET_FUND_DOCUMENT_SIGNEDURL_FAILURE,
        payload: {
          fundId,
          documentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const addTag = (fundId, documentId, tag) => {
  return async dispatch => {
    try {
      dispatch({
        type: types.ADD_TAG_TO_FUND_DOCUMENT_REQUEST,
        payload: {
          fundId,
          documentId,
          tag,
        },
      });
      const response = await request.post(
        `/api/funds-documents/v1/funds/${fundId}/documents/${documentId}/tag`,
        { tag },
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.ADD_TAG_TO_FUND_DOCUMENT_SUCCESS,
        payload: {
          fundId,
          documentId,
          tag,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while adding tag to document. Please try again.';
      dispatch({
        type: types.ADD_TAG_TO_FUND_DOCUMENT_FAILURE,
        payload: {
          fundId,
          documentId,
          tag,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const removeTag = (fundId, documentId, tag) => {
  return async dispatch => {
    try {
      dispatch({
        type: types.REMOVE_TAG_FROM_FUND_DOCUMENT_REQUEST,
        payload: {
          fundId,
          documentId,
          tag,
        },
      });
      const response = await request.del(
        `/api/funds-documents/v1/funds/${fundId}/documents/${documentId}/tag`,
        { tag },
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.REMOVE_TAG_FROM_FUND_DOCUMENT_SUCCESS,
        payload: {
          fundId,
          documentId,
          tag,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while removing tag from document. Please try again.';
      dispatch({
        type: types.REMOVE_TAG_FROM_FUND_DOCUMENT_FAILURE,
        payload: {
          fundId,
          documentId,
          tag,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const deleteDocument = (fundId, documentId) => {
  return async dispatch => {
    try {
      dispatch({
        type: types.DELETE_FUND_DOCUMENT_REQUEST,
        payload: {
          fundId,
          documentId,
        },
      });
      const response = await request.del(
        `/api/funds-documents/v1/funds/${fundId}/documents/${documentId}`,
        {},
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.DELETE_FUND_DOCUMENT_SUCCESS,
        payload: {
          fundId,
          documentId,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while deleting document. Please try again.';
      dispatch({
        type: types.DELETE_FUND_DOCUMENT_FAILURE,
        payload: {
          fundId,
          documentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const hideOrUnhideFundDocument = (fundId, documentId, isHidden) => {
  return async dispatch => {
    try {
      dispatch({
        type: types.HIDE_OR_UNHIDE_FUND_DOCUMENT_REQUEST,
        payload: {
          fundId,
          documentId,
        },
      });
      const response = await request.put(
        `/api/funds-documents/v1/funds/${fundId}/documents/${documentId}/hide_unhide`,
        { is_hidden: isHidden },
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.HIDE_OR_UNHIDE_FUND_DOCUMENT_SUCCESS,
        payload: {
          fundId,
          documentId,
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while deleting document. Please try again.';
      dispatch({
        type: types.HIDE_OR_UNHIDE_FUND_DOCUMENT_FAILURE,
        payload: {
          fundId,
          documentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

export const listTags = () => {
  return async dispatch => {
    try {
      dispatch({
        type: types.LIST_FUND_DOCUMENT_TAGS_REQUEST,
        payload: {},
      });
      const response = await request.get(
        `/api/funds-documents/v1/tags`,
        {},
        dispatch
      );
      const data = response.data;
      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }
      dispatch({
        type: types.LIST_FUND_DOCUMENT_TAGS_SUCCESS,
        payload: {
          data: data.data,
        },
      });
      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while listing tags. Please try again.';
      dispatch({
        type: types.LIST_FUND_DOCUMENT_TAGS_FAILURE,
        payload: {
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
  };
};

// https://medium.com/@kevinwu/client-side-file-upload-to-s3-using-axios-c9363ec7b530
export const fileUploadToS3 = (documentId, signedURL, file) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.FILE_UPLOAD_S3_REQUEST,
        payload: { documentId },
      });
      const attachmentContentType =
        file?.mime_type || file?.attachment?.mime_type;
      const fileContentType = file.ref?.type;
      if (
        attachmentContentType &&
        fileContentType &&
        attachmentContentType !== fileContentType
      ) {
        const message =
          'Something went wrong while uploading document, please try again.';
        dispatch({
          type: types.FILE_UPLOAD_S3_FAILURE,
          payload: {
            documentId,
            message,
          },
        });
        dispatch(enqueueNotification(message));
        return Promise.reject(new Error(message));
      }
      // Note the difference here
      axios
        .put(signedURL, file.ref ? file.ref : file, {
          headers: {
            'Content-Type': attachmentContentType
              ? attachmentContentType
              : file.ref
              ? file.ref.type
              : file.mime_type || file.type,
          },
        })
        .then(({ data }) => {
          dispatch({
            type: types.FILE_UPLOAD_S3_SUCCESS,
            payload: { documentId, data },
          });
          resolve(data);
        })
        .catch(err => {
          const message =
            'Something went wrong while uploading document, please try again.';
          dispatch({
            type: types.FILE_UPLOAD_S3_FAILURE,
            payload: {
              documentId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const fileDownloadFromS3 = (documentId, signedURL, options) => {
  return async dispatch => {
    dispatch({
      type: types.FILE_DOWNLOAD_S3_REQUEST,
      payload: { documentId },
    });
    let data;
    try {
      // Note the difference here
      const response = await axios.get(signedURL, options);
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while downloading document, please try again.';
      dispatch({
        type: types.FILE_DOWNLOAD_S3_FAILURE,
        payload: {
          documentId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    dispatch({
      type: types.FILE_DOWNLOAD_S3_SUCCESS,
      payload: { documentId, data },
    });
    return data;
  };
};

// Clears redux state
export const clearFundDocumentsState = () => dispatch =>
  dispatch({ type: types.CLEAR });
