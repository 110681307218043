
import { INCREMENT_DEAL_NOTE_COUNT } from '../actions';

export default (dealId) => {
  return dispatch => {
    dispatch({
      type: INCREMENT_DEAL_NOTE_COUNT,
      payload: {
        dealId
      }
    });
  };
};
