/**
 * DELETE_SIGN_OFF_ITEM reducer
 * @format
 */
import {
  DELETE_SIGN_OFF_ITEM_REQUEST,
  DELETE_SIGN_OFF_ITEM_SUCCESS,
  DELETE_SIGN_OFF_ITEM_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case DELETE_SIGN_OFF_ITEM_REQUEST:
      return Object.assign({}, state, {
        updateSignOffItemLoading: {
          ...state.updateSignOffItemLoading,
          [payload.signOffId]: true,
        },
        updateSignOffItemError: {
          ...state.updateSignOffItemError,
          [payload.signOffId]: null,
        },
      });

    case DELETE_SIGN_OFF_ITEM_SUCCESS:
      return Object.assign({}, state, {
        updateSignOffItemLoading: {
          ...state.updateSignOffItemLoading,
          [payload.signOffId]: false,
        },
        updateSignOffItemError: {
          ...state.updateSignOffItemError,
          [payload.signOffId]: null,
        },
        signOffs: {
          ...state.signOffs,
          [payload.signOffId]: null,
        },
        // remove the sign off item from the association hashmap
        signOffGroupsItemsHashMap: {
          ...state.signOffGroupsItemsHashMap,
          [payload.signOffGroupId]: state.signOffGroupsItemsHashMap[
            payload.signOffGroupId
          ]
            ? state.signOffGroupsItemsHashMap[payload.signOffGroupId].filter(
                sign_off_id => sign_off_id !== payload.signOffId
              )
            : [],
        },
      });
    case DELETE_SIGN_OFF_ITEM_FAILURE:
      return Object.assign({}, state, {
        updateSignOffItemLoading: {
          ...state.updateSignOffItemLoading,
          [payload.signOffId]: false,
        },
        updateSignOffItemError: {
          ...state.updateSignOffItemError,
          [payload.signOffId]: payload.message,
        },
      });

    default:
      return state;
  }
};
