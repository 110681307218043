/**
 * Reducers for ageing report
 * Stores rows and columns required for ageing report
 * @format
 */
import * as types from './actions';
const initialState = {
  rows: {},
  columns: {},
  ageingReportLoading: {},
  ageingReportError: {},

  //Workspaces tracking(which workspace to switch to etc)
  workspaces: [],
  activeWorkspacePositionId: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.FETCH_AGEING_REPORT_REQUEST:
      return Object.assign({}, state, {
        ageingReportLoading: {
          [payload.workspace_id]: true,
        },
        ageingReportError: {
          [payload.workspace_id]: null,
        },
      });

    case types.FETCH_AGEING_REPORT_SUCCESS:
      return Object.assign({}, state, {
        rows: {
          [payload.workspace_id]: [
            ...(state.rows[payload.workspace_id] || []),
            ...payload.data.rows,
          ],
        },

        ageingReportLoading: {
          [payload.workspace_id]: null,
        },
        ageingReportError: {
          [payload.workspace_id]: null,
        },
      });

    case types.FETCH_AGEING_REPORT_FAILURE:
      return Object.assign({}, state, {
        ageingReportError: payload.message,
      });

    case types.FETCH_WORKSPACES:
      return Object.assign({}, state, {
        workspaces: payload.data,
      });

    case types.SWITCH_WORKSPACE:
      return Object.assign({}, state, {
        activeWorkspacePositionId: payload.positionId,
      });

    default:
      return state;
  }
};
