/**
 * @format
 */
import {
  UPDATE_PINNED_STATUS_REQUEST,
  UPDATE_PINNED_STATUS_SUCCESS,
  UPDATE_PINNED_STATUS_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    /**
     * Pinned Note Status reducers
     */
    case UPDATE_PINNED_STATUS_REQUEST:
      return Object.assign({}, state, {
        pinnedNoteLoading: true,
        pinnedNoteError: null,
      });
    case UPDATE_PINNED_STATUS_SUCCESS: {
      const isNotePinned = payload.data.is_pinned;
      // currently pinned note
      let currentlyPinnedNote = state.pinnedNote;
      let isCurrentlyPinnedNoteUnpinned = false;
      // The newly pinned note is different from currently pinned note
      if (
        currentlyPinnedNote &&
        isNotePinned &&
        currentlyPinnedNote.id !== payload.data.id &&
        currentlyPinnedNote.pipeline_id === payload.data.pipeline_id
      ) {
        // update is_pinned to false since it's no longer pinned
        currentlyPinnedNote = {
          ...currentlyPinnedNote,
          is_pinned: false,
        };
        isCurrentlyPinnedNoteUnpinned = true;
      }
      const newState = {
        pinnedNote: isNotePinned ? payload.data : null,
        dealNotes: {
          ...state.dealNotes,
          [payload.dealId]: [
            // Whenever any note is pinned or not pinned remove that note from
            // the dealNotes, and update it with new one
            ...(state.dealNotes[payload.dealId]
              ? state.dealNotes[payload.dealId].filter(
                  note =>
                    note.note.id !== payload.data.note.id &&
                    (isCurrentlyPinnedNoteUnpinned
                      ? // This skips the currently pinned note as well from this
                        // subset of the list
                        note.id !== currentlyPinnedNote.id
                      : // If currently pinned note is not being unpinned,
                        // this condition is ignored
                        true)
                )
              : []),
            // if currently pinned notes is being updated, add it at the end
            ...(isCurrentlyPinnedNoteUnpinned ? [currentlyPinnedNote] : []),
            // Add the updated note at the end
            payload.data,
          ],
        },
        note: {
          ...state.note,
          [payload.noteId]: {
            ...payload.data,
          },
        },
        pinnedNoteLoading: false,
        pinnedNoteError: null,
      };
      if (isCurrentlyPinnedNoteUnpinned) {
        // note state should also be updated
        newState.note[currentlyPinnedNote.note_id] = currentlyPinnedNote;
      }
      newState.dealNotes[payload.dealId] = newState.dealNotes[
        payload.dealId
      ].sort((a, b) => {
        const aLastActiveAt = new Date(a.last_active_at);
        const bLastActiveAt = new Date(b.last_active_at);
        // sort by last_active_at in descending order
        if (aLastActiveAt > bLastActiveAt) {
          return -1;
        } else if (aLastActiveAt < bLastActiveAt) {
          return 1;
        } else {
          return 0;
        }
      });
      return Object.assign({}, state, newState);
    }
    case UPDATE_PINNED_STATUS_FAILURE:
      return Object.assign({}, state, {
        pinnedNoteLoading: false,
        pinnedNoteError: payload.message,
      });

    default:
      return state;
  }
};
