import {
  REMOVE_ASSIGNEE_REQUEST,
  REMOVE_ASSIGNEE_SUCCESS,
  REMOVE_ASSIGNEE_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case REMOVE_ASSIGNEE_REQUEST:
      return Object.assign({}, state, {
        removeAssigneeLoading: {
          ...state.removeAssigneeLoading,
          [payload.dealId]: true
        },
        removeAssigneeError: {
          ...state.removeAssigneeError,
          [payload.dealId]: null
        }
      });

    case REMOVE_ASSIGNEE_SUCCESS:
      const copyOfRemoveAssigneeStates = Object.assign({}, state, {
        removeAssigneeLoading: {
          ...state.removeAssigneeLoading,
        },
        removeAssigneeError: {
          ...state.removeAssigneeError,
        },
      });
      delete copyOfRemoveAssigneeStates.removeAssigneeLoading[payload.dealId];
      delete copyOfRemoveAssigneeStates.removeAssigneeError[payload.dealId];
      return copyOfRemoveAssigneeStates;

    case REMOVE_ASSIGNEE_FAILURE:
      return Object.assign({}, state, {
        removeAssigneeLoading: {
          ...state.removeAssigneeLoading,
          [payload.dealId]: false
        },
        removeAssigneeError: {
          ...state.removeAssigneeError,
          [payload.dealId]: payload.message
        },
      });

    default:
      return state;
  }
};
