/**
 * Fetches resource counts associated with the deal
 *
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_DEALS_RESOURCE_COUNT_REQUEST,
  LOAD_DEALS_RESOURCE_COUNT_SUCCESS,
  LOAD_DEALS_RESOURCE_COUNT_FAILURE,
} from '../actions';

export default (dealIds, options) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LOAD_DEALS_RESOURCE_COUNT_REQUEST,
      });
      request
        .get(
          `/api/v5/deals/resources/counts`,
          { dealIds: dealIds, ...options },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: LOAD_DEALS_RESOURCE_COUNT_SUCCESS,
              payload: {
                data: data.data,
              },
            });
            resolve(data);
          } else {
            dispatch({
              type: LOAD_DEALS_RESOURCE_COUNT_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading deal resource counts, please try again.';
          dispatch({
            type: LOAD_DEALS_RESOURCE_COUNT_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
