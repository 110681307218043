/**
 * fetches dashboard by id
 *
 * @format
 */

import request from '../../../helpers/request';
import isEmptyString from '../../../helpers/string/isEmptyString';
import { enqueueNotification } from '../../app';
import {
  GET_PIPELINE_DASHBOARD_FAILURE,
  GET_PIPELINE_DASHBOARD_REQUEST,
  GET_PIPELINE_DASHBOARD_SUCCESS,
} from '../actions';

export default (dashboardId, options) => {
  return async dispatch => {
    if (isEmptyString(dashboardId)) {
      throw new Error('Missing dashboardId');
    }

    dispatch({
      type: GET_PIPELINE_DASHBOARD_REQUEST,
      payload: {
        dashboardId,
        options,
      },
    });

    let data;
    try {
      const response = await request.get(
        `/api/pipeline/dealflowbi/v1/dashboard/${dashboardId}?withComponents=true`,
        options,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading the dashboard and its components, please try again';
      dispatch({
        type: GET_PIPELINE_DASHBOARD_FAILURE,
        payload: {
          dashboardId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }

    if (data.success) {
      dispatch({
        type: GET_PIPELINE_DASHBOARD_SUCCESS,
        payload: {
          dashboardId,
          data: data.data,
          options,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: GET_PIPELINE_DASHBOARD_FAILURE,
        payload: {
          dashboardId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
