/**
 * Sets the deal overview fields
 * @format
 */
import supportsHTML5Storage from './supportsHTML5Storage';
import Time from '../components/Time';

const setDealOverviewFields = (data, workspace_id) => {
  if (!supportsHTML5Storage()) {
    return;
  }
  let dealOverviewFields = window.localStorage.getItem('dealOverviewFields');

  if (dealOverviewFields && typeof dealOverviewFields === 'string') {
    try {
      dealOverviewFields = JSON.parse(dealOverviewFields);
      // If expires_on is in the past, expire
      if (new Date(dealOverviewFields.expires_on) < new Date()) {
        window.localStorage.removeItem('dealOverviewFields');
        dealOverviewFields = { value: {} };
      }
    } catch (e) {
      return null;
    }
  } else {
    dealOverviewFields = { value: {} };
  }
  const payload = {
    value: {
      ...dealOverviewFields.value,
      [workspace_id]: data,
    }, // array of columns and their configs
    expires_on: Time.add(new Date(), 15, 'minute'), // when we consider this key expired
  };
  window.localStorage.removeItem('dealOverviewFields');
  window.localStorage.setItem('dealOverviewFields', JSON.stringify(payload));
};

export default setDealOverviewFields;
