/** @format */
import * as types from './actions';
import { assign } from '../../helpers/object';

const initialState = {
  /**
   * Holds state related to fund general partners loading
   */
  generalPartnersLoading: false,
  /**
   * Holds state related to fund general partners - error (it could be for fetching, creating or deleting)
   */
  generalPartnersError: null,

  /**
   * Stores fund general partners
   */
  generalPartners: [],
  /**
   * Stores fund contact persons
   */
  fundContactPersons: [],
  fundContactPersonsLoading: false,

  addOrUpdateLoadingOfFundPersonOfFundPerson: false, //this variable shows loading state for general partner and fund contact person creation or updation
  removeGeneralPartnerLoading: false,
  removeFundContactPersonLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CREATE_FUND_GENERAL_PARTNER_REQUEST: {
      return assign({}, state, {
        addOrUpdateLoadingOfFundPerson: true,
      });
    }
    case types.CREATE_FUND_GENERAL_PARTNER_SUCCESS: {
      return assign({}, state, {
        addOrUpdateLoadingOfFundPerson: false,
        generalPartners: [...state.generalPartners, payload.generalPartner],
      });
    }
    case types.CREATE_FUND_GENERAL_PARTNER_FAILURE: {
      return assign({}, state, {
        addOrUpdateLoadingOfFundPerson: false,
      });
    }

    case types.LIST_FUND_GENERAL_PARTNERS_REQUEST: {
      return assign({}, state, {
        generalPartnersLoading: true,
      });
    }
    case types.LIST_FUND_GENERAL_PARTNERS_SUCCESS: {
      return assign({}, state, {
        generalPartnersLoading: false,
        generalPartners: Array.isArray(payload) ? payload : [],
      });
    }
    case types.LIST_FUND_GENERAL_PARTNERS_FAILURE: {
      return assign({}, state, {
        generalPartnersLoading: false,
      });
    }

    case types.EDIT_FUND_GENERAL_PARTNER_REQUEST: {
      return assign({}, state, {
        addOrUpdateLoadingOfFundPerson: true,
      });
    }
    case types.EDIT_FUND_GENERAL_PARTNER_SUCCESS: {
      const updatedGeneralPartners = state.generalPartners.map(partner =>
        partner.id === payload.general_partner.id
          ? { ...payload.general_partner }
          : partner
      );
      return assign({}, state, {
        addOrUpdateLoadingOfFundPerson: false,
        generalPartners: updatedGeneralPartners,
      });
    }
    case types.EDIT_FUND_GENERAL_PARTNER_FAILURE: {
      return assign({}, state, {
        addOrUpdateLoadingOfFundPerson: false,
      });
    }

    case types.REMOVE_FUND_GENERAL_PARTNER_REQUEST: {
      return assign({}, state, {
        removeGeneralPartnerLoading: true,
      });
    }
    case types.REMOVE_FUND_GENERAL_PARTNER_SUCCESS: {
      const updatedGeneralPartners = state.generalPartners.filter(
        partner => partner.id !== payload.general_partner_id
      );
      return assign({}, state, {
        removeGeneralPartnerLoading: false,
        generalPartners: updatedGeneralPartners,
      });
    }
    case types.REMOVE_FUND_GENERAL_PARTNER_FAILURE: {
      return assign({}, state, {
        removeGeneralPartnerLoading: false,
      });
    }

    case types.CREATE_FUND_CONTACT_PERSON_REQUEST: {
      return assign({}, state, {
        addOrUpdateLoadingOfFundPerson: true,
      });
    }
    case types.CREATE_FUND_CONTACT_PERSON_SUCCESS: {
      return assign({}, state, {
        addOrUpdateLoadingOfFundPerson: false,
        fundContactPersons: [
          ...state.fundContactPersons,
          payload.fundContactPerson,
        ],
      });
    }
    case types.CREATE_FUND_CONTACT_PERSON_FAILURE: {
      return assign({}, state, {
        addOrUpdateLoadingOfFundPerson: false,
      });
    }

    case types.LIST_FUND_CONTACT_PERSON_REQUEST: {
      return assign({}, state, {
        fundContactPersonsLoading: true,
      });
    }
    case types.LIST_FUND_CONTACT_PERSON_SUCCESS: {
      return assign({}, state, {
        fundContactPersonsLoading: false,
        fundContactPersons: Array.isArray(payload) ? payload : [],
      });
    }
    case types.LIST_FUND_CONTACT_PERSON_FAILURE: {
      return assign({}, state, {
        fundContactPersonsLoading: false,
      });
    }

    case types.EDIT_FUND_CONTACT_PERSON_REQUEST: {
      return assign({}, state, {
        addOrUpdateLoadingOfFundPerson: true,
      });
    }
    case types.EDIT_FUND_CONTACT_PERSON_SUCCESS: {
      const updatedFundContactPersons = state.fundContactPersons.map(partner =>
        partner.id === payload.fund_contact_person.id
          ? { ...payload.fund_contact_person }
          : partner
      );
      return assign({}, state, {
        addOrUpdateLoadingOfFundPerson: false,
        fundContactPersons: updatedFundContactPersons,
      });
    }
    case types.EDIT_FUND_CONTACT_PERSON_FAILURE: {
      return assign({}, state, {
        addOrUpdateLoadingOfFundPerson: false,
      });
    }

    case types.REMOVE_FUND_CONTACT_PERSON_REQUEST: {
      return assign({}, state, {
        removeFundContactPersonLoading: true,
      });
    }
    case types.REMOVE_FUND_CONTACT_PERSON_SUCCESS: {
      const updatedFundContactPersons = state.fundContactPersons.filter(
        partner => partner.id !== payload.fund_contact_person_id
      );
      return assign({}, state, {
        removeFundContactPersonLoading: false,
        fundContactPersons: updatedFundContactPersons,
      });
    }
    case types.REMOVE_FUND_CONTACT_PERSON_FAILURE: {
      return assign({}, state, {
        removeFundContactPersonLoading: false,
      });
    }

    default:
      return state;
  }
};
