/**
 * CLEAR_AUTOFILL_SUGGESTIONS reducer
 * 
 */
import {
  CLEAR_AUTOFILL_SUGGESTION_REQUEST,
  CLEAR_AUTOFILL_SUGGESTION_SUCCESS,
  CLEAR_AUTOFILL_SUGGESTION_FAILURE,
} from './actions';

  /**
  * Clear Autofill states hashmap with deal id
  */
  const initialState = {
    clearAutofillSuggestionsLoading: {},
    clearAutofillSuggestionsError: {}
}

export default (state=initialState, {type, payload}) => {
  switch(type) {
    case CLEAR_AUTOFILL_SUGGESTION_REQUEST:
      return Object.assign({}, state, {
        clearAutofillSuggestionsLoading: {
          ...state.clearAutofillSuggestionsLoading,
          [payload.dealId]: true
        },
        clearAutofillSuggestionsError: {
          ...state.clearAutofillSuggestionsError,
          [payload.dealId]: null
        }
      })

    case CLEAR_AUTOFILL_SUGGESTION_SUCCESS:
      return Object.assign({}, state, {
        clearAutofillSuggestionsLoading: {
          ...state.clearAutofillSuggestionsLoading,
          [payload.dealId]: false
        },
        clearAutofillSuggestionsError: {
          ...state.clearAutofillSuggestionsError,
          [payload.dealId]: null
        }
      })
    case CLEAR_AUTOFILL_SUGGESTION_FAILURE:
      return Object.assign({}, state, {
        clearAutofillSuggestionsLoading: {
          ...state.clearAutofillSuggestionsLoading,
          [payload.dealId]: false
        },
        clearAutofillSuggestionsError: {
          ...state.clearAutofillSuggestionsError,
          [payload.dealId]: payload.message
        }
      })

    default:
    return state;
}
}
