/**
 * Whatsapp Actions
 */
export const GET_ASSOCIATION_REQUEST = 'whatsapp/GET_ASSOCIATION_REQUEST';
export const GET_ASSOCIATION_SUCCESS = 'whatsapp/GET_ASSOCIATION_SUCCESS';
export const GET_ASSOCIATION_FAILURE = 'whatsapp/GET_ASSOCIATION_FAILURE';

export const DELETE_ASSOCIATION_REQUEST = 'whatsapp/DELETE_ASSOCIATION_REQUEST';
export const DELETE_ASSOCIATION_SUCCESS = 'whatsapp/DELETE_ASSOCIATION_SUCCESS';
export const DELETE_ASSOCIATION_FAILURE = 'whatsapp/DELETE_ASSOCIATION_FAILURE';

export const GENERATE_CHALLENGE_CODE_REQUEST = 'whatsapp/GENERATE_CHALLENGE_CODE_REQUEST';
export const GENERATE_CHALLENGE_CODE_SUCCESS = 'whatsapp/GENERATE_CHALLENGE_CODE_SUCCESS';
export const GENERATE_CHALLENGE_CODE_FAILURE = 'whatsapp/GENERATE_CHALLENGE_CODE_FAILURE';
