import * as types from './actions';
import initialState from './initialState';

import getPersonContact from './reducerHandlers/getPersonContact';
import getRecentInteractions from './reducerHandlers/getRecentInteractions';
import updatePersonContact from './reducerHandlers/updatePersonContact';

/**
 * Reducers
 */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * GET_PERSON reducers
     */
    case types.GET_PERSON_REQUEST:
    case types.GET_PERSON_SUCCESS:
    case types.GET_PERSON_FAILURE:
      return getPersonContact(state, type, payload);
    /**
     * UPDATE_PERSON reducers
     */
    case types.UPDATE_PERSON_REQUEST:
    case types.UPDATE_PERSON_SUCCESS:
    case types.UPDATE_PERSON_FAILURE:
      return updatePersonContact(state, type, payload);
    /**
     * GET_RECENT_INTERACTIONS reducers
     */
    case types.GET_RECENT_INTERACTIONS_REQUEST:
    case types.GET_RECENT_INTERACTIONS_SUCCESS:
    case types.GET_RECENT_INTERACTIONS_FAILURE:
      return getRecentInteractions(state, type, payload);

    default:
      return state;
  }
};
