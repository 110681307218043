/**
 * deal/UPDATE_DEAL_TABLE_COLUMN reducers, handles following-
 * 
 * - ordering
 * - enabling/disabling
 * 
 * @author Ritesh Shrivastav
 */
import {
  UPDATE_DEAL_TABLE_COLUMN_REQUEST,
  UPDATE_DEAL_TABLE_COLUMN_SUCCESS,
  UPDATE_DEAL_TABLE_COLUMN_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    /**
     * UPDATE_DEAL_TABLE_COLUMN_REQUEST
     * Before sending request to get it saved in backend, apply the updates
     * immediately, even if request is failed it should not prevent in real-time
     * enabling/disabling of the columns.
     */
    case UPDATE_DEAL_TABLE_COLUMN_REQUEST:
      return Object.assign({}, state, {
        columns: payload,
        columnsLoading: true,
        columnsError: null
      });
    
    /**
     * UPDATE_DEAL_TABLE_COLUMN_SUCCESS
     */
    case UPDATE_DEAL_TABLE_COLUMN_SUCCESS:
      return Object.assign({}, state, {
        columns: payload,
        columnsLoading: false,
        columnsError: null
      });

    /**
     * UPDATE_DEAL_TABLE_COLUMN_FAILURE
     */
    case UPDATE_DEAL_TABLE_COLUMN_FAILURE:
      return Object.assign({}, state, {
        columnsLoading: false,
        columnsError: payload
      });
      
    default:
      return state;
  }
};
