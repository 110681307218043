/**
 * Deal Stage related constants
 */
import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import yellow from '@material-ui/core/colors/yellow';
import amber from '@material-ui/core/colors/amber';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import brown from '@material-ui/core/colors/brown';
import grey from '@material-ui/core/colors/grey';
import blueGrey from '@material-ui/core/colors/blueGrey';

export const ALLOWED_VARIANTS = {
  LIST_ITEM: 'list-item',
  VERTICAL_RIBBON: 'vertical-ribbon',
  CHIP: 'chip',
  CIRCLE: 'circle',
};

export const COLOR_SELECTIONS = {
  red: {
    name: 'red',
    color: red[500],
  },
  pink: {
    name: 'pink',
    color: pink[500],
  },
  purple: {
    name: 'purple',
    color: purple[500],
  },
  deepPurple: {
    name: 'deepPurple',
    color: deepPurple[500],
  },
  indigo: {
    name: 'indigo',
    color: indigo[500],
  },
  blue: {
    name: 'blue',
    color: blue[500],
  },
  lightBlue: {
    name: 'lightBlue',
    color: lightBlue[500],
  },
  cyan: {
    name: 'cyan',
    color: cyan[500],
  },
  teal: {
    name: 'teal',
    color: teal[500],
  },
  green: {
    name: 'green',
    color: green[500],
  },
  lightGreen: {
    name: 'lightGreen',
    color: lightGreen[500],
  },
  lime: {
    name: 'lime',
    color: lime[500],
  },
  yellow: {
    name: 'yellow',
    color: yellow[500],
  },
  amber: {
    name: 'amber',
    color: amber[500],
  },
  orange: {
    name: 'orange',
    color: orange[500],
  },
  deepOrange: {
    name: 'deepOrange',
    color: deepOrange[500],
  },
  brown: {
    name: 'brown',
    color: brown[500],
  },
  grey: {
    name: 'grey',
    color: grey[500],
  },
  blueGrey: {
    name: 'blueGrey',
    color: blueGrey[500],
  },
  almostBlack: {
    name: 'almostBlack',
    color: grey[900],
  }
};

export const FUNDED = {
  key: 'funded',
  name: 'Funded'
}
