/**
 * @format
 */

import * as types from '../actions';

import { assign } from '../../../helpers/object';

export default (state, type, payload) => {
  switch (type) {
    /** DELETE_DISTRIBUTION */
    case types.DELETE_DISTRIBUTION_REQUEST: {
      return assign(state, {
        deleteDistributionLoading: true,
      });
    }
    case types.DELETE_DISTRIBUTION_FAILURE: {
      return assign(state, {
        deleteDistributionLoading: false,
      });
    }
    case types.DELETE_DISTRIBUTION_SUCCESS: {
      const distributionState = { ...state.distribution };
      delete distributionState[payload.distributionId];
      return assign(state, {
        deleteDistributionLoading: false,
        distribution: distributionState,
      });
    }

    default:
      return state;
  }
};
