/**
 * Event reducer
 *
 * @author Ritesh Shrivastav
 */
import * as types from './actions';

const initialState = {
  eventList: [],
  eventListLoading: false,
  eventListError: null,
  eventListOptions: {
    type: 'all',
    page: 1,
    limit: 10
  },
  eventListMore: false,

  event: null,
  eventLoading: false,
  eventError: null,

  assignToDealLoading: false,
  assignToDealError: null,

  noteList: [],
  noteListLoading: false,
  noteListError: null,
  noteListOptions: {
    type: 'all',
    page: 1,
    limit: 10
  },
  noteListMore: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
     * Event list reducers
     */
    case types.LOAD_EVENT_LIST_REQUEST:
      return Object.assign({}, state, {
        eventListLoading: true,
        eventListError: null
      });
    case types.LOAD_EVENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        eventList: ((payload.options.page === 1) ? payload.data : [...state.eventList,...payload.data]),
        eventListLoading: false,
        eventListError: null,
        eventListOptions: payload.options,
        eventListMore: (payload.data.length >= payload.options.limit ? true : false)
      });
    case types.LOAD_EVENT_LIST_FAILURE:
      return Object.assign({}, state, {
        eventListLoading: false,
        eventListError: payload,
      });

    /**
     * Event reducer
     */
    case types.LOAD_EVENT_REQUEST:
      return Object.assign({}, state, {
        eventLoading: true,
        eventError: null
      });
    case types.LOAD_EVENT_SUCCESS:
      return Object.assign({}, state, {
        event: payload,
        eventLoading: false,
        eventError: null
      });
    case types.LOAD_EVENT_FAILURE:
      return Object.assign({}, state, {
        eventLoading: false,
        eventError: payload,
      });

    /**
     * Event to deal assign reducer
     */
    case types.ASSIGN_TO_DEAL_REQUEST:
      return Object.assign({}, state, {
        assignToDealLoading: true,
        assignToDealError: null
      });
    case types.ASSIGN_TO_DEAL_SUCCESS:
      return Object.assign({}, state, {
        assignToDealLoading: false,
        assignToDealError: null
      });
    case types.ASSIGN_TO_DEAL_FAILURE:
      return Object.assign({}, state, {
        assignToDealLoading: false,
        assignToDealError: payload,
      });

    default:
      return state;
  }
};
