/**
 * Feed action creators
 *
 * @author Ritesh Shrivastav
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const loadReviewedArticles = (options) => {
  return async dispatch => {
    dispatch({
      type: types.LOAD_REVIEWED_ARTICLES_REQUEST
    });
    let data;
    try {
      const response = await request.get(`/api/v3/holodeck/articles/reviewed`, options, dispatch)
      data = response.data;
    } catch (err) {
      const message = 'Something went wrong while loading reviewed articles, please try again.';
      dispatch({
        type: types.LOAD_REVIEWED_ARTICLES_FAILURE,
        payload: message
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.LOAD_REVIEWED_ARTICLES_SUCCESS,
        payload: {
          data: data.data,
          options: options
        }
      });
      return data.data;
    } else {
      dispatch({
        type: types.LOAD_REVIEWED_ARTICLES_FAILURE,
        payload: data.message
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message)
    }
  };
};
export const loadUniverseCompanies = (options) => {
  return async dispatch => {
    dispatch({
      type: types.LOAD_UNIVERSE_COMPANIES_REQUEST
    });
    let data;
    try {
      const response = await request.get(`/api/v3/holodeck/universe/companies`, options, dispatch);
      data = response.data;
    } catch (err) {
      const message = 'Something went wrong while loading companies, please try again.';
      dispatch({
        type: types.LOAD_UNIVERSE_COMPANIES_FAILURE,
        payload: message
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.LOAD_UNIVERSE_COMPANIES_SUCCESS,
        payload: {
          data: data.data,
          options: options
        }
      });
      return data.data;
    } else {
      dispatch({
        type: types.LOAD_UNIVERSE_COMPANIES_FAILURE,
        payload: data.message
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
