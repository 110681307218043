/**
 * Add sign off group on a deal
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  ADD_SIGN_OFF_GROUP_REQUEST,
  ADD_SIGN_OFF_GROUP_SUCCESS,
  ADD_SIGN_OFF_GROUP_FAILURE,
} from '../actions';

export default (form, deal_id) => {
  return async dispatch => {
    dispatch({
      type: ADD_SIGN_OFF_GROUP_REQUEST,
      payload: {
        dealId: deal_id,
      },
    });

    let data;
    try {
      const response = await request.post(
        `/api/pipeline/sign-offs/v1/deal/${deal_id}/sign-off-groups`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      let message =
        'Something went wrong while adding a sign off list, please try again.';
      if (err?.response?.status === 429) {
        message = `Too fast! Try again in a couple of seconds.`;
      }
      dispatch({
        type: ADD_SIGN_OFF_GROUP_FAILURE,
        payload: {
          dealId: deal_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: ADD_SIGN_OFF_GROUP_SUCCESS,
        payload: {
          dealId: deal_id,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: ADD_SIGN_OFF_GROUP_FAILURE,
        payload: {
          dealId: deal_id,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
