import {
  LOAD_DEAL_TABLE_ROW_SETTINGS_REQUEST,
  LOAD_DEAL_TABLE_ROW_SETTINGS_SUCCESS,
  LOAD_DEAL_TABLE_ROW_SETTINGS_FAILURE } from '../actions';
import { DealRowHeightOptions } from '../../../constants/DealRowHeightOptions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_DEAL_TABLE_ROW_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        tableRowSettingsLoading: true,
        tableRowSettingsError: null
      });

    case LOAD_DEAL_TABLE_ROW_SETTINGS_SUCCESS:
      return Object.keys(payload).length > 0 ? 
        Object.assign({}, state, {
        tableRowSettingsLoading: false,
        tableRowSettingsError: null,
        tableRowSettings: payload
      })
      : Object.assign({}, state, {
        tableRowSettingsLoading: false,
        tableRowSettingsError: null,
        tableRowSettings: {
          ...state.tableRowSettings,
          heights: {...DealRowHeightOptions}
        }
      });

    case LOAD_DEAL_TABLE_ROW_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        tableRowSettingsLoading: false,
        tableRowSettingsError: payload.message
      });

    default:
      return state;
  }
};
