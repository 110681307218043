/**
 * ADD_SIGN_OFF_ITEM reducer
 * @format
 */
import {
  ADD_SIGN_OFF_ITEM_REQUEST,
  ADD_SIGN_OFF_ITEM_SUCCESS,
  ADD_SIGN_OFF_ITEM_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case ADD_SIGN_OFF_ITEM_REQUEST:
      return Object.assign({}, state, {
        addSignOffItemLoading: {
          ...state.addSignOffItemLoading,
          [payload.signOffGroupId]: true,
        },
        addSignOffItemError: {
          ...state.addSignOffItemError,
          [payload.signOffGroupId]: null,
        },
      });

    case ADD_SIGN_OFF_ITEM_SUCCESS:
      return Object.assign({}, state, {
        addSignOffItemLoading: {
          ...state.addSignOffItemLoading,
          [payload.signOffGroupId]: false,
        },
        addSignOffItemError: {
          ...state.addSignOffItemError,
          [payload.signOffGroupId]: null,
        },
        signOffs: {
          ...state.signOffs,
          [payload.data.id]: payload.data,
        },
        signOffGroupsItemsHashMap: {
          ...state.signOffGroupsItemsHashMap,
          [payload.signOffGroupId]: state.signOffGroupsItemsHashMap[
            payload.signOffGroupId
          ]
            ? [
                // If the group already has other sign offs, append the newest
                // one to the bottom
                ...state.signOffGroupsItemsHashMap[payload.signOffGroupId],
                payload.data.id,
              ]
            : // If it's the first one for this group, initialize
              [payload.data.id],
        },
      });
    case ADD_SIGN_OFF_ITEM_FAILURE:
      return Object.assign({}, state, {
        addSignOffItemLoading: {
          ...state.addSignOffItemLoading,
          [payload.signOffGroupId]: false,
        },
        addSignOffItemError: {
          ...state.addSignOffItemError,
          [payload.signOffGroupId]: payload.message,
        },
      });

    default:
      return state;
  }
};
