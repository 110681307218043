/** @format */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

// mark reducers as the default export
export default reducers;

// LoadMagicSyncSuggestionsForUser loads all the suggestions of deals
export const LoadMagicSyncSuggestionsForUser = (queryParams, isDateChanged) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_MAGIC_SYNC_SUGGESTIONS_REQUEST,
        payload: {},
      });
      request
        .get(`/api/magicsync/v1/stats/me`, queryParams, dispatch)
        .then(({ data }) => {
          if (data.success) {
            const { contacts, meta } = data.data;
            dispatch({
              type: types.LOAD_MAGIC_SYNC_SUGGESTIONS_SUCCESS,
              payload: { contacts, meta, isDateChanged },
            });
            return resolve(data.data);
          } else {
            dispatch({
              type: types.LOAD_MAGIC_SYNC_SUGGESTIONS_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading Suggestions, please try again.';
          dispatch({
            type: types.LOAD_MAGIC_SYNC_SUGGESTIONS_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

export const addDealFromContact = contacts => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const addedIds = Array.isArray(contacts)
        ? contacts.map(suggestion => suggestion.id)
        : [contacts.id];
      dispatch({
        type: types.ADD_DEAL_FROM_CONTACT_REQUEST,
        payload: addedIds,
      });
      request
        .post(`/api/contact/v1/deal-from-contact`, contacts, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.ADD_DEAL_FROM_CONTACT_SUCCESS,
              payload: addedIds,
            });
            dispatch(enqueueNotification('Deal Added succesfully!'));
            return resolve(data.success);
          } else {
            dispatch({
              type: types.ADD_DEAL_FROM_CONTACT_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while Adding deal, please try again.';
          dispatch({
            type: types.ADD_DEAL_FROM_CONTACT_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

export const DiscardPotentialDealFromContact = contacts => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const discardedIds = Array.isArray(contacts)
        ? contacts.map(suggestion => suggestion.id)
        : [contacts.id];
      dispatch({
        type: types.DISCARD_FROM_CONTACT_REQUEST,
        payload: discardedIds,
      });
      request
        .post(`/api/contact/v1/not-a-deal`, contacts, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.DISCARD_FROM_CONTACT_SUCCESS,
              payload: discardedIds,
            });
            return resolve(data.success);
          } else {
            dispatch({
              type: types.DISCARD_FROM_CONTACT_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while discarding, please try again.';
          dispatch({
            type: types.DISCARD_FROM_CONTACT_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

export const updatePotentialDealName = (dealName, suggestionId) => {
  return dispatch => {
    dispatch({
      type: types.UPDATE_SUGGESTION_DEAL_NAME,
      payload: { suggestionId, dealName },
    });
  };
};
