/**
 * Sets deal id list - list of deals currently being shown in deals page
 *
 * @format
 */
import { SET_DEAL_ID_LIST } from '../actions';

export default ids => {
  return dispatch => {
    dispatch({
      type: SET_DEAL_ID_LIST,
      payload: ids && ids.length > 0 ? ids : [],
    });
  };
};
