/** @format */
import reduce from 'lodash.reduce';
import omit from 'lodash.omit';
import * as types from './actions';

import { assign } from '../../helpers/object';
import property from 'lodash.property';
import uniq from 'lodash.uniq';
import defaultCompaniesTableColumnsConfig from './defaultCompaniesTableColumnsConfig';

const initial = {
  funds: {
    /* store of all funds in this organization */
  },
  fundLoading: false,
  fundError: null,
  rates: [
    /* collection of exchange rates */
  ],
  deleteRateLoading: false,
  // Transaction report. Key is transaction id
  transactionReport: {},
  transactionReportLoading: false,
  accessMap: {
    // [fundId]: [{user, _roles}]
  },
  accessLoading: false,

  //Table view configuration for fund-companies Table
  columns: defaultCompaniesTableColumnsConfig,
  columnsLoading: false,
  columnsError: null,
  manageColumnOpen: false,

  /**
   * Funds custom fields, these are common for all the Funds
   */
  customFields: [],
  customFieldsLoading: false,
  customFieldsError: null,
  customFieldValues: {},
  customFieldValuesLoading: {},
  customFieldValuesError: null,
};

export default function(state = initial, { type, payload }) {
  switch (type) {
    case types.LOAD_ALL_FUNDS: {
      // payload is an array of fund objects.
      // we convert / reduce that array into an object of shape
      //   { [id]: fund, ... }
      const funds = reduce(
        payload,
        function(funds, fund) {
          funds[fund.id] = fund;
          return funds;
        },
        {
          /* accumulator */
        }
      );
      return assign(state, {
        funds: assign(state.funds, funds),
      });
    }

    case types.LOAD_FUND_REQUEST:
    case types.UPDATE_FUND_REQUEST: {
      return assign(state, {
        fundLoading: true,
        fundError: null,
      });
    }
    case types.LOAD_FUND_SUCCESS:
    case types.UPDATE_FUND_SUCCESS: {
      return assign(state, {
        fundLoading: false,
        funds: assign(state.funds, {
          [payload.fundId]: assign(
            property(payload.fundId)(state.funds) || {},
            payload.data
          ),
        }),
        fundError: null,
      });
    }
    case types.LOAD_FUND_FAILURE:
    case types.UPDATE_FUND_FAILURE: {
      return assign(state, {
        fundLoading: false,
        fundError: payload.message,
      });
    }

    case types.ADD_FUND: {
      return assign(state, {
        funds: assign(state.funds, { [payload.id]: payload }),
      });
    }

    case types.DELETE_FUND: {
      return assign(state, {
        funds: omit(state.funds, payload.id),
      });
    }

    case types.LOAD_EXCHANGE_RATE: {
      // payload is an array of rate objects.
      // we convert / reduce that array into an object of shape
      //   { [from-to]: rate, ... }
      if (!payload.filters && !payload.page) {
        const rates = reduce(
          payload.data,
          function(rates, rate) {
            rates[`${rate.from}-${rate.to}`] = rate;
            return rates;
          },
          {}
        );

        return assign(state, { rates: assign(state.rates, rates) });
      }
      // @todo: handle updating redux states appropriately when filters and page
      // params are supplied
      return state;
    }

    case types.SAVE_EXCHANGE_RATE: {
      const rate = { [`${payload.from}-${payload.to}`]: payload };
      return assign(state, { rates: assign(state.rates, rate) });
    }

    case types.DELETE_EXCHANGE_RATE_REQUEST: {
      return assign(state, { deleteRateLoading: true });
    }
    case types.DELETE_EXCHANGE_RATE_SUCCESS: {
      const rates = { ...state.rates };
      delete rates[`${payload.from}-${payload.to}`];
      return assign(state, {
        deleteRateLoading: false,
        rates: assign({}, rates),
      });
    }
    case types.DELETE_EXCHANGE_RATE_FAILURE: {
      return assign(state, { deleteRateLoading: false });
    }

    case types.LOAD_TRANSACTION_REPORT_REQUEST: {
      return assign(state, { transactionReportLoading: true });
    }
    case types.LOAD_TRANSACTION_REPORT_SUCCESS: {
      return assign(state, {
        transactionReportLoading: false,
        transactionReport: assign(
          state.transactionReport,
          // `payload.data` will be an array of transaction reports. We reduce
          // it to a hashmap here
          reduce(
            payload.data,
            (acc, trx) => {
              acc[trx.id] = trx;
              return acc;
            },
            {}
          )
        ),
      });
    }
    case types.LOAD_TRANSACTION_REPORT_FAILURE: {
      return assign(state, { transactionReportLoading: false });
    }

    case types.LOAD_FUND_ACCESS_MAP_REQUEST: {
      return assign(state, {
        accessLoading: true,
      });
    }

    case types.LOAD_FUND_ACCESS_MAP_SUCCESS: {
      return assign(state, {
        accessLoading: false,
        accessMap: {
          [payload.fundId]: payload.data,
        },
      });
    }

    case types.LOAD_FUND_ACCESS_MAP_FAILURE: {
      return assign(state, {
        accessLoading: false,
      });
    }

    case types.UPDATE_FUND_ACCESS_REQUEST: {
      return assign(state, {
        accessLoading: true,
      });
    }

    case types.UPDATE_FUND_ACCESS_SUCCESS: {
      return assign(state, {
        accessLoading: false,
        accessMap: {
          ...state.accessMap,
          // Update roles in the state on success
          // prettier-ignore
          [payload.fundId]: (property(`accessMap.${payload.fundId}`)(state) || []).map(({ user, _roles }) => {
            const result = { user, _roles };
            if(user.id === payload.userId) {
              // only for the user whose roles are being changed
              Object.keys(payload.accessMap).forEach(role => {
                if (payload.accessMap[role]) {
                  // means that the user has this role
                  result._roles = uniq([..._roles, role]);
                } else {
                  // means the user doesn't have this role
                  result._roles = result._roles.filter(r => r !== role);
                }
              });
            }
            return result;
          })
        },
      });
    }

    case types.UPDATE_FUND_ACCESS_FAILURE: {
      return assign(state, {
        accessLoading: false,
      });
    }

    case types.CLEAR_TRANSACTION_REPORT: {
      // can be used to unset local redux state
      return assign(state, { transactionReport: {} });
    }

    // Resets the redux state
    case types.CLEAR: {
      return assign(state, initial);
    }

    // Load custom fields
    case types.LOAD_CUSTOM_FIELDS_REQUEST:
      return Object.assign({}, state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case types.LOAD_CUSTOM_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: null,
        customFields: payload,
      });

    case types.LOAD_CUSTOM_FIELDS_FAILURE:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: payload.message,
      });

    // Load Custom field values
    case types.LOAD_CUSTOM_FIELD_VALUES_REQUEST:
      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...payload,
        },
        customFieldValuesError: null,
      });

    case types.LOAD_CUSTOM_FIELD_VALUES_SUCCESS:
      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...payload.loadingMap,
        },
        customFieldValues: {
          ...state.customFieldValues,
          ...payload.dataMap,
        },
        customFieldValuesError: null,
      });

    case types.LOAD_CUSTOM_FIELD_VALUES_FAILURE:
      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...payload.loadingMap,
        },
        customFieldValuesError: payload.message,
      });

    // Update Custom Field Values
    case types.UPDATE_CUSTOM_FIELD_VALUE_REQUEST:
      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...payload,
        },
        customFieldsError: null,
      });

    case types.UPDATE_CUSTOM_FIELD_VALUE_SUCCESS:
      return Object.assign({}, state, {
        customFieldsError: null,
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...payload.loadingMap,
        },
        customFieldValues: {
          ...state.customFieldValues,
          ...payload.dataMap,
        },
      });

    case types.UPDATE_CUSTOM_FIELD_VALUE_FAILURE:
      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...payload.loadingMap,
        },
        customFieldsError: payload.message,
      });

    case types.UPDATE_CUSTOM_FIELDS_REQUEST:
      return Object.assign({}, state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case types.UPDATE_CUSTOM_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: null,
      });

    case types.UPDATE_CUSTOM_FIELDS_FAILURE:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: payload,
      });

    case types.REMOVE_CUSTOM_FIELDS_REQUEST:
      return Object.assign({}, state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case types.REMOVE_CUSTOM_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: null,
      });

    case types.REMOVE_CUSTOM_FIELDS_FAILURE:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: payload,
      });

    case types.ADD_CUSTOM_FIELD_REQUEST:
      return Object.assign({}, state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case types.ADD_CUSTOM_FIELD_SUCCESS:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFields: payload,
        customFieldsError: null,
      });

    case types.ADD_CUSTOM_FIELD_FAILURE:
      return Object.assign({}, state, {
        customFieldsLoading: false,
        customFieldsError: payload,
      });
    case types.LOAD_FUND_COMPANIES_TABLE_COLUMNS_REQUEST:
      return Object.assign({}, state, {
        columnsLoading: true,
        columnsError: null,
      });
    case types.LOAD_FUND_COMPANIES_TABLE_COLUMNS_SUCCESS:
      return payload.length > 0
        ? Object.assign({}, state, {
            columnsLoading: false,
            columnsError: null,
            columns: payload,
          })
        : Object.assign({}, state, {
            columns: defaultCompaniesTableColumnsConfig,
            columnsLoading: false,
            columnsError: null,
          });
    case types.LOAD_FUND_COMPANIES_TABLE_COLUMNS_FAILURE:
      return Object.assign({}, state, {
        columnsLoading: false,
        columnsError: payload?.message,
      });

    case types.UPDATE_FUND_COMPANIES_TABLE_COLUMNS_REQUEST:
      return Object.assign({}, state, {
        columns: payload,
        columnsLoading: true,
        columnsError: null,
      });
    /**
     * UPDATE_PORTFOLIO_TABLE_COLUMN_SUCCESS
     */
    case types.UPDATE_FUND_COMPANIES_TABLE_COLUMNS_SUCCESS:
      return Object.assign({}, state, {
        columns: payload,
        columnsLoading: false,
        columnsError: null,
      });
    case types.UPDATE_FUND_COMPANIES_TABLE_COLUMNS_FAILURE:
      return Object.assign({}, state, {
        columnsLoading: false,
        columnsError: payload,
      });

    case types.COMPANIES_OPEN_MANAGE_COLUMN:
      return Object.assign({}, state, {
        manageColumnOpen: true,
      });
    case types.COMPANIES_CLOSE_MANAGE_COLUMN:
      return Object.assign({}, state, {
        manageColumnOpen: false,
      });

    default:
      return state;
  }
}
