/**
 * fetches values of custom fields for an individual company
 * @format
 */
import {
  LOAD_CUSTOM_FIELD_VALUES_REQUEST,
  LOAD_CUSTOM_FIELD_VALUES_SUCCESS,
  LOAD_CUSTOM_FIELD_VALUES_FAILURE,
} from '../actions';
import { enqueueNotification } from '../../app';
import request from '../../../helpers/request';

export default portcoIds => {
  return async dispatch => {
    dispatch({
      type: LOAD_CUSTOM_FIELD_VALUES_REQUEST,
      payload: { portcoIds },
    });
    let data;
    try {
      const response = await request.get(`/api/portfolio-fields/v1/values`, {
        id: portcoIds,
      });
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading custom field values, please try again.';
      dispatch({
        type: LOAD_CUSTOM_FIELD_VALUES_FAILURE,
        payload: { message: data.message, portcoIds },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: LOAD_CUSTOM_FIELD_VALUES_SUCCESS,
        payload: { data: data.data, portcoIds },
      });
      return data;
    } else {
      dispatch({
        type: LOAD_CUSTOM_FIELD_VALUES_FAILURE,
        payload: { message: data.message, portcoIds },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
