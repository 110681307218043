/**
 * Removes external referrer from the deal
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  REMOVE_EXTERNAL_REFERRER_REQUEST,
  REMOVE_EXTERNAL_REFERRER_SUCCESS,
  REMOVE_EXTERNAL_REFERRER_FAILURE } from '../actions';

export default (dealId, userId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: REMOVE_EXTERNAL_REFERRER_REQUEST,
        payload: { dealId }
      });
      request.del(`/api/v2/deals/${dealId}/external_referrers/${userId}`, {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: REMOVE_EXTERNAL_REFERRER_SUCCESS,
            payload: { dealId }
          });
          resolve(data);
        } else {
          dispatch({
            type: REMOVE_EXTERNAL_REFERRER_FAILURE,
            payload: {
              dealId,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while removing external referrer, please try again.';
        dispatch({
          type: REMOVE_EXTERNAL_REFERRER_FAILURE,
          payload: {
            dealId,
            message,
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};