/**
 * Organization reducers
 *
 * @author Ritesh Shrivastav
 */
import Time from '../../components/Time';
import * as types from './actions';
import defaultDealOverviewFields from './defaultDealOverviewFields';
import defaultPortfolioTabsFields from './defaultPortfolioTabsFields';

const EXPIRE_AFTER = 48 * 60 * 60 * 1000;

const initialState = {
  teamMembers: [],
  teamMembersLoading: false,
  teamMembersError: null,

  inviteeList: [],
  inviteeListLoading: false,
  inviteeListError: null,

  inviteMembersLoading: false,
  inviteMembersError: null,
  inviteMembersData:[],

  inviteMemberLoading: false,
  inviteMemberError: null,

  teamMemberRoles: {},
  teamMemberRolesLoading: {},
  teamMemberRolesError: {},

  updateTeamMemberRolesLoading: {},
  updateTeamMemberRolesError: {},

  organizationList: [],
  organizationListLoading: false,
  organizationListError: null,
  organizationListMore: false,
  organizationListOptions: { nolimit: true },

  teamMemberDeals: {},
  teamMemberDealsLoading: {},
  teamMemberDealsError: {},

  assignMemberDealToSelfLoading: {},
  assignMemberDealToSelfError: {},

  disableMemberAccountLoading: {},
  disableMemberAccountError: {},

  // portfolio tab configuration
  portcoTabs:defaultPortfolioTabsFields,
  portfoliotTabsLoading:false,
  portfoliotTabsError:null,

  /**
   * Deal Overview configurations
   */
  dealOverviewFields: defaultDealOverviewFields,
  dealOverviewFieldsLoading: false,
  dealOverviewFieldsError: null,

  shareDealTemplates:[],
  shareDealTemplatesLoading:false,
  shareDealTemplatesError:null,

};

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case types.LOAD_INVITEE_LIST_REQUEST:
      return Object.assign({}, state, {
        inviteeListLoading: true,
        inviteeListError: null
      });
    case types.LOAD_INVITEE_LIST_SUCCESS:
      const _inviteeList = payload.map(invite=>{
          const startDate = new Date(invite.updatedAt);
          const expiryTime = new Date(Date.parse(startDate) + EXPIRE_AFTER);
          const updatedInvitee =  {...invite , expiresOn : Time.getFormattedString(expiryTime, 'MMMM Do YYYY, h:mm a')}
          delete updatedInvitee.updatedAt;
          return updatedInvitee;
      })
      return Object.assign({}, state, {
        inviteeList: _inviteeList,
        inviteeListLoading: false,
        inviteeListError: null
      });
    case types.LOAD_INVITEE_LIST_FAILURE:
      return Object.assign({}, state, {
        inviteeListLoading: false,
        inviteeListError: payload
      });

    /**
     * Load team members reducers
     */
    case types.LOAD_TEAM_MEMBERS_REQUEST:
      return Object.assign({}, state, {
        teamMembersLoading: true,
        teamMembersError: null
      });
    case types.LOAD_TEAM_MEMBERS_SUCCESS:
      return Object.assign({}, state, {
        teamMembers: payload,
        teamMembersLoading: false,
        teamMembersError: null,
      });
    case types.LOAD_TEAM_MEMBERS_FAILURE:
      return Object.assign({}, state, {
        teamMembersLoading: false,
        teamMembersError: payload,
      });

    /**
    * Inviting multiple team members reducers
    */
    case types.INVITE_MEMBERS_REQUEST:
      return Object.assign({}, state, {
        inviteMembersLoading: true,
        inviteMembersError: null
      });
    case types.INVITE_MEMBERS_SUCCESS:
      return Object.assign({}, state, {
        inviteMembersLoading: false,
        inviteMembersError: null
      });
    case types.INVITE_MEMBERS_FAILURE:
      return Object.assign({}, state, {
        inviteMembersLoading: true,
        inviteMembersError: payload
    });

    /*
     * Load team member roles reducers
     */
    case types.LOAD_MEMBER_ROLES_REQUEST:
      return Object.assign({}, state, {
        teamMemberRolesLoading: {
          ...state.teamMemberRolesLoading,
          [payload.user_id]: true,
        },
        teamMemberRolesError: {
          ...state.teamMemberRolesError,
          [payload.user_id]: null,
        },
      });
    case types.LOAD_MEMBER_ROLES_SUCCESS:
      return Object.assign({}, state, {
        teamMemberRolesLoading: {
          ...state.teamMemberRolesLoading,
          [payload.user_id]: false,
        },
        teamMemberRoles: {
          ...state.teamMemberRoles,
          [payload.user_id]: payload.payload,
        },
        teamMemberRolesError: {
          ...state.teamMemberRolesError,
          [payload.user_id]: null,
        },
      });
    case types.LOAD_MEMBER_ROLES_FAILURE:
      return Object.assign({}, state, {
        teamMemberRolesLoading: {
          ...state.teamMemberRolesLoading,
          [payload.user_id]: false,
        },
        teamMemberRolesError: {
          ...state.teamMemberRolesError,
          [payload.user_id]: payload.payload,
        },
      });

    /*
     * Load team member deals reducers
     */
    case types.LOAD_MEMBER_DEALS_REQUEST:
      return Object.assign({}, state, {
        teamMemberDealsLoading: {
          ...state.teamMemberDealsLoading,
          [payload.user_id]: true,
        },
        teamMemberDealsError: {
          ...state.teamMemberDealsError,
          [payload.user_id]: null,
        },
      });
    case types.LOAD_MEMBER_DEALS_SUCCESS:
      return Object.assign({}, state, {
        teamMemberDealsLoading: {
          ...state.teamMemberDealsLoading,
          [payload.user_id]: false,
        },
        teamMemberDeals: {
          ...state.teamMemberDeals,
          [payload.user_id]: payload.data,
        },
        teamMemberDealsError: {
          ...state.teamMemberDealsError,
          [payload.user_id]: null,
        },
      });
    case types.LOAD_MEMBER_DEALS_FAILURE:
      return Object.assign({}, state, {
        teamMemberDealsLoading: {
          ...state.teamMemberDealsLoading,
          [payload.user_id]: false,
        },
        teamMemberDealsError: {
          ...state.teamMemberDealsError,
          [payload.user_id]: payload.payload,
        },
      });

    /*
     * Assign team member deal to self reducers
     */
    case types.ASSIGN_MEMBER_DEAL_TO_SELF_REQUEST:
      return Object.assign({}, state, {
        assignMemberDealToSelfLoading: {
          ...state.assignMemberDealToSelfLoading,
          [`${payload.member_user_id}-${payload.deal_id}`]: true,
        },
        assignMemberDealToSelfError: {
          ...state.assignMemberDealToSelfError,
          [`${payload.member_user_id}-${payload.deal_id}`]: null,
        },
      });
    case types.ASSIGN_MEMBER_DEAL_TO_SELF_SUCCESS:
      return Object.assign({}, state, {
        assignMemberDealToSelfLoading: {
          ...state.assignMemberDealToSelfLoading,
          [`${payload.member_user_id}-${payload.deal_id}`]: false,
        },
        assignMemberDealToSelfError: {
          ...state.assignMemberDealToSelfError,
          [`${payload.member_user_id}-${payload.deal_id}`]: null,
        },
      });
    case types.ASSIGN_MEMBER_DEAL_TO_SELF_FAILURE:
      return Object.assign({}, state, {
        assignMemberDealToSelfLoading: {
          ...state.assignMemberDealToSelfLoading,
          [`${payload.member_user_id}-${payload.deal_id}`]: false,
        },
        assignMemberDealToSelfError: {
          ...state.assignMemberDealToSelfError,
          [`${payload.member_user_id}-${payload.deal_id}`]: payload.payload,
        },
      });

    /**
     * Update team member roles reducers
     */
    case types.UPDATE_MEMBER_ROLES_REQUEST:
      return Object.assign({}, state, {
        updateTeamMemberRolesLoading: {
          ...state.updateTeamMemberRolesLoading,
          [payload.user_id]: true,
        },
        updateTeamMemberRolesError: {
          ...state.updateTeamMemberRolesError,
          [payload.user_id]: null,
        },
      });
    case types.UPDATE_MEMBER_ROLES_SUCCESS:
      return Object.assign({}, state, {
        updateTeamMemberRolesLoading: {
          ...state.updateTeamMemberRolesLoading,
          [payload.user_id]: false,
        },
        updateTeamMemberRolesError: {
          ...state.updateTeamMemberRolesError,
          [payload.user_id]: null,
        },
      });
    case types.UPDATE_MEMBER_ROLES_FAILURE:
      return Object.assign({}, state, {
        updateTeamMemberRolesLoading: {
          ...state.updateTeamMemberRolesLoading,
          [payload.user_id]: false,
        },
        updateTeamMemberRolesError: {
          ...state.updateTeamMemberRolesError,
          [payload.user_id]: payload.payload,
        },
      });

    /**
     * Disable member account reducers
     */
    case types.DISABLE_MEMBER_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        disableMemberAccountLoading: {
          ...state.disableMemberAccountLoading,
          [payload.member_user_id]: true,
        },
        disableMemberAccountError: {
          ...state.disableMemberAccountError,
          [payload.member_user_id]: null,
        },
      });
    case types.DISABLE_MEMBER_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        disableMemberAccountLoading: {
          ...state.disableMemberAccountLoading,
          [payload.member_user_id]: false,
        },
        disableMemberAccountError: {
          ...state.disableMemberAccountError,
          [payload.member_user_id]: null,
        },
      });
    case types.DISABLE_MEMBER_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        disableMemberAccountLoading: {
          ...state.disableMemberAccountLoading,
          [payload.member_user_id]: false,
        },
        disableMemberAccountError: {
          ...state.disableMemberAccountError,
          [payload.member_user_id]: payload.payload,
        },
      });

    /**
     * Invite team member reducers
     */
    case types.INVITE_MEMBER_REQUEST:
      return Object.assign({}, state, {
        inviteMemberLoading: true,
        inviteMembersData:[],
        inviteMemberError: null
      });
    case types.INVITE_MEMBER_SUCCESS:
      const latestInvitedMembers = payload.map(invite=>{
        const startDate = new Date(invite.updatedAt);
        const expiryTime = new Date(Date.parse(startDate) + EXPIRE_AFTER);
        const updatedInvitee =  {...invite ,isExpired : 'false', expiresOn : Time.getFormattedString(expiryTime, 'MMMM Do YYYY, h:mm a')}
        delete updatedInvitee.updatedAt;
        return updatedInvitee;
      })
                    
      const updatedInvitees = state.inviteeList.map(invitee => {
        // Find the matching payload object by email
        const matchedInvitee = latestInvitedMembers.find(payload => payload.email === invitee.email);
        if (matchedInvitee) {
          return { ...invitee, isExpired:'false', invitedUrl: matchedInvitee.invitedUrl , expiresOn : matchedInvitee.expiresOn };
        }
        return invitee;
      });
      const newInvitees = latestInvitedMembers.filter(latestInvitee =>
        !state.inviteeList.some(invitee => invitee.email === latestInvitee.email)
      )
      return Object.assign({}, state, {
        inviteMemberLoading: false,
        inviteMembersData:latestInvitedMembers,
        inviteeList : [...newInvitees,...updatedInvitees],
        inviteMemberError: null,
      });
    case types.INVITE_MEMBER_FAILURE:
      return Object.assign({}, state, {
        inviteMemberLoading: false,
        inviteMembersData:[],
        inviteMemberError: payload,
      });

    /**
     * Load organization list - reducers
     */
    case types.LIST_ORGANIZATIONS_REQUEST:
      return Object.assign({}, state, {
        organizationListLoading: true,
        organizationListError: null,
        organizationListOptions: payload.options,
      });
    case types.LIST_ORGANIZATIONS_SUCCESS:
      return Object.assign({}, state, {
        organizationListLoading: false,
        organizationListError: null,
        organizationList: ((payload.options.nolimit || payload.options.page === 1) ?
          payload.data : [...state.organizationList,...payload.data]),
        organizationListOptions: payload.options,
        organizationListMore: ((payload.data.length >= payload.options.limit &&
          !payload.options.nolimit) ? true : false)
      });
    case types.LIST_ORGANIZATIONS_FAILURE:
      return Object.assign({}, state, {
        organizationListLoading: false,
        organizationListError: payload,
      });

    /**
    * Load portfolio tabs - reducers
    */
    case types.PORTFOLIO_TAB_REQUEST:
      return Object.assign({}, state, {
        portfoliotTabsLoading: true,
      });
    case types.PORTFOLIO_TAB_SUCCESS:
      return payload.length > 0
        ? Object.assign({}, state, {
            portfoliotTabsLoading: false,
            portcoTabs: payload,
          })
        : Object.assign({}, state, {
            columns: defaultPortfolioTabsFields,
            columnsLoading: false,
          });
    case types.PORTFOLIO_TAB_FAILURE:
      return Object.assign({}, state, {
        portfoliotTabsLoading: false,
        portfoliotTabsError: payload.message,
      });

      case types.UPDATE_PORTFOLIO_TAB_REQUEST:
        return Object.assign({}, state, {
          portcoTabs: payload,
          portfoliotTabsLoading: true,
        });
      case types.UPDATE_PORTFOLIO_TAB_SUCCESS:
        return Object.assign({}, state, {
          portcoTabs: payload,
          portfoliotTabsLoading: false,
        });
      case types.UPDATE_PORTFOLIO_TAB_FAILURE:
        return Object.assign({}, state, {
          portfoliotTabsLoading: false,
          portfoliotTabsError: payload.message,
        });


    case types.DEAL_OVERVIEW_FIELDS_REQUEST:
      return Object.assign({}, state, {
        dealOverviewFieldsLoading: true,
      });
    case types.DEAL_OVERVIEW_FIELDS_SUCCESS:
      return payload.length > 0
        ? Object.assign({}, state, {
            dealOverviewFieldsLoading: false,
            dealOverviewFields: payload,
          })
        : Object.assign({}, state, {
            columns: defaultDealOverviewFields,
            columnsLoading: false,
          });
    case types.DEAL_OVERVIEW_FIELDS_FAILURE:
      return Object.assign({}, state, {
        dealOverviewFieldsLoading: false,
        dealOverviewFieldsError: payload.message,
      });

    case types.UPDATE_DEAL_OVERVIEW_FIELDS_REQUEST:
      return Object.assign({}, state, {
        dealOverviewFields: payload,
        dealOverviewFieldsLoading: true,
      });
    case types.UPDATE_DEAL_OVERVIEW_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        dealOverviewFields: payload,
        dealOverviewFieldsLoading: false,
      });
    case types.UPDATE_DEAL_OVERVIEW_FIELDS_FAILURE:
      return Object.assign({}, state, {
        dealOverviewFieldsLoading: false,
        dealOverviewFieldsError: payload.message,
      });

    case types.LOAD_SHARE_DEAL_TEMPLATES_REQUEST:
      return Object.assign({},state,{
        shareDealTemplatesLoading:true,
        shareDealTemplatesError:null
      })  
    case types.LOAD_SHARE_DEAL_TEMPLATES_SUCCESS:
      return Object.assign({},state,{
        shareDealTemplatesLoading:false,
        shareDealTemplates:payload.data,
        shareDealTemplatesError:null
      })  
    case types.LOAD_SHARE_DEAL_TEMPLATES_FAILURE:
      return Object.assign({},state,{
        shareDealTemplatesLoading:false,
        shareDealTemplatesError:payload
      })  

    case types.UPDATE_SHARE_DEAL_TEMPLATE_NAME_REQUEST:
      return Object.assign({},state,{
        shareDealTemplatesError:null
      })  
    case types.UPDATE_SHARE_DEAL_TEMPLATE_NAME_SUCCESS:
      let updatedTemplates = state.shareDealTemplates.map(template => {
        if (template.id === payload.id) {
          return { ...template, name: payload.newName }; 
        }
        return template;
      });
    
      return Object.assign({},state,{
        shareDealTemplates:updatedTemplates,
        shareDealTemplatesError:null

      })  
    case types.UPDATE_SHARE_DEAL_TEMPLATE_NAME_FAILURE:
      return Object.assign({},state,{
        shareDealTemplatesError:payload
      })   
      
    case types.DELETE_SHARE_DEAL_TEMPLATE_REQUEST:
      return Object.assign({},state,{
        shareDealTemplatesError:null
      })  
    case types.DELETE_SHARE_DEAL_TEMPLATE_SUCCESS:
      let _updatedTemplates = state.shareDealTemplates.filter(template => template.id !== payload.id)
      return Object.assign({},state,{
        shareDealTemplates:_updatedTemplates,
        shareDealTemplatesError:null

      })  
    case types.DELETE_SHARE_DEAL_TEMPLATE_FAILURE:
      return Object.assign({},state,{
        shareDealTemplatesError:payload
      })    

    default:
      return state;
  }
};
