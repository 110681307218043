/**
 * @format
 */

import * as types from '../actions';

import { assign } from '../../../helpers/object';

import reduce from 'lodash.reduce';

export default (state, type, payload) => {
  switch (type) {
    case types.ADD_NAV_UPDATES_REQUEST: {
      return assign(state, {
        addNavUpdatesLoading: true,
      });
    }
    case types.ADD_NAV_UPDATES_FAILURE: {
      return assign(state, {
        addNavUpdatesLoading: false,
      });
    }
    case types.ADD_NAV_UPDATES_SUCCESS: {
      const newNavUpdates = reduce(
        payload.data,
        (acc, result) => {
          if (result.success) {
            // only successful results end with NAV update getting added
            const newNavUpdate = result.data;
            acc[newNavUpdate.id] = newNavUpdate;
          }
          return acc;
        },
        {}
      );
      return assign(state, {
        addNavUpdatesLoading: false,
        // merge existing and new
        navUpdate: assign(state.navUpdate, newNavUpdates),
      });
    }

    default:
      return state;
  }
};
