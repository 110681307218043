import * as types from './actions';

const initialState = {
  /**
   * Holds Conversation List, key here is - portco id
   */
  portfolioConversations: {},
  portfolioConversationsLoading: {},
  portfolioConversationsError: {},
  portfolioConversationsOptions: {},
  portfolioConversationsMore: {},

  /**
   * Holds individual conversation of each portfolioConversations(key is portco id)
   * and key is - conversation id.
   *
   * TODO:
   * https://gitlab.com/taghash/valence-v2/merge_requests/109#note_118195673
   * And corresponding issue -
   * https://gitlab.com/taghash/nucleus/issues/660
   */
  conversation: {},
  conversationLoading: {},
  conversationError: {},

  /**
   * Key is - conversation id.
   */
  addReplyLoading: {},
  addReplyError: {},

  /**
   * Key is - portco id
   */
  createConversationLoading: {},
  createConversationError: {},

  /**
   * Holds participants of each conversation, key is - conversation id.
   */
  conversationParticipantList: {},
  conversationParticipantListLoading: {},
  conversationParticipantListError: {},

  /**
   * Key is - conversation id.
   */
  deleteConversationLoading: {},
  deleteConversationError: {},

  /**
   * Key for starring email conversation
   * 
   */
  portcoConversationStarredLoading:{},
  portcoConversationStarredError:{},
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
     * Conversation list reducers
     */
    case types.LOAD_CONVERSATION_LIST_REQUEST:
      return Object.assign({}, state, {
        portfolioConversationsLoading: {
          ...state.portfolioConversationsLoading,
          [payload.portcoId]: true
        },
        portfolioConversationsError: {
          ...state.portfolioConversationsError,
          [payload.portcoId]: null
        }
      });
    case types.LOAD_CONVERSATION_LIST_SUCCESS:
      return Object.assign({}, state, {
        portfolioConversations: {
          ...state.portfolioConversations,
          [payload.portcoId]: (
            (payload.options.page === 1) ?
              payload.data : [...state.portfolioConversations[payload.portcoId], ...payload.data]
          ).map((conversation) => {conversation.participants = conversation.participantsAndLevels;
          return conversation})},
        portfolioConversationsLoading: {
          ...state.portfolioConversationsLoading,
          [payload.portcoId]: false
        },
        portfolioConversationsError: {
          ...state.portfolioConversationsError,
          [payload.portcoId]: null
        },
        portfolioConversationsOptions: {
          ...state.portfolioConversationsOptions,
          [payload.portcoId]: payload.options
        },
        portfolioConversationsMore: {
          ...state.portfolioConversationsMore,
          [payload.portcoId]: (payload.data.length >= payload.options.limit ? true : false)
        },
        conversationParticipantList: {
          ...state.conversationParticipantList,
          // only if withParticipants option is true, participants would have
          // been returned in the api
          ...(payload.data && payload.options.withParticipants
            ? payload.data.reduce((acc, conversation) => {
              if(conversation.participantsAndLevels && conversation.participantsAndLevels.length >0){
                acc[conversation.id] = conversation.participantsAndLevels;
              }
              else{
                acc[conversation.id] = conversation.participants;
              }
              return acc;
            }, {})
            : {})
        }
      });
    case types.LOAD_CONVERSATION_LIST_FAILURE:
      return Object.assign({}, state, {
        portfolioConversationsLoading: {
          ...state.portfolioConversationsLoading,
          [payload.portcoId]: false
        },
        portfolioConversationsError: {
          ...state.portfolioConversationsError,
          [payload.portcoId]: payload.message
        },
      });

    /**
     * Conversation reducer
     */
    case types.LOAD_CONVERSATION_REQUEST:
      return Object.assign({}, state, {
        conversationLoading: {
          ...state.conversationLoading,
          [payload.conversationId]: true
        },
        conversationError: {
          ...state.conversationError,
          [payload.conversationId]: null
        }
      });
    case types.LOAD_CONVERSATION_SUCCESS:
      return Object.assign({}, state, {
        conversation: {
          ...state.conversation,
          [payload.conversationId]: payload.data
        },
        conversationLoading: {
          ...state.conversationLoading,
          [payload.conversationId]: false
        },
        conversationError: {
          ...state.conversationError,
          [payload.conversationId]: null
        }
      });
    case types.LOAD_CONVERSATION_FAILURE:
      return Object.assign({}, state, {
        conversationLoading: {
          ...state.conversationLoading,
          [payload.conversationId]: false
        },
        conversationError: {
          ...state.conversationError,
          [payload.conversationId]: payload.message
        },
      });

    /**
     * Conversation participant list reducer
     */
    case types.LOAD_PARTICIPANT_LIST_REQUEST:
      return Object.assign({}, state, {
        conversationParticipantListLoading: {
          ...state.conversationParticipantListLoading,
          [payload.conversationId]: true,
        },
        conversationParticipantListError: {
          ...state.conversationParticipantListError,
          [payload.conversationId]:null
        }
      });
    case types.LOAD_PARTICIPANT_LIST_SUCCESS:
      return Object.assign({}, state, {
        conversationParticipantList: {
          ...state.conversationParticipantList,
          [payload.conversationId]: payload.data
        },
        conversationParticipantListLoading: {
          ...state.conversationParticipantListLoading,
          [payload.conversationId]: false
        },
        conversationParticipantListError: {
          ...state.conversationParticipantListError,
          [payload.conversationId]: null
        }
      });
    case types.LOAD_PARTICIPANT_LIST_FAILURE:
      return Object.assign({}, state, {
        conversationParticipantListLoading: {
          ...state.conversationParticipantListLoading,
          [payload.conversationId]: false
        },
        conversationParticipantListError: {
          ...state.conversationParticipantListError,
          [payload.conversationId]: payload.message
        }
      });

    /**
     * Portfolio conversation starring reducer
     */
    case types.TOGGLE_CONVERSATION_AS_STARRED_REQUEST:
        return Object.assign({}, state, {
          portcoConversationStarredLoading:{
            ...state.portcoConversationStarredLoading,
            [payload.conversationId]: true
          },
          portfolioConversationsStarredError:{
            ...state.portcoConversationStarredError,
            [payload.conversationId]: null
          }
        })

    case types.TOGGLE_CONVERSATION_AS_STARRED_FAILURE:
      return Object.assign({},state,{
        portcoConversationStarredLoading:{
          ...state.portcoConversationStarredLoading,
          [payload.conversationId]: false
        },
        portfolioConversationsStarredError:{
          ...state.portcoConversationStarredError,
          [payload.conversationId]: payload.message
        }
      })

    case types.TOGGLE_CONVERSATION_AS_STARRED_SUCCESS:
      return Object.assign({}, state, {
        portcoConversationStarredLoading:{
          ...state.portcoConversationStarredLoading,
          [payload.conversationId]: false
        },
        portfolioConversationsStarredError:{
          ...state.portcoConversationStarredError,
          [payload.conversationId]: null
        },
        portfolioConversations:{
          ...state.portfolioConversations,
          //All convvos in this portco
          [payload.portcoId]:[
            ...(state.portfolioConversations[payload.portcoId] || []).map(convo  => {
              if(convo.id === payload.conversationId){
                convo.is_starred = payload.is_starred;
              }
              return convo
            }),
          ]
        },

        conversation: {
          ...state.conversation,
          [payload.conversationId]: {
            ...state.conversation[payload.conversationId],
            is_starred: payload.is_starred
          }
        },
      })
    /**
     * Create conversation reducer
     */
    case types.CREATE_CONVERSATION_REQUEST:
      return Object.assign({}, state, {
        createConversationLoading: {
          ...state.createConversationLoading,
          [payload.portcoId]: true
        },
        createConversationError: {
          ...state.createConversationError,
          [payload.portcoId]: null
        }
      });
    case types.CREATE_CONVERSATION_SUCCESS:
      return Object.assign({}, state, {
        createConversationLoading: {
          ...state.createConversationLoading,
          [payload.portcoId]: false
        },
        portfolioConversations: {
          ...state.portfolioConversations,
          [payload.portcoId]: [payload.data,
            ...(state.portfolioConversations[payload.portcoId] ? state.portfolioConversations[payload.portcoId] : [])
            ]
          },
        createConversationError: {
          ...state.createConversationError,
          [payload.portcoId]: null
        }
      });
    case types.CREATE_CONVERSATION_FAILURE:
      return Object.assign({}, state, {
        createConversationLoading: {
          ...state.createConversationLoading,
          [payload.portcoId]: false
        },
        createConversationError: {
          ...state.createConversationError,
          [payload.portcoId]: payload.message
        }
      });

    /**
     * Delete conversation reducer
     */
    case types.DELETE_CONVERSATION_REQUEST:
      return Object.assign({}, state, {
        deleteConversationLoading: {
          ...state.deleteConversationLoading,
          [payload.conversationId]: true
        },
        deleteConversationError: {
          ...state.deleteConversationError,
          [payload.conversationId]: null
        }
      });
    case types.DELETE_CONVERSATION_SUCCESS:
      return Object.assign({}, state, {
        deleteConversationLoading: {
          ...state.deleteConversationLoading,
          [payload.conversationId]: false
        },
        deleteConversationError: {
          ...state.deleteConversationError,
          [payload.conversationId]: null
        },
      });
    case types.DELETE_CONVERSATION_FAILURE:
      return Object.assign({}, state, {
        deleteConversationLoading: {
          ...state.deleteConversationLoading,
          [payload.conversationId]: false
        },
        deleteConversationError: {
          ...state.deleteConversationError,
          [payload.conversationId]: payload.message
        }
      });

    default:
      return state;
  }
};
