/**
 * @format
 */

import * as types from '../actions';

import { assign } from '../../../helpers/object';
import reduce from 'lodash.reduce';

export default (state, type, payload) => {
  switch (type) {
    case types.ADD_DRAWDOWNS_REQUEST: {
      return assign(state, {
        addDrawdownsLoading: true,
      });
    }
    case types.ADD_DRAWDOWNS_FAILURE: {
      return assign(state, {
        addDrawdownsLoading: false,
      });
    }
    case types.ADD_DRAWDOWNS_SUCCESS: {
      const newDrawdowns = reduce(
        payload.data,
        (acc, result) => {
          if (result.success) {
            // only successful results end with drawdown getting added
            const newDrawdown = result.data;
            acc[newDrawdown.id] = newDrawdown;
          }
          return acc;
        },
        {}
      );
      return assign(state, {
        addDrawdownsLoading: false,
        // merge existing and new
        drawdown: assign(state.drawdown, newDrawdowns),
      });
    }

    default:
      return state;
  }
};
