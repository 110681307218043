/**
 * `auth` actions
 *
 * @author  Ritesh Shrivastav
 *
 */
export const LOAD_USER_REQUEST = 'auth/LOAD_USER_REQUEST';
export const LOAD_USER_SUCCESS = 'auth/LOAD_USER_SUCCESS';
export const LOAD_USER_ERROR = 'auth/LOAD_USER_ERROR';

export const SIGNUP_USER_REQUEST = 'auth/SIGNUP_USER_REQUEST';
export const SIGNUP_USER_SUCCESS = 'auth/SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'auth/SIGNUP_USER_FAILURE';

export const LOGIN_USER_REQUEST = 'auth/LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'auth/LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'auth/LOGIN_USER_FAILURE';

export const LOGOUT_USER_REQUEST = 'auth/LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'auth/LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'auth/LOGOUT_USER_FAILURE';

export const LOGOUT_USER_CLEAR_REDUX = 'auth/LOGOUT_USER_CLEAR_REDUX'

export const LOAD_INVITERDATA_REQUEST = 'auth/LOAD_INVITERDATA_REQUEST';
export const LOAD_INVITERDATA_SUCCESS = 'auth/LOAD_INVITERDATA_SUCCESS';
export const LOAD_INVITERDATA_FAILURE = 'auth/LOAD_INVITERDATA_FAILURE';

export const RESET_PASSWORD_INIT_REQUEST = 'auth/RESET_PASSWORD_INIT_REQUEST';
export const RESET_PASSWORD_INIT_SUCCESS = 'auth/RESET_PASSWORD_INIT_SUCCESS';
export const RESET_PASSWORD_INIT_FAILURE = 'auth/RESET_PASSWORD_INIT_FAILURE';

export const RESET_PASSWORD_DATA_REQUEST = 'auth/RESET_PASSWORD_DATA_REQUEST';
export const RESET_PASSWORD_DATA_SUCCESS = 'auth/RESET_PASSWORD_DATA_SUCCESS';
export const RESET_PASSWORD_DATA_FAILURE = 'auth/RESET_PASSWORD_DATA_FAILURE';

export const RESET_PASSWORD_FINISH_REQUEST = 'auth/RESET_PASSWORD_FINISH_REQUEST';
export const RESET_PASSWORD_FINISH_SUCCESS = 'auth/RESET_PASSWORD_FINISH_SUCCESS';
export const RESET_PASSWORD_FINISH_FAILURE = 'auth/RESET_PASSWORD_FINISH_FAILURE';

export const GOOGLE_SETTINGS_FOR_LOGIN_REQUEST = 'auth/GOOGLE_SETTINGS_FOR_LOGIN_REQUEST';
export const GOOGLE_SETTINGS_FOR_LOGIN_SUCCESS = 'auth/GOOGLE_SETTINGS_FOR_LOGIN_SUCCESS';
export const GOOGLE_SETTINGS_FOR_LOGIN_FAILURE = 'auth/GOOGLE_SETTINGS_FOR_LOGIN_FAILURE';

export const MICROSOFT_SETTINGS_FOR_LOGIN_REQUEST = 'auth/MICROSOFT_SETTINGS_FOR_LOGIN_REQUEST';
export const MICROSOFT_SETTINGS_FOR_LOGIN_SUCCESS = 'auth/MICROSOFT_SETTINGS_FOR_LOGIN_SUCCESS';
export const MICROSOFT_SETTINGS_FOR_LOGIN_FAILURE = 'auth/MICROSOFT_SETTINGS_FOR_LOGIN_FAILURE';

export const OAUTH2_AUTHORIZATION_INIT_REQUEST = 'auth/OAUTH2_AUTHORIZATION_INIT_REQUEST';
export const OAUTH2_AUTHORIZATION_INIT_SUCCESS = 'auth/OAUTH2_AUTHORIZATION_INIT_SUCCESS';
export const OAUTH2_AUTHORIZATION_INIT_FAILURE = 'auth/OAUTH2_AUTHORIZATION_INIT_FAILURE';

export const OAUTH2_AUTHORIZATION_FINISH_REQUEST = 'auth/OAUTH2_AUTHORIZATION_FINISH_REQUEST';

