
export const CREATE_FUND_DOCUMENT_REQUEST = 'fund-document/CREATE_FUND_DOCUMENT_REQUEST';
export const CREATE_FUND_DOCUMENT_SUCCESS = 'fund-document/CREATE_FUND_DOCUMENT_SUCCESS';
export const CREATE_FUND_DOCUMENT_FAILURE = 'fund-document/CREATE_FUND_DOCUMENT_FAILURE';

export const LIST_FUND_DOCUMENTS_REQUEST = 'fund-document/LIST_FUND_DOCUMENTS_REQUEST';
export const LIST_FUND_DOCUMENTS_SUCCESS = 'fund-document/LIST_FUND_DOCUMENTS_SUCCESS';
export const LIST_FUND_DOCUMENTS_FAILURE = 'fund-document/LIST_FUND_DOCUMENTS_FAILURE';

export const GET_FUND_DOCUMENT_SIGNEDURL_REQUEST = 'fund-document/GET_FUND_DOCUMENT_SIGNEDURL_REQUEST';
export const GET_FUND_DOCUMENT_SIGNEDURL_SUCCESS = 'fund-document/GET_FUND_DOCUMENT_SIGNEDURL_SUCCESS';
export const GET_FUND_DOCUMENT_SIGNEDURL_FAILURE = 'fund-document/GET_FUND_DOCUMENT_SIGNEDURL_FAILURE';

export const ADD_TAG_TO_FUND_DOCUMENT_REQUEST = 'fund-document/ADD_TAG_TO_FUND_DOCUMENT_REQUEST';
export const ADD_TAG_TO_FUND_DOCUMENT_SUCCESS = 'fund-document/ADD_TAG_TO_FUND_DOCUMENT_SUCCESS';
export const ADD_TAG_TO_FUND_DOCUMENT_FAILURE = 'fund-document/ADD_TAG_TO_FUND_DOCUMENT_FAILURE';

export const REMOVE_TAG_FROM_FUND_DOCUMENT_REQUEST = 'fund-document/REMOVE_TAG_FROM_FUND_DOCUMENT_REQUEST';
export const REMOVE_TAG_FROM_FUND_DOCUMENT_SUCCESS = 'fund-document/REMOVE_TAG_FROM_FUND_DOCUMENT_SUCCESS';
export const REMOVE_TAG_FROM_FUND_DOCUMENT_FAILURE = 'fund-document/REMOVE_TAG_FROM_FUND_DOCUMENT_FAILURE';

export const DELETE_FUND_DOCUMENT_REQUEST = 'fund-document/DELETE_FUND_DOCUMENT_REQUEST';
export const DELETE_FUND_DOCUMENT_SUCCESS = 'fund-document/DELETE_FUND_DOCUMENT_SUCCESS';
export const DELETE_FUND_DOCUMENT_FAILURE = 'fund-document/DELETE_FUND_DOCUMENT_FAILURE';

export const HIDE_OR_UNHIDE_FUND_DOCUMENT_REQUEST = 'fund-document/HIDE_OR_UNHIDE_FUND_DOCUMENT_REQUEST';
export const HIDE_OR_UNHIDE_FUND_DOCUMENT_SUCCESS = 'fund-document/HIDE_OR_UNHIDE_FUND_DOCUMENT_SUCCESS';
export const HIDE_OR_UNHIDE_FUND_DOCUMENT_FAILURE = 'fund-document/HIDE_OR_UNHIDE_FUND_DOCUMENT_FAILURE';

export const LIST_FUND_DOCUMENT_TAGS_REQUEST = 'fund-document/LIST_FUND_DOCUMENT_TAGS_REQUEST';
export const LIST_FUND_DOCUMENT_TAGS_SUCCESS = 'fund-document/LIST_FUND_DOCUMENT_TAGS_SUCCESS';
export const LIST_FUND_DOCUMENT_TAGS_FAILURE = 'fund-document/LIST_FUND_DOCUMENT_TAGS_FAILURE';

export const FILE_UPLOAD_S3_REQUEST = 'fund-document/FILE_UPLOAD_S3_REQUEST';
export const FILE_UPLOAD_S3_SUCCESS = 'fund-document/FILE_UPLOAD_S3_SUCCESS';
export const FILE_UPLOAD_S3_FAILURE = 'fund-document/FILE_UPLOAD_S3_FAILURE';

export const FILE_DOWNLOAD_S3_REQUEST = 'fund-document/FILE_DOWNLOAD_S3_REQUEST';
export const FILE_DOWNLOAD_S3_SUCCESS = 'fund-document/FILE_DOWNLOAD_S3_SUCCESS';
export const FILE_DOWNLOAD_S3_FAILURE = 'fund-document/FILE_DOWNLOAD_S3_FAILURE';

export const CLEAR = 'fund-document/CLEAR';
