
export const ADD_ORGANIZATION_CONTACT_NOTE_REQUEST = 'organization-contact-note/ADD_ORGANIZATION_CONTACT_NOTE_REQUEST';
export const ADD_ORGANIZATION_CONTACT_NOTE_SUCCESS = 'organization-contact-note/ADD_ORGANIZATION_CONTACT_NOTE_SUCCESS';
export const ADD_ORGANIZATION_CONTACT_NOTE_FAILURE = 'organization-contact-note/ADD_ORGANIZATION_CONTACT_NOTE_FAILURE';

export const UPDATE_ORGANIZATION_CONTACT_NOTE_REQUEST = 'organization-contact-note/UPDATE_ORGANIZATION_CONTACT_NOTE_REQUEST';
export const UPDATE_ORGANIZATION_CONTACT_NOTE_SUCCESS = 'organization-contact-note/UPDATE_ORGANIZATION_CONTACT_NOTE_SUCCESS';
export const UPDATE_ORGANIZATION_CONTACT_NOTE_FAILURE = 'organization-contact-note/UPDATE_ORGANIZATION_CONTACT_NOTE_FAILURE';

export const LIST_ORGANIZATION_CONTACT_NOTES_REQUEST = 'organization-contact-note/LIST_ORGANIZATION_CONTACT_NOTES_REQUEST';
export const LIST_ORGANIZATION_CONTACT_NOTES_SUCCESS = 'organization-contact-note/LIST_ORGANIZATION_CONTACT_NOTES_SUCCESS';
export const LIST_ORGANIZATION_CONTACT_NOTES_FAILURE = 'organization-contact-note/LIST_ORGANIZATION_CONTACT_NOTES_FAILURE';

export const GET_ORGANIZATION_CONTACT_NOTE_REQUEST = 'organization-contact-note/GET_ORGANIZATION_CONTACT_NOTE_REQUEST';
export const GET_ORGANIZATION_CONTACT_NOTE_SUCCESS = 'organization-contact-note/GET_ORGANIZATION_CONTACT_NOTE_SUCCESS';
export const GET_ORGANIZATION_CONTACT_NOTE_FAILURE = 'organization-contact-note/GET_ORGANIZATION_CONTACT_NOTE_FAILURE';

export const GET_PINNED_ORGANIZATION_CONTACT_NOTE_REQUEST = 'organization-contact-note/GET_PINNED_ORGANIZATION_CONTACT_NOTE_REQUEST';
export const GET_PINNED_ORGANIZATION_CONTACT_NOTE_SUCCESS = 'organization-contact-note/GET_PINNED_ORGANIZATION_CONTACT_NOTE_SUCCESS';
export const GET_PINNED_ORGANIZATION_CONTACT_NOTE_FAILURE = 'organization-contact-note/GET_PINNED_ORGANIZATION_CONTACT_NOTE_FAILURE';

export const DELETE_ORGANIZATION_CONTACT_NOTE_REQUEST = 'organization-contact-note/DELETE_ORGANIZATION_CONTACT_NOTE_REQUEST';
export const DELETE_ORGANIZATION_CONTACT_NOTE_SUCCESS = 'organization-contact-note/DELETE_ORGANIZATION_CONTACT_NOTE_SUCCESS';
export const DELETE_ORGANIZATION_CONTACT_NOTE_FAILURE = 'organization-contact-note/DELETE_ORGANIZATION_CONTACT_NOTE_FAILURE';

export const LOAD_NOTE_REPLY_LIST_REQUEST ='organization-contact-note/LOAD_NOTE_REPLY_LIST_REQUEST'
export const LOAD_NOTE_REPLY_LIST_SUCCESS ='organization-contact-note/LOAD_NOTE_REPLY_LIST_SUCCESS'
export const LOAD_NOTE_REPLY_LIST_FAILURE ='organization-contact-note/LOAD_NOTE_REPLY_LIST_FAILURE'

export const LOAD_NOTE_REPLY_COUNT_REQUEST ='organization-contact-note/LOAD_NOTE_REPLY_COUNT_REQUEST'
export const LOAD_NOTE_REPLY_COUNT_SUCCESS ='organization-contact-note/LOAD_NOTE_REPLY_COUNT_SUCCESS'
export const LOAD_NOTE_REPLY_COUNT_FAILURE ='organization-contact-note/LOAD_NOTE_REPLY_COUNT_FAILURE'