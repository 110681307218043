/**
 * @format
 */
import {
  LOAD_DEALS_RESOURCE_COUNT_REQUEST,
  LOAD_DEALS_RESOURCE_COUNT_SUCCESS,
  LOAD_DEALS_RESOURCE_COUNT_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case LOAD_DEALS_RESOURCE_COUNT_REQUEST:
      return Object.assign({}, state, {
        dealsResourceCountLoading: true,
        dealResourceCountError: null,
      });
    case LOAD_DEALS_RESOURCE_COUNT_SUCCESS:
      let dealRourceCountWithOldValues = {};
      Object.keys(payload.data).map(dealId => {
        dealRourceCountWithOldValues[dealId] = {
          ...state.dealsResourceCount[dealId],
          ...payload.data[dealId],
        };
        return null;
      });
      return Object.assign({}, state, {
        dealsResourceCount: {
          ...state.dealsResourceCount,
          ...dealRourceCountWithOldValues,
        },
        dealsResourceCountLoading: false,
        dealResourceCountError: null,
      });
    case LOAD_DEALS_RESOURCE_COUNT_FAILURE:
      return Object.assign({}, state, {
        dealsResourceCountLoading: false,
        dealResourceCountError: payload.message,
      });
    default:
      return state;
  }
};
