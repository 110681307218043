/**
 * ADD_ASSIGNEE reducer
 * 
 * @author Ritesh Shrivastav
 */
import {
  ADD_ASSIGNEE_REQUEST,
  ADD_ASSIGNEE_SUCCESS,
  ADD_ASSIGNEE_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case ADD_ASSIGNEE_REQUEST:
      return Object.assign({}, state, {
        addAssigneeLoading: {
          ...state.addAssigneeLoading,
          [payload.dealId]: true
        },
        addAssigneeError: {
          ...state.addAssigneeError,
          [payload.dealId]: null
        }
      });

    case ADD_ASSIGNEE_SUCCESS:
      const copyOfAddAssigneeState = Object.assign({}, state, {
        addAssigneeLoading: {
          ...state.addAssigneeLoading,
        },
        addAssigneeError: {
          ...state.addAssigneeError,
        },
      });

      delete copyOfAddAssigneeState.addAssigneeLoading[payload.dealId];
      delete copyOfAddAssigneeState.addAssigneeError[payload.dealId];
      return copyOfAddAssigneeState;

    case ADD_ASSIGNEE_FAILURE:
      return Object.assign({}, state, {
        addAssigneeLoading: {
          ...state.addAssigneeLoading,
          [payload.dealId]: false
        },
        addAssigneeError: {
          ...state.addAssigneeError,
          [payload.dealId]: payload.message
        },
      });

    default:
      return state;
  }
};
