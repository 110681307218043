/**
 * Checks on breakponts by calculating the device width.
 *
 * @author Akhila
 */
// Breakpoint ranges that match with fixed screen width taken from
// below link:
// https://material-ui.com/layout/breakpoints/#breakpoints
const FIXED_BREAKPOINTS = {
  'xs': {
    min: 0,
    max: 599,
  },
  'sm': {
    min: 600,
    max: 959,
  },
  'md': {
    min: 960,
    max: 1279,
  },
  'lg': {
    min: 1280,
    max: 1919,
  },
  'xl': {
    min: 1920,
    max: 10000
  }
};

/**
 * Used to find the current Device Width
 * @return {number} Current browser width
 */
const getDeviceWidth = () => {
  // In tests document and window object will not be available, this check is handling that.
  if (!document || !window) return 1024;
  return window.innerWidth || document.body.clientWidth;
};

/**
 * Checks if the device width is higher or equals to the breakpoint.
 * @param  {String} breakpoint Values like 'lg', 'sm', 'xs' etc.
 * @return {bool}
 */
const up = (breakpoint) => {
  if (!breakpoint) {
    throw new Error('No argument or invalid argument');
  }
  if (!FIXED_BREAKPOINTS.hasOwnProperty(breakpoint)) {
    throw new Error('Invalid argument type');
  }
  let deviceWidth = getDeviceWidth();
  return (deviceWidth >= FIXED_BREAKPOINTS[breakpoint].min);
};

/**
 * Checks if the device width is smaller or equals to the breakpoint.
 * @param {String} breakpoint  Values like 'lg', 'sm', 'xs' etc.
 * @return {bool}
 */
const down = (breakpoint) => {
  if (!breakpoint) {
    throw new Error('No argument or invalid argument');
  }
  if (!FIXED_BREAKPOINTS.hasOwnProperty(breakpoint)) {
    throw new Error('Invalid argument type');
  }
  let deviceWidth = getDeviceWidth();
  return (deviceWidth <= FIXED_BREAKPOINTS[breakpoint].max);
};

export default {
  down,
  FIXED_BREAKPOINTS,
  getDeviceWidth,
  up,
};
