/**
 * @format
 * Add deal reducer handler
 */
import {
  DELETE_DEAL_FAILURE,
  DELETE_DEAL_REQUEST,
  DELETE_DEAL_SUCCESS,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case DELETE_DEAL_REQUEST:
      return Object.assign({}, state, {
        deleteDealLoading: true,
        deleteDealError: null,
      });

    case DELETE_DEAL_SUCCESS:
      const dealsFiltered = Object.keys(state.deals)
        .filter(dealId => dealId !== payload.dealId)
        .reduce((obj, item) => {
          obj[item] = state.deals[item];
          return obj;
        }, {});
      return Object.assign({}, state, {
        deals: {
          ...dealsFiltered,
        },
        deleteDealLoading: false,
        deleteDealError: null,
      });

    case DELETE_DEAL_FAILURE:
      return Object.assign({}, state, {
        deleteDealLoading: false,
        deleteDealError: payload.message,
      });

    default:
      return state;
  }
};
