/**
 * @format
 */
import * as types from './actions';
import initialState from './initialState';

import addPortCo from './reducerHandlers/addPortCo';
import loadPortCoList from './reducerHandlers/loadPortCoList';
import getPortCo from './reducerHandlers/getPortCo';
import loadCategories from './reducerHandlers/loadCategories';
import addMultipleStartupFounders from './reducerHandlers/addMultipleStartupFounders';
import removeStartupFounder from './reducerHandlers/removeStartupFounder';
import deletePortco from './reducerHandlers/deletePortco';
import loadPortcoACL from './reducerHandlers/loadPortcoACL';
import updatePortCo from './reducerHandlers/updatePortCo';
import openManageColumn from './reducerHandlers/openManageColumn';
import closeManageColumn from './reducerHandlers/closeManageColumn';
import updatePortfolioTableColumns from './reducerHandlers/updatePortfolioTableColumns';
import loadPortcoCompanies from './reducerHandlers/loadPortcoCompanies';
import loadPortfolioTableColumns from './reducerHandlers/loadPortfolioTableColumns';
import setFilters from './reducerHandlers/setFilters';
import loadCustomFields from './reducerHandlers/loadCustomFields';
import updateCustomFields from './reducerHandlers/updateCustomFields';
import removeCustomFields from './reducerHandlers/removeCustomFields';
import addCustomField from './reducerHandlers/addCustomField';
import loadCustomFieldValues from './reducerHandlers/loadCustomFieldValues';

/**
 * Reducers
 */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * ADD_PORTCO reducers
     */
    case types.ADD_PORTCO_REQUEST:
    case types.ADD_PORTCO_SUCCESS:
    case types.ADD_PORTCO_FAILURE:
      return addPortCo(state, type, payload);

    /**
     * UPDATE_PORTCO reducers
     */
    case types.UPDATE_PORTCO_REQUEST:
    case types.UPDATE_PORTCO_SUCCESS:
    case types.UPDATE_PORTCO_FAILURE:
      return updatePortCo(state, type, payload);

    /**
     * LOAD_PORTCO_LIST reducers
     */
    case types.LOAD_PORTCO_LIST_REQUEST:
    case types.LOAD_PORTCO_LIST_SUCCESS:
    case types.LOAD_PORTCO_LIST_FAILURE:
      return loadPortCoList(state, type, payload);

    /**
     * GET_PORTCO reducers
     */
    case types.GET_PORTCO_REQUEST:
    case types.GET_PORTCO_SUCCESS:
    case types.GET_PORTCO_FAILURE:
      return getPortCo(state, type, payload);

    /**
     * LOAD_PORTCO_COMPANIES reducer
     */
    case types.LOAD_PORTCO_COMPANIES_REQUEST:
    case types.LOAD_PORTCO_COMPANIES_SUCCESS:
    case types.LOAD_PORTCO_COMPANIES_FAILURE:
      return loadPortcoCompanies(state, type, payload);

    /**
     * LOAD_CATEGORIES reducers
     */
    case types.LOAD_CATEGORIES_REQUEST:
    case types.LOAD_CATEGORIES_SUCCESS:
    case types.LOAD_CATEGORIES_FAILURE:
      return loadCategories(state, type, payload);

    /**
     * ADD_MULTIPLE_STARTUP_FOUNDERS reducers
     */
    case types.ADD_MULTIPLE_STARTUP_FOUNDERS_REQUEST:
    case types.ADD_MULTIPLE_STARTUP_FOUNDERS_SUCCESS:
    case types.ADD_MULTIPLE_STARTUP_FOUNDERS_FAILURE:
      return addMultipleStartupFounders(state, type, payload);

    /**
     * REMOVE_STARTUP_FOUNDER reducers
     */
    case types.REMOVE_STARTUP_FOUNDER_REQUEST:
    case types.REMOVE_STARTUP_FOUNDER_SUCCESS:
    case types.REMOVE_STARTUP_FOUNDER_FAILURE:
      return removeStartupFounder(state, type, payload);

    /**
     * DELETE_PORTCO reducers
     */
    case types.DELETE_PORTCO_REQUEST:
    case types.DELETE_PORTCO_SUCCESS:
    case types.DELETE_PORTCO_FAILURE:
      return deletePortco(state, type, payload);

    /**
     * LOAD_PORTCO_ACL reducers
     */
    case types.LOAD_PORTCO_ACL_REQUEST:
    case types.LOAD_PORTCO_ACL_SUCCESS:
    case types.LOAD_PORTCO_ACL_FAILURE:
      return loadPortcoACL(state, type, payload);

    case types.LOAD_PORTFOLIO_TABLE_COLUMNS_REQUEST:
    case types.LOAD_PORTFOLIO_TABLE_COLUMNS_SUCCESS:
    case types.LOAD_PORTFOLIO_TABLE_COLUMNS_FAILURE:
      return loadPortfolioTableColumns(state, type, payload);

    case types.UPDATE_PORTFOLIO_TABLE_COLUMNS_REQUEST:
    case types.UPDATE_PORTFOLIO_TABLE_COLUMNS_SUCCESS:
    case types.UPDATE_PORTFOLIO_TABLE_COLUMNS_FAILURE:
      return updatePortfolioTableColumns(state, type, payload);

    case types.PORTCO_OPEN_MANAGE_COLUMN:
      return openManageColumn(state, type);

    case types.PORTCO_CLOSE_MANAGE_COLUMN:
      return closeManageColumn(state, type);

    case types.SET_PORTCO_FILTERS:
      return setFilters(state, type, payload);
    case types.ADD_CUSTOM_FIELD_REQUEST:
    case types.ADD_CUSTOM_FIELD_SUCCESS:
    case types.ADD_CUSTOM_FIELDS_FAILURE:
      return addCustomField(state, type, payload);

    case types.LOAD_CUSTOM_FIELDS_REQUEST:
    case types.LOAD_CUSTOM_FIELDS_SUCCESS:
    case types.LOAD_CUSTOM_FIELDS_FAILURE:
      return loadCustomFields(state, type, payload);

    case types.UPDATE_CUSTOM_FIELDS_REQUEST:
    case types.UPDATE_CUSTOM_FIELDS_SUCCESS:
    case types.UPDATE_CUSTOM_FIELDS_FAILURE:
      return updateCustomFields(state, type, payload);

    case types.REMOVE_CUSTOM_FIELDS_REQUEST:
    case types.REMOVE_CUSTOM_FIELDS_SUCCESS:
    case types.REMOVE_CUSTOM_FIELDS_FAILURE:
      return removeCustomFields(state, type, payload);

    case types.LOAD_CUSTOM_FIELD_VALUES_REQUEST:
    case types.LOAD_CUSTOM_FIELD_VALUES_SUCCESS:
    case types.LOAD_CUSTOM_FIELD_VALUES_FAILURE:
      return loadCustomFieldValues(state, type, payload);

    default:
      return state;
  }
};
