/**
 * CLOSE_MANAGE_COLUMN reducer
 * 
 * @author Ritesh Shrivastav
 */
import { CLOSE_MANAGE_COLUMN } from '../actions';

export default (state, type) => {
  switch(type) {
    case CLOSE_MANAGE_COLUMN:
      return Object.assign({}, state, {
        manageColumnOpen: false
      });

    default:
      return state;
  }
};