/**
 * LIST_SIGN_OFF_ACTIVITY_LOGS reducer
 * @format
 */
import initialState from '../initialState';

export default (state = initialState) => {
  return Object.assign({}, state, {
    ...initialState,
  });
};
