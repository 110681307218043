/** @format */
import * as types from './actions';
import reducers from './reducers';
import request, { ApplicationError } from '../../helpers/request';
import { enqueueNotification } from '../app';
import isEmptyString from '../../helpers/string/isEmptyString';

export default reducers;

export const createFundGeneralPartner = (fundId, inputData, profilePic) => {
  return async dispatch => {
    const url = `/api/funds/v1/funds/${fundId}/general_partner`;

    try {
      if (!fundId) {
        throw new ApplicationError(
          'Please select a Fund in which you want to add a general partner.'
        );
      }
      dispatch({
        type: types.CREATE_FUND_GENERAL_PARTNER_REQUEST,
        payload: {
          fundId,
        },
      });
      const { data } = await request.post(url, {
        ...inputData,
        profileImageFileName:
          profilePic && !isEmptyString(profilePic.name)
            ? profilePic.name
            : null,
      });

      let payload = data.data?.general_partner || {};
      if (data?.success) {
        /* if user has added a profile image for gp then upload the picture to the
         s3 bucket using the signed url that we got from the above api response */
        if (profilePic && profilePic.name) {
          await request.put(data.data?.uploadProfileUrl, profilePic);
          /* after uploading the image to s3 update the gp logo_url to the backend*/
          const { data: updatedPersonProfileData } = await request.put(
            `/api/contact/v1/people/${data?.data?.general_partner?.person_contact_id}/profile/${profilePic.name}`,
            {}
          );
          payload.person = updatedPersonProfileData.data;
        }
        dispatch({
          type: types.CREATE_FUND_GENERAL_PARTNER_SUCCESS,
          payload: {
            fundId,
            generalPartner: payload,
          },
        });
      } else {
        dispatch({
          type: types.CREATE_FUND_GENERAL_PARTNER_FAILURE,
          payload: {
            fundId,
            message: data.message,
          },
        });

        throw new Error(data.message);
      }
    } catch (err) {
      const message =
        'Something went wrong while adding a general partner, please try again.';
      dispatch(enqueueNotification(err.message || message));
    }
  };
};

export const loadGeneralPartnersOfAFund = fund_id => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LIST_FUND_GENERAL_PARTNERS_REQUEST,
        payload: {},
      });
      request
        .get(`/api/funds/v1/funds/${fund_id}/general_partners`, {}, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LIST_FUND_GENERAL_PARTNERS_SUCCESS,
              payload: data.data,
            });
            return resolve(data.data);
          } else {
            dispatch({
              type: types.LIST_FUND_GENERAL_PARTNERS_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading general partners, please try again.';
          dispatch({
            type: types.LIST_FUND_GENERAL_PARTNERS_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

export const updateFundGeneralPartner = (
  fundId,
  person_contact_id,
  general_partner_id,
  inputData,
  profilePic
) => {
  return async dispatch => {
    const url = `/api/funds/v1/funds/${fundId}/general_partner/${general_partner_id}`;

    try {
      if (!fundId) {
        throw new ApplicationError('Please provide a fund id.');
      }
      dispatch({
        type: types.EDIT_FUND_GENERAL_PARTNER_REQUEST,
        payload: {
          fundId,
        },
      });
      inputData.profilePicFileName = '';
      if (profilePic && profilePic.name) {
        // get the signedUrl to upload logo to s3
        const {
          data: profilePicUploadResponse,
        } = await request.get(
          `/api/contact/v1/people/${person_contact_id}/upload-signed-url`,
          { fileName: profilePic.name }
        );

        await request.put(
          profilePicUploadResponse?.signedUrlToUpload,
          profilePic
        );
        inputData.profilePicFileName = profilePic.name;
      }
      const { data: updateResponse } = await request.put(url, inputData);
      if (updateResponse.success) {
        dispatch({
          type: types.EDIT_FUND_GENERAL_PARTNER_SUCCESS,
          payload: {
            general_partner: updateResponse?.data,
          },
        });
      } else {
        const message =
          'Something went wrong while updating a general partner, please try again.';
        dispatch(enqueueNotification(updateResponse.message || message));
        dispatch({
          type: types.EDIT_FUND_GENERAL_PARTNER_FAILURE,
          payload: {
            general_partner_id,
          },
        });
      }
    } catch (err) {
      const message =
        'Something went wrong while updating a general partner, please try again.';
      dispatch(enqueueNotification(err.message || message));
      dispatch({
        type: types.EDIT_FUND_GENERAL_PARTNER_FAILURE,
        payload: {
          general_partner_id,
        },
      });
    }
  };
};

export const removeGeneralPartnerOfAFund = (fund_id, general_partner_id) => {
  return dispatch => {
    const url = `/api/funds/v1/funds/${fund_id}/general_partner/${general_partner_id}`;
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.REMOVE_FUND_GENERAL_PARTNER_REQUEST,
        payload: {},
      });
      request
        .del(url, {}, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.REMOVE_FUND_GENERAL_PARTNER_SUCCESS,
              payload: { general_partner_id },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.REMOVE_FUND_GENERAL_PARTNER_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while removing a general partner, please try again.';
          dispatch({
            type: types.REMOVE_FUND_GENERAL_PARTNER_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

export const createFundContactPerson = (fundId, inputData, profilePic) => {
  return async dispatch => {
    const url = `/api/funds/v1/funds/${fundId}/fund_contact_person`;

    try {
      if (!fundId) {
        throw new ApplicationError(
          'Please select a Fund in which you want to add a fund contact person.'
        );
      }
      dispatch({
        type: types.CREATE_FUND_CONTACT_PERSON_REQUEST,
        payload: {
          fundId,
        },
      });
      const { data } = await request.post(url, {
        ...inputData,
        profileImageFileName:
          profilePic && !isEmptyString(profilePic.name)
            ? profilePic.name
            : null,
      });

      let payload = data.data?.fund_contact_person || {};
      if (data?.success) {
        /* if user has added a profile image for fund contact person then upload the picture to the
         s3 bucket using the signed url that we got from the above api response */
        if (profilePic && profilePic.name) {
          await request.put(data.data?.uploadProfileUrl, profilePic);
          /* after uploading the image to s3 update the fcp logo_url to the backend*/
          const { data: updatedPersonProfileData } = await request.put(
            `/api/contact/v1/people/${data?.data?.fund_contact_person?.person_contact_id}/profile/${profilePic.name}`,
            {}
          );
          payload.person = updatedPersonProfileData.data;
        }
        dispatch({
          type: types.CREATE_FUND_CONTACT_PERSON_SUCCESS,
          payload: {
            fundId,
            fundContactPerson: payload,
          },
        });
      } else {
        dispatch({
          type: types.CREATE_FUND_CONTACT_PERSON_FAILURE,
          payload: {
            fundId,
            message: data.message,
          },
        });

        throw new Error(data.message);
      }
    } catch (err) {
      const message =
        'Something went wrong while adding a fund contact person, please try again.';
      dispatch(enqueueNotification(err.message || message));
    }
  };
};

export const loadFundContactPersons = fund_id => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LIST_FUND_CONTACT_PERSON_REQUEST,
        payload: {},
      });
      request
        .get(
          `/api/funds/v1/funds/${fund_id}/fund_contact_persons`,
          {},
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LIST_FUND_CONTACT_PERSON_SUCCESS,
              payload: data.data,
            });
            return resolve(data.data);
          } else {
            dispatch({
              type: types.LIST_FUND_CONTACT_PERSON_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading fund contact persons, please try again.';
          dispatch({
            type: types.LIST_FUND_CONTACT_PERSON_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

export const updateFundContactPerson = (
  fundId,
  person_contact_id,
  fund_contact_person_id,
  inputData,
  profilePic
) => {
  return async dispatch => {
    const url = `/api/funds/v1/funds/${fundId}/fund_contact_person/${fund_contact_person_id}`;

    try {
      if (!fundId) {
        throw new ApplicationError('Please provide a fund id.');
      }
      dispatch({
        type: types.EDIT_FUND_CONTACT_PERSON_REQUEST,
        payload: {
          fundId,
        },
      });
      inputData.profilePicFileName = '';
      if (profilePic && profilePic.name) {
        // get the signedUrl to upload logo to s3
        const {
          data: profilePicUploadResponse,
        } = await request.get(
          `/api/contact/v1/people/${person_contact_id}/upload-signed-url`,
          { fileName: profilePic.name }
        );

        await request.put(
          profilePicUploadResponse?.signedUrlToUpload,
          profilePic
        );
        inputData.profilePicFileName = profilePic.name;
      }
      const { data: updateResponse } = await request.put(url, inputData);
      if (updateResponse?.success) {
        dispatch({
          type: types.EDIT_FUND_CONTACT_PERSON_SUCCESS,
          payload: {
            fund_contact_person: updateResponse?.data,
          },
        });
      } else {
        const message =
          'Something went wrong while updating a fund contact person, please try again.';
        dispatch(enqueueNotification(updateResponse?.message || message));
        dispatch({
          type: types.EDIT_FUND_CONTACT_PERSON_FAILURE,
          payload: {
            fund_contact_person_id,
          },
        });
      }
    } catch (err) {
      const message =
        'Something went wrong while updating a fund contact person, please try again.';
      dispatch(enqueueNotification(err.message || message));
      dispatch({
        type: types.EDIT_FUND_CONTACT_PERSON_FAILURE,
        payload: {
          fund_contact_person_id,
        },
      });
    }
  };
};

export const removeFundContactPerson = (fund_id, fund_contact_person_id) => {
  return dispatch => {
    const url = `/api/funds/v1/funds/${fund_id}/fund_contact_person/${fund_contact_person_id}`;
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.REMOVE_FUND_CONTACT_PERSON_REQUEST,
        payload: {},
      });
      request
        .del(url, {}, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.REMOVE_FUND_CONTACT_PERSON_SUCCESS,
              payload: { fund_contact_person_id },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.REMOVE_FUND_CONTACT_PERSON_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while removing a fund contact person, please try again.';
          dispatch({
            type: types.REMOVE_FUND_CONTACT_PERSON_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};
