/**
 * SET_DEAL_ID_LIST reducer
 *
 * @format
 */
import { SET_DEAL_ID_LIST } from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case SET_DEAL_ID_LIST:
      return Object.assign({}, state, {
        dealsIdsList: [...payload],
      });

    default:
      return state;
  }
};
