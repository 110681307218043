/**
 * Managing notes in a organization contact
 *
 * @format
 */

import * as types from './actions';
import reducers from './reducers';
import request, { ApplicationError } from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import isEmptyString from '../../../helpers/string/isEmptyString';

export default reducers;

export const addOrganizationContactNote = (organizationContactId, form) => {
  return async dispatch => {
    dispatch({
      type: types.ADD_ORGANIZATION_CONTACT_NOTE_REQUEST,
      payload: { organizationContactId },
    });
    let data;
    try {
      const response = await request.post(
        `/api/contact-notes/v1/organization-contact/${organizationContactId}`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      let message =
        'Something went wrong while adding a note on the organization contact, please try again or contact support.';

      if (err?.response?.status === 429) {
        message = `Too fast! Try again in a couple of seconds.`;
      }
      dispatch({
        type: types.ADD_ORGANIZATION_CONTACT_NOTE_FAILURE,
        payload: {
          message,
          organizationContactId,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.ADD_ORGANIZATION_CONTACT_NOTE_SUCCESS,
        payload: {
          organizationContactId,
          data: data.data,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.ADD_ORGANIZATION_CONTACT_NOTE_FAILURE,
        payload: {
          organizationContactId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const getOrganizationContactNoteList = (
  organizationContactId,
  options
) => {
  return async dispatch => {
    dispatch({
      type: types.LIST_ORGANIZATION_CONTACT_NOTES_REQUEST,
      payload: { organizationContactId, options },
    });
    let data;
    try {
      const response = await request.get(
        `/api/contact-notes/v1/organization-contact/${organizationContactId}`,
        options,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading list of notes on the organization contact, please try again or contact support.';
      dispatch({
        type: types.LIST_ORGANIZATION_CONTACT_NOTES_FAILURE,
        payload: {
          message,
          organizationContactId,
          options,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.LIST_ORGANIZATION_CONTACT_NOTES_SUCCESS,
        payload: {
          organizationContactId,
          data: data.data,
          options,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.LIST_ORGANIZATION_CONTACT_NOTES_FAILURE,
        payload: {
          organizationContactId,
          message: data.message,
          options,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const getOrganizationContactNote = (
  noteId,
  organizationContactId,
  options
) => {
  return async dispatch => {
    dispatch({
      type: types.GET_ORGANIZATION_CONTACT_NOTE_REQUEST,
      payload: { organizationContactId, noteId, options },
    });
    let data;
    try {
      const response = await request.get(
        `/api/contact-notes/v1/organization-contact/${organizationContactId}/note/${noteId}`,
        options,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading a note, please try again or contact support.';
      dispatch({
        type: types.GET_ORGANIZATION_CONTACT_NOTE_FAILURE,
        payload: {
          message,
          organizationContactId,
          noteId,
          options,
        },
      });
      dispatch(enqueueNotification(message));
      throw new ApplicationError(message, data);
    }
    if (data.success) {
      dispatch({
        type: types.GET_ORGANIZATION_CONTACT_NOTE_SUCCESS,
        payload: {
          organizationContactId,
          noteId,
          data: data.data,
          options,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.GET_ORGANIZATION_CONTACT_NOTE_FAILURE,
        payload: {
          organizationContactId,
          noteId,
          message: data.message,
          options,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new ApplicationError(data.message, data);
    }
  };
};

export const updateOrganizationContactNote = (
  noteId,
  organizationContactId,
  form
) => {
  return async dispatch => {
    dispatch({
      type: types.UPDATE_ORGANIZATION_CONTACT_NOTE_REQUEST,
      payload: { noteId },
    });
    if (isEmptyString(noteId) || isEmptyString(organizationContactId)) return;
    let data;
    try {
      const response = await request.put(
        `/api/contact-notes/v1/organization-contact/${organizationContactId}/note/${noteId}`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while updating a note, please try again or contact support.';
      dispatch({
        type: types.UPDATE_ORGANIZATION_CONTACT_NOTE_FAILURE,
        payload: {
          message,
          noteId,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.UPDATE_ORGANIZATION_CONTACT_NOTE_SUCCESS,
        payload: {
          noteId,
          data: data.data,
        },
      });
      return data;
    } else {
      dispatch({
        type: types.UPDATE_ORGANIZATION_CONTACT_NOTE_FAILURE,
        payload: {
          noteId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const deleteOrganizationContactNote = (
  noteId,
  organizationContactId
) => {
  return async dispatch => {
    dispatch({
      type: types.DELETE_ORGANIZATION_CONTACT_NOTE_REQUEST,
      payload: { organizationContactId, noteId },
    });
    let data;
    try {
      const response = await request.del(
        `/api/contact-notes/v1/organization-contact/${organizationContactId}/note/${noteId}`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while deleting a note, please try again or contact support.';
      dispatch({
        type: types.DELETE_ORGANIZATION_CONTACT_NOTE_FAILURE,
        payload: {
          message,
          organizationContactId,
          noteId,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.DELETE_ORGANIZATION_CONTACT_NOTE_SUCCESS,
        payload: {
          organizationContactId,
          noteId,
        },
      });
      return;
    } else {
      dispatch({
        type: types.DELETE_ORGANIZATION_CONTACT_NOTE_FAILURE,
        payload: {
          organizationContactId,
          noteId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

/**
 * List replies to a organization contact note
 * @param  {String} threadId Note thread id
 * @param  {Object} organizationContactId   OrganizationContact's id
 */
export const loadOrganizationContactNoteReplyList = (
  threadId,
  organizationContactId,
  options = {}
) => {
  return dispatch => {
    dispatch({
      type: types.LOAD_NOTE_REPLY_LIST_REQUEST,
      payload: { threadId },
    });
    dispatch({
      type: types.LOAD_NOTE_REPLY_COUNT_REQUEST,
      payload: { threadId },
    });
    request
      .get(
        `/api/contact-notes/v1/organization-contact/${organizationContactId}/threads/${threadId}/replies`,
        {
          ...options,
          thread_id: threadId,
          organization_contact_id: organizationContactId,
          withAuthor:
            typeof options.withAuthor !== 'undefined'
              ? options.withAuthor
              : true,
          withAttachments:
            typeof options.withAttachments !== 'undefined'
              ? options.withAttachments
              : true,
        },
        dispatch
      )
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_NOTE_REPLY_LIST_SUCCESS,
            payload: {
              data: data.data,
              metadata: data.metadata,
              options: options,
              threadId,
            },
          });
          dispatch({
            type: types.LOAD_NOTE_REPLY_COUNT_SUCCESS,
            payload: {
              threadId,
              data: data.metadata,
            },
          });
        } else {
          dispatch({
            type: types.LOAD_NOTE_REPLY_LIST_FAILURE,
            payload: {
              threadId,
              message: data.message,
            },
          });
          dispatch({
            type: types.LOAD_NOTE_REPLY_COUNT_FAILURE,
            payload: {
              threadId,
              message: data.message,
            },
          });
          dispatch(enqueueNotification(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while loading replies, please try again.';
        dispatch({
          type: types.LOAD_NOTE_REPLY_LIST_FAILURE,
          payload: {
            threadId,
            message,
          },
        });
        dispatch({
          type: types.LOAD_NOTE_REPLY_COUNT_FAILURE,
          payload: {
            threadId,
            message,
          },
        });
        dispatch(enqueueNotification(message));
      });
  };
};

/**
 * Update pinned status
 * @param {String} noteId Note id
 * @param  {String} organizationContactId OrganizationContact's id
 * @param  {Boolean} is_pinned  Pinned Status
 * @return {Promise}       Resolves on success
 */
export const updateIsPinnedOrganizationContactNote = (
  noteId,
  organizationContactId,
  is_pinned
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_ORGANIZATION_CONTACT_NOTE_REQUEST,
        payload: { noteId, organizationContactId },
      });
      request
        .put(
          `/api/contact-notes/v1/organization-contact/${organizationContactId}/note/${noteId}/is_pinned`,
          { is_pinned },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_ORGANIZATION_CONTACT_NOTE_SUCCESS,
              payload: {
                noteId,
                organizationContactId,
                data: data.data,
              },
            });
            resolve(data.data);
          } else {
            dispatch({
              type: types.UPDATE_ORGANIZATION_CONTACT_NOTE_FAILURE,
              payload: {
                noteId,
                organizationContactId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message = `Something went wrong while updating note, please try again.`;
          dispatch({
            type: types.UPDATE_ORGANIZATION_CONTACT_NOTE_FAILURE,
            payload: {
              noteId,
              organizationContactId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const loadPinnedOrganizationContactNote = (
  organizationContactId,
  options = {}
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GET_PINNED_ORGANIZATION_CONTACT_NOTE_REQUEST,
        payload: { organizationContactId },
      });
      request
        .get(
          `/api/contact-notes/v1/organization-contact/${organizationContactId}/pinned`,
          options,
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.GET_PINNED_ORGANIZATION_CONTACT_NOTE_SUCCESS,
              payload: {
                data: data.data,
                organizationContactId,
              },
            });
            resolve(data);
          } else {
            dispatch({
              type: types.GET_PINNED_ORGANIZATION_CONTACT_NOTE_FAILURE,
              payload: {
                message: data.message,
                organizationContactId,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading note, please try again.';
          dispatch({
            type: types.GET_PINNED_ORGANIZATION_CONTACT_NOTE_FAILURE,
            payload: { message, organizationContactId },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
