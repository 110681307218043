import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const loadCategoryList = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_CATEGORY_LIST_REQUEST
      });
      request.get(`/api/v2/categories`, {
        parent: 'all'
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_CATEGORY_LIST_SUCCESS,
            payload: data.data
          });
          resolve(data);
        } else {
          dispatch({
            type: types.LOAD_CATEGORY_LIST_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading categories, please try again.';
        dispatch({
          type: types.LOAD_CATEGORY_LIST_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(new Error(message));
      });
    });
  };
};

export const regenerateDefaultCategories = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.REGENERATE_CATEGORY_REQUEST
      });
      request.post(`/api/v2/categories/regenerate`, {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: types.REGENERATE_CATEGORY_SUCCESS });
          return resolve(data);
        } else {
          dispatch({
            type: types.REGENERATE_CATEGORY_FAILURE,
            payload: data.message
          });
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while adding category, please try again.';
        dispatch({
          type: types.REGENERATE_CATEGORY_FAILURE,
          payload: message
        });
        reject(new Error(message));
      });
    });
  };
};

export const addCategory = (form, orgId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.ADD_CATEGORY_REQUEST
      });
      request.post(`/api/v1/categories/${orgId}`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: types.ADD_CATEGORY_SUCCESS,payload:form });
          return resolve(data);
        } else {
          dispatch({
            type: types.ADD_CATEGORY_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while adding category, please try again.';
        dispatch({
          type: types.ADD_CATEGORY_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

export const removeCategory = (name, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.REMOVE_CATEGORY_REQUEST,
        payload: {
          name
        }
      });
      request.del(`/api/v2/categories/${encodeURIComponent(name)}`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.REMOVE_CATEGORY_SUCCESS,
            payload: {
              name
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.REMOVE_CATEGORY_FAILURE,
            payload: {
              name,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while removing category, please try again.';
        dispatch({
          type: types.REMOVE_CATEGORY_FAILURE,
          payload: {
            name,
            message
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

export const updateRootCategorySetting = (rootCategory, disableCategoryAddition) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      request.put(`/api/v2/categories`, {rootCategory,
        disableCategoryAddition}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.UPDATE_ROOT_CATEGORY_SETTING,
            payload: {
              rootCategory,
              disableCategoryAddition
            }
          });
          return resolve(data);
        } else {
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while updating category setting, please try again.';
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};