/**
 * List sign off items in a group
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LIST_SIGN_OFF_ITEMS_REQUEST,
  LIST_SIGN_OFF_ITEMS_SUCCESS,
  LIST_SIGN_OFF_ITEMS_FAILURE,
} from '../actions';

export default (sign_off_group_id, deal_id, options = {}) => {
  return async dispatch => {
    dispatch({
      type: LIST_SIGN_OFF_ITEMS_REQUEST,
      payload: {
        signOffGroupId: sign_off_group_id,
      },
    });

    let data;
    try {
      const response = await request.get(
        `/api/pipeline/sign-offs/v1/deal/${deal_id}/sign-off-group/${sign_off_group_id}/sign-offs`,
        options,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while fetching sign off items, please try again.';
      dispatch({
        type: LIST_SIGN_OFF_ITEMS_FAILURE,
        payload: {
          signOffGroupId: sign_off_group_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: LIST_SIGN_OFF_ITEMS_SUCCESS,
        payload: {
          signOffGroupId: sign_off_group_id,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: LIST_SIGN_OFF_ITEMS_FAILURE,
        payload: {
          signOffGroupId: sign_off_group_id,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
