/** @format */

import * as types from './actions';
import { assign } from '../../helpers/object';
import loadDrawdownList from './reducerHandlers/loadDrawdownList';
import getDrawdown from './reducerHandlers/getDrawdown';
import addDrawdowns from './reducerHandlers/addDrawdowns';
import deleteDrawdown from './reducerHandlers/deleteDrawdown';
import addDistributions from './reducerHandlers/addDistributions';
import loadDistributionList from './reducerHandlers/loadDistributionList';
import getDistribution from './reducerHandlers/getDistribution';
import deleteDistribution from './reducerHandlers/deleteDistribution';
import addNavUpdates from './reducerHandlers/addNavUpdates';
import loadNavUpdateList from './reducerHandlers/loadNavUpdateList';
import getNavUpdate from './reducerHandlers/getNavUpdate';
import deleteNavUpdate from './reducerHandlers/deleteNavUpdate';
import loadCommitmentList from './reducerHandlers/loadCommitmentList';
import addCommitment from './reducerHandlers/addCommitment';
import deleteCommitment from './reducerHandlers/deleteCommitment';

const initial = {
  // Drawdowns states
  drawdown: {},
  drawdownListLoading: false,
  drawdownListError: null,
  drawdownLoading: {},
  drawdownError: {},
  addDrawdownsLoading: false,
  deleteDrawdownLoading: false,

  // Distribution states
  distribution: {},
  distributionListLoading: false,
  distributionListError: null,
  distributionLoading: {},
  distributionError: {},
  addDistributionsLoading: false,
  deleteDistributionLoading: false,

  // NAV update states
  navUpdate: {},
  navUpdateListLoading: false,
  navUpdateListError: null,
  navUpdateLoading: {},
  navUpdateError: {},
  addNavUpdatesLoading: false,
  deleteNavUpdateLoading: false,

  // Commitment states
  commitment: {},
  commitmentListLoading: false,
  commitmentListError: null,
  commitmentLoading: {},
  commitmentError: {},
  addCommitmentLoading: false,
  deleteCommitmentLoading: false,
};

export default function(state = initial, { type, payload }) {
  switch (type) {
    ////////////////////////////////////////////////////////////////////////////
    ///                              Drawdown                                ///
    ////////////////////////////////////////////////////////////////////////////
    case types.ADD_DRAWDOWNS_REQUEST:
    case types.ADD_DRAWDOWNS_FAILURE:
    case types.ADD_DRAWDOWNS_SUCCESS:
      return addDrawdowns(state, type, payload);

    case types.LOAD_DRAWDOWN_LIST_REQUEST:
    case types.LOAD_DRAWDOWN_LIST_FAILURE:
    case types.LOAD_DRAWDOWN_LIST_SUCCESS:
      return loadDrawdownList(state, type, payload);

    case types.GET_DRAWDOWN_REQUEST:
    case types.GET_DRAWDOWN_FAILURE:
    case types.GET_DRAWDOWN_SUCCESS:
      return getDrawdown(state, type, payload);

    case types.DELETE_DRAWDOWN_REQUEST:
    case types.DELETE_DRAWDOWN_FAILURE:
    case types.DELETE_DRAWDOWN_SUCCESS:
      return deleteDrawdown(state, type, payload);

    ////////////////////////////////////////////////////////////////////////////
    ///                          Distribution                                ///
    ////////////////////////////////////////////////////////////////////////////
    case types.ADD_DISTRIBUTIONS_REQUEST:
    case types.ADD_DISTRIBUTIONS_FAILURE:
    case types.ADD_DISTRIBUTIONS_SUCCESS:
      return addDistributions(state, type, payload);

    case types.LOAD_DISTRIBUTION_LIST_REQUEST:
    case types.LOAD_DISTRIBUTION_LIST_FAILURE:
    case types.LOAD_DISTRIBUTION_LIST_SUCCESS:
      return loadDistributionList(state, type, payload);

    case types.GET_DISTRIBUTION_REQUEST:
    case types.GET_DISTRIBUTION_FAILURE:
    case types.GET_DISTRIBUTION_SUCCESS:
      return getDistribution(state, type, payload);

    case types.DELETE_DISTRIBUTION_REQUEST:
    case types.DELETE_DISTRIBUTION_FAILURE:
    case types.DELETE_DISTRIBUTION_SUCCESS:
      return deleteDistribution(state, type, payload);

    ////////////////////////////////////////////////////////////////////////////
    ///                            NAV Update                                ///
    ////////////////////////////////////////////////////////////////////////////
    case types.ADD_NAV_UPDATES_REQUEST:
    case types.ADD_NAV_UPDATES_FAILURE:
    case types.ADD_NAV_UPDATES_SUCCESS:
      return addNavUpdates(state, type, payload);

    case types.LOAD_NAV_UPDATE_LIST_REQUEST:
    case types.LOAD_NAV_UPDATE_LIST_FAILURE:
    case types.LOAD_NAV_UPDATE_LIST_SUCCESS:
      return loadNavUpdateList(state, type, payload);

    case types.GET_NAV_UPDATE_REQUEST:
    case types.GET_NAV_UPDATE_FAILURE:
    case types.GET_NAV_UPDATE_SUCCESS:
      return getNavUpdate(state, type, payload);

    case types.DELETE_NAV_UPDATE_REQUEST:
    case types.DELETE_NAV_UPDATE_FAILURE:
    case types.DELETE_NAV_UPDATE_SUCCESS:
      return deleteNavUpdate(state, type, payload);

    ////////////////////////////////////////////////////////////////////////////
    ///                            Commitments                               ///
    ////////////////////////////////////////////////////////////////////////////
    case types.ADD_COMMITMENT_REQUEST:
    case types.ADD_COMMITMENT_FAILURE:
    case types.ADD_COMMITMENT_SUCCESS:
      return addCommitment(state, type, payload);

    case types.LOAD_COMMITMENT_LIST_REQUEST:
    case types.LOAD_COMMITMENT_LIST_FAILURE:
    case types.LOAD_COMMITMENT_LIST_SUCCESS:
      return loadCommitmentList(state, type, payload);

    case types.DELETE_COMMITMENT_REQUEST:
    case types.DELETE_COMMITMENT_FAILURE:
    case types.DELETE_COMMITMENT_SUCCESS:
      return deleteCommitment(state, type, payload);

    // Resets the redux state
    case types.CLEAR: {
      return assign(state, initial);
    }

    default:
      return state;
  }
}
