/**
 * Reducer for reminders dashboard
 * @format
 */
import reduce from 'lodash.reduce';
import * as types from './actions';
const initalState = {
  //Holds state for listing reminders
  reminders: [],
  listRemindersLoading: false,
  listRemindersError: null,
  remindersCount: 0,

  //Holds state for updating reminders
  updateRemindersLoading: {},
  updateRemindersError: {},

  //holds state for deleting reminders
  deleteRemindersLoading: {},
  deleteRemindersError: {},
};

export default (state = initalState, { type, payload }) => {
  switch (type) {
    /**
     * List reminders dashboard
     */
    case types.LIST_REMINDER_DASHBOARD_REQUEST:
      return Object.assign({}, state, {
        listRemindersLoading: true,
        listRemindersError: null,
      });
    case types.LIST_REMINDER_DASHBOARD_FAILURE:
      return Object.assign({}, state, {
        listRemindersLoading: false,
        listRemindersError: payload.message,
      });
    case types.LIST_REMINDER_DASHBOARD_SUCCESS:
      const remindersObj = reduce(
        payload.data,
        (reminderObj, reminder) => {
          reminderObj[reminder.id] = reminder;

          return reminderObj;
        },
        {}
      );

      return Object.assign({}, state, {
        listRemindersLoading: false,
        listRemindersError: null,

        remindersCount: payload.metadata?.count,
        reminders: {
          ...state.reminders,
          ...remindersObj,
        },
      });

    /**
     * Update reminder reducers
     */
    case types.UPDATE_REMINDER_DASHBOARD_REQUEST:
      return Object.assign({}, state, {
        updateRemindersLoading: {
          ...state.updateRemindersLoading,
          [payload.reminderId]: true,
        },
        updateRemindersError: {
          ...state.updateRemindersError,
          [payload.reminderId]: null,
        },
      });

    case types.UPDATE_REMINDER_DASHBOARD_FAILURE:
      return Object.assign({}, state, {
        updateRemindersLoading: {
          ...state.updateRemindersLoading,
          [payload.reminderId]: false,
        },
        updateRemindersError: {
          ...state.updateRemindersError,
          [payload.reminderId]: payload.message,
        },
      });
    case types.UPDATE_REMINDER_DASHBOARD_SUCCESS:
      const updatedState = Object.assign({}, state, {
        updateRemindersLoading: {
          ...state.updateRemindersLoading,
          [payload.reminderId]: false,
        },
        updateRemindersError: {
          ...state.updateRemindersError,
          [payload.reminderId]: null,
        },
        reminders: {
          ...state.reminders,
          [payload.data.id]: payload.data,
        },
      });

      /**
       * Implications that we are converting this from one time to recurring, The one time reminder
       * gets deleted and the first instance of recurring reminder will be returned
       * because a user can only convert one time to recurring if they have applied the one tome
       *
       * therefore we are removing both of them as one time reminder will be deleted from the db and recurring reminder
       * should not be shown as the user has the one time reminder filter applied on the dashboard
       *
       */
      if (payload.reminderId !== payload.data.id) {
        delete updatedState.reminders[payload.reminderId];
        delete updatedState.reminders[payload.data.id];
      }

      return updatedState;

    /**
     * Delete reminder reducers
     */
    case types.DELETE_REMINDER_DASHBOARD_REQUEST:
      return Object.assign({}, state, {
        deleteRemindersLoading: {
          ...state.deleteRemindersLoading,

          [payload.reminderId]: true,
        },
        deleteRemindersError: {
          ...state.deleteRemindersError,
          [payload.reminderId]: null,
        },
      });
    case types.DELETE_REMINDER_DASHBOARD_FAILURE:
      return Object.assign({}, state, {
        deleteRemindersLoading: {
          ...state.deleteRemindersLoading,
          [payload.reminderId]: false,
        },
        deleteRemindersError: {
          ...state.deleteRemindersError,
          [payload.reminderId]: payload.message,
        },
      });
    case types.DELETE_REMINDER_DASHBOARD_SUCCESS:
      const newState = Object.assign({}, state, {
        deleteRemindersLoading: {
          ...state.deleteRemindersLoading,
          [payload.reminderId]: false,
        },
        deleteRemindersError: {
          ...state.deleteRemindersError,
          [payload.reminderId]: null,
        },
        reminders: {
          ...state.reminders,
        },
      });
      delete newState.reminders[payload.reminderId];
      return newState;

    case types.RESET_REMINDER_STATE:
      return Object.assign({}, state, {
        ...initalState,
      });

    default:
      return state;
  }
};
