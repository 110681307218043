/**
 * @format
 */

import PORTFOLIO_TABS from '../../constants/PortCoTabs';

// if the "portfolio_tabs" organization settings key is not set, then show all the tabs
// if user doesn't have enough permission to see any of these below tabs or the feature flag is not enabled for the org
// we account for it while rendering the tabs inside a Portfolio
export default [
  { id: PORTFOLIO_TABS.SUMMARY.key, active: true },
  { id: PORTFOLIO_TABS.COMPANY_PROFILE.key, active: true },
  { id: PORTFOLIO_TABS.TIMELINE.key, active: true },
  { id: PORTFOLIO_TABS.DOCUMENTS.key, active: true },
  { id: PORTFOLIO_TABS.MIS_SHEETS.key, active: true },
  { id: PORTFOLIO_TABS.VISUALIZATIONS.key, active: true },
  { id: PORTFOLIO_TABS.INTELLIGENCE.key, active: true },
  { id: PORTFOLIO_TABS.INVESTMENTS.key, active: true },
  { id: PORTFOLIO_TABS.MIS_REPORTS.key, active: true },
  { id: PORTFOLIO_TABS.TEAR_SHEETS.key, active: true },
  { id: PORTFOLIO_TABS.SETTINGS.key, active: true },
];
