import * as types from './actions';

const initialState = {
  /**
   * Holds state related to individual startup address update request loading,
   * keys are the startup ids and the values are boolean indicating if it's
   * loading or not.
   */
  startupAddressUpdateLoading: {},
  /**
   * Holds state related to individual startup address update error, keys are the
   * startup ids and the values are string error messages
   */
  startupAddressUpdateError: {},
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case types.UPDATE_STARTUP_ADDRESS_REQUEST:
      return Object.assign({}, state, {
        startupAddressUpdateLoading: {
          ...state.startupAddressUpdateLoading,
          [payload.startup_id]: true
        },
        startupAddressUpdateError: {
          ...state.startupAddressUpdateError,
          [payload.startup_id]: null
        }
      });
    case types.UPDATE_STARTUP_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        startupAddressUpdateLoading: {
          ...state.startupAddressUpdateLoading,
          [payload.startup_id]: false
        },
        startupAddressUpdateError: {
          ...state.startupAddressUpdateError,
          [payload.startup_id]: null
        }
      });
    case types.UPDATE_STARTUP_ADDRESS_FAILURE:
      return Object.assign({}, state, {
        startupAddressUpdateLoading: {
          ...state.startupAddressUpdateLoading,
          [payload.startup_id]: false
        },
        startupAddressUpdateError: {
          ...state.startupAddressUpdateError,
          [payload.startup_id]: payload.message
        }
      });
    default:
      return state;
  }
};
