/**
 * Returns the org contact table column and it's width pair
 * See@ https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
 * @format
 */

import supportsHTML5Storage from './supportsHTML5Storage';

const getOrgContactTableColumnsWidths = () => {
  if (!supportsHTML5Storage()) {
    return null;
  }
  let columnIdResizedPairs = window.localStorage.getItem(
    'orgContactColumnIdResizedPairs'
  );
  if (columnIdResizedPairs && typeof columnIdResizedPairs === 'string') {
    try {
      columnIdResizedPairs = JSON.parse(columnIdResizedPairs);
      // If expires_on is in the past, expire
      if (new Date(columnIdResizedPairs.expires_on) < new Date()) {
        window.localStorage.removeItem('columnIdResizedPairs');
        return null;
      }
      // Return value if it has not expired
      return columnIdResizedPairs.value ? columnIdResizedPairs.value : null;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export default getOrgContactTableColumnsWidths;
