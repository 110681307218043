export const LOAD_ORGANIZATION_WORKSPACE_LIST_REQUEST = 'workspace/LOAD_ORGANIZATION_WORKSPACE_LIST_REQUEST';
export const LOAD_ORGANIZATION_WORKSPACE_LIST_SUCCESS = 'workspace/LOAD_ORGANIZATION_WORKSPACE_LIST_SUCCESS';
export const LOAD_ORGANIZATION_WORKSPACE_LIST_FAILURE = 'workspace/LOAD_ORGANIZATION_WORKSPACE_LIST_FAILURE';

export const ADD_WORKSPACE_REQUEST = 'workspace/ADD_WORKSPACE_REQUEST';
export const ADD_WORKSPACE_SUCCESS = 'workspace/ADD_WORKSPACE_SUCCESS';
export const ADD_WORKSPACE_FAILURE = 'workspace/ADD_WORKSPACE_FAILURE';

export const UPDATE_WORKSPACE_REQUEST = 'workspace/UPDATE_WORKSPACE_REQUEST';
export const UPDATE_WORKSPACE_SUCCESS = 'workspace/UPDATE_WORKSPACE_SUCCESS';
export const UPDATE_WORKSPACE_FAILURE = 'workspace/UPDATE_WORKSPACE_FAILURE';

export const LOAD_WORKSPACE_MEMBERS_ROLES_REQUEST = 'workspace/LOAD_WORKSPACE_MEMBERS_ROLES_REQUEST';
export const LOAD_WORKSPACE_MEMBERS_ROLES_SUCCESS = 'workspace/LOAD_WORKSPACE_MEMBERS_ROLES_SUCCESS';
export const LOAD_WORKSPACE_MEMBERS_ROLES_FAILURE = 'workspace/LOAD_WORKSPACE_MEMBERS_ROLES_FAILURE';

export const SOFT_DELETE_WORKSPACE_REQUEST = 'workspace/SOFT_DELETE_WORKSPACE_REQUEST';
export const SOFT_DELETE_WORKSPACE_SUCCESS = 'workspace/SOFT_DELETE_WORKSPACE_SUCCESS';
export const SOFT_DELETE_WORKSPACE_FAILURE = 'workspace/SOFT_DELETE_WORKSPACE_FAILURE';

export const SWITCH_WORKSPACE = 'workspace/SWITCH_WORKSPACE';
