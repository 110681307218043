/**
 * Update personContact reducer handler
 * @format
 */
import {
  UPDATE_ORG_CONTACT_REQUEST,
  UPDATE_ORG_CONTACT_SUCCESS,
  UPDATE_ORG_CONTACT_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case UPDATE_ORG_CONTACT_REQUEST:
      return Object.assign({}, state, {
        updateOrgContactLoading: {
          ...state.updateOrgContactLoading,
          [payload.orgContactId]: true,
        },
        updateOrgContactError: {
          ...state.updateOrgContactError,
          [payload.orgContactId]: null,
        },
      });

    case UPDATE_ORG_CONTACT_SUCCESS:
      return Object.assign({}, state, {
        organizationContacts: {
          ...state.organizationContacts,
          [payload.orgContactId]: state.organizationContacts[
            payload.orgContactId
          ]
            ? {
                ...state.organizationContacts[payload.orgContactId],
                ...payload.data,
                _roles:
                  // array
                  payload.data._roles
                    ? payload.data._roles
                    : state.organizationContacts[payload.orgContactId]._roles,
                deals:
                  // array
                  payload.data.deals
                    ? payload.data.deals
                    : state.organizationContacts[payload.orgContactId].deals,
                portcos:
                  // array
                  payload.data.portcos
                    ? payload.data.portcos
                    : state.organizationContacts[payload.orgContactId].portcos,
              }
            : payload.data,
        },
        updateOrgContactLoading: {
          ...state.updateOrgContactLoading,
          [payload.orgContactId]: false,
        },
        updateOrgContactError: {
          ...state.updateOrgContactError,
          [payload.orgContactId]: null,
        },
      });

    case UPDATE_ORG_CONTACT_FAILURE:
      return Object.assign({}, state, {
        updateOrgContactLoading: {
          ...state.updateOrgContactLoading,
          [payload.orgContactId]: false,
        },
        updateOrgContactError: {
          ...state.updateOrgContactError,
          [payload.orgContactId]: payload.error,
        },
      });

    default:
      return state;
  }
};
