import * as types from './actions';

const initialState = {
  offline: false,
  notifications: [],
  history: [],
  isPageLoaded: false,
  isMainSidebarOpen: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {

    /**
    * Notifications and device setting reducer
    */
    case types.OFFLINE:
      return Object.assign({}, state, {
        'offline': true
      });
    case types.ENQUEUE_NOTIFICATION:
      return Object.assign({}, state, {
        'notifications': [
          ...state.notifications,
          payload
        ]
      });
    case types.DEQUEUE_NOTIFICATION:
      return Object.assign({}, state, {
        'notifications': [
          ...state.notifications.slice(1)
        ]
      });
    case types.ADD_HISTORY:
      if (state.history.length > 0 && state.history[0] === payload) {
        return state;
      }
      return Object.assign({}, state, {
        'history': [
          payload,
          ...state.history,
        ]
      });
    case types.PAGE_LOADED:
      return Object.assign({}, state, {
        'isPageLoaded': true
      });
    // main sidebar
    case types.MAIN_SIDEBAR_TOGGLE:
      return Object.assign({}, state, {
        'isMainSidebarOpen': !state.isMainSidebarOpen
      });
    case types.MAIN_SIDEBAR_OPEN:
      return Object.assign({}, state, {
        'isMainSidebarOpen': true
      });
    case types.MAIN_SIDEBAR_CLOSE:
      return Object.assign({}, state, {
        'isMainSidebarOpen': false
      });
    default:
      return state;
  }
};
