/**
 * @format
 */
import * as types from './actions';

const initialState = {
  // All these are keyed by conversation id
  commentList: {},
  commentListLoading: {},
  commentListError: {},
  commentListOptions: {},
  commentListMore: {},

  commentsCount: {},
  commentsCountLoading: {},
  commentsCountError: {},

  addReplyLoading: {},
  addReplyError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * Comment list reducers
     */
    case types.LOAD_COMMENT_LIST_REQUEST:
      return Object.assign({}, state, {
        commentListLoading: {
          ...state.commentListLoading,
          [payload.conversationId]: true,
        },
        commentListError: {
          ...state.commentListError,
          [payload.conversationId]: null,
        },
      });
    case types.LOAD_COMMENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        commentList: {
          ...state.commentList,
          [payload.conversationId]:
            payload.options.page === 1
              ? payload.data
              : [...state.commentList[payload.conversationId], ...payload.data],
        },
        commentListLoading: {
          ...state.commentListLoading,
          [payload.conversationId]: false,
        },
        commentListError: {
          ...state.commentListError,
          [payload.conversationId]: null,
        },
        commentListOptions: {
          ...state.commentListOptions,
          [payload.conversationId]: payload.options,
        },
        commentListMore: {
          ...state.commentListMore,
          [payload.conversationId]:
            payload.data.length >= payload.options.limit ? true : false,
        },
      });
    case types.LOAD_COMMENT_LIST_FAILURE:
      return Object.assign({}, state, {
        commentListLoading: {
          ...state.commentListLoading,
          [payload.conversationId]: false,
        },
        commentListError: {
          ...state.commentListError,
          [payload.conversationId]: payload.message,
        },
      });

    /**
     * Comments counts
     */
    case types.LOAD_COMMENTS_COUNT_REQUEST:
      return Object.assign({}, state, {
        commentsCountLoading: {
          ...state.commentsCountLoading,
          [payload.conversationId]: true,
        },
        commentsCountError: {
          ...state.commentsCountError,
          [payload.conversationId]: null,
        },
      });
    case types.LOAD_COMMENTS_COUNT_SUCCESS:
      return Object.assign({}, state, {
        commentsCount: {
          ...state.commentsCount,
          [payload.conversationId]: payload.data,
        },
        commentsCountLoading: {
          ...state.commentsCountLoading,
          [payload.conversationId]: false,
        },
        commentsCountError: {
          ...state.commentsCountError,
          [payload.conversationId]: null,
        },
      });
    case types.LOAD_COMMENTS_COUNT_FAILURE:
      return Object.assign({}, state, {
        commentsCountLoading: {
          ...state.commentsCountLoading,
          [payload.conversationId]: false,
        },
        commentsCountError: {
          ...state.commentsCountError,
          [payload.conversationId]: payload.message,
        },
      });

    /**
     * Conversation reply reducer
     */
    case types.ADD_REPLY_REQUEST:
      return Object.assign({}, state, {
        addReplyLoading: {
          ...state.addReplyLoading,
          [payload.conversationId]: true,
        },
        addReplyError: {
          ...state.addReplyError,
          [payload.conversationId]: null,
        },
      });
    case types.ADD_REPLY_SUCCESS:
      return Object.assign({}, state, {
        addReplyLoading: {
          ...state.addReplyLoading,
          [payload.conversationId]: false,
        },
        addReplyError: {
          ...state.addReplyError,
          [payload.conversationId]: null,
        },
        // commentList: {
        //   ...state.commentList,
        //   [payload.conversationId]: [...state.commentList[payload.conversationId], payload.data]
        // },
        commentsCount: {
          ...state.commentsCount,
          [payload.conversationId]:
            state.commentsCount[payload.conversationId] + 1,
        },
      });
    case types.ADD_REPLY_FAILURE:
      return Object.assign({}, state, {
        addReplyLoading: {
          ...state.addReplyLoading,
          [payload.conversationId]: false,
        },
        addReplyError: {
          ...state.addReplyError,
          [payload.conversationId]: payload.message,
        },
      });

    case types.BULK_LOAD_COMMENT_LIST: {
      const copyOfState = {
        ...state,
        commentList: {
          ...state.commentList,
        },
        commentsCount: {
          ...state.commentsCount,
        },
        commentListMore: {
          ...state.commentListMore,
        },
        commentListOptions: {
          ...state.commentListMore,
        },
      };
      payload.conversations.forEach(conversation => {
        copyOfState.commentList[conversation.id] = conversation.comments || [];
        copyOfState.commentsCount[conversation.id] =
          conversation.commentCount || 0;
        copyOfState.commentListMore[conversation.id] =
          conversation.comments.length >= payload.options.limit ? true : false;
        copyOfState.commentListOptions[conversation.id] = payload.options;
      });

      return Object.assign({}, state, copyOfState);
    }

    default:
      return state;
  }
};
