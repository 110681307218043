/**
 * Associates category to a portco
 *
 * @format
 */
import request from '../../helpers/request';
import { enqueueNotification } from '../app';
import * as types from './actions';
import reducers from './reducers';

export default reducers;

export const associateCategory = (portcoId, category_name) => {
  return async dispatch => {
    dispatch({
      type: types.ASSOCIATE_CATEGORY_REQUEST,
      payload: { portcoId, category_name },
    });
    let data;
    try {
      const response = await request.post(
        `/api/portfolio/categories/v1/companies/${portcoId}/associate`,
        {
          category_name,
        },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while associating category, please try again.';
      dispatch({
        type: types.ASSOCIATE_CATEGORY_FAILURE,
        payload: { message: message, portcoId },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.ASSOCIATE_CATEGORY_SUCCESS,
        payload: { portcoId, category_name },
      });
      return;
    } else {
      dispatch({
        type: types.ASSOCIATE_CATEGORY_FAILURE,
        payload: { message: data.message, portcoId },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const deassociateCategory = (portcoId, category_name) => {
  return async dispatch => {
    dispatch({
      type: types.DEASSOCIATE_CATEGORY_REQUEST,
      payload: { portcoId, category_name },
    });
    let data;
    try {
      const response = await request.post(
        `/api/portfolio/categories/v1/companies/${portcoId}/deassociate`,
        {
          category_name,
        },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while deassociating category, please try again.';
      dispatch({
        type: types.DEASSOCIATE_CATEGORY_FAILURE,
        payload: { message: message, portcoId },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.DEASSOCIATE_CATEGORY_SUCCESS,
        payload: { portcoId, category_name },
      });
      return;
    } else {
      dispatch({
        type: types.DEASSOCIATE_CATEGORY_FAILURE,
        payload: { message: data.message, portcoId },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
