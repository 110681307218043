/** @format */

import * as types from '../actions';
import request, { ApplicationError } from '../../../helpers/request';
import { enqueueNotification } from '../../app';

/**
 * Adds commitments in bulk (can also be used to add a single commitment with an array of 1 element)
 * @param {Array} arrayOfCommitments Accepts Commitment details in an array format
 * @returns
 */
export default function addCommitments(arrayOfCommitments) {
  return async dispatch => {
    dispatch({
      type: types.ADD_COMMITMENT_REQUEST,
      payload: {},
    });

    try {
      const { data } = await request.post(
        `/api/fund-transactions/v1/commitments`,
        arrayOfCommitments,
        dispatch
      );

      if (!data.success) {
        throw new ApplicationError(data.message, data);
      }

      const response = data.data[0];
      const { success } = response;
      if (!success) {
        throw new ApplicationError(response.message, response);
      }

      dispatch({
        type: types.ADD_COMMITMENT_SUCCESS,
        payload: { data: data.data },
      });

      return data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong while adding commitment. Please try again.';
      dispatch({
        type: types.ADD_COMMITMENT_FAILURE,
        payload: { message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(err.message);
    }
  };
}
