/** @format */
import * as types from './actions';
import { assign } from '../../../helpers/object';
import reduce from 'lodash.reduce';
import property from 'lodash.property';
import uniqBy from 'lodash.uniqby';

const initialState = {
  organizationContactDocumentLoading: {},
  organizationContactDocumentError: {},
  organizationContactDocuments: {},

  createOrganizationContactDocumentLoading: false,
  organizationContactDocumentsListLoading: false,
  organizationContactDocumentsCount: {},
  tagsListLoading: false,

  tags: [],

  getOrganizationContactDocumentOptions: {
    q: '',
    tags: [],
    sortBy: '',
    sortOrder: '',
    limit: '',
    page: '',
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CREATE_ORGANIZATION_CONTACT_DOCUMENT_REQUEST: {
      return assign(state, {
        createOrganizationContactDocumentLoading: true,
      });
    }
    case types.CREATE_ORGANIZATION_CONTACT_DOCUMENT_SUCCESS: {
      return assign(state, {
        createOrganizationContactDocumentLoading: false,
        organizationContactDocuments: assign(
          state.organizationContactDocuments,
          reduce(
            payload.data,
            (acc, { document }) => {
              // no need to `assign` here, since it's a fresh doc
              // added a empty tags array for newly created fund document to add tags without reloading the page
              acc[document.id] = { ...document, tags: [] };
              return acc;
            },
            {}
          )
        ),
        organizationContactDocumentsCount: {
          ...state.organizationContactDocumentsCount,
          [payload.organizationContactId]: {
            ...state.organizationContactDocumentsCount[
              payload.organizationContactId
            ],
            all:
              state.organizationContactDocumentsCount[
                payload.organizationContactId
              ] &&
              state.organizationContactDocumentsCount[
                payload.organizationContactId
              ].all
                ? state.organizationContactDocumentsCount[
                    payload.organizationContactId
                  ].all + payload.data.length
                : 1,
          },
        },
      });
    }
    case types.CREATE_ORGANIZATION_CONTACT_DOCUMENT_FAILURE: {
      return assign(state, {
        createOrganizationContactDocumentLoading: false,
      });
    }
    //
    case types.LIST_ORGANIZATION_CONTACT_DOCUMENTS_REQUEST: {
      return assign(state, {
        organizationContactDocumentsListLoading: true,
      });
    }
    case types.LIST_ORGANIZATION_CONTACT_DOCUMENTS_SUCCESS: {
      return assign(state, {
        organizationContactDocumentsListLoading: false,
        organizationContactDocuments: assign(
          state.organizationContactDocuments,
          reduce(
            payload.data,
            (acc, item) => {
              // merge with existing state, if it exists
              acc[item.id] = assign(
                property(`organizationContactDocuments.${item.id}`)(state) ||
                  {},
                item
              );
              return acc;
            },
            {}
          )
        ),
        organizationContactDocumentsCount: {
          ...state.organizationContactDocumentsCount,
          [payload.organizationContactId]: {
            all: payload.metadata.documentsCount || 0,
            hidden: payload.metadata.hiddenDocumentsCount || 0,
          },
        },
      });
    }
    case types.LIST_ORGANIZATION_CONTACT_DOCUMENTS_FAILURE: {
      return assign(state, {
        organizationContactDocumentsListLoading: false,
      });
    }

    case types.GET_ORGANIZATION_CONTACT_DOCUMENT_SIGNEDURL_REQUEST:
    case types.ADD_TAG_TO_ORGANIZATION_CONTACT_DOCUMENT_REQUEST:
    case types.REMOVE_TAG_FROM_ORGANIZATION_CONTACT_DOCUMENT_REQUEST:
    case types.DELETE_ORGANIZATION_CONTACT_DOCUMENT_REQUEST:
    case types.HIDE_OR_UNHIDE_ORGANIZATION_CONTACT_DOCUMENT_REQUEST:
    case types.FILE_UPLOAD_S3_REQUEST:
    case types.FILE_DOWNLOAD_S3_REQUEST:
      return Object.assign({}, state, {
        organizationContactDocumentLoading: {
          ...state.organizationContactDocumentLoading,
          [payload.documentId]: true,
        },
        organizationContactDocumentError: {
          ...state.organizationContactDocumentError,
          [payload.documentId]: null,
        },
      });
    case types.GET_ORGANIZATION_CONTACT_DOCUMENT_SIGNEDURL_SUCCESS:
    case types.DELETE_ORGANIZATION_CONTACT_DOCUMENT_SUCCESS:
    case types.HIDE_OR_UNHIDE_ORGANIZATION_CONTACT_DOCUMENT_SUCCESS:
    case types.FILE_UPLOAD_S3_SUCCESS:
    case types.FILE_DOWNLOAD_S3_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        organizationContactDocumentLoading: {
          ...state.organizationContactDocumentLoading,
        },
        organizationContactDocumentError: {
          ...state.organizationContactDocumentError,
        },
      });
      // clear the target items
      delete newState.organizationContactDocumentLoading[payload.documentId];
      delete newState.organizationContactDocumentError[payload.documentId];
      return newState;
    }
    case types.GET_ORGANIZATION_CONTACT_DOCUMENT_SIGNEDURL_FAILURE:
    case types.ADD_TAG_TO_ORGANIZATION_CONTACT_DOCUMENT_FAILURE:
    case types.REMOVE_TAG_FROM_ORGANIZATION_CONTACT_DOCUMENT_FAILURE:
    case types.DELETE_ORGANIZATION_CONTACT_DOCUMENT_FAILURE:
    case types.HIDE_OR_UNHIDE_ORGANIZATION_CONTACT_DOCUMENT_FAILURE:
    case types.FILE_UPLOAD_S3_FAILURE:
    case types.FILE_DOWNLOAD_S3_FAILURE:
      return Object.assign({}, state, {
        organizationContactDocumentLoading: {
          ...state.organizationContactDocumentLoading,
          [payload.documentId]: false,
        },
        organizationContactDocumentError: {
          ...state.organizationContactDocumentError,
          [payload.documentId]: payload.message,
        },
      });

    // list tags
    case types.LIST_ORGANIZATION_CONTACT_DOCUMENT_TAGS_REQUEST:
      return Object.assign({}, state, {
        tagsListLoading: true,
      });
    case types.LIST_ORGANIZATION_CONTACT_DOCUMENT_TAGS_SUCCESS:
      return Object.assign({}, state, {
        tagsListLoading: false,
        tags: payload.data,
      });
    case types.LIST_ORGANIZATION_CONTACT_DOCUMENT_TAGS_FAILURE:
      return Object.assign({}, state, {
        tagsListLoading: false,
      });

    // add tag
    case types.ADD_TAG_TO_ORGANIZATION_CONTACT_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        organizationContactDocumentLoading: {
          ...state.organizationContactDocumentLoading,
          [payload.documentId]: false,
        },
        organizationContactDocuments: {
          ...state.organizationContactDocuments,
          [payload.documentId]: assign(
            state.organizationContactDocuments[payload.documentId],
            {
              tags: uniqBy(
                [
                  ...(property(
                    `organizationContactDocuments.${payload.documentId}.tags`
                  )(state) || []),
                  { name: payload.tag },
                ],
                t => t.name
              ),
            }
          ),
        },
      });

    // remove tag
    case types.REMOVE_TAG_FROM_ORGANIZATION_CONTACT_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        organizationContactDocumentLoading: {
          ...state.organizationContactDocumentLoading,
          [payload.documentId]: false,
        },
        organizationContactDocuments: {
          ...state.organizationContactDocuments,
          [payload.documentId]: assign(
            state.organizationContactDocuments[payload.documentId],
            {
              tags: (
                property(
                  `organizationContactDocuments.${payload.documentId}.tags`
                )(state) || []
              ).filter(tag => tag.name !== payload.tag),
            }
          ),
        },
      });

    case types.CLEAR: {
      return assign(state, initialState);
    }

    default:
      return state;
  }
};
