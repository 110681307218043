/**
 * Opens manage column
 * @format
 */

import { PORTCO_OPEN_MANAGE_COLUMN } from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case PORTCO_OPEN_MANAGE_COLUMN:
      return Object.assign({}, state, {
        manageColumnOpen: true,
      });

    default:
      return state;
  }
};
