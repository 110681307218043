import PortfolioTableViewColumn from "../../constants/PorfolioTableColumn";

export default [
    {id: PortfolioTableViewColumn.PORTCO.key, active: true},
    {id: PortfolioTableViewColumn.CURRENCY.key, active: true},
    {id: PortfolioTableViewColumn.STARTUP_OPERATIONAL_STATUS.key, active: false},
    {id: PortfolioTableViewColumn.STARTUP_DESCRIPTION.key, active: true},
    {id: PortfolioTableViewColumn.STARTUP_EMAIL.key, active: false},
    {id: PortfolioTableViewColumn.STARTUP_EMPLOYEE_COUNT.key, active: false},
    {id: PortfolioTableViewColumn.FUNDS.key, active: true},
    {id: PortfolioTableViewColumn.STARTUP_FOUNDERS.key, active: true},
    {id: PortfolioTableViewColumn.STARTUP_FOUNDING_YEAR.key, active: false},
    {id: PortfolioTableViewColumn.STARTUP_GLOBAL_OP_ADDRESS.key, active: false},
    {id: PortfolioTableViewColumn.STARTUP_LOCAL_OP_ADDRESS.key, active: false},
    {id: PortfolioTableViewColumn.STARTUP_PHONE_NUMBER.key, active: false},
    {id: PortfolioTableViewColumn.STARTUP_REGISTERED_NAME.key, active: false},
    {id: PortfolioTableViewColumn.STARTUP_URL.key, active: true},
    {id: PortfolioTableViewColumn.MANAGERS.key, active: true},
    {id:PortfolioTableViewColumn.ADDED_BY.key, active: false},
    {id: PortfolioTableViewColumn.CREATED_AT.key, active: false},
    {id: PortfolioTableViewColumn.LAST_ACTIVE_AT.key, active: false},
    // {id: PortfolioTableViewColumn.ATTACHMENTS.key, active: true},
    {id: PortfolioTableViewColumn.SOCIAL_LINKS.key, active: false},
    
]