/**
 * Removes assignee from a deal
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  REMOVE_ASSIGNEE_REQUEST,
  REMOVE_ASSIGNEE_SUCCESS,
  REMOVE_ASSIGNEE_FAILURE } from '../actions';

export default (id, user_id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: REMOVE_ASSIGNEE_REQUEST,
        payload: {
          dealId: id
        }
      });
      request.post(`/api/v1/pipelines/${id}/assignee/remove`, {
        user_id
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: REMOVE_ASSIGNEE_SUCCESS,
            payload: {
              dealId: id
            }
          });
          resolve();
        } else {
          dispatch({
            type: REMOVE_ASSIGNEE_FAILURE,
            payload: {
              dealId: id,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while removing assignee, please try again.';
        dispatch({
          type: REMOVE_ASSIGNEE_FAILURE,
          payload: {
            message,
            dealId: id,
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
