/** @format */
import * as types from './actions';

const initialState = {
  /**
   * Holds state related to individual chart, keys are the chart ids and
   * the values are object that contain the chart metadata, its rows and columns
   */
  charts: {},

  // state to keep track of delete-chart operation. Key is chartId
  deleteChartLoading: {},
  deleteChartError: {},

  // Keyed by portfolio company's id
  chartListLoading: {},
  chartListError: {},

  // Keyed by portfolio company's id
  createChartLoading: {},
  createChartError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /** LIST_PORTCO_CHARTS */
    case types.LIST_PORTCO_CHARTS_REQUEST:
      return Object.assign({}, state, {
        chartListLoading: {
          ...state.chartListLoading,
          [payload.portcoId]: true,
        },
        chartListError: {
          ...state.chartListError,
          [payload.portcoId]: null,
        },
      });
    case types.LIST_PORTCO_CHARTS_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        chartListLoading: {
          ...state.chartListLoading,
        },
        chartListError: {
          ...state.chartListError,
        },
        charts: {
          ...state.charts,
          // convert list to object
          ...payload.data.reduce((acc, chart) => {
            acc[chart.id] = chart;
            return acc;
          }, {}),
        },
      });
      // clear the target items
      delete newState.chartListLoading[payload.portcoId];
      delete newState.chartListError[payload.portcoId];
      return newState;
    }
    case types.LIST_PORTCO_CHARTS_FAILURE:
      return Object.assign({}, state, {
        chartListLoading: {
          ...state.chartListLoading,
          [payload.portcoId]: false,
        },
        chartListError: {
          ...state.chartListError,
          [payload.portcoId]: payload.message,
        },
      });

    /** CREATE_PORTCO_CHART */
    case types.CREATE_PORTCO_CHART_REQUEST:
      return Object.assign({}, state, {
        createChartLoading: {
          ...state.createChartLoading,
          [payload.portcoId]: true,
        },
        createChartError: {
          ...state.createChartError,
          [payload.portcoId]: null,
        },
      });
    case types.CREATE_PORTCO_CHART_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        createChartLoading: {
          ...state.createChartLoading,
        },
        createChartError: {
          ...state.createChartError,
        },
        charts: {
          ...state.charts,
          // add new chart to state
          [payload.data.id]: payload.data,
        },
      });
      // clear the target items
      delete newState.createChartLoading[payload.portcoId];
      delete newState.createChartError[payload.portcoId];
      return newState;
    }
    case types.CREATE_PORTCO_CHART_FAILURE:
      return Object.assign({}, state, {
        createChartLoading: {
          ...state.createChartLoading,
          [payload.portcoId]: false,
        },
        createChartError: {
          ...state.createChartError,
          [payload.portcoId]: payload.message,
        },
      });

    /** DELETE_PORTCO_CHARTS */
    case types.DELETE_PORTCO_CHARTS_REQUEST:
      return Object.assign({}, state, {
        deleteChartLoading: {
          ...state.deleteChartLoading,
          [payload.chartId]: true,
        },
        deleteChartError: {
          ...state.deleteChartError,
          [payload.chartId]: null,
        },
      });
    case types.DELETE_PORTCO_CHARTS_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        deleteChartLoading: {
          ...state.deleteChartLoading,
        },
        deleteChartError: {
          ...state.deleteChartError,
        },
        charts: {
          ...state.charts,
        },
      });
      // clear the target items
      delete newState.deleteChartLoading[payload.chartId];
      delete newState.deleteChartError[payload.chartId];
      // remove chart from local state
      delete newState.charts[payload.chartId];
      return newState;
    }
    case types.DELETE_PORTCO_CHARTS_FAILURE:
      return Object.assign({}, state, {
        deleteChartLoading: {
          ...state.deleteChartLoading,
          [payload.chartId]: false,
        },
        deleteChartError: {
          ...state.deleteChartError,
          [payload.chartId]: payload.message,
        },
      });

    case types.CLEAR_CHARTS:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
};
