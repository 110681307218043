/**
 * CLEAR_DEALS reducer
 * 
 * @author Shrikrishna Holla
 */
import initialState from '../initialState';

export default (state = initialState) => {
  return Object.assign({}, state, {
    ...initialState,
    /**
     * Keep the suggestion untouched
     */
    suggestions: state.suggestions,
    suggestionsLoading: state.suggestionsLoading,
    suggestionsError: state.suggestionsError,
    /**
     * Keep the deal list columns untouched
     */
    columns: state.columns,
    columnsLoading: state.columnsLoading,
    columnsError: state.columnsError,
    /**
     * Keep the custom fields untouched
     */
    customFields: state.customFields,
    customFieldsLoading: state.customFieldsLoading,
    customFieldsError: state.customFieldsError,
  });
};