/**
 * Fetches list of notes associated with deal event
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
   LOAD_DEAL_EVENT_NOTE_LIST_REQUEST,
   LOAD_DEAL_EVENT_NOTE_LIST_SUCCESS,
   LOAD_DEAL_EVENT_NOTE_LIST_FAILURE } from '../actions';

export default (dealId, eventId, options) => {
  return dispatch => {
    dispatch({
      type: LOAD_DEAL_EVENT_NOTE_LIST_REQUEST,
      payload: {
        eventId: eventId
      }
    });
    request.get(`/api/v1/pipelines/${dealId}/events/${eventId}/notes`, options, dispatch)
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: LOAD_DEAL_EVENT_NOTE_LIST_SUCCESS,
          payload: {
            data: data.data,
            options: options,
            eventId: eventId,
          }
        });
      } else {
        dispatch({
          type: LOAD_DEAL_EVENT_NOTE_LIST_FAILURE,
          payload: {
            message: data.message,
            eventId: eventId
          },
        });
        dispatch(enqueueNotification(data.message));
      }
    })
    .catch((err) => {
      const message = 'Something went wrong while loading event notes, please try again.';
      dispatch({
        type: LOAD_DEAL_EVENT_NOTE_LIST_FAILURE,
        payload: {
          message: message,
          eventId: eventId
        }
      });
      dispatch(enqueueNotification(message));
    });
  };
};
