/** Actions supported on PortCo module */
export const ADD_PORTCO_REQUEST = 'portfolio/ADD_PORTCO_REQUEST';
export const ADD_PORTCO_SUCCESS = 'portfolio/ADD_PORTCO_SUCCESS';
export const ADD_PORTCO_FAILURE = 'portfolio/ADD_PORTCO_FAILURE';

export const UPDATE_PORTCO_REQUEST = 'portfolio/UPDATE_PORTCO_REQUEST';
export const UPDATE_PORTCO_SUCCESS = 'portfolio/UPDATE_PORTCO_SUCCESS';
export const UPDATE_PORTCO_FAILURE = 'portfolio/UPDATE_PORTCO_FAILURE';

export const LOAD_PORTCO_LIST_REQUEST = 'portfolio/LOAD_PORTCO_LIST_REQUEST';
export const LOAD_PORTCO_LIST_SUCCESS = 'portfolio/LOAD_PORTCO_LIST_SUCCESS';
export const LOAD_PORTCO_LIST_FAILURE = 'portfolio/LOAD_PORTCO_LIST_FAILURE';

export const LOAD_PORTCO_COMPANIES_REQUEST = 'portfolio/LOAD_PORTCO_COMPANIES_REQUEST';
export const LOAD_PORTCO_COMPANIES_SUCCESS = 'portfolio/LOAD_PORTCO_COMPANIES_SUCCESS';
export const LOAD_PORTCO_COMPANIES_FAILURE = 'portfolio/LOAD_PORTCO_COMPANIES_FAILURE';

export const LOAD_PORTFOLIO_TABLE_COLUMNS_REQUEST = 'portfolio/LOAD_PORTFOLIO_TABLE_COLUMNS_REQUEST';
export const LOAD_PORTFOLIO_TABLE_COLUMNS_SUCCESS = 'portfolio/LOAD_PORTFOLIO_TABLE_COLUMNS_SUCCESS';
export const LOAD_PORTFOLIO_TABLE_COLUMNS_FAILURE = 'portfolio/LOAD_PORTFOLIO_TABLE_COLUMNS_FAILURE';

export const UPDATE_PORTFOLIO_TABLE_COLUMNS_REQUEST = 'portfolio/UPDATE_PORTFOLIO_TABLE_COLUMNS_REQUEST';
export const UPDATE_PORTFOLIO_TABLE_COLUMNS_SUCCESS = 'portfolio/UPDATE_PORTFOLIO_TABLE_COLUMNS_SUCCESS';
export const UPDATE_PORTFOLIO_TABLE_COLUMNS_FAILURE = 'portfolio/UPDATE_PORTFOLIO_TABLE_COLUMNS_FAILURE';

export const GET_PORTCO_REQUEST = 'portfolio/GET_PORTCO_REQUEST';
export const GET_PORTCO_SUCCESS = 'portfolio/GET_PORTCO_SUCCESS';
export const GET_PORTCO_FAILURE = 'portfolio/GET_PORTCO_FAILURE';

export const LOAD_CATEGORIES_REQUEST = 'portfolio/LOAD_CATEGORIES_REQUEST';
export const LOAD_CATEGORIES_SUCCESS = 'portfolio/LOAD_CATEGORIES_SUCCESS';
export const LOAD_CATEGORIES_FAILURE = 'portfolio/LOAD_CATEGORIES_FAILURE';

export const INCREMENT_PORTCO_CONVERSATION_COUNT = 'portfolio/INCREMENT_PORTCO_CONVERSATION_COUNT';
export const DECREMENT_PORTCO_CONVERSATION_COUNT = 'portfolio/DECREMENT_PORTCO_CONVERSATION_COUNT';

export const ADD_MULTIPLE_STARTUP_FOUNDERS_REQUEST = 'portfolio/ADD_MULTIPLE_STARTUP_FOUNDERS_REQUEST';
export const ADD_MULTIPLE_STARTUP_FOUNDERS_SUCCESS = 'portfolio/ADD_MULTIPLE_STARTUP_FOUNDERS_SUCCESS';
export const ADD_MULTIPLE_STARTUP_FOUNDERS_FAILURE = 'portfolio/ADD_MULTIPLE_STARTUP_FOUNDERS_FAILURE';

export const REMOVE_STARTUP_FOUNDER_REQUEST = 'portfolio/REMOVE_STARTUP_FOUNDER_REQUEST';
export const REMOVE_STARTUP_FOUNDER_SUCCESS = 'portfolio/REMOVE_STARTUP_FOUNDER_SUCCESS';
export const REMOVE_STARTUP_FOUNDER_FAILURE = 'portfolio/REMOVE_STARTUP_FOUNDER_FAILURE';

export const DELETE_PORTCO_REQUEST = 'portfolio/DELETE_PORTCO_REQUEST';
export const DELETE_PORTCO_SUCCESS = 'portfolio/DELETE_PORTCO_SUCCESS';
export const DELETE_PORTCO_FAILURE = 'portfolio/DELETE_PORTCO_FAILURE';

export const LOAD_PORTCO_ACL_REQUEST = 'portfolio/LOAD_PORTCO_ACL_REQUEST';
export const LOAD_PORTCO_ACL_SUCCESS = 'portfolio/LOAD_PORTCO_ACL_SUCCESS';
export const LOAD_PORTCO_ACL_FAILURE = 'portfolio/LOAD_PORTCO_ACL_FAILURE';

export const PORTCO_OPEN_MANAGE_COLUMN = 'portfolio/PORTCO_OPEN_MANAGE_COLUMN';

export const PORTCO_CLOSE_MANAGE_COLUMN = 'portfolio/PORTCO_CLOSE_MANAGE_COLUMN';

export const SET_PORTCO_FILTERS = 'portfolio/SET_PORTCO_FILTERS';

export const ADD_CUSTOM_FIELD_REQUEST = 'portfolio/ADD_CUSTOM_FIELD_REQUEST';
export const ADD_CUSTOM_FIELD_SUCCESS = 'portfolio/ADD_CUSTOM_FIELD_SUCCESS';
export const ADD_CUSTOM_FIELDS_FAILURE = 'portfolio/ADD_CUSTOM_FIELDS_FAILURE';

export const LOAD_CUSTOM_FIELDS_REQUEST = 'portfolio/LOAD_CUSTOM_FIELDS_REQUEST';
export const LOAD_CUSTOM_FIELDS_SUCCESS = 'portfolio/LOAD_CUSTOM_FIELDS_SUCCESS';
export const LOAD_CUSTOM_FIELDS_FAILURE = 'portfolio/LOAD_CUSTOM_FIELDS_FAILURE';

export const UPDATE_CUSTOM_FIELDS_REQUEST = 'portfolio/UPDATE_CUSTOM_FIELDS_REQUEST';
export const UPDATE_CUSTOM_FIELDS_SUCCESS = 'portfolio/UPDATE_CUSTOM_FIELDS_SUCCESS';
export const UPDATE_CUSTOM_FIELDS_FAILURE = 'portfolio/UPDATE_CUSTOM_FIELDS_FAILURE';

export const REMOVE_CUSTOM_FIELDS_REQUEST = 'portfolio/REMOVE_CUSTOM_FIELDS_REQUEST';
export const REMOVE_CUSTOM_FIELDS_SUCCESS = 'portfolio/REMOVE_CUSTOM_FIELDS_SUCCESS';
export const REMOVE_CUSTOM_FIELDS_FAILURE = 'portfolio/REMOVE_CUSTOM_FIELDS_FAILURE';

export const LOAD_CUSTOM_FIELD_VALUES_REQUEST = 'portfolio/LOAD_CUSTOM_FIELD_VALUES_REQUEST';
export const LOAD_CUSTOM_FIELD_VALUES_SUCCESS = 'portfolio/LOAD_CUSTOM_FIELD_VALUES_SUCCESS';
export const LOAD_CUSTOM_FIELD_VALUES_FAILURE = 'portfolio/LOAD_CUSTOM_FIELD_VALUES_FAILURE';

export const UPDATE_CUSTOM_FIELD_VALUE_REQUEST = 'portfolio/UPDATE_CUSTOM_FIELD_VALUE_REQUEST';
export const UPDATE_CUSTOM_FIELD_VALUE_SUCCESS = 'portfolio/UPDATE_CUSTOM_FIELD_VALUE_SUCCESS';
export const UPDATE_CUSTOM_FIELD_VALUE_FAILURE = 'portfolio/UPDATE_CUSTOM_FIELD_VALUE_FAILURE';