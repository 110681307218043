/**
 * Fetches activity log for a deal
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  GET_DEAL_ACTIVITY_LOG_REQUEST,
  GET_DEAL_ACTIVITY_LOG_SUCCESS,
  GET_DEAL_ACTIVITY_LOG_FAILURE,
} from '../actions';

export default (dealId, { page, limit, deal_created_at }) => {
  return async dispatch => {
    dispatch({
      type: GET_DEAL_ACTIVITY_LOG_REQUEST,
      payload: {
        dealId,
        options: { page, limit },
        deal_created_at,
      },
    });
    let data;
    try {
      const response = await request.get(
        `/api/v5/deals/${dealId}/log`,
        { page, limit },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading activity log for this deal, please try again.';
      dispatch({
        type: GET_DEAL_ACTIVITY_LOG_FAILURE,
        payload: {
          message,
          dealId,
          options: { page, limit },
          deal_created_at,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    if (data.success) {
      dispatch({
        type: GET_DEAL_ACTIVITY_LOG_SUCCESS,
        payload: {
          data: data.data,
          dealId,
          options: { page, limit },
          deal_created_at,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: GET_DEAL_ACTIVITY_LOG_FAILURE,
        payload: {
          message: data.message,
          dealId,
          options: { page, limit },
          deal_created_at,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
