/**
 * @format
 */

import * as types from '../actions';

import { assign } from '../../../helpers/object';

export default (state, type, payload) => {
  switch (type) {
    /** DELETE_NAV_UPDATE */
    case types.DELETE_NAV_UPDATE_REQUEST: {
      return assign(state, {
        deleteNavUpdateLoading: true,
      });
    }
    case types.DELETE_NAV_UPDATE_FAILURE: {
      return assign(state, {
        deleteNavUpdateLoading: false,
      });
    }
    case types.DELETE_NAV_UPDATE_SUCCESS: {
      const navUpdateState = { ...state.navUpdate };
      delete navUpdateState[payload.navUpdateId];
      return assign(state, {
        deleteNavUpdateLoading: false,
        navUpdate: navUpdateState,
      });
    }

    default:
      return state;
  }
};
