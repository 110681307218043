/**
 * Settings action creators
 * @format
 * @author Ritesh Shrivastav
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import isEmptyString from '../../helpers/string/isEmptyString';
import { enqueueNotification } from '../app';
import {
  setCache,
  getCache,
  getOrganizationSettingCacheKey,
} from '../../helpers/Cache';
import getWorkspaceViewType from '../../helpers/getWorkspaceViewType';
import setWorkspaceViewType from '../../helpers/setWorkspaceViewType';
import setPortfolioView from '../../helpers/setPortfolioViewType';
import getPortfolioView from '../../helpers/getPortfolioViewType';

export default reducers;

/**
 * Handles google connect
 * @return {Promise}     Resolves on success
 */
export const handleGoogleConnect = (code, state) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GOOGLE_CONNECT_REQUEST,
      });
      request
        .post('/api/v1/settings/google', { code, state }, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.GOOGLE_CONNECT_SUCCESS,
            });
            resolve(data.data);
          } else {
            dispatch({
              type: types.GOOGLE_CONNECT_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            const err = new Error(data.message);
            err.code = data.code; // if error is that user is not registered, this'll be 103
            err.data = data.data; // this can contain user's email address
            reject(err);
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while connecting google account, please try again.';
          dispatch({
            type: types.GOOGLE_CONNECT_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const handleMicrosoftConnect = (code, state) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.MICROSOFT_CONNECT_REQUEST,
      });
      request
        .post('/api/v1/settings/microsoft', { code, state }, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.MICROSOFT_CONNECT_SUCCESS,
            });
            resolve(data.data);
          } else {
            dispatch({
              type: types.MICROSOFT_CONNECT_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            const err = new Error(data.message);
            err.code = data.code;
            err.data = data.data;
            reject(err);
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while connecting Microsoft account, please try again.';
          dispatch({
            type: types.MICROSOFT_CONNECT_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

/**
 * Updates user profile
 * @param  {Object} form Fields to be updated
 * @return {Promise}     Resolves on success
 */
export const updateProfile = form => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_PROFILE_REQUEST,
      });
      request
        .post('/api/v1/users/profile', form, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_PROFILE_SUCCESS,
            });
            dispatch(
              enqueueNotification('Updated profile details successfully.')
            );
            resolve(data.data);
          } else {
            dispatch({
              type: types.UPDATE_PROFILE_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating profile, please try again.';
          dispatch({
            type: types.UPDATE_PROFILE_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

/**
 * Fetches google setting
 */
export const fetchGoogleSetting = () => {
  return dispatch => {
    dispatch({
      type: types.FETCH_GOOGLE_SETTING_REQUEST,
    });
    request
      .get('/api/v5/settings/google', {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.FETCH_GOOGLE_SETTING_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: types.FETCH_GOOGLE_SETTING_FAILURE,
            payload: data.message,
          });
          dispatch(enqueueNotification(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while fetching google settings, please try again.';
        dispatch({
          type: types.FETCH_GOOGLE_SETTING_FAILURE,
          payload: message,
        });
        dispatch(enqueueNotification(message));
      });
  };
};

/**
 * Fetches Microsoft Settings
 */
export const fetchMicrosoftSetting = () => {
  return dispatch => {
    dispatch({
      type: types.FETCH_MICROSOFT_SETTING_REQUEST,
    });
    request
      .get('/api/v5/settings/outlook', {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.FETCH_MICROSOFT_SETTING_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: types.FETCH_MICROSOFT_SETTING_FAILURE,
            payload: data.message,
          });

          dispatch(enqueueNotification(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while fetching microsoft settings. Try Again';
        dispatch({
          type: types.FETCH_MICROSOFT_SETTING_FAILURE,
          payload: message,
        });
        dispatch(enqueueNotification(message));
      });
  };
};

/*
 * Saves stages
 * @param {String} stage Stage to save.
 */
export const saveStage = stage => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.SAVE_STAGE_REQUEST,
      });
      request
        .post('/api/v1/settings/stage', { stage }, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.SAVE_STAGE_SUCCESS,
            });
            resolve(data.data);
          } else {
            dispatch({
              type: types.SAVE_STAGE_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message = 'Something went wrong, please try again.';
          dispatch({
            type: types.SAVE_STAGE_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

/*
 * Update deal view type
 * @param {String} selectedView Selected view
 */
export const updateDealViewType = selectedView => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_DEAL_VIEW_TYPE_REQUEST,
        payload: selectedView,
      });
      request
        .post('/api/v2/settings/deal_view', { selectedView }, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_DEAL_VIEW_TYPE_SUCCESS,
              payload: selectedView,
            });
            if (selectedView) {
              setWorkspaceViewType(selectedView);
            }
            resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_DEAL_VIEW_TYPE_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while saving selected view, please try again.';
          dispatch({
            type: types.UPDATE_DEAL_VIEW_TYPE_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

/*
 * Update portfolio view type
 * @param {String} selectedView Selected view
 */
export const updatePortfolioViewType = selectedView => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_PORTFOLIO_VIEW_TYPE_REQUEST,
        payload: selectedView,
      });
      request
        .post('/api/v2/settings/portfolio_view', { selectedView }, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_PORTFOLIO_VIEW_TYPE_SUCCESS,
              payload: selectedView,
            });
            if (selectedView) {
              setPortfolioView(selectedView);
            }
            resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_PORTFOLIO_VIEW_TYPE_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            // reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while saving selected view, please try again.';
          dispatch({
            type: types.UPDATE_DEAL_VIEW_TYPE_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

/*
 * Get deal view type
 * @param {String} stage Stage to save.
 */
export const getDealViewType = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_DEAL_VIEW_TYPE_REQUEST,
      });
      let cachedValue = getWorkspaceViewType();
      if (cachedValue) {
        // resolve from cache
        dispatch({
          type: types.LOAD_DEAL_VIEW_TYPE_SUCCESS,
          payload: cachedValue,
        });
        resolve({ success: true, data: cachedValue });
        return;
      }
      request
        .get('/api/v2/settings/deal_view', {}, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_DEAL_VIEW_TYPE_SUCCESS,
              payload: data.data,
            });
            if (data.data) {
              setWorkspaceViewType(data.data);
            }
            resolve(data);
          } else {
            dispatch({
              type: types.LOAD_DEAL_VIEW_TYPE_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading deal view, please try again.';
          dispatch({
            type: types.LOAD_DEAL_VIEW_TYPE_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

/*
 * Get portfolio view type
 * @param {String} stage Stage to save.
 */
export const getPortfolioViewType = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_PORTFOLIO_VIEW_TYPE_REQUEST,
      });
      let cachedValue = getPortfolioView();
      if (cachedValue) {
        // resolve from cache
        dispatch({
          type: types.LOAD_PORTFOLIO_VIEW_TYPE_SUCCESS,
          payload: cachedValue,
        });
        resolve({ success: true, data: cachedValue });
        return;
      }
      request
        .get('/api/v2/settings/portfolio_view', {}, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_PORTFOLIO_VIEW_TYPE_SUCCESS,
              payload: data.data,
            });
            if (data.data) {
              setPortfolioView(data.data);
            }
            resolve(data);
          } else {
            dispatch({
              type: types.LOAD_PORTFOLIO_VIEW_TYPE_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            // reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading deal view, please try again.';
          dispatch({
            type: types.LOAD_PORTFOLIO_VIEW_TYPE_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

/*
 * Update FCM registration tokens
 * @param {String} selectedView Selected view
 */
export const updateFCMRegistrationTokens = newToken => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_FCM_REGISTRATION_TOKENS_REQUEST,
        payload: newToken,
      });
      request
        .post(
          '/api/v2/settings/fcm_registration_tokens',
          { registration_token: newToken },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_FCM_REGISTRATION_TOKENS_SUCCESS,
              payload: newToken,
            });
            resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_FCM_REGISTRATION_TOKENS_FAILURE,
              payload: data.message,
            });
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating fcm registration token';
          dispatch({
            type: types.UPDATE_FCM_REGISTRATION_TOKENS_FAILURE,
            payload: message,
          });
          reject(err);
        });
    });
  };
};

/*
 * Get setting value by key
 * @param {String} key Key for setting to fetch
 */
export const getValueByKey = (key, options = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      // Resolve without any data, without making any api request if key is empty
      if (isEmptyString(key)) {
        return resolve();
      }
      dispatch({
        type: types.LOAD_SETTING_VALUE_BY_KEY_REQUEST,
        payload: {
          key,
        },
      });
      request
        .get(`/api/v2/settings/key/${key}`, (options = {}), dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_SETTING_VALUE_BY_KEY_SUCCESS,
              payload: {
                key,
                value: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.LOAD_SETTING_VALUE_BY_KEY_FAILURE,
              payload: {
                key,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading settings, please try again.';
          dispatch({
            type: types.LOAD_SETTING_VALUE_BY_KEY_FAILURE,
            payload: {
              key,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

/**
 * Sets the user setting value in redux, should be called in the context of redux
 * @param {String} key
 * @param {String} value
 * @returns
 */
export const setUserSettingValueByKey = (key, value) => async dispatch => {
  if (isEmptyString(key)) {
    const message = `user setting key empty`;
    dispatch({
      type: types.UPDATE_SETTING_VALUE_BY_KEY_FAILURE,
      payload: {
        message,
        key,
      },
    });
    throw new Error(message);
  }

  dispatch({
    type: types.UPDATE_SETTING_VALUE_BY_KEY_REQUEST,
    payload: {
      key,
    },
  });
  let data;
  try {
    const response = await request.put(
      `/api/v2/settings/key/${key}`,
      { value },
      dispatch
    );
    data = response.data;
  } catch (err) {
    const message = `Something went wrong while updating this`;
    dispatch({
      type: types.UPDATE_SETTING_VALUE_BY_KEY_FAILURE,
      payload: {
        key,
        message,
      },
    });
    throw new Error(err.message);
  }

  if (!data.success) {
    dispatch({
      type: types.UPDATE_SETTING_VALUE_BY_KEY_FAILURE,
      payload: {
        key,
        message: data.message,
      },
    });

    throw new Error(data.message);
  }
  dispatch({
    type: types.UPDATE_SETTING_VALUE_BY_KEY_SUCCESS,
    payload: {
      key,
      data: data.data,
    },
  });
  return data.data;
};

/*
 * Get Organization setting value by key
 * @param {String} key Key for setting to fetch
 */
export const getOrganizationSettingValueByKey = (
  key,
  queryOpts = {},
  options = {}
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      // Resolve without any data, without making any api request if key is empty
      if (isEmptyString(key)) {
        return resolve();
      }
      dispatch({
        type: types.LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_REQUEST,
        payload: {
          key,
        },
      });
      let cacheKey = getOrganizationSettingCacheKey(key);
      if (options.useCache) {
        const cachedValue = getCache(cacheKey);
        if (cachedValue !== null) {
          dispatch({
            type: types.LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_SUCCESS,
            payload: {
              key,
              value: cachedValue,
            },
          });
          return resolve({ success: true, data: cachedValue });
        }
      }
      request
        .get(`/api/v2/settings/organization/key/${key}`, queryOpts, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_SUCCESS,
              payload: {
                key,
                value: data.data,
              },
            });
            setCache(cacheKey, data.data, { expires_on: options.expires_on });
            return resolve(data);
          } else {
            dispatch({
              type: types.LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_FAILURE,
              payload: {
                key,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading settings, please try again.';
          dispatch({
            type: types.LOAD_ORGANIZATION_SETTING_VALUE_BY_KEY_FAILURE,
            payload: {
              key,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

/*
 * Updates Organization setting value by key
 * @param {String} key Key for setting to update
 * @param {Any} value Value to update
 */
export const updateOrganizationSettingValueByKey = (key, value) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      // Resolve without any data, without making any api request if key is empty
      if (isEmptyString(key)) {
        return resolve();
      }
      dispatch({
        type: types.UPDATE_ORGANIZATION_SETTING_BY_KEY_REQUEST,
        payload: {
          key,
        },
      });
      request
        .put(`/api/v2/settings/organization/key/${key}`, { value }, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_ORGANIZATION_SETTING_BY_KEY_SUCCESS,
              payload: {
                key,
                value: data.data,
              },
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_ORGANIZATION_SETTING_BY_KEY_FAILURE,
              payload: {
                key,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating settings, please try again.';
          dispatch({
            type: types.UPDATE_ORGANIZATION_SETTING_BY_KEY_FAILURE,
            payload: {
              key,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

/*
 * Update Notification Email Unread summary setting
 * @param {String} newValue Selected view
 */
export const updateNotificationEmailUnreadSummary = newValue => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_REQUEST,
        payload: newValue,
      });
      request
        .put(
          '/api/v2/settings/notifications_email_unread_summary',
          { value: newValue },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_SUCCESS,
              payload: newValue,
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_FAILURE,
              payload: data.message,
            });
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating unread notification summary settings';
          dispatch({
            type: types.UPDATE_UNREAD_NOTIFICATIONS_SUMMARY_FAILURE,
            payload: message,
          });
          reject(err);
        });
    });
  };
};

/*
 * Update Deal assignment Notification Email setting
 * @param {String} newValue Selected view
 */
export const updateNotificationEmailDealAssignment = newValue => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_REQUEST,
        payload: newValue,
      });
      request
        .put(
          '/api/v2/settings/notifications_email_assigned_to_you',
          { value: newValue },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_SUCCESS,
              payload: newValue,
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_FAILURE,
              payload: data.message,
            });
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating deal assignment notification settings';
          dispatch({
            type: types.UPDATE_NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_FAILURE,
            payload: message,
          });
          reject(err);
        });
    });
  };
};

/*
 * Update Signals Summary Notification Email setting
 * @param {String} newValue Selected view
 */
export const updateNotificationEmailSignalsSummary = newValue => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_REQUEST,
        payload: newValue,
      });
      request
        .put(
          '/api/v2/settings/notifications_email_signals_summary',
          {
            value: newValue,
          },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_SUCCESS,
              payload: newValue,
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_FAILURE,
              payload: data.message,
            });
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating signals summary notification settings';
          dispatch({
            type: types.UPDATE_NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_FAILURE,
            payload: message,
          });
          reject(err);
        });
    });
  };
};

/*
 * Update Hide Deal Activity Log setting
 * @param {String} newValue Selected view
 */
export const updateDealHideActivityLogSetting = newValue => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_HIDE_DEAL_ACTIVITY_LOG_REQUEST,
        payload: newValue,
      });
      request
        .put(
          '/api/v2/settings/deal_hide_activity_log',
          { value: newValue },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_HIDE_DEAL_ACTIVITY_LOG_SUCCESS,
              payload: newValue,
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_HIDE_DEAL_ACTIVITY_LOG_FAILURE,
              payload: data.message,
            });
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating activity log visibility settings';
          dispatch({
            type: types.UPDATE_HIDE_DEAL_ACTIVITY_LOG_FAILURE,
            payload: message,
          });
          reject(err);
        });
    });
  };
};

/*
 * Update Flag Full Sync Gmail setting
 * @param {String} newValue Selected view
 */
export const updateFlagFullSyncGmail = newValue => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_FLAG_FULL_SYNC_GMAIL_REQUEST,
        payload: newValue,
      });
      request
        .put(
          '/api/v2/settings/flag_full_sync_gmail',
          { value: newValue },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            const message = `We have triggered a background job to sync your emails. Please check after some time`;
            dispatch({
              type: types.UPDATE_FLAG_FULL_SYNC_GMAIL_SUCCESS,
              payload: newValue,
            });
            dispatch(enqueueNotification(message));
            return resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_FLAG_FULL_SYNC_GMAIL_FAILURE,
              payload: data.message,
            });
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating signals summary notification settings';
          dispatch({
            type: types.UPDATE_FLAG_FULL_SYNC_GMAIL_FAILURE,
            payload: message,
          });
          reject(err);
        });
    });
  };
};
