/**
 * Privacy component
 *
 * @author Akhila
*/
import Footer from '../Footer';
import HeadManager from '../HeadManager';
import PublicPage from '../PublicPage';
import React from 'react';

class Privacy extends React.PureComponent {
  render() {
    return(
      <PublicPage header="Privacy Policy">
        <HeadManager title="Privacy Policy" />
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <ol>
              <li>
                <h4>DEFINITIONS</h4>
                <p>The Parties to this Privacy Policy hereby unconditionally agree that unless the context otherwise requires, the terms listed below when used in this Privacy Policy shall have the meanings attached to them and these terms shall be interpreted accordingly. The terms listed below as used in this Privacy Policy may be identified by the capitalization of the first letter of each principal word thereof. In addition to the terms defined below, the meanings of the terms defined in the Terms of Use executed between the Customer, the End User and the Company shall be made applicable mutatis mutandis to this Privacy Policy:</p>
                <p>"<strong>Public Information</strong>" shall mean any information that is available to anyone on or off our Services and can be seen or accessed through online search engines, APIs, and offline media, such as on TV.</p>
                <p>"<strong>Collected Information</strong>" shall have the meaning ascribed to it in Clause 3.1.</p>
                <p>"<strong>Collected Information</strong>" shall have the meaning ascribed to it in Clause 3.1.</p>
                <p>"<strong>Team</strong>" shall mean a designated group of people inside the Customer’s organisation that carry out a specific function together.</p>
                <p>"<strong>End User</strong>" shall mean the individual employee/consultant/authorised representative of the Customer that makes use of Portal.</p>
              </li>

              <li>
                <p>In this Terms of Use, except to the extent that the context otherwise requires:</p>
                <ol>
                  <li><p>References to a statute, ordinance or other Law shall be deemed to include any references to a statute, ordinance or other Law as amended, supplemented  or replaced from time to time in accordance with its terms and (where applicable) subject to compliance with the requirements set forth therein and shall include regulations and other instruments under such statue, ordinance or other Law;</p></li>
                  <li><p>References to Clauses, are reference to clauses in this Terms of Use unless the context requires otherwise and the recitals to this Terms of Use shall always be deemed to form part of this Terms of Use;</p></li>
                  <li><p>The headings are inserted for convenience only and shall not affect the construction of this Terms of Use;</p></li>
                  <li><p>The masculine gender includes the feminine gender and vice versa.</p></li>
                  <li><p>In the context of this Terms of Use, the Parties agree that their respective rights and obligations under this Terms of Use shall be interpreted, acted upon and governed in accordance with the terms and conditions of this Terms of Use.</p></li>
                </ol>
              </li>

              <li>
                <h4>DATA COLLECTION</h4>
                <ol>
                  <li>
                    <p>The Company shall collect and store the following information (“hereinafter referred to as “<strong>Collected Information</strong>”) :</p>
                    <ol>
                      <li>Activities of all the End Users on the Portal</li>
                      <li>Device information of the End User.</li>
                      <li>Information from the third party partners.</li>
                      <li>Activities of end users on related services (Taghash for Outlook add-in, Taghash for Gmail chrome extension etc.) </li>
                    </ol>
                  </li>

                  <li>
                    <p>
                      The Company shall use Collected Information for the following reasons:
                    </p>
                    <ol>
                      <li>Provide, improve, develop TagHash Service and/or TagHash</li>
                      <li>Communicate with the Customer as well as the End User in a more efficient and effective manner</li>
                      <li>To promote safety and security</li>
                      <li>For better End User experience</li>
                      <li>For more effective dissemination of the information within the Customer’s organisation</li>
                      <li>For better decisions and better choices</li>
                      <li>For displaying and measuring the services rendered through TagHash</li>
                      <li>For research and data analytics.</li>
                    </ol>
                  </li>

                  <li>
                    <p>
                      The Company reserves the right to share the Collected Information which can be used for personally identification of any User (hereinafter referred to as “<strong>Non-Aggregated Collected Information</strong>”) only in the following instances:
                    </p>
                    <ol>
                      <li>At the instance the End User is engaged any suspicious activity, which is in contravention of Applicable Law and/or the policies of the Customer’s organisation</li>
                      <li>If required under Applicable Law or for a bona fide purpose to facilitate a judicial proceeding.</li>
                      <li>In case of violation or perceived violations of any of these clauses in the Terms of Use and/or any other policies of the Company.</li>
                      <li>In case of violation of any third party rights</li>
                      <li>In case of change of ownership of Company, to such new owner.</li>
                    </ol>
                  </li>

                  <li><p>Notwithstanding anything contained herein, the Company shall not sell any Non-Aggregated Collected Information to any third party.</p></li>
                  <li><p>The Company reserves the right to share the Collected Information which cannot be used for personally identification of any User (hereinafter referred to as “<strong>Aggregated Collected Information</strong>”) to any third party.</p></li>
                  <li><p>Non-Aggregated information does not include Public information.</p></li>
                  <li><p>The Company does not take any responsibility for misuse of the Public Information on or off TagHash.</p></li>
                  <li><p>The Customer as well as the End User grants the Company a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any intellectual property in relation to any information and/or content that is shared by the Customer as well as the  End User on or off TagHash in connection with the Service. The license shall persist even after the deletion of such information and/or content from TagHash in case the Company has backed up the data for requirement under Applicable Law or for the purposes laid down and those incidental thereto in Clauses 3.1, 3.2 and 3.3.</p></li>
                  <li><p>Notwithstanding anything contained herein, all the information that is made available to the portal by the Customer and/or the End User is at his own risk.</p></li>
                  <li><p>Each End User specifically and explicitly allows the Company to have complete access any and all content in relation to their mailer accounts (Gmail or Outlook as applicable) within the Customer’s organisation for the purpose of free flow and streamlined dissemination of information within the Team.</p></li>
                  <li><p>The Company shall be required to obtain the consent of the End User, at the instance the Company has to make available any information from the mailer accounts (Gmail or Outlook as applicable) of the End User to any person who is not part of the Team that such End User belongs to.</p></li>
                  <li><p>Taghash has applied reasonable security and procedures to protect any and all information made available by the Customer. However, the Customer understands, affirms, acknowledges and agrees that in case the data is leaked and/or otherwise hacked into by malicious third parties using mechanisms which are not reasonably foreseeable by the Company, it shall not be liable for such data leak and/or breach of privacy.</p></li>
                </ol>
              </li>

              <li>
                <h4>COOKIES</h4>
                <p>TagHash uses the following types of Cookies:</p>
                <ol>
                  <li><p><strong>Essential</strong>: TagHash uses cookies necessary to enable or enhance certain functionality of TagHash, such as recalling the End User’s recent actions on TagHash or remembering the End User’s account settings. If the End User has Cookies disabled in his browser, these Cookies will be blocked.</p></li>
                  <li><p><strong>Non-essential</strong>: TagHash uses Google Analytics’ cookies. If the End User has Cookies disabled in his browser, these Cookies will be blocked. Google Analytics is a web analysis service that is offered by Google Inc. Google Analytics uses cookies to analyse the usage of TagHash by the End Users to give the Company an insight in the way User uses TagHash.</p></li>
                  <li><p>During use of TagHash, the End User may enter into correspondence with, purchase goods and/or services from, or participate in promotions of third party service providers, advertisers or sponsors showing their goods and/or services through a link on TagHash. The End User understands that the links take him off TagHash and are beyond the control of the Company. The End User understands that these links to may take him to websites, applications, services and/or platforms that have their own separate terms and conditions as well as privacy policy. For abundant cautions, it is hereby clarified that the Company shall not be responsible and cannot be held liable for the content and activities of these websites applications, services and/or platforms. The End User understands that he visits or accesses these websites, applications, services and/or platforms entirely at his own risk.</p></li>
                  <li><p>The End User understands that the relationship between the Company and the End User stands independent and is unrelated to the relationship between the End User and the application store from which TagHash is downloaded by him.</p></li>
                  <li><p>The Customer and/or the End User understands, appreciates, acknowledges and agrees that the Company reserves the right to amend and revise this Policy at any time, without notice to any Party, and that the Customer and/or the End User specifically covenant to abide by such amended Policy in the form and manner as may be prescribed therein.</p></li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
        <Footer />
      </PublicPage>
    )
  }
}

export default Privacy;
