import * as types from './actions';

const initialState = {
  emails: {},
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case types.CLEAR_EMAILS:
      return Object.assign({}, state, {
        emails: {}
      });

    /**
     * Email list reducers
     */
    case types.LOAD_EMAIL_LIST_SUCCESS:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          ...payload.reduce((result, item) => {
            result[item.id] = state.emails[item.id] ? {
              ...state.emails[item.id],
              ...item
            } : item;
            return result;
          }, {}),
        }
      });

    /**
     * Email reducers
     */
    case types.LOAD_EMAIL_REQUEST:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: (state.emails[payload.emailId] ? {
            ...state.emails[payload.emailId],
            loadEmailLoading: true,
            loadEmailError: null
          } : {
            loadEmailLoading: true,
            loadEmailError: null
          })
        }
      });
    case types.LOAD_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: {
            ...payload.data,
            loadEmailLoading: false,
            loadEmailError: null
          }
        }
      });
    case types.LOAD_EMAIL_FAILURE:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: (state.emails[payload.emailId] ? {
            ...state.emails[payload.emailId],
            loadEmailLoading: false,
            loadEmailError: payload.message,
          } : {
            loadEmailLoading: false,
            loadEmailError: payload.message,
          })
        }
      })

    /**
     * Email to new deal reducers
     */
    case types.CREATE_DEAL_REQUEST:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: (state.emails[payload.emailId] ? {
            ...state.emails[payload.emailId],
            createDealLoading: true,
            createDealError: null
          } : {
            createDealLoading: true,
            createDealError: null
          })
        }
      });
    case types.CREATE_DEAL_SUCCESS:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: {
            ...payload.data.email,
            createDealLoading: false,
            createDealError: null
          }
        }
      });
    case types.CREATE_DEAL_FAILURE:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: (state.emails[payload.emailId] ? {
            ...state.emails[payload.emailId],
            createDealLoading: false,
            createDealError: payload.message,
          } : {
            createDealLoading: false,
            createDealError: payload.message,
          })
        }
      });

    /**
     * Email to existing deal reducers
     */
    case types.ADD_TO_DEAL_REQUEST:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: (state.emails[payload.emailId] ? {
            ...state.emails[payload.emailId],
            addToDealLoading: true,
            addToDealError: null
          } : {
            addToDealLoading: true,
            addToDealError: null
          })
        }
      });
    case types.ADD_TO_DEAL_SUCCESS:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: {
            ...payload.data.email,
            addToDealLoading: false,
            addToDealError: null
          }
        }
      });
    case types.ADD_TO_DEAL_FAILURE:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: (state.emails[payload.emailId] ? {
            ...state.emails[payload.emailId],
            addToDealLoading: false,
            addToDealError: payload.message,
          } : {
            addToDealLoading: false,
            addToDealError: payload.message,
          })
        }
      })

    /**
     * Marking email as spam
     */
    case types.MARK_SPAM_REQUEST:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: (state.emails[payload.emailId] ? {
            ...state.emails[payload.emailId],
            markSpamLoading: true,
            markSpamError: null
          } : {
            markSpamLoading: true,
            markSpamError: null
          })
        }
      });
    case types.MARK_SPAM_SUCCESS:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: {
            ...payload.data,
            markSpamLoading: false,
            markSpamError: null
          }
        }
      });
    case types.MARK_SPAM_FAILURE:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: (state.emails[payload.emailId] ? {
            ...state.emails[payload.emailId],
            markSpamLoading: false,
            markSpamError: payload.message,
          } : {
            markSpamLoading: false,
            markSpamError: payload.message,
          })
        }
      });

    /**
     * Ignoring email
     */
    case types.IGNORE_EMAIL_REQUEST:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: (state.emails[payload.emailId] ? {
            ...state.emails[payload.emailId],
            ignoreEmailLoading: true,
            ignoreEmailError: null
          } : {
            ignoreEmailLoading: true,
            ignoreEmailError: null
          })
        }
      });
    case types.IGNORE_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: {
            ...payload.data,
            ignoreEmailLoading: false,
            ignoreEmailError: null
          }
        }
      });
    case types.IGNORE_EMAIL_FAILURE:
      return Object.assign({}, state, {
        emails: {
          ...state.emails,
          [payload.emailId]: (state.emails[payload.emailId] ? {
            ...state.emails[payload.emailId],
            ignoreEmailLoading: false,
            ignoreEmailError: payload.message,
          } : {
            ignoreEmailLoading: false,
            ignoreEmailError: payload.message,
          })
        }
      });
    case types.SET_EMAIL_DATA:
      return Object.assign({}, state, {
        emailData: {
          ...state.emailData,
          [payload.emailId]: {
            deals: payload.data.deals.map(deal => deal.id),
            portcos: payload.data.portcos.map(portco => portco.id),
            personContacts: payload.data.personContacts.map(
              person => person.id
            ),
            organizationContacts: payload.data.organizationContacts.map(
              orgContact => orgContact.id
            ),
          },
        },
      });

    default:
      return state;
  }
};
