import * as types from './actions';
import { LOAD_WORKSPACE_DEAL_STAGE_LIST_SUCCESS } from '../deal-stage/actions';
import isEmptyString from '../../helpers/string/isEmptyString';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';
import { setActiveWorkspacePositionId } from '../../helpers/setActiveWorkspacePositionId';

export default reducers;

export const loadOrganizationWorkspaces = (options={}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_ORGANIZATION_WORKSPACE_LIST_REQUEST
      });
      request.get(`/api/v3/workspaces`, {
        includeHidden: options.includeHidden,
        includeInactive: options.includeInactive,
        withStages: options.withStages,
        includeUnusedDealStages: options.includeUnusedDealStages,
        ignoreCache: options.ignoreCache,
        includePipelinesCountByMember: options.includePipelinesCountByMember,
        withWorkspaceRoles: options.withWorkspaceRoles,
      }, dispatch)
      .then(({ data }) => {
        if (data && data.success) {
          dispatch({
            type: types.LOAD_ORGANIZATION_WORKSPACE_LIST_SUCCESS,
            payload: {
              data: data.data
            }
          });
          if (options.withStages) {
            data.data.forEach(workspace => {
              dispatch({
                type: LOAD_WORKSPACE_DEAL_STAGE_LIST_SUCCESS,
                payload: {
                  data: workspace.stages,
                  workspaceId: workspace.id,
                  // Don't touch the `dealStages` state, since we have loaded
                  // data for multiple workspaces here, whereas, we keep
                  // only active workspace's stages in `dealStages`
                  shouldSkipDealStagesState: true,
                }
              });
            });
          }
          return resolve(data);
        } else {
          dispatch({
            type: types.LOAD_ORGANIZATION_WORKSPACE_LIST_FAILURE,
            payload: {
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        let message;
        message = `Something went wrong while loading organization's workspaces. Please try again`;
        dispatch({
          type: types.LOAD_ORGANIZATION_WORKSPACE_LIST_FAILURE,
          payload: {
            message
          }
        });
        dispatch(enqueueNotification(message));
        return reject(err);
      });
    });
  };
};

export const loadWorkspaceMembersRoles = (workspaceId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_WORKSPACE_MEMBERS_ROLES_REQUEST,
        payload: {workspaceId}
      });
      request.get(`/api/v3/workspaces/${workspaceId}/getMembersRoles`, {}, dispatch)
      .then(({ data }) => {
        if (data && data.success) {
          dispatch({
            type: types.LOAD_WORKSPACE_MEMBERS_ROLES_SUCCESS,
            payload: {
              workspaceId,
              data: data.data
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.LOAD_WORKSPACE_MEMBERS_ROLES_FAILURE,
            payload: {
              workspaceId,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        let message;
        message = `Something went wrong while loading workspace's members' roles. Please try again`;
        dispatch({
          type: types.LOAD_ORGANIZATION_WORKSPACE_LIST_FAILURE,
          payload: {
            workspaceId,
            message
          }
        });
        dispatch(enqueueNotification(message));
        return reject(err);
      });
    });
  };
};

export const addWorkspace = (form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.ADD_WORKSPACE_REQUEST,
        payload: form
      });
      request.post(`/api/v3/workspaces`, form, dispatch)
      .then(({ data }) => {
        if (data && data.success) {
          dispatch({
            type: types.ADD_WORKSPACE_SUCCESS,
            payload: {
              data: data.data
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.ADD_WORKSPACE_FAILURE,
            payload: {
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = `Something went wrong while adding this workspace. Please try again`;
        dispatch({
          type: types.ADD_WORKSPACE_FAILURE,
          payload: {
            message
          }
        });
        dispatch(enqueueNotification(message));
        return reject(err);
      });
    });
  };
}

export const updateWorkspace = (workspace_id, position_id, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (isEmptyString(workspace_id) || isNaN(position_id) || position_id < 0) {
        // nothing to update
        dispatch(enqueueNotification(`Nothing to update`));
        return reject(new Error(`Nothing to update`));
      }
      const propertiesToUpdate = [];
      if (!isEmptyString(form.name)) {
        propertiesToUpdate.push('name');
      }
      if (!isEmptyString(form.color)) {
        propertiesToUpdate.push('color');
      }
      if (!isNaN(form.position_id) && form.position_id > -1) {
        propertiesToUpdate.push('position_id');
      }
      if (form.membersRoles && form.membersRoles.length > 0) {
        propertiesToUpdate.push('membersRoles');
      }
      // No need to maintain these
      // if (form.membersRoles && form.membersRoles.length > 0) {
      //   propertiesToUpdate.push('membersRoles');
      // }
      if (propertiesToUpdate.length === 0) {
        // nothing to update
        dispatch(enqueueNotification(`Nothing to update`));
        return reject(new Error(`Nothing to update`));
      }
      dispatch({
        type: types.UPDATE_WORKSPACE_REQUEST,
        payload: {
          position_id,
          propertiesToUpdate
        }
      });
      request.put(`/api/v3/workspaces/${workspace_id}`, form, dispatch)
      .then(({ data }) => {
        if (data && data.success) {
          dispatch({
            type: types.UPDATE_WORKSPACE_SUCCESS,
            payload: {
              position_id,
              propertiesToUpdate,
              data: data.data,
              membersRoles: form.membersRoles,
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.UPDATE_WORKSPACE_FAILURE,
            payload: {
              position_id,
              propertiesToUpdate,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = `Something went wrong while updating this workspace. Please try again`;
        dispatch({
          type: types.UPDATE_WORKSPACE_FAILURE,
          payload: {
            position_id,
            propertiesToUpdate,
            message
          }
        });
        dispatch(enqueueNotification(message));
        return reject(err);
      });
    });
  };
};

export const softDeleteWorkspace = (workspace_id, position_id, {migrate_deals_to_workspace_id}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (isEmptyString(workspace_id) || isNaN(position_id) || position_id < 0) {
        // nothing to update
        dispatch(enqueueNotification(`Nothing to delete`));
        return reject(new Error(`Nothing to delete`));
      }
      if (isEmptyString(migrate_deals_to_workspace_id)) {
        const message = `Missing reference to fallback workspace for migrating deals`;
        // nothing to update
        dispatch(enqueueNotification(message));
        return reject(new Error(message));
      }
      dispatch({
        type: types.SOFT_DELETE_WORKSPACE_REQUEST,
        payload: {
          position_id,
        }
      });
      request.del(`/api/v3/workspaces/${workspace_id}`, {migrate_deals_to_workspace_id}, dispatch)
      .then(({ data }) => {
        if (data && data.success) {
          dispatch({
            type: types.SOFT_DELETE_WORKSPACE_SUCCESS,
            payload: {
              position_id,
              data: data.data
            }
          });
          return resolve(data);
        } else {
          dispatch({
            type: types.SOFT_DELETE_WORKSPACE_FAILURE,
            payload: {
              position_id,
              message: data.message
            }
          });
          dispatch(enqueueNotification(data.message));
          return reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = `Something went wrong while deleting this workspace. Please try again`;
        dispatch({
          type: types.SOFT_DELETE_WORKSPACE_FAILURE,
          payload: {
            position_id,
            message
          }
        });
        dispatch(enqueueNotification(message));
        return reject(err);
      });
    });
  };
};

export const switchWorkspace = (position_id) => {
  return dispatch => {
    dispatch({
      type: types.SWITCH_WORKSPACE,
      payload: {
        position_id,
      }
    });
    setActiveWorkspacePositionId(position_id);
  }
}
