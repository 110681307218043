/**
 * Alert component, used to show global notification.
 *
 * @author Ritesh Shrivastav
 * @format
 */
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    maxWidth: '75vw',
  },
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
});

class Alert extends React.PureComponent {
  static propTypes = {
    dequeueNotification: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    offline: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
  };

  handleClose = () => {
    this.props.dequeueNotification();
  };

  render() {
    const { notifications, offline, classes } = this.props;
    let notification;
    if (offline) {
      notification = {
        message:
          'Internet connection problem, please check your Internet connection.',
        reload: true,
      };
    } else if (notifications && notifications.length > 0) {
      notification = notifications[0];
    }
    if (!notification) return null;
    const action = [];
    if (notification.reload) {
      action.push(
        <Button
          key="reload"
          color="secondary"
          size="small"
          onClick={() => {
            window.location.reload(true);
          }}
        >
          RELOAD
        </Button>
      );
    }
    return (
      <Snackbar
        className={classes.root}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={true}
        message={<span>{notification.message}</span>}
        autoHideDuration={
          notification.reload
            ? null
            : notification.sticky
            ? null
            : notification.duration
        }
        onClose={this.handleClose}
        action={[
          ...action,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

export default withStyles(styles)(Alert);
