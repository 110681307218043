/*
@format
*/
import * as types from './actions';
const initialState = {
  suggestionsList: [],
  suggestionsListLoading: false,
  suggestionsListError: null,
  discardSuggestionsLoading: [],
  addDealSuggestionsLoading: [],
  metaData: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * MagicSync Suggestions list reducers
     */
    case types.LOAD_MAGIC_SYNC_SUGGESTIONS_REQUEST:
      return Object.assign({}, state, {
        suggestionsListLoading: true,
        suggestionsListError: null,
      });
    case types.LOAD_MAGIC_SYNC_SUGGESTIONS_SUCCESS:
      return Object.assign({}, state, {
        suggestionsList: payload.isDateChanged
          ? payload.contacts || []
          : state.suggestionsList.concat(payload.contacts || []),
        suggestionsListLoading: false,
        metaData: payload.meta,
      });
    case types.LOAD_MAGIC_SYNC_SUGGESTIONS_FAILURE:
      return Object.assign({}, state, {
        suggestionsListLoading: false,
        suggestionsListError: payload.message,
      });
    case types.ADD_DEAL_FROM_CONTACT_REQUEST:
      // Here payload is an array of added contacts as deals
      return { ...state, addDealSuggestionsLoading: [...payload] };
    case types.ADD_DEAL_FROM_CONTACT_SUCCESS:
      const addedIds = payload;
      const SuggestionListAfterAdded = state.suggestionsList.map(suggestion => {
        if (addedIds.includes(suggestion.id)) {
          return { ...suggestion, status: 'accepted' };
        }
        return suggestion;
      });
      return {
        ...state,
        addDealSuggestionsLoading: [],
        suggestionsList: SuggestionListAfterAdded,
      };
    case types.ADD_DEAL_FROM_CONTACT_FAILURE:
      return { ...state, addDealSuggestionsLoading: [] };
    case types.DISCARD_FROM_CONTACT_REQUEST:
      // payload is the array of discardIds.
      return { ...state, discardSuggestionsLoading: [...payload] };
    case types.DISCARD_FROM_CONTACT_SUCCESS:
      const discardedIds = payload;
      const SuggestionListAfterDiscared = state.suggestionsList.map(
        suggestion => {
          if (discardedIds.includes(suggestion.id)) {
            return { ...suggestion, status: 'rejected' };
          }
          return suggestion;
        }
      );
      return {
        ...state,
        discardSuggestionsLoading: [],
        suggestionsList: SuggestionListAfterDiscared,
      };
    case types.DISCARD_FROM_CONTACT_FAILURE:
      return { ...state, discardSuggestionsLoading: [] };
    case types.UPDATE_SUGGESTION_DEAL_NAME:
      const { suggestionId, dealName } = payload;
      const updatedSuggestionsList = state.suggestionsList.map(suggestion => {
        if (suggestion.id === suggestionId) {
          return {
            ...suggestion,
            name: dealName,
          };
        }
        return suggestion;
      });
      return {
        ...state,
        suggestionsList: updatedSuggestionsList,
      };
    default:
      return state;
  }
};
