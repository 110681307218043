/**
 * Removes a founder from the startup associated with the deal
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  REMOVE_STARTUP_FOUNDER_REQUEST,
  REMOVE_STARTUP_FOUNDER_SUCCESS,
  REMOVE_STARTUP_FOUNDER_FAILURE } from '../actions';

export default (dealId, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: REMOVE_STARTUP_FOUNDER_REQUEST
      });
      request.post(`/api/v1/pipelines/${dealId}/startup/founders/remove`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: REMOVE_STARTUP_FOUNDER_SUCCESS,
            payload: data.data
          });
          resolve(data.data);
        } else {
          dispatch({
            type: REMOVE_STARTUP_FOUNDER_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while removing founder, please try again.';
        dispatch({
          type: REMOVE_STARTUP_FOUNDER_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
