export const LIST_REMINDER_DASHBOARD_REQUEST=`reminder-dashboard/LIST_REMINDER_DASHBOARD_REQUEST`;
export const LIST_REMINDER_DASHBOARD_SUCCESS=`reminder-dashboard/LIST_REMINDER_DASHBOARD_SUCCESS`;
export const LIST_REMINDER_DASHBOARD_FAILURE=`reminder-dashboard/LIST_REMINDER_DASHBOARD_FAILURE`;

export const RESET_REMINDER_STATE=`reminder-dashboard/RESET_REMINDER_STATE`

export const UPDATE_REMINDER_DASHBOARD_REQUEST = `reminder-dashboard/UPDATE_REMINDER_DASHBOARD_REQUEST`;
export const UPDATE_REMINDER_DASHBOARD_SUCCESS = `reminder-dashboard/UPDATE_REMINDER_DASHBOARD_SUCCESS`;
export const UPDATE_REMINDER_DASHBOARD_FAILURE = `reminder-dashboard/UPDATE_REMINDER_DASHBOARD_FAILURE`;





export const DELETE_REMINDER_DASHBOARD_REQUEST = `reminder-dashboard/DELETE_REMINDER_DASHBOARD_REQUEST`;
export const DELETE_REMINDER_DASHBOARD_SUCCESS = `reminder-dashboard/DELETE_REMINDER_DASHBOARD_SUCCESS`;
export const DELETE_REMINDER_DASHBOARD_FAILURE = `reminder-dashboard/DELETE_REMINDER_DASHBOARD_FAILURE`;

