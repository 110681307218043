/**
 * Fetches deal details
 *
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_DEAL_REQUEST,
  LOAD_DEAL_SUCCESS,
  LOAD_DEAL_FAILURE } from '../actions';

export default (dealId, options) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LOAD_DEAL_REQUEST,
        payload: { dealId }
      });
      request.get(`/api/v5/deals/${dealId}`, options, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: LOAD_DEAL_SUCCESS,
            payload: {
              data: data.data,
              dealId
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: LOAD_DEAL_FAILURE,
            payload: {
              message: data.message,
              dealId
            }
          });
          dispatch(enqueueNotification(data.message));
          // Rejecting full data to get the code which helps to understand the type
          // of error.
          // TODO: Add Error class ented that class for customized classes.
          reject(data);
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading deal, please try again.'
        dispatch({
          type: LOAD_DEAL_FAILURE,
          payload: { message, dealId }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
