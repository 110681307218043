/**
 * @format
 */
import supportsHTML5Storage from './supportsHTML5Storage';
import Time from '../components/Time';

const setWorkspaceViewType = selectedViewType => {
  if (!supportsHTML5Storage()) {
    return;
  }
  let viewType = window.localStorage.getItem('viewType');
  if (viewType && typeof viewType === 'string') {
    try {
      viewType = JSON.parse(viewType);
      // If expires_on is in the past, expire
      if (new Date(viewType.expires_on) < new Date()) {
        window.localStorage.removeItem('viewType');
        viewType = { value: null };
      }
    } catch (e) {
      return null;
    }
  } else {
    viewType = { value: null };
  }
  const payload = {
    value: selectedViewType,
    expires_on: Time.add(new Date(), 1, 'day'), // when we consider this key expired
  };
  window.localStorage.removeItem('viewType');
  window.localStorage.setItem('viewType', JSON.stringify(payload));
};

export default setWorkspaceViewType;
