/**
 * Email action creators and reducers
 *
 * @author Ritesh Shrivastav
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

/**
 * Loads all emails
 * @param  {Object} options List options
 */
export const loadEmailList = (options) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_EMAIL_LIST_REQUEST
      });
      // since we are enabling email search feat for particular org currently, we have this if-else condition
      // once we impliment it for all the orgs, we have to remove this condition and make req to `api/email/v1`
      let url;
      if(options.isEmailSearchEnabled) url = `/api/email/v1`
      else url = `/api/v1/emails`
      request.get(url,options, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_EMAIL_LIST_SUCCESS,
            payload: data.data
          });
          resolve(data);
        } else {
          dispatch({
            type: types.LOAD_EMAIL_LIST_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading emails, please try again.';
        dispatch({
          type: types.LOAD_EMAIL_LIST_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};


/**
 * Loads email details
 * @param  {String} emailId Email id
 */
export const loadEmail = (emailId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_EMAIL_REQUEST,
        payload: {
          emailId: emailId
        }
      });
      request.get(`/api/v1/emails/${emailId}`, {
        withAttachments: true
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_EMAIL_SUCCESS,
            payload: {
              data: data.data,
              emailId: emailId
            }
          });
          resolve(data.data);
        } else {
          dispatch({
            type: types.LOAD_EMAIL_FAILURE,
            payload: {
              message: data.message,
              emailId: emailId
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading email, please try again.';
        dispatch({
          type: types.LOAD_EMAIL_FAILURE,
          payload: {
            message,
            emailId: emailId
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  }
};

/**
 * Creates new deal from email data
 * @param  {String} emailId Email id
 * @param  {Object} form    Request body
 * @return {Promise}        Resolves on success
 */
export const createDeal = (emailId, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.CREATE_DEAL_REQUEST,
        payload: {
          emailId: emailId
        }
      });
      request
        .post(
          `/api/email-processor/v1/create-deal`,
          {
            ...form,
            email_id: emailId,
          },
          dispatch
        )
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.CREATE_DEAL_SUCCESS,
            payload: {
              data: data.data,
              emailId: emailId
            }
          });
          resolve(data.data);
        } else {
          dispatch({
            type: types.CREATE_DEAL_FAILURE,
            payload: {
              message: data.message,
              emailId: emailId
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while creating new deal, please try again.';
        dispatch({
          type: types.CREATE_DEAL_FAILURE,
          payload: {
            message,
            emailId: emailId
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Add email to existing deal
 * @param  {String} emailId Email id
 * @param  {Object} form    Request body
 * @return {Promise}        Resolves with data on success
 */
export const addToDeal = (emailId, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.ADD_TO_DEAL_REQUEST,
        payload: {
          emailId: emailId
        }
      });
      request
        .post(
          `/api/email-processor/v1/add-to-deal`,
          {
            ...form,
            email_id: emailId,
          },
          dispatch
        )
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.ADD_TO_DEAL_SUCCESS,
            payload: {
              data: data.data,
              emailId: emailId
            }
          });
          resolve(data.data);
        } else {
          dispatch({
            type: types.ADD_TO_DEAL_FAILURE,
            payload: {
              message: data.message,
              emailId: emailId
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while adding email to existing deal, please try again.';
        dispatch({
          type: types.ADD_TO_DEAL_FAILURE,
          payload: {
            message,
            emailId: emailId
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Report email as spam
 * @param  {String} emailId Email id
 * @return {Promise}        Resolves with data on success
 */
export const markSpam = (emailId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.MARK_SPAM_REQUEST,
        payload: {
          emailId: emailId
        }
      });
      request.post(`/api/v1/emails/${emailId}/spam`, {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.MARK_SPAM_SUCCESS,
            payload: {
              data: data.data,
              emailId: emailId
            }
          });
          resolve(data.data);
        } else {
          dispatch({
            type: types.MARK_SPAM_FAILURE,
            payload: {
              message: data.message,
              emailId: emailId
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while reporting this email as spam. Please try again.';
        dispatch({
          type: types.MARK_SPAM_FAILURE,
          payload: {
            message,
            emailId: emailId
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Ignore email
 * @param  {String} emailId Email id
 * @return {Promise}        Resolves with data on success
 */
export const ignoreEmail = (emailId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.IGNORE_EMAIL_REQUEST,
        payload: {
          emailId: emailId
        }
      });
      request.post(`/api/v1/emails/${emailId}/ignore`, {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.IGNORE_EMAIL_SUCCESS,
            payload: {
              data: data.data,
              emailId: emailId
            }
          });
          resolve(data.data);
        } else {
          dispatch({
            type: types.IGNORE_EMAIL_FAILURE,
            payload: {
              message: data.message,
              emailId: emailId
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while moving this email to trash. Please try again.';
        dispatch({
          type: types.IGNORE_EMAIL_FAILURE,
          payload: {
            message,
            emailId: emailId
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Clear all the prviously loaded emails in state if user visits email list page
 */
export const clearEmails = () => {
  return dispatch => {
    dispatch({
      type: types.CLEAR_EMAILS
    });
  };
};
