export const LOAD_COMMENT_LIST_REQUEST = 'portfolio-comment/LOAD_COMMENT_LIST_REQUEST';
export const LOAD_COMMENT_LIST_SUCCESS = 'portfolio-comment/LOAD_COMMENT_LIST_SUCCESS';
export const LOAD_COMMENT_LIST_FAILURE = 'portfolio-comment/LOAD_COMMENT_LIST_FAILURE';

export const LOAD_COMMENTS_COUNT_REQUEST = 'portfolio-comment/LOAD_COMMENTS_COUNT_REQUEST';
export const LOAD_COMMENTS_COUNT_SUCCESS = 'portfolio-comment/LOAD_COMMENTS_COUNT_SUCCESS';
export const LOAD_COMMENTS_COUNT_FAILURE = 'portfolio-comment/LOAD_COMMENTS_COUNT_FAILURE';

export const ADD_REPLY_REQUEST = 'portfolio-comment/ADD_REPLY_REQUEST';
export const ADD_REPLY_SUCCESS = 'portfolio-comment/ADD_REPLY_SUCCESS';
export const ADD_REPLY_FAILURE = 'portfolio-comment/ADD_REPLY_FAILURE';

export const BULK_LOAD_COMMENT_LIST = 'portfolio-comment/BULK_LOAD_COMMENT_LIST';
