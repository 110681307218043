/**
 * LIST_SIGN_OFF_ITEMS reducer
 * @format
 */
import {
  LIST_SIGN_OFF_ITEMS_REQUEST,
  LIST_SIGN_OFF_ITEMS_SUCCESS,
  LIST_SIGN_OFF_ITEMS_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case LIST_SIGN_OFF_ITEMS_REQUEST:
      return Object.assign({}, state, {
        listSignOffItemsLoading: {
          ...state.listSignOffItemsLoading,
          [payload.signOffGroupId]: true,
        },
        listSignOffItemsError: {
          ...state.listSignOffItemsError,
          [payload.signOffGroupId]: null,
        },
      });

    case LIST_SIGN_OFF_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        listSignOffItemsLoading: {
          ...state.listSignOffItemsLoading,
          [payload.signOffGroupId]: false,
        },
        listSignOffItemsError: {
          ...state.listSignOffItemsError,
          [payload.signOffGroupId]: null,
        },
        // Map sign off group ids to list of sign offs' ids
        signOffGroupsItemsHashMap: {
          ...state.signOffGroupsItemsHashMap,
          // no pagination required. All sign off items returned with a single request
          [payload.signOffGroupId]: payload.data.map(signOff => signOff.id),
        },
        // Populate sign off groups hashmap with returned data
        signOffs: {
          ...state.signOffs,
          ...payload.data.reduce((acc, signOff) => {
            acc[signOff.id] = signOff;
            return acc;
          }, {}),
        },
      });
    case LIST_SIGN_OFF_ITEMS_FAILURE:
      return Object.assign({}, state, {
        listSignOffItemsLoading: {
          ...state.listSignOffItemsLoading,
          [payload.signOffGroupId]: false,
        },
        listSignOffItemsError: {
          ...state.listSignOffItemsError,
          [payload.signOffGroupId]: payload.message,
        },
      });

    default:
      return state;
  }
};
