const STATUS_ARRAY = [
  true,
  false
];

const STATUS_MAP = {
  true: 'Open',
  false: 'Closed',
};

export {
  STATUS_ARRAY,
  STATUS_MAP,
};
