export const LOAD_FUND_LPS_REQUEST = 'limited-partner/LOAD_FUND_LPS_REQUEST';
export const LOAD_FUND_LPS_SUCCESS = 'limited-partner/LOAD_FUND_LPS_SUCCESS';
export const LOAD_FUND_LPS_FAILURE = 'limited-partner/LOAD_FUND_LPS_FAILURE';


export const LOAD_FUND_LPS_LIST_TAGS_REQUEST = 'limited-partner/LOAD_FUND_LPS_LIST_TAGS_REQUEST';
export const LOAD_FUND_LPS_LIST_TAGS_SUCCESS = 'limited-partner/LOAD_FUND_LPS_LIST_TAGS_SUCCESS';
export const LOAD_FUND_LPS_LIST_TAGS_FAILURE = 'limited-partner/LOAD_FUND_LPS_LIST_TAGS_FAILURE';

export const ADD_TAG_TO_LP_REQUEST = 'limited-partner/ADD_TAG_TO_LP_REQUEST';
export const ADD_TAG_TO_LP_SUCCESS = 'limited-partner/ADD_TAG_TO_LP_SUCCESS';
export const ADD_TAG_TO_LP_FAILURE = 'limited-partner/ADD_TAG_TO_LP_FAILURE';

export const REMOVE_TAG_FROM_LP_REQUEST = 'limited-partner/REMOVE_TAG_FROM_LP_REQUEST';
export const REMOVE_TAG_FROM_LP_SUCCESS = 'limited-partner/REMOVE_TAG_FROM_LP_SUCCESS';
export const REMOVE_TAG_FROM_LP_FAILURE = 'limited-partner/REMOVE_TAG_FROM_LP_FAILURE';

export const ADD_LP_TO_FUND_REQUEST = 'limited-partner/ADD_LP_TO_FUND_REQUEST';
export const ADD_LP_TO_FUND_SUCCESS = 'limited-partner/ADD_LP_TO_FUND_SUCCESS';
export const ADD_LP_TO_FUND_FAILURE = 'limited-partner/ADD_LP_TO_FUND_FAILURE';

export const LOAD_LP_REQUEST = 'limited-partner/LOAD_LP_REQUEST';
export const LOAD_LP_SUCCESS = 'limited-partner/LOAD_LP_SUCCESS';
export const LOAD_LP_FAILURE = 'limited-partner/LOAD_LP_FAILURE';

export const UPDATE_LP_REQUEST = 'limited-partner/UPDATE_LP_REQUEST';
export const UPDATE_LP_SUCCESS = 'limited-partner/UPDATE_LP_SUCCESS';
export const UPDATE_LP_FAILURE = 'limited-partner/UPDATE_LP_FAILURE';

export const DELETE_LP_REQUEST = 'limited-partner/DELETE_LP_REQUEST';
export const DELETE_LP_SUCCESS = 'limited-partner/DELETE_LP_SUCCESS';
export const DELETE_LP_FAILURE = 'limited-partner/DELETE_LP_FAILURE';

export const CLEAR = 'limited-partner/CLEAR';
