/**
 * @format
 */
import * as types from './actions';

const initialState = {
  /**
   * Holds state related to loading all of organization's note templates. No
   * namespacing needed for this state
   */
  noteTemplatesLoading: false,
  /**
   * Holds state related to error in loading all of organization's note templates.
   * No namespacing needed for this state
   */
  noteTemplatesError: null,
  /**
   * Holds state related to loading state of request to add new note template
   * No namespacing needed for this state
   */
  addNoteTemplateLoading: false,
  /**
   * Holds state related to error in adding new note template.
   * No namespacing needed for this state
   */
  addNoteTemplateError: null,
  /**
   * Holds note templates for an organization. Namespacing will be needed when we
   * support multiple single user to be part of multiple organizations
   *
   * This here is an object keyed by position_id, which are numbers, so it can be
   * treated both as an object (if you have the position_id), or as an array
   *
   * Warning: If you are planning to iterate through this list, iterate using
   * `for(let key in ob){...}` instead of the regular `for(let i=0;i<length;i++){...}`
   * because there may be indexes with missing values, because of logical bugs on
   * server end that you'll need to check for otherwise
   */
  noteTemplates: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // List organization noteTemplates
    case types.LOAD_NOTE_TEMPLATE_LIST_REQUEST:
      return Object.assign({}, state, {
        noteTemplatesLoading: true,
        noteTemplatesError: null,
      });
    case types.LOAD_NOTE_TEMPLATE_LIST_SUCCESS: {
      // Index noteTemplates by their position id
      let noteTemplates = [];
      if (
        payload &&
        payload.data &&
        payload.data instanceof Array &&
        payload.data.length > 0
      ) {
        // map list to noteTemplates array, keying with position_id
        payload.data.forEach(template => {
          noteTemplates[template.position_id] = {
            // preserve any previously loaded related data
            ...noteTemplates[template.position_id],
            ...template,
          };
        });
      }
      return Object.assign({}, state, {
        noteTemplatesLoading: false,
        noteTemplatesError: null,
        noteTemplates,
      });
    }
    case types.LOAD_NOTE_TEMPLATE_LIST_FAILURE:
      return Object.assign({}, state, {
        noteTemplatesLoading: false,
        noteTemplatesError: payload.message,
      });

    // Add new template
    case types.ADD_NOTE_TEMPLATE_REQUEST:
      return Object.assign({}, state, {
        addNoteTemplateLoading: true,
        addNoteTemplateError: null,
      });
    case types.ADD_NOTE_TEMPLATE_SUCCESS: {
      const noteTemplates = [...state.noteTemplates];
      // set at the position id returned by the response / at the last position
      noteTemplates[payload.data.position_id || noteTemplates.length] =
        payload.data;
      return Object.assign({}, state, {
        addNoteTemplateLoading: false,
        addNoteTemplateError: null,
        noteTemplates,
      });
    }
    case types.ADD_NOTE_TEMPLATE_FAILURE:
      return Object.assign({}, state, {
        addNoteTemplateLoading: false,
        addNoteTemplateError: payload.message,
      });

    // Update a template
    case types.UPDATE_NOTE_TEMPLATE_REQUEST:
      // Deep clone target template as it is in the current state
      const clonedTemplateForUpdateRequest = {
        ...state.noteTemplates[payload.position_id],
      };
      // If the target template is undefined (not present), then do nothing
      if (Object.keys(clonedTemplateForUpdateRequest).length === 0) {
        return state; // this shouldn't happen, really
      }
      // Deep clone second-level props
      clonedTemplateForUpdateRequest.loading = {
        ...clonedTemplateForUpdateRequest.loading,
      };
      clonedTemplateForUpdateRequest.error = {
        ...clonedTemplateForUpdateRequest.error,
      };
      // Update loading/error for the properties being updated
      payload.propertiesToUpdate.forEach(propertyToUpdate => {
        clonedTemplateForUpdateRequest.loading[propertyToUpdate] = true;
        clonedTemplateForUpdateRequest.error[propertyToUpdate] = null;
      });
      // Duplicate the array, placing the updated template object at its position index
      return Object.assign({}, state, {
        noteTemplates: [
          ...state.noteTemplates.slice(0, payload.position_id),
          clonedTemplateForUpdateRequest,
          ...state.noteTemplates.slice(payload.position_id + 1),
        ],
      });
    case types.UPDATE_NOTE_TEMPLATE_SUCCESS:
      // NOTE: We will not be taking care of re-positioning noteTemplates even
      // if position is updated with this request. The update in position has
      // to be synced with state by making a separate request to reload template
      // list. If we try to manage that here, we will end up introducing weird,
      // hard-to-debug bugs
      // Deep clone target template as it is in the current state
      const clonedTemplateForUpdateSuccess = {
        ...state.noteTemplates[payload.position_id],
      };
      // If the target template is undefined (not present), then do nothing
      if (Object.keys(clonedTemplateForUpdateSuccess).length === 0) {
        return state; // this shouldn't happen, really
      }
      clonedTemplateForUpdateSuccess.loading = {
        ...clonedTemplateForUpdateSuccess.loading,
      };
      clonedTemplateForUpdateSuccess.error = {
        ...clonedTemplateForUpdateSuccess.error,
      };
      // Remove unnecessary props
      payload.propertiesToUpdate.forEach(propertyToUpdate => {
        delete clonedTemplateForUpdateSuccess.loading[propertyToUpdate];
        delete clonedTemplateForUpdateSuccess.error[propertyToUpdate];
      });
      // If there are no keys left after deletion, delete loading and error props too
      if (Object.keys(clonedTemplateForUpdateSuccess.loading).length === 0) {
        delete clonedTemplateForUpdateSuccess.loading;
      }
      if (Object.keys(clonedTemplateForUpdateSuccess.error).length === 0) {
        delete clonedTemplateForUpdateSuccess.error;
      }
      // Duplicate the array, placing the updated template object at its position index
      return Object.assign({}, state, {
        noteTemplates: [
          ...state.noteTemplates.slice(0, payload.position_id),
          clonedTemplateForUpdateSuccess,
          ...state.noteTemplates.slice(payload.position_id + 1),
        ],
      });
    case types.UPDATE_NOTE_TEMPLATE_FAILURE:
      // Deep clone target template as it is in the current state
      const clonedTemplateForUpdateFailure = {
        ...state.noteTemplates[payload.position_id],
      };
      // If the target template is undefined (not present), then do nothing
      if (Object.keys(clonedTemplateForUpdateFailure).length === 0) {
        return state; // this shouldn't happen, really
      }
      // Deep clone second-level props
      clonedTemplateForUpdateFailure.loading = {
        ...clonedTemplateForUpdateFailure.loading,
      };
      clonedTemplateForUpdateFailure.error = {
        ...clonedTemplateForUpdateFailure.error,
      };
      // Update loading/error for the properties being updated
      payload.propertiesToUpdate.forEach(propertyToUpdate => {
        clonedTemplateForUpdateFailure.loading[propertyToUpdate] = false;
        clonedTemplateForUpdateFailure.error[propertyToUpdate] =
          payload.message;
      });
      // Duplicate the array, placing the updated template object at its position index
      return Object.assign({}, state, {
        noteTemplates: [
          ...state.noteTemplates.slice(0, payload.position_id),
          clonedTemplateForUpdateFailure,
          ...state.noteTemplates.slice(payload.position_id + 1),
        ],
      });

    // soft-delete a template
    case types.SOFT_DELETE_NOTE_TEMPLATE_REQUEST:
      // Deep clone target template as it is in the current state
      const clonedTemplateForDeleteRequest = {
        ...state.noteTemplates[payload.position_id],
      };
      // If the target template is undefined (not present), then do nothing
      if (Object.keys(clonedTemplateForDeleteRequest).length === 0) {
        return state; // this shouldn't happen, really
      }
      clonedTemplateForDeleteRequest.loading = {
        ...clonedTemplateForDeleteRequest.loading,
        softDelete: true,
      };
      clonedTemplateForDeleteRequest.error = {
        ...clonedTemplateForDeleteRequest.error,
        softDelete: null,
      };
      // Duplicate the array, placing the updated template object at its position index
      return Object.assign({}, state, {
        noteTemplates: [
          ...state.noteTemplates.slice(0, payload.position_id),
          clonedTemplateForDeleteRequest,
          ...state.noteTemplates.slice(payload.position_id + 1),
        ],
      });
    case types.SOFT_DELETE_NOTE_TEMPLATE_SUCCESS:
      // NOTE: We will not be taking care of re-positioning noteTemplates or
      // marking it deleted even though position is updated with this request.
      // The update in its situation has to be synced with state by making a
      // separate request to reload template list. If we try to manage that
      // here, we will end up introducing weird, hard-to-debug bugs
      // Deep clone target template as it is in the current state
      const clonedTemplateForDeleteSuccess = {
        ...state.noteTemplates[payload.position_id],
      };
      // If the target template is undefined (not present), then do nothing
      if (Object.keys(clonedTemplateForDeleteSuccess).length === 0) {
        return state; // this shouldn't happen, really
      }
      clonedTemplateForDeleteSuccess.loading = {
        ...clonedTemplateForDeleteSuccess.loading,
      };
      clonedTemplateForDeleteSuccess.error = {
        ...clonedTemplateForDeleteSuccess.error,
      };
      // Remove unnecessary props
      delete clonedTemplateForDeleteSuccess.loading.softDelete;
      delete clonedTemplateForDeleteSuccess.error.softDelete;
      // If there are no keys left after deletion, delete loading and error props too
      if (Object.keys(clonedTemplateForDeleteSuccess.loading).length === 0) {
        delete clonedTemplateForDeleteSuccess.loading;
      }
      if (Object.keys(clonedTemplateForDeleteSuccess.error).length === 0) {
        delete clonedTemplateForDeleteSuccess.error;
      }
      // Duplicate the array, placing the updated template object at its position index
      return Object.assign({}, state, {
        noteTemplates: [
          ...state.noteTemplates.slice(0, payload.position_id),
          clonedTemplateForDeleteSuccess,
          ...state.noteTemplates.slice(payload.position_id + 1),
        ],
      });
    case types.SOFT_DELETE_NOTE_TEMPLATE_FAILURE:
      // Deep clone target template as it is in the current state
      const clonedTemplateForDeleteFailure = {
        ...state.noteTemplates[payload.position_id],
      };
      // If the target template is undefined (not present), then do nothing
      if (Object.keys(clonedTemplateForDeleteFailure).length === 0) {
        return state; // this shouldn't happen, really
      }
      clonedTemplateForDeleteFailure.loading = {
        ...clonedTemplateForDeleteFailure.loading,
        softDelete: false,
      };
      clonedTemplateForDeleteFailure.error = {
        ...clonedTemplateForDeleteFailure.error,
        softDelete: payload.message,
      };
      // Duplicate the array, placing the updated template object at its position index
      return Object.assign({}, state, {
        noteTemplates: [
          ...state.noteTemplates.slice(0, payload.position_id),
          clonedTemplateForDeleteFailure,
          ...state.noteTemplates.slice(payload.position_id + 1),
        ],
      });
    default:
      return state;
  }
};
