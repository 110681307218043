/**
 * @format
 */

import request from '../../../helpers/request';
import * as types from '../actions';
import { enqueueNotification } from '../../app';

/**
 * Create deal from org contact
 */
export default (orgContactId, emailId) => {
  return async dispatch => {
    dispatch({
      type: types.CREATE_DEAL_FROM_ORGCONTACT_REQUEST,
      payload: {
        orgContactId,
      },
    });
    let data;
    try {
      const response = await request.post(
        '/api/email-processor/v1/create-deal-from-orgcontact',
        {
          email_id: emailId,
          organization_contact_id: orgContactId,
        },
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong with adding deal. Please try again or contact support';
      dispatch({
        type: types.CREATE_DEAL_FROM_ORGCONTACT_ERROR,
        payload: {
          orgContactId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: types.CREATE_DEAL_FROM_ORGCONTACT_SUCCESS,
        payload: {
          orgContactId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.CREATE_DEAL_FROM_ORGCONTACT_ERROR,
        payload: {
          orgContactId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
    }
  };
};
