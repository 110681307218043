import { SET_DEAL_FILTERS } from "../actions";

/**
 * @format
 */
export default filters => {
    return dispatch => {
        dispatch({
            type: SET_DEAL_FILTERS,
            payload: filters && Object.keys(filters).length>0 ? filters: {}
        })
    }
}