/**
 * @format
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';
import { BULK_LOAD_COMMENT_LIST } from '../comment/actions';
import {
  INCREMENT_DEAL_CONVERSATION_COUNT,
  DECREMENT_DEAL_CONVERSATION_COUNT,
} from '../deal/actions';

export default reducers;

export const loadDealConversations = (dealId, options = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_CONVERSATION_LIST_REQUEST,
        payload: {
          dealId: dealId,
        },
      });
      options = {
        ...options,
        deal_id: dealId,
        withCreator:
          typeof options.withCreator !== 'undefined'
            ? options.withCreator
            : true,
        withFirstPageComments:
          typeof options.withFirstPageComments !== 'undefined'
            ? options.withFirstPageComments
            : true,
        withCommentCount:
          typeof options.withCommentCount !== 'undefined'
            ? options.withCommentCount
            : true,
        withParticipants:
          typeof options.withParticipants !== 'undefined'
            ? options.withParticipants
            : true,
        withPartcipantsAndParticipantLevels:
          typeof options.withPartcipantsAndParticipantLevels !== 'undefined'
            ? options.withPartcipantsAndParticipantLevels
            : true,
        //Bu default we want all conversations to be loaded, including starred convos
        onlyStarredConversations:
          typeof options.onlyStarredConversations !== 'undefined'
            ? options.onlyStarredConversations
            : false,
      };
      request
        .get(`/api/v5/conversations`, options, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_CONVERSATION_LIST_SUCCESS,
              payload: {
                data: data.data,
                options: options,
                dealId: dealId,
              },
            });
            if (
              data.data &&
              data.data.length > 0 &&
              // If either of these options is not set, then BULK_LOAD_COMMENT_LIST
              // should not be dispatched
              options.withFirstPageComments &&
              options.withCommentCount
            ) {
              dispatch({
                type: BULK_LOAD_COMMENT_LIST,
                payload: {
                  conversations: data.data,
                  options: { limit: 10, page: 1 },
                },
              });
            }
            return resolve(data.data);
          } else {
            dispatch({
              type: types.LOAD_CONVERSATION_LIST_FAILURE,
              payload: {
                message: data.message,
                dealId: dealId,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading conversations, please try again.';
          dispatch({
            type: types.LOAD_CONVERSATION_LIST_FAILURE,
            payload: {
              message,
              dealId: dealId,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

export const loadConversation = (conversationId, dealId) => {
  return dispatch => {
    dispatch({
      type: types.LOAD_CONVERSATION_REQUEST,
      payload: {
        conversationId: conversationId,
      },
    });
    request
      .get(
        `/api/v1/pipelines/${dealId}/conversations/${conversationId}`,
        {},
        dispatch
      )
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_CONVERSATION_SUCCESS,
            payload: {
              data: data.data,
              conversationId: conversationId,
            },
          });
        } else {
          dispatch({
            type: types.LOAD_CONVERSATION_FAILURE,
            payload: {
              message: data.message,
              conversationId: conversationId,
            },
          });
          dispatch(enqueueNotification(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while loading conversation, please try again.';
        dispatch({
          type: types.LOAD_CONVERSATION_FAILURE,
          payload: {
            message,
            conversationId: conversationId,
          },
        });
        dispatch(enqueueNotification(message));
      });
  };
};

export const createConversation = (dealId, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.CREATE_CONVERSATION_REQUEST,
        payload: {
          dealId: dealId,
        },
      });
      request
        .post(
          `/api/v5/conversations`,
          {
            deal_id: dealId,
            ...form,
          },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.CREATE_CONVERSATION_SUCCESS,
              payload: {
                data: data.data,
                dealId: dealId,
              },
            });
            dispatch({
              type: INCREMENT_DEAL_CONVERSATION_COUNT,
              payload: {
                dealId,
                increment: 1,
              },
            });
            resolve(data.data);
          } else {
            dispatch({
              type: types.CREATE_CONVERSATION_FAILURE,
              payload: {
                message: data.message,
                dealId: dealId,
              },
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          let message =
            'Something went wrong while creating conversation, please try again.';
          if (err?.response?.status === 429) {
            message = `Too fast! Try again in a couple of seconds.`;
          }
          dispatch({
            type: types.CREATE_CONVERSATION_FAILURE,
            payload: {
              message,
              dealId: dealId,
            },
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const loadConversationParticipants = (conversationId, dealId) => {
  return dispatch => {
    dispatch({
      type: types.LOAD_PARTICIPANT_LIST_REQUEST,
      payload: {
        conversationId: conversationId,
      },
    });
    request
      .get(
        `/api/v1/pipelines/${dealId}/conversations/${conversationId}/participants`,
        {},
        dispatch
      )
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_PARTICIPANT_LIST_SUCCESS,
            payload: {
              data: data.data,
              conversationId: conversationId,
            },
          });
        } else {
          dispatch({
            type: types.LOAD_PARTICIPANT_LIST_FAILURE,
            payload: {
              message: data.message,
              conversationId: conversationId,
            },
          });
          dispatch(enqueueNotification(data.message));
        }
      })
      .catch(err => {
        const message =
          'Something went wrong while loading conversation participants, please try again.';
        dispatch({
          type: types.LOAD_PARTICIPANT_LIST_FAILURE,
          payload: {
            message,
            conversationId: conversationId,
          },
        });
        dispatch(enqueueNotification(message));
      });
  };
};

/**
 * Toggles the conversation as starred
 * @param {String} deal_id
 * @param {String} conversation_id
 * @param {Boolean} is_starred
 * @returns
 */
export const toggleConversationAsStarred = (
  deal_id,
  conversation_id,
  is_starred
) => {
  return async dispatch => {
    if (!deal_id || !conversation_id || typeof is_starred !== 'boolean') {
      let message = `Something went wrong when toggling this conversation as starred, one of the required fields might be missing`;
      dispatch({
        type: types.TOGGLE_CONVERSATION_AS_STARRED_FAILURE,
        payload: { message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }

    dispatch({
      type: types.TOGGLE_CONVERSATION_AS_STARRED_REQUEST,
      payload: {
        dealId: deal_id,
        conversationId: conversation_id,
      },
    });

    let data;
    try {
      const response = await request.put(
        `/api/pipeline/communications/v1/conversations/${conversation_id}/is_starred`,
        {
          deal_id,
          is_starred,
        },
        dispatch
      );
      data = response.data;
    } catch (err) {
      let message;
      message = `Something went wrong while toggling this conversation as starred. Please try again`;
      dispatch({
        type: types.TOGGLE_CONVERSATION_AS_STARRED_FAILURE,
        payload: {
          dealId: deal_id,
          conversationId: conversation_id,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (!data.success) {
      dispatch({
        type: types.TOGGLE_CONVERSATION_AS_STARRED_FAILURE,
        payload: {
          dealId: deal_id,
          conversationId: conversation_id,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }

    dispatch({
      type: types.TOGGLE_CONVERSATION_AS_STARRED_SUCCESS,
      payload: {
        dealId: deal_id,
        conversationId: conversation_id,
        is_starred,
        data: data.data,
      },
    });
    return data.data;
  };
};

export const rollbackConversation = (conversationId, dealId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.ROLLBACK_CONVERSATION_REQUEST,
        payload: { conversationId },
      });
      dispatch(
        enqueueNotification(
          `Deleting this conversation. ` +
            `You will be redirected to the email page once this action is complete. ` +
            `Please wait.`
        )
      );
      const requestUrl = `/api/pipeline/communications/v1/conversations/${conversationId}?deal_id=${dealId}`;
      request
        .del(requestUrl, {}, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.ROLLBACK_CONVERSATION_SUCCESS,
              payload: {
                conversationId,
                data: data.data,
              },
            });
            dispatch(
              enqueueNotification(
                `Conversation deleted. Taking you to the review page.`
              )
            );
            dispatch({
              type: DECREMENT_DEAL_CONVERSATION_COUNT,
              payload: {
                dealId,
                decrement: 1,
              },
            });
            return resolve(data.data);
          } else {
            dispatch({
              type: types.ROLLBACK_CONVERSATION_FAILURE,
              payload: {
                conversationId,
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while deleting this conversation, ' +
            'please try again.';
          dispatch({
            type: types.ROLLBACK_CONVERSATION_FAILURE,
            payload: {
              conversationId,
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(err);
        });
    });
  };
};
