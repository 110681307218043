/** @format */
import getPersonContact from './actionCreators/getPersonContact';
import getRecentInteractions from './actionCreators/getRecentInteractions';
import updatePersonContact from './actionCreators/updatePersonContact';
import reducers from './reducers';

// Reducers by default
export default reducers;

export { getPersonContact, updatePersonContact, getRecentInteractions };
