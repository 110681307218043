/** @format */
import * as types from './actions';
import { uniq } from 'lodash';

const initialState = {
  // key is portco id and value is an array of ids of portfolio updates
  portfolioUpdateList: {},
  // key is the id of a portfolio update and value is the data
  portfolioUpdates: {},
  // key is portco id and value is boolean
  addPortfolioUpdateLoading: {},
  addPortfolioUpdateError: {},
  // key is update id and value is boolean
  getPortfolioUpdateLoading: {},
  getPortfolioUpdateError: {},
  // key is portco id and value is boolean
  getPortfolioUpdateListLoading: {},
  getPortfolioUpdateListOptions: {},
  getPortfolioUpdateListMore: {},
  getPortfolioUpdateListError: {},
  // key is portfolio update's id
  updatePortfolioUpdateLoading: {},
  updatePortfolioUpdateError: {},
  // key is portfolio update's id
  deletePortfolioUpdateLoading: {},
  deletePortfolioUpdateError: {},

  // state used when any update get pinned
  pinnedUpdate: null,
  pinnedUpdateLoading: false,
  pinnedUpdateError: null,
  transcribeUpdateLoading: {},
  transcribeUpdateError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * Add portfolio list reducers
     */
    case types.ADD_PORTFOLIO_UPDATE_REQUEST:
      return Object.assign({}, state, {
        addPortfolioUpdateLoading: {
          ...state.addPortfolioUpdateLoading,
          [payload.portcoId]: true,
        },
        addPortfolioUpdateError: {
          ...state.addPortfolioUpdateError,
          [payload.portcoId]: null,
        },
      });
    case types.ADD_PORTFOLIO_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        portfolioUpdateList: {
          ...state.portfolioUpdateList,
          [payload.portcoId]:
            state.portfolioUpdateList[payload.portcoId] &&
            state.portfolioUpdateList[payload.portcoId].length > 0
              ? [
                  // append to the beginning
                  payload.data.id,
                  ...state.portfolioUpdateList[payload.portcoId],
                ]
              : [payload.data.id],
        },
        // add data to the hashmap
        portfolioUpdates: {
          ...state.portfolioUpdates,
          [payload.data.id]: payload.data,
        },
        addPortfolioUpdateLoading: {
          ...state.addPortfolioUpdateLoading,
          [payload.portcoId]: false,
        },
        addPortfolioUpdateError: {
          ...state.addPortfolioUpdateError,
          [payload.portcoId]: null,
        },
      });
    case types.ADD_PORTFOLIO_UPDATE_FAILURE:
      return Object.assign({}, state, {
        addPortfolioUpdateLoading: {
          ...state.addPortfolioUpdateLoading,
          [payload.portcoId]: false,
        },
        addPortfolioUpdateError: {
          ...state.addPortfolioUpdateError,
          [payload.portcoId]: payload.message,
        },
      });
    /**
     * Get portfolio update reducers
     */
    case types.GET_PORTFOLIO_UPDATE_REQUEST:
      return Object.assign({}, state, {
        getPortfolioUpdateLoading: {
          ...state.getPortfolioUpdateLoading,
          [payload.updateId]: true,
        },
        getPortfolioUpdateError: {
          ...state.getPortfolioUpdateError,
          [payload.updateId]: null,
        },
      });
    case types.GET_PORTFOLIO_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        // add data to the hashmap
        portfolioUpdates: {
          ...state.portfolioUpdates,
          [payload.data.id]: payload.data,
        },
        getPortfolioUpdateLoading: {
          ...state.getPortfolioUpdateLoading,
          [payload.updateId]: false,
        },
        getPortfolioUpdateError: {
          ...state.getPortfolioUpdateError,
          [payload.updateId]: null,
        },
      });
    case types.GET_PORTFOLIO_UPDATE_FAILURE:
      return Object.assign({}, state, {
        getPortfolioUpdateLoading: {
          ...state.getPortfolioUpdateLoading,
          [payload.updateId]: false,
        },
        getPortfolioUpdateError: {
          ...state.getPortfolioUpdateError,
          [payload.updateId]: payload.message,
        },
      });
    /**
     * Load portfolio updates reducers
     */
    case types.GET_PORTFOLIO_UPDATE_LIST_REQUEST:
      return Object.assign({}, state, {
        getPortfolioUpdateListLoading: {
          ...state.getPortfolioUpdateListLoading,
          [payload.portcoId]: true,
        },
        getPortfolioUpdateListError: {
          ...state.getPortfolioUpdateListError,
          [payload.portcoId]: null,
        },
      });
    case types.GET_PORTFOLIO_UPDATE_LIST_SUCCESS: {
      const currentPortfolioUpdateList =
        state.portfolioUpdateList[payload.portcoId] &&
        state.portfolioUpdateList[payload.portcoId].length > 0
          ? state.portfolioUpdateList[payload.portcoId]
          : [];
      const newPortfolioUpdateList = payload.data.map(update => update.id);
      return Object.assign({}, state, {
        portfolioUpdateList: {
          ...state.portfolioUpdateList,
          [payload.portcoId]:
            // if we are loading first page of results, reset the list, otherwise
            // append to it
            payload.options.page === 1
              ? newPortfolioUpdateList
              : uniq([
                  ...currentPortfolioUpdateList,
                  ...newPortfolioUpdateList,
                ]),
        },
        // add data to the hashmap
        portfolioUpdates: {
          ...state.portfolioUpdates,
          // create hashmap for returned updates
          ...payload.data.reduce(
            (acc, update) => ({ ...acc, [update.id]: update }),
            {}
          ),
        },
        getPortfolioUpdateListLoading: {
          ...state.getPortfolioUpdateListLoading,
          [payload.portcoId]: false,
        },
        getPortfolioUpdateListOptions: {
          ...state.getPortfolioUpdateListOptions,
          [payload.portcoId]: payload.options,
        },
        getPortfolioUpdateListMore: {
          ...state.getPortfolioUpdateListMore,
          [payload.portcoId]: payload.data.length === payload.options.limit,
        },
        getPortfolioUpdateListError: {
          ...state.getPortfolioUpdateListError,
          [payload.portcoId]: null,
        },
      });
    }
    case types.GET_PORTFOLIO_UPDATE_LIST_FAILURE:
      return Object.assign({}, state, {
        getPortfolioUpdateListLoading: {
          ...state.getPortfolioUpdateListLoading,
          [payload.portcoId]: false,
        },
        getPortfolioUpdateListError: {
          ...state.getPortfolioUpdateListError,
          [payload.portcoId]: payload.message,
        },
      });

    /**
     *  update pinnedStatus of portFolioUpdate reducer
     */

    case types.UPDATE_PINNED_STATUS_REQUEST:
      return Object.assign({}, state, {
        pinnedUpdateLoading: true,
        pinnedUpdateError: null,
      });
    case types.UPDATE_PINNED_STATUS_SUCCESS: {
      let updatesList =
        state.portfolioUpdateList[payload.data.portfolio_company_id];
      let index = updatesList.indexOf(payload.data.id);

      let updatedportfolioUpdateList = [
        payload.data.id,
        ...state.portfolioUpdateList[payload.data.portfolio_company_id].slice(
          0,
          index
        ),
        ...state.portfolioUpdateList[payload.data.portfolio_company_id].slice(
          index + 1
        ),
      ];
      let isUpdatePinned = payload.data.is_pinned;
      let currentlyPinnedUpdate = state.pinnedUpdate?.id
        ? {
            [state.pinnedUpdate.id]: {
              ...state.portfolioUpdates[state.pinnedUpdate.id],
              is_pinned: false,
            },
          }
        : {};
      // check if this portco has a pinned update
      // if so, update the is_pinned to true : {}
      return Object.assign({}, state, {
        pinnedUpdate: isUpdatePinned ? payload.data : null,

        portfolioUpdateList: {
          ...state.portfolioUpdateList,
          [payload.data.portfolio_company_id]: updatedportfolioUpdateList,
        },
        portfolioUpdates: {
          ...state.portfolioUpdates,
          [payload.update_id]: {
            ...state.portfolioUpdates[payload.update_id],
            is_pinned: true,
          },
          ...currentlyPinnedUpdate,
        },
        pinnedUpdateLoading: false,
        pinnedUpdateError: null,
      });
    }
    case types.UPDATE_PINNED_STATUS_FAILURE:
      return Object.assign({}, state, {
        pinnedUpdateLoading: false,
        pinnedUpdateError: payload.message,
      });

    /**
     * get pinnedstatus of portfolioUpdate
     */

    case types.GET_PORTCO_PINNNED_UPDATE_REQUEST:
      return Object.assign({}, state, {
        pinnedUpdateLoading: true,
        pinnedUpdateError: null,
      });

    case types.GET_PORTCO_PINNED_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        pinnedUpdate: payload.data,
        pinnedUpdateLoading: false,
        pinnedUpdateError: null,
      });

    case types.GET_PORTCO_PINNED_UPDATE_FAILURE:
      return Object.assign({}, state, {
        pinnedUpdateLoading: false,
        pinnedUpdateError: payload.message,
      });
    /**
     * Update portfolio update reducers
     */
    case types.UPDATE_PORTFOLIO_UPDATE_REQUEST:
      return Object.assign({}, state, {
        updatePortfolioUpdateLoading: {
          ...state.updatePortfolioUpdateLoading,
          [payload.updateId]: true,
        },
        updatePortfolioUpdateError: {
          ...state.updatePortfolioUpdateError,
          [payload.updateId]: null,
        },
      });
    case types.UPDATE_PORTFOLIO_UPDATE_SUCCESS: {
      const newState = Object.assign({}, state, {
        // add data to the hashmap
        portfolioUpdates: {
          ...state.portfolioUpdates,
          [payload.updateId]: {
            ...(state.portfolioUpdates[payload.updateId] || {}),
            ...payload.data,
            author: {
              ...(state.portfolioUpdates[payload.updateId]
                ? state.portfolioUpdates[payload.updateId].author
                : {}),
              ...(payload.data.author || {}),
            },
            attachments: payload.data.attachments
              ? payload.data.attachments
              : state.portfolioUpdates[payload.updateId]
              ? state.portfolioUpdates[payload.updateId] || []
              : [],
          },
        },
        updatePortfolioUpdateLoading: {
          ...state.updatePortfolioUpdateLoading,
          [payload.updateId]: false,
        },
        updatePortfolioUpdateError: {
          ...state.updatePortfolioUpdateError,
          [payload.updateId]: null,
        },
      });
      return newState;
    }
    case types.UPDATE_PORTFOLIO_UPDATE_FAILURE:
      return Object.assign({}, state, {
        updatePortfolioUpdateLoading: {
          ...state.updatePortfolioUpdateLoading,
          [payload.updateId]: false,
        },
        updatePortfolioUpdateError: {
          ...state.updatePortfolioUpdateError,
          [payload.updateId]: payload.message,
        },
      });
    /**
     * Delete portfolio update reducers
     */
    case types.DELETE_PORTFOLIO_UPDATE_REQUEST:
      return Object.assign({}, state, {
        deletePortfolioUpdateLoading: {
          ...state.deletePortfolioUpdateLoading,
          [payload.updateId]: true,
        },
        deletePortfolioUpdateError: {
          ...state.deletePortfolioUpdateError,
          [payload.updateId]: null,
        },
      });
    case types.DELETE_PORTFOLIO_UPDATE_SUCCESS: {
      const copyOfState = Object.assign({}, state, {
        // add data to the hashmap
        portfolioUpdates: {
          ...state.portfolioUpdates,
        },
        deletePortfolioUpdateLoading: {
          ...state.deletePortfolioUpdateLoading,
          [payload.updateId]: false,
        },
        deletePortfolioUpdateError: {
          ...state.deletePortfolioUpdateError,
          [payload.updateId]: null,
        },
      });
      // delete copyOfState.portfolioUpdates[payload.updateId];
      return copyOfState;
    }
    case types.DELETE_PORTFOLIO_UPDATE_FAILURE:
      return Object.assign({}, state, {
        deletePortfolioUpdateLoading: {
          ...state.deletePortfolioUpdateLoading,
          [payload.updateId]: false,
        },
        deletePortfolioUpdateError: {
          ...state.deletePortfolioUpdateError,
          [payload.updateId]: payload.message,
        },
      });
    case types.TRANSCRIBE_UPDATE_REQUEST:
      return Object.assign({}, state, {
        transcribeUpdateLoading: {
          ...state.transcribeUpdateLoading,
          [payload.updateId]: true,
        },
        transcribeUpdateError: {
          ...state.transcribeUpdateError,
          [payload.updateId]: null,
        },
      });
    case types.TRANSCRIBE_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        transcribeUpdateLoading: {
          ...state.transcribeUpdateLoading,
          [payload.updateId]: false,
        },
        transcribeUpdateError: {
          ...state.transcribeUpdateError,
          [payload.updateId]: null,
        },
      });
    case types.TRANSCRIBE_UPDATE_FAILURE:
      return Object.assign({}, state, {
        transcribeUpdateLoading: {
          ...state.transcribeUpdateLoading,
          [payload.updateId]: false,
        },
        transcribeUpdateError: {
          ...state.transcribeUpdateError,
          [payload.updateId]: payload.message,
        },
      });

    default:
      return state;
  }
};
