/** @format */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

/**
 * Returns list of all charts created in this portfolio company
 * @param {UUID} portcoId Portfolio company'd id
 * @returns List of charts in the company (non-paginated)
 */
export const getChartList = portcoId => {
  return async dispatch => {
    if (!portcoId) {
      const message = 'Please select a company to load charts for';
      dispatch({
        type: types.LIST_PORTCO_CHARTS_FAILURE,
        payload: {
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.LIST_PORTCO_CHARTS_REQUEST,
      payload: {
        portcoId,
      },
    });
    let data;
    try {
      const response = await request.get(
        `/api/portfolio/v1/companies/${portcoId}/charts`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while loading charts for this company. Please try again.';
      dispatch({
        type: types.LIST_PORTCO_CHARTS_FAILURE,
        payload: {
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      const list = data.data;
      dispatch({
        type: types.LIST_PORTCO_CHARTS_SUCCESS,
        payload: {
          portcoId,
          data: list,
        },
      });
      return list;
    } else {
      dispatch({
        type: types.LIST_PORTCO_CHARTS_FAILURE,
        payload: {
          portcoId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

/**
 * Creates a new chart in the portfolio company (non-cubejs, direct query)
 * @param {UUID} portcoId Portfolio company'd id
 * @param {Object} form attributes to create a new chart
 * @returns New chart
 */
export const createChart = (portcoId, form) => {
  return async dispatch => {
    if (!portcoId) {
      const message = 'Please select a company to add chart in';
      dispatch({
        type: types.CREATE_PORTCO_CHART_FAILURE,
        payload: {
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.CREATE_PORTCO_CHART_REQUEST,
      payload: {
        portcoId,
      },
    });
    let data;
    try {
      const response = await request.post(
        `/api/portfolio/v1/companies/${portcoId}/charts`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      let message =
        'Something went wrong while adding a new chart in this company. Please try again.';
      if (
        err.response &&
        err.response.status === 409 &&
        err.response.data &&
        err.response.data.message
      ) {
        message = err.response.data.message;
      }

      dispatch({
        type: types.CREATE_PORTCO_CHART_FAILURE,
        payload: {
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      const chart = data.data;
      dispatch({
        type: types.CREATE_PORTCO_CHART_SUCCESS,
        payload: {
          portcoId,
          data: chart,
        },
      });
      return chart;
    } else {
      dispatch({
        type: types.CREATE_PORTCO_CHART_FAILURE,
        payload: {
          portcoId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

/**
 * Delete a chart
 * @param {String} chartId chart's id
 * @param {String} portcoId Portfolio Company's id
 */
export const deleteChart = (chartId, portcoId) => {
  return async dispatch => {
    if (!portcoId || !chartId) {
      const message = 'Missing key input in deleting chart';
      dispatch({
        type: types.DELETE_PORTCO_CHARTS_FAILURE,
        payload: {
          chartId,
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
    dispatch({
      type: types.DELETE_PORTCO_CHARTS_REQUEST,
      payload: {
        chartId,
        portcoId,
      },
    });
    let data;
    try {
      const response = await request.del(
        `/api/portfolio/v1/companies/${portcoId}/charts/${chartId}`,
        {},
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message = `Something went wrong while deleting chart. Please try again.`;
      dispatch({
        type: types.DELETE_PORTCO_CHARTS_FAILURE,
        payload: {
          chartId,
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }

    if (data.success) {
      dispatch({
        type: types.DELETE_PORTCO_CHARTS_SUCCESS,
        payload: {
          chartId,
          portcoId,
          data: data.data,
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.DELETE_PORTCO_CHARTS_FAILURE,
        payload: {
          chartId,
          portcoId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};

export const clearCharts = () => {
  return dispatch => {
    return new Promise(resolve => {
      dispatch({
        type: types.CLEAR_CHARTS,
      });
      resolve();
    });
  };
};
