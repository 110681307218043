/**

Autofill actionCreator

*/


import request from '../../helpers/request';
import { enqueueNotification } from '../app';
import reducers from './reducers';
import * as types from './actions';



// Reducers by default
export default reducers;

export const clearAutofillSuggestion = (dealId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.CLEAR_AUTOFILL_SUGGESTION_REQUEST,
        payload: {
          dealId
        }
      });
      request.patch(`/api/v3/autofill/${dealId}/clear`, null, dispatch)
      .then(({data}) => {
        if(data.success) {
            dispatch({
            type: types.CLEAR_AUTOFILL_SUGGESTION_SUCCESS,
            payload: {
              dealId
            }
          });
          resolve(data.data);
        }
        else {
          dispatch({
            type: types.CLEAR_AUTOFILL_SUGGESTION_FAILURE,
            payload: {
              dealId: dealId,
              message: data.message,
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));  
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while clearing Autofill suggestions, please try again.';
        dispatch({
          type: types.CLEAR_AUTOFILL_SUGGESTION_FAILURE,
          payload: {
            dealId,
            message,
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
