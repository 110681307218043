/**
 * @format
 */
import * as types from './actions';

const initialState = {
  // All these are keyed by portco id
  associateCategoryLoading: {},
  associateCategoryError: {},

  deassociateCategoryLoading: {},
  deassociateCategoryError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * Associate category reducers
     */
    case types.ASSOCIATE_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        associateCategoryLoading: {
          ...state.associateCategoryLoading,
          [payload.portcoId]: true,
        },
        associateCategoryError: {
          ...state.associateCategoryError,
          [payload.portcoId]: null,
        },
      });
    case types.ASSOCIATE_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        associateCategoryLoading: {
          ...state.associateCategoryLoading,
          [payload.portcoId]: false,
        },
        associateCategoryError: {
          ...state.associateCategoryError,
          [payload.portcoId]: null,
        },
      });
    case types.ASSOCIATE_CATEGORY_FAILURE:
      return Object.assign({}, state, {
        associateCategoryLoading: {
          ...state.associateCategoryLoading,
          [payload.portcoId]: false,
        },
        associateCategoryError: {
          ...state.associateCategoryError,
          [payload.portcoId]: payload.message,
        },
      });

    /**
     * Associate category reducers
     */
    case types.DEASSOCIATE_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        deassociateCategoryLoading: {
          ...state.deassociateCategoryLoading,
          [payload.portcoId]: true,
        },
        deassociateCategoryError: {
          ...state.deassociateCategoryError,
          [payload.portcoId]: null,
        },
      });
    case types.DEASSOCIATE_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        deassociateCategoryLoading: {
          ...state.deassociateCategoryLoading,
          [payload.portcoId]: false,
        },
        deassociateCategoryError: {
          ...state.deassociateCategoryError,
          [payload.portcoId]: null,
        },
      });
    case types.DEASSOCIATE_CATEGORY_FAILURE:
      return Object.assign({}, state, {
        deassociateCategoryLoading: {
          ...state.deassociateCategoryLoading,
          [payload.portcoId]: false,
        },
        deassociateCategoryError: {
          ...state.deassociateCategoryError,
          [payload.portcoId]: payload.message,
        },
      });

    default:
      return state;
  }
};
