/**
 * Loads deal event
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_DEAL_EVENT_REQUEST,
  LOAD_DEAL_EVENT_SUCCESS,
  LOAD_DEAL_EVENT_FAILURE } from '../actions';

export default (eventId, dealId) => {
  return dispatch => {
    dispatch({
      type: LOAD_DEAL_EVENT_REQUEST,
      payload: {
        eventId: eventId
      },
    });
    request.get(`/api/v1/pipelines/${dealId}/events/${eventId}`, {}, dispatch)
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: LOAD_DEAL_EVENT_SUCCESS,
          payload: {
            data: data.data,
            eventId: eventId
          }
        });
      } else {
        dispatch({
          type: LOAD_DEAL_EVENT_FAILURE,
          payload: {
            message: data.message,
            eventId: eventId
          }
        });
        dispatch(enqueueNotification(data.message));
      }
    })
    .catch((err) => {
      const message = 'Something went wrong while loading event, please try again.';
      dispatch({
        type: LOAD_DEAL_EVENT_FAILURE,
        payload: {
          message,
          eventId: eventId
        }
      });
      dispatch(enqueueNotification(message));
    });
  };
};
