/**
 * @format
 */

import {
  LOAD_DEAL_NOTE_LIST_REQUEST,
  LOAD_DEAL_NOTE_LIST_SUCCESS,
  LOAD_DEAL_NOTE_LIST_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case LOAD_DEAL_NOTE_LIST_REQUEST:
      return Object.assign({}, state, {
        dealNotesLoading: {
          ...state.dealNotesLoading,
          [payload.dealId]: true,
        },
        dealNotesError: {
          ...state.dealNotesError,
          [payload.dealId]: null,
        },
      });

    case LOAD_DEAL_NOTE_LIST_SUCCESS:
      return Object.assign({}, state, {
        dealNotes: {
          ...state.dealNotes,
          [payload.dealId]:
            payload.options.page === 1
              ? payload.data
              : [
                  /**
                   * Filtering the state.dealNotes[deal.id] by removing the notes
                   * which might present in the payload.data to avoid duplicates
                   * while appending payload.
                   */
                  ...(state.dealNotes[payload.dealId]
                    ? state.dealNotes[payload.dealId].filter(
                        note => !payload.data.find(({ id }) => note.id === id)
                      )
                    : []),
                  ...payload.data,
                ],
        },
        dealNotesLoading: {
          ...state.dealNotesLoading,
          [payload.dealId]: false,
        },
        dealNotesError: {
          ...state.dealNotesError,
          [payload.dealId]: null,
        },
        dealNotesOptions: {
          ...state.dealNotesOptions,
          [payload.dealId]: payload.options,
        },
        dealNotesMore: {
          ...state.dealNotesMore,
          [payload.dealId]:
            payload.data.length >= payload.options.limit ? true : false,
        },
      });

    case LOAD_DEAL_NOTE_LIST_FAILURE:
      return Object.assign({}, state, {
        dealNotesLoading: {
          ...state.dealNotesLoading,
          [payload.dealId]: false,
        },
        dealNotesError: {
          ...state.dealNotesError,
          [payload.dealId]: payload.message,
        },
      });

    default:
      return state;
  }
};
