/**
 * LIST_SIGN_OFF_GROUPS reducer
 * @format
 */
import {
  LIST_SIGN_OFF_GROUPS_REQUEST,
  LIST_SIGN_OFF_GROUPS_SUCCESS,
  LIST_SIGN_OFF_GROUPS_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case LIST_SIGN_OFF_GROUPS_REQUEST:
      return Object.assign({}, state, {
        listSignOffGroupsLoading: {
          ...state.listSignOffGroupsLoading,
          [payload.dealId]: true,
        },
        listSignOffGroupsError: {
          ...state.listSignOffGroupsError,
          [payload.dealId]: null,
        },
      });

    case LIST_SIGN_OFF_GROUPS_SUCCESS:
      return Object.assign({}, state, {
        listSignOffGroupsLoading: {
          ...state.listSignOffGroupsLoading,
          [payload.dealId]: false,
        },
        listSignOffGroupsError: {
          ...state.listSignOffGroupsError,
          [payload.dealId]: null,
        },
        // Map deal ids to list of sign off group ids
        signOffGroupsDealsHashMap: {
          ...state.signOffGroupsDealsHashMap,
          // no pagination required. All sign off groups returned with a single request
          [payload.dealId]: payload.data.map(signOffGroup => signOffGroup.id),
        },
        // Populate sign off groups hashmap with returned data
        signOffGroups: {
          ...state.signOffGroups,
          ...payload.data.reduce((acc, signOffGroup) => {
            acc[signOffGroup.id] = signOffGroup;
            return acc;
          }, {}),
        },
      });
    case LIST_SIGN_OFF_GROUPS_FAILURE:
      return Object.assign({}, state, {
        listSignOffGroupsLoading: {
          ...state.listSignOffGroupsLoading,
          [payload.dealId]: false,
        },
        listSignOffGroupsError: {
          ...state.listSignOffGroupsError,
          [payload.dealId]: payload.message,
        },
      });

    default:
      return state;
  }
};
