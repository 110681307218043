/** @format */
import merge from 'lodash.merge';
import * as types from './actions';
import { uniq } from 'lodash';
import filter from 'lodash.filter';

const initialState = {
  // key is personContact id and value is an array of ids of personContact notes
  personContactNoteList: {},
  // key is personContact id and value is the id of the pinned personContact note
  pinnedNote: {},
  // key is the note id of a personContact note and value is the data
  personContactNotes: {},
  // key is personContact id and value is boolean
  addPersonContactNoteLoading: {},
  addPersonContactNoteError: {},
  // key is note id and value is boolean
  getPersonContactNoteLoading: {},
  getPersonContactNoteError: {},
  // key is personContact id and value is boolean
  getPinnedPersonContactNoteLoading: {},
  getPinnedPersonContactNoteError: {},
  // key is personContact id and value is boolean
  getPersonContactNoteListLoading: {},
  getPersonContactNoteListOptions: {},
  getPersonContactNoteListMore: {},
  getPersonContactNoteListError: {},
  // key is note's id
  updatePersonContactNoteLoading: {},
  updatePersonContactNoteError: {},
  // key is note's id
  deletePersonContactNoteLoading: {},
  deletePersonContactNoteError: {},

  // states to hold reply data. Key is thread id
  noteReplyList: {},
  noteReplyListLoading: {},
  noteReplyListError: {},
  noteReplyListOptions: {},
  noteReplyListMore: {},

  noteReplyCount: {},
  noteReplyCountLoading: {},
  noteReplyCountError: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * Add personContact note list reducers
     */
    case types.ADD_PERSON_CONTACT_NOTE_REQUEST:
      return Object.assign({}, state, {
        addPersonContactNoteLoading: {
          ...state.addPersonContactNoteLoading,
          [payload.personContactId]: true,
        },
        addPersonContactNoteError: {
          ...state.addPersonContactNoteError,
          [payload.personContactId]: null,
        },
      });
    case types.ADD_PERSON_CONTACT_NOTE_SUCCESS: {
      const shouldAddToPersonContactNoteList = !payload.data.note.parent_id;
      let newPersonContactNoteList = {
        ...state.personContactNoteList,
      };
      if (shouldAddToPersonContactNoteList) {
        newPersonContactNoteList = {
          ...newPersonContactNoteList,
          [payload.personContactId]:
            state.personContactNoteList[payload.personContactId] &&
            state.personContactNoteList[payload.personContactId].length > 0
              ? [
                  // append to the beginning
                  payload.data.id,
                  ...state.personContactNoteList[payload.personContactId],
                ]
              : [payload.data.id],
        };
      }

      return Object.assign({}, state, {
        personContactNoteList: newPersonContactNoteList,
        // add data to the hashmap
        personContactNotes: {
          ...state.personContactNotes,
          [payload.data.id]: payload.data,
        },
        addPersonContactNoteLoading: {
          ...state.addPersonContactNoteLoading,
          [payload.personContactId]: false,
        },
        addPersonContactNoteError: {
          ...state.addPersonContactNoteError,
          [payload.personContactId]: null,
        },
      });
    }
    case types.ADD_PERSON_CONTACT_NOTE_FAILURE:
      return Object.assign({}, state, {
        addPersonContactNoteLoading: {
          ...state.addPersonContactNoteLoading,
          [payload.personContactId]: false,
        },
        addPersonContactNoteError: {
          ...state.addPersonContactNoteError,
          [payload.personContactId]: payload.message,
        },
      });

    /**
     * Get personContact note reducers
     */
    case types.GET_PERSON_CONTACT_NOTE_REQUEST:
      return Object.assign({}, state, {
        getPersonContactNoteLoading: {
          ...state.getPersonContactNoteLoading,
          [payload.noteId]: true,
        },
        getPersonContactNoteError: {
          ...state.getPersonContactNoteError,
          [payload.noteId]: null,
        },
      });
    case types.GET_PERSON_CONTACT_NOTE_SUCCESS:
      return Object.assign({}, state, {
        // add data to the hashmap
        personContactNotes: {
          ...state.personContactNotes,
          [payload.data.id]: payload.data,
        },
        getPersonContactNoteLoading: {
          ...state.getPersonContactNoteLoading,
          [payload.noteId]: false,
        },
        getPersonContactNoteError: {
          ...state.getPersonContactNoteError,
          [payload.noteId]: null,
        },
      });
    case types.GET_PERSON_CONTACT_NOTE_FAILURE:
      return Object.assign({}, state, {
        getPersonContactNoteLoading: {
          ...state.getPersonContactNoteLoading,
          [payload.noteId]: false,
        },
        getPersonContactNoteError: {
          ...state.getPersonContactNoteError,
          [payload.noteId]: payload.message,
        },
      });

    /**
     * Get pinned personContact note reducers
     */
    case types.GET_PINNED_PERSON_CONTACT_NOTE_REQUEST:
      return Object.assign({}, state, {
        getPinnedPersonContactNoteLoading: {
          ...state.getPinnedPersonContactNoteLoading,
          [payload.personContactId]: true,
        },
        getPinnedPersonContactNoteError: {
          ...state.getPinnedPersonContactNoteError,
          [payload.personContactId]: null,
        },
      });
    case types.GET_PINNED_PERSON_CONTACT_NOTE_SUCCESS: {
      const pinnedNoteId = payload.data?.id;
      // if pinned note is present, add its payload to the personContactNotes hashmap
      const updatedPersonContactNotes = pinnedNoteId
        ? merge(state.personContactNotes, { [payload.data.id]: payload.data })
        : state.personContactNotes;
      return Object.assign({}, state, {
        // add data to the hashmap
        personContactNotes: updatedPersonContactNotes,
        pinnedNote: {
          ...state.pinnedNote,
          // update reference to the pinned note for this personContact
          // if non-existent, this will be undefined
          [payload.personContactId]: pinnedNoteId,
        },
        getPinnedPersonContactNoteLoading: {
          ...state.getPinnedPersonContactNoteLoading,
          [payload.personContactId]: false,
        },
        getPinnedPersonContactNoteError: {
          ...state.getPinnedPersonContactNoteError,
          [payload.personContactId]: null,
        },
      });
    }
    case types.GET_PINNED_PERSON_CONTACT_NOTE_FAILURE:
      return Object.assign({}, state, {
        getPinnedPersonContactNoteLoading: {
          ...state.getPinnedPersonContactNoteLoading,
          [payload.personContactId]: false,
        },
        getPinnedPersonContactNoteError: {
          ...state.getPinnedPersonContactNoteError,
          [payload.personContactId]: payload.message,
        },
      });

    /**
     * Load personContact notes reducers
     */
    case types.LIST_PERSON_CONTACT_NOTES_REQUEST:
      return Object.assign({}, state, {
        getPersonContactNoteListLoading: {
          ...state.getPersonContactNoteListLoading,
          [payload.personContactId]: true,
        },
        getPersonContactNoteListError: {
          ...state.getPersonContactNoteListError,
          [payload.personContactId]: null,
        },
      });
    case types.LIST_PERSON_CONTACT_NOTES_SUCCESS: {
      const currentPersonContactNoteList =
        state.personContactNoteList[payload.personContactId] &&
        state.personContactNoteList[payload.personContactId].length > 0
          ? state.personContactNoteList[payload.personContactId]
          : [];
      const newPersonContactNoteList = payload.data.map(note => note.note_id);
      return Object.assign({}, state, {
        personContactNoteList: {
          ...state.personContactNoteList,
          [payload.personContactId]:
            // if we are loading first page of results, reset the list, otherwise
            // append to it
            payload.options.page === 1
              ? newPersonContactNoteList
              : uniq([
                  ...currentPersonContactNoteList,
                  ...newPersonContactNoteList,
                ]),
        },
        // add data to the hashmap
        personContactNotes: {
          ...state.personContactNotes,
          // create hashmap for returned notes
          ...payload.data.reduce(
            (acc, note) => ({ ...acc, [note.note_id]: note }),
            {}
          ),
        },
        getPersonContactNoteListLoading: {
          ...state.getPersonContactNoteListLoading,
          [payload.personContactId]: false,
        },
        getPersonContactNoteListOptions: {
          ...state.getPersonContactNoteListOptions,
          [payload.personContactId]: payload.options,
        },
        getPersonContactNoteListMore: {
          ...state.getPersonContactNoteListMore,
          [payload.personContactId]:
            payload.data.length === payload.options.limit,
        },
        getPersonContactNoteListError: {
          ...state.getPersonContactNoteListError,
          [payload.personContactId]: null,
        },
      });
    }
    case types.LIST_PERSON_CONTACT_NOTES_FAILURE:
      return Object.assign({}, state, {
        getPersonContactNoteListLoading: {
          ...state.getPersonContactNoteListLoading,
          [payload.personContactId]: false,
        },
        getPersonContactNoteListError: {
          ...state.getPersonContactNoteListError,
          [payload.personContactId]: payload.message,
        },
      });

    /**
     * Update personContact note reducers
     */
    case types.UPDATE_PERSON_CONTACT_NOTE_REQUEST:
      return Object.assign({}, state, {
        updatePersonContactNoteLoading: {
          ...state.updatePersonContactNoteLoading,
          [payload.noteId]: true,
        },
        updatePersonContactNoteError: {
          ...state.updatePersonContactNoteError,
          [payload.noteId]: null,
        },
      });
    case types.UPDATE_PERSON_CONTACT_NOTE_SUCCESS: {
      const newState = Object.assign({}, state, {
        // add data to the hashmap
        personContactNotes: {
          ...state.personContactNotes,
          [payload.noteId]: merge(
            state.personContactNotes[payload.noteId] || {},
            payload.data
          ),
        },
        updatePersonContactNoteLoading: {
          ...state.updatePersonContactNoteLoading,
          [payload.noteId]: false,
        },
        updatePersonContactNoteError: {
          ...state.updatePersonContactNoteError,
          [payload.noteId]: null,
        },
      });
      return newState;
    }
    case types.UPDATE_PERSON_CONTACT_NOTE_FAILURE:
      return Object.assign({}, state, {
        updatePersonContactNoteLoading: {
          ...state.updatePersonContactNoteLoading,
          [payload.noteId]: false,
        },
        updatePersonContactNoteError: {
          ...state.updatePersonContactNoteError,
          [payload.noteId]: payload.message,
        },
      });

    /**
     * Delete personContact note reducers
     */
    case types.DELETE_PERSON_CONTACT_NOTE_REQUEST:
      return Object.assign({}, state, {
        deletePersonContactNoteLoading: {
          ...state.deletePersonContactNoteLoading,
          [payload.noteId]: true,
        },
        deletePersonContactNoteError: {
          ...state.deletePersonContactNoteError,
          [payload.noteId]: null,
        },
      });
    case types.DELETE_PERSON_CONTACT_NOTE_SUCCESS: {
      const copyOfState = Object.assign({}, state, {
        personContactNoteList: {
          ...state.personContactNoteList,
        },
        // add data to the hashmap
        personContactNotes: {
          ...state.personContactNotes,
        },
        deletePersonContactNoteLoading: {
          ...state.deletePersonContactNoteLoading,
          [payload.noteId]: false,
        },
        deletePersonContactNoteError: {
          ...state.deletePersonContactNoteError,
          [payload.noteId]: null,
        },
      });
      copyOfState.personContactNoteList[payload.personContactId] = filter(
        copyOfState.personContactNoteList[payload.personContactId],
        noteId => noteId !== payload.noteId
      );
      delete copyOfState.personContactNotes[payload.noteId];
      return copyOfState;
    }
    case types.DELETE_PERSON_CONTACT_NOTE_FAILURE:
      return Object.assign({}, state, {
        deletePersonContactNoteLoading: {
          ...state.deletePersonContactNoteLoading,
          [payload.noteId]: false,
        },
        deletePersonContactNoteError: {
          ...state.deletePersonContactNoteError,
          [payload.noteId]: payload.message,
        },
      });

    case types.LOAD_NOTE_REPLY_LIST_REQUEST:
      return Object.assign({}, state, {
        noteReplyListLoading: {
          ...state.noteReplyListLoading,
          [payload.threadId]: true,
        },
        noteReplyListError: {
          ...state.noteReplyListError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_LIST_SUCCESS:
      return Object.assign({}, state, {
        noteReplyList: {
          ...state.noteReplyList,
          [payload.threadId]:
            payload.options.page === 1
              ? payload.data
              : [...state.noteReplyList[payload.threadId], ...payload.data],
        },
        noteReplyListLoading: {
          ...state.noteReplyListLoading,
          [payload.threadId]: false,
        },
        noteReplyListError: {
          ...state.noteReplyListError,
          [payload.threadId]: null,
        },
        noteReplyListOptions: {
          ...state.noteReplyListOptions,
          [payload.threadId]: payload.options,
        },
        noteReplyListMore: {
          ...state.noteReplyListMore,
          [payload.threadId]:
            payload.metadata.length >= payload.options.limit ? true : false,
        },
        noteReplyCount: {
          ...state.noteReplyCount,
          [payload.threadId]: payload.metadata.length,
        },
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: false,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_LIST_FAILURE:
      return Object.assign({}, state, {
        noteReplyListLoading: {
          ...state.noteReplyListLoading,
          [payload.threadId]: false,
        },
        noteReplyListError: {
          ...state.noteReplyListError,
          [payload.threadId]: payload.message,
        },
      });

    case types.LOAD_NOTE_REPLY_COUNT_REQUEST:
      return Object.assign({}, state, {
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: true,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_COUNT_SUCCESS:
      return Object.assign({}, state, {
        noteReplyCount: {
          ...state.noteReplyCount,
          [payload.threadId]: payload.data.length,
        },
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: false,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: null,
        },
      });
    case types.LOAD_NOTE_REPLY_COUNT_FAILURE:
      return Object.assign({}, state, {
        noteReplyCountLoading: {
          ...state.noteReplyCountLoading,
          [payload.threadId]: false,
        },
        noteReplyCountError: {
          ...state.noteReplyCountError,
          [payload.threadId]: payload.message,
        },
      });

    default:
      return state;
  }
};
