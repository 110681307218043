/** @format */
import * as types from './actions';
import { assign } from '../../helpers/object';

const initial = {
  boards: [
    /* store of all boards in this organization */
  ],
};

export default function(state = initial, { type, payload }) {
  switch (type) {
    case types.FETCH_ALL_BOARDS: {
      return assign(state, { boards: payload });
    }

    default: {
      return state;
    }
  }
}
