/**
 * HeadManager component, allows you to update page header on fly
 * 
 * @author Ritesh Shrivastav
 */
import React from 'react';
import ReactHelmet from 'react-helmet';
import PropTypes from 'prop-types';
import Track from '../Track';

class HeadManager extends React.Component {
  static propTypes = {
    /**
     * Function to anonymise page view data, since there can be pages
     * containing sensitive data in title or the URL. This will get URL of the
     * page(including search query) as first argument and title as second
     * argument.
     */
    anonymisePageView: PropTypes.func
  };

  // Why not PureComponent? Because here the reason to re-render is not based
  // on the props or state changes but rather it's based on the url or the
  // title
  shouldComponentUpdate(nextProps) {
    if (nextProps.title !== this.props.title) {
      return true;
    }
    // In some cases the title might be same but URL can be different, see if
    // these are different and if so; allow re-rendering
    const url = document.location.pathname + document.location.search;
    if (this.last_tracked_url !== url) {
      this.last_tracked_url = url;
      return true;
    }
    return false;
  }

  // This is to fix https://github.com/nfl/react-helmet/issues/189
  handleClientStateChange = (newState) => {
    // Check title, if not set then default title is being set and in that case
    // we do not want to record the hits because that will duplicate the call
    if (!this.props.title || !this.last_tracked_url) return;
    // Constructing the tracking arguments
    let trackArgs = {
      url: this.last_tracked_url,
      title: this.props.title
    };
    // If anonymisePageView function is implemented, take the values returned
    // by this function.
    if (typeof this.props.anonymisePageView === 'function') {
      trackArgs = this.props.anonymisePageView(trackArgs.url, trackArgs.title);
    }
    // If all good, track it
    if (trackArgs && trackArgs.url && trackArgs.title) {
      Track.pageview(trackArgs.url, trackArgs.title);
    }
  };

  render() {
    return (
      <ReactHelmet
        onChangeClientState={this.handleClientStateChange}
        {...this.props}
      />
    )
  }
}

export default HeadManager;
