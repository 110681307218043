/**
 * Terms component
 *
 * @author Akhila
*/
import Footer from '../Footer';
import HeadManager from '../HeadManager';
import PublicPage from '../PublicPage';
import React from 'react';

class Terms extends React.PureComponent {

  render() {
    return(
      <PublicPage header="Terms of Use" >
        <HeadManager title="Terms of Use" />
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <p>This Terms of Use is entered into by and between Epicurean Technologies Pvt Ltd (hereinafter referred to as the “Company”) and the Customer, its Teams and End Users</p>
            <p>The Company, its Teams and the End Users shall hereinafter be individually referred to as a “Party” and collectively referred to as the “Parties”, as the context may require.</p>

            <h4>WHEREAS:</h4>
            <ol>
              <li>The Company is engaged in the providing content and database management solutions through its product (hereinafter referred to as “TagHash Services”) through its portal (hereinafter referred to as the “Portal”).</li>
              <li>The End User is any person who is part of the Customer’s organisation, designated to a Team and making use of the Portal.</li>
              <li>The Parties are desirous to enter into this Terms of Use.</li>
              <li>In consideration of the mutual promises, covenants, undertakings and agreements between the Parties hereto, the Parties hereby enter into this Terms of Use on the terms and conditions contained herein.</li>
            </ol>
            <ol>
              <li>
                <h4>DEFINITIONS AND INTERPRETATIONS AND OVERRIDING EFFECT</h4>
                <ol>
                  <li>
                    <p><strong>Definitions:</strong> The Parties to this Terms of Use hereby unconditionally agree that unless the context otherwise requires, the terms listed below when used in this Terms of Use shall have the meanings attached to them and these terms shall be interpreted accordingly. The terms listed below as used in this Terms of Use may be identified by the capitalization of the first letter of each principal word thereof. In addition to the terms defined below, certain other capitalized terms are defined elsewhere in this Terms of Use  and whenever such terms are used in this Terms of Use  they shall have their respective  defined  meanings,  unless  the context,  expressly  or by necessary implication, require otherwise:</p>
                    <ol>
                      <li><p>“<strong>Portal</strong>” shall have the meaning ascribed to it in Recital B.</p></li>
                      <li><p>“<strong>Applicable Law</strong>” includes all applicable statutes, enactments, acts of state legislatures or parliament, laws, ordinances, rules, bye-laws, regulations, notifications, guidelines, policies, directions, directives and orders of any governmental authority, statutory authority, tribunal, board, court or recognized stock exchange of any relevant jurisdiction.</p></li>
                      <li><p>“<strong>End User</strong>” shall mean the individual employee/consultant/authorised representative of the Customer that makes use of Portal.</p></li>
                      <li><p>“<strong>Dispute</strong>” shall have the meaning ascribed to it in Clause 7.3.</p></li>
                      <li><p>“<strong>Indemnified Parties</strong>” shall have the meaning ascribed to it in Clause 5.</p></li>
                      <li><p>“<strong>Person</strong>” means and includes an individual, a sole proprietorship, an association, syndicate, a corporation, a firm, a partnership, a joint venture, a trust, an unincorporated organization, a joint stock company, a limited liability company or other entity or organization, body corporate, governmental authority, judicial authority, a natural person in his capacity as trustee, executor, administrator, or other legal representative and any other entity including a government or political subdivision, or an agency or instrumentality thereof and/or any other legal entity.</p></li>
                      <li><p>"<strong>Team</strong>" shall mean a designated group of people inside the Customer’s organisation that carry out a specific function together.</p></li>
                      <li><p>"<strong>Third Party</strong>" shall mean any Persons other than the Parties and the term “Third Parties” shall be construed accordingly.</p></li>
                    </ol>
                  </li>
                  <li>
                    <h5>General Interpretation</h5>
                    In this Terms of Use, except to the extent that the context otherwise requires:
                    <ol>
                      <li><p>References to a statute, ordinance or other Law shall be deemed to include any references to a statute, ordinance or other Law as amended, supplemented  or replaced from time to time in accordance with its terms and (where applicable) subject to compliance with the requirements set forth therein and shall include regulations and other instruments under such statue, ordinance or other Law;</p></li>
                      <li><p>References to Clauses, are reference to clauses in this Terms of Use unless the context requires otherwise and the recitals to this Terms of Use shall always be deemed to form part of this Terms of Use;</p></li>
                      <li><p>The headings are inserted for convenience only and shall not affect the construction of this Terms of Use;</p></li>
                      <li><p>The masculine gender includes the feminine gender and vice versa.</p></li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <h4>RIGHTS OF THE COMPANY</h4>
                <ol>
                  <li><p>The Company does not guarantee that the Portal, the TagHash Service, and/or any connected computer system/network/service connected thereto, will function on any particular hardware or devices. In addition, all the TagHash Services envisaged under this Terms of Use rendered by the Company may be subject to malfunctions and delays inherent to the use of the Internet and electronic communications.</p></li>
                  <li><p>The User understands, acknowledges and affirms that the Company does not have any control over the conduct of any other User.</p></li>
                  <li><p>The Customer as well as the End User understands, acknowledges and affirms that the Company has no connection in relation to contractual relationships that are entered into after the TagHash Service has been utilised by any person.</p></li>
                  <li><p>All the TagHash Services envisaged under this Terms of Use provided by the Company are provided “as is” and “as available” basis.</p></li>
                  <li><p>Notwithstanding anything contained herein, the Company disclaims all implied covenants, representations and warranties.</p></li>
                  <li><p>Notwithstanding anything contained herein, it is expressly stated that the Company makes no representation, warranty, or guarantee regarding the reliability, timeliness, quality, suitability, or availability of the TagHash Service, or that the TagHash Service shall be uninterrupted or error-free.</p></li>
                  <li><p>All decisions made by the Customer in relation any project and/or activity, in relation the TagHash Services are being utilised, shall be at the sole judgment and discretion of the Customer. Nothing in these Terms and Condition, and/or TagHash Services shall be deemed to create any warranty and/or otherwise an indemnification liability for the Company in relation to any third party for any reason whatsoever.</p></li>
                  <li><p>The TagHash Services are limited to data presentation and database management for the Customer’s organisation. Nothing in these Terms shall be construed as providing of consultancy of any kind, whether financial, legal or otherwise.</p></li>
                  <li><p>Notwithstanding anything contained herein, the Company does not vouch and/or make any explicit and/or implicit representations and/or warranties on behalf of any investee company that the Customer shall be interested in.</p></li>
                  <li>
                    <p>The Customer understands that the Company reserves the right to discontinue any and all part of the TagHash Services provided by it for the following reasons:</p>
                    <ol>
                      <li>If providing such services becomes commercially unfeasible</li>
                      <li>For betterment of the over-all user experience’</li>
                      <li>For any breach of the Customer of these Terms</li>
                    </ol>
                  </li>
                  <li><p>For any breach of the Customer of these Terms</p></li>
                  <li><p>If the Company decides to change this Terms of Use, the Company may notify such changes to the End User through its Portal. However, the Company may only notify the Customer about those alterations that shall have a direct impact to his/her functionality, interaction and usage of the Portal.</p></li>
                  <li><p>The Customer and the End User understands, acknowledges, affirms and agrees that any information shared and/or otherwise made available to the Company by the virtue of this Agreement shall be at the End User’s own risk.</p></li>
                  <li><p>Notwithstanding anything contained herein, the Company shall not be liable for any and all direct, indirect, incidental and/or consequential actions, claims, losses and/or damages to any End User caused by the access provided to his profile and/or account data to any person by the virtue of the TagHash Services.</p></li>
                </ol>
              </li>

              <li>
                <h4>COVENANTS, REPRESENTATIONS AND WARRANTIES BY THE END USER AND/OR THE CUSTOMER</h4>
                <ol>
                  <li><p>Each Party hereby warrants that he has the legal right, power and authority to enter into, deliver and perform this Terms of Use and any other documents executed by him in connection with or pursuant thereto.</p></li>
                  <li><p>Notwithstanding anything contained herein and or any connection that the Company may have with any arrangement and/or agreement that are entered pursuant thereto, the Company and every individual End User individually and specifically represents and warrants that he is not prohibited, in any manner whatsoever, either directly and/or indirectly, to avail any of the TagHash Services that have been made available to him by the Company and/or any third party through this Terms of Use.;</p></li>
                  <li><p>Notwithstanding anything contained herein and or any connection that the Company may have with any arrangement and/or agreement that are entered pursuant thereto, the User covenants that he shall not make use of any of the TagHash Services been made available to him by the Company and/or any third party through this Terms of Use, for a purpose that is prohibited under Applicable Law.</p></li>
                  <li><p>The Customer and/or End User covenants that he shall not upload viruses or other malicious code to the Portal and/or any other computer system connected thereto.</p></li>
                  <li><p>The Customer and/or End User shall not solicit login information or access an account belonging to any Third Party.</p></li>
                  <li><p>The Customer and/or End User shall not do anything that could disable, overburden, or impair the proper working or appearance of the Portal, the TagHash Services and/are any part thereof and/or TagHash Services connected thereto, such as a denial of TagHash Service attack or interference with page rendering or other aspects of operational functionality.</p></li>
                  <li><p>The Customer and/or End User shall not facilitate and/or encourage any violations of this Terms of Use or any of the policies of the Company.</p></li>
                  <li><p>The End User shall not provide any false personal information on Portal and/or any other computer system connected thereto, or creates an account for any Third Party other than himself/herself without permission of that Third Party as well as the Company, respectively.</p></li>
                  <li><p>The End User shall not create more than one account for the Portal.</p></li>
                  <li><p>The End User shall not create another account on the Portal after the Company disables his/her account, without the permission of the Company.</p></li>
                  <li><p>The End User shall not share his password, let anyone else access his account, and/or do anything else that might jeopardize the security of his account with the Portal and/or any computer system connected thereto.</p></li>
                  <li><p>The Customer and/or End User shall not make any attempts to interfere with or disrupt the Portal, the TagHash Service and/or any TagHash Service/computer systems/networks connected thereto.</p></li>
                  <li><p>The Customer and/or End User shall not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Portal and/or the computer systems connected thereto;</p></li>
                  <li><p>The Customer and/or End User shall not copy, reproduce, alter, modify, and/or publicly display any information displayed on the Portal, or create derivative works from the Portal, to the extent that such action(s) would constitute copyright infringement or otherwise violate the intellectual property rights of the Company or any other Third Party, except with the prior written consent of Company.</p></li>
                  <li><p>The Customer and/or End User shall not take any action which is intended, or would reasonably be expected, to harm the Company or its or their reputation or which would reasonably be expected to lead to unwanted or unfavourable publicity to the Company.</p></li>
                </ol>
              </li>

              <li>
                <h4>COVENANTS, REPRESENTATIONS AND WARRANTIES BY THE CUSTOMER AND/OR THE END USERS</h4>
                <ol>
                  <li><p>The Customer specifically covenants that he shall use TagHash for the sole purpose of information gathering and data processing.</p></li>
                  <li><p>The Customer shall carry out its usage of the Portal only through the End Users of the respective Teams, who shall not be barred and/or otherwise restricted in any manner from using the TagHash Services by Applicable Law.</p></li>
                  <li><p>The End User understands, acknowledges, affirms and agrees that any information shared and/or otherwise made available to Taghash by the virtue of this Agreement shall be at the End User’s own risk.</p></li>
                  <li><p>Every End User who shall have access to any information through the Portal shall use such information for the sole purpose for the due fulfilment his/her role in the Customer’s organisation.</p></li>
                  <li><p>Every End User shall not further disseminate any information made available to him/her through the Portal with any third party without prior written consent of the Taghash.</p></li>
                </ol>
              </li>

              <li>
                <h4>INDEMNIFICATION</h4>
                <p>The Customer and the End User shall defend, protect, indemnify and hold harmless the Company and its directors, employees, agents, successors, and assigns (“<strong>Indemnified Parties</strong>”) from and against any and all claims in connection therewith  (collectively, the “<strong>Indemnified Liabilities</strong>”), incurred by the Indemnified Parties as a result of, arising out of or relating to:</p>
                <ol>
                  <li><p>any misrepresentation to the Indemnified Parties,</p></li>
                  <li><p>any misrepresentation to any Third Party, with respect to the Indemnified Parties,</p></li>
                  <li><p>all applicable taxes and</p></li>
                </ol>
                <p>The right of indemnification under this Clause 5 is not prejudicial to any other rights of the Indemnified Parties under this Agreement, Applicable Law, and/or equity.</p>
              </li>

              <li>
                <h4>LIMITATION OF LIABLITY</h4>
                <p>The aggregate liability of the Company under this Terms of Use with respect to any Transaction shall be limited to fee that is payable to the Company for such services, which shall be interest free, and does not include any consequential damages.</p>
              </li>

              <li>
                <h4>GOVERNING LAW AND ARBITRATION</h4>
                <ol>
                  <li><p>This Terms of Use and its performance shall be governed by and construed in all respects in accordance with the Laws of the Republic of India.</p></li>
                  <li><p>Subject to Clause 7.3 below, this Terms of Use shall be subject to the jurisdiction of the courts in Bangalore, India.</p></li>
                  <li><p>Any action, dispute or difference arising under or relating to this Terms of Use (“<strong>Dispute</strong>”) shall at the first instance be resolved through good faith negotiations between the Parties hereto, which negotiations shall begin promptly, within 15 (fifteen) days after a Party has delivered to the other Party a written request for such consultation. If the Parties are unable to resolve the Dispute in question within 15 (fifteen) days of the commencement of negotiations, the Dispute shall be referred to and finally and conclusively settled by arbitration in accordance with the International Arbitration Act, 1994.</p></li>
                  <li><p>The seat of arbitration shall be Bangalore.</p></li>
                  <li><p>All proceedings, including issuance of an arbitration award, in any such arbitration, shall be conducted in English.</p></li>
                  <li><p>The arbitration shall be conducted by a sole arbitrator appointed by the Company.</p></li>
                  <li><p>The arbitral tribunal will have the power to grant any remedy or relief that they deem just and equitable, including but not limited to injunctive relief, whether interim and/or final.</p></li>
                  <li><p>The arbitration award shall be final and binding on the Parties, and may be enforced by any court of competent jurisdiction.</p></li>
                  <li><p>The Parties agree to bear their own costs of arbitration until such time that the arbitral tribunal does not pass an award deciding on the costs. The arbitrators may, (but shall not be required to), award to a Party that substantially prevails on merits, its costs and reasonable expenses (including reasonable fees of its counsel).</p></li>
                  <li><p>When any dispute is under arbitration, the Parties shall continue to exercise their remaining respective rights and fulfil their remaining respective obligations under this Terms of Use.</p></li>
                </ol>
              </li>

              <li>
                <h4>SURVIVAL</h4>
                <p>Clauses 5, 6, 7, and 10 and all the definitions associated thereto (whether under this Terms of Use, under Applicable Law, standard industry practices or otherwise) shall survive the termination of this Terms of Use and/or the engagement with the End User. </p>
              </li>

              <li>
                <h4>ASSIGNABILITY</h4>
                <p>Except as provided in this Terms of Use, neither Party, shall be entitled to assign their rights and obligations under the Terms of Use to a Third Party without the prior written consent of the other Parties. The Parties shall execute such documents as may be necessary or desirable to give effect to such assignment.</p>
              </li>

              <li>
                <h4>CONFIDENTIALITY</h4>
                <p>It is hereby clarified that nothing contained herein shall affect the ability of the Company to make disclosure to any governmental authority or any other Person under the provisions of any Applicable Law, and/or the ability of the Company to make disclosures to its lenders, third party contractors and/other associated entities.</p>
              </li>

              <li>
                <h4>SEVERABILITY</h4>
                <p>Any provision in this Terms of Use, which is or may become prohibited or unenforceable in any jurisdiction, shall, as to such jurisdiction, be ineffective to the extent of such prohibition or unenforceability without invalidating the remaining provisions of this Terms of Use or affecting the validity or enforceability of such provision in the same or any other jurisdiction. Without prejudice to the foregoing, the Parties will immediately negotiate in good faith to replace such provision with a proviso, which is not prohibited or unenforceable and has, as far as possible, the same legal and commercial effect as that which it replaces.</p>
              </li>

              <li>
                <h4>INDEPENDENT CONTRACTORS</h4>
                <p>The Parties are independent contracting parties and will have no power or authority to assume or create any obligation or responsibility on behalf of each other. This Terms of Use will not be construed to create or imply any partnership, agency or joint venture, or employer-employee relationship.</p>
              </li>


              <li>
                <h4>RETURN & REFUND POLICY</h4>
                <p>Refund requests will only be entertained if such a request is made via an Email by the Customer to the Company within 14 days of the date of transaction. If the services are terminated by the Company in accordance with Section 2.10, the Customer will pay any unpaid fees covering the remainder of the term of all services.</p>
              </li>


              <li>
                <h4>ENTIRE AGREEMENT</h4>
                <p>This Terms of Use and Annexures attached thereto, along with the Privacy Policy, comprises the entire agreement of the Parties hereto with respect to the transactions envisaged under this Terms of Use and Annexures attached thereto and the inter-se rights and obligations of the Parties, superseding and replacing all prior negotiations, agreements, discussions, memoranda or heads of agreements. For clarification of doubt, it is expressly stated that the Recitals form an inherent part of this Terms of Use.</p>
              </li>
            </ol>
          </div>
        </div>
        <Footer />
      </PublicPage>
    )
  }
}

export default Terms;
