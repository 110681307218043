/** 
* Dropdown menulist fields includes user, logout
* @author Akhila
*/
import classNames from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Popper } from 'react-popper';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    minWidth: '200px'
  },
  popperClose: {
    pointerEvents: 'none',
  },
});

const FAQ = 'https://docs.taghash.io/';

class UserDropdownMenu extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    logoutText: PropTypes.string,
    /**
     * Function to track actions
     */
    trackAction: PropTypes.func.isRequired,
  };

  handleLogout = (event) => {
    event && event.preventDefault();
    this.props.trackAction('clicked logout');
    this.props.logout();
  }

  render() {
    const { open, id, classes } = this.props;
    return (
      <div>
        <ClickAwayListener onClickAway={this.props.handleClose}>
          <Popper
            placement="bottom-start"
            eventsEnabled={open}
            className={classNames({ [classes.popperClose]: !open })}
          >
              <Grow in={open} id={id} style={{ transformOrigin: '0 0 0' }}>
                <Paper className={classes.paper}>
                  <MenuList role="menu">
                    <MenuItem 
                      component={Link}
                      to="/dashboard/settings"
                    >
                      Settings
                    </MenuItem>
                    <MenuItem component="a" href={FAQ} target="_blank" rel="noopener noreferrer" >FAQ</MenuItem>
                    <MenuItem onClick={this.handleLogout}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Grow>
          </Popper>
        </ClickAwayListener>
      </div>
    );
  }
}

export default withStyles(styles)(UserDropdownMenu);
