/**Initial state for dealflow analytics */
export default {
    pipelineDashboards: {},

    //Loading and error states
    pipelineDashboardListLoading: false,
    pipelineDashboardListError: null,
    //getpipelineboard error states
    getPipelineDashboardLoading: {},
    getPipelineDashboardError: {},
    //Holds latest options when loading pipelineDashboards
    pipelineDashboardListOptions: {},

    addChartToDashboardLoading: {},
    addChartToDashboardError: {}
}
