export const REVENUE_MODEL = 'Revenue Model';
export const INDUSTRY = 'Industry';
export const TECHNOLOGY = 'Technology';

export const COLOR_REVENUE_MODEL = '#249B21';
export const COLOR_INDUSTRY = '#3C8CCC';
export const COLOR_TECHNOLOGY = '#040404';

export default {
  [REVENUE_MODEL]: 'Revenue models',
  [INDUSTRY]: 'Industries',
  [TECHNOLOGY]: 'Technologies'
};
