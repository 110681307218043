/** @format */

import * as types from '../actions';
import request, { wrap } from '../../../helpers/request';
import isEmptyString from '../../../helpers/string/isEmptyString';
import { enqueueNotification } from '../../app';

/**
 * Deletes a commitment
 * @param {UUID} commitmentId Id of commitment to delete
 * @param {UUID} fundId Fund's id
 */
export default function deleteCommitment(commitmentId, fundId) {
  if (isEmptyString(fundId)) {
    throw new Error('Fund id missing');
  }
  if (isEmptyString(commitmentId)) {
    throw new Error('Commitment id missing');
  }
  return async dispatch => {
    dispatch({
      type: types.DELETE_COMMITMENT_REQUEST,
      payload: {
        fundId,
        commitmentId,
      },
    });

    try {
      const { data } = await wrap(
        request.del(
          `/api/fund-transactions/v1/fund/${fundId}/commitments/${commitmentId}`,
          {},
          dispatch
        )
      );

      dispatch({
        type: types.DELETE_COMMITMENT_SUCCESS,
        payload: { fundId, commitmentId, data: data.data },
      });

      return data.data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong when deleting the commitment. Please try again.';
      dispatch({
        type: types.DELETE_COMMITMENT_FAILURE,
        payload: { fundId, commitmentId, message: message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
  };
}
