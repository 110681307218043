import DealSheetColumn from '../../constants/DealSheetColumn';

export default [
  { id: DealSheetColumn.DEAL.key, active: true },
  { id: DealSheetColumn.STAGE.key, active: true },
  { id: DealSheetColumn.WORKSPACE.key, active: false },
  { id: DealSheetColumn.STARTUP_DESCRIPTION.key, active: true },
  { id: DealSheetColumn.STARTUP_FOUNDING_YEAR.key, active: true },
  { id: DealSheetColumn.STARTUP_LOCAL_OP_ADDRESS.key, active: true },
  { id: DealSheetColumn.ASSIGNEES.key, active: true },
  { id: DealSheetColumn.ADDED_BY.key, active: true},
  { id: DealSheetColumn.INTERNAL_REFERRERS.key, active: true },
  { id: DealSheetColumn.STARTUP_FOUNDERS.key, active: true },
  { id: DealSheetColumn.EXTERNAL_REFERRER.key, active: true },
  { id: DealSheetColumn.CREATED_AT.key, active: true },
  { id: DealSheetColumn.LAST_ACTIVE_AT.key, active: true },
  { id: DealSheetColumn.FIRST_EMAIL_INTERACTION_DATE.key, active:true }
];
