/**
 * Holodeck reducers
 */
import * as types from './actions';

const initialState = {
  articles: {},
  articleListLoading: false,
  articleListError: null,
  universeCompanies: {},
  universeCompaniesListLoading: false,
  universeCompaniesListError: null,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
     * LOAD_REVIEWED_ARTICLES reducers
     */
    case types.LOAD_REVIEWED_ARTICLES_REQUEST:
      return Object.assign({}, state, {
        articleListLoading: true,
        articleListError: null
      });
    case types.LOAD_REVIEWED_ARTICLES_SUCCESS: {
      const articlesFromPayload = payload.data.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {})
      return Object.assign({}, state, {
        articleListLoading: false,
        articleListError: null,
        articles: {
          ...state.articles,
          ...articlesFromPayload,
        },
      });
    }
    case types.LOAD_REVIEWED_ARTICLES_FAILURE:
      return Object.assign({}, state, {
        articleListLoading: false,
        articleListError: payload,
      });

    /**
     * LOAD_UNIVERSE_COMPANIES reducers
     */
    case types.LOAD_UNIVERSE_COMPANIES_REQUEST:
      return Object.assign({}, state, {
        universeCompaniesListLoading: true,
        universeCompaniesListError: null
      });
    case types.LOAD_UNIVERSE_COMPANIES_SUCCESS: {
      const universeCompaniesFromPayload = payload.data.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {})
      return Object.assign({}, state, {
        universeCompaniesListLoading: false,
        universeCompaniesListError: null,
        universeCompanies: {
          ...state.universeCompanies,
          ...universeCompaniesFromPayload,
        },
      });
    }
    case types.LOAD_UNIVERSE_COMPANIES_FAILURE:
      return Object.assign({}, state, {
        universeCompaniesListLoading: false,
        universeCompaniesListError: payload,
      });

    default:
      return state;
  }
};
