/**
* Sign up container 
* 
* @author Akhila
*/
import GuestPage from '../components/GuestPage';
import HeadManager from '../components/HeadManager';
import PropTypes from 'prop-types';
import React from 'react';
import SignupForm from '../components/SignupForm';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadInviterData } from '../modules/auth';
import { signup } from '../modules/auth';

class Signup extends React.Component {

  static propTypes = {
    signupLoading: PropTypes.bool.isRequired,
    inviterData: PropTypes.object,
    signup: PropTypes.func.isRequired,
    loadInviterData: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    inviterDataLoading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { loadInviterData } = this.props;
    const { token, email } = this.props.match.params;
    loadInviterData(token, email);
  }

  render() {
    const { inviterDataLoading, signupLoading, signup } = this.props;
    const { inviterData, history } = this.props;
    const { token } = this.props.match.params;
    return (
      <div>
        <HeadManager title="Sign up" />
        <GuestPage>
          <SignupForm 
            inviterData={inviterData}
            signupLoading={ signupLoading || inviterDataLoading || false }
            signup={signup}
            token={token}
            history={history}
          />
        </GuestPage>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  signupLoading: state.auth.signupLoading,
  inviterDataError: state.auth.inviterDataError,
  inviterDataLoading: state.auth.inviterDataLoading,
  inviterData: state.auth.inviterData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  signup,
  loadInviterData,
}, dispatch);

//
// https://github.com/ReactTraining/react-router/issues/4756
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
