/**
 * Stats action creators
 *
 * @author Ritesh Shrivastav
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

/**
 * Fetches email actions graph data points
 * @param  {Object} query
 */
export const getEmailActionsPoints = (query = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GET_EMAIL_ACTIONS_POINTS_REQUEST,
        payload: { query }
      });
      request.get(`/api/v2/stats/emails/actions`, {
        start_time: query.start_time,
        end_time: query.end_time,
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.GET_EMAIL_ACTIONS_POINTS_SUCCESS,
            payload: {
              cacheTime: data.cache_time,
              data: data.data,
              query: query
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.GET_EMAIL_ACTIONS_POINTS_FAILURE,
            payload: {
              message: data.message,
              query: query
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while fetching email actions points, please try again.';
        dispatch({
          type: types.GET_EMAIL_ACTIONS_POINTS_FAILURE,
          payload: {
            message: message,
            query: query
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Fetches email actions count
 * @param  {Object} query
 */
export const getEmailActionsCount = (query = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GET_EMAIL_ACTIONS_COUNT_REQUEST,
        payload: { query }
      });
      request.get(`/api/v2/stats/emails/actions/count`, {
        start_time: query.start_time,
        end_time: query.end_time,
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.GET_EMAIL_ACTIONS_COUNT_SUCCESS,
            payload: {
              cacheTime: data.cache_time,
              data: data.data,
              query: query
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.GET_EMAIL_ACTIONS_COUNT_FAILURE,
            payload: {
              message: data.message,
              query: query
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while fetching email actions count, please try again.';
        dispatch({
          type: types.GET_EMAIL_ACTIONS_COUNT_FAILURE,
          payload: {
            message: message,
            query: query
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Fetches email actions count for specific member. This uses the same
 * endpoint as `getEmailActionsCount()` but it requires to localise the data
 * for each members.
 *
 * @param  {Object} query
 */
export const getMemberEmailActionsCount = (query = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      // `investor_id` is one of the required query, if consumer need to fetch
      // the action counts it should use `getEmailActionsCount()` instead.
      if (!query.investor_id) {
        const err = new Error('Please select a member to get email action stats.');
        dispatch(enqueueNotification(err.message));
        return reject(err);
      }
      dispatch({
        type: types.GET_MEMBER_EMAIL_ACTIONS_COUNT_REQUEST,
        payload: { query, investor_id: query.investor_id }
      });
      request.get(`/api/v2/stats/emails/actions/count`, {
        start_time: query.start_time,
        end_time: query.end_time,
        investor_id: query.investor_id,
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.GET_MEMBER_EMAIL_ACTIONS_COUNT_SUCCESS,
            payload: {
              cacheTime: data.cache_time,
              data: data.data,
              query: query,
              investor_id: query.investor_id,
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.GET_MEMBER_EMAIL_ACTIONS_COUNT_FAILURE,
            payload: {
              message: data.message,
              query: query,
              investor_id: query.investor_id,
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while fetching email actions count, please try again.';
        dispatch({
          type: types.GET_MEMBER_EMAIL_ACTIONS_COUNT_FAILURE,
          payload: {
            message: message,
            query: query,
            investor_id: query.investor_id
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Fetches deal statuses graph data points
 * @param  {Object} query
 */
export const getDealStatusesPoints = (query = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GET_DEAL_STATUSES_POINTS_REQUEST,
        payload: { query }
      });
      request.get(`/api/v2/stats/deals/statuses`, {
        start_time: query.start_time,
        end_time: query.end_time,
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.GET_DEAL_STATUSES_POINTS_SUCCESS,
            payload: {
              cacheTime: data.cache_time,
              data: data.data,
              query: query
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.GET_DEAL_STATUSES_POINTS_FAILURE,
            payload: {
              message: data.message,
              query: query
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while fetching deal statuses points, please try again.';
        dispatch({
          type: types.GET_DEAL_STATUSES_POINTS_FAILURE,
          payload: {
            message: message,
            query: query
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Fetches deal statuses count
 * @param  {Object} query
 */
export const getDealStatusesCount = (query = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GET_DEAL_STATUSES_COUNT_REQUEST,
        payload: { query }
      });
      request.get(`/api/v2/stats/deals/statuses/count`, {
        start_time: query.start_time,
        end_time: query.end_time,
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.GET_DEAL_STATUSES_COUNT_SUCCESS,
            payload: {
              cacheTime: data.cache_time,
              data: data.data,
              query: query
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.GET_DEAL_STATUSES_COUNT_FAILURE,
            payload: {
              message: data.message,
              query: query
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while fetching deal statuses count, please try again.';
        dispatch({
          type: types.GET_DEAL_STATUSES_COUNT_FAILURE,
          payload: {
            message: message,
            query: query
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Fetches deal stages graph data points
 * @param  {Object} query
 */
export const getDealStagesPoints = (query = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GET_DEAL_STAGES_POINTS_REQUEST,
        payload: { query, workspaceId: query.workspace_id }
      });
      request.get(`/api/v5/stats/deals/deal-stages`, {
        start_time: query.start_time,
        end_time: query.end_time,
        workspace_id: query.workspace_id,
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.GET_DEAL_STAGES_POINTS_SUCCESS,
            payload: {
              cacheTime: data.cache_time,
              data: data.data,
              query: query,
              workspaceId: query.workspace_id
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.GET_DEAL_STAGES_POINTS_FAILURE,
            payload: {
              message: data.message,
              query: query,
              workspaceId: query.workspace_id
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while fetching deal stages points, please try again.';
        dispatch({
          type: types.GET_DEAL_STAGES_POINTS_FAILURE,
          payload: {
            message: message,
            query: query,
            workspaceId: query.workspace_id
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Fetches deal stages count
 * @param  {Object} query
 */
export const getDealStagesCount = (query = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GET_DEAL_STAGES_COUNT_REQUEST,
        payload: { query, workspaceId: query.workspace_id }
      });
      request.get(`/api/v5/stats/deals/deal-stages/count`, {
        start_time: query.start_time,
        end_time: query.end_time,
        workspace_id: query.workspace_id,
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.GET_DEAL_STAGES_COUNT_SUCCESS,
            payload: {
              cacheTime: data.cache_time,
              data: data.data,
              query: query,
              workspaceId: query.workspace_id,
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.GET_DEAL_STAGES_COUNT_FAILURE,
            payload: {
              message: data.message,
              query: query,
              workspaceId: query.workspace_id,
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while fetching deal stages count, please try again.';
        dispatch({
          type: types.GET_DEAL_STAGES_COUNT_FAILURE,
          payload: {
            message: message,
            query: query,
            workspaceId: query.workspace_id,
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Fetches deal categories count
 * @param  {Object} query
 */
export const getDealCategoriesCount = (query = {}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.GET_DEAL_CATEGORIES_COUNT_REQUEST,
        payload: { query }
      });
      request.get(`/api/v2/stats/deals/categories/count`, {
        start_time: query.start_time,
        end_time: query.end_time,
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.GET_DEAL_CATEGORIES_COUNT_SUCCESS,
            payload: {
              cacheTime: data.cache_time,
              data: data.data,
              query: query
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.GET_DEAL_CATEGORIES_COUNT_FAILURE,
            payload: {
              message: data.message,
              query: query
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while fetching deal categories count, please try again.';
        dispatch({
          type: types.GET_DEAL_CATEGORIES_COUNT_FAILURE,
          payload: {
            message: message,
            query: query
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
