/**
 */

import map from "lodash.map";
import reduce from "lodash.reduce";

export const AVAILABLE_RESOURCE_TYPES = {
   PIPELINES_OR_DEALS: 'pipelines',
   PORTFOLIOS: 'portfolio.companies',
   PIPELINE_CONVERSATIONS: 'pipeline_conversations',
   DEAL_NOTES: 'notes',
   PORTFOLIO_CONVERSATIONS: 'portfolio.conversations',
   PORTFOLIO_UPDATES: 'portfolio.updates',
   DEALS_SIGNALS: 'deals_signals',
   DEALS_SUPER_SIGNALS: 'signals_super_signals',
   SIGNALS_MOVEMENTS: 'signals_movements',
   FUND_NOTES: 'fund.notes',
   PERSON_CONTACT: 'contact.people',
   ORGANIZATION_CONTACT: 'contact.organization_contacts',
   PERSON_CONTACT_NOTES: 'contact.person_contact_notes',
   ORGANIZATION_CONTACT_NOTES: 'contact.organization_contact_notes'
};

export const RESOURCE_TYPE_VARIANT_MAP = {
  'pipelines': 'deal',
  'notes': 'note',
  'pipeline_conversations': 'conversation',
  'portfolio.companies': 'portfolio',
  'portfolio.conversations': 'conversation',
  'portfolio.updates': 'note',
  'fund.notes': 'note',
  'contact.people': 'contact',
  'contact.organization_contacts': 'contact',
  'contact.person_contact_notes': 'note',
  'contact.organization_contact_notes': 'note'
};


//For reminders dashboard, primary resource indicates
//the big tabs that are in display, these have secondary resource types which are dependent on it
export const PRIMARY_TO_SECONDARY_RESOURCE_TYPES = {
  //Deals: notes, deal convos
  'pipelines' : ['notes', 'pipeline_conversations'],
  'portfolio.companies': ['portfolio.conversations','portfolio.updates',],
  'contact.people': ['contact.person_contact_notes'],
  'contact.organization_contacts':['contact.organization_contact_notes'],
}

/**
 * Invert the above object so that it reads like
 * {
 *    notes: 'pipeines',
 *    'pipeline_conversations':'pipeines'
 * }
 */
export const SECONDARY_TO_PRIMARY_RESOURCE_TYPES = reduce(
  Object.keys(PRIMARY_TO_SECONDARY_RESOURCE_TYPES),
  (obj, primaryResourceType) =>{
    const secondaryResourceTypeArray = PRIMARY_TO_SECONDARY_RESOURCE_TYPES[primaryResourceType];
    //Construct the map here
    map(secondaryResourceTypeArray, secondaryResource => {
      if(!obj[secondaryResource]){
        obj[secondaryResource] = primaryResourceType;
      }
    });
    return obj
  },
{});