/**
 * deal/UPDATE reducers
 * 
 * @author Ritesh Shrivastav
 */
import {
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case UPDATE_REQUEST:
      return Object.assign({}, state, {
        updateDealLoading: true,
        updateDealError: null
      });
    
    case UPDATE_SUCCESS:
      return Object.assign({}, state, {
        updateDealLoading: false,
        updateDealError: null
      });
    
    case UPDATE_FAILURE:
      return Object.assign({}, state, {
        updateDealLoading: false,
        updateDealError: payload
      });

    default:
      return state;
  }
};
