/** @format */

import * as types from './actions';
import reducers from './reducers';
import { wrap, api } from '../../helpers/request';

// mark reducers as the default export
export default reducers;

// LoadCaptable loads the captable of the provided portfolio company
export function loadCaptable(id, params) {
  return async function(dispatch) {
    try {
      dispatch({
        type: types.LOAD_PORTFOLIO_CAPTABLE_REQUEST,
        payload: { id },
      });

      const { data } = await wrap(
        api.get(
          `/api/portfolio-captable/v1/companies/${id}`,
          params && { params }
        )
      );

      if (!data.success) {
        throw new Error(data.message);
      }

      dispatch({
        type: types.LOAD_PORTFOLIO_CAPTABLE_SUCCESS,
        payload: { id, data: data.data },
      });

      return data;
    } catch (err) {
      dispatch({
        type: types.LOAD_PORTFOLIO_CAPTABLE_FAILURE,
        payload: { id },
      });
      throw err;
    }
  };
}

/**
 * Load the share logs for this company
 * @async
 * @param {String} id Portfolio company's id
 * @returns {Array} share logs for this company
 */
export function loadSharesLog(id) {
  return async function(dispatch) {
    dispatch({
      type: types.LOAD_PORTFOLIO_CAPTABLE_SHARES_LOG_REQUEST,
      payload: { id },
    });
    try {
      const { data } = await wrap(
        api.get(`/api/portfolio-captable/v1/companies/${id}/shares-log`)
      );
      if (!data.success) {
        throw new Error(data.message);
      }
      dispatch({
        type: types.LOAD_PORTFOLIO_CAPTABLE_SHARES_LOG_SUCCESS,
        payload: { id, data: data.data },
      });

      return data;
    } catch (err) {
      dispatch({
        type: types.LOAD_PORTFOLIO_CAPTABLE_SHARES_LOG_FAILURE,
        payload: { id },
      });
      throw err;
    }
  };
}

// UpdateCaptable updates the captable of the provided portfolio company
export function updateCaptable(id, payload) {
  return async function(dispatch) {
    const { data } = await wrap(
      api.post(`/api/portfolio-captable/v1/companies/${id}`, payload)
    );

    if (!data.success) {
      throw new Error(data.message);
    }

    dispatch({
      type: types.UPDATE_PORTFOLIO_CAPTABLE,
      payload: { id, data: data.data },
    });

    return data;
  };
}

/**
 * Delete the share logs for this company
 * @async
 * @param {String} id Portfolio company's id
 * @param {String} date date key to remove record with
 */
export function deleteSharesLog(id, date) {
  return async function(dispatch) {
    const key = `${id}-${date}`;
    dispatch({
      type: types.DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG_REQUEST,
      payload: { id, key },
    });
    try {
      const { data } = await wrap(
        api.delete(`/api/portfolio-captable/v1/companies/${id}`, {
          params: { date },
        })
      );
      if (!data.success) {
        throw new Error(data.message);
      }
      dispatch({
        type: types.DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG_SUCCESS,
        payload: { id, key },
      });

      return data;
    } catch (err) {
      dispatch({
        type: types.DELETE_PORTFOLIO_CAPTABLE_SHARES_LOG_FAILURE,
        payload: { id, key },
      });
      throw err;
    }
  };
}

/**
 * Clears the share logs from redux. Cleanup function
 */
export function clearSharesLog() {
  return function(dispatch) {
    dispatch({
      type: types.CLEAR_PORTFOLIO_CAPTABLE_SHARES_LOG,
    });
  };
}
