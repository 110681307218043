/**
 * Function which encodes `next` path so as to not interfere with other query params
 *
 * @param  {String} next path to be encoded
 * @return {String} hex-encoded string
 */
export const encodeNext = (nextPath) => {
  return Buffer.from(nextPath, 'utf8').toString('hex');
};

/**
 * Function which decodes `next` path previously encoded by `encodeNext`
 *
 * @param  {String} hex-encoded next path to be decoded
 * @return {String} decoded path string
 */
export const decodeNext = (encodedNext) => {
  return Buffer.from(encodedNext, 'hex').toString('utf8');
};
