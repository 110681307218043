/**
* GuestPage component
*
* @author Akhila
*/
import classNames from 'classnames';
import deviceWidth from '../../helpers/deviceWidth';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
  },
  container: {
    height: '100%',
  },
  layout: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: '100%',
  },
  control: {
    padding: theme.spacing(2),
  },
  leftSide: {
    backgroundColor: '#f7f7f7'
  },
  rightSide: {
    backgroundColor: '#379AFF'
  }
});

class GuestPage extends React.PureComponent {
  render() {
    const { classes } = this.props;
    const { left, right, children } = this.props;
    const content = left || children;
    const isSmallDevice = deviceWidth.down('sm');
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Grid
            container
            alignItems="stretch"
            direction="row"
            justify="center"
            className={classes.container}
          >
            <Grid item xs={12} md={6}>
              <div className={classes.layout}>
                {content}
              </div>
            </Grid>
            {!isSmallDevice && (
              <Grid item xs={12} md={6}>
                <div className={classNames(
                  classes.layout,
                  classes.rightSide)}>
                  {right || ''}
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(GuestPage);
