/**
 * Function which checks for valid password
 * @author [akhila]
 *
 * @param  {[String]} password To be checked.
 * @return {[Boolean]}
 */
export const isPasswordValid = (password) => {
  const regEx = RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"); 
  return regEx.test(password);
}
