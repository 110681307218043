import React from 'react';
import SplashScreen from '../components/SplashScreen';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { handleGoogleConnect } from '../modules/setting';
import { loadUser } from '../modules/auth';
import { decodeNext } from '../helpers/string/nextPath';
import { OAUTH2_GOOGLE_STATE_NEXT_PREFIX } from '../constants/View';

class GoogleRedirect extends React.Component {
  componentDidMount() {
    const { location, history, handleGoogleConnect } = this.props;
    const params = new URLSearchParams(location.search);
    if (params.get('code')) {
      handleGoogleConnect(params.get('code'), params.get('state'))
      .then(() => {
        const state = params.get('state');
        if (state) {
          const stateParts = state.split('-');
          // first two parts are reserved for use by the server
          if (stateParts && stateParts.length > 2 && stateParts[2] &&
            // based on the prefix set in `LoginForm/index.js`
            stateParts[2].indexOf(OAUTH2_GOOGLE_STATE_NEXT_PREFIX) > -1) {
            // Remove prefix
            const encodedNext = stateParts[2].replace(OAUTH2_GOOGLE_STATE_NEXT_PREFIX, '');
            if (encodedNext && encodedNext.length > 0) {
              const next = decodeNext(encodedNext);
              // Only if these come together perfectly, we have a next-redirect url
              // This is doomed to fail since in this request, set-cookie will be called
              // After the redirect, auth will be called again, which will pass.
              // Without this, you will see a white screen of death
              this.props.loadUser().catch(() => {});
              history.push(next);
              return; // or else you will be redirected to /dashboard instead
            }
          }
        }
        history.push('/dashboard');
      })
      .catch((err) => {
        // handle error
        if (err && err.code === 103) {
            // If a user with this email id is not registered in Taghash,
            // redirect them to book a demo
            history.push(`/invite/request`);
        } else {
          history.push('/');
        }
      });
      
    } else {
      history.push('/dashboard');
    }
  }

  render() {
    return (<SplashScreen message="Redirecting..." />);
  }

}

const mapStateToProps = (state) => ({
  me: state.auth.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  handleGoogleConnect,
  loadUser
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleRedirect);
