import {
  LOAD_DEAL_PARTICIPANT_LIST_REQUEST,
  LOAD_DEAL_PARTICIPANT_LIST_SUCCESS,
  LOAD_DEAL_PARTICIPANT_LIST_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_DEAL_PARTICIPANT_LIST_REQUEST:
      return Object.assign({}, state, {
        dealParticipantListLoading: {
          ...state.dealParticipantListLoading,
          [payload.dealId]: true
        },
        dealParticipantListError: {
          ...state.dealParticipantListError,
          [payload.dealId]: null
        }
      });
    
    case LOAD_DEAL_PARTICIPANT_LIST_SUCCESS:
      return Object.assign({}, state, {
        dealParticipantList: {
          ...state.dealParticipantList,
          [payload.dealId]: payload.data
        },
        dealParticipantListLoading: {
          ...state.dealParticipantListLoading,
          [payload.dealId]: false
        },
        dealParticipantListError: {
          ...state.dealParticipantListError,
          [payload.dealId]: null
        }
      });
    
    case LOAD_DEAL_PARTICIPANT_LIST_FAILURE:
      return Object.assign({}, state, {
        dealParticipantListLoading: {
          ...state.dealParticipantListLoading,
          [payload.dealId]: false
        },
        dealParticipantListError: {
          ...state.dealParticipantListError,
          [payload.dealId]: payload.message
        }
      });

    default:
      return state;
  }
};
