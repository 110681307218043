/**
 * Adds new note to the deal
 *
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  ADD_DEAL_NOTE_REQUEST,
  ADD_DEAL_NOTE_SUCCESS,
  ADD_DEAL_NOTE_FAILURE } from '../actions';

export default (dealId, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_DEAL_NOTE_REQUEST
      });
      request.post(`/api/v3/deals/${dealId}/notes`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: ADD_DEAL_NOTE_SUCCESS,
            payload: {
              dealId: dealId,
              data: data.data
            }
          });
          resolve(data.data);
        } else {
          dispatch({
            type: ADD_DEAL_NOTE_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        let message = 'Something went wrong while adding note, please try again.';
        if(err?.response?.status === 429) {
          message = `Too fast! Try again in a couple of seconds.`
        }
        dispatch({
          type: ADD_DEAL_NOTE_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};
