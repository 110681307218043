/**
 * Adds assignee to the deal
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  ADD_ASSIGNEE_REQUEST,
  ADD_ASSIGNEE_SUCCESS,
  ADD_ASSIGNEE_FAILURE } from '../actions';

export default (id, user_id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_ASSIGNEE_REQUEST,
        payload: {
          dealId: id
        }
      });
      request.post(`/api/v1/pipelines/${id}/assignee/add`, {
        user_id
      }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: ADD_ASSIGNEE_SUCCESS,
            payload: {
              dealId: id
            }
          });
          resolve();
        } else {
          dispatch({
            type: ADD_ASSIGNEE_FAILURE,
            payload: {
              message: data.message,
              dealId: id
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        let message = 'Something went wrong while assigning deal to member, please try again.';
        if(err.response?.data?.message && err.response?.status === 409) message = err.response.data.message
        dispatch({
          type: ADD_ASSIGNEE_FAILURE,
          payload: {
            message,
            dealId: id
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};