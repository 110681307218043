/**
 * Loads list of deal events
 * 
 * @author Ritesh Shrivastav
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  LOAD_DEAL_EVENT_LIST_REQUEST,
  LOAD_DEAL_EVENT_LIST_SUCCESS,
  LOAD_DEAL_EVENT_LIST_FAILURE } from '../actions';

export default (dealId, options) => {
  return dispatch => {
    dispatch({
      type: LOAD_DEAL_EVENT_LIST_REQUEST,
      payload: {
        dealId: dealId
      }
    });
    request.get(`/api/v1/pipelines/${dealId}/events`, options, dispatch)
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: LOAD_DEAL_EVENT_LIST_SUCCESS,
          payload: {
            data: data.data,
            options: options,
            dealId: dealId,
          }
        });
      } else {
        dispatch({
          type: LOAD_DEAL_EVENT_LIST_FAILURE,
          payload: {
            message: data.message,
            dealId: dealId
          }
        });
        dispatch(enqueueNotification(data.message));
      }
    })
    .catch((err) => {
      const message = 'Something went wrong while loading events, please try again.';
      dispatch({
        type: LOAD_DEAL_EVENT_LIST_FAILURE,
        payload: {
          message,
          dealId: dealId,
        }
      });
      dispatch(enqueueNotification(message));
    });
  };
};
