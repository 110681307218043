/**
 * SignupForm component
 *
 * @author  Akhila
 * @format
 */
import Autocomplete from '@material-ui/lab/Autocomplete';
import BlockMessage from '../BlockMessage';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Footer from '../Footer';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Loader from '../Loader';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Time from '../Time';
import TimezoneMap from '../../constants/Timezone';
import { isEmailValid } from '../../helpers/string/validEmail';
import { isPasswordValid } from '../../helpers/string/validPassword';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  form: {
    margin: theme.spacing(1),
  },
  singnupSideHeader: {
    color: '#379AFF',
    fontSize: '30px',
    margin: '0px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
  checkboxForPolicy: {
    display: 'flex',
  },
  checkbox: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '1em',
    },
  },
});

class SignupForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    signupLoading: PropTypes.bool.isRequired,
    inviterData: PropTypes.object,
    signup: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    form: {
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      website: '',
      organization: '',
      password: '',
      invited_as: '',
      timezone_name: null,
    },
    cPassword: '',
    policyCheck: true,
    matchError: false,
    emailError: false,
    passwordErr: false,
    showPassword: false,
    showConfirmPassword: false,
  };

  componentDidMount() {
    // If user's timezone is amongst the timezones we recognize, initialize
    // timezone field with the name of the timezone that the user's browser
    // is currently set to
    // @see https://danielcompton.net/2017/07/06/detect-user-timezone-javascript
    const timezone_name = Time.getSystemTimezone();
    if (timezone_name) {
      this.setState({ form: { ...this.state.form, timezone_name } });
    }
  }

  componentDidUpdate(prevProps) {
    // The component needs data to be updated if inviterData chenges
    if (this.props.inviterData !== prevProps.inviterData) {
      this.setState({
        form: {
          ...this.state.form,
          ...this.props.inviterData,
        },
      });
    }
  }

  handleSubmit = event => {
    event && event.preventDefault();
    const { signup, token, history, signupLoading } = this.props;
    const { cPassword, policyCheck } = this.state;
    const { password } = this.state.form;
    if (signupLoading) return;
    if (password !== cPassword && !isEmailValid(this.state.form.email)) {
      this.setState({
        matchError: true,
        emailError: true,
        passwordErr: false,
      });
    } else if (!isEmailValid(this.state.form.email)) {
      this.setState({
        matchError: false,
        emailError: true,
        passwordErr: false,
      });
    } else if (!isPasswordValid(this.state.form.password)) {
      this.setState({
        passwordErr: true,
        matchError: false,
        emailError: false,
      });
    } else if (password !== cPassword) {
      this.setState({
        matchError: true,
        emailError: false,
        passwordErr: false,
      });
    } else if (policyCheck !== true) {
      this.setState({
        matchError: false,
        emailError: false,
        passwordErr: false,
      });
    } else {
      this.setState(
        {
          policyCheck: true,
          matchError: false,
          emailError: false,
          passwordErr: false,
        },
        () => {
          signup({ ...this.state.form, token })
            .then(data => {
              history.push('/login');
            })
            .catch(err => {});
        }
      );
    }
  };

  handleChange = name => event => {
    let apiEndpointProps = Object.keys(this.state.form);
    if (apiEndpointProps.indexOf(name) > -1) {
      this.setState({
        form: {
          ...this.state.form,
          [name]: event.target.value,
        },
      });
    } else if (event.target.type === 'checkbox') {
      this.setState({
        [name]: event.target.checked,
      });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  render() {
    const { signupLoading, classes, inviterData } = this.props;
    return (
      <div className={classes.root}>
        <Loader active={signupLoading} />
        <h1 className={classes.singnupSideHeader}>Welcome onboard!</h1>
        {inviterData ? (
          <div>
            <form className={classes.form}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormControl
                    className={classes.formControl}
                    required
                    disabled={signupLoading}
                    fullWidth
                  >
                    <TextField
                      variant="outlined"
                      label="First name *"
                      value={this.state.form.first_name}
                      disabled={signupLoading}
                      onChange={this.handleChange('first_name')}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    className={classes.formControl}
                    required
                    fullWidth
                    disabled={signupLoading}
                  >
                    <TextField
                      variant="outlined"
                      label="Last name *"
                      value={this.state.form.last_name}
                      onChange={this.handleChange('last_name')}
                      disabled={signupLoading}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl
                className={classes.formControl}
                fullWidth
                required
                disabled={isEmailValid(inviterData.email)}
              >
                <TextField
                  variant="outlined"
                  label="Email address"
                  value={inviterData.email}
                  disabled={isEmailValid(inviterData.email)}
                />
                {!isEmailValid(inviterData.email) && (
                  <FormHelperText>Email is not valid.</FormHelperText>
                )}
              </FormControl>
              {inviterData.invited_as === 'organization-admin' && (
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  required
                  disabled={signupLoading}
                >
                  <TextField
                    variant="outlined"
                    label="Organization's name *"
                    value={this.state.form.organization}
                    onChange={this.handleChange('organization')}
                    disabled={signupLoading}
                  />
                </FormControl>
              )}
              <FormControl
                className={classes.formControl}
                fullWidth
                required
                disabled={signupLoading}
              >
                <TextField
                  variant="outlined"
                  label="Mobile Number"
                  type="tel"
                  value={this.state.form.mobile}
                  onChange={this.handleChange('mobile')}
                  max="32"
                  disabled={signupLoading}
                />
              </FormControl>
              <FormControl
                className={classes.formControl}
                fullWidth
                required
                disabled={signupLoading}
              >
                <Autocomplete
                  id="user-timezone"
                  options={Object.keys(TimezoneMap).map(timezoneKey => ({
                    label: TimezoneMap[timezoneKey],
                    value: TimezoneMap[timezoneKey],
                  }))}
                  value={
                    this.state.form.timezone_name && {
                      label: this.state.form.timezone_name,
                      value: this.state.form.timezone_name,
                    }
                  }
                  onChange={(event, newValue) => {
                    this.handleChange('timezone_name')({
                      target: { value: newValue.value },
                    });
                  }}
                  getOptionLabel={option => option.label || ''}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Timezone"
                      placeholder="Asia/Kolkata"
                      variant="outlined"
                      disabled={signupLoading}
                      helperText={
                        <React.Fragment>
                          Note: This is only used to determine the appropriate
                          time to send email notifications. The dashboard always
                          uses your browser's configuration to localize the time
                          references
                        </React.Fragment>
                      }
                    />
                  )}
                />
              </FormControl>
              <FormControl
                className={classes.formControl}
                fullWidth
                required
                disabled={signupLoading}
                error={this.state.passwordErr}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.form.password}
                  onChange={this.handleChange('password')}
                  disabled={signupLoading}
                  helperText={
                    !this.state.passwordErr &&
                    'Minimum 8 characters, at least one uppercase letter, one lowercase letter and one number'
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={80}
                />
                {this.state.passwordErr && (
                  <FormHelperText>
                    Password should have Minimum 8 characters, at least one
                    uppercase letter, one lowercase letter and one number.
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                className={classes.formControl}
                fullWidth
                required
                disabled={signupLoading}
                error={this.state.matchError}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  type={this.state.showConfirmPassword ? 'text' : 'password'}
                  value={this.state.cPassword}
                  onChange={this.handleChange('cPassword')}
                  disabled={signupLoading}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowConfirmPassword}
                      >
                        {this.state.showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={140}
                />
                {this.state.matchError && (
                  <FormHelperText>
                    Password and confirm password don't match.
                  </FormHelperText>
                )}
              </FormControl>
              <div className={classes.checkboxForPolicy}>
                <FormControl
                  disabled={signupLoading}
                  className={classes.checkbox}
                >
                  <Checkbox
                    color="primary"
                    type="checkbox"
                    checked={this.state.policyCheck}
                    onChange={this.handleChange('policyCheck')}
                    disabled={signupLoading}
                  />
                </FormControl>
                <p>
                  I agree to{' '}
                  <Link to="/terms-and-conditions/v1" className={classes.link}>
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link to="/privacy-policy/v1" className={classes.link}>
                    Privacy Policy
                  </Link>{' '}
                </p>
              </div>
              {!this.state.policyCheck && (
                <FormHelperText error>
                  You need to agree to the Terms of Service and Privacy Policy
                  to continue.
                </FormHelperText>
              )}
              <FormControl
                className={classes.formControl}
                disabled={signupLoading}
              >
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}
                  disabled={signupLoading}
                >
                  Submit
                </Button>
              </FormControl>
            </form>
            <Footer />
          </div>
        ) : (
          <BlockMessage>
            Sorry! You don't have access to this page.
          </BlockMessage>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(SignupForm);
