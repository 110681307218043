export const LOAD_ALL_FUNDS = 'funds/LOAD_ALL_FUNDS';
export const ADD_FUND = 'funds/ADD_FUND';
export const DELETE_FUND = 'funds/DELETE_FUND';
export const LOAD_EXCHANGE_RATE = 'funds/LOAD_EXCHANGE_RATE';
export const SAVE_EXCHANGE_RATE = 'funds/SAVE_EXCHANGE_RATE';


export const DELETE_EXCHANGE_RATE_REQUEST = 'funds/DELETE_EXCHANGE_RATE_REQUEST';
export const DELETE_EXCHANGE_RATE_SUCCESS = 'funds/DELETE_EXCHANGE_RATE_SUCCESS';
export const DELETE_EXCHANGE_RATE_FAILURE = 'funds/DELETE_EXCHANGE_RATE_FAILURE';

export const LOAD_TRANSACTION_REPORT_REQUEST = 'funds/LOAD_TRANSACTION_REPORT_REQUEST';
export const LOAD_TRANSACTION_REPORT_SUCCESS = 'funds/LOAD_TRANSACTION_REPORT_SUCCESS';
export const LOAD_TRANSACTION_REPORT_FAILURE = 'funds/LOAD_TRANSACTION_REPORT_FAILURE';
export const CLEAR_TRANSACTION_REPORT = 'funds/CLEAR_TRANSACTION_REPORT';

export const LOAD_FUND_REQUEST = 'funds/LOAD_FUND_REQUEST';
export const LOAD_FUND_SUCCESS = 'funds/LOAD_FUND_SUCCESS';
export const LOAD_FUND_FAILURE = 'funds/LOAD_FUND_FAILURE';

export const LOAD_FUND_COMPANIES_TABLE_COLUMNS_REQUEST='funds/LOAD_FUND_COMPANIES_TABLE_COLUMNS_REQUEST';
export const LOAD_FUND_COMPANIES_TABLE_COLUMNS_SUCCESS='funds/LOAD_FUND_COMPANIES_TABLE_COLUMNS_SUCCESS';
export const LOAD_FUND_COMPANIES_TABLE_COLUMNS_FAILURE='funds/LOAD_FUND_COMPANIES_TABLE_COLUMNS_FAILURE';

export const UPDATE_FUND_COMPANIES_TABLE_COLUMNS_REQUEST='funds/UPDATE_FUND_COMPANIES_TABLE_COLUMNS_REQUEST';
export const UPDATE_FUND_COMPANIES_TABLE_COLUMNS_SUCCESS='funds/UPDATE_FUND_COMPANIES_TABLE_COLUMNS_SUCCESS';
export const UPDATE_FUND_COMPANIES_TABLE_COLUMNS_FAILURE='funds/UPDATE_FUND_COMPANIES_TABLE_COLUMNS_FAILURE';

export const COMPANIES_OPEN_MANAGE_COLUMN='funds/COMPANIES_OPEN_MANAGE_COLUMN';
export const COMPANIES_CLOSE_MANAGE_COLUMN='funds/COMPANIES_CLOSE_MANAGE_COLUMN';

export const UPDATE_FUND_REQUEST = 'funds/UPDATE_FUND_REQUEST';
export const UPDATE_FUND_SUCCESS = 'funds/UPDATE_FUND_SUCCESS';
export const UPDATE_FUND_FAILURE = 'funds/UPDATE_FUND_FAILURE';

export const LOAD_FUND_ACCESS_MAP_REQUEST = 'funds/LOAD_FUND_ACCESS_MAP_REQUEST';
export const LOAD_FUND_ACCESS_MAP_SUCCESS = 'funds/LOAD_FUND_ACCESS_MAP_SUCCESS';
export const LOAD_FUND_ACCESS_MAP_FAILURE = 'funds/LOAD_FUND_ACCESS_MAP_FAILURE';

export const UPDATE_FUND_ACCESS_REQUEST = 'funds/UPDATE_FUND_ACCESS_REQUEST';
export const UPDATE_FUND_ACCESS_SUCCESS = 'funds/UPDATE_FUND_ACCESS_SUCCESS';
export const UPDATE_FUND_ACCESS_FAILURE = 'funds/UPDATE_FUND_ACCESS_FAILURE';

export const CLEAR = 'funds/CLEAR';

export const ADD_CUSTOM_FIELD_REQUEST = 'funds/ADD_CUSTOM_FIELD_REQUEST';
export const ADD_CUSTOM_FIELD_SUCCESS = 'funds/ADD_CUSTOM_FIELD_SUCCESS';
export const ADD_CUSTOM_FIELD_FAILURE = 'funds/ADD_CUSTOM_FIELD_FAILURE';

export const LOAD_CUSTOM_FIELDS_REQUEST = 'funds/LOAD_CUSTOM_FIELDS_REQUEST';
export const LOAD_CUSTOM_FIELDS_SUCCESS = 'funds/LOAD_CUSTOM_FIELDS_SUCCESS';
export const LOAD_CUSTOM_FIELDS_FAILURE = 'funds/LOAD_CUSTOM_FIELDS_FAILURE';

export const UPDATE_CUSTOM_FIELDS_REQUEST = 'funds/UPDATE_CUSTOM_FIELDS_REQUEST';
export const UPDATE_CUSTOM_FIELDS_SUCCESS = 'funds/UPDATE_CUSTOM_FIELDS_SUCCESS';
export const UPDATE_CUSTOM_FIELDS_FAILURE = 'funds/UPDATE_CUSTOM_FIELDS_FAILURE';

export const REMOVE_CUSTOM_FIELDS_REQUEST = 'funds/REMOVE_CUSTOM_FIELDS_REQUEST';
export const REMOVE_CUSTOM_FIELDS_SUCCESS = 'funds/REMOVE_CUSTOM_FIELDS_SUCCESS';
export const REMOVE_CUSTOM_FIELDS_FAILURE = 'funds/REMOVE_CUSTOM_FIELDS_FAILURE';

export const LOAD_CUSTOM_FIELD_VALUES_REQUEST = 'funds/LOAD_CUSTOM_FIELD_VALUES_REQUEST';
export const LOAD_CUSTOM_FIELD_VALUES_SUCCESS = 'funds/LOAD_CUSTOM_FIELD_VALUES_SUCCESS';
export const LOAD_CUSTOM_FIELD_VALUES_FAILURE = 'funds/LOAD_CUSTOM_FIELD_VALUES_FAILURE';

export const UPDATE_CUSTOM_FIELD_VALUE_REQUEST = 'funds/UPDATE_CUSTOM_FIELD_VALUE_REQUEST';
export const UPDATE_CUSTOM_FIELD_VALUE_SUCCESS = 'funds/UPDATE_CUSTOM_FIELD_VALUE_SUCCESS';
export const UPDATE_CUSTOM_FIELD_VALUE_FAILURE = 'funds/UPDATE_CUSTOM_FIELD_VALUE_FAILURE';
