/**
 *
 * @format
 */
import supportsHTML5Storage from './supportsHTML5Storage';
import Time from '../components/Time';

const setPortfolioTableColumnWidths = data => {
  if (!supportsHTML5Storage()) {
    return;
  }
  let columnIdResizedPairs = window.localStorage.getItem(
    'portfolioColumnIdResizedPairs'
  );
  if (columnIdResizedPairs && typeof columnIdResizedPairs === 'string') {
    try {
      columnIdResizedPairs = JSON.parse(columnIdResizedPairs);
      // If expires_on is in the past, expire
      if (new Date(columnIdResizedPairs.expires_on) < new Date()) {
        window.localStorage.removeItem('columnIdResizedPairs');
        columnIdResizedPairs = { value: {} };
      }
    } catch (e) {
      return null;
    }
  } else {
    columnIdResizedPairs = { value: {} };
  }
  const payload = {
    value: {
      ...columnIdResizedPairs.value,
      data,
    }, // array of columns and their configs
    expires_on: Time.add(new Date(), 15, 'minute'), // when we consider this key expired
  };
  window.localStorage.removeItem('portfolioColumnIdResizedPairs');
  window.localStorage.setItem(
    'portfolioColumnIdResizedPairs',
    JSON.stringify(payload)
  );
};

export default setPortfolioTableColumnWidths;
