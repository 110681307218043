/**
 * Deal reducers also exports action creators
 *
 * @author Ritesh Shrivastav
 */

import addAssignee from './actionCreators/addAssignee';
import addDeal from './actionCreators/addDeal';
import addDealEventNote from './actionCreators/addDealEventNote';
import addDealNote from './actionCreators/addDealNote';
import addInternalReferrer from './actionCreators/addInternalReferrer';
import addInternalReferrers from './actionCreators/addInternalReferrers';
import addParticipant from './actionCreators/addParticipant';
import addStartupFounder from './actionCreators/addStartupFounder';
import addStartupFounders from './actionCreators/addStartupFounders';
import associateCategory from './actionCreators/associateCategory';
import clearDeals from './actionCreators/clearDeals';
import convertFounderToExternalReferrer from './actionCreators/convertFounderToExternalReferrer';
import deassociateCategory from './actionCreators/deassociateCategory';
import deleteDeal from './actionCreators/deleteDeal';
import incrementDealNoteCount from './actionCreators/incrementDealNoteCount';
import loadAssigneeList from './actionCreators/loadAssigneeList';
import loadDeal from './actionCreators/loadDeal';
import loadDealCategories from './actionCreators/loadDealCategories';
import loadDealEvent from './actionCreators/loadDealEvent';
import loadDealEventNotes from './actionCreators/loadDealEventNotes';
import loadDealEvents from './actionCreators/loadDealEvents';
import loadDealList from './actionCreators/loadDealList';
import loadDealNote from './actionCreators/loadDealNote';
import loadDealNotes from './actionCreators/loadDealNotes';
import loadDealParticipants from './actionCreators/loadDealParticipants';
import loadDealResourceCounts from './actionCreators/loadDealResourceCounts';
import loadDealSuggestions from './actionCreators/loadDealSuggestions';
import loadPinnedDealNote from './actionCreators/loadPinnedDealNote';
import reducers from './reducers';
import removeAssignee from './actionCreators/removeAssignee';
import removeExternalReferrer from './actionCreators/removeExternalReferrer';
import removeInternalReferrer from './actionCreators/removeInternalReferrer';
import removeParticipant from './actionCreators/removeParticipant';
import removeStartupFounder from './actionCreators/removeStartupFounder';
import setDealIdList from './actionCreators/setDealIdList';
import clearDealIdList from './actionCreators/clearDealIdList';
import toggleDealSummary from './actionCreators/toggleDealSummary';
import updateDeal from './actionCreators/updateDeal';
import updatePinnedStatus from './actionCreators/updatePinnedStatus';
import loadDealsResourceCount from './actionCreators/loadDealsResourceCount';
import modifyAttachmentCount from './actionCreators/modifyAttachmentCount'
import modifyDealConversationCount from './actionCreators/modifyDealConversationCount'
import loadDealTableRowSetting from './actionCreators/loadDealTableRowSetting';
import updateDealTableRowSetting from './actionCreators/updateDealTableRowSetting';
import convertToPortfolio from './actionCreators/convertToPortfolio';
import unlinkFromPortfolio from './actionCreators/unlinkFromPortfolio';
import loadCycleReport from './actionCreators/loadCycleReport';
import loadActivityLog from './actionCreators/loadActivityLog';
import loadCustomFields from './actionCreators/loadCustomFields';
import loadDealTableColumns from './actionCreators/loadDealTableColumns';
import setFilters from './actionCreators/setFilters';
import getDealsCount from './actionCreators/getDealsCount';

/**
 * Loads deal with participant, this action creator is written here because it
 * depends on other action creators. Creating it in different location and
 * importing dependent action creator will create import loop.
 * @param  {String} dealId Deal id
 */
export const loadDealWithParticipants = (dealId, options={}, callbacks={}) => {
  return dispatch => {
    const { loadDealOptions } = options;
    const { loadDealSuccessCallback, loadDealFailureCallback } = callbacks;
    // @todo: Handle errors here gracefully. Forward to upstream and handle error
    // cases appropriately there
    dispatch(loadDeal(dealId, loadDealOptions))
      .then((data) => typeof loadDealSuccessCallback === 'function' && loadDealSuccessCallback(data))
      .catch((err) => typeof loadDealFailureCallback === 'function' && loadDealFailureCallback(err));
    dispatch(loadDealParticipants(dealId));
  };
};

// Reducers by default
export default reducers;
// Export all action creators
export {
  loadDealSuggestions,
  loadDealList,
  setDealIdList,
  clearDealIdList,
  loadAssigneeList,
  loadDeal,
  loadDealParticipants,
  associateCategory,
  removeParticipant,
  removeAssignee,
  addAssignee,
  addParticipant,
  deassociateCategory,
  updateDeal,
  loadDealEvent,
  loadDealEvents,
  addDealNote,
  loadDealNote,
  loadDealNotes,
  addStartupFounder,
  addStartupFounders,
  removeStartupFounder,
  loadDealEventNotes,
  addDealEventNote,
  loadDealCategories,
  loadDealResourceCounts,
  removeExternalReferrer,
  removeInternalReferrer,
  convertFounderToExternalReferrer,
  toggleDealSummary,
  clearDeals,
  addDeal,
  incrementDealNoteCount,
  addInternalReferrer,
  addInternalReferrers,
  loadPinnedDealNote,
  updatePinnedStatus,
  deleteDeal,
  loadDealsResourceCount,
  modifyAttachmentCount,
  modifyDealConversationCount,
  loadDealTableRowSetting,
  updateDealTableRowSetting,
  convertToPortfolio,
  unlinkFromPortfolio,
  loadCycleReport,
  loadActivityLog,
  loadCustomFields,
  loadDealTableColumns,
  setFilters,
  getDealsCount
};
