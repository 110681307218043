/** @format */
import {
  LOAD_PORTCO_ACL_REQUEST,
  LOAD_PORTCO_ACL_SUCCESS,
  LOAD_PORTCO_ACL_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case LOAD_PORTCO_ACL_REQUEST:
      return Object.assign({}, state, {
        portcoACLLoading: {
          ...state.portcoACLLoading,
          [payload.portcoId]: true,
        },
        portcoACLError: {
          ...state.portcoACLError,
          [payload.portcoId]: null,
        },
      });

    case LOAD_PORTCO_ACL_SUCCESS:
      return Object.assign({}, state, {
        portcoACLLoading: {
          ...state.portcoACLLoading,
          [payload.portcoId]: false,
        },
        portcoACLError: {
          ...state.portcoACLError,
          [payload.portcoId]: null,
        },
        portcoACL: {
          ...state.portcoACL,
          [payload.portcoId]: payload.data || [],
        },
      });

    case LOAD_PORTCO_ACL_FAILURE:
      return Object.assign({}, state, {
        portcoACLLoading: {
          ...state.portcoACLLoading,
          [payload.portcoId]: false,
        },
        portcoACLError: {
          ...state.portcoACLError,
          [payload.portcoId]: payload.message,
        },
      });

    default:
      return state;
  }
};
