export const LOAD_PORTFOLIO_TRANSACTIONS_REQUEST = 'portfolio-valuation/LOAD_PORTFOLIO_TRANSACTIONS_REQUEST';
export const LOAD_PORTFOLIO_TRANSACTIONS_SUCCESS = 'portfolio-valuation/LOAD_PORTFOLIO_TRANSACTIONS_SUCCESS';
export const LOAD_PORTFOLIO_TRANSACTIONS_FAILURE = 'portfolio-valuation/LOAD_PORTFOLIO_TRANSACTIONS_FAILURE';

export const ADD_PORTFOLIO_TRANSACTION = 'portfolio-valuation/ADD_PORTFOLIO_TRANSACTION';
export const DELETE_PORTFOLIO_TRANSACTION = 'portfolio-valuation/DELETE_PORTFOLIO_TRANSACTION';
export const CONVERT_PORTFOLIO_TRANSACTION = 'portfolio-valuation/CONVERT_PORTFOLIO_TRANSACTION';
export const UPDATE_PORTFOLIO_TRANSACTION = 'portfolio-valuation/UPDATE_PORTFOLIO_TRANSACTION';

export const LOAD_PORTFOLIO_VALUATIONS_REQUEST = 'portfolio-valuation/LOAD_PORTFOLIO_VALUATIONS_REQUEST';
export const LOAD_PORTFOLIO_VALUATIONS_SUCCESS = 'portfolio-valuation/LOAD_PORTFOLIO_VALUATIONS_SUCCESS';
export const LOAD_PORTFOLIO_VALUATIONS_FAILURE = 'portfolio-valuation/LOAD_PORTFOLIO_VALUATIONS_FAILURE';

export const DELETE_PORTFOLIO_VALUATION = 'portfolio-valuation/DELETE_PORTFOLIO_VALUATION';
export const ADD_PORTFOLIO_VALUATION = 'portfolio-valuation/ADD_PORTFOLIO_VALUATION';
export const UPDATE_PORTFOLIO_VALUATION = 'portfolio-valuation/UPDATE_PORTFOLIO_VALUATION';

export const DELETE_PORTFOLIO_VALUATION_REQUEST = 'portfolio-valuation/DELETE_PORTFOLIO_VALUATION_REQUEST';
export const DELETE_PORTFOLIO_VALUATION_SUCCESS = 'portfolio-valuation/DELETE_PORTFOLIO_VALUATION_SUCCESS';
export const DELETE_PORTFOLIO_VALUATION_FAILURE = 'portfolio-valuation/DELETE_PORTFOLIO_VALUATION_FAILURE';

export const LOAD_PORTFOLIO_FUNDING_ROUNDS = 'portfolio-valuation/LOAD_PORTFOLIO_FUNDING_ROUNDS';
export const DELETE_PORTFOLIO_FUNDING_ROUND = 'portfolio-valuation/DELETE_PORTFOLIO_FUNDING_ROUND';
export const ADD_PORTFOLIO_FUNDING_ROUND = 'portfolio-valuation/ADD_PORTFOLIO_FUNDING_ROUND';

export const ADD_FUNDING_ROUND_IN_BULK_REQUEST = 'portfolio-valuation/ADD_FUNDING_ROUND_IN_BULK_REQUEST'
export const ADD_FUNDING_ROUND_IN_BULK_SUCCESS = 'portfolio-valuation/ADD_FUNDING_ROUND_IN_BULK_SUCCESS'
export const ADD_FUNDING_ROUND_IN_BULK_FAILURE = 'portfolio-valuation/ADD_FUNDING_ROUND_IN_BULK_FAILURE'

export const ADD_DIRECT_INVESTMENT_IN_BULK_REQUEST = 'portfolio-valuation/ADD_DIRECT_INVESTMENT_IN_BULK_REQUEST'
export const ADD_DIRECT_INVESTMENT_IN_BULK_SUCCESS = 'portfolio-valuation/ADD_DIRECT_INVESTMENT_IN_BULK_SUCCESS'
export const ADD_DIRECT_INVESTMENT_IN_BULK_FAILURE = 'portfolio-valuation/ADD_DIRECT_INVESTMENT_IN_BULK_FAILURE'

export const ADD_IN_DIRECT_INVESTMENT_IN_BULK_REQUEST = 'portfolio-valuation/ADD_IN_DIRECT_INVESTMENT_IN_BULK_REQUEST'
export const ADD_IN_DIRECT_INVESTMENT_IN_BULK_SUCCESS = 'portfolio-valuation/ADD_IN_DIRECT_INVESTMENT_IN_BULK_SUCCESS'
export const ADD_IN_DIRECT_INVESTMENT_IN_BULK_FAILURE = 'portfolio-valuation/ADD_IN_DIRECT_INVESTMENT_IN_BULK_FAILURE'

export const ADD_VALUATION_IN_BULK_REQUEST = 'portfolio-valuation/ADD_VALUATION_IN_BULK_REQUEST'
export const ADD_VALUATION_IN_BULK_SUCCESS = 'portfolio-valuation/ADD_VALUATION_IN_BULK_SUCCESS'
export const ADD_VALUATION_IN_BULK_FAILURE = 'portfolio-valuation/ADD_VALUATION_IN_BULK_FAILURE'


export const CLEAR = 'portfolio-valuation/CLEAR'
