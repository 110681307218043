/**
 * This helper method is meant as an alternative to lodash's omit
 * @format
 * Lodash's omit seems to have performance issues, this method will be discontinued in lodash v5
 * native destructuring seems to be faster than lodash's omit
 * https://www.measurethat.net/Benchmarks/Show/8837/0/lodash-omit-vs-native-object-destruction
 *
 * @param {Object} obj  object whose properties are to be removed
 * @param {Array} propsToRemove Array of properties whose properties are removed
 * @returns
 */

const omit = (obj, propsToRemove) => {
  const entries = Object.entries(obj);
  const filtered = Object.fromEntries(
    entries.filter(([key]) => !propsToRemove.includes(key))
  );
  return filtered;
};

export default omit;
