/** @format */

import * as types from '../actions';
import request, { wrap } from '../../../helpers/request';
import isEmptyString from '../../../helpers/string/isEmptyString';
import { enqueueNotification } from '../../app';

/**
 * Lists the commitment for a fund (+ LP)
 * @param {UUID} fundId Fund's id
 * @param {Object} options Options. Supports { limited_partner_id, limit, page, withLimitedPartner, withFund }
 * @returns List of commitment in the fund
 */
export default function loadCommitmentList(fundId, options) {
  if (isEmptyString(fundId)) {
    throw new Error('Fund id missing');
  }
  return async dispatch => {
    dispatch({
      type: types.LOAD_COMMITMENT_LIST_REQUEST,
      payload: {
        fundId,
      },
    });

    try {
      const { data } = await wrap(
        request.get(
          `/api/fund-transactions/v1/fund/${fundId}/commitments`,
          options,
          dispatch
        )
      );

      dispatch({
        type: types.LOAD_COMMITMENT_LIST_SUCCESS,
        payload: { fundId, data: data.data },
      });

      return data.data;
    } catch (err) {
      const message = err.isApplicationError
        ? err.message
        : 'Something went wrong when loading commitments for this fund. Please try again.';
      dispatch({
        type: types.LOAD_COMMITMENT_LIST_FAILURE,
        payload: { fundId, message: message },
      });
      dispatch(enqueueNotification(message));
      throw new Error(message);
    }
  };
}
