/**
* Footer component
*
* @author Akhila
*/
import React from 'react';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
   root: {
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    left: '0px',
    bottom: '0px',
    height: '40px',
    maxWidth: '100%',
    marginTop: '2em',
    [theme.breakpoints.up('lg')]: {
      // To maintain space between the form contents and
      // this footer. If any extra contents we add footer goes down
      // and margin top should be reduced.
      marginTop: '100px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    }
  },
  footerLink: {
    textDecoration: 'none',
    marginRight: '2em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block'
    },
  }
});

class Footer extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Link to="/terms-and-conditions/v1" className={classes.footerLink}>
          Terms & Conditions
        </Link>
        <Link to="/privacy-policy/v1" className={classes.footerLink}>
          Privacy Policy
        </Link>
        <Link to="/security-policy/v1" className={classes.footerLink}>
          Security Policy
        </Link>
      </div>
    );
  }
}

export default withStyles(styles)(Footer);
