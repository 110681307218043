/**@format */
import * as types from './actions';
import { assign } from '../../helpers/object';
import { map, isArray } from 'lodash';

const initial = {
  // Custom Fields
  customFields: [],
  pitchSubmissionsCustomFields: [],
  customFieldsLoading: false,
  customFieldsError: null,
  customFieldValues: {},
  pitchSubmissionsCustomFieldValues: {},
  customFieldValuesLoading: {},
  customFieldValuesError: null,
};

export default function(state = initial, { type, payload }) {
  switch (type) {
    case types.LOAD_CUSTOM_FIELDS_REQUEST:
      return assign(state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case types.LOAD_CUSTOM_FIELDS_SUCCESS: {
      const newState = {
        customFieldsLoading: false,
        customFieldsError: null,
      };
      payload?.options?.source === 'pitch_submissions'
        ? (newState['pitchSubmissionsCustomFields'] = payload.data)
        : (newState['customFields'] = payload.data);
      return assign(state, newState);
    }
    case types.LOAD_CUSTOM_FIELDS_FAILURE:
      return assign(state, {
        customFieldsLoading: false,
        customFieldsError: payload.message,
      });

    case types.ADD_CUSTOM_FIELD_REQUEST:
      return assign(state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case types.ADD_CUSTOM_FIELD_SUCCESS:
      return assign(state, {
        customFieldsLoading: false,
        customFields: payload,
        customFieldsError: null,
      });

    case types.ADD_CUSTOM_FIELD_FAILURE:
      return assign(state, {
        customFieldsLoading: false,
        customFieldsError: payload,
      });

    case types.UPDATE_CUSTOM_FIELDS_REQUEST:
      return assign(state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case types.UPDATE_CUSTOM_FIELDS_SUCCESS:
      return assign(state, {
        customFieldsLoading: false,
        customFieldsError: null,
      });

    case types.UPDATE_CUSTOM_FIELDS_FAILURE:
      return assign(state, {
        customFieldsLoading: false,
        customFieldsError: payload,
      });

    case types.REMOVE_CUSTOM_FIELDS_REQUEST:
      return assign(state, {
        customFieldsLoading: true,
        customFieldsError: null,
      });

    case types.REMOVE_CUSTOM_FIELDS_SUCCESS:
      return assign(state, {
        // -------------------------
        // update it here
        customFieldsLoading: false,
        customFieldsError: null,
      });

    case types.REMOVE_CUSTOM_FIELDS_FAILURE:
      return assign(state, {
        customFieldsLoading: false,
        customFieldsError: payload,
      });

    case types.LOAD_CUSTOM_FIELD_VALUES_REQUEST: {
      let loadingMap = {};
      let dealArray = isArray(payload.dealId)
        ? payload.dealId
        : [payload.dealId];
      map(dealArray, dealId => {
        loadingMap[dealId] = true;
      });

      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...loadingMap,
        },
        customFieldValuesError: null,
      });
    }

    case types.LOAD_CUSTOM_FIELD_VALUES_SUCCESS: {
      let dataMap = {};
      let loadingMap = {};
      map(payload.data, deal => {
        dataMap[deal.id] = deal;
        loadingMap[deal.id] = false;
      });
      const newState = {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...loadingMap,
        },
        customFieldValuesError: null,
      };

      payload?.options?.source === 'pitch_submissions'
        ? (newState['pitchSubmissionsCustomFieldValues'] = {
            ...state.pitchSubmissionsCustomFieldValues,
            ...dataMap,
          })
        : (newState['customFieldValues'] = {
            ...state.customFieldValues,
            ...dataMap,
          });

      return Object.assign({}, state, newState);
    }

    case types.LOAD_CUSTOM_FIELD_VALUES_FAILURE: {
      let loadingMap = {};
      let dealArray = isArray(payload.dealId)
        ? payload.dealId
        : [payload.dealId];
      map(dealArray, dealId => {
        loadingMap[dealId] = false;
      });

      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...loadingMap,
        },
        customFieldValuesError: payload.message,
      });
    }

    case types.UPDATE_CUSTOM_FIELD_VALUE_REQUEST:
      return assign(state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...payload,
        },
        customFieldsError: null,
      });

    case types.UPDATE_CUSTOM_FIELD_VALUE_SUCCESS:
      return assign(state, {
        customFieldsError: null,
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...payload.loadingMap,
        },
        customFieldValues: {
          ...state.customFieldValues,
          ...payload.dataMap,
        },
      });

    case types.UPDATE_CUSTOM_FIELD_VALUE_FAILURE:
      return assign(state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...payload.loadingMap,
        },
        customFieldsError: payload.message,
      });

    default:
      return state;
  }
}
