/**
 * Adds new founders to the startup associated with the deal
 *
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  ADD_STARTUP_FOUNDERS_REQUEST,
  ADD_STARTUP_FOUNDERS_SUCCESS,
  ADD_STARTUP_FOUNDERS_FAILURE,
} from '../actions';

export default (dealId, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_STARTUP_FOUNDERS_REQUEST,
      });

      request
        .post(`/api/v4/deals/${dealId}/startup/founders/add`, form, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: ADD_STARTUP_FOUNDERS_SUCCESS,
              payload: data.data,
            });
            resolve(data.data);
          } else {
            dispatch({
              type: ADD_STARTUP_FOUNDERS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while adding founders, please try again.';
          dispatch({
            type: ADD_STARTUP_FOUNDERS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};
