/**
 *
 * @format
 */
import {
  UPDATE_PORTFOLIO_TABLE_COLUMNS_REQUEST,
  UPDATE_PORTFOLIO_TABLE_COLUMNS_SUCCESS,
  UPDATE_PORTFOLIO_TABLE_COLUMNS_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case UPDATE_PORTFOLIO_TABLE_COLUMNS_REQUEST:
      return Object.assign({}, state, {
        columns: payload,
        columnsLoading: true,
        columnsError: null,
      });
    /**
     * UPDATE_PORTFOLIO_TABLE_COLUMN_SUCCESS
     */
    case UPDATE_PORTFOLIO_TABLE_COLUMNS_SUCCESS:
      return Object.assign({}, state, {
        columns: payload,
        columnsLoading: false,
        columnsError: null,
      });
    case UPDATE_PORTFOLIO_TABLE_COLUMNS_FAILURE:
      return Object.assign({}, state, {
        columnsLoading: false,
        columnsError: payload,
      });

    default:
      return state;
  }
};
