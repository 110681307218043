/**
 * Organization action creators
 *
 * @author Ritesh Shrivastav
 */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';
import executeInBackground from '../../helpers/executeInBackground';
import getDealOverviewFields from '../../helpers/getDealOverviewFields';
import setDealOverviewFields from '../../helpers/setDealOverviewFields';
import getPortfolioTabs from '../../helpers/getPortfolioTabs';
import setPortfolioTabs from '../../helpers/setPortfolioTabs';

export default reducers;

/**
 * Disable team member account
 * @param  {String} user_id User id of the team member
 * @return {Promise}       Resolves on success
 */
export const disableMemberAccount = (member_user_id, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.DISABLE_MEMBER_ACCOUNT_REQUEST,
        payload: { member_user_id }
      });
      request.post(`/api/v1/organizations/0/members/${member_user_id}/disable`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.DISABLE_MEMBER_ACCOUNT_SUCCESS,
            payload: { member_user_id }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.DISABLE_MEMBER_ACCOUNT_FAILURE,
            payload: { message: data.message, member_user_id }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while disabling team member account, please try again.'
        dispatch({
          type: types.DISABLE_MEMBER_ACCOUNT_FAILURE,
          payload: { message, member_user_id }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Assign member deal to self
 * @param  {String} user_id User id of the team member
 * @return {Promise}       Resolves on success
 */
export const assignMemberDealToSelf = (member_user_id, deal_id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.ASSIGN_MEMBER_DEAL_TO_SELF_REQUEST,
        payload: { member_user_id, deal_id }
      });
      request.post(`/api/v1/organizations/0/members/${member_user_id}/pipelines/${deal_id}/assign`, {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.ASSIGN_MEMBER_DEAL_TO_SELF_SUCCESS,
            payload: { data: data.data, member_user_id, deal_id }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.ASSIGN_MEMBER_DEAL_TO_SELF_FAILURE,
            payload: { message: data.message, member_user_id, deal_id }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading member\'s deals, please try again.'
        dispatch({
          type: types.ASSIGN_MEMBER_DEAL_TO_SELF_FAILURE,
          payload: { message, member_user_id, deal_id }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Load member deals
 * @param  {String} user_id User id of the team member
 * @return {Promise}       Resolves on success
 */
export const loadMemberDeals = (user_id, options) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_MEMBER_DEALS_REQUEST,
        payload: { user_id }
      });
      request.get(`/api/v1/organizations/0/members/${user_id}/pipelines`, options, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_MEMBER_DEALS_SUCCESS,
            payload: { data: data.data, user_id }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.LOAD_MEMBER_DEALS_FAILURE,
            payload: { message: data.message, user_id }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading member\'s deals, please try again.'
        dispatch({
          type: types.LOAD_MEMBER_DEALS_FAILURE,
          payload: { message, user_id }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Fetches team member roles
 * @param  {String} user_id User id
 */
export const loadTeamMemberRoles = (user_id) => {
  return async dispatch => {
    if(!user_id){
      const message = 'Please fetch a valid user id';
      dispatch({
        type: types.LOAD_MEMBER_ROLES_FAILURE,
        payload: {
          user_id,
          message
        }
      });
      dispatch(enqueueNotification(message))
      throw new Error(message)
    }
    dispatch({
      type: types.LOAD_MEMBER_ROLES_REQUEST,
      payload: { user_id }
    });
    let data;
    try{
      const response = await request.get(`/api/v1/organizations/0/members/${user_id}/roles`, {}, dispatch);
      data = response.data
    }catch(err){
      const message = 'Something went wrong while loading member roles, please try again.';
      dispatch({
        type: types.LOAD_MEMBER_ROLES_FAILURE,
        payload: {
          payload: message,
          user_id
        }
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if(data.success){
      dispatch({
        type: types.LOAD_MEMBER_ROLES_SUCCESS,
        payload: {
          payload: data.data,
          user_id
        },
      });
      return data.data;
    } else {
      dispatch({
        type: types.LOAD_MEMBER_ROLES_FAILURE,
        payload: {
          payload: data.message,
          user_id
        }
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }

  };
};

/**
 * Update team member roles
 * @param  {String} user_id User id
 * @param  {Object} form    Request body
 * @return {Promise} Resolves on success
 */
export const updateTeamMemberRoles = (user_id, form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_MEMBER_ROLES_REQUEST,
        payload: { user_id }
      });
      request.post(`/api/v1/organizations/0/members/${user_id}/roles`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.UPDATE_MEMBER_ROLES_SUCCESS,
            payload: {
              payload: data.data,
              user_id
            },
          });
          resolve(data.data);
        } else {
          dispatch({
            type: types.UPDATE_MEMBER_ROLES_FAILURE,
            payload: {
              payload: data.message,
              user_id
            }
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while updating member roles, please try again.';
        dispatch({
          type: types.UPDATE_MEMBER_ROLES_FAILURE,
          payload: {
            payload: message,
            user_id
          }
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Fetches team members
 * @deprecated Use `getTeamMembers()` instead which returns promise
 */
export const loadTeamMembers = () => {
  return dispatch => {
    dispatch({
      type: types.LOAD_TEAM_MEMBERS_REQUEST
    });
    request.get('/api/v1/organizations/0/members', {}, dispatch)
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: types.LOAD_TEAM_MEMBERS_SUCCESS,
          payload: data.data,
        });
      } else {
        dispatch({
          type: types.LOAD_TEAM_MEMBERS_FAILURE,
          payload: data.message
        });
        dispatch(enqueueNotification(data.message));
      }
    })
    .catch((err) => {
      const message = 'Something went wrong while loading team members, please try again.';
      dispatch({
        type: types.LOAD_TEAM_MEMBERS_FAILURE,
        payload: message
      });
      dispatch(enqueueNotification(message));
    });
  };
};

/**
 * Fetches team members and returns promise object, which resolves after the
 * team members are loaded.
 */
export const getTeamMembers = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_TEAM_MEMBERS_REQUEST
      });
      request.get('/api/v1/organizations/0/members', {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_TEAM_MEMBERS_SUCCESS,
            payload: data.data,
          });
          resolve(data);
        } else {
          dispatch({
            type: types.LOAD_TEAM_MEMBERS_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading team members, please try again.';
        dispatch({
          type: types.LOAD_TEAM_MEMBERS_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(new Error(message));
      });
    });
  };
};


/**
 * Fetches the invited members info and returns promise object, which resolves after the
 * invites are loaded.
 */
export const loadInviteeList = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_INVITEE_LIST_REQUEST
      });
      request.get('/api/v5/organizations/members/invited', {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_INVITEE_LIST_SUCCESS,
            payload: data.data,
          });
          resolve(data);
        } else {
          dispatch({
            type: types.LOAD_INVITEE_LIST_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while loading the invitees, please try again.';
        dispatch({
          type: types.LOAD_INVITEE_LIST_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(new Error(message));
      });
    });
  };
};

/**
* Invite multiple members
* @param  {Array} form  Form data
* @return {Promise}     Resolves on success
*
*/
export const inviteMembers = (emails) => {
  return dispatch => {
    return new Promise ((resolve, reject) => {
      dispatch({
        type: types.INVITE_MEMBERS_REQUEST
      });
      request.post('/api/v1/organizations/0/members', { emails: emails }, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.INVITE_MEMBERS_SUCCESS
          });
          resolve(data.success);
        } else {
          dispatch({
            type: types.INVITE_MEMBERS_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while inviting team members, please try again.';
        dispatch({
          type: types.INVITE_MEMBERS_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * Invite team member
 * @param  {Object} form Form data
 * @return {Promise}     Resolves on success
 */
export const inviteTeamMember = (form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.INVITE_MEMBER_REQUEST
      });
      request.post('/api/v5/organizations/0/members', form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.INVITE_MEMBER_SUCCESS,
            payload: data.data,
          });
          dispatch(enqueueNotification(`Invited successfully.`));
          resolve(data.data);
        } else {
          dispatch({
            type: types.INVITE_MEMBER_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        let message = 'Something went wrong while inviting team members, please try again.';
        if(err?.response?.status === 429) {
          message = `Too fast! Try again in a couple of seconds.`
        }
        dispatch({
          type: types.INVITE_MEMBER_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

/**
 * List organizations
 * @return {Promise}     Resolves with list of orgs on success
 */
export const loadOrganizationsList = (options) => {
  options = options || {nolimit: true};
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LIST_ORGANIZATIONS_REQUEST,
        payload: {
          options
        }
      });
      request.get('/api/v2/organizations', options, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LIST_ORGANIZATIONS_SUCCESS,
            payload: {
              data: data.data,
              options
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.LIST_ORGANIZATIONS_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while fetching list of organizations, please try again.';
        dispatch({
          type: types.LIST_ORGANIZATIONS_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

export const loadPorfolioTabs = (
  shouldForceAPIRequest = false,
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!shouldForceAPIRequest) {
        // If column information is present in local storage, fetch it from there
        // `shouldForceAPIRequest` allows for overriding this behaviour
        const columnData = getPortfolioTabs();
        if (columnData && columnData.length > 0) {
          dispatch({
            type: types.PORTFOLIO_TAB_SUCCESS,
            payload: columnData,
          });
          // also contact server to get up to date data, which gets updated in the background
          executeInBackground(() =>
            loadPorfolioTabs( true)(dispatch).catch(() => {})
          );
          return resolve(columnData);
        }
      }
      dispatch({
        type: types.PORTFOLIO_TAB_REQUEST,
      });
      request
        .get(
          `/api/portfolio/v1/tabs`,
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.PORTFOLIO_TAB_SUCCESS,
              payload: data.data,
            });
            setPortfolioTabs(data.data);
            resolve(data);
          } else {
            dispatch({
              type: types.PORTFOLIO_TAB_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading portfolio tabs order, please try again.';
          dispatch({
            type: types.PORTFOLIO_TAB_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const updatePortfolioTabs = (orderedFields) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_PORTFOLIO_TAB_REQUEST,
        payload: orderedFields,
      });
      request
        .put(
          `/api/portfolio/v1/tabs`,
          { tabs: orderedFields },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_PORTFOLIO_TAB_SUCCESS,
              payload: orderedFields,
            });
            setPortfolioTabs(orderedFields);
            resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_PORTFOLIO_TAB_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating portfolio tabs order, please try again.';
          dispatch({
            type: types.UPDATE_PORTFOLIO_TAB_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};


export const loadDealOverviewFields = (
  workspace_id,
  shouldForceAPIRequest = false,
  ignoreCache = false
) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (!shouldForceAPIRequest) {
        // If column information is present in local storage, fetch it from there
        // `shouldForceAPIRequest` allows for overriding this behaviour
        const columnData = getDealOverviewFields(workspace_id);
        if (columnData && columnData.length > 0) {
          dispatch({
            type: types.DEAL_OVERVIEW_FIELDS_SUCCESS,
            payload: columnData,
          });
          // also contact server to get up to date data, which gets updated in the background
          executeInBackground(() =>
            loadDealOverviewFields(workspace_id, true)(dispatch).catch(() => {})
          );
          return resolve(columnData);
        }
      }
      dispatch({
        type: types.DEAL_OVERVIEW_FIELDS_REQUEST,
      });
      request
        .get(
          `/api/organizations/v6/deal_overview_fields`,
          { workspace_id, ignoreCache },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.DEAL_OVERVIEW_FIELDS_SUCCESS,
              payload: data.data,
            });
            setDealOverviewFields(data.data, workspace_id);
            resolve(data);
          } else {
            dispatch({
              type: types.DEAL_OVERVIEW_FIELDS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading deal overview fields, please try again.';
          dispatch({
            type: types.DEAL_OVERVIEW_FIELDS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};

export const updateDealOverviewFields = (orderedFields, workspace_id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.UPDATE_DEAL_OVERVIEW_FIELDS_REQUEST,
        payload: orderedFields,
      });
      request
        .put(
          `/api/organizations/v6/deal_overview_fields`,
          { fields: orderedFields, workspace_id },
          dispatch
        )
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_DEAL_OVERVIEW_FIELDS_SUCCESS,
              payload: orderedFields,
            });
            setDealOverviewFields(orderedFields, workspace_id);
            resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_DEAL_OVERVIEW_FIELDS_FAILURE,
              payload: data.message,
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating deal overview fields, please try again.';
          dispatch({
            type: types.UPDATE_DEAL_OVERVIEW_FIELDS_FAILURE,
            payload: message,
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
    });
  };
};


/**
 * List of share deals templates of the organization
 * @return {Promise}     Resolves with list of templates on success
 */
export const loadShareDealTemplates = (options) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_SHARE_DEAL_TEMPLATES_REQUEST,
        payload: {}
      });
      request.get('/api/share-deal-templates', options, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_SHARE_DEAL_TEMPLATES_SUCCESS,
            payload: {
              data: data.data,
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.LOAD_SHARE_DEAL_TEMPLATES_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while fetching list of templates, please try again.';
        dispatch({
          type: types.LOAD_SHARE_DEAL_TEMPLATES_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};


export const uploadTemplate =
    inputData => async dispatch => {
    const url = "/api/share-deal-templates";
    const file = inputData.file;
    try {
      const { data } = await request.post(url, inputData);
      if (data.success) {
        await request.put(data.data.url, file, {});
        return data;
      } else {
        dispatch(
          enqueueNotification(
            data.message ||
              'Something went wrong while uploading template, please try again.'
          )
        );
      }
    } catch (error) {
      const message =
        'Something went wrong while uploading template, please try again.';
      dispatch(enqueueNotification(message));
    }
};

export const editShareDealTemplateName =
    (template_Id,name) => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        dispatch({
          type: types.UPDATE_SHARE_DEAL_TEMPLATE_NAME_REQUEST,
          payload: {}
        });
        request.patch(`/api/share-deal-templates/${template_Id}`, {name}, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_SHARE_DEAL_TEMPLATE_NAME_SUCCESS,
              payload: {id:template_Id,newName:name}
            });
            resolve(data);
          } else {
            dispatch({
              type: types.UPDATE_SHARE_DEAL_TEMPLATE_NAME_FAILURE,
              payload: data.message
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch((err) => {
          const message = 'Something went wrong while updating the name of template, please try again.';
          dispatch({
            type: types.UPDATE_SHARE_DEAL_TEMPLATE_NAME_FAILURE,
            payload: message
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
      });
    };
};


export const deleteShareDealTemplate =
    (template_Id) => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        dispatch({
          type: types.DELETE_SHARE_DEAL_TEMPLATE_REQUEST,
          payload: {}
        });
        request.del(`/api/share-deal-templates/${template_Id}`, {}, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.DELETE_SHARE_DEAL_TEMPLATE_SUCCESS,
              payload: {id:template_Id}
            });
            resolve(data);
          } else {
            dispatch({
              type: types.DELETE_SHARE_DEAL_TEMPLATE_FAILURE,
              payload: data.message
            });
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch((err) => {
          const message = 'Something went wrong while deleting template, please try again.';
          dispatch({
            type: types.DELETE_SHARE_DEAL_TEMPLATE_FAILURE,
            payload: message
          });
          dispatch(enqueueNotification(message));
          reject(err);
        });
      });
    };
};

export const getShareDealtemplateUrl =
    (template_Id) => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        request.get(`/api/share-deal-templates/${template_Id}/signed-url`, {}, dispatch)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.data);
          } else {
            dispatch(enqueueNotification(data.message));
            reject(new Error(data.message));
          }
        })
        .catch((err) => {
          const message = 'Something went wrong while deleting template, please try again.';
          dispatch(enqueueNotification(message));
          reject(err);
        });
      });
    };
};

