export const INVITE_ORGANIZATION_REQUEST = 'system/INVITE_ORGANIZATION_REQUEST';
export const INVITE_ORGANIZATION_SUCCESS = 'system/INVITE_ORGANIZATION_SUCCESS';
export const INVITE_ORGANIZATION_FAILURE = 'system/INVITE_ORGANIZATION_FAILURE';

export const DEAL_IMPORT_GET_SIGNED_URL_REQUEST = 'system/DEAL_IMPORT_GET_SIGNED_URL_REQUEST';
export const DEAL_IMPORT_GET_SIGNED_URL_SUCCESS = 'system/DEAL_IMPORT_GET_SIGNED_URL_SUCCESS';
export const DEAL_IMPORT_GET_SIGNED_URL_FAILURE = 'system/DEAL_IMPORT_GET_SIGNED_URL_FAILURE';

export const DEAL_IMPORT_S3_UPLOAD_REQUEST = 'system/DEAL_IMPORT_S3_UPLOAD_REQUEST';
export const DEAL_IMPORT_S3_UPLOAD_SUCCESS = 'system/DEAL_IMPORT_S3_UPLOAD_SUCCESS';
export const DEAL_IMPORT_S3_UPLOAD_FAILURE = 'system/DEAL_IMPORT_S3_UPLOAD_FAILURE';

export const DEAL_IMPORT_ENQUEUE_JOB_REQUEST = 'system/DEAL_IMPORT_ENQUEUE_JOB_REQUEST';
export const DEAL_IMPORT_ENQUEUE_JOB_SUCCESS = 'system/DEAL_IMPORT_ENQUEUE_JOB_SUCCESS';
export const DEAL_IMPORT_ENQUEUE_JOB_FAILURE = 'system/DEAL_IMPORT_ENQUEUE_JOB_FAILURE';

export const LIST_DEAL_IMPORT_REQUEST = 'system/LIST_DEAL_IMPORT_REQUEST';
export const LIST_DEAL_IMPORT_SUCCESS = 'system/LIST_DEAL_IMPORT_SUCCESS';
export const LIST_DEAL_IMPORT_FAILURE = 'system/LIST_DEAL_IMPORT_FAILURE';

export const LIST_DEAL_IMPORT_CLEAR = 'system/LIST_DEAL_IMPORT_CLEAR';

export const DOWNLOAD_CSV_SAMPLE_REQUEST = 'system/DOWNLOAD_CSV_SAMPLE_REQUEST';
export const DOWNLOAD_CSV_SAMPLE_SUCCESS = 'system/DOWNLOAD_CSV_SAMPLE_SUCCESS';
export const DOWNLOAD_CSV_SAMPLE_FAILURE = 'system/DOWNLOAD_CSV_SAMPLE_FAILURE';


export const LOAD_TAGHASH_FAQ_REQUEST = 'system/LOAD_TAGHASH_FAQ_REQUEST';
export const LOAD_TAGHASH_FAQ_SUCCESS = 'system/LOAD_TAGHASH_FAQ_SUCCESS';
export const LOAD_TAGHASH_FAQ_FAILURE = 'system/LOAD_TAGHASH_FAQ_FAILURE';


