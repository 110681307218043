/** @format */
import * as types from './actions';
import { assign } from '../../helpers/object';
import reduce from 'lodash.reduce';
import property from 'lodash.property';
import uniqBy from 'lodash.uniqby';

const initialState = {
  /**
   * Holds state related to individual document loading, keys are the document ids and
   * the values are boolean indicating if it's loading or not.
   */
  fundDocumentLoading: {},
  /**
   * Holds state related to individual document error, keys are the document ids and
   * the values are string error messages
   */
  fundDocumentError: {},

  /**
   * Stores fund documents, keyed by id
   */
  fundDocuments: {},

  createFundDocumentLoading: false,
  fundDocumentsListLoading: false,
  fundDocumentsCount: {},
  tagsListLoading: false,

  tags: [
    // list of tags defined in context of fund documents
  ],

  getFundDocumentOptions: {
    q: '',
    limited_partner_id: '',
    tags: [],
    sortBy: '',
    sortOrder: '',
    limit: '',
    page: '',
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CREATE_FUND_DOCUMENT_REQUEST: {
      return assign(state, {
        createFundDocumentLoading: true,
      });
    }
    case types.CREATE_FUND_DOCUMENT_SUCCESS: {
      return assign(state, {
        createFundDocumentLoading: false,
        fundDocuments: assign(
          state.fundDocuments,
          reduce(
            payload.data,
            (acc, { document }) => {
              // no need to `assign` here, since it's a fresh doc
              // added a empty tags array for newly created fund document to add tags without reloading the page
              acc[document.id] = { ...document, tags: [] };
              return acc;
            },
            {}
          )
        ),
        fundDocumentsCount: {
          ...state.fundDocumentsCount,
          [payload.fundId]: {
            ...state.fundDocumentsCount[payload.fundId],
            all:
              state.fundDocumentsCount[payload.fundId] &&
              state.fundDocumentsCount[payload.fundId].all
                ? state.fundDocumentsCount[payload.fundId].all +
                  payload.data.length
                : 1,
          },
        },
      });
    }
    case types.CREATE_FUND_DOCUMENT_FAILURE: {
      return assign(state, {
        createFundDocumentLoading: false,
      });
    }
    //
    case types.LIST_FUND_DOCUMENTS_REQUEST: {
      return assign(state, {
        fundDocumentsListLoading: true,
      });
    }
    case types.LIST_FUND_DOCUMENTS_SUCCESS: {
      return assign(state, {
        fundDocumentsListLoading: false,
        fundDocuments: assign(
          state.fundDocuments,
          reduce(
            payload.data,
            (acc, item) => {
              // merge with existing state, if it exists
              acc[item.id] = assign(
                property(`fundDocuments.${item.id}`)(state) || {},
                item
              );
              return acc;
            },
            {}
          )
        ),
        fundDocumentsCount: {
          ...state.fundDocumentsCount,
          [payload.fundId]: {
            all: payload.metadata.documentsCount || 0,
            hidden: payload.metadata.hiddenDocumentsCount || 0,
          },
        },
      });
    }
    case types.LIST_FUND_DOCUMENTS_FAILURE: {
      return assign(state, {
        fundDocumentsListLoading: false,
      });
    }

    case types.GET_FUND_DOCUMENT_SIGNEDURL_REQUEST:
    case types.ADD_TAG_TO_FUND_DOCUMENT_REQUEST:
    case types.REMOVE_TAG_FROM_FUND_DOCUMENT_REQUEST:
    case types.DELETE_FUND_DOCUMENT_REQUEST:
    case types.HIDE_OR_UNHIDE_FUND_DOCUMENT_REQUEST:
    case types.FILE_UPLOAD_S3_REQUEST:
    case types.FILE_DOWNLOAD_S3_REQUEST:
      return Object.assign({}, state, {
        fundDocumentLoading: {
          ...state.fundDocumentLoading,
          [payload.documentId]: true,
        },
        fundDocumentError: {
          ...state.fundDocumentError,
          [payload.documentId]: null,
        },
      });
    case types.GET_FUND_DOCUMENT_SIGNEDURL_SUCCESS:
    case types.DELETE_FUND_DOCUMENT_SUCCESS:
    case types.HIDE_OR_UNHIDE_FUND_DOCUMENT_SUCCESS:
    case types.FILE_UPLOAD_S3_SUCCESS:
    case types.FILE_DOWNLOAD_S3_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        fundDocumentLoading: {
          ...state.fundDocumentLoading,
        },
        fundDocumentError: {
          ...state.fundDocumentError,
        },
      });
      // clear the target items
      delete newState.fundDocumentLoading[payload.documentId];
      delete newState.fundDocumentError[payload.documentId];
      return newState;
    }
    case types.GET_FUND_DOCUMENT_SIGNEDURL_FAILURE:
    case types.ADD_TAG_TO_FUND_DOCUMENT_FAILURE:
    case types.REMOVE_TAG_FROM_FUND_DOCUMENT_FAILURE:
    case types.DELETE_FUND_DOCUMENT_FAILURE:
    case types.HIDE_OR_UNHIDE_FUND_DOCUMENT_FAILURE:
    case types.FILE_UPLOAD_S3_FAILURE:
    case types.FILE_DOWNLOAD_S3_FAILURE:
      return Object.assign({}, state, {
        fundDocumentLoading: {
          ...state.fundDocumentLoading,
          [payload.documentId]: false,
        },
        fundDocumentError: {
          ...state.fundDocumentError,
          [payload.documentId]: payload.message,
        },
      });

    // list tags
    case types.LIST_FUND_DOCUMENT_TAGS_REQUEST:
      return Object.assign({}, state, {
        tagsListLoading: true,
      });
    case types.LIST_FUND_DOCUMENT_TAGS_SUCCESS:
      return Object.assign({}, state, {
        tagsListLoading: false,
        tags: payload.data,
      });
    case types.LIST_FUND_DOCUMENT_TAGS_FAILURE:
      return Object.assign({}, state, {
        tagsListLoading: false,
      });

    // add tag
    case types.ADD_TAG_TO_FUND_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        fundDocumentLoading: {
          ...state.fundDocumentLoading,
          [payload.documentId]: false,
        },
        fundDocuments: {
          ...state.fundDocuments,
          [payload.documentId]: assign(
            state.fundDocuments[payload.documentId],
            {
              tags: uniqBy(
                [
                  ...(property(`fundDocuments.${payload.documentId}.tags`)(
                    state
                  ) || []),
                  { name: payload.tag },
                ],
                t => t.name
              ),
            }
          ),
        },
      });

    // remove tag
    case types.REMOVE_TAG_FROM_FUND_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        fundDocumentLoading: {
          ...state.fundDocumentLoading,
          [payload.documentId]: false,
        },
        fundDocuments: {
          ...state.fundDocuments,
          [payload.documentId]: assign(
            state.fundDocuments[payload.documentId],
            {
              tags: (
                property(`fundDocuments.${payload.documentId}.tags`)(state) ||
                []
              ).filter(tag => tag.name !== payload.tag),
            }
          ),
        },
      });

    case types.CLEAR: {
      return assign(state, initialState);
    }

    default:
      return state;
  }
};
