/**
 * @format
 */
import { SET_DEAL_FILTERS } from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case SET_DEAL_FILTERS:
      return Object.assign({}, state, {
        filters: { ...state.filters, ...payload },
      });

    default:
      return state;
  }
};
