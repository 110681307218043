/**
 * Get personContact's recent interactions reducer handler
 * @format
 */
import {
  GET_RECENT_INTERACTIONS_REQUEST,
  GET_RECENT_INTERACTIONS_SUCCESS,
  GET_RECENT_INTERACTIONS_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case GET_RECENT_INTERACTIONS_REQUEST:
      return Object.assign({}, state, {
        recentInteractionsLoading: {
          ...state.recentInteractionsLoading,
          [payload.personContactId]: true,
        },
        recentInteractionsError: {
          ...state.recentInteractionsError,
          [payload.personContactId]: null,
        },
      });

    case GET_RECENT_INTERACTIONS_SUCCESS:
      return Object.assign({}, state, {
        recentInteractions: {
          ...state.recentInteractions,
          [payload.personContactId]: state.recentInteractions[
            payload.personContactId
          ]
            ? payload.options.page === 1 // recent when in first page
              ? payload.data
              : [
                  ...state.recentInteractions[payload.personContactId],
                  ...payload.data, // if not in first page, append
                ]
            : payload.data,
        },
        recentInteractionsLoading: {
          ...state.recentInteractionsLoading,
          [payload.personContactId]: false,
        },
        recentInteractionsError: {
          ...state.recentInteractionsError,
          [payload.personContactId]: null,
        },
      });

    case GET_RECENT_INTERACTIONS_FAILURE:
      return Object.assign({}, state, {
        recentInteractionsLoading: {
          ...state.recentInteractionsLoading,
          [payload.personContactId]: false,
        },
        recentInteractionsError: {
          ...state.recentInteractionsError,
          [payload.personContactId]: payload.error,
        },
      });

    default:
      return state;
  }
};
