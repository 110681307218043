import {
  LOAD_DEAL_SUGGESTIONS_REQUEST,
  LOAD_DEAL_SUGGESTIONS_SUCCESS,
  LOAD_DEAL_SUGGESTIONS_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_DEAL_SUGGESTIONS_REQUEST:
      return Object.assign({}, state, {
        suggestionsLoading: true,
        suggestionsError: null
      });
    case LOAD_DEAL_SUGGESTIONS_SUCCESS:
      let suggestionMap = {};
      payload.forEach((eachSuggestion) => {
        suggestionMap[eachSuggestion.startup.name] = eachSuggestion;
      });
      return Object.assign({}, state, {
        suggestions: {
          ...state.suggestions,
          ...suggestionMap
        },
        suggestionsLoading: false,
        suggestionsError: null,
      });
    case LOAD_DEAL_SUGGESTIONS_FAILURE:
      return Object.assign({}, state, {
        suggestionsLoading: false,
        suggestionsError: payload,
      });
    default:
      return state;
  }
};
