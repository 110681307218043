/**
 * @format
 */

const FUND_COMPANIES_TABLE_COLUMNS = {
  COMPANY: {
    //unique deal
    key: 'company_name',
    // the columns with `sticky` property set to true are considered fixed,
    // which is mostly first column of the table - deal name
    sticky: true,
    // Name of the column to be displayed
    name: 'Company',
  },
  FUNDS: {
    key: 'funds',
    sticky: true,
    name: 'Fund',
  },
  AS_ON: {
    key: 'as_on',
    name: 'As On of Valuation Date',
  },
  MANAGERS: {
    key: 'managers',
    name: 'Managers',
  },

  FOUNDER_EMAIL: {
    key: 'founder_email',
    name: `Founder's Email`,
  },

  FMV_SHARE: {
    key: 'fmv_share',
    name: 'FMV / Share',
  },
  FOREX_RATE: {
    key: 'forex_rate',
    name: 'Forex Rate',
  },
  FIRST_INVESTMENT_DATE: {
    key: 'first_investment_date',
    name: 'First Investment Date',
  },
  HOLDINGS: {
    key: 'holdings',
    name: 'Holdings',
    EQUITY_SHARE: {
      key: 'equity_share',
      name: 'Equity Share',
    },
    CCPS_DILUTED: {
      key: 'ccps_diluted',
      name: 'CCPS Diluted',
    },
    CCD_DILUTED: {
      key: 'ccd_diluted',
      name: 'CCD Diluted',
    },
    OTHERS_DILUTED: {
      key: 'others_diluted',
      name: 'Others Diluted',
    },
    SHARES_HELD: {
      key: 'shares_held',
      name: 'Shares Held',
    },
    TOTAL_OUTSTANDING: {
      key: 'total_outstanding',
      name: 'Total Outstanding',
    },
    PERCENTAGE_HOLDING: {
      key: 'percentage_holding',
      name: '% Holding',
    },
  },
  LATEST_FUNDING_ROUND: {
    key: 'latest_funding_round',
    name: 'Latest Funding Round',
    DATE: {
      key: 'date',
      name: 'Date',
    },
    NAME: {
      key: 'name',
      name: 'Name',
    },
    PRE_MONEY: {
      key: 'pre_money',
      name: 'Pre Money',
    },
    POST_MONEY: {
      key: 'post_money',
      name: 'Post Money',
    },
  },
  TOTAL_COMMITTED: {
    key: 'total_committed',
    name: 'Total Committed',
    LOCAL: {
      key: 'local',
      name: 'Local',
    },
    FUND: {
      key: 'fund',
      name: 'Fund',
    },
  },
  TOTAL_INVESTED: {
    key: 'total_invested',
    name: 'Total Invested',
    LOCAL: {
      key: 'local',
      name: 'Local',
    },
    FUND: {
      key: 'fund',
      name: 'Fund',
    },
  },
  NET_COST: {
    key: 'net_cost',
    name: 'Net Cost',
    LOCAL: {
      key: 'local',
      name: 'Local',
    },
    FUND: {
      key: 'fund',
      name: 'Fund',
    },
  },
  NET_REALISED_GAIN: {
    key: 'net_realised_gain',
    name: 'Net Realised Gain',
    LOCAL: {
      key: 'local',
      name: 'Local',
    },
    FUND: {
      key: 'fund',
      name: 'Fund',
    },
  },
  FMV_UNREALIZED: {
    key: 'fmv_commited',
    name: 'FMV (Unrealized)',
    LOCAL: {
      key: 'local',
      name: 'Local',
    },
    FUND: {
      key: 'fund',
      name: 'Fund',
    },
  },
  REALISED_AMOUNT: {
    key: 'realised_amount',
    name: 'Realised Amount',
    LOCAL: {
      key: 'local',
      name: 'Local',
    },
    FUND: {
      key: 'fund',
      name: 'Fund',
    },
  },
  XIRR: {
    key: 'xirr',
    name: 'XIRR',
    LOCAL: {
      key: 'local',
      name: 'Local',
    },
    FUND: {
      key: 'fund',
      name: 'Fund',
    },
  },
  MOIC: {
    key: 'moic',
    name: 'MOIC',
    LOCAL: {
      key: 'local',
      name: 'Local',
    },
    FUND: {
      key: 'fund',
      name: 'Fund',
    },
  },
};

module.exports = FUND_COMPANIES_TABLE_COLUMNS;
