/**
 * Ensures protected routes are viewable for logged in users only
 * @author  Ritesh Shrivastav
 *
 * Source:
 * https://medium.com/the-many/adding-login-and-authentication-sections-to-your-react-or-react-native-app-7767fd251bd1
 */
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { encodeNext } from '../../helpers/string/nextPath';
import { loadUser } from '../../modules/auth';
import { withRouter } from "react-router-dom";

class EnsureSignedIn extends React.Component {
  static propTypes = {
    loadUser: PropTypes.func.isRequired,
    loadUserLoading: PropTypes.bool.isRequired,
    loadUserError: PropTypes.string,
    user: PropTypes.object,
  };

  componentDidMount() {
    // Removing this since `EnsureSignedIn` is always used as a child of `App`
    // and `App` is calling `loadUser` within its `componentDidMount` as well.
    //
    // NOTE: Keeping it here because this needs to be uncommented if or when
    // `EnsureSignedIn` is used outside `App`, since `loadUser` needs to be called
    // by _someone_ somewhere for `user` to be loaded without which none of
    // `EnsureSignedIn`'s children will be rendered
    //
    // NOTE: Uncommenting this will cause `/api/v1/auth` to be called twice
    // this.props.loadUser()
    //   // Ignore success and error here. `componentDidUpdate` will take care of
    //   // error handling
    //   .catch(() => {});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadUserLoading !== this.props.loadUserLoading &&
      this.props.loadUserError) {
      const base64EncodedNext = encodeNext(this.props.currentURL);
      this.props.history.push('/login?next=' + base64EncodedNext);
    }
  }

  render() {
    return (this.props.user ? this.props.children : null);
  }
}

// Grab a reference to the current URL. If this is a web app and you are
// using React Router, you can use `ownProps` to find the URL. Other
// platforms (Native) or routing libraries have similar ways to find
// the current position in the app.
const mapStateToProps = (state, ownProps) => {
  let currentURL = ownProps.location.pathname;
  if (ownProps.location.search) {
    currentURL += ownProps.location.search;
  }
  return {
    currentURL,
    user: state.auth.user
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadUser,
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EnsureSignedIn));
