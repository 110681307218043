/** @format */
import * as types from './actions';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

// mark reducers as the default export
export default reducers;

// LoadMagicSyncBlocklistForUser loads all the suggestions of deals
export const LoadMagicSyncBlocklistForUser = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_MAGIC_SYNC_BLOCKLIST_REQUEST,
        payload: {},
      });
      request
        .get(`/api/magicsync/v1/blocklist/self`, {}, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.LOAD_MAGIC_SYNC_BLOCKLIST_SUCCESS,
              payload: data.data,
            });
            return resolve(data);
          } else {
            dispatch({
              type: types.LOAD_MAGIC_SYNC_BLOCKLIST_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while loading Magic sync blocklist, please try again.';
          dispatch({
            type: types.LOAD_MAGIC_SYNC_BLOCKLIST_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};

export const updateMagicSyncBlocklist = blockList => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const blockListArray = Array.isArray(blockList) ? blockList : [blockList];
      dispatch({
        type: types.UPDATE_MAGIC_SYNC_BLOCKLIST_REQUEST,
        payload: blockListArray,
      });
      request
        .put(`/api/magicsync/v1/blocklist/self`, blockListArray, dispatch)
        .then(({ data }) => {
          if (data.success) {
            dispatch({
              type: types.UPDATE_MAGIC_SYNC_BLOCKLIST_SUCCESS,
              payload: data.data,
            });
            return resolve(data.success);
          } else {
            dispatch({
              type: types.UPDATE_MAGIC_SYNC_BLOCKLIST_FAILURE,
              payload: {
                message: data.message,
              },
            });
            dispatch(enqueueNotification(data.message));
            return reject(new Error(data.message));
          }
        })
        .catch(err => {
          const message =
            'Something went wrong while updating blocklist, please try again.';
          dispatch({
            type: types.UPDATE_MAGIC_SYNC_BLOCKLIST_FAILURE,
            payload: {
              message,
            },
          });
          dispatch(enqueueNotification(message));
          return reject(new Error(message));
        });
    });
  };
};
