/**
 * Hint component, allows you to control tooltip
 * 
 * @author Ritesh Shrivastav
 */
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

class Hint extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired
  };

  static defaultProps = {
    disabled: false
  };

  render() {
    const { disabled, children, ...others } = this.props;
    
    return disabled ? children : (
      <Tooltip
        children={children}
        {...others}
      />
    );
  }
}

export default Hint;
