/**
 * safeParseInt() parses the passed number, if number is greater than
 * Number.MAX_SAFE_INTEGER then it throws error.
 */
export default (number) => {
  const parsedNum = parseInt(number, 10);
  if (isNaN(parsedNum)) {
    return parsedNum;
  }
  if (!Number.isSafeInteger(parsedNum)) {
    throw new Error('Not expecting number to cross Number.MAX_SAFE_INTEGER limit.');
  }
  return parsedNum;
};
