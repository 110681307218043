/**
 * Holds all the portfolio tabs
 * @format
 */

const PORTFOLIO_TABS = {
  SUMMARY: {
    key: 'summary',
    name: 'Summary',
  },
  COMPANY_PROFILE: {
    key: 'company-profile',
    name: 'Company Profile',
  },
  TIMELINE: {
    key: 'timeline',
    name: 'Timeline',
  },
  DOCUMENTS: {
    key: 'documents',
    name: 'Document',
  },
  MIS_SHEETS: {
    key: 'sheets',
    name: 'MIS Sheets',
  },
  VISUALIZATIONS: {
    key: 'charts',
    name: 'Visualizations',
  },
  INTELLIGENCE: {
    key: 'intelligence',
    name: 'Intelligence',
  },
  INVESTMENTS: {
    key: 'investments',
    name: 'Investments',
  },
  MIS_REPORTS: {
    key: 'reports',
    name: 'MIS Reports',
  },
  TEAR_SHEETS: {
    key: 'consolidated-reports',
    name: 'Tear Sheets',
  },
  SETTINGS: {
    key: 'settings',
    name: 'Settings',
  },
};

module.exports = PORTFOLIO_TABS;
