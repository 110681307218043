/**
 * LIST_SIGN_OFF_ACTIVITY_LOGS reducer
 * @format
 */
import {
  LIST_SIGN_OFF_ACTIVITY_LOGS_REQUEST,
  LIST_SIGN_OFF_ACTIVITY_LOGS_SUCCESS,
  LIST_SIGN_OFF_ACTIVITY_LOGS_FAILURE,
} from '../actions';

export default (state, type, payload) => {
  switch (type) {
    case LIST_SIGN_OFF_ACTIVITY_LOGS_REQUEST:
      return Object.assign({}, state, {
        listSignOffActivityLogsLoading: {
          ...state.listSignOffActivityLogsLoading,
          [payload.signOffId]: true,
        },
        listSignOffActivityLogsError: {
          ...state.listSignOffActivityLogsError,
          [payload.signOffId]: null,
        },
      });

    case LIST_SIGN_OFF_ACTIVITY_LOGS_SUCCESS:
      return Object.assign({}, state, {
        listSignOffActivityLogsLoading: {
          ...state.listSignOffActivityLogsLoading,
          [payload.signOffId]: false,
        },
        listSignOffActivityLogsError: {
          ...state.listSignOffActivityLogsError,
          [payload.signOffId]: null,
        },
        // Populate sign off activity log hashmap with returned data
        signOffActivityLogs: {
          ...state.signOffActivityLogs,
          [payload.signOffId]: payload.data || [],
        },
      });
    case LIST_SIGN_OFF_ACTIVITY_LOGS_FAILURE:
      return Object.assign({}, state, {
        listSignOffActivityLogsLoading: {
          ...state.listSignOffActivityLogsLoading,
          [payload.signOffId]: false,
        },
        listSignOffActivityLogsError: {
          ...state.listSignOffActivityLogsError,
          [payload.signOffId]: payload.message,
        },
      });

    default:
      return state;
  }
};
