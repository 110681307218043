/**
 * @format
 */
import * as types from './actions';

const initialState = {
  dealSignals: {},
  // key is deal id, value is loading state for that deal's deal signals
  dealSignalsListLoading: {},
  // key is deal id, value is error state for that deal's deal signals
  dealSignalsListError: {},
  // key is deal-signal id, value is loading state for that deal's specific signal
  dealSignalLoading: {},
  // key is deal-signal id, value is error state for that deal's specific signal
  dealSignalError: {},
  //
  // base signals object, key is signal id (different from deal signal)
  // value is an object corresponding to api response
  signals: {},
  getOrganizationSignalsLoading: false,
  getOrganizationSignalsError: null,
  //
  // Query params applied to `getOrganizationSignals` api
  getOrganizationSignalsOptions: {
    // relations
    withDealSignals: true,
    withSuperSignals: true,
    withMovements: true,
    // extra relations
    withArticle: true,
    withCompany: true,
    // filters
    onlyDealSignals: false,
    onlySuperSignals: false,
    onlyMovements: false,
    // page, limit
    page: 1,
    limit: 10,
  },
  //
  // Key is super signal id
  createDealFromSuperSignalLoading: {},
  //
  // Key is super signal id
  createDealFromSuperSignalError: {},
  //
  // Key is movement id
  createDealFromMovementLoading: {},
  //
  // Key is movement id
  createDealFromMovementError: {},
  //
  // signalsStats object
  // Will have the following props:
  // - deals_signals_count
  // - super_signal_count
  // - super_signal_converted_direct_count
  // - super_signal_converted_indirect_count
  // - super_signal_incorrect_count
  // - super_signal_ignored_count
  // - super_signal_portfolio_count
  // - movement_count
  // - movement_converted_direct_count
  // - movement_converted_indirect_count
  // - movement_incorrect_count
  // - movement_ignored_count
  // - movement_deal_count
  // - movement_portfolio_count
  signalsStats: {},
  signalsStatsLoading: false,
  signalsStatsError: null,
  //
  // used when loading different types of signals associated with deals
  // key is deal id, value is list of signals loaded for the deal
  signalsForDeals: {},
  // key is deal id
  signalsForDealsLoading: {},
  // key is deal id
  signalsForDealsError: {},
  // key is deal id, value is options for each deal, like limit and page
  signalsForDealsOptions: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * Signals list reducers
     */
    case types.LOAD_DEAL_SIGNALS_LIST_REQUEST:
      return Object.assign({}, state, {
        dealSignalsListLoading: {
          ...state.dealSignalsListLoading,
          [payload.dealId]: true,
        },
        dealSignalsListError: {
          ...state.dealSignalsListError,
          [payload.dealId]: null,
        },
      });
    case types.LOAD_DEAL_SIGNALS_LIST_SUCCESS:
      return Object.assign({}, state, {
        dealSignals: {
          ...state.dealSignals,
          // {id: {id, deal_id, signal_id, reportHTML}...}
          ...(payload.data && payload.data.length > 0
            ? payload.data.reduce((result, signal) => {
                result[signal.id] = signal;
                return result;
              }, {})
            : {}),
        },
        dealSignalsListLoading: {
          ...state.dealSignalsListLoading,
          [payload.dealId]: false,
        },
        dealSignalsListError: {
          ...state.dealSignalsListError,
          [payload.dealId]: null,
        },
      });
    case types.LOAD_DEAL_SIGNALS_LIST_FAILURE:
      return Object.assign({}, state, {
        dealSignalsListLoading: {
          ...state.dealSignalsListLoading,
          [payload.dealId]: false,
        },
        dealSignalsListError: {
          ...state.dealSignalsListError,
          [payload.dealId]: payload.message,
        },
      });
    /**
     * Deal signal load (specific)
     */
    case types.LOAD_DEAL_SIGNAL_REQUEST:
      return Object.assign({}, state, {
        dealSignalLoading: {
          ...state.dealSignalLoading,
          [payload.signalId]: true,
        },
        dealSignalError: {
          ...state.dealSignalError,
          [payload.signalId]: null,
        },
      });
    case types.LOAD_DEAL_SIGNAL_SUCCESS:
      return Object.assign({}, state, {
        dealSignals: {
          ...state.dealSignals,
          // {id, deal_id, signal_id, reportHTML}
          [payload.signalId]: payload.data, // signal data
        },
        dealSignalLoading: {
          ...state.dealSignalLoading,
          [payload.signalId]: false,
        },
        dealSignalError: {
          ...state.dealSignalError,
          [payload.signalId]: null,
        },
      });
    case types.LOAD_DEAL_SIGNAL_FAILURE:
      return Object.assign({}, state, {
        dealSignalLoading: {
          ...state.dealSignalLoading,
          [payload.signalId]: false,
        },
        dealSignalError: {
          ...state.dealSignalError,
          [payload.signalId]: payload.message,
        },
      });

    /**
     * LOAD_ORGANIZATION_SIGNALS reducers
     *
     * signals
     * getOrganizationSignalsLoading
     * getOrganizationSignalsError
     * getOrganizationSignalsOptions
     */
    case types.LOAD_ORGANIZATION_SIGNALS_REQUEST:
      return Object.assign({}, state, {
        getOrganizationSignalsLoading: true,
        getOrganizationSignalsError: null,
        getOrganizationSignalsOptions: {
          ...state.getOrganizationSignalsOptions,
          ...payload.options,
        },
      });
    case types.LOAD_ORGANIZATION_SIGNALS_SUCCESS: {
      const signalsOb =
        payload.data && payload.data.length > 0
          ? payload.data.reduce((acc, item) => {
              acc[item.id] = item;
              return acc;
            }, {})
          : {};
      return Object.assign({}, state, {
        getOrganizationSignalsLoading: false,
        getOrganizationSignalsError: null,
        signals:
          payload.options.page === 1
            ? signalsOb
            : { ...state.signals, ...signalsOb },
      });
    }
    case types.LOAD_ORGANIZATION_SIGNALS_FAILURE:
      return Object.assign({}, state, {
        getOrganizationSignalsLoading: false,
        getOrganizationSignalsError: payload.message,
      });

    /**
     * LOAD_SIGNALS_STATS reducers
     *
     * signalsStats
     * signalsStatsLoading
     * signalsStatsError
     */
    case types.LOAD_SIGNALS_STATS_REQUEST:
      return Object.assign({}, state, {
        signalsStatsLoading: true,
        signalsStatsError: null,
      });
    case types.LOAD_SIGNALS_STATS_SUCCESS:
      return Object.assign({}, state, {
        signalsStatsLoading: false,
        signalsStatsError: null,
        signalsStats: payload.data,
      });
    case types.LOAD_SIGNALS_STATS_FAILURE:
      return Object.assign({}, state, {
        signalsStatsLoading: false,
        signalsStatsError: payload.message,
      });

    /**
     * CREATE_DEAL_FROM_SUPER_SIGNAL reducers
     *
     * createDealFromSuperSignalLoading
     * createDealFromSuperSignalError
     */
    case types.CREATE_DEAL_FROM_SUPER_SIGNAL_REQUEST:
      return Object.assign({}, state, {
        createDealFromSuperSignalLoading: {
          ...state.createDealFromSuperSignalLoading,
          [payload.superSignalId]: true,
        },
        createDealFromSuperSignalError: {
          ...state.createDealFromSuperSignalError,
          [payload.superSignalId]: null,
        },
      });
    case types.CREATE_DEAL_FROM_SUPER_SIGNAL_SUCCESS: {
      const createDealFromSuperSignalLoading = {
        ...state.createDealFromSuperSignalLoading,
      };
      const createDealFromSuperSignalError = {
        ...state.createDealFromSuperSignalError,
      };
      // Deleting to conserve memory, since we don't need it anymore
      delete createDealFromSuperSignalLoading[payload.superSignalId];
      delete createDealFromSuperSignalError[payload.superSignalId];
      // Please refer to API documentation in nucleus to know the structure
      const { signalId, superSignalId } = payload;
      const { deal, superSignal } = payload.data;
      const signal = state.signals[signalId];
      let superSignalIndex;
      for (let i = signal.superSignals.length - 1; i >= 0; i--) {
        if (signal.superSignals[i].id === superSignalId) {
          superSignalIndex = i;
          break;
        }
      }
      return Object.assign({}, state, {
        createDealFromSuperSignalLoading,
        createDealFromSuperSignalError,
        signals: {
          ...state.signals,
          [signalId]: {
            ...signal,
            superSignals: [
              ...signal.superSignals.slice(0, superSignalIndex),
              {
                ...superSignal,
                deal: deal, // has related startup, categories, stage, assignees
              },
              ...signal.superSignals.slice(superSignalIndex + 1),
            ],
          },
        },
      });
    }
    case types.CREATE_DEAL_FROM_SUPER_SIGNAL_FAILURE:
      return Object.assign({}, state, {
        createDealFromSuperSignalLoading: {
          ...state.createDealFromSuperSignalLoading,
          [payload.superSignalId]: false,
        },
        createDealFromSuperSignalError: {
          ...state.createDealFromSuperSignalError,
          [payload.superSignalId]: payload.message,
        },
      });

    /**
     * CREATE_DEAL_FROM_MOVEMENT reducers
     *
     * createDealFromMovementLoading
     * createDealFromMovementError
     */
    case types.CREATE_DEAL_FROM_MOVEMENT_REQUEST:
      return Object.assign({}, state, {
        createDealFromMovementLoading: {
          ...state.createDealFromMovementLoading,
          [payload.movementId]: true,
        },
        createDealFromMovementError: {
          ...state.createDealFromMovementError,
          [payload.movementId]: null,
        },
      });
    case types.CREATE_DEAL_FROM_MOVEMENT_SUCCESS: {
      const createDealFromMovementLoading = {
        ...state.createDealFromMovementLoading,
      };
      const createDealFromMovementError = {
        ...state.createDealFromMovementError,
      };
      // Deleting to conserve memory, since we don't need it anymore
      delete createDealFromMovementLoading[payload.movementId];
      delete createDealFromMovementError[payload.movementId];
      // Please refer to API documentation in nucleus to know the structure
      const { signalId, movementId } = payload;
      const { deal, movementSignal } = payload.data;
      const signal = state.signals[signalId];
      let movementSignalIndex;
      for (let i = signal.movementSignals.length - 1; i >= 0; i--) {
        if (signal.movementSignals[i].id === movementId) {
          movementSignalIndex = i;
          break;
        }
      }
      return Object.assign({}, state, {
        createDealFromMovementLoading,
        createDealFromMovementError,
        signals: {
          ...state.signals,
          [signalId]: {
            ...signal,
            movementSignals: [
              ...signal.movementSignals.slice(0, movementSignalIndex),
              {
                ...movementSignal,
                deal: deal, // has related startup, categories, stage, assignees
              },
              ...signal.movementSignals.slice(movementSignalIndex + 1),
            ],
          },
        },
      });
    }
    case types.CREATE_DEAL_FROM_MOVEMENT_FAILURE:
      return Object.assign({}, state, {
        createDealFromMovementLoading: {
          ...state.createDealFromMovementLoading,
          [payload.movementId]: false,
        },
        createDealFromMovementError: {
          ...state.createDealFromMovementError,
          [payload.movementId]: payload.message,
        },
      });

    /**
     * LOAD_SIGNALS_FOR_DEAL reducers
     *
     * signalsForDeals
     * signalsForDealsLoading
     * signalsForDealsError
     * signalsForDealsOptions
     */
    case types.LOAD_SIGNALS_FOR_DEAL_REQUEST:
      return Object.assign({}, state, {
        signalsForDealsLoading: {
          ...state.signalsForDealsLoading,
          [payload.dealId]: true,
        },
        signalsForDealsError: {
          ...state.signalsForDealsError,
          [payload.dealId]: null,
        },
        signalsForDealsOptions: {
          ...state.signalsForDealsOptions,
          [payload.dealId]: {
            ...state.signalsForDealsOptions[payload.dealId],
            ...payload.options,
          },
        },
      });
    case types.LOAD_SIGNALS_FOR_DEAL_SUCCESS: {
      const signalsOb =
        payload.data && payload.data.length > 0
          ? payload.data.reduce((acc, item) => {
              acc[item.id] = item;
              return acc;
            }, {})
          : {};
      return Object.assign({}, state, {
        signalsForDealsLoading: {
          ...state.signalsForDealsLoading,
          [payload.dealId]: false,
        },
        signalsForDealsError: {
          ...state.signalsForDealsError,
          [payload.dealId]: null,
        },
        signalsForDeals: {
          ...state.signalsForDeals,
          //
          // For a given deal id, signals are stored in array as they are
          // loaded from the api
          [payload.dealId]:
            payload.options.page === 1
              ? payload.data
              : state.signalsForDeals[payload.dealId]
              ? [...state.signalsForDeals[payload.dealId], ...payload.data]
              : payload.data,
        },
        // Also add the signals information into the single source of truth
        signals: { ...state.signals, ...signalsOb },
      });
    }
    case types.LOAD_SIGNALS_FOR_DEAL_FAILURE:
      return Object.assign({}, state, {
        signalsForDealsLoading: {
          ...state.signalsForDealsLoading,
          [payload.dealId]: false,
        },
        signalsForDealsError: {
          ...state.signalsForDealsError,
          [payload.dealId]: payload.message,
        },
      });

    default:
      return state;
  }
};
