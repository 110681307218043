import {
  REMOVE_INTERNAL_REFERRER_REQUEST,
  REMOVE_INTERNAL_REFERRER_SUCCESS,
  REMOVE_INTERNAL_REFERRER_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case REMOVE_INTERNAL_REFERRER_REQUEST:
      return Object.assign({}, state, {
        removeInternalReferrerLoading: {
          ...state.removeInternalReferrerLoading,
          [payload.dealId]: true
        },
        removeInternalReferrerError: {
          ...state.removeInternalReferrerError,
          [payload.dealId]: null
        },
      });
    
    case REMOVE_INTERNAL_REFERRER_SUCCESS:
      return Object.assign({}, state, {
        deals: {
          ...state.deals,
          [payload.dealId]: (state.deals[payload.dealId] ? {
            ...state.deals[payload.dealId],
            internalReferrers: [...payload.data.internalReferrers]
          } : payload.data)
        },
        removeInternalReferrerLoading: {
          ...state.removeInternalReferrerLoading,
          [payload.dealId]: false
        },
        removeInternalReferrerError: {
          ...state.removeInternalReferrerError,
          [payload.dealId]: null
        },
      });
    
    case REMOVE_INTERNAL_REFERRER_FAILURE:
      return Object.assign({}, state, {
        removeInternalReferrerLoading: {
          ...state.removeInternalReferrerLoading,
          [payload.dealId]: false
        },
        removeInternalReferrerError: {
          ...state.removeExternalReferrerError,
          [payload.dealId]: payload.message
        },
      });

    default:
      return state;
  }
};
