/**
 * PublicPage component, use inside Terms & condition and
 * Privacy policy pages
 *
 * @author Akhila
 */
import AppBar from '@material-ui/core/AppBar';
import Fab from '@material-ui/core/Fab';
import IconBack from '@material-ui/icons/ArrowBack';
import logo from '../../assets/logo.svg';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import UserDropdown from '../Navbar/UserDropdown';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  grow: {
    flexGrow: 1,
  },
  appbar: {
    position: 'fixed'
  },
  logo: {
    width: '150px',
    borderRadius: '4px',
    margin: theme.spacing(3),
  },
  backButton: {
    margin: theme.spacing(1),
    backgroundColor: '#379AFF',
    color: '#fff',
  },
  iconBack: {
    width: '70%',
    height: '70%'
  },
  pageSection: {
    maxWidth: '1024px',
    padding: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '5em',
  },
});

class PublicPage extends React.PureComponent {

  render() {
    const { classes, header, children, disableBack, me, logout } = this.props;
    return(
      <div className={classes.root}>
        <AppBar className={classes.appbar}>
          <Toolbar>
          {!disableBack &&
            <Fab
              className={classes.backButton}
              component={Link}
              to="/">
              <IconBack className={classes.iconBack}/>
            </Fab>
          }
          <Link to="/" className={classes.grow}>
            <Typography className={classes.flex}>
              <img src={logo} alt="Taghash" className={classes.logo}/>
            </Typography>
          </Link>
          {me && (
            <UserDropdown
              me={me}
              logout={logout}
              hideSm={false}
            />
          )}
          </Toolbar>
        </AppBar>
        <div className={classes.pageSection}>
          <div className={classes.pageHeader}>
            <h1>{header}</h1>
          </div>
          <div className={classes.pageDescription}>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

PublicPage.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(PublicPage);
