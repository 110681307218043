export const LOAD_CONVERSATION_LIST_REQUEST = 'conversation/LOAD_CONVERSATION_LIST_REQUEST';
export const LOAD_CONVERSATION_LIST_SUCCESS = 'conversation/LOAD_CONVERSATION_LIST_SUCCESS';
export const LOAD_CONVERSATION_LIST_FAILURE = 'conversation/LOAD_CONVERSATION_LIST_FAILURE';

export const LOAD_CONVERSATION_REQUEST = 'conversation/LOAD_CONVERSATION_REQUEST';
export const LOAD_CONVERSATION_SUCCESS = 'conversation/LOAD_CONVERSATION_SUCCESS';
export const LOAD_CONVERSATION_FAILURE = 'conversation/LOAD_CONVERSATION_FAILURE';

export const LOAD_PARTICIPANT_LIST_REQUEST = 'conversation/LOAD_PARTICIPANT_LIST_REQUEST';
export const LOAD_PARTICIPANT_LIST_SUCCESS = 'conversation/LOAD_PARTICIPANT_LIST_SUCCESS';
export const LOAD_PARTICIPANT_LIST_FAILURE = 'conversation/LOAD_PARTICIPANT_LIST_FAILURE';

export const CREATE_CONVERSATION_REQUEST = 'conversation/CREATE_CONVERSATION_REQUEST';
export const CREATE_CONVERSATION_SUCCESS = 'conversation/CREATE_CONVERSATION_SUCCESS';
export const CREATE_CONVERSATION_FAILURE = 'conversation/CREATE_CONVERSATION_FAILURE';

export const ROLLBACK_CONVERSATION_REQUEST = 'conversation/ROLLBACK_CONVERSATION_REQUEST';
export const ROLLBACK_CONVERSATION_SUCCESS = 'conversation/ROLLBACK_CONVERSATION_SUCCESS';
export const ROLLBACK_CONVERSATION_FAILURE = 'conversation/ROLLBACK_CONVERSATION_FAILURE';


export const TOGGLE_CONVERSATION_AS_STARRED_REQUEST = 'conversation/TOGGLE_CONVERSATION_AS_STARRED_REQUEST';
export const TOGGLE_CONVERSATION_AS_STARRED_SUCCESS = 'conversation/TOGGLE_CONVERSATION_AS_STARRED_SUCCESS';
export const TOGGLE_CONVERSATION_AS_STARRED_FAILURE = 'conversation/TOGGLE_CONVERSATION_AS_STARRED_FAILURE'; 