import supportsHTML5Storage from "./supportsHTML5Storage";
import Time from "../components/Time";

/*
 * This is to abstract out cache as in future we might
 * use different tech than localstorage
 */

const setCache = (cache_key, data, options={}) => {
  if (!supportsHTML5Storage()) {
    return;
  }
  const payload = {
    value: data,
    expires_on: options.expires_on || Time.add(new Date(), 7, "day") // when we consider this key expired
  };
  window.localStorage.setItem(cache_key, JSON.stringify(payload));
};

const getCache = cache_key => {
  if (!supportsHTML5Storage()) {
    return;
  }
  let cachedValue = window.localStorage.getItem(cache_key);
  if (cachedValue && typeof cachedValue === "string") {
    try {
      cachedValue = JSON.parse(cachedValue);
      // If expires_on is in the past, expire
      if (new Date(cachedValue.expires_on) < new Date()) {
        window.localStorage.removeItem(cache_key);
        return null;
      }
      // Return value if it has not expired
      return cachedValue.value;
    } catch (e) {
      return null;
    }
  }
  return null;
};

const removeCache = cache_key => {
  if (!supportsHTML5Storage()) {
    return;
  }
  window.localStorage.removeItem(cache_key);
};

// Delete all cache key-values which are expired
const deleteOrphanCaches = () => {
  if (!supportsHTML5Storage()) {
    return;
  }
  for(var i =0; i < localStorage.length; i++) {
      let cachedValue = localStorage.getItem(localStorage.key(i));
      if (cachedValue && typeof cachedValue === "string") {
        try {
          cachedValue = JSON.parse(cachedValue);
          // If expires_on is in the past, expire
          if (new Date(cachedValue.expires_on) < new Date()) {
            window.localStorage.removeItem(localStorage.key(i));
            return null;
          }
        } catch (e) {
          return null;
        }
      }
      return null;
  }
}

// TODO: Figure out the appropriate place in the structure for this
export const getOrganizationSettingCacheKey = (key) => {
  return `v1/ORGANIZATION_SETTINGS/${key}`
};


export { setCache, getCache, removeCache, deleteOrphanCaches };
