import { AVAILABLE_RESOURCE_TYPES } from '../../constants/Reminder';
import * as types from './actions';
import isEmptyString from '../../helpers/string/isEmptyString';

import { removeRemindersWithRecurringReminderId } from '.';

const initialState = {
  /**
   * Holds state for reminders. keys are the reminder ids and
   * the values are objects that represent individual reminders
   * It stores reminders for a particular resource say a deal in 
   * the form of:
   * {
   *  [`pipelines`]:{
   *    [`dealId`]:[
   *      //Reminders of dealId
   *    ]
   *  }
   * }
   */
  reminders: Object.keys(AVAILABLE_RESOURCE_TYPES).reduce((acc,resource_type) =>{
    acc[AVAILABLE_RESOURCE_TYPES[resource_type]] = {};
    return acc;
},{}),

  remindersCount:  Object.keys(AVAILABLE_RESOURCE_TYPES).reduce((acc,resource_type) =>{
    acc[AVAILABLE_RESOURCE_TYPES[resource_type]] = {}
    return acc;
},{}),

  /**
   * Loading state when fetching reminders
   * To see if a reminder from a particular 
   */
  getRemindersForResourceLoading: {},

  /**
   * Error state when fetching reminders
   */
  getRemindersForResourceError: {},


  /**
   * Holds state related to individual add-reminder-to-resource loading,
   * 
   */
   addReminderToResourceLoading: {},
  /**
   * Holds state related to individual add-reminder-to-resource error, keys are
   * the deal ids and the values are string error messages
   */
  addReminderToResourceError: {},

  /**
   * Holds loading state for reminders,
   * Keys are reminder ids
   * It represents if the update opeartion for a reminder is going on
   * 
   */
  updateRemindersLoading:Object.keys(AVAILABLE_RESOURCE_TYPES).reduce((acc,resource_type) =>{
    acc[AVAILABLE_RESOURCE_TYPES[resource_type]] = {};
    return acc;
},{}),

  /**
   * Holds error state for reminders,
   * Keys are reminder ids
   * It represents if the update operation for a reminder has failed
   */
  updateRemindersError: Object.keys(AVAILABLE_RESOURCE_TYPES).reduce((acc,resource_type) =>{
    acc[AVAILABLE_RESOURCE_TYPES[resource_type]] = {};
    return acc;
},{}),

  /**
   * Holds state for loading when
   * deleting reminders
   */
  deleteRemindersLoading:{},

  /**
   * Holds state for any error 
   * caused when reminders are being deleted
   * 
   */
  deleteRemindersError:{},
  /**
   * Holds state related to individual deal's reminders count, keys are
   * the deal ids and the values are numbers indicating number of reminders
   */
  dealRemindersCount: {},
  /**
   * Holds state related to individual add-reminder-to-deal loading, keys are
   * the deal ids and the values are boolean indicating if it's loading or not.
   */
  addDealReminderLoading: {},
  /**
   * Holds state related to individual add-reminder-to-deal error, keys are
   * the deal ids and the values are string error messages
   */
  addDealReminderError: {},
  /**
   * Holds state related to individual get-deal-reminders loading, keys are
   * the deal ids and the values are boolean indicating if it's loading or not.
   */
  getDealRemindersLoading: {},
  /**
   * Holds state related to individual get-deal-reminders error, keys are
   * the deal ids and the values are string error messages
   */
  getDealRemindersError: {},
  
  /**
   * Holds state related to batch resource-reminders loading, keys are
   * the resource_types(pipelines) which contain their resource ids (deal ids) 
   * and the values are boolean indicating if it's loading or not.
   */
  batchResourcesRemindersLoading: Object.keys(AVAILABLE_RESOURCE_TYPES).reduce((acc,resource_type) =>{
    acc[AVAILABLE_RESOURCE_TYPES[resource_type]] = {}
    return acc;
},{}),
  /**
   * Holds state related to batch resource-reminders loading, keys are
   * the resource_types(pipelines) which contain their resource ids (deal ids) 
   * and the values  are string error messages
   */
  batchResourcesRemindersError: Object.keys(AVAILABLE_RESOURCE_TYPES).reduce((acc,resource_type) =>{
    acc[AVAILABLE_RESOURCE_TYPES[resource_type]] = {}
    return acc;
},{}),
  /**
   * Holds state related to individual portco's reminders count, keys are
   * the portco ids and the values are numbers indicating number of reminders
   */
  portcoRemindersCount: {},
  /**
   * Holds state related to individual add-reminder-to-portco loading, keys are
   * the portco ids and the values are boolean indicating if it's loading or not.
   */
  addPortcoReminderLoading: {},
  /**
   * Holds state related to individual add-reminder-to-portco error, keys are
   * the portco ids and the values are string error messages
   */
  addPortcoReminderError: {},
  /**
   * Holds state related to individual get-portco-reminders loading, keys are
   * the portco ids and the values are boolean indicating if it's loading or not.
   */
  getPortcoRemindersLoading: {},
  /**
   * Holds state related to individual get-portco-reminders error, keys are
   * the portco ids and the values are string error messages
   */
  getPortcoRemindersError: {},
  /**
   * Holds state related to batch portco-reminders loading, keys are
   * the portco ids and the values are boolean indicating if it's loading or not.
   */
  batchPortcosRemindersLoading: {},
  /**
   * Holds state related to batch portco-reminders error, keys are
   * the portco ids and the values are string error messages
   */
  batchPortcosRemindersError: {},
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    /**
     * ADD REMINDER RESOURCES
     */
    case types.ADD_REMINDER_TO_RESOURCE_REQUEST:
      return Object.assign({}, state, {
        addReminderToResourceLoading: {
          ...state.addReminderToResourceLoading,
          [payload.resource_type]:{
            ...state.addReminderToResourceLoading[payload.resource_type],
            [payload.resource_id]: true
          }
        },

        addReminderToResourceError:{
          ...state.addReminderToResourceError,
          [payload.resource_type]:{
            ...state.addReminderToResourceError[payload.resource_type],
            [payload.resource_id]: null,
          },
        },
      });
    
    case types.ADD_REMINDER_TO_RESOURCE_SUCCESS:{
      const newState = Object.assign({}, state,{
        addReminderToResourceLoading: {
          ...state.addReminderToResourceLoading,
          [payload.resource_type]:{
            ...state.addReminderToResourceLoading[payload.resource_type],
            [payload.resource_id]: false,
          }
        },

        addReminderToResourceError:{
          ...state.addReminderToResourceError,
          [payload.resource_type]:{
            ...state.addReminderToResourceError[payload.resource_type],
            [payload.resource_id]: null,
          },
        },

        reminders:{
          ...state.reminders,
          [payload.resource_type]:{
            ...state.reminders[payload.resource_type],
            [payload.resource_id]:{
              ...state.reminders[payload.resource_type][payload.resource_id],
              [payload.data.id]: payload.data,
            }
          },
        },

        remindersCount:{
          ...state.remindersCount,
          [payload.resource_type]:{
            ...state.remindersCount[payload.resource_type],
            [payload.resource_id]: (state.remindersCount[payload.resource_type][payload.resource_id]?
              state.remindersCount[payload.resource_type][payload.resource_id]+ 1: 1),
          }
        }
        
      });
      delete newState.addReminderToResourceLoading[payload.resource_type][payload.resource_id];
      delete newState.addReminderToResourceError[payload.resource_type][payload.resource_id];
      return newState;
    }

    case types.ADD_REMINDER_TO_RESOURCE_FAILURE:
      return Object.assign({}, state,{
        addReminderToResourceLoading: {
          ...state.addReminderToResourceLoading,
          [payload.resource_type]:{
            ...state.addReminderToResourceLoading[payload.resource_type],
            [payload.resource_id]: false,
          }
        },

        addReminderToResourceError:{
          ...state.addReminderToResourceError,
          [payload.resource_type]:{
            ...state.addReminderToResourceError[payload.resource_type],
            [payload.resource_id]: payload.message,
          },
        },
      });

    /**
     * GET_REMINDER_FOR_RESOURCE reducers
     */
    case types.GET_REMINDER_FOR_RESOURCE_REQUEST:
      return Object.assign({}, state,{
        getRemindersForResourceLoading:{
          ...state.getRemindersForResourceLoading,
          [payload.resource_type]:{
            ...state.getRemindersForResourceLoading[payload.resource_type],
            [payload.resource_id]: true
          },
        },

        getRemindersForResourceError:{
          ...state.getRemindersForResourceError,
          [payload.resource_type]:{
            ...state
              .getRemindersForResourceError[payload.resource_type],
            [payload.resource_id]: null,
          },
        },
      });
    case types.GET_REMINDER_FOR_RESOURCE_SUCCESS:{
      const newState = Object.assign({}, state,{
        getRemindersForResourceLoading:{
          ...state.getRemindersForResourceLoading,
          [payload.resource_type]:{
            ...state
              .getRemindersForResourceLoading[payload.resource_type],
            [payload.resource_id]: false
          },
        },

        getRemindersForResourceError:{
          ...state.getRemindersForResourceError,
          [payload.resource_type]:{
            ...state
              .getRemindersForResourceError[payload.resource_type],
            [payload.resource_id]: null,
          },
        },
        remindersCount:{
          ...state.remindersCount,
          [payload.resource_type]:{
            ...state.remindersCount[payload.resource_type],
            [payload.resource_id]: payload.metadata.count,
          }
        }
      });
       payload.data && payload.data.length > 0 && payload.data.forEach(reminder => {
        newState.reminders[payload.resource_type][payload.resource_id] 
          = newState.reminders[payload.resource_type]? 
            {
              ...newState.reminders[payload.resource_type][payload.resource_id],
              [reminder.id]: reminder,
            }:{[reminder.id]: reminder};
      });

      return newState;
    }

    case types.GET_REMINDER_FOR_RESOURCE_FAILURE:
      return Object.assign({}, state,{
        getRemindersForResourceLoading:{
          ...state.getRemindersForResourceLoading,
          [payload.resource_type]:{
            ...state
              .getRemindersForResourceLoading[payload.resource_type],
            [payload.resource_id]: false
          },
        },

        getRemindersForResourceError:{
          ...state.getRemindersForResourceError,
          [payload.resource_type]:{
            ...state
              .getRemindersForResourceError[payload.resource_type],
            [payload.resource_id]: payload.message,
          },
        },
      });
    

    /**
     * GET_BATCH_RESOURCES_REMINDERS reducers
     */
    case types.GET_BATCH_RESOURCES_REMINDERS_REQUEST: {
      const stateToOverride = {
        batchResourcesRemindersLoading: {},
        batchResorcesRemindersError: {},
      };
      payload.resourceIds.forEach(resource_id => {
        stateToOverride.batchResourcesRemindersLoading[payload.resource_type][resource_id] = true;
        stateToOverride.batchResorcesRemindersError[payload.resource_type][resource_id] = null;
      });
      return Object.assign({}, state, {
        batchResourcesRemindersLoading: {
          ...state.batchResourcesRemindersLoading,
          ...stateToOverride.batchResourcesRemindersLoading
        },
        batchResorcesRemindersError: {
          ...state.batchResorcesRemindersError,
          ...stateToOverride.batchResorcesRemindersError
        }
      });
    }
    case types.GET_BATCH_RESOURCES_REMINDERS_SUCCESS: {
      // create a deep copy of the section of states that are heading for clearance
      const newState = Object.assign({}, state, {
        batchResourcesRemindersLoading: {
          ...state.batchResourcesRemindersLoading,
        },
        batchResorcesRemindersError: {
          ...state.batchResorcesRemindersError,
        },
        // If you don't do this, subscribers to this state won't know that
        // something has changed
        remindersCount: {
          ...state.remindersCount,
        },
        reminders: {
          ...state.reminders,
        },
      });
      // Append the reminders from all dealIds, to reminders hash
      // payload.data is in the form {deal_id: {reminders, count, limit, page}}
      // payload.dealIds has list of deal ids
      payload.resourceIds.forEach(resource_id => {
        payload.data[resource_id].reminders.forEach(reminder => {
          newState.reminders[payload.resource_type][resource_id]
            = newState.reminders[payload.resource_type] ? 
            
              {
                ...newState.reminders[payload.resource_type][resource_id],
                [reminder.id]: reminder,      
            } : {[reminder.id]: reminder};
        });
         newState.remindersCount[payload.resource_type][resource_id] = payload.data[resource_id].count;
        // clear the target items
        delete newState.batchResourcesRemindersLoading[payload.resource_type][resource_id];
        delete newState.batchDealsRemindersError[payload.resource_type][resource_id];
      });
      return newState;
    }
    // case types.GET_BATCH_DEALS_REMINDERS_FAILURE: {
    //   const stateToOverride = {
    //     batchDealsRemindersLoading: {},
    //     batchDealsRemindersError: {},
    //   };
    //   payload.dealIds.forEach(deal_id => {
    //     stateToOverride.batchDealsRemindersLoading[deal_id] = false;
    //     // in a batch request, error is common to all items
    //     stateToOverride.batchDealsRemindersError[deal_id] = payload.message;
    //   });
    //   return Object.assign({}, state, {
    //     batchDealsRemindersLoading: {
    //       ...state.batchDealsRemindersLoading,
    //       ...stateToOverride.batchDealsRemindersLoading
    //     },
    //     batchDealsRemindersError: {
    //       ...state.batchDealsRemindersError,
    //       ...stateToOverride.batchDealsRemindersError
    //     }
    //   });
    // }
    ///
    //////////////////////// Portfolio begins //////////////////////////////////
    ///

    /**
     * GET_BATCH_PORTCOS_REMINDERS reducers
     */
    // case types.GET_BATCH_PORTCOS_REMINDERS_REQUEST: {
    //   const stateToOverride = {
    //     batchPortcosRemindersLoading: {},
    //     batchPortcosRemindersError: {},
    //   };
    //   payload.portcoIds.forEach(portco_id => {
    //     stateToOverride.batchPortcosRemindersLoading[portco_id] = true;
    //     stateToOverride.batchPortcosRemindersError[portco_id] = null;
    //   });
    //   return Object.assign({}, state, {
    //     batchPortcosRemindersLoading: {
    //       ...state.batchPortcosRemindersLoading,
    //       ...stateToOverride.batchPortcosRemindersLoading
    //     },
    //     batchPortcosRemindersError: {
    //       ...state.batchPortcosRemindersError,
    //       ...stateToOverride.batchPortcosRemindersError
    //     }
    //   });
    // }
    // case types.GET_BATCH_PORTCOS_REMINDERS_SUCCESS: {
    //   // create a deep copy of the section of states that are heading for clearance
    //   const newState = Object.assign({}, state, {
    //     batchPortcosRemindersLoading: {
    //       ...state.batchPortcosRemindersLoading,
    //     },
    //     batchPortcosRemindersError: {
    //       ...state.batchPortcosRemindersError,
    //     },
    //     // If you don't do this, subscribers to this state won't know that
    //     // something has changed
    //     portcoRemindersCount: {
    //       ...state.portcoRemindersCount,
    //     },
    //     reminders: {
    //       ...state.reminders,
    //     },
    //   });
    //   // Append the reminders from all portcoIds, to reminders hash
    //   // payload.data is in the form {portco_id: {reminders, count, limit, page}}
    //   // payload.portcoIds has list of portco ids
    //   payload.portcoIds.forEach(portcoId => {
    //     payload.data[portcoId].reminders.forEach(reminder => {
    //       newState.reminders[reminder.id] = reminder;
    //     });
    //     newState.portcoRemindersCount[portcoId] = payload.data[portcoId].count;
    //     // clear the target items
    //     delete newState.batchPortcosRemindersLoading[portcoId];
    //     delete newState.batchPortcosRemindersError[portcoId];
    //   });
    //   return newState;
    // }
    // case types.GET_BATCH_PORTCOS_REMINDERS_FAILURE: {
    //   const stateToOverride = {
    //     batchPortcosRemindersLoading: {},
    //     batchPortcosRemindersError: {},
    //   };
    //   payload.portcoIds.forEach(portco_id => {
    //     stateToOverride.batchPortcosRemindersLoading[portco_id] = false;
    //     // in a batch request, error is common to all items
    //     stateToOverride.batchPortcosRemindersError[portco_id] = payload.message;
    //   });
    //   return Object.assign({}, state, {
    //     batchPortcosRemindersLoading: {
    //       ...state.batchPortcosRemindersLoading,
    //       ...stateToOverride.batchPortciosRemindersLoading
    //     },
    //     batchPortcosRemindersError: {
    //       ...state.batchPortcosRemindersError,
    //       ...stateToOverride.batchPortcosRemindersError
    //     }
    //   });
    // }
    /**
     * Update reminders reducers
     */
    case types.UPDATE_REMINDER_FOR_RESOURCE_REQUEST:{
      return  Object.assign({},state,{
        updateRemindersLoading:{
          ...state.updateRemindersLoading,
          [payload.resource_type]: {
            ...(state.updateRemindersLoading[payload.resource_type] || {}),
            [payload.resource_id]:{
              ...(state.updateRemindersLoading[payload.resource_type]? 
                state.updateRemindersLoading[payload.resource_type][payload.resource_id] 
                : {}),
              [payload.reminderId]: true
            }
          } 
        },
        updateRemindersError:{
          ...state.updateRemindersError,
          [payload.resource_type]:{
            ...state.addReminderToResourceError[payload.resource_type],
            [payload.resource_id]: {
              ...(state.addReminderToResourceError[payload.resource_type]? 
                state.addReminderToResourceError[payload.resource_type][payload.resource_id] 
                : {}),
              [payload.reminderId]: null,
            },
          },
        },
      });
    }

    case types.UPDATE_REMINDER_FOR_RESOURCE_SUCCESS:{
      let updatingState = Object.assign({}, state)
      /**
       * If we are updating the underlying recuring reminder id
       * we must make sure all reminder instances wrt that id
       * must be removed from redux as well
       * 
       */
      
      if(!isEmptyString(payload.data?.recurringReminder?.id)){        
        updatingState.reminders[payload.resource_type][payload.resource_id] = removeRemindersWithRecurringReminderId(
          payload.data?.recurringReminder?.id,
          updatingState.reminders[payload.resource_type][payload.resource_id]
        );
      }
      
       updatingState =  Object.assign(updatingState, state,{
        updateRemindersLoading:{
          ...state.updateRemindersLoading,
          [payload.resource_type]: {
            ...(state.updateRemindersLoading[payload.resource_type] || {}),
            [payload.resource_id]:{
              ...(state.updateRemindersLoading[payload.resource_type][payload.resource_id] || {}),
              [payload.reminderId]: false
            }
          } 
        },
        updateRemindersError:{
          ...state.updateRemindersError,
          [payload.resource_type]:{
            ...state.addReminderToResourceError[payload.resource_type],
            [payload.resource_id]: {
              ...(state.addReminderToResourceError[payload.resource_type] ? 
                state.addReminderToResourceError[payload.resource_type][payload.resource_id]
                 : {}),
              [payload.reminderId]: null,
            },
          },
        },
        reminders:{
          ...state.reminders,
          [payload.resource_type]:{
            ...state.reminders[payload.resource_type],
            [payload.resource_id]:{
              ...state.reminders[payload.resource_type][payload.resource_id],
              [payload.data.id]: payload.data,
            }
          },
        },
      });
      if(payload.reminderId !== payload.data.id)
      delete updatingState.reminders[payload.resource_type][payload.resource_id][payload.reminderId]
      

      return updatingState;
    }

    case types.UPDATE_REMINDER_FOR_RESOURCE_FAILURE:{
      return Object.assign({}, state,{
        updateRemindersLoading:{
          ...state.updateRemindersLoading,
          [payload.resource_type]: {
            ...(state.updateRemindersLoading[payload.resource_type] || {}),
            [payload.resource_id]:{
              ...(state.updateRemindersLoading[payload.resource_type][payload.resource_id] || {}),
              [payload.reminderId]: true
            }
          } 
        },
        updateRemindersError:{
          ...state.updateRemindersError,
          [payload.resource_type]:{
            ...state.addReminderToResourceError[payload.resource_type],
            [payload.resource_id]: {
              ...(state.addReminderToResourceError[payload.resource_type]?
                state.addReminderToResourceError[payload.resource_type][payload.resource_id]
                :{}),
              [payload.reminderId]: null,
            },
          },
        },
      })

      
    }

    /**
     * Delete reminder reducers
     */
    case types.DELETE_REMINDER_FOR_RESOURCE_REQUEST:{
        return Object.assign({}, state,{
          deleteRemindersLoading:{
            ...state.deleteRemindersLoading,
            [payload.resource_type]:{
              ...(state.deleteRemindersLoading[payload.resource_type] || {}),
              [payload.resource_id]: {
                ...(state.deleteRemindersLoading[payload.resource_type] ? 
                  state.deleteRemindersLoading[payload.resource_type][payload.resource_id] : {}),
                [payload.reminderId]: true,
              }
            }
          },

          deleteRemindersError:{
            ...state.deleteRemindersError,
            [payload.resource_type]:{
              ...(state.deleteRemindersError[payload.resource_type] || {}),
              [payload.resource_type]:{
                ...(state.deleteRemindersError[payload.resource_type] || {}),
                [payload.resource_id]:{
                  ...(state.deleteRemindersError[payload.resource_type] ? 
                    state.deleteRemindersError[payload.resource_type][payload.resource_id] :{}),
                  [payload.reminderId]: null
                }
              }
            }
          }
        })
    }

    case types.DELETE_REMINDER_FOR_RESOURCE_SUCCESS:{
      let newState =  Object.assign({}, state,{
        deleteRemindersLoading:{
          ...state.deleteRemindersLoading,
          [payload.resource_type]:{
            ...(state.deleteRemindersLoading[payload.resource_type] || {}),
            [payload.resource_id]:{
              ...(state.deleteRemindersLoading[payload.resource_type][payload.resource_id] || {}),
              [payload.reminderId]: false,
            }
          }
        },

        deleteRemindersError:{
          ...state.deleteRemindersError,
          [payload.resource_type]:{
            ...(state.deleteRemindersError[payload.resource_type] || {}),
            [payload.resource_id]:{
              ...(state.deleteRemindersError[payload.resource_type][payload.resource_id] || {}),
              [payload.reminderId] :  null
            }
          }
        },

        reminders:{
          ...state.reminders
        },
        remindersCount:{
          ...state.remindersCount,
          [payload.resource_type]:{
            ...state.remindersCount[payload.resource_type],
            [payload.resource_id]:{
              ...(state.remindersCount[payload.resource_type][payload.resource_id] - 1),
            }
          }
        }

      });
      //If we are deleting the recurring reminder id, we must make sure the reminder instances asoociated with
      //that recurring reminder id must be removed as well
      if(payload?.isRecurring &&
        !isEmptyString(
        newState.reminders[payload.resource_type]
        [payload.resource_id][payload.reminderId]
        ?.recurringReminder?.id
      )){        
        const recurringReminderId =  newState.reminders[payload.resource_type]
        [payload.resource_id][payload.reminderId]
        ?.recurringReminder?.id
        //Remove all reminders wih this recurring reminder id from redux
          newState.reminders[payload.resource_type][payload.resource_id] = removeRemindersWithRecurringReminderId(
            recurringReminderId,
          newState.reminders[payload.resource_type][payload.resource_id]
        );        
      }
      delete newState.reminders[payload.resource_type][payload.resource_id][payload.reminderId];
      return newState;
    }

    case types.DELETE_REMINDER_FOR_RESOURCE_FAILURE:{
      return Object.assign({}, state,{
        deleteRemindersLoading:{
          ...state.deleteRemindersLoading,
          [payload.resource_type]:{
            ...(state.deleteRemindersLoading[payload.resource_type] || {}),
            [payload.resource_id]:{
              ...(state.deleteRemindersLoading[payload.resource_type][payload.resource_id] || {}),
              [payload.reminderId]: false,
            }
          }
        },

        deleteRemindersError:{
          ...state.deleteRemindersError,
          [payload.resource_type]:{
            ...(state.deleteRemindersError[payload.resource_type]  || {}),
            [payload.resource_id]:{
              ...(state.deleteRemindersError[payload.resource_type] ? 
                state.deleteRemindersError[payload.resource_type][payload.resource_id] : {}),
                [payload.reminderId]: payload.message
            }
          }
        }
      })
    }

    default:
      return state;
  }
}
