import { 
  ADD_STARTUP_FOUNDER_REQUEST,
  ADD_STARTUP_FOUNDER_SUCCESS,
  ADD_STARTUP_FOUNDER_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case ADD_STARTUP_FOUNDER_REQUEST:
      return Object.assign({}, state, {
        addFounderLoading: true,
        addFounderError: null
      });

    case ADD_STARTUP_FOUNDER_SUCCESS:
      return Object.assign({}, state, {
        addFounderLoading: false,
        addFounderError: null
      });
      
    case ADD_STARTUP_FOUNDER_FAILURE:
      return Object.assign({}, state, {
        addFounderLoading: false,
        addFounderError: payload,
      });

    default:
      return state;
  }
};
