import * as types from './actions';
import initialState from './initialState';

import getOrgContact from './reducerHandlers/getOrgContact';
import updateOrgContact from './reducerHandlers/updateOrgContact';

/**
 * Reducers
 */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    /**
     * GET_ORG_CONTACT reducers
     */
    case types.GET_ORG_CONTACT_REQUEST:
    case types.GET_ORG_CONTACT_SUCCESS:
    case types.GET_ORG_CONTACT_FAILURE:
      return getOrgContact(state, type, payload);
    /**
     * UPDATE_ORG_CONTACT reducers
     */
    case types.UPDATE_ORG_CONTACT_REQUEST:
    case types.UPDATE_ORG_CONTACT_SUCCESS:
    case types.UPDATE_ORG_CONTACT_FAILURE:
      return updateOrgContact(state, type, payload);

    default:
      return state;
  }
};
