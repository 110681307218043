/**
 * @format
 */

// unread email summary constants
export const NOTIFICATIONS_EMAIL_UNREAD_SUMMARY =
  'notifications_email_unread_summary';

export const NOTIFICATIONS_EMAIL_UNREAD_SUMMARY_CHOICES_ARRAY = [
  'never',
  'daily',
];

export const NOTIFICATIONS_EMAIL_UNREAD_SUMMARY_CHOICES_MAP = {
  'never': 'Never',
  'daily': 'Daily',
};

export const NOTIFICATIONS_EMAIL_UNREAD_SUMMARY_CHOICE_DEFAULT = 'daily';
// end

// assigned-to-you email notification settings constants
export const NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU =
  'notifications_email_assigned_to_you';

export const NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_CHOICES_ARRAY = [
  'never',
  'immediately',
];

export const NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_CHOICES_MAP = {
  'never': 'Never',
  'immediately': 'Immediately',
};

export const NOTIFICATIONS_EMAIL_ASSIGNED_TO_YOU_CHOICE_DEFAULT = 'immediately';
// end

export const NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_SETTING_KEY =
  'notifications_email_signals_summary';

export const NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_CHOICES_ARRAY = [
  'never',
  'weekly',
];

export const NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_CHOICES_MAP = {
  'never': 'Never',
  'weekly': 'Weekly',
};

export const NOTIFICATIONS_EMAIL_SIGNALS_SUMMARY_CHOICE_DEFAULT = 'weekly';

export const REMINDER_ACKNOWLEDGEMENT_TOGGLE =
  'reminder_acknowledgement_toggle';

export const REMINDER_GCAL_TOGGLE_DEFAULT = 'false';

export const DEAL_HIDE_ACTIVITY_LOG = 'deal_hide_activity_log';

export const DEAL_HIDE_ACTIVITY_LOG_ARRAY = [true, false];

export const DEAL_HIDE_ACTIVITY_LOG_DEFAULT = false;

export const FLAG_FULL_SYNC_GMAIL = 'flag_full_sync_gmail';

export const MAGIC_SYNC_ENABLED = 'magicsync.enabled';

export const FLAG_FULL_SYNC_GMAIL_DEFAULT = false;
