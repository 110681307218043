/**
 * @format
 */
import {
  LOAD_CUSTOM_FIELD_VALUES_REQUEST,
  LOAD_CUSTOM_FIELD_VALUES_SUCCESS,
  LOAD_CUSTOM_FIELD_VALUES_FAILURE,
} from '../actions';
import { map, isArray } from 'lodash';

export default (state, type, payload) => {
  switch (type) {
    case LOAD_CUSTOM_FIELD_VALUES_REQUEST: {
      let loadingMap = {};
      let portcoIdsArray = isArray(payload.portcoIds)
        ? payload.portcoIds
        : [payload.portcoIds];
      map(portcoIdsArray, portcoId => {
        loadingMap[portcoId] = true;
      });
      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...loadingMap,
        },
        customFieldValuesError: null,
      });
    }

    case LOAD_CUSTOM_FIELD_VALUES_SUCCESS: {
      let dataMap = {};
      let loadingMap = {};
      let portcoIdsArray = isArray(payload.portcoIds)
        ? payload.portcoIds
        : [payload.portcoIds];
      map(payload.data, portco => {
        dataMap[portco.id] = portco;
      });
      map(portcoIdsArray, portcoId => {
        loadingMap[portcoId] = false;
      });
      const newState = {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...loadingMap,
        },
        customFieldValues: {
          ...state.customFieldValues,
          ...dataMap,
        },
        customFieldValuesError: null,
      };
      return Object.assign({}, state, newState);
    }

    case LOAD_CUSTOM_FIELD_VALUES_FAILURE: {
      let loadingMap = {};
      let portcoIdsArray = isArray(payload.portcoIds)
        ? payload.portcoIds
        : [payload.portcoIds];
      map(portcoIdsArray, portcoId => {
        loadingMap[portcoId] = false;
      });

      return Object.assign({}, state, {
        customFieldValuesLoading: {
          ...state.customFieldValuesLoading,
          ...loadingMap,
        },
        customFieldValuesError: payload.message,
      });
    }

    default:
      return state;
  }
};
