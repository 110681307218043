/**
 * @format
 */

import * as types from '../actions';

import { assign } from '../../../helpers/object';

export default (state, type, payload) => {
  switch (type) {
    /** DELETE_DRAWDOWN */
    case types.DELETE_DRAWDOWN_REQUEST: {
      return assign(state, {
        deleteDrawdownLoading: true,
      });
    }
    case types.DELETE_DRAWDOWN_FAILURE: {
      return assign(state, {
        deleteDrawdownLoading: false,
      });
    }
    case types.DELETE_DRAWDOWN_SUCCESS: {
      const drawdownState = { ...state.drawdown };
      delete drawdownState[payload.drawdownId];
      return assign(state, {
        deleteDrawdownLoading: false,
        drawdown: drawdownState,
      });
    }

    default:
      return state;
  }
};
