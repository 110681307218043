/**
 * Sign offs reducers also exports action creators
 */

import addSignOffGroup from './actionCreators/addSignOffGroup';
import addSignOffItem from './actionCreators/addSignOffItem';
import updateSignOffGroup from './actionCreators/updateSignOffGroup';
import updateSignOffItem from './actionCreators/updateSignOffItem';
import addSignOffAssignee from './actionCreators/addSignOffAssignee';
import removeSignOffAssignee from './actionCreators/removeSignOffAssignee';
import approveSignOff from './actionCreators/approveSignOff';
import rejectSignOff from './actionCreators/rejectSignOff';
import addSignOffAttachment from './actionCreators/addSignOffAttachment';
import removeSignOffAttachment from './actionCreators/removeSignOffAttachment';
import deleteSignOffItem from './actionCreators/deleteSignOffItem';
import deleteSignOffGroup from './actionCreators/deleteSignOffGroup';
import listSignOffGroups from './actionCreators/listSignOffGroups';
import getSignOffGroup from './actionCreators/getSignOffGroup';
import listSignOffItems from './actionCreators/listSignOffItems';
import getSignOffItem from './actionCreators/getSignOffItem';
import listSignOffActivityLogs from './actionCreators/listSignOffActivityLogs';
import clearSignOffState from './actionCreators/clearSignOffState';

import reducers from './reducers';

// Reducers by default
export default reducers;
// Export all action creators
export {
  addSignOffGroup,
  addSignOffItem,
  updateSignOffGroup,
  updateSignOffItem,
  addSignOffAssignee,
  removeSignOffAssignee,
  approveSignOff,
  rejectSignOff,
  addSignOffAttachment,
  removeSignOffAttachment,
  deleteSignOffItem,
  deleteSignOffGroup,
  listSignOffGroups,
  getSignOffGroup,
  listSignOffItems,
  getSignOffItem,
  listSignOffActivityLogs,
  clearSignOffState,
};
