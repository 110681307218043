import {
  LOAD_DEAL_EVENT_REQUEST,
  LOAD_DEAL_EVENT_SUCCESS,
  LOAD_DEAL_EVENT_FAILURE } from '../actions';

export default (state, type, payload) => {
  switch(type) {
    case LOAD_DEAL_EVENT_REQUEST:
      return Object.assign({}, state, {
        eventLoading: {
          ...state.eventLoading,
          [payload.eventId]: true
        },
        eventError: {
          ...state.eventError,
          [payload.eventId]: null
        }
      });
    
    case LOAD_DEAL_EVENT_SUCCESS:
      return Object.assign({}, state, {
        event: {
          ...state.event,
          [payload.eventId]: payload.data
        },
        eventLoading: {
          ...state.eventLoading,
          [payload.eventId]: false
        },
        eventError: {
          ...state.eventError,
          [payload.eventId]: null
        }
      });
    
    case LOAD_DEAL_EVENT_FAILURE:
      return Object.assign({}, state, {
        eventLoading: {
          ...state.eventLoading,
          [payload.eventId]: false
        },
        eventError: {
          ...state.eventError,
          [payload.eventId]: payload.message
        },
      });

    default:
      return state;
  }
};
