/**
 * @format
 */

import reduce from 'lodash.reduce';
import * as types from '../actions';

import { assign } from '../../../helpers/object';

export default (state, type, payload) => {
  switch (type) {
    /** LOAD_NAV_UPDATE_LIST */
    case types.LOAD_NAV_UPDATE_LIST_REQUEST: {
      return assign(state, {
        navUpdateListLoading: true,
        navUpdateListError: null,
      });
    }
    case types.LOAD_NAV_UPDATE_LIST_FAILURE: {
      return assign(state, {
        navUpdateListLoading: false,
        navUpdateListError: payload.message,
      });
    }
    case types.LOAD_NAV_UPDATE_LIST_SUCCESS: {
      const navUpdates = reduce(
        payload.data,
        function(acc, navUpdate) {
          acc[navUpdate.id] = navUpdate;
          return acc;
        },
        {}
      );
      return assign(state, {
        navUpdateListLoading: false,
        navUpdateListError: null,
        navUpdate: assign(state.navUpdate, navUpdates),
      });
    }

    default:
      return state;
  }
};
