/**
 * Updates a portfolio company
 *
 * @format
 */
import request from '../../../helpers/request';
import { enqueueNotification } from '../../app';
import {
  UPDATE_PORTCO_REQUEST,
  UPDATE_PORTCO_SUCCESS,
  UPDATE_PORTCO_FAILURE,
} from '../actions';

export default (portcoId, form) => {
  return async dispatch => {
    dispatch({
      type: UPDATE_PORTCO_REQUEST,
      payload: {
        portcoId,
      },
    });
    let data;
    try {
      const response = await request.put(
        `/api/portfolio/v1/companies/${portcoId}`,
        form,
        dispatch
      );
      data = response.data;
    } catch (err) {
      const message =
        'Something went wrong while adding the portfolio company, please try again or contact support.';
      dispatch({
        type: UPDATE_PORTCO_FAILURE,
        payload: {
          portcoId,
          message,
        },
      });
      dispatch(enqueueNotification(message));
      throw err;
    }
    if (data.success) {
      dispatch({
        type: UPDATE_PORTCO_SUCCESS,
        payload: {
          portcoId: data.id,
          data: data.data,
        },
      });
      return data;
    } else {
      dispatch({
        type: UPDATE_PORTCO_FAILURE,
        payload: {
          portcoId,
          message: data.message,
        },
      });
      dispatch(enqueueNotification(data.message));
      throw new Error(data.message);
    }
  };
};
