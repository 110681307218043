/**
 * @format
 */
export const INTERNAL_NAMES = {
  PIPELINE: 'PIPELINE',
  PORTFOLIO: 'PORTFOLIO',
  FUNDS: 'FUNDS',
  INVESTORS: 'INVESTORS',
  CONTACTS: 'CONTACTS',
};

export const MANAGE_WORKSPACE_FORM_ROLES = {
  ADMIN: {
    value: 'workspaceAdmin',
    label: 'Admin (Can see, update all deals)',
  },
  MEMBER: {
    value: 'workspaceMember',
    label: 'Member (Can see all, update only on assigned deals)',
  },
};

export const ACTIVE_WORKSPACE_POSTION_ID = 'active_workspace_position_id';
