/**
 * NOTE: Import actions are in system module
 * TODO: Move them here
 */
export const LIST_DEAL_EXPORT_REQUEST = 'system/LIST_DEAL_EXPORT_REQUEST';
export const LIST_DEAL_EXPORT_SUCCESS = 'system/LIST_DEAL_EXPORT_SUCCESS';
export const LIST_DEAL_EXPORT_FAILURE = 'system/LIST_DEAL_EXPORT_FAILURE';

export const LIST_PORTCO_EXPORT_REQUEST = 'system/LIST_PORTCO_EXPORT_REQUEST';
export const LIST_PORTCO_EXPORT_SUCCESS = 'system/LIST_PORTCO_EXPORT_SUCCESS';
export const LIST_PORTCO_EXPORT_FAILURE = 'system/LIST_PORTCO_EXPORT_FAILURE';

export const DEAL_EXPORT_ENQUEUE_JOB_REQUEST = 'system/DEAL_EXPORT_ENQUEUE_JOB_REQUEST';
export const DEAL_EXPORT_ENQUEUE_JOB_SUCCESS = 'system/DEAL_EXPORT_ENQUEUE_JOB_SUCCESS';
export const DEAL_EXPORT_ENQUEUE_JOB_FAILURE = 'system/DEAL_EXPORT_ENQUEUE_JOB_FAILURE';

export const PORTCO_EXPORT_ENQUEUE_JOB_REQUEST = 'system/PORTCO_EXPORT_ENQUEUE_JOB_REQUEST';
export const PORTCO_EXPORT_ENQUEUE_JOB_SUCCESS = 'system/PORTCO_EXPORT_ENQUEUE_JOB_SUCCESS';
export const PORTCO_EXPORT_ENQUEUE_JOB_FAILURE = 'system/PORTCO_EXPORT_ENQUEUE_JOB_FAILURE';

export const DEAL_EXPORT_GET_SIGNED_URL_REQUEST = 'system/DEAL_EXPORT_GET_SIGNED_URL_REQUEST';
export const DEAL_EXPORT_GET_SIGNED_URL_SUCCESS = 'system/DEAL_EXPORT_GET_SIGNED_URL_SUCCESS';
export const DEAL_EXPORT_GET_SIGNED_URL_FAILURE = 'system/DEAL_EXPORT_GET_SIGNED_URL_FAILURE';


export const LIST_DEAL_EXPORT_CLEAR = 'system/LIST_DEAL_EXPORT_CLEAR';

export const LIST_PORTCO_EXPORT_CLEAR = 'system/LIST_PORTCO_EXPORT_CLEAR';
