/**@todo: Need to update this file with new roles */

export const organizationRoles = {
  ADMIN: 'adminInvestor',
  ACCOUNT_MANAGER: 'accountManager',
  MEMBER: 'memberInvestor',
  DEALFLOW_IMPORTER: 'dealflowImporter',
  DEALFLOW_EXPORTER: 'dealflowExporter',
  PORTFOLIO_ADMIN: 'portfolioAdmin',
  PORTFOLIO_ANALYST: 'portfolioAnalyst',
  PIPELINE_ADMIN: 'pipelineAdmin'
};

export const ROLES_MAP = {
  PIPELINE_MEMBER: 'pipeline_member',
  ADMIN_INVESTOR: 'super_admin',
  ACCOUNT_MANAGER: 'account_manager',
  PIPELINE_ADMIN: 'pipeline_admin',
  PORTFOLIO_ADMIN: 'portfolio_admin',
  PORTFOLIO_ANALYST: 'portfolio_analyst'
};

export const AvailableRoles = {
  'pipeline_member': 'Pipeline member',
  'super_admin': 'Admin Investor',
  'account_manager': 'Account Manager',
  'pipeline_admin': 'Pipeline Admin',
  'portfolio_admin': 'Portfolio Admin',
  'portfolio_analyst': 'Portfolio Analyst'
};

export const ROLES_KEY_MAP = {
  adminInvestor: 'Admin Investor',
  memberInvestor: 'Member Investor',
  accountManager: 'Account Manager',
  pipelineAdmin:'Pipeline Admin',
  pipelineMember: 'Pipeline Member',
  dealflowAnalyticsAdmin:'DealflowAnalytics Admin',
  dealflowAnalyticsMember:'DealflowAnalytics Member',
  contactAdmin:'Contact Admin',
  contactMember:'Contact Member',
  portfolioAdmin: 'Portfolio Admin',
  portfolioAnalyst: 'Portfolio Analyst'
}
