/**
 * System action creators
 *
 * @author Ritesh Shrivastav
 */
import * as types from './actions';
import axios from 'axios';
import reducers from './reducers';
import request from '../../helpers/request';
import { enqueueNotification } from '../app';

export default reducers;

export const inviteOrganization = (form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.INVITE_ORGANIZATION_REQUEST
      });
      request.post(`/api/v1/invitations`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.INVITE_ORGANIZATION_SUCCESS
          });
          dispatch(enqueueNotification('Organization invited successfully.'));
          resolve(data);
        } else {
          dispatch({
            type: types.INVITE_ORGANIZATION_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          let err = new Error(data.message);
          err.code = data.code;
          reject(err);
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while inviting organization, please try again.';
        dispatch({
          type: types.INVITE_ORGANIZATION_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

export const dealImportGetSignedURL = (form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.DEAL_IMPORT_GET_SIGNED_URL_REQUEST
      });
      request.post(`/api/v2/data-transport/deal/import/getSignedUrl`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.DEAL_IMPORT_GET_SIGNED_URL_SUCCESS
          });
          resolve(data);
        } else {
          dispatch({
            type: types.DEAL_IMPORT_GET_SIGNED_URL_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while uploading csv, please try again.';
        dispatch({
          type: types.DEAL_IMPORT_GET_SIGNED_URL_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

// https://medium.com/@kevinwu/client-side-file-upload-to-s3-using-axios-c9363ec7b530
export const dealImportUploadToS3 = (signedURL, file) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.DEAL_IMPORT_S3_UPLOAD_REQUEST
      });
      // Note the difference here
      axios.put(signedURL, file, {
        headers: { 'Content-Type': file.type }
      })
      .then(({ data }) => {
        dispatch({
          type: types.DEAL_IMPORT_S3_UPLOAD_SUCCESS
        });
        resolve(data);
      })
      .catch((err) => {
        const message = 'Something went wrong while uploading csv, please try again.';
        dispatch({
          type: types.DEAL_IMPORT_S3_UPLOAD_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

export const enqueueDealImportJob = (form) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.DEAL_IMPORT_ENQUEUE_JOB_REQUEST
      });
      request.post(`/api/v2/data-transport/deal/import/enqueue`, form, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.DEAL_IMPORT_ENQUEUE_JOB_SUCCESS
          });
          dispatch(enqueueNotification('Enqueued job to process deals'));
          resolve(data);
        } else {
          dispatch({
            type: types.DEAL_IMPORT_ENQUEUE_JOB_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while enqueuing import job, please try again.';
        dispatch({
          type: types.DEAL_IMPORT_ENQUEUE_JOB_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

export const listDealImportJobs = (options) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LIST_DEAL_IMPORT_REQUEST,
        payload: {
          options
        }
      });
      request.get(`/api/v2/data-transport/deal/import`, options, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.LIST_DEAL_IMPORT_SUCCESS,
            payload: {
              data: data.data,
              options
            }
          });
          resolve(data);
        } else {
          dispatch({
            type: types.LIST_DEAL_IMPORT_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while listing import jobs, please try again.';
        dispatch({
          type: types.LIST_DEAL_IMPORT_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

export const clearDealImportJobs = (options) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LIST_DEAL_IMPORT_CLEAR
      });
      resolve();
    });
  };
};

export const downloadCSVSample = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.DOWNLOAD_CSV_SAMPLE_REQUEST,
      });
      request.get(`/api/v2/data-transport/deal/downloadCsvSample`, {}, dispatch)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: types.DOWNLOAD_CSV_SAMPLE_SUCCESS,
            payload: data.data,
          });
          resolve(data.data)
        } else {
          dispatch({
            type: types.LIST_DEAL_IMPORT_FAILURE,
            payload: data.message
          });
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch((err) => {
        const message = 'Something went wrong while downloading CSV sample, please try again.';
        dispatch({
          type: types.DOWNLOAD_CSV_SAMPLE_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      });
    });
  };
};

export const loadFaqWithAnswers = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: types.LOAD_TAGHASH_FAQ_REQUEST,
      })
      request.get(`/api/v5/faq`, {}, dispatch)
      .then(({data}) => {
        if (data.success) {
          dispatch({
            type: types.LOAD_TAGHASH_FAQ_SUCCESS,
            payload: data.data
          })
          resolve(data.data);
        } else {
          dispatch({
            type: types.LOAD_TAGHASH_FAQ_FAILURE,
            payload: data.message
          })
          dispatch(enqueueNotification(data.message));
          reject(new Error(data.message));
        }
      })
      .catch(err => {
        const message = 'Something went wrong while loading FAQs, please try again.';
        dispatch({
          type: types.LOAD_TAGHASH_FAQ_FAILURE,
          payload: message
        });
        dispatch(enqueueNotification(message));
        reject(err);
      })
    })
  }
}
